import { useAuth } from '@cibo/profile'
import { FieldModel } from '@cibo/core'

export const getFieldsOwner = (fieldModels: FieldModel[]) => {
  if (fieldModels.length === 0) return

  return fieldModels.reduce((currentOwner, fieldModel) => {
    const owner = fieldModel.owner

    if (currentOwner.userId !== owner.userId) {
      throw new Error('fields do not have single owner')
    }

    return currentOwner
  }, fieldModels[0].owner)
}

export const useFieldsOwner = (fieldModels: FieldModel[]) => {
  const { userId: selfUserId } = useAuth()

  try {
    const owner = getFieldsOwner(fieldModels)

    return {
      owner,
      isSelf: owner?.userId === selfUserId,
      displayName: fieldModels[0].ownerLabel,
    }
  } catch (e) {
    console.log(e)
  }
}
