import { SquareSvgProps, Theme } from '@cibo/ui'

const FB_BLUE = '#1977F3'
const WHITE = '#FEFEFE'

interface Props {
  invert?: boolean
}

function SvgComponent({ fill, invert, size = 24, style }: Props & SquareSvgProps) {
  return (
    <svg width={size} height={size} viewBox="0 0 408 400" style={style}>
      <g clipPath="url(#prefix__clip0)" fillRule="evenodd" clipRule="evenodd">
        <path
          d="M407.035 200.21C407.035 89.646 315.909 0 203.518 0 91.127 0 0 89.646 0 200.21 0 300.132 74.413 382.964 171.721 398V258.097h-51.688V200.21h51.688v-44.119c0-50.173 30.394-77.906 76.873-77.906 22.266 0 45.563 3.914 45.563 3.914v49.272h-25.672c-25.271 0-33.171 15.429-33.171 31.28v37.558h56.438l-9.015 57.887h-47.425v139.902c97.309-15.008 171.722-97.84 171.722-197.789l.001.001z"
          fill={invert ? WHITE : fill || FB_BLUE}
        />
        <path
          d="M282.932 257.779l9.032-57.845h-56.541v-37.53c0-15.812 7.884-31.257 33.231-31.257h25.72V81.911S271.035 78 248.727 78c-46.564 0-77.014 27.685-77.014 77.849v44.087H119.93v57.845h51.783v139.801A208.855 208.855 0 00203.568 400c10.838 0 21.476-.845 31.855-2.418V257.781h47.51l-.001-.002z"
          fill={invert ? FB_BLUE : fill ? 'transparent' : WHITE}
        />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill={Theme.color.text.inverse.default} d="M0 0h407.035v400H0z" />
        </clipPath>
      </defs>
    </svg>
  )
}

export default SvgComponent

export { FB_BLUE }
