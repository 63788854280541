import { AxiosResponse } from 'axios'
import { AuthUserContactType, MpxUser, UserId } from './MpxUser'

export interface RegisterVerifiedUserError {
  activity: string
  duration: string
  httpCode: number
  message: string
  source: string
}

export const CONTACT_TYPE = {
  EMAIL: 'email',
}

export const MP_REGISTER_VERIFIED_USER_SEND_CODE_PENDING =
  'MP_REGISTER_VERIFIED_USER_SEND_CODE_PENDING'
export const MP_REGISTER_VERIFIED_USER_SEND_CODE_ERROR = 'MP_REGISTER_VERIFIED_USER_SEND_CODE_ERROR'
export const MP_REGISTER_VERIFIED_USER_SEND_CODE_SUCCESS =
  'MP_REGISTER_VERIFIED_USER_SEND_CODE_SUCCESS'

export interface RegisterVerifedUserSendCodeQuery {
  formAuthToken: string
  formAuthCode: string
  contactType: AuthUserContactType
  contactAddress: string
}

export interface RegisterVerifiedUserSendCodeResult {
  contactType: string
  contactAddress: string
}

export const MP_REGISTER_VERIFIED_USER_CREATE_PENDING = 'MP_REGISTER_VERIFIED_USER_CREATE_PENDING'
export const MP_REGISTER_VERIFIED_USER_CREATE_ERROR = 'MP_REGISTER_VERIFIED_USER_CREATE_ERROR'
export const MP_REGISTER_VERIFIED_USER_CREATE_SUCCESS = 'MP_REGISTER_VERIFIED_USER_CREATE_SUCCESS'

export interface RegisterVerifedUserCreateQuery extends RegisterVerifedUserSendCodeQuery {
  verifyCode: string
}

export interface RegisterVerifiedUserCreateResult extends MpxUser {}

interface RegisterVerifedUserCreatePendingAction {
  type: typeof MP_REGISTER_VERIFIED_USER_CREATE_PENDING
  query: RegisterVerifedUserCreateQuery
}

interface RegisterVerifedUserCreateErrorAction {
  type: typeof MP_REGISTER_VERIFIED_USER_CREATE_ERROR
  error: RegisterVerifiedUserError
}

interface RegisterVerifedUserCreateSuccessAction {
  type: typeof MP_REGISTER_VERIFIED_USER_CREATE_SUCCESS
  results: AxiosResponse<RegisterVerifiedUserCreateResult>
}

export type RegisterVerifiedUserCreateActions =
  | RegisterVerifedUserCreatePendingAction
  | RegisterVerifedUserCreateErrorAction
  | RegisterVerifedUserCreateSuccessAction

interface RegisterVerifedUserSendCodePendingAction {
  type: typeof MP_REGISTER_VERIFIED_USER_SEND_CODE_PENDING
}

interface RegisterVerifedUserSendCodeErrorAction {
  type: typeof MP_REGISTER_VERIFIED_USER_SEND_CODE_ERROR
  error: RegisterVerifiedUserError
}

interface RegisterVerifedUserSendCodeSuccessAction {
  type: typeof MP_REGISTER_VERIFIED_USER_SEND_CODE_SUCCESS
  results: AxiosResponse<RegisterVerifiedUserSendCodeResult>
}

export type RegisterVerifiedUserSendCodeActions =
  | RegisterVerifedUserSendCodePendingAction
  | RegisterVerifedUserSendCodeErrorAction
  | RegisterVerifedUserSendCodeSuccessAction

export const MP_REGISTER_VERIFIED_USER_SET_LOGIN_PENDING =
  'MP_REGISTER_VERIFIED_USER_SET_LOGIN_PENDING'
export const MP_REGISTER_VERIFIED_USER_SET_LOGIN_ERROR = 'MP_REGISTER_VERIFIED_USER_SET_LOGIN_ERROR'
export const MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS =
  'MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS'

export interface RegisterVerifedUserSetLoginQuery {
  formAuthToken: string
  userId: UserId
  password: string
  verifyCode: string
  xMpAuthType: 'webapp'
}

interface RegisterVerifedUserSetLoginPendingAction {
  type: typeof MP_REGISTER_VERIFIED_USER_SET_LOGIN_PENDING
  query: RegisterVerifedUserSetLoginQuery
}

interface RegisterVerifedUserSetLoginErrorAction {
  type: typeof MP_REGISTER_VERIFIED_USER_SET_LOGIN_ERROR
  error: RegisterVerifiedUserError
}

export interface RegisterVerifiedUserSetLoginResult extends MpxUser {}

interface RegisterVerifedUserSetLoginSuccessAction {
  type: typeof MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS
  results: AxiosResponse<RegisterVerifiedUserSetLoginResult>
}

export type RegisterVerifiedUserSetLoginActions =
  | RegisterVerifedUserSetLoginPendingAction
  | RegisterVerifedUserSetLoginErrorAction
  | RegisterVerifedUserSetLoginSuccessAction

export const MP_REGISTER_VERIFIED_RESET = 'MP_REGISTER_VERIFIED_RESET'
export interface RegisterVerifiedUserResetAction {
  type: typeof MP_REGISTER_VERIFIED_RESET
}

export type RegisterVerifiedUserActionTypes =
  | RegisterVerifiedUserSendCodeActions
  | RegisterVerifiedUserCreateActions
  | RegisterVerifiedUserSetLoginActions
  | RegisterVerifiedUserResetAction
