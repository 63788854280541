import dayjs from 'dayjs'
import { pathOr } from 'ramda'
import { UAParser } from 'ua-parser-js'

// import config from 'consts/envdex'
import {
  AuthCheckActionTypes,
  AuthDirectLoginActionTypes,
  AuthDirectLogoutActionTypes,
  AuthSessionActionTypes,
  AuthUserLevel,
  AuthUserPermission,
  DeviceInfo,
  MP_AUTH_CHECK_SUCCESS,
  MP_AUTH_DIRECT_LOGIN_SUCCESS,
  MP_AUTH_DIRECT_LOGOUT_SUCCESS,
  MP_AUTH_SESSION_INVALID,
  MP_AUTH_SESSION_VALID,
  MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS,
  MP_USER_INFO_ERROR,
  MP_USER_INFO_PENDING,
  MP_USER_INFO_SUCCESS,
  Persona,
  RegisterVerifiedUserSetLoginActions,
  UserId,
  UserInfoActionTypes,
  UserRole,
} from '@cibo/core'
import { setUserId } from '../../utils/analytics'

const parser = new UAParser()

export interface SessionState {
  resolved: boolean
  refreshing?: boolean
  valid?: boolean
  userId?: UserId
  authUserLevel?: AuthUserLevel
  userPermissions: AuthUserPermission[]
  publicName?: string
  email?: string
  picture?: string
  roles?: Array<UserRole>
  userJwt?: string
  devices?: DeviceInfo[]
  userAccount?: string
  userOrganization?: {
    id: string
    accountId?: string
    name: string
    color?: string
    logo?: string
    headerLogo?: string
    termsOfUse?: string[]
    contentfulOrgId?: string
  }
  hasPassword?: boolean
  persona?: Persona
}

const initialState: SessionState = {
  resolved: false,
  valid: undefined,
  refreshing: false,
  userPermissions: [], //config.DEV_PERMISSIONS,
}

export const sortIpsByDate = (a: DeviceInfo, b: DeviceInfo) => {
  return dayjs(b.captureDate).diff(dayjs(a.captureDate), 'second')
}

export const sessionReducer = (
  state = initialState,
  action:
    | AuthSessionActionTypes
    | AuthDirectLoginActionTypes
    | AuthCheckActionTypes
    | AuthDirectLoginActionTypes
    | AuthDirectLogoutActionTypes
    | RegisterVerifiedUserSetLoginActions
    | UserInfoActionTypes
) => {
  switch (action.type) {
    case MP_USER_INFO_PENDING:
      return { ...state, refreshing: true }
    case MP_AUTH_SESSION_VALID:
      return { ...state, resolved: true, valid: true }

    case MP_AUTH_SESSION_INVALID:
      setUserId(null)
      return {
        resolved: true,
        valid: false,
        userId: undefined,
        authUserLevel: undefined,
        publicName: undefined,
        email: undefined,
        picture: undefined,
        persona: undefined,
        refreshing: false,
        roles: undefined,
        userPermissions: [], //config.DEV_PERMISSIONS as AuthUserPermission[],
        userAccount: undefined,
        userOrganization: undefined,
      }

    case MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS:
      const {
        publicName,
        userId,
        authUserLevel,
        hasPassword,
        roles,
        userJwt,
        userPermissions,
        userProfileData: { contacts = [] } = {},
      } = action.results.data

      setUserId(userId)

      const emailContact = contacts.find(({ contactType }) => contactType === 'email')

      return {
        ...state,
        userId,
        authUserLevel,
        email: emailContact ? emailContact.contactAddress : undefined,
        hasPassword,
        publicName,
        roles,
        userPermissions: [...(userPermissions || [])], // ...config.DEV_PERMISSIONS],
        userJwt,
        valid: true,
        resolved: true,
      }

    case MP_USER_INFO_ERROR:
      return { ...state, refreshing: false }

    case MP_AUTH_CHECK_SUCCESS:
    case MP_USER_INFO_SUCCESS:
    case MP_AUTH_DIRECT_LOGIN_SUCCESS: {
      const {
        authUserLevel,
        hasPassword,
        persona,
        picture,
        publicName,
        roles,
        userAccount,
        userId,
        userJwt,
        userOrganization,
        userPermissions,
        userProfileData: { contacts = [], loginSources: { capturedIps = [] } = {} } = {},
      } = action.results.data

      setUserId(userId)

      const emailContact = contacts.find(({ contactType }) => contactType === 'email')

      const devices: DeviceInfo[] = []
      capturedIps.forEach(
        ({ ipAddress, userAgents, geoLocation }) =>
          userAgents &&
          userAgents.forEach((agent: string) => {
            const deviceInfo = parser.setUA(agent).getResult()
            const computedKey = `${deviceInfo.browser.name}:${deviceInfo.browser.major}:${deviceInfo.os.name}`
            if (!devices.find(({ deviceKey }) => deviceKey === computedKey)) {
              devices.push({
                captureDate: agent.split('#')[0],
                ipAddress,
                geoLocation,
                deviceInfo,
                deviceKey: computedKey,
              })
            }
          })
      )

      return {
        ...state,
        authUserLevel,
        devices: devices.sort(sortIpsByDate),
        email: emailContact ? emailContact.contactAddress : undefined,
        hasPassword,
        persona,
        picture,
        publicName,
        refreshing: false,
        resolved: true,
        roles,
        userAccount,
        userId,
        userJwt: userJwt || state.userJwt,
        userOrganization,
        userPermissions: [...(userPermissions || [])], //, ...config.DEV_PERMISSIONS],
        valid: Boolean(userId),
      }
    }

    case MP_AUTH_DIRECT_LOGOUT_SUCCESS:
      setUserId(null)
      return { ...initialState }

    default:
      return state
  }
}

export interface StoreWithSession {
  authReducer: { session: SessionState }
}

const selectBase = (state: StoreWithSession) => state.authReducer.session
export const selectAuthSessionResolved = (state: StoreWithSession) => selectBase(state).resolved
export const selectAuthSessionValid = (state: StoreWithSession) => selectBase(state).valid

export const selectAuthUserIsLoggedIn = (state: StoreWithSession) =>
  Boolean(selectAuthSessionResolved(state) && selectAuthSessionValid(state))
export const selectAuthUserLevel = (state: StoreWithSession) => selectBase(state).authUserLevel
export const selectAuthUserId = (state: StoreWithSession) => selectBase(state).userId
export const selectAuthUserPermissions = (state: StoreWithSession) =>
  selectBase(state).userPermissions || []

export const selectAuthUserEmail = (state: StoreWithSession) => selectBase(state).email
export const selectAuthUsername = (state: StoreWithSession) => selectBase(state).publicName
export const selectAuthUserPicture = (state: StoreWithSession) => selectBase(state).picture
export const selectAuthToken = (state: StoreWithSession) => selectBase(state).userJwt
export const selectIsRefreshingUserInfo = (state: StoreWithSession) => selectBase(state).refreshing
export const selectAuthLoginDevices = (state: StoreWithSession) => selectBase(state).devices
export const selectUserAccount = (state: StoreWithSession) => selectBase(state).userAccount
export const selectUserHasPassword = (state: StoreWithSession) => selectBase(state).hasPassword
export const selectUserRoles = (state: StoreWithSession) => selectBase(state).roles
export const selectUserPersona = (state: StoreWithSession) => selectBase(state).persona

export const selectIsEnterprise = (state: StoreWithSession) =>
  Boolean(selectUserAccount(state) && selectUserAccount(state) !== 'public')

export const selectOrg = (state: StoreWithSession) => selectBase(state).userOrganization
export const selectOrgColor = (state: StoreWithSession) =>
  pathOr(undefined, ['userOrganization', 'color'], selectBase(state)) // @todo: import from ui Theme.color.surface.contrast.tertiary
export const selectOrgAccountId = (state: StoreWithSession) =>
  selectBase(state).userOrganization?.accountId
export const selectOrgImage = (state: StoreWithSession) => selectBase(state).userOrganization?.logo
export const selectOrgName = (state: StoreWithSession) => selectBase(state).userOrganization?.name
export const selectOrgHeaderImage = (state: StoreWithSession) =>
  selectBase(state).userOrganization?.headerLogo
export const selectOrgTerms = (state: StoreWithSession) =>
  selectBase(state).userOrganization?.termsOfUse || []
