export const TraitIds = [
  'agronomicDataCsv',
  'amendment',
  'amendmentSummary',
  'biological',
  'biomassBurning',
  'byrAppliedSlurryOnce',
  'byrBareGround',
  'byrCoverCrops',
  'cashCrop',
  'commitment',
  'cornNitrogenApplication',
  'cottonNitrogenReduction',
  'coverCrop',
  'cropManagementData',
  'dairySoilsEnrollment',
  'enrolledOtherCarbon',
  'enrolledInUSCottonTrust',
  'description',
  'earlyAdopterTillage',
  'eqipApplicationResults',
  'eqipAutogenRequiredForms',
  'eqipCurrentPractices',
  'eqipDocuments',
  'eqipEligibilityConservationACEP',
  'eqipEligibilityConservationCRP',
  'eqipEligibilityConservationCSP',
  'eqipEligibilityFarmInfo',
  'eqipEligibilityLandControl',
  'eqipEligibilityWetlandCompliance',
  'eqipEngagementFarmInfo',
  'eqipFarmInformation',
  'eqipFsaForms',
  'eqipGrowerDetails',
  'eqipHasFarmInfo',
  'eqipNrcsPermission',
  'eqipOptionalForms',
  'eqipPLUId',
  'eqipPracticeChoiceAgroforestry',
  'eqipPracticeChoiceEngineeringLandErosionStructures',
  'eqipPracticeChoiceEngineeringWaterControlStructures',
  'eqipPracticeChoiceSoil',
  'eqipPracticeChoiceWildlife',
  'eqipPracticeSelectionConfirmation',
  'eqipPracticesFlooding',
  'eqipPracticesIrrigationMethod',
  'eqipPracticesIrrigationQuantity',
  'eqipPracticesNaturalMoisture',
  'eqipPracticesSeeps',
  'eqipPracticesSnowDrifts',
  'eqipPracticesSoilCompaction',
  'eqipPracticesSoilCover',
  'eqipPracticesSoilErosionClassicalGully',
  'eqipPracticesSoilErosionEphemeralGully',
  'eqipPracticesSoilSalinity',
  'eqipPracticesSoilSubsidence',
  'eqipPracticesWaterTable',
  'eqipRecommendedPracticesIntent',
  'eqipRequiredForms',
  'eqipReviewRecommendationResults',
  'eqipSelectedPractices',
  'eventIrrigation',
  'farmManagementSystem',
  'fertilizer',
  'fertilizerReduction',
  'fmsSystem',
  'futureCashCrop',
  'futureCoverCrop',
  'futureLivestockChange',
  'futureTillage',
  'geometry',
  'grazing',
  'includesWetlandDesignation',
  'irrigation',
  'liming',
  'livestock',
  'lolFarmId',
  'name',
  'nativeVegetationConversion',
  'nativeVegetationConversionDate',
  'naturalDisturbance',
  'offer',
  'onFarmDairyFeed',
  'ownership',
  'practiceSelectionIntentFromPoints',
  'proofOfLandControl',
  'productAppliedPerPlan',
  'shipmentDistance',
  'summaryFootprint',
  'tillage',
  'usdaAgiThreshold',
  'usdaFederalProgramEnrollment',
  'usdaGrowerDetails',
  'usdaEarlyAdopter',
  'usdaGrowerType',
  'usdaUnderservedSmall',
  'waterPumpIrrigation',
  'xtremeAgCode',
] as const

export type TraitId = typeof TraitIds[number]

export type Trait = {
  fields: {
    traitId: TraitId
    description: string
  }
}

export interface TraitStatus {
  traitId: TraitId
  missing: boolean
  failing: boolean
  missingOptional: boolean
}

// This was from v1 where we didn't have task order
export const TRAIT_ORDER = [
  'nativeVegetationConversionDate',
  'includesWetlandDesignation',
  'ownership',
  'farmManagementSystem',

  'biomassBurning',
  'livestock',
  'grazingEvents',
  'cashCrop',
  'coverCrop',
  'tillage',
  'fertilizer',
  'amendment',
  'irrigation',
  'agronomicDataCsv',

  'futureCashCrop',
  'futureCoverCrop',
  'futureTillage',
  'fertilizerReduction',
  'futureLivestockChange',
] as TraitId[]
export const ORDER_BY_TRAIT_ID: Record<TraitId, number> = TRAIT_ORDER.reduce(
  (acc, item, index) => ({ ...acc, [item]: index }),
  {} as Record<TraitId, number>
)
