import { RDGrazing } from '@cibo/core'
import { useTranslation } from 'react-i18next'
import { DetailSimpleCellProps } from '../types'

export const GrazingSimpleCell = ({ detail }: DetailSimpleCellProps<RDGrazing>) => {
  const { t } = useTranslation('@cibo/landmanager/GrazingEditor')

  return (
    <>
      {!detail?.input?.events.length
        ? '--'
        : detail?.input?.events.map(({ species }) => t(`species_${species}`)).join(', ')}
    </>
  )
}
