import {
  RDAmendmentSummary,
  RDAmendmentSummaryApplicationType,
  RDAmendmentSummaryUnit,
  TraitId,
} from '@cibo/core'
import { pathEq } from 'ramda'
import { boolean, number, object, string } from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { AmendmentSummaryCellDisplay } from './AmendmentSummaryCellDisplay'
import { AmendmentSummaryEditor } from './AmendmentSummaryEditor'
import { AmendmentSummaryRollup } from './AmendmentSummaryRollup'

const TRAIT_ID: TraitId = 'amendmentSummary'

export const AmendmentSummary: ITraitFeatureByIdYear<RDAmendmentSummary> = {
  traitId: TRAIT_ID,
  cellDisplay: AmendmentSummaryCellDisplay,
  editor: AmendmentSummaryEditor,

  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: AmendmentSummaryRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          applicationType: 'not_available',
          rate: 0,
        },
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },

  initialValues: ({ fieldModels, year }) =>
    (findInitialValuesDetail(TRAIT_ID)({ fieldModels, year })?.input ?? {
      compost: false,
      manure: false,
      biochar: false,
    }) as RDAmendmentSummary['input'],
  ns: '@cibo/landmanager/AmendmentSummary',
  validationSchema: ({ t }) =>
    object()
      .shape({
        applicationType: string<RDAmendmentSummaryApplicationType>()
          .required(t('required_applicationType'))
          .typeError(t('required_applicationType')),
        rate: number().when('applicationType', {
          is: 'not_available',
          then: number().nullable(),
          otherwise: number()
            .min(0.01, t('min_rate'))
            .required(t('required_rate'))
            .typeError(t('required_rate')),
        }),
        units: string<RDAmendmentSummaryUnit | undefined>().when('applicationType', {
          is: 'not_available',
          then: string().nullable(),
          otherwise: string<RDAmendmentSummaryUnit>()
            .transform((curr, orig) => (orig === '' ? null : curr))
            .required(t('required_units'))
            .typeError(t('required_units')),
        }),
        compost: boolean().required(t('required_compost')),
        manure: boolean().required(t('required_manure')),
        biochar: boolean().required(t('required_biochar')),
      })
      .required(t('required')),
}
