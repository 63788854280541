import { Button, createTheme, ThemeProvider } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { BASE_THEME } from '@cibo/ui'
import FacebookIcon from '@mui/icons-material/Facebook'

import { FB_BLUE } from './FacebookIcon'

const FB_THEME = createTheme({
  palette: {
    primary: {
      main: FB_BLUE,
    },
  },
  ...BASE_THEME,
})

export default (props: { onPress(): void }) => {
  const { t } = useTranslation('auth0')

  return (
    <ThemeProvider theme={FB_THEME}>
      <Button
        data-testid="auth0_facebook_button"
        onClick={props.onPress}
        size="large"
        startIcon={<FacebookIcon />}
        variant="contained"
      >
        {t('facebookButton')}
      </Button>
    </ThemeProvider>
  )
}
