import { FieldModel } from '@cibo/core'
import create, { StateCreator } from 'zustand'

export type FieldsPerspective = 'carbon' | 'program' | 'practice' | 'status'

export interface InstanceOptions {
  perspective?: FieldsPerspective
  query?: any
}

interface FieldTableSlice {
  instanceOptions: Record<string, InstanceOptions>
  setInstanceOptions: (id: string, options: InstanceOptions) => void
  //column configurations?
}

const createFieldSlice: StateCreator<FieldTableSlice> = set => ({
  instanceOptions: {},
  setInstanceOptions: (id: string, options: InstanceOptions) =>
    set(state => ({ instanceOptions: { ...state.instanceOptions, [id]: options } })),
})

export type FieldManagerViewMode = 'map' | 'list'
interface FieldManagerSlice {
  viewMode: FieldManagerViewMode
  setViewMode: (viewMode: FieldManagerViewMode) => void
}

const createFieldManagerSlice: StateCreator<FieldManagerSlice> = set => ({
  viewMode: 'list',
  setViewMode: (viewMode: FieldManagerViewMode) => set({ viewMode }),
})
interface FieldManagerMapSlice {
  bounds: number[][] | undefined
  setBounds: (bounds: number[][]) => void
}

const createFieldManagerMapSlice: StateCreator<FieldManagerMapSlice> = set => ({
  bounds: undefined,
  setBounds: (bounds: number[][]) => set({ bounds }),
})

export interface FieldsetSlice {
  fieldsetFields?: FieldModel[]
  addFieldsetField: (field: FieldModel) => void
  removeFieldsetField: (fieldId: string) => void
  setFieldsetFields: (fieldsets: FieldModel[]) => void
  clearFieldsetFields: () => void
  fieldsetMode: 'add' | 'edit'
  setFieldsetMode: (mode: 'add' | 'edit') => void
  replaceFieldsetField: (newField: FieldModel) => void
}

const createFieldsetSlice: StateCreator<FieldsetSlice> = set => ({
  fieldsetFields: undefined,
  addFieldsetField: (field: FieldModel) =>
    set(state => ({
      fieldsetFields: [...(state.fieldsetFields ? state.fieldsetFields : []), field],
    })),
  removeFieldsetField: (fieldId: string) =>
    set(state => ({ fieldsetFields: state.fieldsetFields?.filter(field => field.id !== fieldId) })),
  setFieldsetFields: (fieldsetFields: FieldModel[]) => set({ fieldsetFields }),
  clearFieldsetFields: () => set({ fieldsetFields: undefined }),
  fieldsetMode: 'add',
  setFieldsetMode: (newMode: 'add' | 'edit') => set(state => ({ ...state, fieldsetMode: newMode })),
  replaceFieldsetField: (newField: FieldModel) =>
    set(state => {
      const index =
        state.fieldsetFields && state.fieldsetFields.findIndex(field => field.id === newField.id)
      const newFieldset = state.fieldsetFields || []
      if (index && index !== -1) {
        newFieldset[index] = newField
      }
      return { fieldsetFields: newFieldset }
    }),
})

export const useFieldStore = create<
  FieldTableSlice & FieldManagerSlice & FieldsetSlice & FieldManagerMapSlice
>((...a) => ({
  ...createFieldSlice(...a),
  ...createFieldManagerSlice(...a),
  ...createFieldsetSlice(...a),
  ...createFieldManagerMapSlice(...a),
}))
