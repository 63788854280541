import { ResourceDetail } from './ResourceDetail'

export const LIVESTOCK_CHANGE_TYPE = ['increase', 'decrease', 'none'] as const
export type LivestockChange = typeof LIVESTOCK_CHANGE_TYPE[number]

export interface RDFutureLivestockChange extends ResourceDetail {
  traitId: 'futureLivestockChange'
  result: LivestockChange
  input:
    | {
        livestockChange: 'increase' | 'decrease'
        headcountPerAcre: number
      }
    | {
        livestockChange: 'none'
      }
}
