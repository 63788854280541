import * as Yup from 'yup'
import { ITraitFeatureById, InitialValuesRequest } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { ByrAppliedSlurryOnceCell } from './ByrAppliedSlurryOnceCell'
import { ByrAppliedSlurryOnceEditor } from './ByrAppliedSlurryOnceEditor'
import { RDByrAppliedSlurryOnce } from './types'

const TRAIT_ID = 'byrAppliedSlurryOnce'

export const ByrAppliedSlurryOnce: ITraitFeatureById<RDByrAppliedSlurryOnce> = {
  rollups: {},
  forbidBulkEdit: false,
  traitId: TRAIT_ID,
  editor: ByrAppliedSlurryOnceEditor,
  cellDisplay: ByrAppliedSlurryOnceCell,
  ns: '@cibo/landmanager/ByrAppliedSlurryOnce',
  validationSchema: ({ requirement, t }) =>
    Yup.object({
      applied: Yup.boolean().required(t('required')),
      isSlurry: Yup.boolean().when('applied', {
        is: values => !values,
        then: Yup.boolean().nullable(),
        otherwise: Yup.boolean().required(t('required')),
      }),
    }).required(t('required')),
  initialValues: (request: InitialValuesRequest) => {
    const detail = findInitialValuesDetail<RDByrAppliedSlurryOnce>('byrAppliedSlurryOnce')(request)

    if (detail?.input) {
      return detail.input
    }

    return {
      applied: undefined,
    } as unknown as RDByrAppliedSlurryOnce['input']
  },
}
