import { Feature, MultiPolygon, Point, Polygon } from 'geojson'
import { CarbonFootprintResult } from '../types/CarbonFootprintResult'
import { UserId } from './AuthTypes'
import { AbbreviatedCarbonComparisonResult } from './CarbonLabTypes'
import { CollectionEntry, LandProfile } from './LandProfile'
import { UserMessage } from './Messages'
import { MpxResponseBase } from './MpxTypes'

export interface CollectionsFetchRequest {
  collectionIds?: string[]
  depth?: number
  fieldsOnly?: boolean
  space?: 'account' | 'public' | 'stage' | 'user'
  topLevel?: boolean
}

export interface CollectionEntryRequest {
  type: 'parcel'
  refItemId: string
}

export enum CollectionType {
  FIELD = 'field',
  PORTFOLIO = 'portfolio',
}

export interface CollectionValues {
  type: CollectionType
  name: string
  description?: string
  color?: string
  operator?: string
}

export type PortfolioValues = {
  name: string
  description?: string
  color?: string
}

export type CollectionSpace = 'public' | 'user' | 'account' | 'stage'
export type CollectionSpaceType = 'private' | 'account'
export type CollectionAction = 'edit_meta' | 'delete' | 'create_child' | 'carbon_lab'
export interface CollectionAncestor {
  resourceId: string
  name?: string
}

export type BoundaryType = 'specified' | 'science' | 'legal'
export type BoundaryPermission = 'edit' | 'delete'

export interface BoundarySummary {
  boundaryType: BoundaryType
  createdAt: string
  id: number
  source: string
  updatedAt: string
}

export interface Boundary {
  createdAt: string
  id: string
  updatedAt: string
  userId: UserId
  legal: BoundarySummary
  parcels: string[]
  permissions: BoundaryPermission[]
  science: BoundarySummary
  specified: BoundarySummary
}

export interface Collection {
  id: string
  isNew?: boolean
  type: CollectionType
  name: string
  description?: string
  color?: string
  boundaries: Record<string, Boundary>
  parcelCount: number
  geometrySlug: string
  operator?: string
  entries: CollectionEntry[]
  createdDate: string
  modifiedDate: string
  tillableGeometrySlug: string
  _meta: {
    landProfile: LandProfile
    allowedActions: CollectionAction[]
    descendants: {
      total: number
      totalFields?: number
      totalParcels?: number
    }
    lineages: Array<CollectionAncestor[]>
    parents: CollectionAncestor[]
    messages?: UserMessage[]
    ciboResults?: {
      carbonComparison?: AbbreviatedCarbonComparisonResult[]
      carbonFootprint?: CarbonFootprintResult[]
    }
  }
  space: CollectionSpace
  spaceType: CollectionSpaceType
  isTopLevel?: boolean
  // @deprecated
  inventory?: any[]
  thumbnail?: string
  userId?: UserId
  userProperties?: any
}

export interface EnrolledCollection extends Collection {
  // @deprecated
  inventory: any[]
}

export interface AggregatedLandProfileResult {
  data: { aggregatedLandProfile: LandProfile; parcelLandProfiles: LandProfile[] }
}

export interface CollectionFetchAllResult extends MpxResponseBase {
  data: {
    items: Collection[]
  }
}
export interface CollectionCreateResult extends MpxResponseBase {
  data: any
}
export interface CollectionUpdateResult extends MpxResponseBase {
  data: any
}
export interface CollectionSplitGeometryResult extends MpxResponseBase {
  data: any
}
export interface CollectionSetGeometryResult extends MpxResponseBase {
  data: any
}
export interface CollectionDeleteResult extends MpxResponseBase {
  data: any
}

export type CollectionSearchQuery = {
  hasAncestor?: string
  lowerLeft?: string // lon,lat
  upperRight?: string // lon,lat

  screenShare?: number // proportion of viewport size that switches on shapes
  // For example, if the viewport width is 1deg and we send screenShare=.1,
  // then a field with geometry .1deg wide would appear as a Polygon whereas
  // another field with width .09deg would appear as a Point in the response.

  clusteringType?: 'geoHashGrid' | 'binSplitting'
  clusterThreshold?: number
}

export type CollectionSearchResultProperties = {
  name: string
  resourceId: string
}

export type CollectionClusterSearchResultProperties = {
  featureId: number
  fieldsInBin: number
  boundingBox: number[][]
  location: [number, number]
}

export type CollectionSearchResponse = {
  fieldResults: Array<Feature<Point | Polygon | MultiPolygon, CollectionSearchResultProperties>>
  fieldClusters: Array<Feature<Point, CollectionClusterSearchResultProperties>>
}
