import { FieldModel, ResourceConcernComponents } from '@cibo/core'
import { ListCell } from '@cibo/ui'
import { GridCellParams } from '@mui/x-data-grid-pro'

export const ResourceConcernsCell = (
  params: GridCellParams<FieldModel, ResourceConcernComponents[]>
) => {
  return (
    <ListCell<ResourceConcernComponents>
      isPending={false}
      listItems={params.value}
      accessor={(practice: ResourceConcernComponents) => practice.resourceConcernDesc}
    />
  )
}
