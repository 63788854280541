import { RDEqipPracticeSelectionConfirmation } from '@cibo/core'
import { useFieldColumns } from '@cibo/landmanager/src/components/FieldColumns'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Stack, Typography, useTheme } from '@mui/material'
import { DataGridPro, GridColDef } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ResourceConcernsCell } from '../../features/ResourceDetailFeatures/EQIP/RDEqipReviewRecommendationResultsResourceDetailFeature/ResourceConcernsCell'
import { useEqipEngagementFieldsWithConcerns } from '../../queries/useEqipRecommendations'
import { PracticesSelectedCell } from './PracticesSelectedCell'

export const PracticeSelectionConfirmation = ({
  engagementId,
  ownerName,
  userRole,
}: ResourceDetailFeatureTaskEditorProps<RDEqipPracticeSelectionConfirmation>) => {
  const { t } = useTranslation('@cibo/programs/PracticeSelectionConfirmation')
  const { palette } = useTheme()
  const { fieldNameColumn } = useFieldColumns()

  const fieldsWithConcerns = useEqipEngagementFieldsWithConcerns(engagementId)
  const rows = fieldsWithConcerns.data ?? []

  const columns = useMemo<GridColDef[]>(
    () => [
      fieldNameColumn,
      {
        field: 'selectedPractices',
        headerName: t('practicesSelected'),
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: PracticesSelectedCell,
      },
      {
        field: 'resourceConcerns',
        headerName: t('resourceConcern'),
        flex: 1,
        sortable: false,
        filterable: false,
        renderCell: ResourceConcernsCell,
      },
    ],
    []
  )

  return (
    <Stack>
      <Typography variant="h6" component="h2">
        {t('title', {
          context: userRole,
          name: ownerName,
        })}
      </Typography>
      <Markdown
        typographyProps={{ variant: 'body1' }}
        //@ts-ignore adding color in typographyProps is not working
        sx={{
          color: palette.text.secondary,
        }}
      >
        {t('subtitle', {
          context: userRole,
          name: ownerName,
        })}
      </Markdown>
      <DataGridPro
        rows={rows}
        getRowHeight={() => 'auto'}
        columns={columns}
        loading={fieldsWithConcerns.isPending}
        autoHeight
      />
    </Stack>
  )
}
