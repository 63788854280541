import { TILLAGE_TYPE } from '@cibo/core'
import { AutocompleteField } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const TillageResultsOnlyEditor = ({
  name = 'tillageEditor',
  year,
  fieldModels,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/TillageEditor')

  return (
    <FormikField name={name} id={name}>
      {() => {
        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { count: fieldModels.length })}</Typography>
            <Stack>
              <AutocompleteField
                name={`${name}`}
                options={TILLAGE_TYPE}
                getOptionLabel={(option: string) => t(option)}
                data-testid={`${name}`}
                selectOnFocus
              />
            </Stack>
          </Stack>
        )
      }}
    </FormikField>
  )
}
