import { RDEqipPracticesSoilErosionClassicalGully } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesSoilErosionClassicalGullyResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSoilErosionClassicalGully> =
  {
    traitId: 'eqipPracticesSoilErosionClassicalGully',
    TaskEditor: (
      props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSoilErosionClassicalGully>
    ) => (
      <BooleanRollup<RDEqipPracticesSoilErosionClassicalGully>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } }}
        detailKey="classicalGully"
        ns="@cibo/landmanager/RDEqipPracticesSoilErosionClassicalGullyResourceDetailFeature"
        {...props}
      />
    ),
  }
