import { Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { LimingEvent, RDLiming } from '@cibo/core'

import { TraitCellDisplayProps } from '../types'

export const LimingCell = ({ traitId, year, params }: TraitCellDisplayProps<RDLiming>) => {
  const { t } = useTranslation('@cibo/landmanager/LimingEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.events?.length) {
    return (
      <Tooltip
        title={
          <Stack spacing={1} padding={1}>
            {detail.input?.events.map(({ date, amount, unit }: LimingEvent, index: number) => (
              <Stack key={index}>
                <Typography variant="body1">{t('eventDate', { date: new Date(date) })}</Typography>
                <Typography variant="body2" sx={{ color: 'default' }}>
                  {amount} - {t(unit)}
                </Typography>
              </Stack>
            ))}
          </Stack>
        }
      >
        <Typography variant="body1">
          {t('events', { count: detail.input?.events?.length })}
        </Typography>
      </Tooltip>
    )
  }

  return <Typography>{t('none')}</Typography>
}
