import { IrrigationSchedule, IrrigationType, IrrigationUnits, RDIrrigation } from '@cibo/core'
import { pathEq } from 'ramda'
import { number, object, string } from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { isDetailRequired } from '../useIsRequired'
import { findInitialValuesDetail } from '../utils'
import { IrrigationCell } from './IrrigationCell'
import { IrrigationCellEditor } from './IrrigationCellEditor'
import { IrrigationEditor } from './IrrigationEditor'
import { IrrigationRollup } from './IrrigationRollup'
import { IrrigationSimpleCell } from './IrrigationSimpleCell'

const TRAIT_ID = 'irrigation'

export const IrrigationInput: ITraitFeatureByIdYear<RDIrrigation> = {
  traitId: TRAIT_ID,
  cellDisplay: IrrigationCell,
  SimpleCellView: IrrigationSimpleCell,
  editor: IrrigationEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: IrrigationRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        result: false,
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  ns: '@cibo/landmanager/IrrigationEditor',
  // If any inputs are set, all required inputs must be set, otherwise everything is
  // treated as optional. To indicate no irrigation, send {}
  validationSchema: ({ requirement, t }) =>
    object().shape(
      {
        schedule: string<IrrigationSchedule>().when(
          ['rate', 'irrigationType', 'totalAcreInches', 'units'],
          {
            is: (rate, irrigationType, totalAcreInches, units) =>
              !rate && !irrigationType && !totalAcreInches && !units,
            then: (schema: any) => schema.optional(),
            otherwise: isDetailRequired({ requirement, fieldName: 'schedule' })
              ? (schema: any) =>
                  schema
                    .required(t('required', { context: 'schedule' }))
                    .typeError(t('required', { context: 'schedule' }))
              : (schema: any) => schema.optional(),
          }
        ),
        irrigationType: string<IrrigationType>()
          .ensure()
          .when(['schedule', 'rate', 'totalAcreInches', 'units'], {
            is: (schedule, rate, totalAcreInches, units) =>
              !schedule && !rate && !totalAcreInches && !units,
            then: (schema: any) => schema.nullable(),
            otherwise: isDetailRequired({ requirement, fieldName: 'irrigationType' })
              ? (schema: any) =>
                  schema
                    .required(t('required_irrigationType'))
                    .typeError(t('required', { context: 'irrigationType' }))
              : (schema: any) => schema.nullable(),
          }),
        totalAcreInches: number().when(['schedule', 'rate', 'irrigationType', 'units'], {
          is: (schedule, rate, irrigationType, units) =>
            !schedule && !rate && !irrigationType && !units,
          then: (schema: any) => schema.nullable(),
          otherwise: isDetailRequired({ requirement, fieldName: 'totalAcreInches' })
            ? (schema: any) => schema.required(t('required_totalAcreInches'))
            : (schema: any) => schema.nullable(),
        }),
        rate: number().when(['schedule', 'irrigationType', 'totalAcreInches', 'units'], {
          is: (schedule, irrigationType, totalAcreInches, units) =>
            !schedule && !irrigationType && !totalAcreInches && !units,
          then: (schema: any) => schema.optional(),
          otherwise:
            isDetailRequired({ requirement, fieldName: 'rate' }) ||
            isDetailRequired({ requirement, fieldName: 'units' })
              ? (schema: any) => schema.required(t('required_rate'))
              : (schema: any) => schema.optional(),
        }),
        units: string<IrrigationUnits>().when(
          ['schedule', 'rate', 'irrigationType', 'totalAcreInches'],
          {
            is: (schedule, rate, irrigationType, totalAcreInches) =>
              !schedule && !rate && !irrigationType && !totalAcreInches,
            then: (schema: any) => schema.nullable(),
            otherwise:
              isDetailRequired({ requirement, fieldName: 'units' }) ||
              isDetailRequired({ requirement, fieldName: 'rate' })
                ? (schema: any) =>
                    schema
                      .required(t('required_units'))
                      .typeError(t('required', { context: 'units' }))
                : (schema: any) => schema.nullable(),
          }
        ),
      },
      // because validation of all of the fields relies on all other fields, the dependency array is "everything"
      [
        ['schedule', 'rate'],
        ['schedule', 'irrigationType'],
        ['schedule', 'totalAcreInches'],
        ['schedule', 'units'],
        ['rate', 'irrigationType'],
        ['rate', 'totalAcreInches'],
        ['rate', 'units'],
        ['irrigationType', 'totalAcreInches'],
        ['irrigationType', 'units'],
        ['totalAcreInches', 'units'],
      ]
    ),

  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)

    if (!detail?.result) {
      return {
        schedule: '',
        irrigationType: '',
        rate: '',
        units: '',
        totalAcreInches: '',
      }
    }

    return detail?.result === false ? {} : detail.input
  },
}

export const IrrigationResult: ITraitFeatureByIdYear<RDIrrigation> = {
  traitId: TRAIT_ID,
  cellDisplay: IrrigationCell,
  editor: IrrigationEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: IrrigationRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        result: false,
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  cellEditor: IrrigationCellEditor,
  ns: '@cibo/landmanager/IrrigationEditor',
  // @ts-ignore this isn't used for -Result right?
  validationSchema: () => null,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)

    return detail?.result
  },
}
