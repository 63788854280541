import { DetailRequirement, FieldModel, TraitId } from '@cibo/core'
import { flatten } from 'ramda'
import { DetailStatus, QuestionRollup } from './types'

export const getDetailStatuses = (
  traitId: TraitId,
  fieldModels: FieldModel[],
  years?: number[]
) => {
  const detailSpecs: DetailRequirement[] = years?.length
    ? years.map(year => ({ traitId, year, dataType: 'field' }))
    : [{ traitId, dataType: 'field' }]

  const detailStatuses: Omit<DetailStatus, 'matchesRollup'>[] = flatten(
    fieldModels.map(model =>
      detailSpecs.map(({ traitId, year }) => {
        const detail = model.resolveStandingDetail(traitId, year)
        return {
          resourceId: model.resourceId,
          traitId,
          year,
          detail,
        }
      })
    )
  )
  return detailStatuses
}

export const getDetailRollupStatuses = (
  rollup: QuestionRollup,
  fieldModels: FieldModel[],
  years?: number[]
) => {
  const { traitId } = rollup

  const detailStatuses = getDetailStatuses(traitId, fieldModels, years)

  return detailStatuses.map(status => ({
    ...status,
    matchesRollup: rollup.isRollupValue(status.detail),
  }))
}
