import { RDEqipPracticesSoilSubsidence } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesSoilSubsidenceResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSoilSubsidence> =
  {
    traitId: 'eqipPracticesIrrigationMethod',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSoilSubsidence>) => (
      <BooleanRollup<RDEqipPracticesSoilSubsidence>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } }}
        detailKey="soilSubsidence"
        ns="@cibo/landmanager/RDEqipPracticesSoilSubsidenceResourceDetailFeature"
        {...props}
      />
    ),
  }
