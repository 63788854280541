import { RDTillage } from '@cibo/core'

import * as Yup from 'yup'
import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { TillageEventValidationSchema } from './schema'
import { TillageCell } from './TillageCell'
import { TillageCellEditor } from './TillageCellEditor'
import { TillageEditor } from './TillageEditor'
import { TillageResultsOnlyEditor } from './TillageResultsOnlyEditor'
import { TillageSimpleCell } from './TillageSimpleCell'
import { TillageTableCta } from './TillageTableCta'

const TRAIT_ID = 'tillage'

export const TillageInput: ITraitFeatureByIdYear<RDTillage> = {
  traitId: TRAIT_ID,
  cellDisplay: TillageCell,
  SimpleCellView: TillageSimpleCell,
  editor: TillageEditor,
  tableCta: TillageTableCta,
  ns: '@cibo/landmanager/TillageEditor',
  validationSchema: eventValidationSchema<RDTillage['input']>(props =>
    TillageEventValidationSchema(props)
  ),

  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
}

export const TillageResult: ITraitFeatureByIdYear<RDTillage> = {
  traitId: TRAIT_ID,
  editor: TillageResultsOnlyEditor,
  cellDisplay: TillageCell,
  SimpleCellView: TillageSimpleCell,
  cellEditor: TillageCellEditor,
  tableCta: TillageTableCta,

  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.result || ''
  },
  ns: '@cibo/landmanager/TillageEditor',
  // @ts-ignore not used - we should make optional or disallow for result traits
  validationSchema: () => Yup.object().nullable(),

  detailFromEditorValues: (formValues, requirement) => {
    return {
      traitId: TRAIT_ID,
      year: requirement?.year,
      result: formValues.input,
    }
  },
}
