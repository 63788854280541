import { RDCashCropSpecies, RDFutureCashCrop } from '@cibo/core'
import { object, string } from 'yup'

import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FutureCashCropCell } from './FutureCashCropCell'
import { FutureCashCropCellEditor } from './FutureCashCropCellEditor'
import { FutureCashCropEditor } from './FutureCashCropEditor'

const TRAIT_ID = 'futureCashCrop'

const FutureCashCropBase: ITraitFeatureByIdYear<RDFutureCashCrop> = {
  traitId: TRAIT_ID,
  editor: FutureCashCropEditor,
  cellDisplay: FutureCashCropCell,
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail ? detail.input ?? { crop: detail.result } : {}
  },
  ns: '@cibo/landmanager/FutureCashCropEditor',
  validationSchema: ({ t }) =>
    object()
      .shape({
        crop: string<RDCashCropSpecies>()
          .required(t('required', { context: 'crop' }))
          .typeError(t('required', { context: 'crop' })),
      })
      .required(t('required')),
}

export const FutureCashCropInput = {
  ...FutureCashCropBase,
}

export const FutureCashCropResult = {
  ...FutureCashCropBase,
  cellEditor: FutureCashCropCellEditor,
}
