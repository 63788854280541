import { RDEqipPracticesSoilCompaction } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesSoilCompactionResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSoilCompaction> =
  {
    traitId: 'eqipPracticesSoilCompaction',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSoilCompaction>) => (
      <BooleanRollup<RDEqipPracticesSoilCompaction>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } }}
        detailKey="soilCompaction"
        ns="@cibo/landmanager/RDEqipPracticesSoilCompactionResourceDetailFeature"
        {...props}
      />
    ),
  }
