import { FieldModel } from '@cibo/core'
import { GridColDef } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'
import { CoverCropScenarioDropDownMenu } from '../CoverCropScenarioDropDownMenu/CoverCropScenarioDropDownMenu'

export const fieldCoverCropScenarioSelection = ({
  t,
  overrides,
}: {
  t: TFunction
  overrides?: Partial<Omit<GridColDef<FieldModel>, 'renderCell' | 'field'>>
}) =>
  ({
    field: 'selectScenario',
    headerName: t('selectScenario', { ns: '@cibo/landmanager/FieldsSelection' }),
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: row => {
      if (!row.api.isRowSelected(row.id)) return null
      return <CoverCropScenarioDropDownMenu />
    },
    ...overrides,
  } as GridColDef<FieldModel>)
