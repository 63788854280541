import { useContext } from 'react'
import { Layer, Source } from 'react-map-gl/dist/es5'

import { Config } from '../../../../types'
import { LayerContext } from '../LayerContext'
import { MapStyles } from '../const'

export const CDL_YEARS: string[] = []
for (let i = 2008; i <= 2022; i++) {
  CDL_YEARS.push(`${i}`)
}

type Props = { year: string }

export const CDLLayer = ({ year }: Props) => {
  const { cdlYear, mapStyle } = useContext(LayerContext)
  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')

  const sourceId = `cdl_tiles_${year}`
  const visible = mapStyle === MapStyles.CDL && year === cdlYear

  return (
    <Source
      id={sourceId}
      type="raster"
      tiles={[`${maptilesServiceUrl}cdl/${year}/{z}/{y}/{x}.png`]}
      tileSize={256}
      minzoom={6}
      maxzoom={12}
    >
      <Layer
        beforeId="tunnel-street-minor-low"
        id={`${sourceId}_layer`}
        source={sourceId}
        type="raster"
        layout={{
          visibility: visible ? 'visible' : 'none',
        }}
        paint={{ 'raster-opacity': 1, 'raster-resampling': 'nearest' }}
        maxzoom={22}
      />
    </Source>
  )
}
