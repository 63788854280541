import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Collapse, FormControl, FormHelperText, FormLabel, Stack, Typography } from '@mui/material'
import { Field, Formik, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import { equals, omit } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Loading } from '../../../../components'
import { useAuth, useUpdateUserDetails, useUserDetails } from '../../../../hooks'
import { useOrgUser } from '../../../../queries'
import { filterEmptyStrings } from './filterEmptyStrings'
import { UsdaGrowerDetails } from './types'
import { UsdaMailingAddressWidget } from './UsdaMailingAddressWidget/UsdaMailingAddressWidget'

/**
 * Assumptions:
 * 1. you will only pass a single resourceId in the resourceIds array
 * 2. there will only ever be a single detail for a given resourceId
 */
export const UsdaGrowerDetailsTaskEditorForm = ({
  detailRequirements,
  resourceIds: [resourceId],
  onUpdating,
  onError,
  onSuccess,
}: ResourceDetailFeatureTaskEditorProps<UsdaGrowerDetails>) => {
  const { t } = useTranslation('@cibo/profile/UsdaGrowerDetailsTaskEditor')
  const details = useUserDetails({ resourceId, detailRequirements })
  const updateUserDetails = useUpdateUserDetails<UsdaGrowerDetails>()
  const { userId } = useAuth()
  const context = resourceId === userId?.toString() ? 'participant' : 'manager'
  const detail = details?.data?.[0]

  const { values, isValid, dirty } = useFormikContext<any>()

  const nonAddressValues = detail?.value && omit(['mailingAddress'], detail?.value)

  useEffect(() => {
    const filtered: any = filterEmptyStrings(values as object)

    if (!isValid || equals(nonAddressValues, values)) return

    if (updateUserDetails.isPending) {
      updateUserDetails.reset()
    }

    const mailingAddress = (detail as unknown as UsdaGrowerDetails)?.value?.mailingAddress

    onUpdating?.()
    updateUserDetails
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'usdaGrowerDetails',
            year: detailRequirements[0].year,
            value: { ...filtered, mailingAddress },
          } as UsdaGrowerDetails,
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }, [values, isValid, dirty, detail])

  const disabled = details.data?.[0]?.immutable || details?.isPending

  return (
    <Stack spacing={2}>
      <Stack>
        <Typography variant="h6">{t('title')}</Typography>
        <Typography variant="caption">{t('cta')}</Typography>
      </Stack>

      <Stack spacing={1}>
        <FormLabel required>{t('fullNameCta', { context })}</FormLabel>

        <FormControl
          component="fieldset"
          variant="standard"
          error={updateUserDetails.isError}
          disabled={disabled}
        >
          <Stack direction="row" spacing={2} style={{ width: '100%' }}>
            <Field
              id="firstName"
              name="firstName"
              component={TextField}
              InputProps={{
                inputProps: {
                  'data-testid': 'firstName',
                  autoComplete: 'section-basics given-name',
                },
              }}
              maxLength={255}
              placeholder={t('firstName')}
              disabled={disabled}
              fullWidth={true}
            />
            <Field
              id="lastName"
              name="lastName"
              component={TextField}
              InputProps={{
                inputProps: {
                  'data-testid': 'lastName',
                  autoComplete: 'section-basics family-name',
                },
              }}
              maxLength={255}
              placeholder={t('lastName')}
              disabled={disabled}
              fullWidth={true}
            />
          </Stack>
        </FormControl>
      </Stack>

      <Stack spacing={1}>
        <Typography>{t('telephoneNumberCta', { context })}</Typography>

        <FormControl
          component="fieldset"
          variant="standard"
          error={updateUserDetails.isError}
          disabled={disabled}
        >
          <Stack direction="row" spacing={2} style={{ width: '100%' }}>
            <Stack spacing={1} style={{ flex: 1 }}>
              <FormLabel>{t('mobile')}</FormLabel>
              <Field
                id="mobilePhone"
                name="mobilePhone"
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'mobilePhone',
                    autoComplete: 'section-basics tel mobile',
                  },
                }}
                maxLength={255}
                placeholder={t('phoneNumber')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
            <Stack spacing={1} style={{ flex: 1 }}>
              <Typography>{t('business')}</Typography>
              <Field
                id="businessPhone"
                name="businessPhone"
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'businessPhone',
                    autoComplete: 'section-basics tel work',
                  },
                }}
                maxLength={255}
                placeholder={t('phoneNumber')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Stack>
        </FormControl>
      </Stack>

      <Collapse in={updateUserDetails.isError}>
        <FormHelperText>{updateUserDetails.error?.response?.data?.message}</FormHelperText>
      </Collapse>
    </Stack>
  )
}

export const UsdaGrowerDetailsTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<UsdaGrowerDetails>
) => {
  const { t } = useTranslation('@cibo/profile/UsdaGrowerDetailsTaskEditor')
  // @ts-ignore not sure how to tell our types this detail uses value: {...}
  const details = useUserDetails({
    resourceId: props.resourceIds[0],
    detailRequirements: props.detailRequirements,
  })
  const orgUser = useOrgUser(props.resourceIds[0])

  const detail = details?.data?.[0]
  const nonAddressValues = detail?.value && omit(['mailingAddress'], detail?.value)

  const schema = useMemo(
    () =>
      yup.object({
        birthDate: yup
          .date()
          .transform(function (value, originalValue) {
            if (this.isType(value)) {
              return value
            }
            const result = new Date(value)
            return result
          })
          .typeError('please enter a valid date'),

        firstName: yup.string().min(3).required(t('required')),
        lastName: yup.string().min(3).required(t('required')),
        mobilePhone: yup.string(),
        businessPhone: yup.string(),
        mailingAddress: yup.object(),
        farmAddress: yup.object(),
      }),
    []
  )

  if (details.isPending) return <Loading />

  return (
    <Stack spacing={2}>
      <Formik<UsdaGrowerDetails>
        // @ts-ignore How to allow empty strings for these initial values?
        initialValues={
          nonAddressValues ?? {
            birthDate: '',
            firstName: orgUser.data?.user.givenName || '',
            lastName: orgUser.data?.user.familyName || '',
            mobilePhone: '',
            businessPhone: '',
          }
        }
        validateOnChange
        onSubmit={() => {}}
        validationSchema={schema}
      >
        <UsdaGrowerDetailsTaskEditorForm {...props} />
      </Formik>

      <Stack>
        <Typography variant="h6">{t('mailingAddress')}</Typography>
        <Typography variant="caption">{t('mailingAddressDescription')}</Typography>
      </Stack>

      <UsdaMailingAddressWidget {...props} />
    </Stack>
  )
}
