import { ResourceDetail, TraitId } from '@cibo/core'

export class ResourceDetailDisplay {
  traitId: TraitId
  result: any

  constructor(params: ResourceDetail) {
    this.result = params.result
    this.traitId = params.traitId
  }

  static cellLabel = (result: any, traitId: TraitId) => {
    switch (traitId) {
      default:
        return `${result}`
    }
  }
  cellLabel() {
    return ResourceDetailDisplay.cellLabel(this.result, this.traitId)
  }
}
