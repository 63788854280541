import { CardActionAreaProps, CardProps, Card as MUICard, styled } from '@mui/material'

const MAX_MUI_ELEVATION = 24
export interface HoverableCardProps extends CardProps, Pick<CardActionAreaProps, 'disabled'> {
  scale?: number
  hoverElevation?: number
}

export const HoverableCard = ({ children, ...props }: HoverableCardProps) => (
  <StyledCard {...props}>{children}</StyledCard>
)

const Card = ({ children, ...cardProps }: HoverableCardProps) => (
  <MUICard {...cardProps}>{children}</MUICard>
)

const StyledCard = styled(Card)(({ theme, disabled, elevation, hoverElevation, scale }) => ({
  transition: 'all 200ms ease',
  '&:hover': {
    boxShadow: disabled
      ? undefined
      : theme.shadows[
          hoverElevation !== undefined
            ? hoverElevation
            : elevation !== undefined
            ? Math.min(Math.max(elevation, 1) * 4, MAX_MUI_ELEVATION)
            : 8
        ],
    transform: scale ? `scale(${scale})` : undefined,
  },
}))
