import { LimingEvent, RDLiming } from '@cibo/core'
import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EventDetailEditor } from '../EventDetail'
import { DetailEditorPropsByYear } from '../types'
import { useIsRequired } from '../useIsRequired'
import { useShowDetailAttribute } from '../useShowDetailAttribute'
import { LimingEventEditor } from './LimingEventEditor'

export const LimingEditor = ({
  fieldModels,
  year,
  name = 'limingEditor',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/LimingEditor')

  const show = useShowDetailAttribute<LimingEvent>(filterInputs)
  const isRequired = useIsRequired(requirement)

  const LimingEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: '160px', padding: '6px' }}>
          <Typography>
            {t('date')}
            {isRequired('date') && '*'}
          </Typography>
        </TableCell>
        {show('type') && (
          <TableCell sx={{ paddig: '6px' }}>
            <Typography sx={{ minWidth: '100px' }}>
              {t('type')}
              {isRequired('type') && '*'}
            </Typography>
          </TableCell>
        )}
        <TableCell sx={{ paddig: '6px' }}>
          <Typography>
            {t('amount')}
            {isRequired('amount') && '*'}
          </Typography>
        </TableCell>
        <TableCell sx={{ paddig: '6px' }}>
          <Typography sx={{ minWidth: '80px' }}>
            {t('unit')}
            {isRequired('unit') && '*'}
          </Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
  return (
    <EventDetailEditor<RDLiming['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={LimingEventTableHead}
      EventComponent={LimingEventEditor}
      translationContext="liming"
      defaultEvent={{ date: null, amount: '', unit: '' }}
    />
  )
}
