import { boundsFromArray2d } from '@cibo/core'
import { MAINLAND_US_BOUNDS } from '../../../consts/Map'

const defaultBounds = boundsFromArray2d(MAINLAND_US_BOUNDS)

export const mpxSearchInitialState: any = {
  pending: false,
  query: {
    ...defaultBounds,
    fldUsage: ['-any'],
    fldHistory: ['-any'],
    tags: ['lcav'],
  },
  aggregating: undefined,
  aggregationValues: undefined,
  sampleResults: undefined,
  numSearchResults: 0,
  numResultsInBoundingBox: 0,
  numBuckets: 0,
  showingPoints: false,
  showingShapes: false,
  parcelResults: undefined,
  queryTrigger: undefined,
  resultsTrigger: undefined,
  resultsId: 0,
  selectedParcelId: undefined,
  similarParcelsError: undefined,
  similarParcelsPending: false,
  similarParcels: undefined,
  stashedSearch: undefined,
}
