import { Typography } from '@mui/material'
import { TraitCellDisplayProps } from '../types'

export const XtremeAgCodeCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      <>{detail.input}</>
    </Typography>
  )
}
