import { FieldModel, FieldsAPI } from '@cibo/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEY } from '../../../queries/queryKey'

export interface FieldDetailDeleteRequest {
  resourceId: string
  traitId: string
}

export const useDeleteFileAndDetails = () => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, any>({
    mutationFn: ({ resourceId, traitId }: FieldDetailDeleteRequest) => {
      return FieldsAPI.deleteFileDetailAndRelatedDetails(resourceId, traitId)
    },

    mutationKey: [QUERY_KEY.SAVE_DETAIL_FILE],
    onSuccess: (result: FieldModel, request: any) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.resetQueries({ queryKey: [QUERY_KEY.FIELDS, request.resourceId] })
    },
  })
}
