import { ResourceDetailYearly } from './ResourceDetail'

export const FUTURE_COVER_CROP_SPECIES = [
  'none',
  'flaxseed',
  'spinach',
  'lupin',
  'corn',
  'teff',
  'whiteClover',
  'medic',
  'sweetClover',
  'foxtailMillet',
  'berseemClover',
  'annualFescue',
  'safflower',
  'pearlMillet',
  'sudanGrass',
  'soybeans',
  'proscoMillet',
  'mustard',
  'fieldPea',
  'alfalfa',
  'squash',
  'radishes',
  'chickpeas',
  'redClover',
  'wheat',
  'carrots',
  'chicory',
  'oats',
  'cowpea',
  'vetch',
  'cerealRye',
  'triticale',
  'grainSorghum',
  'barley',
  'ryegrass',
  'phacelia',
  'amaranth',
  'sunflower',
  'canola',
  'lentils',
  'sainfoin',
  'buckwheat',
  'mungbean',
  'winterwheat',
  'turnips',
  'sugarbeets',
  'kale',
  'birdsfootTrefoil',
  'other',
] as const
export type RDFutureCoverCropSpecies = typeof FUTURE_COVER_CROP_SPECIES[number]

export interface RDFutureCoverCrop extends ResourceDetailYearly {
  traitId: 'futureCoverCrop'
  result: boolean
  input: {
    crop: RDFutureCoverCropSpecies
  }
}
