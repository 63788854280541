/* istanbul ignore file */
import { Farm } from '../types/FarmsTypes'
import { TermsetAPI } from './TermsetAPI'

const PATHS = {
  LIST: '/termset/farms',
  ENTITY: '/termset/farms/:id',
  FIELDS: '/termset/farms/linkWithResources',
  QUERY: '/termset/farms/query',
}

export const FarmsAPI = new TermsetAPI<Farm>(PATHS)
