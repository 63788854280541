import { TraitId } from '@cibo/core'
import { Markdown } from '@cibo/ui'
import { CircularProgress } from '@mui/material'
import { useTraitContent } from './useTraitContent'

type TraitContentProps = { traitId: TraitId }

export const TraitContent = ({ traitId }: TraitContentProps) => {
  const traitContent = useTraitContent(traitId)

  if (traitContent.isFetching) return <CircularProgress />

  return traitContent.data ? <Markdown>{traitContent.data}</Markdown> : null
}
