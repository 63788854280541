import { RDFertilizerEvent } from '@cibo/core'
import { useField } from 'formik'
import { useEffect, useState } from 'react'
import { EventComponentProps } from '../../EventDetail'
import { FertilizerEventDisplay } from './FertilizerEventDisplay'
import { FertilizerEventEditor } from './FertilizerEventEditor'

export const FertilizerEvent = ({
  editing,
  onEditEvent,
  filterInputs,
  year,
  name,
  onPressRemove,
  requirement,
}: EventComponentProps) => {
  // eslint-disable-next-line no-empty-pattern
  const [{}, { initialValue }, {}] = useField<RDFertilizerEvent | undefined>(name)
  const [expanded, setExpanded] = useState(!initialValue)

  useEffect(() => {
    onEditEvent(expanded)
  }, [expanded])

  const handlePressRemove = () => {
    onEditEvent(false)
    onPressRemove()
  }

  return (
    <>
      <FertilizerEventEditor
        expanded={expanded}
        filterInputs={filterInputs}
        name={name}
        onEditEvent={onEditEvent}
        onPressRemove={handlePressRemove}
        requirement={requirement}
        setExpanded={setExpanded}
        year={year}
      />
      <FertilizerEventDisplay
        editing={editing}
        expanded={!expanded}
        name={name}
        onEditEvent={onEditEvent}
        onPressRemove={handlePressRemove}
        setExpanded={setExpanded}
      />
    </>
  )
}
