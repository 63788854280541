import { useTranslation } from 'react-i18next'
import { TextField, styled, TextFieldProps } from '@mui/material'

import { useFormFactor } from '../../hooks/useWidth'

const BorderlessTextField = styled(TextField)(({ theme }) => ({
  width: '100%',
  '.MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.default,
  },
  '.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.default,
  },
  '.MuiOutlinedInput-root:hover fieldset': {
    borderColor: theme.palette.background.default,
  },
  '.MuiOutlinedInput-root.Mui-disabled .MuiOutlinedInput-notchedOutline': {
    borderColor: theme.palette.background.default,
  },
}))

export const SearchTextInput = ({ placeholder, ...rest }: TextFieldProps) => {
  const { t } = useTranslation('searchBar')
  const { isMobile } = useFormFactor()
  const placeholderText = placeholder
    ? placeholder
    : t(isMobile ? 'placeholderMobile' : 'placeholder')

  return (
    <BorderlessTextField data-testid={'search_input'} placeholder={placeholderText} {...rest} />
  )
}
