import { RDAmendment } from '@cibo/core'
import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EventDetailEditor } from '../EventDetail'
import { DetailEditorPropsByYear } from '../types'
import { AmendmentEvent } from './AmendmentEvent'

export const AmendmentEditor = ({
  fieldModels,
  year,
  name = 'amendmentEditor',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/AmendmentEditor')
  const AmendmentEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: '140px' }}>
          <Typography>{t('date')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('type')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('amount')}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
  return (
    <EventDetailEditor<RDAmendment['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={AmendmentEventTableHead}
      EventComponent={AmendmentEvent}
      translationContext="amendment"
      defaultEvent={{ date: null, amendmentType: '', method: '', rate: '', units: '' }}
    />
  )
}
