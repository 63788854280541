import { ResourceDetail } from './ResourceDetail'

export const EVENT_IRRIGATION_UNITS = ['inchesPerAcre', 'gallonsPerAcre'] as const
export type EventIrrigationUnits = typeof EVENT_IRRIGATION_UNITS[number]

export type IrrigationEvent = {
  startDate: Date
  endDate: Date
  amount: number
  unit: EventIrrigationUnits
}

export type EventIrrigationEvents = {
  events: IrrigationEvent[]
}

export interface RDEventIrrigation extends ResourceDetail {
  traitId: 'eventIrrigation'
  year: number
  result: number
  input: EventIrrigationEvents
}
