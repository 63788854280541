import { Paper, Theme, ThemeProvider } from '@mui/material'
import chroma from 'chroma-js'
import Delaunator from 'delaunator'
import { clamp } from 'ramda'
import { CSSProperties, PropsWithChildren, RefObject, useEffect, useRef } from 'react'

import { useSize } from '../../hooks/useSize'
import { THEME_DARK } from '../../theme/ThemeConfigurations'
import { generatePoints } from '../../utils/polygonGenerator'

const COLORS = ['#484848', '#8E8E8E']
const colorScale = chroma.scale(COLORS)

interface UpdatePolygonCanvasOptions {
  canvasRef: RefObject<HTMLCanvasElement>
  points: number[][]
  width: number
  height: number
  overscanX: number
  overscanY: number
  mouseX: number
  mouseY: number
}

const updateCanvas = ({
  canvasRef,
  points,
  width,
  height,
  overscanX,
  overscanY,
  mouseX,
  mouseY,
}: UpdatePolygonCanvasOptions) => {
  const ctx = canvasRef.current?.getContext('2d')
  if (!ctx) {
    return
  }

  const indices = Delaunator.from(points).triangles
  const radius = Math.sqrt(Math.pow(width, 2) + Math.pow(height, 2)) / 2

  for (let i = 0, l = indices.length; i < l; i += 3) {
    const vertexIndices = [indices[i], indices[i + 1], indices[i + 2]]
    const vertices = vertexIndices.map(i => points[i])
    const centroid = {
      x: (vertices[0][0] + vertices[1][0] + vertices[2][0]) / 3,
      y: (vertices[0][1] + vertices[1][1] + vertices[2][1]) / 3,
    }

    const distanceFromMouse = Math.sqrt(
      Math.pow(mouseX - centroid.x, 2) + Math.pow(mouseY - centroid.y, 2)
    )

    ctx.fillStyle = colorScale(1 - clamp(5, Math.max(10, radius), distanceFromMouse) / radius).css()
    ctx.beginPath()
    ctx.moveTo(vertices[0][0], vertices[0][1])
    ctx.lineTo(vertices[1][0], vertices[1][1])
    ctx.lineTo(vertices[2][0], vertices[2][1])
    ctx.lineTo(vertices[0][0], vertices[0][1])
    ctx.closePath()
    ctx.fill()
  }
}

interface PolyBackgroundProps {
  animated?: boolean
  style?: CSSProperties
  trackMouse?: boolean
}

export const PolyBackground = ({
  animated,
  children,
  style,
  trackMouse,
}: PropsWithChildren<PolyBackgroundProps>) => {
  const target = useRef<HTMLDivElement>(null)
  const size = useSize(target, 100)
  const canvasRef = useRef<HTMLCanvasElement>(null)

  useEffect(() => {
    if (!size) {
      return
    }

    const { points, overscanX, overscanY } = generatePoints({
      height: size.height,
      width: size.width,
      cellSize: 150,
      variance: 0.75,
    })

    let mouseX = size.width / 2,
      mouseY = size.height / 2
    const speed = 2

    const motions = points.map(() => ({
      dx: speed * (Math.random() - 0.5),
      dy: speed * (Math.random() - 0.5),
    }))

    const step = () => {
      points.forEach(([x, y], i) => {
        const { dx, dy } = motions[i]
        const movedPoint = [x + dx, y + dy]

        if (movedPoint[0] < -overscanX || movedPoint[0] > size.width + overscanX) {
          motions[i].dx *= -1
        }

        if (movedPoint[1] < -overscanY || movedPoint[1] > size.height + overscanY) {
          motions[i].dy *= -1
        }

        points[i] = movedPoint
      })

      updateCanvas({
        canvasRef,
        points: points,
        height: size.height,
        width: size.width,
        overscanX,
        overscanY,
        mouseX,
        mouseY,
      })

      !!animated && window.requestAnimationFrame(step)
    }

    step()

    if (target?.current && trackMouse) {
      const handleMouseMove = (event: MouseEvent) => {
        mouseX = clamp(0, size.width, event.clientX)
        mouseY = clamp(0, size.height, event.clientY)
      }
      target.current?.addEventListener('mousemove', handleMouseMove)
      return () => target.current?.removeEventListener('mousemove', handleMouseMove)
    }
  }, [animated, size?.height, size?.width, trackMouse])

  return (
    <div
      ref={target}
      style={{
        ...style,
        position: 'relative',
        backgroundColor: '#175768',
        overflow: 'hidden',
      }}
    >
      {!!size && (
        <canvas
          ref={canvasRef}
          width={Math.ceil(size.width)}
          height={Math.ceil(size.height)}
          style={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            mixBlendMode: 'hard-light',
            zIndex: 0,
            pointerEvents: 'none',
          }}
        />
      )}
      <div
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 1,
          background: 'radial-gradient(rgba(20,75,90,255), rgba(16,59,71, 0))',
          pointerEvents: 'none',
        }}
      />
      <div style={{ position: 'relative', zIndex: 2 }}>{children}</div>
    </div>
  )
}

export const PolyBackgroundWithTheme = ({
  children,
  theme = THEME_DARK,
  ...rest
}: PropsWithChildren<PolyBackgroundProps & { theme?: Theme }>) => (
  <PolyBackground {...rest}>
    <ThemeProvider theme={theme}>
      <Paper sx={{ backgroundColor: 'transparent', backgroundImage: 'none', boxShadow: 'none' }}>
        {children}
      </Paper>
    </ThemeProvider>
  </PolyBackground>
)
