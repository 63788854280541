import { FieldModel } from '@cibo/core'
import { useUpdateMultipleFields } from '@cibo/landmanager'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Checkbox,
  FormControl,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
} from '@mui/material'
import { GridCellParams } from '@mui/x-data-grid-pro'
import { uniq } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

type RDEqipCurrentPracticesInputProps = GridCellParams<FieldModel> &
  Pick<ResourceDetailFeatureTaskEditorProps, 'onError' | 'onSuccess' | 'onUpdating'> & {
    options: string[]
    year: number
  }

export const RDEqipCurrentPracticesInput = ({
  onError,
  onSuccess,
  onUpdating,
  options,
  row,
  year,
}: RDEqipCurrentPracticesInputProps) => {
  const { t } = useTranslation('@cibo/programs/RDEqipCurrentPracticesInput')
  const updateFields = useUpdateMultipleFields()
  const [value, setValue] = useState<string[]>(
    row.resolveStandingDetail('eqipCurrentPractices', year)?.result ?? []
  )
  const allOptions = uniq(['none', ...options])
  const staleOptions =
    value?.filter(option => !allOptions.includes(option) && option !== 'none') ?? []

  useEffect(() => {
    value.length === 0 &&
      options.filter(a => a !== 'none').length === 0 &&
      onChange({ target: { value: ['none'] } } as SelectChangeEvent<string[]>)
  }, [options])

  const onChange = (event: SelectChangeEvent<string[]>) => {
    onUpdating?.()
    const prevValue = value

    // 'none' is an exclusive option.
    const newValue =
      (event.target.value as string[])?.includes('none') && !prevValue.includes('none')
        ? ['none']
        : (event.target.value as string[]).filter(option => option !== 'none')
    setValue(newValue)
    updateFields
      .mutateAsync([
        {
          resourceId: row.resourceId,
          details: [
            {
              traitId: 'eqipCurrentPractices',
              year,
              result: newValue.length ? newValue : undefined,
            },
          ],
        },
      ])
      .then(onSuccess)
      .catch(error => {
        onError?.(error)
        setValue(prevValue)
      })
  }

  return (
    <FormControl sx={{ m: 1, width: 300 }}>
      <Select<string[]>
        labelId="demo-multiple-checkbox-label"
        id="demo-multiple-checkbox"
        data-testid="multiple-checkbox"
        multiple
        value={value}
        onChange={onChange}
        input={<OutlinedInput />}
        label={t('selectPractice')}
        renderValue={selected =>
          selected.length
            ? selected
                .map(option => t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' }))
                .join(', ')
            : t('selectPractice')
        }
        disabled={row.resolveStandingDetail('eqipCurrentPractices', year)?.immutable}
        displayEmpty
      >
        {/* TODO: get product / design direction on this possible state */}
        {staleOptions.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value?.includes(option)} />
            <ListItemText
              primary={t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' })}
              sx={{ fontStyle: 'italic', color: 'text.disabled' }}
            />
          </MenuItem>
        ))}
        {allOptions.map(option => (
          <MenuItem key={option} value={option}>
            <Checkbox checked={value?.includes(option)} />
            <ListItemText
              primary={t(`choice-${option}`, { ns: '@cibo/programs/EqipPracticeChoices' })}
            />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
