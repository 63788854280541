import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const WetlandDesignationCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/WetlandDesignationEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return <Typography>{detail.result ? t('yes') : t('no')}</Typography>
}
