import numeral from 'numeral'

import { Collection, CollectionModel, LandProfileModel } from '@cibo/core'
import { LandProfileDisplay, nanCheck, nullCheck, Theme } from '@cibo/ui'

export class CollectionDisplay extends LandProfileDisplay {
  collection: CollectionModel

  constructor(collection: Collection) {
    super(LandProfileModel.fromCollection(collection))

    this.collection = new CollectionModel(collection)
  }

  static createdDate = nullCheck(LandProfileDisplay.formatDate)
  createdDate = () => CollectionDisplay.createdDate(this.collection.createdDate)

  static modifiedDate = nullCheck(LandProfileDisplay.formatDate)
  modifiedDate = () => CollectionDisplay.createdDate(this.collection.modifiedDate)

  static parcelCount = (value: number) => value
  parcelCount = () => CollectionDisplay.parcelCount(this.profile.parcelCount as number)

  color = () => this.collection.color || Theme.color.surface.contrast.tertiary

  static fieldCount = nanCheck((value: number) =>
    value === 0 ? '-' : numeral(value).format('0,0')
  )
  fieldCount = () => CollectionDisplay.fieldCount(this.collection.fieldCount)
}

export default CollectionDisplay
