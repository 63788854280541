import { LIVESTOCK_CHANGE_TYPE } from '@cibo/core'
import { NumberInputField, SelectField } from '@cibo/ui'
import { Collapse, FormControl, FormLabel, Stack, Typography } from '@mui/material'
import { useField } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const FutureLivestockChangeEditor = ({
  year,
  name = 'futureLivestockChangeEditor',
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/FutureLivestockChangeEditor')

  const formikField = useField(name)
  const { value } = formikField[1]
  const { setValue } = formikField[2]

  const livestockChange = value.livestockChange

  useEffect(() => {
    if (livestockChange === 'none') {
      setValue({
        livestockChange: 'none',
      })
    } else {
      setValue({
        livestockChange,
        headcountPerAcre: '',
      })
    }
  }, [livestockChange])

  return (
    <Stack spacing={2}>
      <Typography>{t('cta', { year })}</Typography>

      <FormControl>
        <FormLabel>{t('whatChange')}</FormLabel>

        <SelectField
          fieldName={`${name}.livestockChange`}
          options={LIVESTOCK_CHANGE_TYPE}
          renderOption={t}
        />
      </FormControl>

      <Collapse in={livestockChange !== 'none'}>
        <FormControl>
          <FormLabel>{t('howMany')}</FormLabel>

          <NumberInputField
            name={`${name}.headcountPerAcre`}
            data-testid={`${name}.headcountPerAcre`}
            min={0}
            step={1}
            unit={t('perAc')}
          />
        </FormControl>
      </Collapse>
    </Stack>
  )
}
