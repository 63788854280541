import { Box, Collapse, FormControl, FormLabel, Grid2 as Grid, InputAdornment } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { FertilizerEventEditorProps } from '../FertilizerEventTypes'

export const AmountAndProduct = ({
  name,
  productRequired,
  ...rest
}: Pick<FertilizerEventEditorProps, 'name'> & { in: boolean; productRequired?: boolean }) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  return (
    <Collapse {...rest}>
      <Box>
        <Grid spacing={2} container>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl required fullWidth>
              <FormLabel>{t('nitrogenAmnt')}</FormLabel>
              <Field
                component={TextField}
                name={`nitrogenAmnt`}
                InputProps={{
                  type: 'number',
                  inputProps: {
                    min: 0,
                    max: 400,
                  },
                  endAdornment: <InputAdornment position="end">{t('lbs/ac')}</InputAdornment>,
                }}
                data-testid={`${name}.nitrogenAmnt`}
              />
            </FormControl>
          </Grid>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl required={productRequired} fullWidth>
              <FormLabel>{t('product')}</FormLabel>
              <Field component={TextField} name={`product`} data-testid={`${name}.product`} />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  )
}
