import { FeatureCollection, Geometry } from 'geojson'
import numeral from 'numeral'
import { useMemo } from 'react'

import { SOIL_TEXTURES } from '@cibo/core'
import { Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DenseDataGrid } from '../../../components'
import { MarkdownCell } from '../MarkdownCell'
import { PolygonLabel } from './PolygonLabel'

import classes from './SoilLegend.module.scss'
interface SoilFeatureProperties {
  area: number
  dominantDrainage: string
  layers: { clay: number; organicMatter: number; sand: number; texture: string }[]
  muname: string
  musym: string
}

interface SoilLegendProps {
  featureCollection: FeatureCollection<Geometry, SoilFeatureProperties>
}

export const SoilLegend = ({ featureCollection: { features } }: SoilLegendProps) => {
  const { t } = useTranslation('uiSoilLegend')
  const theme = useTheme()
  const columns = useMemo(
    () => [
      {
        field: 'polygon',
        headerName: '', // on a portfolio this should not say "majority crop"

        renderCell: (params: any) => <PolygonLabel value={params.value} />,
        maxWidth: 80,
        justified: 'center',
      },
      {
        field: 'description',
        renderCell: (params: any) => (
          <div className={classes.markdown}>
            <MarkdownCell value={params.value} />
          </div>
        ),
        headerName: t('description'), // on a portfolio this should not say "majority crop"
        width: 200,
      },
      {
        field: 'area',
        headerName: t('area'),
      },
      {
        field: 'organicMatter',
        headerName: t('organicMatter'),
      },
      {
        field: 'silt',
        headerName: t('silt'),
      },
      {
        field: 'clay',
        headerName: t('clay'),
      },
    ],
    []
  )

  if (!features) return null

  const totalArea = features.reduce(
    (total, polygon) => (total += polygon?.properties?.area || 0),
    0
  )

  const rows = features?.map(
    ({ properties: { area, dominantDrainage, layers, muname, musym } }, index) => {
      const { clay, organicMatter, sand, texture } = layers[0] || {}
      return {
        id: `${SOIL_TEXTURES[index]}:${musym}`,
        polygon: `${SOIL_TEXTURES[index]}:${musym}`,
        descriptionLines: 2 + muname.split(',').length,
        description: `**${t(texture)}**\n\n${muname
          .split(',')
          .join('\n\n')}\n\n${dominantDrainage}`,
        area: numeral(area / totalArea).format('0[.]0%'),
        organicMatter: numeral(organicMatter / 100).format('0[.]0%'),
        clay: numeral(clay / 100).format('0[.]0%'),
        silt: numeral((100 - (sand + clay)) / 100).format('0[.]0%'),
      }
    }
  )

  return (
    <Stack spacing={2}>
      <Typography variant="h5">{t('surfaceProperties')}</Typography>
      <DenseDataGrid
        getRowHeight={({ model }) =>
          model.descriptionLines *
            (theme.typography.caption.fontSize as number) *
            (theme.typography.caption.lineHeight as number) +
          8
        }
        columns={columns}
        rows={rows}
      />

      <Typography color="InactiveCaptionText" variant="caption">
        {t('attribution')}
      </Typography>
    </Stack>
  )
}
