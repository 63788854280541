import { AxiosResponse } from 'axios'
import { MpxUser } from './MpxUser'
export const MP_AUTH_DIRECT_LOGIN_PENDING = 'MP_AUTH_DIRECT_LOGIN_PENDING'
export const MP_AUTH_DIRECT_LOGIN_SUCCESS = 'MP_AUTH_DIRECT_LOGIN_SUCCESS'
export const MP_AUTH_DIRECT_LOGIN_ERROR = 'MP_AUTH_DIRECT_LOGIN_ERROR'

type AuthType = 'webapp'

export const LOGIN_DENIED_REASON = {
  NEED_CODE: 'needCode',
}

export interface AuthDirectLoginResult extends MpxUser {}

export interface AuthDirectLoginError {
  activity: string
  deniedReason?: 'needCode'
  duration: string
  httpCode: number
  message: string
  source: string
}

interface AuthDirectLoginPendingAction {
  type: typeof MP_AUTH_DIRECT_LOGIN_PENDING
}

interface AuthDirectLoginSuccessAction {
  type: typeof MP_AUTH_DIRECT_LOGIN_SUCCESS
  results: AxiosResponse<AuthDirectLoginResult>
  message?: string
}

interface AuthDirectLoginErrorAction {
  type: typeof MP_AUTH_DIRECT_LOGIN_ERROR
  error: AuthDirectLoginError
}

export type AuthDirectLoginActionTypes =
  | AuthDirectLoginPendingAction
  | AuthDirectLoginSuccessAction
  | AuthDirectLoginErrorAction

export interface AuthDirectLoginWithPasswordQuery {
  formAuthToken: string
  formAuthCode: string
  username: string
  password: string
}

export interface AuthDirectLoginWithVerificationCodeQuery {
  formAuthToken: string
  username: string
  verifyCode: string
  password: string
  password2: string
  xMpAuthType?: AuthType
}

export const MP_AUTH_DIRECT_LOGOUT_PENDING = 'MP_AUTH_DIRECT_LOGOUT_PENDING'
export const MP_AUTH_DIRECT_LOGOUT_SUCCESS = 'MP_AUTH_DIRECT_LOGOUT_SUCCESS'
export const MP_AUTH_DIRECT_LOGOUT_ERROR = 'MP_AUTH_DIRECT_LOGOUT_ERROR'

interface AuthDirectLogoutPendingAction {
  type: typeof MP_AUTH_DIRECT_LOGOUT_PENDING
}

interface AuthDirectLogoutSuccessAction {
  type: typeof MP_AUTH_DIRECT_LOGOUT_SUCCESS
  message?: string
}

export interface AuthDirectLogoutError {
  activity: string
  duration: string
  httpCode: number
  message: string
  source: string
}

interface AuthDirectLogoutErrorAction {
  type: typeof MP_AUTH_DIRECT_LOGOUT_ERROR
  error: AuthDirectLogoutError
}

export type AuthDirectLogoutActionTypes =
  | AuthDirectLogoutPendingAction
  | AuthDirectLogoutSuccessAction
  | AuthDirectLogoutErrorAction

export interface AuthDirectRequestVerificationCodeResult {
  username: string
}

export const MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING =
  'MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING'
export const MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS =
  'MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS'
export const MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR =
  'MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR'
export const MP_AUTH_RESET_FORGOT_PASSSWORD_STATE = 'MP_AUTH_RESET_FORGOT_PASSSWORD_STATE'

export interface AuthDirectRequestVerificationCodeQuery {
  formAuthToken: string
  formAuthCode: string
  username: string
  forLogin?: boolean
}

export interface AuthDirectRequestVerificationCodeError {
  activity: string
  duration: string
  httpCode: number
  message: string
  source: string
}

interface AuthDirectRequestVerificationCodePendingAction {
  type: typeof MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING
}

interface AuthDirectRequestVerificationCodeSuccessAction {
  type: typeof MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS
  results: AxiosResponse<AuthDirectRequestVerificationCodeResult>
}

interface AuthDirectRequestVerificationCodeErrorAction {
  type: typeof MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR
  error: AuthDirectRequestVerificationCodeError
}

interface AuthDirectResetForgotPasswordState {
  type: typeof MP_AUTH_RESET_FORGOT_PASSSWORD_STATE
}

export type AuthDirectRequestVerificationCodeActionTypes =
  | AuthDirectRequestVerificationCodePendingAction
  | AuthDirectRequestVerificationCodeSuccessAction
  | AuthDirectRequestVerificationCodeErrorAction
  | AuthDirectResetForgotPasswordState
