import { GeneralResourceDetail } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps, useTraitContent } from '@cibo/ui'
import Download from '@mui/icons-material/Download'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  FormControlLabel,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../hooks/useProgramEngagementDetails'

const HoveredStack = styled(Stack)(({ theme }) => ({
  '.MuiIconButton-root': {
    opacity: 0,
    transition: 'opacity 0.1s ease-in-out',
  },
  '&:hover .MuiIconButton-root, .MuiIconButton-root:focus': {
    opacity: 'unset',
  },
}))

type WorkflowDetailChoiceSelectionProps<T extends GeneralResourceDetail = GeneralResourceDetail> =
  ResourceDetailFeatureTaskEditorProps<T> & {
    choices: string[]
  }

export const WorkflowDetailChoiceSelection = <
  T extends GeneralResourceDetail = GeneralResourceDetail
>({
  choices,
  detailRequirements,
  onError,
  onSuccess,
  onUpdating,
  resourceIds: [resourceId],
}: WorkflowDetailChoiceSelectionProps<T>) => {
  const { t } = useTranslation('@cibo/programs/WorkflowDetailChoiceSelection')
  const { traitId } = detailRequirements[0]
  const details = useProgramEngagementDetails({ detailRequirements, resourceId })
  const updateDetails = useUpdateProgramEngagementDetails<T>()
  const content = useTraitContent(traitId)
  const disclaimer = useMemo(() => t('disclaimer'), [t])
  const [selectionState, setSelectionState] = useState<string[]>([])
  const [links, setLinks] = useState<Record<string, string>>({})

  useEffect(() => {
    if (details.data?.[0]) {
      setSelectionState((details.data[0].result as string[]) ?? [])
    }
  }, [details.dataUpdatedAt])

  useEffect(() => {
    if (content.data?.fields?.detailDocuments?.length) {
      setLinks(
        content.data.fields.detailDocuments.reduce((acc, doc) => {
          acc[`${doc.fields.title}`] = doc.fields.file.url
          return acc
        }, {} as Record<string, string>)
      )
    }
  }, [content.dataUpdatedAt])

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
    const newSelection = checked
      ? event.target.name === 'none'
        ? ['none']
        : [...selectionState.filter(value => value !== 'none'), event.target.name]
      : selectionState.filter(item => item !== event.target.name)
    setSelectionState(newSelection)
    onUpdating?.()
    updateDetails
      .mutateAsync({
        resourceId,
        details: [{ traitId, result: newSelection.length ? newSelection : undefined } as T],
      })
      .then(onSuccess)
      .catch(onError)
  }

  const noneChecked = selectionState.includes('none')
  const filteredChoices = choices.filter(choice => choice !== 'none')
  const filteredSelectionState = selectionState.filter(choice => choice !== 'none')

  useEffect(() => {
    if (!Object.keys(links).length || !filteredChoices) return

    filteredChoices.forEach(choice => {
      if (!links[choice]) {
        console.log(`${traitId} missing link for choice ${choice}`)
      }
    })
  }, [links, choices])

  return (
    <Accordion
      disableGutters
      sx={{
        backgroundImage: 'none',
        boxShadow: 'none',
        margin: 0,
        padding: 0,
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`${traitId}-content`}
        id={`${traitId}-content`}
        sx={{ padding: 0, margin: 0, '.MuiAccordionSummary-content': { margin: 0 } }}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center" flex={1}>
          <Stack>
            <Typography>{t(traitId, { ns: 'traits' })}</Typography>
            {content.data?.fields?.definition && (
              <Typography variant="body2" color="text.secondary">
                {content.data.fields.definition}
              </Typography>
            )}
          </Stack>
          {!!filteredSelectionState.length && (
            <Box>
              <Typography
                variant="body2"
                color="text.secondary"
                sx={{ textWrap: 'nowrap' }}
                marginRight={1}
              >
                {t('selected', { count: filteredSelectionState.length })}
              </Typography>
            </Box>
          )}
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={{ paddingLeft: 0, paddingRight: 0, paddingBottom: 0 }}>
        <Stack spacing={2}>
          <Grid columns={filteredChoices.length > 6 ? 2 : 1} container>
            <Grid size={{ xs: 2 }}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={'none'}
                    value={'none'}
                    data-testid={'option-None'}
                    onChange={handleChange}
                    checked={noneChecked}
                    disabled={details.data?.[0]?.immutable || details.isPending}
                  />
                }
                label={t(`choice-none`, {
                  ns: '@cibo/programs/EqipPracticeChoices',
                })}
              />
            </Grid>
            {filteredChoices.map(choice => {
              const choiceLabel = t(`choice-${choice}`, {
                ns: '@cibo/programs/EqipPracticeChoices',
              })
              return (
                <Grid key={choice} size={{ xs: 1 }}>
                  <HoveredStack direction="row" justifyContent="space-between" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={choice}
                          value={choice}
                          data-testid={`option-${choice}`}
                          onChange={handleChange}
                          checked={!!selectionState.includes(choice)}
                          disabled={details.data?.[0]?.immutable || details.isPending}
                        />
                      }
                      label={choiceLabel}
                    />
                    {links[choice] && (
                      <IconButton
                        aria-label={t('downloadAriaLabel', { choiceLabel })}
                        component={'a'}
                        href={links[choice]}
                        target="_blank"
                      >
                        <Download />
                      </IconButton>
                    )}
                  </HoveredStack>
                </Grid>
              )
            })}
          </Grid>

          {!!disclaimer && <Typography variant="caption">{disclaimer}</Typography>}
        </Stack>
      </AccordionDetails>
    </Accordion>
  )
}
