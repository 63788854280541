import { ResourceDetailYearly } from '../ResourceDetail'

export const EQIP_PRACTICES_SOIL_SALINITY_OPTIONS = ['excessive', 'normal', 'unknown'] as const
export type EqipPracticesSoilSalinityOption = typeof EQIP_PRACTICES_SOIL_SALINITY_OPTIONS[number]

export type RDEqipPracticesSoilSalinityInput = {
  soilSalinity: EqipPracticesSoilSalinityOption
}

export interface RDEqipPracticesSoilSalinity extends ResourceDetailYearly {
  traitId: 'eqipPracticesSoilSalinity'
  input: RDEqipPracticesSoilSalinityInput
  result: RDEqipPracticesSoilSalinityInput
}
