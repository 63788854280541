import { Chip } from '@mui/material'
import { SourceIcon } from '../SourceIcon'
import { useTranslation } from 'react-i18next'
import { CommonChipProps } from './types'

export const NotObservedChip = ({
  size = 'small',
  ...chipProps
}: Omit<CommonChipProps, 'crop'>) => {
  const { t } = useTranslation('@cibo/ui/NotObservedChip')

  return (
    <Chip
      size={size}
      label={t('notObserved')}
      variant="outlined"
      {...chipProps}
      icon={<SourceIcon source="notObserved" sx={{ opacity: 0.8 }} />}
    />
  )
}
