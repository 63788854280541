import { FriendlyError, Markdown } from '@cibo/ui'
import AddIcon from '@mui/icons-material/Add'
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Stack,
  Table,
  TableBody,
} from '@mui/material'
import { Field, FieldArray, FieldProps } from 'formik'
import { equals, last } from 'ramda'
import { ReactElement, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export interface EventComponentProps
  extends Pick<DetailEditorPropsByYear, 'requirement' | 'filterInputs'> {
  key: string | number
  name: string
  year: number
  onPressRemove: () => void
  parentName: string
  eventIndex: number
  onEditEvent: (editing: boolean) => void
  editing?: boolean
}

interface EventDetailEditorPropsByYear extends Omit<DetailEditorPropsByYear, 'name'> {
  name: string
  EventTableHead: () => ReactElement
  translationContext:
    | 'fertilizer'
    | 'amendment'
    | 'eventIrrigation'
    | 'tillage'
    | 'grazing'
    | 'liming'
    | 'naturalDisturbance'
  EventComponent: (props: EventComponentProps) => ReactElement
  defaultEvent: Record<any, any>
  modal?: boolean
}

export const EventDetailEditor = <I extends { events?: Record<any, any>[] | never[] } | undefined>({
  year,
  traitId,
  name,
  requirement,
  filterInputs,
  EventTableHead,
  EventComponent,
  translationContext,
  defaultEvent,
  modal,
}: EventDetailEditorPropsByYear) => {
  const { t } = useTranslation('eventEditor')
  const [editingEvent, setEditingEvent] = useState(false)

  return (
    <Field name={name} id={name}>
      {({
        field: { value: { events } = { events: [] } },
        form: { setFieldTouched, setFieldValue },
        meta: { initialValue, error },
      }: FieldProps<I>) => (
        <Stack spacing={2}>
          <Markdown>
            {t(`cta_${translationContext}`, {
              year,
              previousYear: year - 1,
            })}
          </Markdown>
          <FieldArray
            name={`${name}.events`}
            render={arrayHelpers => (
              <>
                <Collapse in={!events?.length}>
                  <Stack spacing={2} direction="row">
                    <Button
                      onClick={() => arrayHelpers.push(defaultEvent)}
                      startIcon={<AddIcon />}
                      data-testid={`${name}-inline-add-event`}
                    >
                      {t(`addEvent_${traitId}`)}
                    </Button>
                    <FormControlLabel
                      control={
                        <Checkbox
                          name={`${name}.noEvents`}
                          checked={events === undefined ? false : !events.length}
                          onChange={(event, checked) => {
                            setFieldTouched(`${name}.events`, true, false)
                            if (checked) {
                              setFieldValue(`${name}.events`, [])
                            } else {
                              setFieldValue(`${name}.events`, undefined)
                            }
                          }}
                        />
                      }
                      label={t(`noEvents_${traitId}`)}
                    />
                  </Stack>
                </Collapse>

                <Collapse in={!!events?.length}>
                  <Stack spacing={2}>
                    <Table aria-label={t(`tableLabel_${traitId}`)} size="small">
                      <EventTableHead />

                      <TableBody>
                        {events &&
                          events.length > 0 &&
                          // @ts-ignore events.map may be one of two types of function and typescript *hates* that ¯\_(ツ)_/¯
                          events.map((event: any, index: number) => (
                            <EventComponent
                              key={index}
                              name={`${name}.events.${index}`}
                              parentName={name}
                              year={year}
                              onPressRemove={() => arrayHelpers.remove(index)}
                              requirement={requirement}
                              filterInputs={filterInputs}
                              eventIndex={index}
                              onEditEvent={setEditingEvent}
                              editing={editingEvent}
                            />
                          ))}
                      </TableBody>
                    </Table>
                    <Box>
                      <Button
                        onClick={() => arrayHelpers.push(defaultEvent)}
                        startIcon={<AddIcon />}
                        data-testid={`${name}-footer-add-event`}
                        disabled={modal ? editingEvent : false}
                      >
                        {t(`addEvent_${traitId}`)}
                      </Button>
                    </Box>
                  </Stack>
                </Collapse>
              </>
            )}
          />
          {/* We are immediately calling the validation on this, however we do not want to 
          present the error if it's a new event. */}
          {events &&
            // @ts-ignore error is statically typed as a string but it isn't always a string
            error?.events?.length > 0 &&
            !equals(last(events), defaultEvent) && (
              <FriendlyError
                // @ts-ignore error is statically typed as a string but it isn't always a string
                message={t('eventError', { count: error?.events?.length || 0 }) || ''}
              />
            )}
        </Stack>
      )}
    </Field>
  )
}
