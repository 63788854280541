import { AbbreviatedCarbonComparisonResult, YearFootprint } from '../types'

import { CiboResultModel } from './CiboResultModel'
import { FootprintModel } from './FootprintModel'

type NetCarbonDifference = 'increase' | 'decrease' | 'same'

const now = new Date()
const CURRENT_YEAR = now.getFullYear()

export class CarbonComparisonResultModel extends CiboResultModel {
  computationDate?: Date
  isEstimateCurrent?: boolean
  results?: YearFootprint
  footprint?: FootprintModel

  constructor(result: AbbreviatedCarbonComparisonResult) {
    super(result)

    this.computationDate = result.updatedAt ? new Date(result.updatedAt) : undefined
    this.results = result.results
    this.footprint = result.results && new FootprintModel(result.results)

    /**
     * eventually we should probably consider model versions too
     */
    this.isEstimateCurrent = this.computationDate
      ? result.results?.year === CURRENT_YEAR
      : undefined
  }

  /**
   * @NOTE! the total returned in the resuts is inverse from what
   * we calculate in the calculator:
   *    positive total values are a REDUCTION in CO2
   */
  get netDifference(): NetCarbonDifference {
    if (this.results) {
      if (this.results.total < 0) {
        return 'increase'
      }

      if (this.results.total > 0) {
        return 'decrease'
      }
    }
    //@todo return n/a too?
    return 'same'
  }

  get total() {
    const { acreage, total } = this.results || {}
    return (acreage || 0) * (total || 0)
  }
}
