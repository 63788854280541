import { RDEqipPracticeSelectionConfirmation } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { PracticeSelectionConfirmationTaskEditor } from './PracticeSelectionConfirmationTaskEditor'

export const RDEqipPracticeSelectionConfirmationFeature: ResourceDetailFeature<RDEqipPracticeSelectionConfirmation> =
  {
    traitId: 'eqipPracticeSelectionConfirmation',
    TaskEditor: PracticeSelectionConfirmationTaskEditor,
    selfIsland: true,
  }
