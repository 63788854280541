import { FERTILIZER_TYPE_OTHER, FERTILIZER_TYPE_RATE_UNIT, RDFertilizerEvent } from '@cibo/core'
import { Box, Collapse, Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { FormikErrors } from 'formik'
import { Dispatch, MouseEvent, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { AmountAndProduct } from './AmountAndProduct'
import { FertilizerRateAndUnit } from './FertilizerRateAndUnit'

export const AmountInputs = ({
  enterRateAndUnit,
  setFieldValue,
  values,
  setEnterRateAndUnit,
  name,
}: {
  enterRateAndUnit: boolean
  setFieldValue: (
    field: string,
    value: any,
    shouldValidate?: boolean
  ) => Promise<void | FormikErrors<RDFertilizerEvent>>
  values: RDFertilizerEvent
  setEnterRateAndUnit: Dispatch<SetStateAction<boolean>>
  name: string
}) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const displayRateAndUnit = enterRateAndUnit && FERTILIZER_TYPE_RATE_UNIT.includes(values.type)
  return (
    <Box>
      <Collapse in={!!values?.type && FERTILIZER_TYPE_RATE_UNIT.includes(values?.type)}>
        <Stack direction="row" spacing={1} alignItems="center" paddingBottom={1}>
          <Typography>{t('enterAs')}</Typography>
          <ToggleButtonGroup
            exclusive
            size="small"
            disabled={!FERTILIZER_TYPE_RATE_UNIT.includes(values?.type)}
            value={enterRateAndUnit ? 'rate' : 'amnt'}
            onChange={(event: MouseEvent<HTMLElement>, newValue: string) => {
              if (newValue === 'rate') {
                setFieldValue('nitrogenAmnt', '', true)
              } else {
                setFieldValue('rate', '', true)
              }
              setEnterRateAndUnit(newValue === 'rate')
            }}
          >
            <ToggleButton value="amnt" data-testid="nitrogen-toggle">
              {t('nitrogenAmntPerAcre')}
            </ToggleButton>
            <ToggleButton value="rate" data-testid="rate-and-unit-toggle">
              {t('rateAndUnit')}
            </ToggleButton>
          </ToggleButtonGroup>
        </Stack>
      </Collapse>

      <FertilizerRateAndUnit in={displayRateAndUnit} name={name} />

      <AmountAndProduct
        in={(!!values?.type && !displayRateAndUnit) || !!values?.product}
        productRequired={FERTILIZER_TYPE_OTHER.includes(values?.type)}
        name={name}
      />
    </Box>
  )
}
