import {
  FieldModel,
  IntegerString,
  RecommendationsAndAcceptanceScoreForPoolsInputAPI,
  ResourceConcernComponents,
} from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { prop } from 'ramda'
import { PROGRAMS_QUERY_KEY } from './queryKey'
import { useProgramEngagementFields } from './useProgramEngagements'

export const useEqipRecommendations = (engagementId?: string) => {
  return useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.EQIP_RECOMMENDATIONS, engagementId],
    queryFn: () =>
      RecommendationsAndAcceptanceScoreForPoolsInputAPI.summary({
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        engagementId: engagementId!,
      }),
    enabled: !!engagementId,
  })
}

export const useEqipEngagementFieldsWithConcerns = (engagementId?: string) => {
  const recommendations = useEqipRecommendations(engagementId)
  const fieldModels = useProgramEngagementFields({ id: engagementId })

  const results = fieldModels.data?.items.map(field => {
    const perFieldConcerns = recommendations.data?.perFieldConcerns.find(
      ({ resourceId }) => resourceId === field.resourceId
    )
    const availablePractices = recommendations.data?.availablePracticesByField.find(
      ({ resourceId }) => resourceId === field.resourceId
    )

    const fieldWithConcerns = field as FieldModel & {
      resourceConcerns?: ResourceConcernComponents[]
      availablePractices?: IntegerString[]
      selectedPractices?: IntegerString[]
    }

    fieldWithConcerns.resourceConcerns = perFieldConcerns?.concerns.filter(
      concern => concern.vulnerability > 0
    )
    fieldWithConcerns.selectedPractices = perFieldConcerns?.selectedPractices
    fieldWithConcerns.availablePractices = availablePractices?.practices

    return fieldWithConcerns
  })

  const queries = [recommendations, fieldModels]

  return {
    isPending: queries.some(prop('isPending')),
    isError: queries.some(prop('isError')),
    isFetched: queries.every(prop('isFetched')),
    dataUpdatedAt: queries.reduce(
      (acc, q) => (q.dataUpdatedAt ? Math.max(q.dataUpdatedAt, acc) : acc),
      0
    ),
    error: recommendations.error || fieldModels.error,
    data: results,
  }
}
