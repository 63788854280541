import { BreakpointType, FontSizeType, ShapeType } from './ThemeTypes'
import breakpointStyles from './_Breakpoints.module.scss'
import colorStyles from './_Color.module.scss'
import fontStyles from './_Font.module.scss'
import shapeStyles from './_Shape.module.scss'

const pixelSizesToInt = (styleObject: Record<string, string>) => {
  const finalObject: Record<string, any> = {}
  Object.keys(styleObject).forEach(
    item => (finalObject[item] = parseInt(styleObject[item].replace('px', ''), 10))
  )
  return finalObject
}

const shapeIntegers = pixelSizesToInt(shapeStyles) as ShapeType
const fontIntegers = pixelSizesToInt(fontStyles) as FontSizeType
const breakpointIntegers = pixelSizesToInt(breakpointStyles) as BreakpointType

/**
 * Handle transparency by convention:
 * in js: chroma(Theme.color.surface.main.primary).alpha(0.4).hex(),
 * in sass: color.adjust($success, $alpha: -0.1)
 */

export const Theme = {
  color: {
    brand: {
      primary: colorStyles.brand_primary,
      secondary: colorStyles.brand_secondary,
      tertiary: colorStyles.brand_tertiary,
    },
    surface: {
      main: {
        primary: colorStyles.surface_main_primary,
        secondary: colorStyles.surface_main_secondary,
        tertiary: colorStyles.surface_main_tertiary,
        highlight: colorStyles.surface_main_highlight,
        hover: colorStyles.surface_main_hover,
      },
      contrast: {
        primary: colorStyles.surface_contrast_primary,
        secondary: colorStyles.surface_contrast_secondary,
        tertiary: colorStyles.surface_contrast_tertiary,
        hover: colorStyles.surface_contrast_hover,
      },
      error: {
        primary: colorStyles.surface_error_primary,
        secondary: colorStyles.surface_error_secondary,
        hover: colorStyles.surface_error_hover,
      },
      disabled: {
        primary: colorStyles.surface_disabled_primary,
        secondary: colorStyles.surface_disabled_secondary,
      },
    },
    action: {
      main: colorStyles.action_main,
      accent: colorStyles.action_accent,
      highlight: colorStyles.action_highlight,
    },
    text: {
      main: {
        default: colorStyles.text_main_default,
        emphasis: {
          high: colorStyles.text_main_emphasis_high,
          medium: colorStyles.text_main_emphasis_medium,
          low: colorStyles.text_main_emphasis_low,
        },
        disabled: colorStyles.text_main_disabled,
        interactive: colorStyles.text_main_interactive,
        error: colorStyles.text_main_error,
      },
      inverse: {
        default: colorStyles.text_inverse_default,
        emphasis: {
          high: colorStyles.text_inverse_emphasis_high,
          medium: colorStyles.text_inverse_emphasis_medium,
          low: colorStyles.text_inverse_emphasis_low,
        },
        disabled: colorStyles.text_inverse_disabled,
        interactive: colorStyles.text_inverse_interactive,
        error: colorStyles.text_inverse_error,
      },
    },
    primary: colorStyles.primary,
    secondary: colorStyles.secondary,
    tertiary: colorStyles.tertiary,
    highlight: colorStyles.highlight,
    info: colorStyles.info,
    success: colorStyles.success,
    error: colorStyles.error,
    disabled: colorStyles.disabled,
    crop: {
      soybeans: colorStyles.crop_soybeans,
      corn: colorStyles.crop_corn,
      cotton: colorStyles.crop_cotton,
      wheat: colorStyles.crop_wheat,
    },
    landUse: {
      crop: colorStyles.land_use_crop,
      dairy: colorStyles.land_use_dairy,
      forest: colorStyles.land_use_forest,
      ranch: colorStyles.land_use_ranch,
    },
    map: {
      selected: {
        fill: colorStyles.map_selected_fill,
        outline: colorStyles.map_selected_outline,
      },
      carbon: {
        fill: colorStyles.map_carbon_fill,
        outline: colorStyles.map_carbon_outline,
        satelliteFill: colorStyles.map_carbon_satellite_fill,
      },
      carbonPending: {
        fill: colorStyles.map_carbon_pending_fill,
        outline: colorStyles.map_carbon_pending_outline,
        satelliteFill: colorStyles.map_carbon_pending_satellite_fill,
      },
      carbonUnavailable: {
        fill: colorStyles.map_carbon_unavailable_fill,
        outline: colorStyles.map_carbon_unavailable_outline,
        satelliteFill: colorStyles.map_carbon_unavailable_satellite_fill,
      },
      mine: {
        fill: colorStyles.map_mine_fill,
        outline: colorStyles.map_mine_outline,
      },
      floodplain: {
        fill: colorStyles.map_floodplain_fill,
        outline: colorStyles.map_floodplain_outline,
      },
      default: {
        fill: colorStyles.map_default_fill,
        outline: colorStyles.map_default_outline,
      },
    },
    score: {
      worst: colorStyles.score_worst,
      veryBad: colorStyles.score_very_bad,
      bad: colorStyles.score_bad,
      slightlyBad: colorStyles.score_slightly_bad,
      average: colorStyles.score_average,
      slightlyGood: colorStyles.score_slightly_good,
      good: colorStyles.score_good,
      veryGood: colorStyles.score_very_good,
      best: colorStyles.score_best,
    },
  },
  gradient: {
    scores: {
      veryLow: {
        stops: [
          {
            color: colorStyles.score_very_bad,
            position: 0,
          },
          {
            color: colorStyles.score_bad,
            position: 100,
          },
        ],
      },
      low: {
        stops: [
          {
            color: colorStyles.score_bad,
            position: 0,
          },
          {
            color: colorStyles.score_slightly_bad,
            position: 100,
          },
        ],
      },
      averageLow: {
        stops: [
          {
            color: colorStyles.score_slightly_bad,
            position: 0,
          },
          {
            color: colorStyles.score_average,
            position: 100,
          },
        ],
      },
      average: {
        stops: [
          {
            color: colorStyles.score_average,
            position: 0,
          },
          {
            color: colorStyles.score_slightly_good,
            position: 100,
          },
        ],
      },
      averageHigh: {
        stops: [
          {
            color: colorStyles.score_slightly_good,
            position: 0,
          },
          {
            color: colorStyles.score_good,
            position: 100,
          },
        ],
      },
      high: {
        stops: [
          {
            color: colorStyles.score_good,
            position: 0,
          },
          {
            color: colorStyles.score_very_good,
            position: 100,
          },
        ],
      },
      veryHigh: {
        stops: [
          {
            color: colorStyles.score_very_good,
            position: 0,
          },
          {
            color: colorStyles.score_best,
            position: 100,
          },
        ],
      },
    },
    simpleScore: {
      stops: [
        {
          color: colorStyles.score_best,
          position: 0,
        },
        {
          color: colorStyles.score_slightly_good,
          position: 41.51,
        },
        {
          color: colorStyles.score_worst,
          position: 100,
        },
      ],
    },
    impact: {
      stops: [
        {
          color: colorStyles.gradient_impact_stop1,
          position: 0,
        },
        {
          color: colorStyles.gradient_impact_stop2,
          position: 22.92,
        },
        {
          color: colorStyles.gradient_impact_stop3,
          position: 100,
        },
      ],
    },
    illustration: {
      stops: [
        {
          color: colorStyles.gradient_illustration_stop1,
          positition: 0,
        },
        {
          color: colorStyles.gradient_illustration_stop2,
          positition: 23.25,
        },
        {
          color: colorStyles.gradient_illustration_stop3,
          positition: 44.61,
        },
        {
          color: colorStyles.gradient_illustration_stop4,
          positition: 100,
        },
      ],
    },
    plus: {
      stops: [
        {
          color: colorStyles.gradient_plus_stop1,
          position: 0,
        },
        {
          color: colorStyles.gradient_plus_stop2,
          position: 100,
        },
      ],
    },
  },
  font: {
    primary: fontStyles.font_primary,
    secondary: fontStyles.font_secondary,
    size: {
      body1: fontIntegers.font_size_body_1,
      body2: fontIntegers.font_size_body_2,
    },
    lineHeight: {
      body1: fontIntegers.line_height_body_1,
    },
  },
  shape: {
    borderRadius: shapeIntegers.BORDER_RADIUS,
  },

  breakpoints: {
    mobile: breakpointIntegers.BREAK_MOBILE,
    medium: breakpointIntegers.BREAK_MEDIUM,
    large: breakpointIntegers.BREAK_LARGE,
  },
}
