import { grey } from '@mui/material/colors'

export const OTHER_COLOR = grey[100]

export const PALETTE = ['#3D5562', '#FE8500', '#00A1B3', '#AF85D6', '#7B5D25', '#9DD966', '#C2C2BE']

export type DonutLegendSeries = {
  key: string
  label: string
  color: string
  acres: number
  percent: number
}

export type DonutChartProps = { series: DonutLegendSeries[] }

export type SupplyShedRoutes = {
  SUPPLY_SHED: string
  SUPPLY_SHED_REPORT: string
}

export type SupplyShedRoutesProps = {
  SUPPLY_SHED_ROUTES: SupplyShedRoutes
}

export const QUERY_KEY = { REPORT: 'supplyShed/report/' }
