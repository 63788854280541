const SHARED_CONFIGS: Record<any, any> = {}

export const getConfigWithKeys = <T>() => {
  return {
    set: (key: T, value: any) => (SHARED_CONFIGS[key] = value),
    get: (key: T) => {
      const requestedValue = SHARED_CONFIGS[key]
      if (requestedValue) {
        return requestedValue
      } else {
        // @ts-ignore
        if (!import.meta.env.PROD) {
          console.log(`CONFIG VALUE NOT FOUND FOR KEY "${key}"`)
        }
      }
    },
    debug: () => console.log({ SHARED_CONFIGS }),
  }
}
