import { EQIP_PRACTICE_CHOICE_AGROFORESTRY, RDEqipPracticeChoiceAgroforestry } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { WorkflowDetailChoiceSelection } from '../../../../components/WorkflowDetailChoiceSelection'

export const RDEqipPracticeChoiceAgroforestryResourceDetailFeature: ResourceDetailFeature<RDEqipPracticeChoiceAgroforestry> =
  {
    traitId: 'eqipPracticeChoiceAgroforestry',
    TaskEditor: props => (
      <WorkflowDetailChoiceSelection {...props} choices={EQIP_PRACTICE_CHOICE_AGROFORESTRY} />
    ),
  }
