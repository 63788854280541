/* istanbul ignore file */
import { AxiosRequestConfig } from 'axios'
import { ExternalUser, PaginationRequest, PaginationResponse, Persona, UserId } from '../types'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  USERS: '/external/user',
}

interface ExternalAPIUsersQuery extends Omit<PaginationRequest<{}>, 'sort'> {
  userIds?: UserId[]
  externalUserIds?: string[]
  persona: Persona
  gteLastModified?: string // yyyy-mm-dd
  ltLastModified?: string // yyyy-mm-dd
  isRegistered?: boolean
  hasExternalId?: boolean
}

export class ExternalAPI {
  static users(params: ExternalAPIUsersQuery, config?: AxiosRequestConfig) {
    return agent
      .get<PaginationResponse<ExternalUser>>(PATHS.USERS, { params, ...config })
      .then(({ data }) => data)
  }
}
