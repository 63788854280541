import { TermsPolicyDataUseEntry } from '@cibo/core'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { MpxUserDataAPI, USER_DATA_KEYS } from '../../api'
import { useOrganizationContent } from '../../queries'
import { TermsUserData } from '../../types'
import { useAuth } from '../useAuth'
import { USER_DATA_QUERY_KEY, useUserData } from '../useUserData'
import { generateTermsState, pendingPolicies } from './utils'

const QUERY_KEY = [USER_DATA_QUERY_KEY, 'policies']

export const usePolicies = () => {
  const { userId } = useAuth()
  const orgContent = useOrganizationContent()

  return useQuery({
    queryKey: QUERY_KEY,
    queryFn: () =>
      MpxUserDataAPI.fetch(userId).then(data => {
        const history = data?.[USER_DATA_KEYS.TERMS_AND_CONDITIONS] as TermsUserData
        const content = (
          orgContent.data?.fields.policies ?? [
            orgContent.data?.fields.termsPolicyDataUse,
            orgContent.data?.fields.privacyPolicy,
          ]
        ).filter(Boolean) as TermsPolicyDataUseEntry[]
        return {
          content,
          history,
          pending: pendingPolicies({ content, history }),
        }
      }),
    enabled: !!userId && !!orgContent.isFetched,
  })
}

export const useAcceptPolicies = () => {
  const { data, update } = useUserData<TermsUserData>(USER_DATA_KEYS.TERMS_AND_CONDITIONS)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ policies }: { policies: TermsPolicyDataUseEntry[] }) => {
      return update(generateTermsState({ currentState: data, policies, status: 'accepted' })).then(
        () => queryClient.invalidateQueries({ queryKey: QUERY_KEY })
      )
    },
  })
}

export const useDeclinePolicies = () => {
  const { data, update } = useUserData<TermsUserData>(USER_DATA_KEYS.TERMS_AND_CONDITIONS)
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ policies }: { policies: TermsPolicyDataUseEntry[] }) => {
      return update(generateTermsState({ currentState: data, policies, status: 'denied' })).then(
        () => queryClient.invalidateQueries({ queryKey: QUERY_KEY })
      )
    },
  })
}
