import { ResourceDetailFeatureTaskEditorProps, Section, logRequestError } from '@cibo/ui'
import ChevronRightIcon from '@mui/icons-material/ChevronRight'
import { Box, Checkbox, InputLabel, Link, Stack, Typography } from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { propEq } from 'ramda'
import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { RDEqipProofOfLandControl } from './types'

export const RDEqipProofOfLandControlTaskEditor = ({
  detailRequirements,
  engagementId,
  resourceIds: [resourceId],
}: ResourceDetailFeatureTaskEditorProps<RDEqipProofOfLandControl>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipProofOfLandControlTaskEditor')
  const { enqueueSnackbar } = useSnackbar()

  const details = useProgramEngagementDetails({
    // @ts-ignore faking a requirement here
    detailRequirements,
    resourceId: engagementId,
  })
  const detail = details.data?.find(propEq('traitId', 'proofOfLandControl'))

  const updateDetail = useUpdateProgramEngagementDetails()

  const handleChange = useCallback((_: any, checked: boolean) => {
    updateDetail
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'proofOfLandControl',
            // @todo get a better year. the happenstance first requirement may not be yearly
            year: detailRequirements[0].year,
            result: {
              agreedToAttach: checked,
            },
          } as RDEqipProofOfLandControl,
        ],
      })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
  }, [])

  return (
    <Stack spacing={3}>
      <Section sx={{ flex: 1 }}>
        <Stack padding={3} spacing={3}>
          <Typography variant="h6">{t('attachProofOfControl')}</Typography>

          <Typography>{t('attachProofOfControlDescription')}</Typography>

          <Stack direction="row" alignItems="center">
            <Checkbox
              name={'iAgree'}
              onChange={handleChange}
              checked={detail?.value?.agreedToAttach || false}
              data-testid={`iAgree-checkbox`}
            />
            <InputLabel htmlFor={`iAgree`}>{t('iAgree')}</InputLabel>
          </Stack>
        </Stack>
      </Section>
      <Section sx={{ flex: 1 }}>
        <Stack padding={3} spacing={3}>
          <Typography variant="h6">{t('getReady')}</Typography>

          <Typography>{t('directions')}</Typography>

          <Box>
            <Link href={t('findNrcsLink')} target={'_blank'} color="secondary">
              <Stack direction="row" alignItems="center">
                {t('findLocalNRCS')} <ChevronRightIcon />
              </Stack>
            </Link>
          </Box>
        </Stack>
      </Section>
    </Stack>
  )
}
