import { LiveStockInput, LivestockSpecies, RDLivestock } from '@cibo/core'
import { pathEq } from 'ramda'
import * as Yup from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { LivestockCell } from './LivestockCell'
import { LivestockEditor } from './LivestockEditor'
import { LivestockRollup } from './LivestockRollup'
import { LivestockSimpleCell } from './LivestockSimpleCell'

const TRAIT_ID = 'livestock'

export const Livestock: ITraitFeatureByIdYear<RDLivestock> = {
  traitId: TRAIT_ID,
  cellDisplay: LivestockCell,
  SimpleCellView: LivestockSimpleCell,
  editor: LivestockEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: LivestockRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          daysGrazing: 0,
        },
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)

    if (!detail?.input) return {}

    if (detail.result || detail.input.daysGrazing) {
      return {
        livestockPresent: 'true',
        ...detail.input,
      }
    } else if (detail?.input.daysGrazing === 0 || detail.result === false) {
      return {
        livestockPresent: 'false',
      }
    }

    return {}
  },
  ns: '@cibo/landmanager/LivestockEditor',
  // @ts-ignore I don't see where we are sending undefined for a Yup.number()
  validationSchema: ({ t }) =>
    Yup.object()
      .shape({
        species: Yup.string<LivestockSpecies>()
          .optional()
          .typeError(t('required', { context: 'species' })),
        headcount: Yup.number()
          .min(1)
          .optional()
          .typeError(t('required', { context: 'headcount' })),
        daysGrazing: Yup.number().when('livestockPresent', {
          is: 'true',
          then: (schema: any) =>
            schema
              .min(1)
              .max(365)
              .required(t('required', { context: 'daysGrazing' }))
              .typeError(t('required', { context: 'daysGrazing' })),
          otherwise: (schema: any) => schema.nullable(),
        }),
      })
      .required(),

  detailFromEditorValues: (values, requirement) => {
    const input: LiveStockInput = { daysGrazing: 0 }

    if (values.input?.livestockPresent === 'true') {
      input.daysGrazing = values.input.daysGrazing
      input.species = values.input.species
      input.headcount = values.input.headcount
    }

    return {
      traitId: TRAIT_ID,
      year: requirement?.year,
      input,
    }
  },
}
