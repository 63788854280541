import { ProgramConfig, ProgramContentListItem } from '@cibo/core'

interface ProgramIconProps {
  program: ProgramConfig | ProgramContentListItem
  size?: number
}

export const ProgramIcon = ({ program, size = 50 }: ProgramIconProps) => (
  <img
    style={{ height: `${size}px`, width: `${size}px`, objectFit: 'contain' }}
    src={`${program.content.fields.logo.fields.file?.url}`}
    srcSet={`${program.content.fields.logo.fields.file?.url}?w=${size}, ${
      program.content.fields.logo.fields.file?.url
    }?w=${size * 2} 2x, ${program.content.fields.logo.fields.file?.url}?w=${size * 3} 3x`}
    alt={program.content.fields.logo.fields.description}
    loading="lazy"
    data-testid="programIcon"
  />
)
