import { LandProfile } from '@cibo/core'
import { HTMLAttributes } from 'react'

export enum LandThumbnailSkin {
  BLANK = 'blank',
  SELECTED = 'selected',
  COLLECTION = 'collection',
  USER_FEATURE = 'USER_FEATURE',
  SMALL = 'SMALL',
  CARBON_CREDIT_POPUP_AVAILABLE = 'CARBON_CREDIT_POPUP_AVAILABLE',
  CARBON_CREDIT_POPUP_PENDING = 'CARBON_CREDIT_POPUP_PENDING',
  CARBON_CREDIT_POPUP_UNAVAILABLE = 'CARBON_CREDIT_POPUP_UNAVAILABLE',
}

export type LandThumbnailProfile = Pick<
  LandProfile,
  'geometrySlug' | 'tillableGeometrySlug' | 'name'
>
export interface LandThumbnailProps extends Partial<Pick<HTMLAttributes<'img'>, 'style'>> {
  width: number
  height: number
  skin?: LandThumbnailSkin
  pixelRatio?: number
}

export interface ThumbnailLayer {
  color?: string
  fill?: string
  width?: number
}
