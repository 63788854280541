import { FieldModel, TraitId } from '@cibo/core'
import CheckCircleRounded from '@mui/icons-material/CheckCircleRounded'
import ErrorRounded from '@mui/icons-material/ErrorRounded'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import Lock from '@mui/icons-material/Lock'
import { Stack, Typography, styled } from '@mui/material'
import AccordionSummary from '@mui/material/AccordionSummary'
import { useTranslation } from 'react-i18next'
import { OverrideWarning } from '../OverrideWarning'

interface EditYearAccordionSummaryProps {
  yearIsImmutable: boolean
  year: number
  touched: boolean
  traitId: TraitId
  isFieldDirty: boolean
  error?: string
  fieldModels: FieldModel[]
}

const StyledAccordionSummary = styled(AccordionSummary)(() => ({
  '&.Mui-disabled': {
    opacity: 1,
    '.MuiAccordionSummary-expandIconWrapper': {
      opacity: 0.38,
    },
  },
}))

export const EditYearAccordionSummary = ({
  yearIsImmutable,
  year,
  touched,
  traitId,
  isFieldDirty,
  error,
  fieldModels,
}: EditYearAccordionSummaryProps) => {
  const { t } = useTranslation('@cibo/landmanager/EditYearAccordionSummary')
  return (
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />} id={`${year}-header`}>
      <Stack direction="row" spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Typography variant="subtitle1" data-testid="section-header">
            {year}
          </Typography>
          {yearIsImmutable && (
            <Stack direction="row" spacing={1} alignItems="center">
              <Lock fontSize="small" />
              <Typography variant="caption">{t('detailIsLocked')}</Typography>
            </Stack>
          )}
          {!yearIsImmutable && !error && (
            <Stack direction="row" spacing={1} alignItems="center">
              <CheckCircleRounded
                color={touched && isFieldDirty ? 'success' : 'disabled'}
                data-testid={`${traitId}-${year}-check`}
                fontSize="small"
              />
              <Typography variant="caption">
                {touched && isFieldDirty ? t('willUpdate') : t('noChange')}
              </Typography>
            </Stack>
          )}
          {!yearIsImmutable && error && touched && (
            <Stack direction="row" spacing={1} alignItems="center">
              <ErrorRounded
                color="error"
                data-testid={`${traitId}-${year}-warn`}
                fontSize="small"
              />
              <Typography variant="caption">{t('hasErrors')}</Typography>
            </Stack>
          )}
          {!error && touched && isFieldDirty && (
            <OverrideWarning fieldModels={fieldModels} traitId={traitId} year={year} />
          )}
        </Stack>
      </Stack>
    </StyledAccordionSummary>
  )
}
