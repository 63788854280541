/* eslint-disable react/forbid-elements */
import { IconProps } from './IconProps';

export const Icon = ({ color, name, size, styleOverrides }: IconProps) => (
  <i
    className={`icon-${name}`}
    style={{
      height: size,
      width: size,
      fontSize: size,
      lineHeight: `${size}px`,
      color,
      display: 'block',
      ...styleOverrides,
    }}
  />
)
