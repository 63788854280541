import { NON_AG_CDL_LABEL } from '@cibo/core'
import { Box, Paper, Stack, Typography } from '@mui/material'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistogramLegend } from '../../../../hooks'
import { Config } from '../../../../types'
import { LayerContext } from '../LayerContext'

export const CDLLegend = () => {
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')
  const { mapStyle } = useContext(LayerContext)
  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')

  // Use the regenerated 2022 tileset for all cdl for now.
  // This tileset includes a metadata.json prototype used by rasterLegend.
  const rasterLegend = useHistogramLegend({
    maptilesServiceUrl,
    tilesetBaseUrl: `${maptilesServiceUrl}cdl/cdl/2022/v2/`,
  })

  const rasterAgLegend =
    rasterLegend && rasterLegend.filter(({ label }) => !NON_AG_CDL_LABEL.includes(label))

  if (mapStyle !== 'CDL' || typeof rasterAgLegend === 'undefined') {
    return null
  }

  if (rasterAgLegend.length === 0) {
    return null
  }

  return (
    <Paper sx={{ p: 1 }}>
      <Stack style={{ maxHeight: '100%', overflow: 'hidden' }}>
        {rasterAgLegend?.map(({ mapColor, label }) => (
          <Stack key={label} direction="row" spacing={1}>
            <Box sx={{ width: 16, height: 16, backgroundColor: mapColor }} />
            <Typography variant="body2">{cropT(label)}</Typography>
          </Stack>
        ))}
      </Stack>
    </Paper>
  )
}
