import { RDCoverCropInput } from '@cibo/core'
import { RadioBooleanField } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const CoverCropResultsOnlyEditor = ({
  filterInputs,
  year,
  name = 'coverCropEditor',
  requirement,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/CoverCropEditor')

  return (
    <Field name={name} id={name}>
      {({ field: { value }, form }: FieldProps<RDCoverCropInput>) => {
        const previousYear = year - 1

        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { year, previousYear })}</Typography>

            <Typography>{t('wasCropPlanted')}</Typography>

            <RadioBooleanField name={`${name}.result`} data-testid={`${name}.result`} />
          </Stack>
        )
      }}
    </Field>
  )
}
