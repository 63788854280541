import { ResponsiveDialog } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteUserSupplySheds, useUserSupplySheds } from '../../queries'

type DeleteSupplyShedModalProps = {
  visible: boolean
  selectedIds?: string[]
  onSuccess?: () => void
  onRequestClose(): void
}

export const DeleteSupplyShedModal = ({
  visible,
  selectedIds,
  onRequestClose,
  onSuccess,
}: DeleteSupplyShedModalProps) => {
  const { t } = useTranslation('@cibo/landmanager/SupplyShed/DeleteSupplyShedModal')
  const deleteSupplySheds = useDeleteUserSupplySheds()
  const supplySheds = useUserSupplySheds(0, 100, { resourceIds: selectedIds })

  useEffect(() => {
    if (visible) {
      deleteSupplySheds.reset()
    }
  }, [visible])

  const handleClickDelete = () => {
    !!selectedIds &&
      deleteSupplySheds.mutateAsync(selectedIds).then(() => {
        onSuccess && onSuccess()
        onRequestClose()
      })
  }

  return (
    <ResponsiveDialog open={visible} onClose={onRequestClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('title', { count: selectedIds?.length })}</DialogTitle>

      <DialogContent>
        {supplySheds.data?.items?.map((response: any) => (
          <Typography key={response.resourceId}>{response.name}</Typography>
        ))}
      </DialogContent>

      <DialogActions>
        <Button onClick={onRequestClose}>{t('cancel')}</Button>
        <LoadingButton
          color="error"
          loading={deleteSupplySheds.isPending}
          variant="contained"
          onClick={handleClickDelete}
        >
          {t('delete')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
