import {
  RDEqipEligibilityConservationACEP,
  RDEqipEligibilityConservationCRP,
  RDEqipEligibilityConservationCSP,
} from '@cibo/core'
import { pathEq } from 'ramda'
import { object, string } from 'yup'
import { ITraitFeatureByIdYear } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { ConservationProgramsCell } from './ConservationProgramsCell'
import { ConservationProgramsCellEditor } from './ConservationProgramsCellEditor'
import { ConservationProgramsEditor } from './ConservationProgramsEditor'
import { ConservationProgramsRollup } from './ConservationProgramsRollup'
import { ProgramAbreviation, ProgramAccessor, ProgramTraitId } from './ConservationProgramsTypes'

export const conservationProgram: <
  T extends
    | RDEqipEligibilityConservationCSP
    | RDEqipEligibilityConservationACEP
    | RDEqipEligibilityConservationCRP
>(
  programAccessor: ProgramAccessor,
  programAbbreviation: ProgramAbreviation,
  programTraitId: ProgramTraitId,
  ns: string
) => ITraitFeatureByIdYear<T> = (programAccessor, programAbbreviation, ProgramTraitId, ns) => ({
  traitId: ProgramTraitId,
  cellDisplay: ConservationProgramsCell(programAccessor),
  editor: ConservationProgramsEditor(programAccessor),
  cellEditor: ConservationProgramsCellEditor(programAccessor),
  rollups: {
    default: {
      traitId: ProgramTraitId,
      Component: ConservationProgramsRollup(programAbbreviation),
      detailBaseValue: () => ({
        traitId: ProgramTraitId,
        input: {
          [programAccessor]: 'no',
        },
      }),
      isRollupValue: pathEq(['result', programAccessor], 'no'),
    },
  },
  initialValues: request => findInitialValuesDetail(ProgramTraitId)(request)?.input as any,
  ns: '@cibo/landmanager/ConservationPrograms',
  validationSchema: ({ t }) =>
    object()
      .shape({
        cspEnrolled: string()
          .oneOf(['yes', 'no', 'unknown'])
          .required(t('conservationResponseRequired')),
      })
      .required(),
})
