import { RDGrazingEvent } from '@cibo/core'
import { Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const GrazingCellDisplay = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/GrazingEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.events?.length) {
    return (
      <Tooltip
        title={
          <Stack spacing={1} padding={1}>
            {detail.input?.events.map(
              ({ startDate, endDate, species, stockingRate }: RDGrazingEvent, index: number) => (
                <Stack key={index}>
                  <Typography variant="body1" fontWeight="bold">
                    {t('eventDate', { date: new Date(startDate) })} -{' '}
                    {t('eventDate', { date: new Date(endDate) })}
                  </Typography>
                  <Typography variant="body2">
                    {t('species', { context: species })} -{' '}
                    {t('stockingRateCount', { count: stockingRate })}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
        }
      >
        <Typography variant="body1">
          {t('events', { count: detail.input?.events?.length })}
        </Typography>
      </Tooltip>
    )
  }

  return <Typography>{t('none')}</Typography>
}
