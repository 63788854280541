import { EqipApplicationResultsParams, RDEqipApplicationResults } from '@cibo/core'
import { Loading } from '@cibo/profile'
import {
  ResourceDetailFeatureTaskEditorProps,
  Section,
  useSaveIndicator,
  useTraitContent,
} from '@cibo/ui'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
  TextField,
} from '@mui/material'
import debounce from 'lodash/debounce'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementDetails } from '../../../../hooks/useProgramEngagementDetails'
import {
  useProgramEngagement,
  useProgramModel,
  useUploadEqipApplicationResults,
} from '../../../../queries'
import { ScheduleOfOperationsUpload } from './ScheduleOfOperationsUpload'

export const RDEqipApplicationResultsTaskEditor = ({
  detailRequirements,
  resourceIds: [resourceId],
}: ResourceDetailFeatureTaskEditorProps<RDEqipApplicationResults>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipApplicationResultsTaskEditor')
  const content = useTraitContent('eqipOptionalForms')
  const { data: details, isFetchedAfterMount } = useProgramEngagementDetails({
    resourceId,
    detailRequirements,
  })
  const { setIsDirty, setIsError, setIsSaving } = useSaveIndicator()
  const uploadEqipApplicationResults = useUploadEqipApplicationResults()
  const engagement = useProgramEngagement({ id: resourceId })
  const programModel = useProgramModel({ programId: engagement.data?.programId })
  const benchmark = programModel.data?.benchmarks?.find(
    ({ benchmark }) => benchmark === 'applicationResults'
  )
  const existingDetail: RDEqipApplicationResults | Record<any, any> = useMemo(
    () => (!!details?.length ? details[0].result : {}),
    [details]
  )
  const [contractValue, setContractValue] = useState<number>(existingDetail?.totalContractValue)

  const hasFile = !!existingDetail?.file?.length
  const displayFileInput =
    (existingDetail?.applicationApproved && existingDetail?.responseReceived) || hasFile

  const onChange = (args: Omit<EqipApplicationResultsParams, 'id'>) => {
    setIsSaving(true)
    setIsDirty(true)
    const { responseReceived, applicationApproved, totalContractValue } = existingDetail
    uploadEqipApplicationResults
      .mutateAsync({
        id: resourceId,
        year: benchmark && benchmark.details[0].year,
        responseReceived,
        applicationApproved,
        totalContractValue,
        ...args,
      })
      .catch(() => setIsError(true))
      .finally(() => setIsSaving(false))
  }

  const debouncedOnChange = useCallback(
    debounce((args: Omit<EqipApplicationResultsParams, 'id'>) => {
      onChange(args)
    }, 200),
    [details]
  )

  useEffect(() => {
    setContractValue(existingDetail?.totalContractValue)
  }, [isFetchedAfterMount])

  if (content.isPending) {
    return <Loading />
  }

  return (
    <Stack spacing={3} flex={1}>
      <Section
        sx={{
          flex: 1,
          marginBottom: displayFileInput ? undefined : -3,
          transition: 'margin-bottom 0.2s ease-in-out',
        }}
      >
        <Stack padding={3} spacing={3}>
          <FormControl
            component="fieldset"
            variant="standard"
            error={uploadEqipApplicationResults.isError}
          >
            <FormLabel id="response-received-radio-buttons-group-label">
              {t('didYouHearBack')}
            </FormLabel>
            <RadioGroup
              aria-labelledby="response-received-radio-buttons-group-label"
              value={existingDetail?.responseReceived ?? ''}
              onChange={e => onChange({ responseReceived: e.target.value === 'true' })}
            >
              <FormControlLabel
                data-testid="yesBtn"
                value="true"
                control={<Radio />}
                label={t('yes')}
                disabled={uploadEqipApplicationResults.isPending || hasFile}
              />
              <FormControlLabel
                data-testid="noBtn"
                value="false"
                control={<Radio />}
                label={t('no')}
                disabled={uploadEqipApplicationResults.isPending || hasFile}
              />
            </RadioGroup>
          </FormControl>
          <Collapse in={existingDetail?.responseReceived ?? ''}>
            <FormControl
              component="fieldset"
              variant="standard"
              error={uploadEqipApplicationResults.isError}
            >
              <FormLabel id="application-approved-radio-buttons-group-label">
                {t('wereYouApproved')}
              </FormLabel>
              <RadioGroup
                aria-labelledby="application-approved-radio-buttons-group-label"
                value={existingDetail?.applicationApproved ?? ''}
                onChange={e => onChange({ applicationApproved: e.target.value === 'true' })}
              >
                <FormControlLabel
                  data-testid="approvedBtn"
                  value="true"
                  control={<Radio />}
                  label={t('applicationApproved')}
                  disabled={uploadEqipApplicationResults.isPending || hasFile}
                />
                <FormControlLabel
                  data-testid="notApprovedBtn"
                  value="false"
                  control={<Radio />}
                  label={t('applicationNotApproved')}
                  disabled={uploadEqipApplicationResults.isPending || hasFile}
                />
              </RadioGroup>
            </FormControl>
          </Collapse>
        </Stack>
      </Section>
      <Collapse in={displayFileInput} timeout={0.2}>
        <Section sx={{ flex: 1 }}>
          <Stack padding={3} spacing={3}>
            <ScheduleOfOperationsUpload
              currentFile={existingDetail?.file}
              currentDetail={{
                ...existingDetail,
                year: benchmark && benchmark.details[0].year,
              }}
              programEngagementId={resourceId}
            />
            <FormControl
              component="fieldset"
              variant="standard"
              error={uploadEqipApplicationResults.isError}
            >
              <FormLabel id="contract-value-radio-buttons-group-label">{t('totalValue')}</FormLabel>
              <TextField
                value={contractValue ?? ''}
                InputProps={{
                  type: 'numeric',
                  startAdornment: <InputAdornment position="start">$</InputAdornment>,
                  // disabled: uploadEqipApplicationResults.isPending,
                }}
                placeholder={t('enterValue')}
                onChange={event => {
                  setContractValue(+event.target.value)
                  debouncedOnChange({ totalContractValue: +event.target.value })
                }}
              />
            </FormControl>
          </Stack>
        </Section>
      </Collapse>
    </Stack>
  )
}
