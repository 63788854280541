import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const CashCropCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/CashCropEditor')
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')
  const detail = params.row.resolveStandingDetail(traitId, year)

  const { plantedAt, harvestedAt } = detail.input || {}

  if (detail.input?.crop) {
    return (
      <Stack>
        <Typography variant="body1">{cropT(detail.input?.crop)}</Typography>
        {!!plantedAt && !!harvestedAt && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('date', { date: new Date(plantedAt) })} -{' '}
            {t('date', { date: new Date(harvestedAt) })}
          </Typography>
        )}
      </Stack>
    )
  }

  return <Typography>{cropT(detail.result)}</Typography>
}
