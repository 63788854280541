/* istanbul ignore file */
import { CollectionFetchAllResult, CollectionsFetchRequest } from '../types'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  COLLECTION: '/collection',
  COLLECTION_BY_ID: '/collection/:collectionId',
  AGGREGATE_LAND_PROFILE: '/data/landProfile/aggregate',
  EDITOR_CONTEXT: '/mpx/editorContext',
  COLLECTION_SEARCH: '/collection/fullTextSearch',
}

export class CollectionApi {
  static fetchCollection(collectionId: string): Promise<CollectionFetchAllResult> {
    //@ts-ignore
    return agent.get(generatePath(PATHS.COLLECTION_BY_ID, { collectionId }))
  }

  static fetchCollections(params?: CollectionsFetchRequest): Promise<CollectionFetchAllResult> {
    const { collectionIds, ...rest } = params || {}

    const sanitizedCollectionIds = collectionIds ? collectionIds.join(',') : undefined

    //@ts-ignore
    return agent.get(PATHS.COLLECTION, {
      params: { ...rest, collectionIds: sanitizedCollectionIds },
    })
  }
}
