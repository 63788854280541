import {
  AuthDirectLoginActionTypes,
  AuthDirectLoginError,
  AuthDirectLogoutActionTypes,
  AuthDirectRequestVerificationCodeActionTypes,
  AuthDirectRequestVerificationCodeError,
  AuthSessionActionTypes,
  MP_AUTH_DIRECT_LOGIN_ERROR,
  MP_AUTH_DIRECT_LOGIN_PENDING,
  MP_AUTH_DIRECT_LOGIN_SUCCESS,
  MP_AUTH_DIRECT_LOGOUT_SUCCESS,
  MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR,
  MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING,
  MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS,
  MP_AUTH_RESET_FORGOT_PASSSWORD_STATE,
  MP_AUTH_SESSION_INVALID,
  MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS,
  RegisterVerifiedUserSetLoginActions,
} from '@cibo/core'

export interface AuthDirectLoginState {
  error?: AuthDirectLoginError
  requestCodeError?: AuthDirectRequestVerificationCodeError
  pending: boolean
  sendingVerifyCode: boolean
  verifyingCode?: boolean
}

const initialState: AuthDirectLoginState = {
  pending: false,
  verifyingCode: false,
  sendingVerifyCode: false,
}

export const directLoginReducer = (
  state = initialState,
  action:
    | AuthDirectLoginActionTypes
    | AuthSessionActionTypes
    | RegisterVerifiedUserSetLoginActions
    | AuthDirectLogoutActionTypes
    | AuthDirectRequestVerificationCodeActionTypes
) => {
  switch (action.type) {
    case MP_AUTH_RESET_FORGOT_PASSSWORD_STATE:
      return { ...state, verifyingCode: false, requestCodeError: undefined }
    case MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING:
      return { ...state, sendingVerifyCode: true, requestCodeError: undefined }
    case MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR:
      return { ...state, sendingVerifyCode: false, requestCodeError: action.error }
    case MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS:
      return {
        ...state,
        sendingVerifyCode: false,
        verifyingCode: true,
      }

    case MP_AUTH_DIRECT_LOGIN_PENDING:
      return {
        ...state,
        pending: true,
      }
    case MP_AUTH_DIRECT_LOGIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      }

    case MP_AUTH_DIRECT_LOGIN_SUCCESS: {
      return {
        ...state,
        pending: false,
        error: undefined,
      }
    }

    case MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS: {
      return {
        ...state,
        pending: false,
        error: undefined,
      }
    }

    case MP_AUTH_DIRECT_LOGOUT_SUCCESS:
    case MP_AUTH_SESSION_INVALID:
      return { ...initialState }

    default:
      return state
  }
}

interface LocalState {
  authReducer: { directLogin: AuthDirectLoginState }
}

const selectBase = (state: LocalState) => state.authReducer.directLogin
export const selectAuthDirectLoginLoading = (state: LocalState) => selectBase(state).pending
export const selectAuthDirectLoginError = (state: LocalState) => selectBase(state).error
