import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const FutureTillageCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {t(`tillage_${detail?.result}`)}
    </Typography>
  )
}
