import { ProgramStageType } from '@cibo/core/src/types/Program/Binding'

export const ENGAGEMENT_PATHS = {
  ROOT: '',
  ENGAGEMENT: ':engagementId',
  STAGE: ':stageId',
  STATUS: 'status',
  STEP: ':stepId',
  TASK: ':taskId',
}

export const ENGAGEMENT_ROUTES = {
  ROOT: ENGAGEMENT_PATHS.ROOT,
  ENGAGEMENT: [ENGAGEMENT_PATHS.ROOT, ENGAGEMENT_PATHS.ENGAGEMENT].join('/'),
  STAGE: [ENGAGEMENT_PATHS.ROOT, ENGAGEMENT_PATHS.ENGAGEMENT, ENGAGEMENT_PATHS.STAGE].join('/'),
  STEP: [
    ENGAGEMENT_PATHS.ROOT,
    ENGAGEMENT_PATHS.ENGAGEMENT,
    ENGAGEMENT_PATHS.STAGE,
    ENGAGEMENT_PATHS.STEP,
  ].join('/'),
  STEP_STATUS: [
    ENGAGEMENT_PATHS.ROOT,
    ENGAGEMENT_PATHS.ENGAGEMENT,
    ENGAGEMENT_PATHS.STAGE,
    ENGAGEMENT_PATHS.STEP,
    ENGAGEMENT_PATHS.STATUS,
  ].join('/'),
  TASK: [
    ENGAGEMENT_PATHS.ROOT,
    ENGAGEMENT_PATHS.ENGAGEMENT,
    ENGAGEMENT_PATHS.STAGE,
    ENGAGEMENT_PATHS.STEP,
    ENGAGEMENT_PATHS.TASK,
  ].join('/'),
}

export type EngagementRouteParams = {
  engagementId?: string
  stageId?: ProgramStageType
  stepId?: string
  taskId?: string
}
