import { RDEqipEligibilityWetlandCompliance, TraitId } from '@cibo/core'
import { path } from 'ramda'
import { boolean, object } from 'yup'
import { ITraitFeatureByIdYear } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { WetlandComplianceCTA } from './WetlandComplianceCTA'
import { WetlandComplianceCell } from './WetlandComplianceCell'
import { WetlandComplianceCellEditor } from './WetlandComplianceCellEditor'
import { WetlandComplianceEditor } from './WetlandComplianceEditor'

const TRAIT_ID: TraitId = 'eqipEligibilityWetlandCompliance'

export const WetlandCompliance: ITraitFeatureByIdYear<RDEqipEligibilityWetlandCompliance> = {
  traitId: TRAIT_ID,
  editor: WetlandComplianceEditor,
  cellEditor: WetlandComplianceCellEditor,
  cellDisplay: WetlandComplianceCell,

  tableCta: WetlandComplianceCTA,
  rollups: {
    row: {
      traitId: TRAIT_ID,
      traitValue: path(['result', 'wetlandCompliance']),
      detailBaseValue: (value: boolean) => ({
        input: {
          wetlandCompliance: value,
        },
      }),
    },
  },
  ns: '@cibo/landmanager/WetlandCompliance',
  validationSchema: ({ t }) =>
    object()
      .shape({
        wetlandCompliance: boolean().required(t('required')),
      })
      .required(),
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
}
