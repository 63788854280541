import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material'
import { Field, useField } from 'formik'
import { TextField as FMTextField } from 'formik-mui'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DetailEditorPropsByYear } from '../types'

type DidBurnOptions = 'yes' | 'no'

export const BiomassBurningInputEditor = ({
  name = 'biomassBurning',
  year,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/BiomassBurningEditor')
  // eslint-disable-next-line no-empty-pattern
  const [{}, {}, { setTouched }] = useField(name)
  // eslint-disable-next-line no-empty-pattern
  const [{ value }, {}, { setValue }] = useField(`${name}.percentage`)
  const [didBurn, setDidBurn] = useState<DidBurnOptions | undefined>(
    value === 0 ? 'no' : value !== undefined ? 'yes' : undefined
  )

  const handleDidBurnChange = (event: SelectChangeEvent) => {
    setDidBurn(event.target.value as DidBurnOptions)
    setTouched(true, false)
    // yeah, empty string :( necessary to clear the input value
    event.target.value === 'no' ? setValue(0) : setValue('')
  }

  return (
    <Stack spacing={2}>
      <Typography>{t('cta', { year })}</Typography>

      <FormControl data-testid="biomass-did-burn" required>
        <FormLabel>{t('didYouBurn')}</FormLabel>

        <RadioGroup
          id={`${name}.didBurn`}
          defaultValue={didBurn}
          onChange={handleDidBurnChange}
          row
        >
          <FormControlLabel
            value="yes"
            control={<Radio />}
            label={t('yes')}
            data-testid={`${name}.didBurn-yes`}
          />
          <FormControlLabel value="no" control={<Radio />} label={t('no')} />
        </RadioGroup>
      </FormControl>

      <Collapse in={didBurn === 'yes'}>
        <FormControl required>
          <FormLabel htmlFor={`${name}.percentage`}>{t('percentBurned')}</FormLabel>

          <Field
            component={FMTextField}
            name={`${name}.percentage`}
            InputProps={{
              type: 'number',
              inputProps: {
                step: 1,
                min: 0,
                'data-testid': `${name}.percentage`,
              },
            }}
          />
        </FormControl>
      </Collapse>
    </Stack>
  )
}
