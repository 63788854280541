import React, { useEffect, useState, useCallback } from 'react'
import { MenuItem, Paper, Stack, Typography, Box, Autocomplete, TextField } from '@mui/material'
import { useSupplyShedSearch } from './useSupplyShedSearch'
import Markdown from 'markdown-to-jsx'

import { SupplyShedSearchResult } from '@cibo/core'
import { styled } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { ShedTypeSelector } from './ShedTypeSelector'
import { useSupplyShedFilters } from '../../hooks'

const StyledTextField = styled(TextField)({
  '& input + fieldset, & input:valid + fieldset': {
    borderWidth: 0,
  },
  '& input:focus + fieldset, & input:valid:focus + fieldset': {
    borderWidth: 0,
    outline: 'none',
  },
})

interface SearchControlProps {
  onSelectResult: (result: SupplyShedSearchResult) => void
}

export const SearchControl = ({ onSelectResult }: SearchControlProps) => {
  const { t } = useTranslation('@cibo/landmanager/SupplyShed/Search')
  const { isFetching, data, queryText, setQueryText } = useSupplyShedSearch()
  const [open, setOpen] = useState(false)
  const { aggregationType } = useSupplyShedFilters()

  useEffect(() => {
    setOpen(true)
  }, [aggregationType])

  const handleInputChange = (event: React.SyntheticEvent, newInputValue: string) => {
    setQueryText(newInputValue)
  }

  const handleChange = (
    event: React.SyntheticEvent,
    value: string | SupplyShedSearchResult | null,
    reason: string
  ) => {
    if (typeof value === 'string' || !value) {
      return
    }

    if (reason === 'selectOption') {
      onSelectResult(value)
    }
  }

  const labelForOption = useCallback(
    (option: SupplyShedSearchResult | string) =>
      typeof option === 'string' ? option : option.matchTitle.replace(/\*\*/g, '') || '',
    []
  )

  return (
    <Paper>
      <Stack direction="row" style={{ pointerEvents: 'all' }}>
        <Stack spacing={1} sx={{ pointerEvents: 'all' }} alignSelf="flex-end">
          <Box>
            <Stack direction="row">
              <Autocomplete
                freeSolo
                open={open}
                onOpen={() => {
                  setOpen(true)
                }}
                onClose={() => {
                  setOpen(false)
                }}
                options={data || []}
                getOptionLabel={labelForOption}
                inputValue={queryText}
                loading={isFetching}
                noOptionsText={t('noResults')}
                renderInput={params => <StyledTextField {...params} />}
                renderOption={(
                  props,
                  { matchTitle, matchSubtitle, resourceId }: SupplyShedSearchResult
                ) => (
                  <MenuItem {...props} key={resourceId}>
                    <Stack>
                      <Typography>
                        <Markdown>{matchTitle}</Markdown>
                      </Typography>

                      {!!matchSubtitle && (
                        <Typography variant="caption">
                          <Markdown>{matchSubtitle}</Markdown>
                        </Typography>
                      )}
                    </Stack>
                  </MenuItem>
                )}
                onInputChange={handleInputChange}
                onChange={handleChange}
                sx={{
                  minWidth: '20rem',
                  '& .MuiOutlinedInput-root': {
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                  },
                }}
              />
            </Stack>
          </Box>
        </Stack>

        <ShedTypeSelector />
      </Stack>
    </Paper>
  )
}
