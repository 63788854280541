import { RDTillage } from '@cibo/core'
import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EventDetailEditor } from '../EventDetail'
import { DetailEditorPropsByYear } from '../types'
import { TillageEventEditor } from './TillageEventEditor'

export const TillageEditor = ({
  fieldModels,
  year,
  name = 'tillageEditor',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/TillageEditor')

  const TillageEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: 80 }}>
          <Typography>{t('date')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('implement')}</Typography>
        </TableCell>
        <TableCell sx={{ minWidth: 60 }}>
          <Typography>{t('depth')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('residueRemaining')}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )

  return (
    <EventDetailEditor<RDTillage['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={TillageEventTableHead}
      EventComponent={TillageEventEditor}
      translationContext="tillage"
      defaultEvent={{ date: null, implement: '' }}
      modal
    />
  )
}
