import { Theme } from './Theme'

// Colors
const colors = [
  Theme.gradient.scores.veryLow.stops[0].color,
  Theme.gradient.scores.low.stops[0].color,
  Theme.gradient.scores.average.stops[0].color,
  Theme.gradient.scores.high.stops[0].color,
  Theme.gradient.scores.veryHigh.stops[0].color,
]
const blueGrey50 = Theme.color.text.main.emphasis.low
const blueGrey700 = Theme.color.text.main.emphasis.high
const grey900 = Theme.color.text.main.default

// Typography
const letterSpacing = 'normal'
const fontSize = 12

// Layout
const padding = 8
const baseProps = {
  width: 600,
  height: 300,
  padding: 50,
}

// * Labels
const baseLabelStyles = {
  fontFamily: Theme.font.primary,
  fontWeight: 600,
  fontSize,
  letterSpacing,
  padding,
  fill: Theme.color.text.main.emphasis.low,
  stroke: 'transparent',
  strokeWidth: 0,
}

const centeredLabelStyles = Object.assign({ textAnchor: 'middle' }, baseLabelStyles)

// Strokes
const strokeLinecap = 'round'
const strokeLinejoin = 'round'

export const defaultVictoryThemeComponents = {
  baseProps,
  baseLabelStyles,
  centeredLabelStyles,
  fontSize,
  letterSpacing,
  padding,
  strokeLinecap,
  strokeLinejoin,
}

// Put it all together...
export const defaultVictoryTheme = {
  area: Object.assign(
    {
      style: {
        data: {
          fill: grey900,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  axis: Object.assign(
    {
      style: {
        axis: {
          stroke: Theme.color.surface.main.secondary,
          strokeWidth: 1.5,
          strokeLinecap,
          strokeLinejoin,
        },
        axisLabel: baseLabelStyles,
        grid: {
          stroke: Theme.color.surface.main.secondary,
          strokeLinecap,
          strokeLinejoin,
          strokeDasharray: '2,3',
        },
        tickLabels: baseLabelStyles,
      },
    },
    baseProps
  ),
  polarDependentAxis: Object.assign({
    style: {
      ticks: {
        fill: 'transparent',
        size: 1,
        stroke: 'transparent',
      },
    },
  }),
  bar: Object.assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          padding,
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  boxplot: Object.assign(
    {
      style: {
        max: { padding, stroke: blueGrey700, strokeWidth: 1 },
        maxLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        median: { padding, stroke: blueGrey700, strokeWidth: 1 },
        medianLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        min: { padding, stroke: blueGrey700, strokeWidth: 1 },
        minLabels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        q1: { padding, fill: blueGrey700 },
        q1Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
        q3: { padding, fill: blueGrey700 },
        q3Labels: Object.assign({}, baseLabelStyles, { padding: 3 }),
      },
      boxWidth: 20,
    },
    baseProps
  ),
  candlestick: Object.assign(
    {
      style: {
        data: {
          stroke: blueGrey700,
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 5 }),
      },
      candleColors: {
        positive: '#ffffff',
        negative: blueGrey700,
      },
    },
    baseProps
  ),
  chart: baseProps,
  errorbar: Object.assign(
    {
      borderWidth: 8,
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  group: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  histogram: Object.assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          stroke: grey900,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  legend: {
    colorScale: colors,
    gutter: 10,
    orientation: 'vertical',
    titleOrientation: 'top',
    style: {
      data: {
        type: 'circle',
      },
      labels: baseLabelStyles,
      title: Object.assign({}, baseLabelStyles, { padding: 5 }),
    },
  },
  line: Object.assign(
    {
      style: {
        data: {
          fill: 'transparent',
          opacity: 1,
          stroke: blueGrey700,
          strokeWidth: 2,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  pie: Object.assign(
    {
      colorScale: colors,
      style: {
        data: {
          padding,
          stroke: blueGrey50,
          strokeWidth: 1,
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 20 }),
      },
    },
    baseProps
  ),
  scatter: Object.assign(
    {
      style: {
        data: {
          fill: blueGrey700,
          opacity: 1,
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: baseLabelStyles,
      },
    },
    baseProps
  ),
  stack: Object.assign(
    {
      colorScale: colors,
    },
    baseProps
  ),
  tooltip: {
    style: Object.assign({}, baseLabelStyles, { padding: 0, pointerEvents: 'none' }),
    flyoutStyle: {
      stroke: grey900,
      strokeWidth: 1,
      fill: '#f0f0f0',
      pointerEvents: 'none',
    },
    flyoutPadding: 5,
    cornerRadius: 5,
    pointerLength: 10,
  },
  voronoi: Object.assign(
    {
      style: {
        data: {
          fill: 'transparent',
          stroke: 'transparent',
          strokeWidth: 0,
        },
        labels: Object.assign({}, baseLabelStyles, { padding: 5, pointerEvents: 'none' }),
        flyout: {
          stroke: grey900,
          strokeWidth: 1,
          fill: '#f0f0f0',
          pointerEvents: 'none',
        },
      },
    },
    baseProps
  ),
}
