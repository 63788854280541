import { RDEqipPracticesSeeps } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesSeepsResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSeeps> =
  {
    traitId: 'eqipPracticesSeeps',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSeeps>) => (
      <BooleanRollup<RDEqipPracticesSeeps>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } }}
        detailKey="seep"
        ns="@cibo/landmanager/RDEqipPracticesSeepsResourceDetailFeature"
        {...props}
      />
    ),
  }
