import geoutils from './helper/geo'

export default class MultiPolygon {
  constructor(options = {}) {
    this.options = options
    this.coords = this.options.coords
    this.color = this.options.color || '#000000BB'
    this.fill = this.options.fill
    this.width = Number.isFinite(this.options.width) ? Number(this.options.width) : 3
    this.simplify = this.options.simplify || false
  }

  /**
   * calculate the coordinates of the envelope / bounding box: (min_lon, min_lat, max_lon, max_lat)
   */
  extent() {
    const allPoints = this.coords[0]

    return [
      Math.min(...allPoints.map(c => c[0])),
      Math.min(...allPoints.map(c => c[1])),
      Math.max(...allPoints.map(c => c[0])),
      Math.max(...allPoints.map(c => c[1])),
    ]
  }

  toSVG(context) {
    const shapeArrays = this.coords.map(shape =>
      shape.map(coord => [
        context.xToPx(geoutils.lonToX(coord[0], context.zoom)),
        context.yToPx(geoutils.latToY(coord[1], context.zoom)),
      ])
    )

    const pathArrays = shapeArrays.map(points => {
      const startPoint = points.shift()

      const pathParts = [
        `M ${startPoint[0]} ${startPoint[1]}`,
        ...points.map(p => `L ${p[0]} ${p[1]}`),
        'Z',
      ]

      return pathParts.join(' ')
    })

    return `<path
    d="${pathArrays.join(' ')}"
    style="fill-rule: inherit;"
    stroke="${this.color}"
    fill="${this.fill ? this.fill : 'none'}"
    stroke-width="${this.width}"/>`
  }
}
