import { Button, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import { ResponsiveDialog } from '@cibo/ui'
import { useTranslation } from 'react-i18next'

type DeleteSupplyShedModalProps = {
  visible: boolean
  selectedIds?: string[]
  onSuccess?: () => void
  onRequestClose(): void
}

export const ShareSupplyShedModal = ({
  visible,
  selectedIds,
  onRequestClose,
  onSuccess,
}: DeleteSupplyShedModalProps) => {
  const { t } = useTranslation('@cibo/landmanager/SupplyShed/ShareSupplyShedModal')

  return (
    <ResponsiveDialog open={visible} onClose={onRequestClose} fullWidth maxWidth="sm">
      <DialogTitle>{t('title', { count: selectedIds?.length })}</DialogTitle>

      <DialogContent></DialogContent>

      <DialogActions>
        <Button onClick={onRequestClose}>cancel</Button>
        <LoadingButton loading={true} variant="contained" onClick={console.log}>
          SHARE
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
