import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const ByrAppliedSlurryOnceCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/ByrAppliedSlurryOnce')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Stack>
      <Typography>{t(`applied_${detail.input?.applied ?? detail.result}`)}</Typography>
      {detail.input && (
        <Typography variant="caption">{t(`isSlurry_${detail.input?.isSlurry}`)}</Typography>
      )}
    </Stack>
  )
}
