import { useTheme } from '@mui/material'
import { Fragment, PropsWithChildren, useMemo } from 'react'
import { Layer, Source } from 'react-map-gl/dist/es5'
import { MapTheme } from '../const'

import { Config } from '../../../types'

export const HUC_WATERSHED_SOURCE_ID = 'huc-watershed'
export const HUC2_HOVER_LAYER = 'huc2-hover'
export const HUC4_HOVER_LAYER = 'huc4-hover'
export const HUC6_HOVER_LAYER = 'huc6-hover'
export const HUC8_HOVER_LAYER = 'huc8-hover'
export const HUC10_HOVER_LAYER = 'huc10-hover'
export const HUC12_HOVER_LAYER = 'huc12-hover'
export const HUC_HOVER_LAYERS = [
  HUC2_HOVER_LAYER,
  HUC4_HOVER_LAYER,
  HUC6_HOVER_LAYER,
  HUC8_HOVER_LAYER,
  HUC10_HOVER_LAYER,
  HUC12_HOVER_LAYER,
]

export const HUC2_LABEL_SOURCE_LAYER = 'huc2-labels-combine'
export const HUC4_LABEL_SOURCE_LAYER = 'huc4-labels-combine'
export const HUC6_LABEL_SOURCE_LAYER = 'huc6-labels-combine'
export const HUC8_LABEL_SOURCE_LAYER = 'huc8-labels-combine'
export const HUC10_LABEL_SOURCE_LAYER = 'huc10-labels-combine'
export const HUC12_LABEL_SOURCE_LAYER = 'huc12-labels-combine'

export const HUC2_SHAPE_SOURCE_LAYER = 'huc2-shapes'
export const HUC4_SHAPE_SOURCE_LAYER = 'huc4-shapes'
export const HUC6_SHAPE_SOURCE_LAYER = 'huc6-shapes'
export const HUC8_SHAPE_SOURCE_LAYER = 'huc8-shapes'
export const HUC10_SHAPE_SOURCE_LAYER = 'huc10-shapes'
export const HUC12_SHAPE_SOURCE_LAYER = 'huc12-shapes'
export const HUC_SHAPE_SOURCE_LAYER = [
  HUC2_SHAPE_SOURCE_LAYER,
  HUC4_SHAPE_SOURCE_LAYER,
  HUC6_SHAPE_SOURCE_LAYER,
  HUC8_SHAPE_SOURCE_LAYER,
  HUC10_SHAPE_SOURCE_LAYER,
  HUC12_SHAPE_SOURCE_LAYER,
]

const LAYERS = [
  {
    id: HUC2_HOVER_LAYER,
    sourceShape: HUC2_SHAPE_SOURCE_LAYER,
    sourceLabel: HUC2_LABEL_SOURCE_LAYER,
    color: 'hsl(230, 100%, 50%)',
    minZoom: 1,
    maxZoom: 5,
  },
  {
    id: HUC4_HOVER_LAYER,
    sourceShape: HUC4_SHAPE_SOURCE_LAYER,
    sourceLabel: HUC4_LABEL_SOURCE_LAYER,
    color: 'hsl(220, 100%, 50%)',
    minZoom: 4,
    maxZoom: 6,
  },
  {
    id: HUC6_HOVER_LAYER,
    sourceShape: HUC6_SHAPE_SOURCE_LAYER,
    sourceLabel: HUC6_LABEL_SOURCE_LAYER,
    color: 'hsl(210, 100%, 50%)',
    minZoom: 5,
    maxZoom: 7,
  },
  {
    id: HUC8_HOVER_LAYER,
    sourceShape: HUC8_SHAPE_SOURCE_LAYER,
    sourceLabel: HUC8_LABEL_SOURCE_LAYER,
    color: 'hsl(200, 100%, 50%)',
    minZoom: 6,
    maxZoom: 8,
  },
  {
    id: HUC10_HOVER_LAYER,
    sourceShape: HUC10_SHAPE_SOURCE_LAYER,
    sourceLabel: HUC10_LABEL_SOURCE_LAYER,
    color: 'hsl(190, 100%, 50%)',
    minZoom: 7,
    maxZoom: 10,
  },
  {
    id: HUC12_HOVER_LAYER,
    sourceShape: HUC12_SHAPE_SOURCE_LAYER,
    sourceLabel: HUC12_LABEL_SOURCE_LAYER,
    color: 'hsl(180, 100%, 50%)',
    minZoom: 9,
  },
]

interface HUCWatershedLayerProps {
  disabled: boolean
}

export const HUCWatershedLayer = ({
  children,
  disabled,
}: PropsWithChildren<HUCWatershedLayerProps>) => {
  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')
  const tiles = useMemo(
    () => [`${maptilesServiceUrl}huc-watersheds/{z}/{x}/{y}.pbf`],
    [maptilesServiceUrl]
  )
  const theme = useTheme<MapTheme>()

  return (
    <Source id={HUC_WATERSHED_SOURCE_ID} type="vector" tiles={tiles} minzoom={1} maxzoom={12}>
      {LAYERS.map(({ id, color, minZoom, maxZoom }, i) => (
        <Layer
          key={`${id}-${color}`}
          id={`${id}-outline`}
          type="line"
          beforeId="data-layer-anchor"
          source={HUC_WATERSHED_SOURCE_ID}
          source-layer={`huc${(i + 1) * 2}-shapes`}
          layout={{ visibility: disabled ? 'none' : 'visible' }}
          paint={{
            'line-color': color,
            'line-opacity': maxZoom
              ? [
                  'interpolate',
                  ['linear'],
                  ['zoom'],
                  minZoom,
                  0,
                  minZoom + 0.5,
                  1,
                  maxZoom - 0.5,
                  1,
                  maxZoom,
                  0,
                ]
              : ['interpolate', ['linear'], ['zoom'], minZoom, 0, minZoom + 0.5, 1],
            'line-width': ['interpolate', ['linear'], ['zoom'], minZoom, 0.75, maxZoom || 12, 5],
          }}
        />
      ))}
      {LAYERS.map(({ id, sourceShape }, i) => (
        <Fragment key={id}>
          <Layer
            source={HUC_WATERSHED_SOURCE_ID}
            source-layer={sourceShape}
            beforeId="data-layer-anchor"
            id={id}
            type="fill"
            layout={{ visibility: disabled ? 'none' : 'visible' }}
            paint={{
              'fill-color': [
                'case',
                ['boolean', ['feature-state', 'select'], false],
                theme.palette.selectFill,
                ['boolean', ['feature-state', 'hover'], false],
                theme.palette.hoverFill,
                'transparent',
              ],
            }}
          />
          <Layer
            source={HUC_WATERSHED_SOURCE_ID}
            source-layer={sourceShape}
            id={`${id}-highlight-stroke`}
            type="line"
            paint={{
              'line-color': [
                'case',

                ['boolean', ['feature-state', 'select'], false],
                'white',
                'transparent',
              ],
              'line-opacity': 1,
              'line-width': ['interpolate', ['linear'], ['zoom'], 10, 9, 12, 11],
            }}
          />
          <Layer
            source={HUC_WATERSHED_SOURCE_ID}
            source-layer={`huc${(i + 1) * 2}-shapes`}
            id={`${id}-base`}
            type="line"
            paint={{
              'line-color': [
                'case',
                ['boolean', ['feature-state', 'select'], false],
                theme.palette.selectLine,
                'transparent',
              ],
              'line-opacity': 1,
              'line-width': ['interpolate', ['linear'], ['zoom'], 10, 4, 12, 7],
            }}
          />
          {/* separating out hover onto a separate layer ensures that the outline is always visible */}
          <Layer
            source={HUC_WATERSHED_SOURCE_ID}
            source-layer={sourceShape}
            id={`${id}-highlight`}
            type="line"
            paint={{
              'line-color': [
                'case',
                [
                  'all',
                  ['!=', ['boolean', ['feature-state', 'select'], false], true],
                  ['boolean', ['feature-state', 'hover'], false],
                ],
                theme.palette.hoverLine,
                'transparent',
              ],
              'line-opacity': 1,
              'line-width': ['interpolate', ['linear'], ['zoom'], 10, 4, 12, 7],
            }}
          />
        </Fragment>
      ))}

      {LAYERS.map(({ id, sourceShape }, i) => (
        <Layer
          key={`${id}-dwell`}
          source={HUC_WATERSHED_SOURCE_ID}
          source-layer={sourceShape}
          id={`${id}-dwell`}
          type="line"
          paint={{
            'line-color': [
              'case',
              ['boolean', ['feature-state', 'dwell'], false],
              theme.palette.resultColor?.main,
              'transparent',
            ],
            'line-opacity': 1,
            'line-width': ['interpolate', ['linear'], ['zoom'], 10, 4, 12, 7],
          }}
        />
      ))}

      {children}
      {LAYERS.map(({ id, color, minZoom, maxZoom, sourceLabel }, i) => (
        <Layer
          key={`${id}-labels`}
          source={HUC_WATERSHED_SOURCE_ID}
          source-layer={sourceLabel}
          id={`${id}-labels`}
          type="symbol"
          maxzoom={maxZoom || 22}
          layout={{
            visibility: disabled ? 'none' : 'visible',
            'text-field': ['to-string', ['concat', ['get', 'huc'], '\n', ['get', 'name']]],
            'text-size': 14,
            'text-letter-spacing': 0.05,
            'text-ignore-placement': true,
          }}
          paint={{
            'text-color': color,
            'text-opacity':
              maxZoom !== undefined
                ? [
                    'interpolate',
                    ['linear'],
                    ['zoom'],
                    minZoom,
                    0,
                    minZoom + 0.5,
                    1,
                    maxZoom - 0.5,
                    1,
                    maxZoom,
                    0,
                  ]
                : ['interpolate', ['linear'], ['zoom'], minZoom, 0, minZoom + 0.5, 1],
            'text-halo-color': theme.palette.watershedTextHalo,
            'text-halo-blur': 1,
            'text-halo-width': 1,
          }}
        />
      ))}
    </Source>
  )
}
