import { AuthUserPermission } from '@cibo/core'
import { flatten } from 'ramda'

export interface PermissionsArgs {
  unlessAny?: AuthUserPermission[] | AuthUserPermission
  unlessAll?: AuthUserPermission[] | AuthUserPermission
  useAny?: AuthUserPermission[] | AuthUserPermission
  useAll?: AuthUserPermission[] | AuthUserPermission
}

export interface PermissionCheckArgs {
  permissions: AuthUserPermission[]
  unlessAny?: AuthUserPermission[] | AuthUserPermission
  unlessAll?: AuthUserPermission[] | AuthUserPermission
  useAny?: AuthUserPermission[] | AuthUserPermission
  useAll?: AuthUserPermission[] | AuthUserPermission
}

export const checkPermissions = ({
  permissions,
  unlessAll = [],
  unlessAny = [],
  useAll = [],
  useAny = [],
}: PermissionCheckArgs) => {
  const useAnyArr = flatten([useAny])
  const unlessAnyArr = flatten([unlessAny])
  const useAllArr = flatten([useAll])
  const unlessAllArr = flatten([unlessAll])

  let testCanUseAny = true
  let testCanUseAll = true
  let testCannotUseAny = unlessAnyArr.length === 0
  let testCannotUseAll = unlessAllArr.length === 0

  if (useAnyArr.length > 0) {
    testCanUseAny = useAnyArr.some(permission => permissions.includes(permission))
  }

  if (useAllArr.length > 0) {
    testCanUseAll = useAllArr.every(permission => permissions.includes(permission))
  }

  if (unlessAnyArr.length > 0) {
    testCannotUseAny = !unlessAnyArr.some(permission => permissions.includes(permission))
  }

  if (unlessAllArr.length > 0) {
    testCannotUseAll = !unlessAllArr.every(permission => permissions.includes(permission))
  }

  return testCanUseAny && testCanUseAll && testCannotUseAny && testCannotUseAll
}
