import * as Yup from 'yup'
import { ITraitFeatureById, InitialValuesRequest } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { ByrCoverCropsCell } from './ByrCoverCropsCell'
import { ByrCoverCropsEditor } from './ByrCoverCropsEditor'
import { RDByrCoverCrops } from './types'

const TRAIT_ID = 'byrCoverCrops'

export const ByrCoverCropsResult: ITraitFeatureById<RDByrCoverCrops> = {
  rollups: {},
  forbidBulkEdit: false,
  traitId: TRAIT_ID,
  editor: ByrCoverCropsEditor,
  cellDisplay: ByrCoverCropsCell,
  ns: '@cibo/landmanager/ByrCoverCrops',
  validationSchema: ({ requirement, t }) =>
    Yup.object({
      result: Yup.boolean().required(t('required')),
    }).required(t('required')),
  initialValues: (request: InitialValuesRequest) => {
    const detail = findInitialValuesDetail<RDByrCoverCrops>('byrCoverCrops')(request)
    return { result: `${detail?.result}` }
  },
  detailFromEditorValues: (values: { input: { result: boolean } }) => ({
    traitId: TRAIT_ID,
    result: values.input.result, // this is coming in stringified bool but works fine
  }),
}
