import { InputAdornment } from '@mui/material'
import { Field } from 'formik'
import { TextField, TextFieldProps } from 'formik-mui'
import { NumberInputProps } from '../inputs/NumberInput'

interface NumberInputFieldProps extends Partial<TextFieldProps>, NumberInputProps {
  name: string
}

export const NumberInputField = ({
  name,
  unit,
  style,
  disabled,
  label,
  ...textFieldProps
}: NumberInputFieldProps) => (
  <Field
    component={TextField}
    name={name}
    InputProps={{
      disabled,
      type: 'number',
      inputProps: textFieldProps,
      endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined,
    }}
    label={label}
    style={style}
  />
)
