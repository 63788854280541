import { Typography, TypographyProps } from '@mui/material'

interface LineLimitTypographyProps extends TypographyProps {
  lineLimit?: number
}

export const LineLimitTypography = ({
  lineLimit = 1,
  sx = {},
  ...typographyProps
}: LineLimitTypographyProps) => (
  <Typography
    sx={{
      display: '-webkit-box',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      WebkitLineClamp: lineLimit,
      WebkitBoxOrient: 'vertical',
      ...sx,
    }}
    {...typographyProps}
  />
)
