import { Array2d } from '@cibo/core'
import { bbox, FeatureCollection } from '@turf/turf'
import WebMercatorViewport from 'viewport-mercator-project'

import { ViewState } from './GeometryEditorTypes'

export const fitBoundsOfBbox = ({
  boundingBox,
  rect,
  viewState,
  padding,
}: {
  boundingBox: Array2d
  rect: DOMRect
  viewState?: ViewState
  padding?: number
}): ViewState => {
  const viewportWebMercator = new WebMercatorViewport({
    ...viewState,
    height: rect.height,
    width: rect.width,
  })

  return viewportWebMercator.fitBounds(boundingBox, {
    padding: padding || 80,
  })
}

export const fitBoundsOfFeature = ({
  features,
  rect,
  viewState,
}: {
  features: FeatureCollection
  rect: DOMRect
  viewState?: ViewState
}): ViewState => {
  const [minLng, minLat, maxLng, maxLat] = bbox(features)

  return fitBoundsOfBbox({
    boundingBox: [
      [minLng, minLat],
      [maxLng, maxLat],
    ],
    rect,
    viewState,
  })
}
