import { THEME_DARK_OPTIONS, THEME_LIGHT_OPTIONS } from '@cibo/ui'
import { PaletteColor, PaletteOptions, Theme, ThemeOptions, createTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { MapStyles } from './Layers/const'

const defaultTheme = createTheme()

export const MAPBOX_TOKEN = import.meta.env.VITE_MAPBOX_ACCESS_TOKEN

export const MAPBOX_STYLES = {
  FOCUSED: {
    light: import.meta.env.VITE_MAPBOX_STYLE_FOCUSED_LIGHT,
    dark: import.meta.env.VITE_MAPBOX_STYLE_FOCUSED_DARK,
  },
  SATELLITE: {
    light: import.meta.env.VITE_MAPBOX_STYLE_SATELLITE_LIGHT,
    dark: import.meta.env.VITE_MAPBOX_STYLE_SATELLITE_DARK,
  },
  CDL: {
    light: import.meta.env.VITE_MAPBOX_STYLE_CDL_LIGHT,
    dark: import.meta.env.VITE_MAPBOX_STYLE_CDL_DARK,
  },
}

interface MapPalette extends PaletteOptions {
  townshipLine: string
  townshipTextHalo: string
  townshipTextColor: string
  parcelLine: string
  sectionLine: string
  sectionText: string
  countyLine: string
  countyText: string
  countyTextHalo: string
  stateLine: string
  stateText: string
  stateTextHalo: string
  resultColor: PaletteColor
  watershedTextHalo: string

  hoverFill: string
  hoverLine: string
  selectFill: string
  selectLine: string

  pinInfoFill: string
  pinInfoStroke: string
  pinOtherFill: string
  pinOtherStroke: string
  pinSuccessFill: string
  pinSuccessStroke: string
  pinWarningFill: string
  pinWarningStroke: string
}

export interface MapTheme extends ThemeOptions {
  palette: MapPalette
}

const MAP_SATELLITE_PALETTE_LIGHT = ({ palette }: Theme) => ({
  townshipLine: 'hsl(187, 76%, 50%)',
  townshipTextHalo: 'hsl(187, 76%, 30%)',
  townshipTextColor: '#fff',
  parcelLine: '#fff',
  sectionLine: '#fff',
  sectionText: '#fff',
  countyLine: 'hsl(0, 0%, 85%)',
  countyText: '#fff',
  countyTextHalo: 'hsl(0, 0%, 5%)',
  stateLine: 'hsl(0, 0%, 85%)',
  stateText: '#fff',
  stateTextHalo: 'hsl(0, 0%, 5%)',
  resultColor: defaultTheme.palette.augmentColor({ color: { main: '#135a62' } }),
  watershedTextHalo: 'hsl(187, 76%, 30%)',
  hoverFill: 'hsla(207, 90%, 77%, 0.16)',
  hoverLine: '#90CAF9',
  selectFill: '#0288D1',
  selectLine: '#00A1BC',

  pinInfoFill: palette.info.main,
  pinInfoStroke: palette.info.contrastText,
  pinOtherFill: grey[300],
  pinOtherStroke: grey[200],
  pinSuccessFill: palette.success.main,
  pinSuccessStroke: palette.success.contrastText,
  pinWarningFill: palette.warning.main,
  pinWarningStroke: palette.warning.contrastText,
})

const MAP_SATELLITE_PALETTE_DARK = ({ palette }: Theme) => ({
  townshipLine: 'hsl(187, 76%, 50%)',
  townshipTextHalo: 'hsl(187, 76%, 30%)',
  townshipTextColor: '#fff',
  parcelLine: '#fff',
  sectionLine: '#fff',
  sectionText: '#fff',
  countyLine: 'hsl(0, 0%, 85%)',
  countyText: '#fff',
  countyTextHalo: 'hsl(0, 0%, 5%)',
  stateLine: 'hsl(0, 0%, 85%)',
  stateText: '#fff',
  stateTextHalo: 'hsl(0, 0%, 5%)',
  resultColor: defaultTheme.palette.augmentColor({ color: { main: '#135a62' } }),
  watershedTextHalo: 'hsl(187, 76%, 30%)',
  hoverFill: 'hsla(207, 90%, 77%, 0.16)',
  hoverLine: '#90CAF9',
  selectFill: '#0288D1',
  selectLine: '#00A1BC',

  pinInfoFill: palette.info.main,
  pinInfoStroke: grey[900],
  pinOtherFill: grey[400],
  pinOtherStroke: grey[200],
  pinSuccessFill: palette.success.main,
  pinSuccessStroke: grey[900],
  pinWarningFill: palette.warning.main,
  pinWarningStroke: grey[900],
})

const MAP_FOCUSED_PALETTE_LIGHT = ({ palette }: Theme) => ({
  townshipLine: 'hsl(187, 76%, 30%)',
  townshipTextHalo: '#000',
  townshipTextColor: 'hsl(187, 100%, 80%)',
  parcelLine: '#333',
  sectionLine: 'hsl(32, 98%, 44%)',
  sectionText: '#de7802',
  countyLine: 'hsl(0, 0%, 55%)',
  countyTextHalo: 'hsl(0, 0%, 19%)',
  countyText: '#e6edef',
  stateLine: 'hsl(0, 0%, 55%)',
  stateTextHalo: 'hsl(0, 0%, 19%)',
  stateText: '#e6edef',
  resultColor: defaultTheme.palette.augmentColor({ color: { main: '#135a62' } }),
  watershedTextHalo: 'hsl(187, 100%, 80%)',

  hoverFill: 'hsla(207, 90%, 77%, 0.16)',
  hoverLine: '#90CAF9',
  selectFill: '#0288D1',
  selectLine: '#00A1BC',

  pinInfoFill: palette.info.main,
  pinInfoStroke: palette.info.dark,
  pinOtherFill: grey[400],
  pinOtherStroke: grey[600],
  pinSuccessFill: palette.success.main,
  pinSuccessStroke: palette.success.dark,
  pinWarningFill: palette.warning.main,
  pinWarningStroke: palette.warning.dark,
})

const MAP_FOCUSED_PALETTE_DARK = ({ palette }: Theme) => ({
  townshipLine: 'hsl(187, 76%, 30%)',
  townshipTextHalo: '#000',
  townshipTextColor: 'hsl(187, 100%, 80%)',
  parcelLine: '#333',
  sectionLine: 'hsl(32, 98%, 44%)',
  sectionText: '#de7802',
  countyLine: 'hsl(0, 0%, 55%)',
  countyTextHalo: 'hsl(0, 0%, 19%)',
  countyText: '#e6edef',
  stateLine: 'hsl(0, 0%, 55%)',
  stateTextHalo: 'hsl(0, 0%, 19%)',
  stateText: '#e6edef',
  resultColor: defaultTheme.palette.augmentColor({ color: { main: '#95D13C' } }),
  watershedTextHalo: 'hsl(187, 100%, 80%)',

  hoverFill: 'hsla(207, 90%, 77%, 0.16)',
  hoverLine: '#90CAF9',
  selectFill: '#0288D1',
  selectLine: '#00A1BC',

  pinInfoFill: palette.info.main,
  pinInfoStroke: palette.info.dark,
  pinOtherFill: grey[300],
  pinOtherStroke: grey[400],
  pinSuccessFill: palette.success.main,
  pinSuccessStroke: palette.success.dark,
  pinWarningFill: palette.warning.main,
  pinWarningStroke: palette.warning.dark,
})

const MAP_MODE_THEME = {
  light: THEME_LIGHT_OPTIONS,
  dark: THEME_DARK_OPTIONS,
}

const MAP_PALETTE_OPTIONS = {
  [MapStyles.FOCUSED]: { light: MAP_FOCUSED_PALETTE_LIGHT, dark: MAP_FOCUSED_PALETTE_DARK },
  [MapStyles.SATELLITE]: { light: MAP_SATELLITE_PALETTE_LIGHT, dark: MAP_SATELLITE_PALETTE_DARK },
  [MapStyles.CDL]: { light: MAP_FOCUSED_PALETTE_LIGHT, dark: MAP_FOCUSED_PALETTE_DARK },
}

export const themeForMapStyle = (theme: Theme, style?: MapStyles) => {
  const BASE_THEME = MAP_MODE_THEME[theme.palette.mode]
  if (!style) {
    return createTheme(BASE_THEME)
  }

  const MAP_PALETTE = MAP_PALETTE_OPTIONS[style][theme.palette.mode](theme)

  return createTheme({
    ...BASE_THEME,
    palette: {
      ...BASE_THEME.palette,
      ...MAP_PALETTE,
    },
  })
}
