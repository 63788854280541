import { styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'
import { StandardLonghandProperties } from 'csstype'
import { DetailedHTMLProps, HTMLAttributes } from 'react'

type PeriodDivProps = {
  duration?: StandardLonghandProperties['animationDuration']
  iterationCount?: StandardLonghandProperties['animationIterationCount']
  timingFunction?: StandardLonghandProperties['animationTimingFunction']
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

const PeriodDiv = ({ duration, timingFunction, iterationCount, ...props }: PeriodDivProps) => (
  <div {...props} />
)

export const SpinningDiv = styled<OverridableComponent<any>>(PeriodDiv)(
  ({ duration = '2s', timingFunction = 'linear', iterationCount = 'infinite' }) => ({
    display: 'flex',
    animation: `spin ${duration} ${timingFunction} ${iterationCount}`,
    '@keyframes spin': {
      '0%': {
        transform: 'rotate(360deg)',
      },
      '100%': {
        transform: 'rotate(0deg)',
      },
    },
  })
)
