import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../BooleanRollup'
import { RDCropManagementData } from './types'

export const CropManagementDataResourceDetailFeature: ResourceDetailFeature<RDCropManagementData> =
  {
    traitId: 'cropManagementData',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDCropManagementData>) => (
      <BooleanRollup<RDCropManagementData>
        answerValues={{ yes: { value: true }, no: { value: false } }}
        ns="@cibo/landmanager/CropManagementData"
        detailKey="hasManagementData"
        {...props}
      />
    ),
  }
