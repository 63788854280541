import { TableCtaProps } from '../../types'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { displayYearsRange } from '../../utils'
import { useFieldsOwner } from '../../../../hooks'

export const EqipIrrigationQuantityTableCta = ({
  count,
  requirements,
  fieldModels,
}: TableCtaProps) => {
  const { t } = useTranslation('@cibo/landmanager/EqipIrrigationQuantity')

  const owner = useFieldsOwner(fieldModels)

  return (
    <Typography>
      {t('cta', {
        context: owner?.isSelf ? 'owner' : 'notOwner',
        count,
        ownerName: owner?.displayName,
        period: displayYearsRange(requirements),
      })}
    </Typography>
  )
}
