import { RankingPoolSymbol } from '@cibo/core/src/types/RecommendationPointsTypes'
import { stripedLinearGradient } from '@cibo/ui'
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined'
import { Box, Stack, alpha, useTheme } from '@mui/material'
import { iconMap } from './iconMap'

export const PracticePointsBar = ({
  effect,
  maxPoints,
  symbol,
}: {
  effect: number
  maxPoints: number
  symbol: RankingPoolSymbol
}) => {
  const {
    palette: {
      secondary: { main, light },
      mode,
    },
  } = useTheme()
  const Symbol = iconMap[symbol] || LandscapeOutlinedIcon

  return (
    <Stack direction="column" alignItems="center" spacing={0.5}>
      <Symbol fontSize="small" />
      <Box
        sx={{
          background: stripedLinearGradient(
            [main, mode === 'light' ? alpha(light, 0.2) : alpha(light, 0.2)],
            0,
            ((effect > 0 ? 0.1 : 0) + (effect / (maxPoints * 1.1)) * 0.9) * 100
          ),
          overflow: 'hidden',
          height: 26,
          width: 12,
        }}
      />
    </Stack>
  )
}
