import { Icon } from '@mui/material'
import {
  CompositeMode,
  DrawCircleFromCenterMode,
  DrawPolygonByDraggingMode,
  DrawRectangleMode,
  GeoJsonEditMode,
  ModifyMode,
  RotateMode,
  ScaleMode,
  SplitPolygonMode,
  TranslateMode,
  ViewMode,
} from '@nebula.gl/edit-modes'
import {
  ShapeCirclePlus as CircleAdd,
  CircleOffOutline as CircleRemove,
  Eraser,
  PencilPlus as PencilAdd,
  PencilMinus as PencilRemove,
  VectorPolylinePlus as PolygonAdd,
  VectorPolylineMinus as PolygonRemove,
  Knife as Split,
} from 'mdi-material-ui'
import { ReactNode } from 'react'

import { CustomDrawPolygonMode } from './CustomDrawPolygonMode'
import { CustomModifyMode } from './CustomModifyMode'

export type TOOL =
  | 'move'
  | 'translate'
  | 'modify'
  | 'removeVertex'
  | 'rotate'
  | 'scale'
  | 'rectangle'
  | 'pencil'
  | 'add_pencil'
  | 'subtract_pencil'
  | 'circle'
  | 'add_circle'
  | 'subtract_circle'
  | 'polygon'
  | 'add_polygon'
  | 'subtract_polygon'
  | 'split'
  | 'eraser'
  | 'marquee_pencil'
  | 'marquee_circle'
  | 'marquee_polygon'
  | 'marquee_rectangle'

type CATEGORY =
  | 'draw'
  | 'transform'
  | 'select'
  | 'pencil'
  | 'circle'
  | 'polygon'
  | 'split'
  | 'eraser'

export const TransModifyMode = new CompositeMode([new TranslateMode(), new ModifyMode()])

export interface EditorTool {
  id: TOOL
  category: CATEGORY
  mode: typeof GeoJsonEditMode
  icon: ReactNode
  someSelected?: boolean
}

export const MAP_TOOLS = [
  {
    id: 'move',
    mode: ViewMode,
    icon: () => <Icon baseClassName="icon-MoveMap" />,
    someSelected: false,
    category: 'select',
    modeConfig: {
      isTransform: true,
    },
  },
]

export const SELECTION_TOOLS = [
  {
    id: 'marquee_polygon',
    mode: CustomDrawPolygonMode,
    icon: () => <Icon baseClassName="icon-MarqueePolygon" />,
    someSelected: false,
    modeConfig: {
      marquee: true,
    },
  },
  {
    id: 'marquee_rectangle',
    mode: DrawRectangleMode,
    icon: () => <Icon baseClassName="icon-MarqueeRectangle" />,
    someSelected: false,
    modeConfig: {
      dragToDraw: true,
      marquee: true,
    },
  },
  {
    id: 'marquee_circle',
    mode: DrawCircleFromCenterMode,
    icon: () => <Icon baseClassName="icon-MarqueeCircle" />,
    someSelected: false,
    modeConfig: {
      dragToDraw: true,
      marquee: true,
    },
  },
  {
    id: 'marquee_pencil',
    mode: DrawPolygonByDraggingMode,
    icon: () => <Icon baseClassName="icon-MarqueeFreeform" />,
    someSelected: false,
    modeConfig: {
      marquee: true,
    },
  },
]

export const DRAW_TOOLS = [
  {
    id: 'polygon',
    mode: CustomDrawPolygonMode,
    icon: () => <Icon baseClassName="icon-DrawPolygon" />,
    someSelected: false,
  },
  {
    id: 'rectangle',
    mode: DrawRectangleMode,
    icon: () => <Icon baseClassName="icon-DrawRectangle" />,
    someSelected: false,
    modeConfig: {
      dragToDraw: true,
    },
  },

  {
    id: 'circle',
    mode: DrawCircleFromCenterMode,
    icon: () => <Icon baseClassName="icon-DrawCircle" />,
    someSelected: false,
    modeConfig: {
      dragToDraw: true,
    },
  },
  {
    id: 'pencil',
    mode: DrawPolygonByDraggingMode,
    icon: () => <Icon baseClassName="icon-DrawFreeform" />,
    someSelected: false,
  },
]

export const TRANSLATION_TOOLS = [
  {
    id: 'translate',
    mode: TranslateMode,
    icon: () => <Icon baseClassName="icon-Translate" />,
    someSelected: false,
  },
]

export const TRANSFORM_TOOLS = [
  {
    id: 'modify',
    mode: CustomModifyMode,
    icon: () => <Icon baseClassName="icon-PrecisionSelect" />,
    someSelected: false,
    modeConfig: {
      isTransform: true,
    },
  },
  {
    id: 'translate',
    mode: TranslateMode,
    icon: () => <Icon baseClassName="icon-Translate" />,
    someSelected: false,
    modeConfig: {
      isTransform: true,
    },
  },
  {
    id: 'rotate',
    mode: RotateMode,
    icon: () => <Icon baseClassName="icon-Rotate" />,
    someSelected: false,
    modeConfig: {
      isTransform: true,
    },
  },
  {
    id: 'scale',
    mode: ScaleMode,
    icon: () => <Icon baseClassName="icon-Scale" />,
    someSelected: false,
    modeConfig: {
      isTransform: true,
    },
  },
]

export const PENCIL_TOOLS = [
  {
    id: 'pencil',
    mode: DrawPolygonByDraggingMode,
    icon: () => <Icon baseClassName="icon-DrawFreeform" />,
    someSelected: false,
  },
  {
    id: 'add_pencil',
    icon: () => <Icon baseClassName="icon-DrawFreeform" />,
    mode: DrawPolygonByDraggingMode,
    modeConfig: { booleanOperation: 'union' },
    someSelected: true,
  },
  {
    id: 'subtract_pencil',
    mode: DrawPolygonByDraggingMode,
    modeConfig: { booleanOperation: 'difference' },
    icon: () => <Icon baseClassName="icon-DrawFreeform" />,
    someSelected: true,
  },
]

export const CIRCLE_TOOLS = [
  {
    id: 'circle',
    mode: DrawCircleFromCenterMode,
    icon: () => <Icon baseClassName="icon-DrawCircle" />,
    someSelected: false,
  },
  {
    id: 'add_circle',
    icon: () => <Icon baseClassName="icon-DrawCircle" />,
    mode: DrawCircleFromCenterMode,
    modeConfig: { booleanOperation: 'union' },
    someSelected: true,
  },
  {
    id: 'subtract_circle',
    icon: () => <Icon baseClassName="icon-DrawCircle" />,
    mode: DrawCircleFromCenterMode,
    modeConfig: { booleanOperation: 'difference' },
    someSelected: true,
  },
]

export const POLYGON_TOOLS = [
  {
    id: 'polygon',
    mode: CustomDrawPolygonMode,
    icon: () => <Icon baseClassName="icon-DrawPolygon" />,
    someSelected: false,
  },
  {
    id: 'add_polygon',
    icon: () => <Icon baseClassName="icon-DrawPolygon" />,
    mode: CustomDrawPolygonMode,
    modeConfig: { booleanOperation: 'union' },
    someSelected: true,
  },
  {
    id: 'subtract_polygon',
    mode: CustomDrawPolygonMode,
    modeConfig: { booleanOperation: 'difference' },
    icon: () => <Icon baseClassName="icon-DrawPolygon" />,
    someSelected: true,
  },
]

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const SPLIT_TOOL = [
  {
    id: 'split',
    mode: SplitPolygonMode,
    icon: () => <Split />,
    someSelected: true,
  },
]

export const ERASER_TOOL = [
  {
    id: 'eraser',
    mode: ModifyMode,
    icon: () => <Eraser />,
    someSelected: false,
    modeConfig: {
      erase: true,
    },
  },
]

export const BOOLEAN_TOOLS = [
  {
    id: 'add_polygon',
    icon: () => <PolygonAdd />,
    mode: CustomDrawPolygonMode,
    modeConfig: { booleanOperation: 'union' },
    someSelected: true,
  },
  {
    id: 'subtract_polygon',
    mode: CustomDrawPolygonMode,
    modeConfig: { booleanOperation: 'difference' },
    icon: () => <PolygonRemove />,
    someSelected: true,
  },
  {
    id: 'add_circle',
    icon: () => <CircleAdd />,
    mode: DrawCircleFromCenterMode,
    modeConfig: { booleanOperation: 'union' },
    someSelected: true,
  },
  {
    id: 'subtract_circle',
    icon: () => <CircleRemove />,
    mode: DrawCircleFromCenterMode,
    modeConfig: { booleanOperation: 'difference' },
    someSelected: true,
  },
  {
    id: 'add_pencil',
    icon: () => <PencilAdd />,
    mode: DrawPolygonByDraggingMode,
    modeConfig: { booleanOperation: 'union' },
    someSelected: true,
  },
  {
    id: 'subtract_pencil',
    mode: DrawPolygonByDraggingMode,
    modeConfig: { booleanOperation: 'difference' },
    icon: () => <PencilRemove />,
    someSelected: true,
  },
]
