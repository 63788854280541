import { useTheme } from '@mui/material'
import { useContext, useMemo } from 'react'
import { MapStyles } from '../../Layers'
import { LayerContext } from '../../Layers/LayerContext'
import { MapTheme } from '../../const'

export const useOtherFieldStyles = () => {
  const { mapStyle } = useContext(LayerContext)
  const theme = useTheme<MapTheme>()

  return useMemo(() => {
    switch (mapStyle) {
      case MapStyles.FOCUSED:
        return {
          linePaint: {
            'line-color': theme.palette.pinOtherStroke,
            'line-opacity': 0.8,
            'line-width': 2,
          },
          fillPaint: {
            'fill-color': theme.palette.pinOtherFill,
            'fill-opacity': 0.2,
          },
          circlePaint: {
            'circle-color': theme.palette.pinOtherFill,
            'circle-opacity': 0.7,
            'circle-stroke-color': theme.palette.pinOtherStroke,
            'circle-stroke-width': 2,
            'circle-radius': 3,
          },
          linePaintFieldset: {
            'line-color': 'rgb(134, 198, 208)',
            'line-opacity': 0.7,
            'line-width': 1.5,
          },
          fillPaintFieldset: {
            'fill-color': 'rgb(134, 198, 208)',
            'fill-opacity': 0.1,
          },
          circlePaintFieldset: {
            'circle-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              'rgba(134, 198, 208, 0.75)',
              8,
              'rgba(134, 198, 208, 0.75)',
              9,
              'rgba(134, 198, 208, 0)',
              24,
              'rgba(134, 198, 208, 0)',
            ],
            'circle-opacity': 0.5,
            'circle-stroke-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              'rgba(134, 198, 208, 1)',
              8,
              'rgba(134, 198, 208, 1)',
              9,
              'rgba(134, 198, 208, 0)',
              24,
              'rgba(134, 198, 208, 0)',
            ],
            'circle-stroke-width': 1.5,
            'circle-radius': 3,
          },
        }

      case MapStyles.SATELLITE:
        return {
          linePaint: {
            'line-color': theme.palette.pinOtherStroke,
            'line-width': 3,
          },
          fillPaint: {
            'fill-color': theme.palette.pinOtherFill,
            'fill-opacity': 0.6,
          },
          circlePaint: {
            'circle-color': theme.palette.pinOtherFill,
            'circle-opacity': 0.7,
            'circle-stroke-color': theme.palette.pinOtherStroke,
            'circle-stroke-width': 2,
            'circle-radius': 3,
          },
          linePaintFieldset: {
            'line-color': 'rgb(134, 198, 208)',
            'line-width': 2,
          },
          fillPaintFieldset: {
            'fill-color': 'rgb(134, 198, 208)',
            'fill-opacity': 0.4,
          },
          circlePaintFieldset: {
            'circle-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              'rgba(134, 198, 208, 0.75)',
              8,
              'rgba(134, 198, 208, 0.75)',
              9,
              'rgba(134, 198, 208, 0)',
              24,
              'rgba(134, 198, 208, 0)',
            ],
            'circle-opacity': 0.5,
            'circle-stroke-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              'rgba(134, 198, 208, 1)',
              8,
              'rgba(134, 198, 208, 1)',
              9,
              'rgba(134, 198, 208, 0)',
              24,
              'rgba(134, 198, 208, 0)',
            ],
            'circle-stroke-width': 1.5,
            'circle-radius': 3,
          },
        }

      case MapStyles.CDL:
      default:
        return {
          linePaint: {
            // @ts-ignore
            'line-color': theme.palette.primary.main,
            'line-opacity': 1,
            'line-dasharray': [2, 4],
            'line-width': 3,
          },
          fillPaint: {
            'fill-color': theme.palette.pinOtherFill,
            'fill-opacity': 0.8,
          },
          circlePaint: {
            'circle-color': theme.palette.pinOtherFill,
            'circle-opacity': 0.6,
            // @ts-ignore
            'circle-stroke-color': theme.palette.primary.main,
            'circle-stroke-width': 2,
            'circle-radius': 3,
          },
          linePaintFieldset: {
            // @ts-ignore
            'line-color': 'rgb(134, 198, 208)',
            'line-opacity': 1,
            'line-dasharray': [2, 4],
            'line-width': 2,
          },
          fillPaintFieldset: {
            'fill-color': 'rgb(134, 198, 208)',
            'fill-opacity': 0.6,
          },
          circlePaintFieldset: {
            'circle-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              'rgba(134, 198, 208, 0.75)',
              8,
              'rgba(134, 198, 208, 0.75)',
              9,
              'rgba(134, 198, 208, 0)',
              24,
              'rgba(134, 198, 208, 0)',
            ],
            'circle-opacity': 0.5,
            'circle-stroke-color': [
              'interpolate',
              ['linear'],
              ['zoom'],
              0,
              'rgba(134, 198, 208, 1)',
              8,
              'rgba(134, 198, 208, 1)',
              9,
              'rgba(134, 198, 208, 0)',
              24,
              'rgba(134, 198, 208, 0)',
            ],
            'circle-stroke-width': 1.5,
            'circle-radius': 3,
          },
        }
    }
  }, [theme, mapStyle])
}
