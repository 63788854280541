import { Button, ButtonGroup, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useRollupQuestion } from '../Rollups/RollupQuestionContext'
import { useSaveBulkDetails } from '../queries'

export const XtremeAgCodeRollup = () => {
  const { t } = useTranslation('@cibo/landmanager/XtremeAgCode')
  const { rollupRequested, setRollupRequested, fieldModels } = useRollupQuestion()
  const saveBulkDetails = useSaveBulkDetails()
  const { enqueueSnackbar } = useSnackbar()

  const handleSaveDetails = (value?: boolean) => {
    saveBulkDetails
      .mutateAsync({
        resourceIds: fieldModels?.map(a => a.id),
        details: [{ traitId: 'xtremeAgCode', result: false }],
      })
      .catch(error => {
        enqueueSnackbar(`${t('saveDetailError')}`, {
          variant: 'error',
        })
      })
  }
  const handleChange = (value: string) => {
    if (value === 'no') {
      handleSaveDetails(false)
    }
    setRollupRequested(value === 'no')
  }

  const answer = rollupRequested === undefined ? '' : rollupRequested ? 'no' : 'yes'

  return (
    <Stack spacing={2}>
      <Typography variant="h6">{t('rollupQuestion', { count: fieldModels?.length })}</Typography>

      <ButtonGroup color="secondary">
        <Button
          onClick={() => handleChange('yes')}
          variant={answer === 'yes' ? 'contained' : 'outlined'}
          disableElevation
        >
          {t('yes')}
        </Button>
        <Button
          onClick={() => handleChange('no')}
          variant={answer === 'no' ? 'contained' : 'outlined'}
          disableElevation
        >
          {t('no')}
        </Button>
      </ButtonGroup>
    </Stack>
  )
}
