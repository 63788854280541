export const downloadFile = ({ blob, filename }: { blob: Blob; filename: string }) => {
  // create file link in browser's memory
  const href = URL.createObjectURL(blob)

  // create "a" HTML element with href to file & click
  const link = document.createElement('a')
  link.href = href
  link.setAttribute('download', filename)
  document.body.appendChild(link)
  link.click()

  try {
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    URL.revokeObjectURL(href)
  } catch (error) {
    console.error('failed to remove child', error)
  }
}
