import { AxiosRequestConfig } from 'axios'
import deepmerge from 'deepmerge'

const axiosConfig: AxiosRequestConfig = {
  headers: {
    'X-MP-AuthType': 'webapp',
  },
  withCredentials: true,
}

export const GlobalMpAuth = {
  setEnvToken: (envToken: string) => {
    axiosConfig.headers.CbEnvAccessToken = `Bearer ${envToken}`
  },
  clearAuthToken: () => {
    delete axiosConfig.headers.Authorization
  },
  setAuthToken: (authToken?: string) => {
    axiosConfig.headers.Authorization = `Bearer ${authToken}`
  },

  requestMiddleware: (config: AxiosRequestConfig) => deepmerge(config, axiosConfig),
}
