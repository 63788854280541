import { RDEqipRequiredForms } from '@cibo/core'
import { Loading } from '@cibo/profile'
import {
  ResourceDetailFeatureTaskEditorProps,
  downloadAxiosResponse,
  useTraitContent,
} from '@cibo/ui'
import AttachFile from '@mui/icons-material/AttachFile'
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined'
import { LoadingButton } from '@mui/lab'
import { Card, FormHelperText, Stack, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { flatten } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateProgramEngagementDetails } from '../../../../hooks/useProgramEngagementDetails'
import { TaskDocumentsAPI } from './TaskDocumentsAPI'

export const RDEqipRequiredFormsTaskEditor = ({
  detailRequirements,
  engagementId,
  resourceIds: [resourceId],
  task,
}: ResourceDetailFeatureTaskEditorProps<RDEqipRequiredForms>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipRequiredFormsTaskEditor')
  const content = useTraitContent('eqipRequiredForms')
  const updateDetail = useUpdateProgramEngagementDetails()
  const [isPending, setIsLoading] = useState(false)
  const { enqueueSnackbar } = useSnackbar()

  const requiredDocs = flatten(detailRequirements[0].reqAttrs ?? [])

  const onDownloadAll = async () => {
    setIsLoading(true)

    task &&
      TaskDocumentsAPI.download({ engagementId, taskId: task.id, name: t('required-forms') })
        .then(response => {
          downloadAxiosResponse(`${t('required-forms')}.zip`)(response)
          updateDetail.mutateAsync({
            resourceId,
            details: [
              {
                traitId: 'eqipRequiredForms',
                year: detailRequirements[0].year,
                result: {
                  agiCertification: true,
                  practiceRecommendations: requiredDocs.includes('practiceRecommendations'),
                  conservationProgramApp: true,
                  consentToReleaseInfo: true,
                },
              } as RDEqipRequiredForms,
            ],
          })
        })
        .catch(error => {
          enqueueSnackbar(t('downloadProblem'), { variant: 'error' })
        })
        .finally(() => setIsLoading(false))
  }

  if (content.isPending) {
    return <Loading />
  }

  return (
    <Stack spacing={3}>
      <Stack direction="row" justifyContent="space-between" spacing={3}>
        <Typography variant="h6">{t('requiredForms')}</Typography>
        <LoadingButton
          color="secondary"
          onClick={onDownloadAll}
          loading={isPending}
          disabled={!task}
        >
          {t('downloadAll')}
        </LoadingButton>
      </Stack>

      {requiredDocs.includes('practiceRecommendations') && (
        <Card variant="outlined">
          <Stack direction="row" alignItems="center" padding={2} spacing={3} flex={1}>
            <InsertDriveFileOutlined color="action" />
            <Stack>
              <Typography variant="body1">{t('practiceSelectionResults')}</Typography>
              <Typography variant="body2" color="text.secondary">
                {t('practiceSelectionResultsDescription')}
              </Typography>
            </Stack>
          </Stack>
        </Card>
      )}

      {content.data?.fields.displayableAssets
        ?.filter(({ fields: { traitKey } }) =>
          !!traitKey ? requiredDocs.includes(traitKey) : false
        )
        ?.map(({ fields: { asset, description, title } }) => (
          <Card key={asset.sys.id} variant="outlined">
            <Stack direction="row" alignItems="center" padding={2} spacing={3} flex={1}>
              <InsertDriveFileOutlined color="action" />
              <Stack>
                <Typography variant="body1">{title}</Typography>
                <Typography variant="body2" color="text.secondary">
                  {asset?.fields.title}
                </Typography>
                {!!description && (
                  <Typography variant="body2" color="text.secondary">
                    {description}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Card>
        ))}

      <Card variant="outlined" sx={{ backgroundColor: 'action.hover', borderWidth: 0 }}>
        <Stack direction="row" alignItems="center" padding={2} spacing={3} flex={1}>
          <AttachFile color="action" />
          <Stack>
            <Typography variant="body1">{t('attachProofOfControl')}</Typography>
            <Typography variant="body2" color="text.secondary">
              {t('attachProofOfControlDescription')}
            </Typography>
          </Stack>
        </Stack>
      </Card>
      <FormHelperText>{t('disclaimer')}</FormHelperText>
    </Stack>
  )
}
