/* istanbul ignore file */
import { getMpNodeAgent } from './utils'

const agent = getMpNodeAgent()

export type DisplayDataset = 'elevation' | 'soil' | 'stability'

const PATH = '/display/v1/'

export class DisplayApi {
  static forResource(dataset: DisplayDataset, resourceId: string): Promise<any> {
    return agent.get(`${PATH}${dataset}?resourceId=${resourceId}`).then(response => response?.data)
  }
}
