import { LatLong } from './geometry'

const PRECISION = 5

export const queryForLatLong = (location: LatLong): string | undefined =>
  location && location.Longitude && location.Latitude
    ? `${location.Longitude.toFixed(PRECISION)},${location.Latitude.toFixed(PRECISION)}`
    : undefined

export const latLongForQuery = (query?: string | number[]): LatLong | undefined => {
  if (!query) {
    return undefined
  }

  if (typeof query === 'string') {
    const parts = query.split(',')
    if (parts.length !== 2) {
      return undefined
    }

    const ll = {
      Longitude: parseFloat(parts[0]),
      Latitude: parseFloat(parts[1]),
    }

    return ll
  }

  return {
    Longitude: query[0],
    Latitude: query[1],
  }
}
