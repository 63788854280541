import { RDEqipPracticesIrrigationMethod } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesIrrigationMethodResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesIrrigationMethod> =
  {
    traitId: 'eqipPracticesIrrigationMethod',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesIrrigationMethod>) => (
      <BooleanRollup<RDEqipPracticesIrrigationMethod>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } }}
        detailKey="furrowIrrigation"
        ns="@cibo/landmanager/RDEqipPracticesIrrigationMethod"
        {...props}
      />
    ),
  }
