import { FieldModel } from '@cibo/core'
import { FeatureCollection, featureCollection } from '@turf/helpers'
import { Feature, MultiPolygon, Point, Polygon } from 'geojson'
import { Dispatch, RefObject, SetStateAction, createContext, useContext } from 'react'
import { ViewState } from 'react-map-gl'
import { GeoJSONEditHistory } from '../GeometryEditorTypes'
import { useEditorOverlaps } from '../useEditorOverlaps'

export interface GeometryEditorState {
  autofixOverlaps(): void
  autofixSelfOverlaps(): void
  container: RefObject<HTMLDivElement>
  editMapViewState: ViewState
  setEditMapViewState(newViewState: ViewState): void
  mapBounds?: string
  setMapBounds(bounds: string): void
  editState: GeoJSONEditHistory
  features: FeatureCollection
  fieldOverlaps: ReturnType<typeof useEditorOverlaps>
  fieldPoints?: Feature<Point>[]
  fieldShapes?: Feature<Polygon | MultiPolygon>[]
  handleUndo?: () => void
  handleRedo?: () => void
  hasSelfOverlaps: () => boolean
  majorityCrops?: any
  resourceId?: string
  fieldsetFields?: FieldModel[]
  setFieldsetFields: (fieldsetFields?: FieldModel[]) => void
  setEditState: (editState: GeoJSONEditHistory) => void
  previewFeatures: any
  setPreviewFeatures: Dispatch<SetStateAction<any>>
  resetGeometryEditor: () => void
  touched: boolean
  baseFeatureCollection?: FeatureCollection
  allViewportFields?: FeatureCollection<any, any> | null
  setBaseFeatureCollection: Dispatch<SetStateAction<FeatureCollection>>
  invalidateFieldQueries: () => void
}

export const GeometryEditorStateContext = createContext<GeometryEditorState>({
  autofixOverlaps: () => null,
  autofixSelfOverlaps: () => null,
  // @ts-ignore
  editMapViewState: {},
  fieldOverlaps: { isReady: false, overlaps: undefined },
  editState: {
    editLayer: { features: featureCollection([]), selectedIndexes: [] },
    redoStack: [],
    undoStack: [],
    transforming: false,
  },
  features: featureCollection([]),
  hasSelfOverlaps: () => false,
  setEditState: () => console.log('setEditState unset'),
  setFieldsetFields: () => console.log('setFieldsetFields unset'),
  touched: false,
  setBaseFeatureCollection: () => console.log('setBaseFeatureCollection unset'),
  resetGeometryEditor: () => console.log('resetGeometryEditor unset'),
  previewFeatures: [],
  setPreviewFeatures: () => console.log('setPreviewFeatures unset'),
  invalidateFieldQueries: () => console.log('invalidateFieldQueries unset'),
})
GeometryEditorStateContext.displayName = 'GeometryEditorStateContext'

export const useGeometryEditorStateContext = () => useContext(GeometryEditorStateContext)
