import {
  PRIORITIZED_DISPLAYABLE_CASH_CROPS,
  PRIORITY_CASH_CROPS,
  RDCashCropSpecies,
  ResourceDetail,
} from '@cibo/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Autocomplete, TextField } from '@mui/material'
import { TraitCellEditorProps } from '../types'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result || ''
}

const optionTest = (option: string, value: string) => (value === '' ? false : option === value)

export const CashCropCellEditor = ({
  traitId,
  year,
  params,
  saveDetail,
  removeDetail,
}: TraitCellEditorProps) => {
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')
  const { t: optionsT } = useTranslation('@cibo/landmanager/CashCropEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleChangeCrop = (value: any) => {
    setAnswer(value)

    if (value === null) {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
        year,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, year, result: value }],
      })
    }
  }

  return (
    <Autocomplete
      /* This prop is to document having tried to suppress the warning.
       * The warning still shows - probably a mui bug.
       */
      isOptionEqualToValue={optionTest}
      data-testid={`cash_crop_editor`}
      fullWidth
      disableClearable
      autoComplete
      groupBy={c =>
        PRIORITY_CASH_CROPS.includes(c as RDCashCropSpecies)
          ? optionsT('suggested')
          : optionsT('all')
      }
      options={PRIORITIZED_DISPLAYABLE_CASH_CROPS}
      getOptionLabel={crop => cropT(crop)}
      renderInput={params => <TextField sx={{ marginRight: 1 }} {...params} />}
      value={answer}
      onChange={(event: any, newValue: string | null) => {
        handleChangeCrop(newValue)
      }}
    />
  )
}
