import { LivestockChange, RDFutureLivestockChange, TraitId } from '@cibo/core'
import { pathEq } from 'ramda'
import * as Yup from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FutureLivestockChangeCell } from './FutureLivestockChangeCell'
import { FutureLivestockChangeEditor } from './FutureLivestockChangeEditor'
import { FutureLivestockChangeRollup } from './FutureLivestockChangeRollup'

const TRAIT_ID: TraitId = 'futureLivestockChange'

export const FutureLivestockChange: ITraitFeatureByIdYear<RDFutureLivestockChange> = {
  traitId: TRAIT_ID,
  editor: FutureLivestockChangeEditor,

  cellDisplay: FutureLivestockChangeCell,

  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: FutureLivestockChangeRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          livestockChange: 'none',
        },
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  ns: '@cibo/landmanager/FutureLivestockChangeEditor',
  // @ts-ignore yup conditional is not transmitting that we don't need headcount when change is 'none'
  validationSchema: ({ t }) =>
    Yup.object({
      livestockChange: Yup.string<LivestockChange>().required(
        t('required', { context: 'livestockChange' })
      ),
      headcountPerAcre: Yup.number()
        .when('livestockChange', {
          is: 'none',
          then: (schema: any) => schema.nullable(),
          otherwise: (schema: any) =>
            schema
              .min(1, t('min', { context: 'headcountPerAcre' }))
              .required(t('required', { context: 'headcountPerAcre' })),
        })
        .typeError(t('required', { context: 'headcountPerAcre' })),
    }).required(),

  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)

    return detail?.input
      ? {
          livestockChange: detail.input.livestockChange,
          headcountPerAcre: detail.input.headcountPerAcre,
        }
      : {
          livestockChange: detail?.result || '',
        }
  },
}
