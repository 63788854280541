import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RDNaturalDisturbance } from '@cibo/core'
import { EventDetailEditor } from '../EventDetail'
import { DetailEditorPropsByYear } from '../types'
import { NaturalDisturbanceEventEditor } from './NaturalDisturbanceEventEditor'

export const NaturalDisturbanceEditor = ({
  fieldModels,
  year,
  name = 'naturalDisturbance',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/NaturalDisturbance')
  const NaturalDisturbanceEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography>{t('month')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('disturbance')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('lossPer')}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
  return (
    <EventDetailEditor<RDNaturalDisturbance['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={NaturalDisturbanceEventTableHead}
      EventComponent={NaturalDisturbanceEventEditor}
      translationContext="naturalDisturbance"
      defaultEvent={{ month: '', disturbance: '', lossPer: '' }}
    />
  )
}
