import { Box, BoxProps, useTheme } from '@mui/material'
import { grey } from '@mui/material/colors'
import { stripedLinearGradient } from '../../utils'

export interface ProgressBarProps extends BoxProps {
  percent: number
  trackColor?: string
  fillColor?: string
}

export const ProgressBar = ({ percent, ...props }: ProgressBarProps) => {
  const {
    palette: {
      success: { light },
      mode,
    },
  } = useTheme()

  const {
    fillColor = light,
    trackColor = mode === 'light' ? grey[400] : grey[600],
    sx = {},
    ...boxProps
  } = props

  return (
    <Box
      sx={{
        background: stripedLinearGradient(
          [fillColor, trackColor],
          110,
          (0.1 + percent * 0.9) * 100
        ),
        borderRadius: 2,
        overflow: 'hidden',
        height: 12,
        ...sx,
      }}
      {...boxProps}
    />
  )
}
