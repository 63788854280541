import { ReactElement } from 'react'
import { BenchmarkStatus } from '@cibo/core'
import IncompleteIcon from '@mui/icons-material/RemoveCircleOutlineOutlined'
import SuccessStatusIcon from '@mui/icons-material/CheckCircleOutlined'
import FailStatusIcon from '@mui/icons-material/HighlightOffOutlined'

export const statusIcon: Record<BenchmarkStatus, ReactElement> = {
  incomplete: <IncompleteIcon />,
  fail: <FailStatusIcon />,
  pass: <SuccessStatusIcon />,
}

export const statusColor: Record<BenchmarkStatus, 'warning' | 'error' | 'success'> = {
  incomplete: 'warning',
  fail: 'error',
  pass: 'success',
}
