import {
  FieldSearchBar,
  PaginatedFieldsContext,
  SelectablePaginatedFieldsTable,
} from '@cibo/landmanager'
import { ResponsiveDialog } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Stack,
  Switch,
} from '@mui/material'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramLimitFeedback, useProgramLimit } from '../../components/ProgramLimitFeedback'
import { useUpdateWorkflow, useWorkflow } from '../../queries'

import { FieldModel } from '@cibo/core'
import { GridRowParams } from '@mui/x-data-grid-pro'
import { prequalificationColumns } from '../../components/ProgramFlow/columnConfiguration'

interface AddMoreFieldsModalProps {
  requestClose: () => void
  workflowId?: string
  onSuccess?: () => void
}

export const AddMoreFieldsModal = ({
  requestClose,
  workflowId,
  onSuccess,
}: AddMoreFieldsModalProps) => {
  const { t } = useTranslation('@cibo/programs/AddMoreFieldsModal')
  const { t: tProgramFlowColumns } = useTranslation('@cibo/programs/programFlowColumns')
  const { t: tColumn } = useTranslation('@cibo/landmanager/FieldsTable')
  const updateWorkflow = useUpdateWorkflow()
  const workflowQuery = useWorkflow(workflowId)
  const programId = workflowQuery.data?.programId
  const ownedBy = workflowQuery.data?.ownedBy

  const { limits } = useProgramLimit()

  const { filters, setFilters, selectedRowIds, clearSelectedRowIds } = useContext(
    PaginatedFieldsContext
  )
  const [hideIneligible, setHideIneligible] = useState(true)
  useEffect(() => {
    clearSelectedRowIds()
  }, [workflowId])

  const handleSubmit = async () => {
    if (!!workflowId && selectedRowIds.length > 0) {
      updateWorkflow
        .mutateAsync({
          workflowId,
          updates: { action: 'merge', fields: selectedRowIds as string[] },
        })
        .then(() => {
          requestClose()
          onSuccess && onSuccess()
        })
    }
  }

  const addLabelFilter = (label: string) => () => {
    setFilters({ ...filters, labels: !!filters.labels ? [...filters.labels, label] : [label] })
  }

  const columns = useMemo(
    () =>
      prequalificationColumns({
        t: tProgramFlowColumns,
        tColumn,
        programId,
        onClickLabel: addLabelFilter,
        filters,
      }),
    [programId, filters]
  )

  const components = useMemo(
    () => ({
      toolbar: () => (
        <Stack spacing={2} mb={1} direction="row">
          <FieldSearchBar />
          <FormControlLabel
            onChange={() => setHideIneligible(!hideIneligible)}
            checked={hideIneligible}
            control={<Switch size="small" />}
            label={`${t('hideIneligible')}`}
          />
        </Stack>
      ),
    }),
    [hideIneligible]
  )

  const noSpaceLimit = !limits || !limits[0].display ? false : limits[0].limitType === 'noSpace'

  return (
    <ResponsiveDialog
      open={!!workflowId}
      onClose={requestClose}
      maxWidth={!workflowId || noSpaceLimit ? undefined : 'xl'}
      fullWidth
    >
      {!noSpaceLimit && <DialogTitle>{t('addFields')}</DialogTitle>}

      <DialogContent>
        {workflowId ? (
          noSpaceLimit && programId && ownedBy ? (
            <ProgramLimitFeedback
              growerName={
                ownedBy.givenName
                  ? `${ownedBy.givenName} ${ownedBy.familyName}`
                  : ownedBy.userPrimaryEmail
              }
            />
          ) : (
            <SelectablePaginatedFieldsTable
              columns={columns}
              baseQuery={{
                owner: workflowQuery.data?.ownedBy.userId,
                availableForWorkflow: programId && hideIneligible ? { programId } : undefined,
              }}
              isRowSelectable={({ row }: GridRowParams<FieldModel>) =>
                !!programId ? row.canAddToWorkflow(programId) : true
              }
              slots={components}
            />
          )
        ) : null}
      </DialogContent>

      <DialogActions>
        <Button onClick={requestClose}>{t('cancel')}</Button>
        <LoadingButton
          variant="contained"
          loading={updateWorkflow.isPending}
          onClick={handleSubmit}
          disabled={updateWorkflow.isError || selectedRowIds.length === 0 || noSpaceLimit}
        >
          {t('confirmAdd')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
