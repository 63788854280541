import { RDEqipDocumentsTaskEditor } from './RDEqipDocumentsTaskEditor'
export * from './types'

export const RDEqipDocumentsFeatureMap = {
  Component: {
    traitId: 'eqipDocuments' as const,
    TaskEditor: RDEqipDocumentsTaskEditor,
  },
  resourceType: 'workflow' as const,
}
