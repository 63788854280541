import { useEffect } from 'react'
import { Action } from 'redux'
import { useSnackbar } from 'notistack'

import { MP_AUTH_DIRECT_LOGOUT_SUCCESS } from '@cibo/core'
import { withActions, WithActionsProps } from '@cibo/ui'

export const AuthFlashMessages = withActions(({ subscribeAction }: WithActionsProps) => {
  const { enqueueSnackbar } = useSnackbar()
  const authEventHandler = (action: Action) => {
    const { type, message } = action as { type: string; message: string }

    if (!message) {
      return
    }

    switch (type) {
      case MP_AUTH_DIRECT_LOGOUT_SUCCESS:
        enqueueSnackbar(message, { variant: 'default' })
        break

      default:
        break
    }
  }

  useEffect(() => {
    subscribeAction(MP_AUTH_DIRECT_LOGOUT_SUCCESS, authEventHandler)
  }, [])

  return null
})
