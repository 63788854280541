import { FormControlLabel, Radio, Stack } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { Field, useFormikContext } from 'formik'
import { RadioGroup } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../../types'

type EditorProgram = 'cspEnrolled' | 'crpEnrolled' | 'acepEnrolled'

export const ConservationProgramsEditor =
  (editorProgram: EditorProgram) => (props: DetailEditorProps) =>
    <ConservationProgramsEditorWithoutProgram {...props} editorProgram={editorProgram} />

const ConservationProgramsEditorWithoutProgram = ({
  fieldModels,
  editorProgram,
  name,
}: DetailEditorProps & { editorProgram: EditorProgram }) => {
  const { t } = useTranslation('@cibo/landmanager/ConservationPrograms')
  const { isSubmitting } = useFormikContext()
  return (
    <Stack spacing={2}>
      <Stack>
        <InputLabel htmlFor={`${editorProgram}`}>
          {t(`editorQuestion_${editorProgram}`, { count: fieldModels.length })}
        </InputLabel>
        <Field name={`${name}.${editorProgram}`} component={RadioGroup} row>
          <FormControlLabel
            value={'no'}
            control={<Radio />}
            label={`${t('no')}`}
            disabled={isSubmitting}
            data-testid={`${name}::no`}
          />
          <FormControlLabel
            value={'yes'}
            control={<Radio />}
            label={`${t('yes')}`}
            disabled={isSubmitting}
            data-testid={`${name}::yes`}
          />
        </Field>
      </Stack>
    </Stack>
  )
}
