import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'

import { FieldModel } from '@cibo/core'
import { LandThumbnail, LandThumbnailProps, LandThumbnailSkin } from '../LandThumbnail'

export const fieldThumbnailColumn = ({
  t,
  thumbnailProps,
  overrides,
}: {
  t: TFunction
  thumbnailProps?: Partial<LandThumbnailProps>
  overrides?: any
}) => ({
  field: 'thumbnail',
  headerName: t('thumbnail', { ns: '@cibo/landmanager/FieldColumns' }),
  renderHeader: () => <></>,
  width: 80,
  resizable: false,
  sortable: false,
  filterable: false,
  renderCell: (params: GridRenderCellParams<FieldModel>) => (
    <LandThumbnail
      field={params.row}
      width={60}
      height={60}
      skin={LandThumbnailSkin.SMALL}
      {...thumbnailProps}
    />
  ),
  ...overrides,
})
