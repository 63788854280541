import { RDLivestock } from '@cibo/core'
import { Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const LivestockCell = ({ traitId, year, params }: TraitCellDisplayProps<RDLivestock>) => {
  const { t } = useTranslation('@cibo/landmanager/LivestockEditor')
  const {
    palette: {
      text: { secondary },
    },
  } = useTheme()
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.species) {
    return (
      <>
        <Typography>
          {t('species', {
            ns: '@cibo/landmanager/LivestockSpecies',
            context: detail.input?.species,
          })}
        </Typography>
        <span style={{ color: secondary }}>
          {[
            !!detail.input?.headcount && t('headcount', { count: detail.input?.headcount }),
            !!detail.input?.daysGrazing && t('daysGrazing', { count: detail.input?.daysGrazing }),
          ]
            .filter(Boolean)
            .join(', ')}
        </span>
      </>
    )
  }

  return <Typography>{detail.result ? t('yes') : t('no')}</Typography>
}
