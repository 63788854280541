import {
  AuthAPI,
  AuthUserLevel,
  MP_USER_INFO_ERROR,
  MP_USER_INFO_LEVEL_CHANGED,
  MP_USER_INFO_PENDING,
  MP_USER_INFO_SUCCESS,
  UserInfoActionTypes,
  UserInfoError,
  UserInfoLevelChangedAction,
  UserInfoResult,
} from '@cibo/core'
import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { selectAuthUserLevel } from '../AuthReducer/Session'

const refreshUserInfoPending = (): UserInfoActionTypes => ({
  type: MP_USER_INFO_PENDING,
})
const refreshUserInfoSuccess = (results: AxiosResponse<UserInfoResult>): UserInfoActionTypes => ({
  type: MP_USER_INFO_SUCCESS,
  results,
})
const refreshUserInfoError = (error: UserInfoError): UserInfoActionTypes => ({
  type: MP_USER_INFO_ERROR,
  error,
})
const userInfoLevelChanged = (authUserLevel: AuthUserLevel): UserInfoLevelChangedAction => ({
  type: MP_USER_INFO_LEVEL_CHANGED,
  payload: { authUserLevel },
})

export const refreshUserInfo =
  (): ThunkAction<void, any, null, Action<string>> => async (dispatch, getState) => {
    dispatch(refreshUserInfoPending())
    try {
      const results = await AuthAPI.userInfo()

      const oldLevel = selectAuthUserLevel(getState())

      dispatch(refreshUserInfoSuccess(results))

      const newLevel = selectAuthUserLevel(getState())

      const subscribeHappened =
        oldLevel && oldLevel !== newLevel && newLevel === AuthUserLevel.SUBSCRIBED

      if (subscribeHappened) {
        dispatch(userInfoLevelChanged(AuthUserLevel.SUBSCRIBED))
      }
    } catch ({ response: { data } = { data: {} } }: any) {
      dispatch(refreshUserInfoError(data as UserInfoError))
    }
  }
