import { ResponsiveDialog, downloadAxiosResponse, logRequestError } from '@cibo/ui'
import LaunchIcon from '@mui/icons-material/Launch'
import { LoadingButton } from '@mui/lab'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormHelperText,
  Stack,
  Typography,
} from '@mui/material'
import { AxiosError } from 'axios'
import { useSnackbar } from 'notistack'
import { ChangeEvent, useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { RequiredDocumentsAPI } from '../RequiredDocumentsAPI'
import { RDEqipDocuments } from './types'
import { useProcessSigningReturn } from './useProcessSigningReturn'

export const EqipApplicationDocusign = ({
  detailYear,
  engagementId,
  taskId,
}: {
  detailYear: number
  engagementId: string
  taskId: string
}) => {
  const { t } = useTranslation('@cibo/programs/RDEqipDocumentsTaskEditor')
  const { enqueueSnackbar } = useSnackbar()

  const eqipDocumentsDetails = useProgramEngagementDetails({
    detailRequirements: [{ year: detailYear, traitId: 'eqipDocuments', dataType: 'workflow' }],
    resourceId: engagementId,
  })
  const eqipDocumentsDetail = eqipDocumentsDetails.data?.[0] as RDEqipDocuments

  const updateDetail = useUpdateProgramEngagementDetails()

  const [loadingDocusignUrl, setLoadingDocusignUrl] = useState(false)

  const [permissionDialogOpen, setPermissionDialogOpen] = useState(false)

  const onClickSign = useCallback(() => {
    if (!engagementId) return

    setPermissionDialogOpen(true)
  }, [])

  const permissionDetails = useProgramEngagementDetails({
    detailRequirements: [{ traitId: 'eqipNrcsPermission', dataType: 'workflow' }],
    resourceId: engagementId,
  })
  const permissionDetail = permissionDetails.data?.[0]

  const [envelopeLoading, setEnvelopLoading] = useState(false)
  useEffect(() => {
    if (!engagementId) return
    setEnvelopLoading(true)
    RequiredDocumentsAPI.generate({ engagementId, taskId })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
      .finally(() => {
        setEnvelopLoading(false)
      })
  }, [])

  const processSigningReturn = useProcessSigningReturn()

  const [searchParams, setSearchParams] = useSearchParams()

  useEffect(() => {
    if (searchParams.has('event')) {
      const eventParam = searchParams.get('event') as string

      searchParams.delete('event')
      setSearchParams(searchParams)

      processSigningReturn.mutateAsync({ engagementId, taskId, eventParam })
    }
  }, [searchParams])

  const onClickCheck = (event: ChangeEvent<HTMLInputElement>) => {
    const value = event.target.checked

    updateDetail.mutateAsync({
      resourceId: engagementId,
      details: [
        // @ts-ignore This detail does not follow the input/result pattern of others
        { traitId: 'eqipNrcsPermission', value },
      ],
    })
  }

  const goToDocusign = useCallback(() => {
    setLoadingDocusignUrl(true)

    RequiredDocumentsAPI.url({ engagementId, taskId, returnUrl: window.location.href })
      .then((response: { docusignUrl: string }) => (document.location.href = response.docusignUrl))
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
      .finally(() => {
        setLoadingDocusignUrl(false)
      })
  }, [])

  const downloadSigned = useCallback(() => {
    setLoadingDocusignUrl(true)

    RequiredDocumentsAPI.download({ engagementId, taskId })
      .catch((error: AxiosError) => {
        enqueueSnackbar(error.message, { variant: 'error' })
        logRequestError(error)
      })
      .then(downloadAxiosResponse())
      .finally(() => {
        setLoadingDocusignUrl(false)
      })
  }, [])

  const isSigned = eqipDocumentsDetail?.result?.eqipPrgDoc?.signed

  return (
    <Stack spacing={3}>
      <Typography variant="body1">{t('eSign')}</Typography>

      <Typography variant="body2">{t('option1desc')}</Typography>

      <Box mt={2}>
        {isSigned ? (
          <Stack direction="row" spacing={2}>
            <LoadingButton
              variant="contained"
              onClick={downloadSigned}
              data-testid="download-documents-button"
              loading={envelopeLoading || loadingDocusignUrl}
            >
              {t('downloadCompletedForms')}
            </LoadingButton>
            <LoadingButton
              variant="outlined"
              onClick={goToDocusign}
              loading={envelopeLoading || loadingDocusignUrl}
              data-testid="view-documents-button"
            >
              {t('reviewForms')}
            </LoadingButton>
          </Stack>
        ) : (
          <LoadingButton
            variant="contained"
            onClick={onClickSign}
            loading={envelopeLoading || loadingDocusignUrl}
            data-testid="view-documents-button"
          >
            {t('reviewAndSign')}
          </LoadingButton>
        )}
      </Box>

      <ResponsiveDialog open={permissionDialogOpen} onClose={() => setPermissionDialogOpen(false)}>
        <DialogTitle>{t('permissionDialogTitle')}</DialogTitle>
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction="row" spacing={1}>
              <Box>
                <Checkbox
                  onChange={onClickCheck}
                  checked={!!permissionDetail?.value}
                  disabled={updateDetail.isPending}
                  data-testid="nrcs-permission-checkbox"
                />
              </Box>
              <Typography sx={{ paddingTop: 1 }}>{t('permissionDescription')}</Typography>
            </Stack>
            <Alert severity="info">{t('verificationCodeAlert')}</Alert>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setPermissionDialogOpen(false)}>{t('cancel')}</Button>
          <LoadingButton
            variant="contained"
            loading={envelopeLoading || loadingDocusignUrl || updateDetail.isPending}
            disabled={!permissionDetail?.value}
            endIcon={<LaunchIcon />}
            onClick={goToDocusign}
            data-testid="open-docusign-button"
          >
            {t('openDocusign')}
          </LoadingButton>
        </DialogActions>
      </ResponsiveDialog>

      <FormHelperText>{t('disclaimer')}</FormHelperText>
    </Stack>
  )
}
