import { ResourceDetailYearly } from '../ResourceDetail'
import { EQIP_PRACTICE_CHOICE_AGROFORESTRY } from './RDEqipPracticeChoiceAgroforestry'
import { EQIP_PRACTICE_CHOICE_ENGINEERING_LAND_EROSION_STRUCTURES } from './RDEqipPracticeChoiceEngineeringLandErosionStructures'
import { EQIP_PRACTICE_CHOICE_ENGINERING_WATER_CONTROL_STRUCTURES } from './RDEqipPracticeChoiceEngineeringWaterControlStructures'
import { EQIP_PRACTICE_CHOICE_SOIL } from './RDEqipPracticeChoiceSoil'
import { EQIP_PRACTICE_CHOICE_WILDLIFE } from './RDEqipPracticeChoiceWildlife'

export const EQIP_CURRENT_PRACTICES = [
  ...EQIP_PRACTICE_CHOICE_AGROFORESTRY,
  ...EQIP_PRACTICE_CHOICE_ENGINEERING_LAND_EROSION_STRUCTURES,
  ...EQIP_PRACTICE_CHOICE_ENGINERING_WATER_CONTROL_STRUCTURES,
  ...EQIP_PRACTICE_CHOICE_SOIL,
  ...EQIP_PRACTICE_CHOICE_WILDLIFE,
] as const

export type EqipCurrentPractices = typeof EQIP_CURRENT_PRACTICES[number]

export type RDEqipCurrentPractices = ResourceDetailYearly<
  { currentPractices: EqipCurrentPractices[] },
  EqipCurrentPractices[]
> & {
  traitId: 'eqipCurrentPractices'
}
