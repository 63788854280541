import { useQuery } from '@tanstack/react-query'

import { FDRStatus, FDRStatusQuery, GrowerProgramsAPI, UserId } from '@cibo/core'

import { PROGRAMS_QUERY_KEY } from './queryKey'

type UseProgramStatsProps = {
  userId?: UserId
  orgId?: string
  fdrStats?: FDRStatusQuery[]
}

const DEFAULT_FDR_STATS = [
  { status: FDRStatus.eligibleForEnrollment },
  { status: FDRStatus.enrolling },
  { status: FDRStatus.enrolled },
]

export const useProgramStats = ({ userId, orgId, fdrStats }: UseProgramStatsProps) =>
  useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_STATS, { userId, orgId, fdrStats }],
    queryFn: () =>
      GrowerProgramsAPI.stats({
        userId,
        orgId,
        fdrStats: fdrStats || DEFAULT_FDR_STATS,
      }),
  })
