import { Theme } from '@cibo/ui'
import chroma from 'chroma-js'

import { LandThumbnailSkin, ThumbnailLayer } from './types'

const outlineTillableSkin = (color: string, pixelRatio: number): ThumbnailLayer[] => {
  const fill = color ? chroma(color).alpha(0.5).hex() : Theme.color.map.mine.fill

  return [
    {
      fill,
    },
    {
      color,
      width: 3 * pixelRatio,
    },
  ]
}

export const layersForProfileSkin = (
  skin: LandThumbnailSkin,
  pixelRatio: number
): ThumbnailLayer[] => {
  const mapColor = Theme.color.map

  switch (skin) {
    case LandThumbnailSkin.BLANK:
      return [{}]

    case LandThumbnailSkin.SELECTED:
      return outlineTillableSkin('#f9ee56', pixelRatio)

    case LandThumbnailSkin.USER_FEATURE:
      return outlineTillableSkin(Theme.color.map.mine.outline, pixelRatio)

    case LandThumbnailSkin.SMALL:
      return [
        {
          color: '#f9ee56',
          width: 2 * pixelRatio,
        },
      ]

    case LandThumbnailSkin.CARBON_CREDIT_POPUP_AVAILABLE:
      return [
        {
          color: mapColor.carbon.outline,
          fill: mapColor.carbon.satelliteFill,
          width: 2 * pixelRatio,
        },
      ]
    case LandThumbnailSkin.CARBON_CREDIT_POPUP_PENDING:
      return [
        {
          color: mapColor.carbonPending.outline,
          fill: mapColor.carbonPending.satelliteFill,
          width: 2 * pixelRatio,
        },
      ]
    case LandThumbnailSkin.CARBON_CREDIT_POPUP_UNAVAILABLE:
      return [
        {
          color: mapColor.carbonUnavailable.outline,
          fill: mapColor.carbonUnavailable.satelliteFill,
          width: 2 * pixelRatio,
        },
      ]

    default:
      return [
        {
          fill: chroma(Theme.color.surface.main.primary).alpha(0.5).hex(),
        },
        {
          color: Theme.color.secondary,
          width: 3 * pixelRatio,
        },
      ]
  }
}
