import { ContentfulApi, getLocale } from '@cibo/core'
import { QUERY_KEY } from '@cibo/landmanager/src/queries/queryKey'
import { useQuery } from '@tanstack/react-query'

export const useResourceConcernsContent = () => {
  const locale = getLocale()

  return useQuery({
    queryKey: [QUERY_KEY.RESOURCE_CONCERN_DEFINITION],
    queryFn: () => ContentfulApi.requestResourceConcernsDefinition(locale),
    gcTime: Infinity,
  })
}
