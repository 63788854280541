import { US_COUNTIES, US_STATES } from '@cibo/core'
import { Autocomplete, FormControl, FormLabel, Stack, TextField } from '@mui/material'
import { useField } from 'formik'
import { equals } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const US_COUNTY_OPTIONS = US_COUNTIES.map(county => ({
  ...county,
  stateFips: county.fips.slice(0, 2),
  stateLabel: US_STATES.find(state => state.fips === county.fips.slice(0, 2))?.label,
}))

export const USCountyFilter = () => {
  const { t } = useTranslation('@cibo/ui/USCountyFilter')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { value: fipsValue = [] }, { setValue: setFipsValue }] = useField<string[] | undefined>(
    'fips'
  )
  const [activeStateFips, setActiveStateFips] = useState<string[]>(
    fipsValue && fipsValue.filter(f => f.length === 2)
  )
  const activeCountyFips = useMemo(() => fipsValue && fipsValue.filter(f => f.length !== 2), [])
  const [activeStateCounties, setActiveStateCounties] = useState<
    { stateFips: string; stateLabel: string | undefined; fips: string; label: string }[]
  >(fipsValue && US_COUNTY_OPTIONS.filter(f => activeStateFips.includes(f.stateFips)))

  const [fips, setFips] = useState<typeof US_COUNTY_OPTIONS>(
    US_COUNTY_OPTIONS.filter(county => fipsValue.includes(county.fips))
  )

  // the `fipsValue` const from useField does not have a static reference, and if left unchecked
  // will cause this component to
  const [staticFipsValue, setStaticFipsValue] = useState(fipsValue)
  useEffect(() => {
    if (!equals(fipsValue, staticFipsValue)) {
      setStaticFipsValue(fipsValue)
    }
  }, [fipsValue])

  // Sets county fips values to a list that includes only those included in the selected states
  useEffect(() => {
    const countyFipsAndActiveStates =
      fipsValue &&
      fipsValue
        .filter(a => a.length === 2 || activeStateFips.includes(a.slice(0, 2)))
        .filter(v => !!v)
    if (!equals(countyFipsAndActiveStates, fipsValue) && !equals(activeCountyFips, fipsValue)) {
      setFipsValue(countyFipsAndActiveStates)
      setFips(US_COUNTY_OPTIONS.filter(county => countyFipsAndActiveStates.includes(county.fips)))
    }
  }, [activeStateFips])

  // this effect sets the list of counties to display in the county picker so that it only displays
  // counties that are in selected states. There is a lot of probably unnecessarily complex state
  // management, but it doesn't work well if the complexity is reduced. The dependency of this effect
  //  is `staticFipsValue` because the formik value updates reference frequently.
  useEffect(() => {
    const newStateFips = fipsValue && fipsValue.filter(f => f.length === 2)
    const newActiveStateCounties =
      fipsValue && US_COUNTY_OPTIONS.filter(f => newStateFips.includes(f.stateFips))
    const countyFipsAndActiveStates =
      fipsValue &&
      fipsValue.filter(a => a.length === 2 || newStateFips.includes(a.slice(0, 2))).filter(v => !!v)
    if (!equals(newStateFips, activeStateFips)) {
      setActiveStateFips(newStateFips)
      setActiveStateCounties(newActiveStateCounties)
      setFipsValue(countyFipsAndActiveStates)
      setFips(US_COUNTY_OPTIONS.filter(county => countyFipsAndActiveStates.includes(county.fips)))
    }
  }, [staticFipsValue])

  const handleChangeFips = (event: any, values: typeof US_COUNTY_OPTIONS) => {
    setFips(values)
    const newFips = [
      ...values.map(({ fips }) => fips),
      ...fipsValue.filter((f: string) => f.length !== 5),
    ].filter(v => !!v)
    setFipsValue(newFips.length === 0 ? undefined : newFips)
  }

  return (
    <FormControl>
      <FormLabel>{t('county')}</FormLabel>
      <Stack spacing={1}>
        <Autocomplete
          multiple
          limitTags={2}
          options={activeStateFips.length > 0 ? activeStateCounties : US_COUNTY_OPTIONS}
          groupBy={option => option.stateLabel || ''}
          getOptionLabel={option => option.label}
          renderInput={params => (
            <TextField {...params} placeholder={fips.length === 0 ? t('any') : ''} />
          )}
          onChange={handleChangeFips}
          size="small"
          value={fips}
          fullWidth
          data-testid="countyDropDown"
        />
      </Stack>
    </FormControl>
  )
}
