import { RDBiological } from '@cibo/core'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const BiologicalCell = ({ traitId, year, params }: TraitCellDisplayProps<RDBiological>) => {
  const { t } = useTranslation('@cibo/landmanager/BiologicalEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return <Typography>{t(detail.result)}</Typography>
}
