import { ContentfulApi, getLocale } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { QUERY_KEY } from './queryKey'

export const usePracticesContent = () => {
  const locale = getLocale()

  return useQuery({
    queryKey: [QUERY_KEY.PRACTICES_CONTENT],
    queryFn: () => ContentfulApi.requestPracticesInfo(locale),
  })
}
