import { RDBiomassBurning } from '@cibo/core'
import { pathEq } from 'ramda'
import { number, object } from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { BiomassBurningCell } from './BiomassBurningCell'
import { BiomassBurningInputEditor } from './BiomassBurningInputEditor'
import { BiomassBurningRollup } from './BiomassBurningRollup'
import { BiomassBurningSimpleCell } from './BiomassBurningSimpleCell'

const TRAIT_ID = 'biomassBurning'
const ns = '@cibo/landmanager/BiomassBurningEditor'

export const BiomassBurning: ITraitFeatureByIdYear<RDBiomassBurning> = {
  traitId: TRAIT_ID,
  cellDisplay: BiomassBurningCell,
  SimpleCellView: BiomassBurningSimpleCell,
  editor: BiomassBurningInputEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: BiomassBurningRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          percentage: 0,
        },
      }),
      isRollupValue: pathEq(['result'], 0),
    },
  },
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail
      ? detail.input ?? {
          percentage: detail.result,
        }
      : {}
  },
  ns,
  validationSchema: ({ t }) =>
    object()
      .shape({
        percentage: number()
          .min(0, t('min', { context: 'percentage' }))
          .max(100, t('max', { context: 'percentage' }))
          .required(t('required', { context: 'percentage' }))
          .typeError(t('required', { context: 'percentage' })),
      })
      .required(t('required')),
}
