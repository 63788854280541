import { AuthUserPermission, UnimplementedPermissions } from '@cibo/core'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { selectAuthUserPermissions } from '../store/AuthReducer/Session'
import { PermissionCheckArgs, checkPermissions } from '../utils'

export const usePermissions = () => {
  const backendPermissions = useSelector(selectAuthUserPermissions)
  const isDebugUser = backendPermissions.includes(AuthUserPermission.DEBUG_USER)

  // allow UnimplementedPermissions if the user is a debug user
  const effectivePermissions = useMemo(
    () => [...backendPermissions, ...(isDebugUser ? UnimplementedPermissions : [])],
    [backendPermissions.join()]
  )

  const can = useCallback(
    (props: Omit<PermissionCheckArgs, 'permissions'>) =>
      checkPermissions({ permissions: effectivePermissions, ...props }),
    [effectivePermissions.join()]
  )

  return {
    isDebugUser,
    permissions: effectivePermissions,
    can,
  }
}
