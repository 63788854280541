import {
  PRIORITIZED_DISPLAYABLE_CASH_CROPS,
  PRIORITY_CASH_CROPS,
  RDCashCropSpecies,
} from '@cibo/core'
import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Slider,
  Stack,
  TextField,
} from '@mui/material'
import { useField } from 'formik'
import { uniq } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { minMaxYears } from './utils'

const currentYear = new Date().getFullYear()
const CASH_CROP_YEARS = new Array(5).fill(0).map((v, i) => currentYear - i)

interface CashCropOption {
  crop: RDCashCropSpecies
  priority: string
}

export const CashCropFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/CashCropFilter')
  const { t: tCrop } = useTranslation('@cibo/ui/cropNames')

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, { value: cashCrops = [] }, { setValue }] = useField('cashCrop')

  const options = useMemo(
    () =>
      PRIORITIZED_DISPLAYABLE_CASH_CROPS.map(crop => ({
        crop,
        priority: PRIORITY_CASH_CROPS.includes(crop) ? t('suggested') : t('other'),
      })),
    []
  )

  const [crops, setCrops] = useState<CashCropOption[]>(
    uniq(cashCrops.map((c: string) => c.split(':')[1])).map(crop =>
      options.find(option => option.crop === crop)
    ) as CashCropOption[]
  )
  const [years, setYears] = useState<number[]>(
    cashCrops.length > 0
      ? minMaxYears(cashCrops.map((c: string) => parseInt(c.split(':')[0])).sort())
      : [CASH_CROP_YEARS[CASH_CROP_YEARS.length - 1], CASH_CROP_YEARS[0]]
  )

  const handleChangeYear = (event: any, newYears: number | number[], activeThumb: number) => {
    if (Array.isArray(newYears)) {
      setYears(newYears)
    }
  }

  const handleChangeCrop = (event: any, newCrops: CashCropOption[], reason: any) => {
    setCrops(newCrops)
  }

  /**
   * update the formik field when we have a valid input - which means
   * at least one crop and a range of years
   */
  useEffect(() => {
    if (crops.length > 0 && years.length > 0) {
      const newCashCrops: string[] = []
      for (let year = years[0]; year <= years[1]; year++) {
        crops.forEach(({ crop }) => newCashCrops.push(`${year}:${crop}`))
      }
      setValue(newCashCrops)
    } else {
      setValue(undefined)
    }
  }, [crops.map(({ crop }) => crop).join(), years.join()])

  return (
    <FormControl>
      <FormLabel>{t('cashCrop')}</FormLabel>
      <Stack>
        <Autocomplete<CashCropOption, true>
          multiple
          limitTags={2}
          options={options}
          groupBy={({ priority }) => priority}
          getOptionLabel={({ crop }) => tCrop(crop)}
          renderInput={params => (
            <TextField {...params} placeholder={crops.length === 0 ? t('any') : ''} />
          )}
          onChange={handleChangeCrop}
          size="small"
          value={crops}
          fullWidth
        />
        <Collapse in={crops.length > 0}>
          <Box sx={{ px: 2 }}>
            <Slider
              min={CASH_CROP_YEARS[CASH_CROP_YEARS.length - 1]}
              max={CASH_CROP_YEARS[0]}
              value={years}
              onChange={handleChangeYear}
              valueLabelDisplay="auto"
              disabled={crops.length === 0}
              marks={[
                {
                  value: CASH_CROP_YEARS[CASH_CROP_YEARS.length - 1],
                  label: CASH_CROP_YEARS[CASH_CROP_YEARS.length - 1],
                },
                { value: CASH_CROP_YEARS[0], label: CASH_CROP_YEARS[0] },
              ]}
              size="small"
            />
          </Box>
        </Collapse>
      </Stack>
    </FormControl>
  )
}
