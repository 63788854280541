import { usePaginatedWorkflowFields, useWorkflow } from '../../../queries'

export const useLedgerConflicts = (workflowId?: string) => {
  const workflow = useWorkflow(workflowId)
  const workflowFields = usePaginatedWorkflowFields(workflowId)

  const fields = workflowFields.data?.items || []

  const conflicts = workflow.data
    ? fields?.filter(field => field.hasLedgerConfict(workflow.data.programId))
    : []

  return {
    ...workflowFields,
    conflicts,
  }
}
