import { GridColDef, GridColumnVisibilityModel, useGridApiRef } from '@mui/x-data-grid-pro'
import { sortBy } from 'ramda'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useUserData } from './useUserData'

interface DataGridSettings {
  columnOrder?: string[]
  columnVisibility?: GridColumnVisibilityModel
  columnWidth?: Record<string, number>
  pageSize?: number
}

type DataGridPersistedProperty = keyof DataGridSettings

export const useDataGridPersistence = ({
  columns,
  key,
  options,
  defaults,
}: {
  columns: GridColDef[]
  key?: string
  options: DataGridPersistedProperty[]
  defaults?: Partial<{
    pageSize?: number
    columnVisibilityModel?: GridColumnVisibilityModel
  }>
}) => {
  const apiRef = useGridApiRef()
  const userData = useUserData<DataGridSettings>(key)
  const [initialUserData, setInitialUserData] = useState<DataGridSettings | undefined>()

  const columnNames = new Set(columns.map(({ field }) => field))

  // The changePageSize event does not fire unless the pageSize and
  // onChangePageSize props are there - so just control it with state.
  const [pageSize, onPageSizeChange] = useState(defaults?.pageSize || 10) // must be in pageSizeOptions

  const [columnVisibilityModel, setColumnVisibilityModel] = useState<
    GridColumnVisibilityModel | undefined
  >(defaults?.columnVisibilityModel)

  useEffect(() => {
    if (userData.data?.pageSize) {
      onPageSizeChange(userData.data.pageSize)
    }
    if (userData.data?.columnVisibility) {
      setColumnVisibilityModel(userData.data.columnVisibility)
    }
    setInitialUserData(userData.data)
  }, [userData.isFetched])

  const initialColumns = useMemo(() => {
    if (!initialUserData) return columns

    const columnOrder = initialUserData?.columnOrder

    const orderedColumns = columnOrder
      ? sortBy(({ field }) => {
          const order = columnOrder.indexOf(field)

          return typeof order === 'number' ? order : Number.POSITIVE_INFINITY
        }, columns)
      : columns

    const columnWidth = initialUserData?.columnWidth

    return orderedColumns.map(column => {
      const withWidth = { ...column }

      const width = column && columnWidth && columnWidth[column.field]
      if (width) withWidth.width = width

      return withWidth
    })
  }, [columns, initialUserData])

  const updateValue = useCallback(
    (name: DataGridPersistedProperty, value: any) => {
      if (!initialUserData) return

      const newObject = {
        ...userData.data,
        [name]: value,
      }
      userData.update(newObject)
    },
    [initialUserData, userData.data]
  )

  const mergeColumnWidth = useCallback(
    (key: string, value: any) => {
      if (!initialUserData) return

      const oldValue = userData.data?.columnWidth

      updateValue('columnWidth', { ...oldValue, [key]: value })
    },
    [initialUserData, userData.data, updateValue]
  )

  useEffect(() => {
    if (options.includes('pageSize') && pageSize !== userData.data?.pageSize) {
      updateValue('pageSize', pageSize)
    }
  }, [pageSize])

  const onColumnVisibilityModelChange = useCallback(
    (newModel: GridColumnVisibilityModel) => {
      updateValue('columnVisibility', newModel)
      setColumnVisibilityModel(newModel)
    },
    [options]
  )

  useEffect(() => {
    if (!apiRef.current?.subscribeEvent) return

    const { current: grid } = apiRef

    const unsubscribes: Function[] = []

    if (options.includes('columnOrder')) {
      unsubscribes.push(
        grid.subscribeEvent('columnOrderChange', (params, event, details) => {
          const newOrder = grid.getVisibleColumns().map(c => c.field)
          updateValue(
            'columnOrder',
            newOrder.filter(field => columnNames.has(field))
          )
        })
      )
    }

    if (options.includes('columnWidth')) {
      unsubscribes.push(
        grid.subscribeEvent('columnWidthChange', ({ colDef, width }) => {
          mergeColumnWidth(colDef.field, width)
        })
      )
    }

    return () => {
      unsubscribes.forEach(unsubscribe => unsubscribe())
    }
  }, [apiRef.current, updateValue, mergeColumnWidth])

  return {
    apiRef,
    columns: initialColumns,
    pageSize,
    onPageSizeChange,
    ...(options.includes('columnVisibility')
      ? { columnVisibilityModel, onColumnVisibilityModelChange }
      : {}),
  }
}
