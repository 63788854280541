import { Box, FormControl, FormLabel, Stack, TextField } from '@mui/material'
import { useField } from 'formik'
import React, { Fragment } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers-pro'
import { DateRangePicker, DateRange } from '@mui/x-date-pickers-pro'

export const LastUpdatedFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/LastUpdatedFilter')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedGt, { value: lastUpdatedGt }, { setValue: setLastUpdatedGt }] = useField(
    'lastUpdatedGt'
  )
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updatedLt, { value: lastUpdatedLt }, { setValue: setLastUpdatedLt }] = useField(
    'lastUpdatedLt'
  )

  const [value, setValue] = React.useState<DateRange<Date>>([
    lastUpdatedGt ? new Date(lastUpdatedGt) : null,
    lastUpdatedLt ? new Date(lastUpdatedLt) : null,
  ])

  const handleChangeDateRange = (newRange: [Date | null, Date | null]) => {
    setValue(newRange)
    setLastUpdatedGt(newRange[0] === null ? undefined : dayjs(newRange[0]).format('YYYY-MM-DD'))
    setLastUpdatedLt(newRange[1] === null ? undefined : dayjs(newRange[1]).format('YYYY-MM-DD'))
  }

  return (
    <FormControl>
      <FormLabel>{t('lastUpdated')}</FormLabel>
      <Stack spacing={1}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DateRangePicker
            value={value}
            onChange={handleChangeDateRange}
            renderInput={(
              { label: startLabel, ...startProps },
              { label: endLabel, ...endProps }
            ) => (
              <Fragment>
                <TextField {...startProps} />
                <Box sx={{ mx: 2 }}> to </Box>
                <TextField {...endProps} />
              </Fragment>
            )}
          />
        </LocalizationProvider>
      </Stack>
    </FormControl>
  )
}
