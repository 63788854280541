import '@formatjs/intl-listformat/locale-data/en'
import '@formatjs/intl-listformat/polyfill'

import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import calendar from 'dayjs/plugin/calendar'
import dayOfYear from 'dayjs/plugin/dayOfYear'
import relativeTime from 'dayjs/plugin/relativeTime'
import { InitOptions, Resource, i18n } from 'i18next'
import numeral from 'numeral'
import { getLocale } from '../utils/getLocale'

dayjs.extend(dayOfYear)
dayjs.extend(calendar)
dayjs.extend(relativeTime)
dayjs.extend(advancedFormat)

const anLetters = ['a', 'e', 'i', 'o', 'u']

const getIndefiniteArticle = (value: string, capitalize: boolean) => {
  const firstLetter = (value && value.substring(0, 1).toLowerCase()) || 'b'

  if (anLetters.find(l => firstLetter === l)) {
    return capitalize ? 'An' : 'an'
  } else {
    return capitalize ? 'A' : 'a'
  }
}

export const initTranslationFormat = (i18n: i18n, resources: Resource, config?: any) =>
  i18n.init({
    resources,
    lng: getLocale(),
    fallbackLng: 'en-US',
    debug: !!config?.DEV_ENV,
    defaultNS: '@cibo/impact',
    fallbackNS: [
      'privatelabel',
      'clientLocalization',
      '@cibo/impact',
      '@cibo/ui',
      '@cibo/programs',
      '@cibo/landmanager',
      'traits',
      'crops',
      'livestock',
      '@cibo/profile',
    ],
    appendNamespaceToCIMode: true,
    interpolation: {
      escapeValue: false, // react already safes from xss

      format: function(value, format, lng) {
        if (lng && lng.indexOf('en-US') !== 0) return ''

        if (format === 'en-indefinite-article')
          return `${getIndefiniteArticle(value, false)} ${value}`

        if (format === 'en-indefinite-article-capitalized')
          return `${getIndefiniteArticle(value, true)} ${value}`

        if (typeof value === 'number' && format) {
          return numeral(value).format(format)
        }

        if (value instanceof Date && format) {
          return dayjs(value).format(format)
        }
        if (format === 'list') {
          return new Intl.ListFormat(lng, { style: 'long', type: 'conjunction' }).format(value)
        }

        return value
      },
    },
  } as InitOptions)
