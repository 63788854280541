/* istanbul ignore file */

import { getMpNodeAgent } from '@cibo/core'

const agent = getMpNodeAgent()

const PATHS = {
  DOWNLOAD: '/task-documents/v1',
}

type BundleParams = {
  engagementId: string
  taskId: string
  name: string
}

export class TaskDocumentsAPI {
  static download(params: BundleParams): Promise<any> {
    const query = new URLSearchParams(params)

    return agent.get(`${PATHS.DOWNLOAD}?${query.toString()}`, { responseType: 'blob' })
  }
}
