type RequestQueueItem = {
  request: Function
  resolve(response: any): void
  reject(error: Error): void
}

const requestQueue: RequestQueueItem[] = []

let busy = false

const processNextRequest = () => {
  if (busy) return

  const item = requestQueue.shift()
  if (!item) return

  busy = true

  item
    .request()
    .then(item.resolve)
    .catch(item.reject)
    .finally(() => {
      busy = false

      processNextRequest()
    })
}

/* Force these async functions to run sequentially. This is intended
 * to avoid repeated requests that potentially involve the same locks,
 * which can result in timeouts.
 *
 * example: mutating details (locks db by user)
 */
export const queueRequest = <T>(request: Function) =>
  new Promise<T>((resolve, reject) => {
    requestQueue.push({ request, resolve, reject })

    processNextRequest()
  })
