export const US_COUNTIES = [
  {
    fips: '01001',
    label: 'Autauga',
  },
  {
    fips: '01003',
    label: 'Baldwin',
  },
  {
    fips: '01005',
    label: 'Barbour',
  },
  {
    fips: '01007',
    label: 'Bibb',
  },
  {
    fips: '01009',
    label: 'Blount',
  },
  {
    fips: '01011',
    label: 'Bullock',
  },
  {
    fips: '01013',
    label: 'Butler',
  },
  {
    fips: '01015',
    label: 'Calhoun',
  },
  {
    fips: '01017',
    label: 'Chambers',
  },
  {
    fips: '01019',
    label: 'Cherokee',
  },
  {
    fips: '01021',
    label: 'Chilton',
  },
  {
    fips: '01023',
    label: 'Choctaw',
  },
  {
    fips: '01025',
    label: 'Clarke',
  },
  {
    fips: '01027',
    label: 'Clay',
  },
  {
    fips: '01029',
    label: 'Cleburne',
  },
  {
    fips: '01031',
    label: 'Coffee',
  },
  {
    fips: '01033',
    label: 'Colbert',
  },
  {
    fips: '01035',
    label: 'Conecuh',
  },
  {
    fips: '01037',
    label: 'Coosa',
  },
  {
    fips: '01039',
    label: 'Covington',
  },
  {
    fips: '01041',
    label: 'Crenshaw',
  },
  {
    fips: '01043',
    label: 'Cullman',
  },
  {
    fips: '01045',
    label: 'Dale',
  },
  {
    fips: '01047',
    label: 'Dallas',
  },
  {
    fips: '01049',
    label: 'DeKalb',
  },
  {
    fips: '01051',
    label: 'Elmore',
  },
  {
    fips: '01053',
    label: 'Escambia',
  },
  {
    fips: '01055',
    label: 'Etowah',
  },
  {
    fips: '01057',
    label: 'Fayette',
  },
  {
    fips: '01059',
    label: 'Franklin',
  },
  {
    fips: '01061',
    label: 'Geneva',
  },
  {
    fips: '01063',
    label: 'Greene',
  },
  {
    fips: '01065',
    label: 'Hale',
  },
  {
    fips: '01067',
    label: 'Henry',
  },
  {
    fips: '01069',
    label: 'Houston',
  },
  {
    fips: '01071',
    label: 'Jackson',
  },
  {
    fips: '01073',
    label: 'Jefferson',
  },
  {
    fips: '01075',
    label: 'Lamar',
  },
  {
    fips: '01077',
    label: 'Lauderdale',
  },
  {
    fips: '01079',
    label: 'Lawrence',
  },
  {
    fips: '01081',
    label: 'Lee',
  },
  {
    fips: '01083',
    label: 'Limestone',
  },
  {
    fips: '01085',
    label: 'Lowndes',
  },
  {
    fips: '01087',
    label: 'Macon',
  },
  {
    fips: '01089',
    label: 'Madison',
  },
  {
    fips: '01091',
    label: 'Marengo',
  },
  {
    fips: '01093',
    label: 'Marion',
  },
  {
    fips: '01095',
    label: 'Marshall',
  },
  {
    fips: '01097',
    label: 'Mobile',
  },
  {
    fips: '01099',
    label: 'Monroe',
  },
  {
    fips: '01101',
    label: 'Montgomery',
  },
  {
    fips: '01103',
    label: 'Morgan',
  },
  {
    fips: '01105',
    label: 'Perry',
  },
  {
    fips: '01107',
    label: 'Pickens',
  },
  {
    fips: '01109',
    label: 'Pike',
  },
  {
    fips: '01111',
    label: 'Randolph',
  },
  {
    fips: '01113',
    label: 'Russell',
  },
  {
    fips: '01115',
    label: 'St. Clair',
  },
  {
    fips: '01117',
    label: 'Shelby',
  },
  {
    fips: '01119',
    label: 'Sumter',
  },
  {
    fips: '01121',
    label: 'Talladega',
  },
  {
    fips: '01123',
    label: 'Tallapoosa',
  },
  {
    fips: '01125',
    label: 'Tuscaloosa',
  },
  {
    fips: '01127',
    label: 'Walker',
  },
  {
    fips: '01129',
    label: 'Washington',
  },
  {
    fips: '01131',
    label: 'Wilcox',
  },
  {
    fips: '01133',
    label: 'Winston',
  },
  {
    fips: '02013',
    label: 'Aleutians East Borough',
  },
  {
    fips: '02016',
    label: 'Aleutians West Census Area',
  },
  {
    fips: '02020',
    label: 'Anchorage',
  },
  {
    fips: '02050',
    label: 'Bethel Census Area',
  },
  {
    fips: '02060',
    label: 'Bristol Bay Borough',
  },
  {
    fips: '02068',
    label: 'Denali Borough',
  },
  {
    fips: '02070',
    label: 'Dillingham Census Area',
  },
  {
    fips: '02090',
    label: 'Fairbanks North Star Borough',
  },
  {
    fips: '02100',
    label: 'Haines Borough',
  },
  {
    fips: '02105',
    label: 'Hoonah-Angoon Census Area',
  },
  {
    fips: '02110',
    label: 'Juneau City and Borough',
  },
  {
    fips: '02122',
    label: 'Kenai Peninsula Borough',
  },
  {
    fips: '02130',
    label: 'Ketchikan Gateway Borough',
  },
  {
    fips: '02150',
    label: 'Kodiak Island Borough',
  },
  {
    fips: '02158',
    label: 'Kusilvak Census Area',
  },
  {
    fips: '02164',
    label: 'Lake and Peninsula Borough',
  },
  {
    fips: '02170',
    label: 'Matanuska-Susitna Borough',
  },
  {
    fips: '02180',
    label: 'Nome Census Area',
  },
  {
    fips: '02185',
    label: 'North Slope Borough',
  },
  {
    fips: '02188',
    label: 'Northwest Arctic Borough',
  },
  {
    fips: '02195',
    label: 'Petersburg Borough',
  },
  {
    fips: '02198',
    label: 'Prince of Wales-Hyder Census Area',
  },
  {
    fips: '02220',
    label: 'Sitka City and Borough',
  },
  {
    fips: '02230',
    label: 'Skagway',
  },
  {
    fips: '02240',
    label: 'Southeast Fairbanks Census Area',
  },
  {
    fips: '02261',
    label: 'Valdez-Cordova Census Area',
  },
  {
    fips: '02275',
    label: 'Wrangell City and Borough',
  },
  {
    fips: '02282',
    label: 'Yakutat City and Borough',
  },
  {
    fips: '02290',
    label: 'Yukon-Koyukuk Census Area',
  },
  {
    fips: '04001',
    label: 'Apache',
  },
  {
    fips: '04003',
    label: 'Cochise',
  },
  {
    fips: '04005',
    label: 'Coconino',
  },
  {
    fips: '04007',
    label: 'Gila',
  },
  {
    fips: '04009',
    label: 'Graham',
  },
  {
    fips: '04011',
    label: 'Greenlee',
  },
  {
    fips: '04012',
    label: 'La Paz',
  },
  {
    fips: '04013',
    label: 'Maricopa',
  },
  {
    fips: '04015',
    label: 'Mohave',
  },
  {
    fips: '04017',
    label: 'Navajo',
  },
  {
    fips: '04019',
    label: 'Pima',
  },
  {
    fips: '04021',
    label: 'Pinal',
  },
  {
    fips: '04023',
    label: 'Santa Cruz',
  },
  {
    fips: '04025',
    label: 'Yavapai',
  },
  {
    fips: '04027',
    label: 'Yuma',
  },
  {
    fips: '05001',
    label: 'Arkansas',
  },
  {
    fips: '05003',
    label: 'Ashley',
  },
  {
    fips: '05005',
    label: 'Baxter',
  },
  {
    fips: '05007',
    label: 'Benton',
  },
  {
    fips: '05009',
    label: 'Boone',
  },
  {
    fips: '05011',
    label: 'Bradley',
  },
  {
    fips: '05013',
    label: 'Calhoun',
  },
  {
    fips: '05015',
    label: 'Carroll',
  },
  {
    fips: '05017',
    label: 'Chicot',
  },
  {
    fips: '05019',
    label: 'Clark',
  },
  {
    fips: '05021',
    label: 'Clay',
  },
  {
    fips: '05023',
    label: 'Cleburne',
  },
  {
    fips: '05025',
    label: 'Cleveland',
  },
  {
    fips: '05027',
    label: 'Columbia',
  },
  {
    fips: '05029',
    label: 'Conway',
  },
  {
    fips: '05031',
    label: 'Craighead',
  },
  {
    fips: '05033',
    label: 'Crawford',
  },
  {
    fips: '05035',
    label: 'Crittenden',
  },
  {
    fips: '05037',
    label: 'Cross',
  },
  {
    fips: '05039',
    label: 'Dallas',
  },
  {
    fips: '05041',
    label: 'Desha',
  },
  {
    fips: '05043',
    label: 'Drew',
  },
  {
    fips: '05045',
    label: 'Faulkner',
  },
  {
    fips: '05047',
    label: 'Franklin',
  },
  {
    fips: '05049',
    label: 'Fulton',
  },
  {
    fips: '05051',
    label: 'Garland',
  },
  {
    fips: '05053',
    label: 'Grant',
  },
  {
    fips: '05055',
    label: 'Greene',
  },
  {
    fips: '05057',
    label: 'Hempstead',
  },
  {
    fips: '05059',
    label: 'Hot Spring',
  },
  {
    fips: '05061',
    label: 'Howard',
  },
  {
    fips: '05063',
    label: 'Independence',
  },
  {
    fips: '05065',
    label: 'Izard',
  },
  {
    fips: '05067',
    label: 'Jackson',
  },
  {
    fips: '05069',
    label: 'Jefferson',
  },
  {
    fips: '05071',
    label: 'Johnson',
  },
  {
    fips: '05073',
    label: 'Lafayette',
  },
  {
    fips: '05075',
    label: 'Lawrence',
  },
  {
    fips: '05077',
    label: 'Lee',
  },
  {
    fips: '05079',
    label: 'Lincoln',
  },
  {
    fips: '05081',
    label: 'Little River',
  },
  {
    fips: '05083',
    label: 'Logan',
  },
  {
    fips: '05085',
    label: 'Lonoke',
  },
  {
    fips: '05087',
    label: 'Madison',
  },
  {
    fips: '05089',
    label: 'Marion',
  },
  {
    fips: '05091',
    label: 'Miller',
  },
  {
    fips: '05093',
    label: 'Mississippi',
  },
  {
    fips: '05095',
    label: 'Monroe',
  },
  {
    fips: '05097',
    label: 'Montgomery',
  },
  {
    fips: '05099',
    label: 'Nevada',
  },
  {
    fips: '05101',
    label: 'Newton',
  },
  {
    fips: '05103',
    label: 'Ouachita',
  },
  {
    fips: '05105',
    label: 'Perry',
  },
  {
    fips: '05107',
    label: 'Phillips',
  },
  {
    fips: '05109',
    label: 'Pike',
  },
  {
    fips: '05111',
    label: 'Poinsett',
  },
  {
    fips: '05113',
    label: 'Polk',
  },
  {
    fips: '05115',
    label: 'Pope',
  },
  {
    fips: '05117',
    label: 'Prairie',
  },
  {
    fips: '05119',
    label: 'Pulaski',
  },
  {
    fips: '05121',
    label: 'Randolph',
  },
  {
    fips: '05123',
    label: 'St. Francis',
  },
  {
    fips: '05125',
    label: 'Saline',
  },
  {
    fips: '05127',
    label: 'Scott',
  },
  {
    fips: '05129',
    label: 'Searcy',
  },
  {
    fips: '05131',
    label: 'Sebastian',
  },
  {
    fips: '05133',
    label: 'Sevier',
  },
  {
    fips: '05135',
    label: 'Sharp',
  },
  {
    fips: '05137',
    label: 'Stone',
  },
  {
    fips: '05139',
    label: 'Union',
  },
  {
    fips: '05141',
    label: 'Van Buren',
  },
  {
    fips: '05143',
    label: 'Washington',
  },
  {
    fips: '05145',
    label: 'White',
  },
  {
    fips: '05147',
    label: 'Woodruff',
  },
  {
    fips: '05149',
    label: 'Yell',
  },
  {
    fips: '06001',
    label: 'Alameda',
  },
  {
    fips: '06003',
    label: 'Alpine',
  },
  {
    fips: '06005',
    label: 'Amador',
  },
  {
    fips: '06007',
    label: 'Butte',
  },
  {
    fips: '06009',
    label: 'Calaveras',
  },
  {
    fips: '06011',
    label: 'Colusa',
  },
  {
    fips: '06013',
    label: 'Contra Costa',
  },
  {
    fips: '06015',
    label: 'Del Norte',
  },
  {
    fips: '06017',
    label: 'El Dorado',
  },
  {
    fips: '06019',
    label: 'Fresno',
  },
  {
    fips: '06021',
    label: 'Glenn',
  },
  {
    fips: '06023',
    label: 'Humboldt',
  },
  {
    fips: '06025',
    label: 'Imperial',
  },
  {
    fips: '06027',
    label: 'Inyo',
  },
  {
    fips: '06029',
    label: 'Kern',
  },
  {
    fips: '06031',
    label: 'Kings',
  },
  {
    fips: '06033',
    label: 'Lake',
  },
  {
    fips: '06035',
    label: 'Lassen',
  },
  {
    fips: '06037',
    label: 'Los Angeles',
  },
  {
    fips: '06039',
    label: 'Madera',
  },
  {
    fips: '06041',
    label: 'Marin',
  },
  {
    fips: '06043',
    label: 'Mariposa',
  },
  {
    fips: '06045',
    label: 'Mendocino',
  },
  {
    fips: '06047',
    label: 'Merced',
  },
  {
    fips: '06049',
    label: 'Modoc',
  },
  {
    fips: '06051',
    label: 'Mono',
  },
  {
    fips: '06053',
    label: 'Monterey',
  },
  {
    fips: '06055',
    label: 'Napa',
  },
  {
    fips: '06057',
    label: 'Nevada',
  },
  {
    fips: '06059',
    label: 'Orange',
  },
  {
    fips: '06061',
    label: 'Placer',
  },
  {
    fips: '06063',
    label: 'Plumas',
  },
  {
    fips: '06065',
    label: 'Riverside',
  },
  {
    fips: '06067',
    label: 'Sacramento',
  },
  {
    fips: '06069',
    label: 'San Benito',
  },
  {
    fips: '06071',
    label: 'San Bernardino',
  },
  {
    fips: '06073',
    label: 'San Diego',
  },
  {
    fips: '06075',
    label: 'San Francisco',
  },
  {
    fips: '06077',
    label: 'San Joaquin',
  },
  {
    fips: '06079',
    label: 'San Luis Obispo',
  },
  {
    fips: '06081',
    label: 'San Mateo',
  },
  {
    fips: '06083',
    label: 'Santa Barbara',
  },
  {
    fips: '06085',
    label: 'Santa Clara',
  },
  {
    fips: '06087',
    label: 'Santa Cruz',
  },
  {
    fips: '06089',
    label: 'Shasta',
  },
  {
    fips: '06091',
    label: 'Sierra',
  },
  {
    fips: '06093',
    label: 'Siskiyou',
  },
  {
    fips: '06095',
    label: 'Solano',
  },
  {
    fips: '06097',
    label: 'Sonoma',
  },
  {
    fips: '06099',
    label: 'Stanislaus',
  },
  {
    fips: '06101',
    label: 'Sutter',
  },
  {
    fips: '06103',
    label: 'Tehama',
  },
  {
    fips: '06105',
    label: 'Trinity',
  },
  {
    fips: '06107',
    label: 'Tulare',
  },
  {
    fips: '06109',
    label: 'Tuolumne',
  },
  {
    fips: '06111',
    label: 'Ventura',
  },
  {
    fips: '06113',
    label: 'Yolo',
  },
  {
    fips: '06115',
    label: 'Yuba',
  },
  {
    fips: '08001',
    label: 'Adams',
  },
  {
    fips: '08003',
    label: 'Alamosa',
  },
  {
    fips: '08005',
    label: 'Arapahoe',
  },
  {
    fips: '08007',
    label: 'Archuleta',
  },
  {
    fips: '08009',
    label: 'Baca',
  },
  {
    fips: '08011',
    label: 'Bent',
  },
  {
    fips: '08013',
    label: 'Boulder',
  },
  {
    fips: '08014',
    label: 'Broomfield',
  },
  {
    fips: '08015',
    label: 'Chaffee',
  },
  {
    fips: '08017',
    label: 'Cheyenne',
  },
  {
    fips: '08019',
    label: 'Clear Creek',
  },
  {
    fips: '08021',
    label: 'Conejos',
  },
  {
    fips: '08023',
    label: 'Costilla',
  },
  {
    fips: '08025',
    label: 'Crowley',
  },
  {
    fips: '08027',
    label: 'Custer',
  },
  {
    fips: '08029',
    label: 'Delta',
  },
  {
    fips: '08031',
    label: 'Denver',
  },
  {
    fips: '08033',
    label: 'Dolores',
  },
  {
    fips: '08035',
    label: 'Douglas',
  },
  {
    fips: '08037',
    label: 'Eagle',
  },
  {
    fips: '08039',
    label: 'Elbert',
  },
  {
    fips: '08041',
    label: 'El Paso',
  },
  {
    fips: '08043',
    label: 'Fremont',
  },
  {
    fips: '08045',
    label: 'Garfield',
  },
  {
    fips: '08047',
    label: 'Gilpin',
  },
  {
    fips: '08049',
    label: 'Grand',
  },
  {
    fips: '08051',
    label: 'Gunnison',
  },
  {
    fips: '08053',
    label: 'Hinsdale',
  },
  {
    fips: '08055',
    label: 'Huerfano',
  },
  {
    fips: '08057',
    label: 'Jackson',
  },
  {
    fips: '08059',
    label: 'Jefferson',
  },
  {
    fips: '08061',
    label: 'Kiowa',
  },
  {
    fips: '08063',
    label: 'Kit Carson',
  },
  {
    fips: '08065',
    label: 'Lake',
  },
  {
    fips: '08067',
    label: 'La Plata',
  },
  {
    fips: '08069',
    label: 'Larimer',
  },
  {
    fips: '08071',
    label: 'Las Animas',
  },
  {
    fips: '08073',
    label: 'Lincoln',
  },
  {
    fips: '08075',
    label: 'Logan',
  },
  {
    fips: '08077',
    label: 'Mesa',
  },
  {
    fips: '08079',
    label: 'Mineral',
  },
  {
    fips: '08081',
    label: 'Moffat',
  },
  {
    fips: '08083',
    label: 'Montezuma',
  },
  {
    fips: '08085',
    label: 'Montrose',
  },
  {
    fips: '08087',
    label: 'Morgan',
  },
  {
    fips: '08089',
    label: 'Otero',
  },
  {
    fips: '08091',
    label: 'Ouray',
  },
  {
    fips: '08093',
    label: 'Park',
  },
  {
    fips: '08095',
    label: 'Phillips',
  },
  {
    fips: '08097',
    label: 'Pitkin',
  },
  {
    fips: '08099',
    label: 'Prowers',
  },
  {
    fips: '08101',
    label: 'Pueblo',
  },
  {
    fips: '08103',
    label: 'Rio Blanco',
  },
  {
    fips: '08105',
    label: 'Rio Grande',
  },
  {
    fips: '08107',
    label: 'Routt',
  },
  {
    fips: '08109',
    label: 'Saguache',
  },
  {
    fips: '08111',
    label: 'San Juan',
  },
  {
    fips: '08113',
    label: 'San Miguel',
  },
  {
    fips: '08115',
    label: 'Sedgwick',
  },
  {
    fips: '08117',
    label: 'Summit',
  },
  {
    fips: '08119',
    label: 'Teller',
  },
  {
    fips: '08121',
    label: 'Washington',
  },
  {
    fips: '08123',
    label: 'Weld',
  },
  {
    fips: '08125',
    label: 'Yuma',
  },
  {
    fips: '09001',
    label: 'Fairfield',
  },
  {
    fips: '09003',
    label: 'Hartford',
  },
  {
    fips: '09005',
    label: 'Litchfield',
  },
  {
    fips: '09007',
    label: 'Middlesex',
  },
  {
    fips: '09009',
    label: 'New Haven',
  },
  {
    fips: '09011',
    label: 'New London',
  },
  {
    fips: '09013',
    label: 'Tolland',
  },
  {
    fips: '09015',
    label: 'Windham',
  },
  {
    fips: '10001',
    label: 'Kent',
  },
  {
    fips: '10003',
    label: 'New Castle',
  },
  {
    fips: '10005',
    label: 'Sussex',
  },
  {
    fips: '11001',
    label: 'District of Columbia',
  },
  {
    fips: '12001',
    label: 'Alachua',
  },
  {
    fips: '12003',
    label: 'Baker',
  },
  {
    fips: '12005',
    label: 'Bay',
  },
  {
    fips: '12007',
    label: 'Bradford',
  },
  {
    fips: '12009',
    label: 'Brevard',
  },
  {
    fips: '12011',
    label: 'Broward',
  },
  {
    fips: '12013',
    label: 'Calhoun',
  },
  {
    fips: '12015',
    label: 'Charlotte',
  },
  {
    fips: '12017',
    label: 'Citrus',
  },
  {
    fips: '12019',
    label: 'Clay',
  },
  {
    fips: '12021',
    label: 'Collier',
  },
  {
    fips: '12023',
    label: 'Columbia',
  },
  {
    fips: '12027',
    label: 'DeSoto',
  },
  {
    fips: '12029',
    label: 'Dixie',
  },
  {
    fips: '12031',
    label: 'Duval',
  },
  {
    fips: '12033',
    label: 'Escambia',
  },
  {
    fips: '12035',
    label: 'Flagler',
  },
  {
    fips: '12037',
    label: 'Franklin',
  },
  {
    fips: '12039',
    label: 'Gadsden',
  },
  {
    fips: '12041',
    label: 'Gilchrist',
  },
  {
    fips: '12043',
    label: 'Glades',
  },
  {
    fips: '12045',
    label: 'Gulf',
  },
  {
    fips: '12047',
    label: 'Hamilton',
  },
  {
    fips: '12049',
    label: 'Hardee',
  },
  {
    fips: '12051',
    label: 'Hendry',
  },
  {
    fips: '12053',
    label: 'Hernando',
  },
  {
    fips: '12055',
    label: 'Highlands',
  },
  {
    fips: '12057',
    label: 'Hillsborough',
  },
  {
    fips: '12059',
    label: 'Holmes',
  },
  {
    fips: '12061',
    label: 'Indian River',
  },
  {
    fips: '12063',
    label: 'Jackson',
  },
  {
    fips: '12065',
    label: 'Jefferson',
  },
  {
    fips: '12067',
    label: 'Lafayette',
  },
  {
    fips: '12069',
    label: 'Lake',
  },
  {
    fips: '12071',
    label: 'Lee',
  },
  {
    fips: '12073',
    label: 'Leon',
  },
  {
    fips: '12075',
    label: 'Levy',
  },
  {
    fips: '12077',
    label: 'Liberty',
  },
  {
    fips: '12079',
    label: 'Madison',
  },
  {
    fips: '12081',
    label: 'Manatee',
  },
  {
    fips: '12083',
    label: 'Marion',
  },
  {
    fips: '12085',
    label: 'Martin',
  },
  {
    fips: '12086',
    label: 'Miami-Dade',
  },
  {
    fips: '12087',
    label: 'Monroe',
  },
  {
    fips: '12089',
    label: 'Nassau',
  },
  {
    fips: '12091',
    label: 'Okaloosa',
  },
  {
    fips: '12093',
    label: 'Okeechobee',
  },
  {
    fips: '12095',
    label: 'Orange',
  },
  {
    fips: '12097',
    label: 'Osceola',
  },
  {
    fips: '12099',
    label: 'Palm Beach',
  },
  {
    fips: '12101',
    label: 'Pasco',
  },
  {
    fips: '12103',
    label: 'Pinellas',
  },
  {
    fips: '12105',
    label: 'Polk',
  },
  {
    fips: '12107',
    label: 'Putnam',
  },
  {
    fips: '12109',
    label: 'St. Johns',
  },
  {
    fips: '12111',
    label: 'St. Lucie',
  },
  {
    fips: '12113',
    label: 'Santa Rosa',
  },
  {
    fips: '12115',
    label: 'Sarasota',
  },
  {
    fips: '12117',
    label: 'Seminole',
  },
  {
    fips: '12119',
    label: 'Sumter',
  },
  {
    fips: '12121',
    label: 'Suwannee',
  },
  {
    fips: '12123',
    label: 'Taylor',
  },
  {
    fips: '12125',
    label: 'Union',
  },
  {
    fips: '12127',
    label: 'Volusia',
  },
  {
    fips: '12129',
    label: 'Wakulla',
  },
  {
    fips: '12131',
    label: 'Walton',
  },
  {
    fips: '12133',
    label: 'Washington',
  },
  {
    fips: '13001',
    label: 'Appling',
  },
  {
    fips: '13003',
    label: 'Atkinson',
  },
  {
    fips: '13005',
    label: 'Bacon',
  },
  {
    fips: '13007',
    label: 'Baker',
  },
  {
    fips: '13009',
    label: 'Baldwin',
  },
  {
    fips: '13011',
    label: 'Banks',
  },
  {
    fips: '13013',
    label: 'Barrow',
  },
  {
    fips: '13015',
    label: 'Bartow',
  },
  {
    fips: '13017',
    label: 'Ben Hill',
  },
  {
    fips: '13019',
    label: 'Berrien',
  },
  {
    fips: '13021',
    label: 'Bibb',
  },
  {
    fips: '13023',
    label: 'Bleckley',
  },
  {
    fips: '13025',
    label: 'Brantley',
  },
  {
    fips: '13027',
    label: 'Brooks',
  },
  {
    fips: '13029',
    label: 'Bryan',
  },
  {
    fips: '13031',
    label: 'Bulloch',
  },
  {
    fips: '13033',
    label: 'Burke',
  },
  {
    fips: '13035',
    label: 'Butts',
  },
  {
    fips: '13037',
    label: 'Calhoun',
  },
  {
    fips: '13039',
    label: 'Camden',
  },
  {
    fips: '13043',
    label: 'Candler',
  },
  {
    fips: '13045',
    label: 'Carroll',
  },
  {
    fips: '13047',
    label: 'Catoosa',
  },
  {
    fips: '13049',
    label: 'Charlton',
  },
  {
    fips: '13051',
    label: 'Chatham',
  },
  {
    fips: '13053',
    label: 'Chattahoochee',
  },
  {
    fips: '13055',
    label: 'Chattooga',
  },
  {
    fips: '13057',
    label: 'Cherokee',
  },
  {
    fips: '13059',
    label: 'Clarke',
  },
  {
    fips: '13061',
    label: 'Clay',
  },
  {
    fips: '13063',
    label: 'Clayton',
  },
  {
    fips: '13065',
    label: 'Clinch',
  },
  {
    fips: '13067',
    label: 'Cobb',
  },
  {
    fips: '13069',
    label: 'Coffee',
  },
  {
    fips: '13071',
    label: 'Colquitt',
  },
  {
    fips: '13073',
    label: 'Columbia',
  },
  {
    fips: '13075',
    label: 'Cook',
  },
  {
    fips: '13077',
    label: 'Coweta',
  },
  {
    fips: '13079',
    label: 'Crawford',
  },
  {
    fips: '13081',
    label: 'Crisp',
  },
  {
    fips: '13083',
    label: 'Dade',
  },
  {
    fips: '13085',
    label: 'Dawson',
  },
  {
    fips: '13087',
    label: 'Decatur',
  },
  {
    fips: '13089',
    label: 'DeKalb',
  },
  {
    fips: '13091',
    label: 'Dodge',
  },
  {
    fips: '13093',
    label: 'Dooly',
  },
  {
    fips: '13095',
    label: 'Dougherty',
  },
  {
    fips: '13097',
    label: 'Douglas',
  },
  {
    fips: '13099',
    label: 'Early',
  },
  {
    fips: '13101',
    label: 'Echols',
  },
  {
    fips: '13103',
    label: 'Effingham',
  },
  {
    fips: '13105',
    label: 'Elbert',
  },
  {
    fips: '13107',
    label: 'Emanuel',
  },
  {
    fips: '13109',
    label: 'Evans',
  },
  {
    fips: '13111',
    label: 'Fannin',
  },
  {
    fips: '13113',
    label: 'Fayette',
  },
  {
    fips: '13115',
    label: 'Floyd',
  },
  {
    fips: '13117',
    label: 'Forsyth',
  },
  {
    fips: '13119',
    label: 'Franklin',
  },
  {
    fips: '13121',
    label: 'Fulton',
  },
  {
    fips: '13123',
    label: 'Gilmer',
  },
  {
    fips: '13125',
    label: 'Glascock',
  },
  {
    fips: '13127',
    label: 'Glynn',
  },
  {
    fips: '13129',
    label: 'Gordon',
  },
  {
    fips: '13131',
    label: 'Grady',
  },
  {
    fips: '13133',
    label: 'Greene',
  },
  {
    fips: '13135',
    label: 'Gwinnett',
  },
  {
    fips: '13137',
    label: 'Habersham',
  },
  {
    fips: '13139',
    label: 'Hall',
  },
  {
    fips: '13141',
    label: 'Hancock',
  },
  {
    fips: '13143',
    label: 'Haralson',
  },
  {
    fips: '13145',
    label: 'Harris',
  },
  {
    fips: '13147',
    label: 'Hart',
  },
  {
    fips: '13149',
    label: 'Heard',
  },
  {
    fips: '13151',
    label: 'Henry',
  },
  {
    fips: '13153',
    label: 'Houston',
  },
  {
    fips: '13155',
    label: 'Irwin',
  },
  {
    fips: '13157',
    label: 'Jackson',
  },
  {
    fips: '13159',
    label: 'Jasper',
  },
  {
    fips: '13161',
    label: 'Jeff Davis',
  },
  {
    fips: '13163',
    label: 'Jefferson',
  },
  {
    fips: '13165',
    label: 'Jenkins',
  },
  {
    fips: '13167',
    label: 'Johnson',
  },
  {
    fips: '13169',
    label: 'Jones',
  },
  {
    fips: '13171',
    label: 'Lamar',
  },
  {
    fips: '13173',
    label: 'Lanier',
  },
  {
    fips: '13175',
    label: 'Laurens',
  },
  {
    fips: '13177',
    label: 'Lee',
  },
  {
    fips: '13179',
    label: 'Liberty',
  },
  {
    fips: '13181',
    label: 'Lincoln',
  },
  {
    fips: '13183',
    label: 'Long',
  },
  {
    fips: '13185',
    label: 'Lowndes',
  },
  {
    fips: '13187',
    label: 'Lumpkin',
  },
  {
    fips: '13189',
    label: 'McDuffie',
  },
  {
    fips: '13191',
    label: 'McIntosh',
  },
  {
    fips: '13193',
    label: 'Macon',
  },
  {
    fips: '13195',
    label: 'Madison',
  },
  {
    fips: '13197',
    label: 'Marion',
  },
  {
    fips: '13199',
    label: 'Meriwether',
  },
  {
    fips: '13201',
    label: 'Miller',
  },
  {
    fips: '13205',
    label: 'Mitchell',
  },
  {
    fips: '13207',
    label: 'Monroe',
  },
  {
    fips: '13209',
    label: 'Montgomery',
  },
  {
    fips: '13211',
    label: 'Morgan',
  },
  {
    fips: '13213',
    label: 'Murray',
  },
  {
    fips: '13215',
    label: 'Muscogee',
  },
  {
    fips: '13217',
    label: 'Newton',
  },
  {
    fips: '13219',
    label: 'Oconee',
  },
  {
    fips: '13221',
    label: 'Oglethorpe',
  },
  {
    fips: '13223',
    label: 'Paulding',
  },
  {
    fips: '13225',
    label: 'Peach',
  },
  {
    fips: '13227',
    label: 'Pickens',
  },
  {
    fips: '13229',
    label: 'Pierce',
  },
  {
    fips: '13231',
    label: 'Pike',
  },
  {
    fips: '13233',
    label: 'Polk',
  },
  {
    fips: '13235',
    label: 'Pulaski',
  },
  {
    fips: '13237',
    label: 'Putnam',
  },
  {
    fips: '13239',
    label: 'Quitman',
  },
  {
    fips: '13241',
    label: 'Rabun',
  },
  {
    fips: '13243',
    label: 'Randolph',
  },
  {
    fips: '13245',
    label: 'Richmond',
  },
  {
    fips: '13247',
    label: 'Rockdale',
  },
  {
    fips: '13249',
    label: 'Schley',
  },
  {
    fips: '13251',
    label: 'Screven',
  },
  {
    fips: '13253',
    label: 'Seminole',
  },
  {
    fips: '13255',
    label: 'Spalding',
  },
  {
    fips: '13257',
    label: 'Stephens',
  },
  {
    fips: '13259',
    label: 'Stewart',
  },
  {
    fips: '13261',
    label: 'Sumter',
  },
  {
    fips: '13263',
    label: 'Talbot',
  },
  {
    fips: '13265',
    label: 'Taliaferro',
  },
  {
    fips: '13267',
    label: 'Tattnall',
  },
  {
    fips: '13269',
    label: 'Taylor',
  },
  {
    fips: '13271',
    label: 'Telfair',
  },
  {
    fips: '13273',
    label: 'Terrell',
  },
  {
    fips: '13275',
    label: 'Thomas',
  },
  {
    fips: '13277',
    label: 'Tift',
  },
  {
    fips: '13279',
    label: 'Toombs',
  },
  {
    fips: '13281',
    label: 'Towns',
  },
  {
    fips: '13283',
    label: 'Treutlen',
  },
  {
    fips: '13285',
    label: 'Troup',
  },
  {
    fips: '13287',
    label: 'Turner',
  },
  {
    fips: '13289',
    label: 'Twiggs',
  },
  {
    fips: '13291',
    label: 'Union',
  },
  {
    fips: '13293',
    label: 'Upson',
  },
  {
    fips: '13295',
    label: 'Walker',
  },
  {
    fips: '13297',
    label: 'Walton',
  },
  {
    fips: '13299',
    label: 'Ware',
  },
  {
    fips: '13301',
    label: 'Warren',
  },
  {
    fips: '13303',
    label: 'Washington',
  },
  {
    fips: '13305',
    label: 'Wayne',
  },
  {
    fips: '13307',
    label: 'Webster',
  },
  {
    fips: '13309',
    label: 'Wheeler',
  },
  {
    fips: '13311',
    label: 'White',
  },
  {
    fips: '13313',
    label: 'Whitfield',
  },
  {
    fips: '13315',
    label: 'Wilcox',
  },
  {
    fips: '13317',
    label: 'Wilkes',
  },
  {
    fips: '13319',
    label: 'Wilkinson',
  },
  {
    fips: '13321',
    label: 'Worth',
  },
  {
    fips: '15001',
    label: 'Hawaii',
  },
  {
    fips: '15003',
    label: 'Honolulu',
  },
  {
    fips: '15005',
    label: 'Kalawao',
  },
  {
    fips: '15007',
    label: 'Kauai',
  },
  {
    fips: '15009',
    label: 'Maui',
  },
  {
    fips: '16001',
    label: 'Ada',
  },
  {
    fips: '16003',
    label: 'Adams',
  },
  {
    fips: '16005',
    label: 'Bannock',
  },
  {
    fips: '16007',
    label: 'Bear Lake',
  },
  {
    fips: '16009',
    label: 'Benewah',
  },
  {
    fips: '16011',
    label: 'Bingham',
  },
  {
    fips: '16013',
    label: 'Blaine',
  },
  {
    fips: '16015',
    label: 'Boise',
  },
  {
    fips: '16017',
    label: 'Bonner',
  },
  {
    fips: '16019',
    label: 'Bonneville',
  },
  {
    fips: '16021',
    label: 'Boundary',
  },
  {
    fips: '16023',
    label: 'Butte',
  },
  {
    fips: '16025',
    label: 'Camas',
  },
  {
    fips: '16027',
    label: 'Canyon',
  },
  {
    fips: '16029',
    label: 'Caribou',
  },
  {
    fips: '16031',
    label: 'Cassia',
  },
  {
    fips: '16033',
    label: 'Clark',
  },
  {
    fips: '16035',
    label: 'Clearwater',
  },
  {
    fips: '16037',
    label: 'Custer',
  },
  {
    fips: '16039',
    label: 'Elmore',
  },
  {
    fips: '16041',
    label: 'Franklin',
  },
  {
    fips: '16043',
    label: 'Fremont',
  },
  {
    fips: '16045',
    label: 'Gem',
  },
  {
    fips: '16047',
    label: 'Gooding',
  },
  {
    fips: '16049',
    label: 'Idaho',
  },
  {
    fips: '16051',
    label: 'Jefferson',
  },
  {
    fips: '16053',
    label: 'Jerome',
  },
  {
    fips: '16055',
    label: 'Kootenai',
  },
  {
    fips: '16057',
    label: 'Latah',
  },
  {
    fips: '16059',
    label: 'Lemhi',
  },
  {
    fips: '16061',
    label: 'Lewis',
  },
  {
    fips: '16063',
    label: 'Lincoln',
  },
  {
    fips: '16065',
    label: 'Madison',
  },
  {
    fips: '16067',
    label: 'Minidoka',
  },
  {
    fips: '16069',
    label: 'Nez Perce',
  },
  {
    fips: '16071',
    label: 'Oneida',
  },
  {
    fips: '16073',
    label: 'Owyhee',
  },
  {
    fips: '16075',
    label: 'Payette',
  },
  {
    fips: '16077',
    label: 'Power',
  },
  {
    fips: '16079',
    label: 'Shoshone',
  },
  {
    fips: '16081',
    label: 'Teton',
  },
  {
    fips: '16083',
    label: 'Twin Falls',
  },
  {
    fips: '16085',
    label: 'Valley',
  },
  {
    fips: '16087',
    label: 'Washington',
  },
  {
    fips: '17001',
    label: 'Adams',
  },
  {
    fips: '17003',
    label: 'Alexander',
  },
  {
    fips: '17005',
    label: 'Bond',
  },
  {
    fips: '17007',
    label: 'Boone',
  },
  {
    fips: '17009',
    label: 'Brown',
  },
  {
    fips: '17011',
    label: 'Bureau',
  },
  {
    fips: '17013',
    label: 'Calhoun',
  },
  {
    fips: '17015',
    label: 'Carroll',
  },
  {
    fips: '17017',
    label: 'Cass',
  },
  {
    fips: '17019',
    label: 'Champaign',
  },
  {
    fips: '17021',
    label: 'Christian',
  },
  {
    fips: '17023',
    label: 'Clark',
  },
  {
    fips: '17025',
    label: 'Clay',
  },
  {
    fips: '17027',
    label: 'Clinton',
  },
  {
    fips: '17029',
    label: 'Coles',
  },
  {
    fips: '17031',
    label: 'Cook',
  },
  {
    fips: '17033',
    label: 'Crawford',
  },
  {
    fips: '17035',
    label: 'Cumberland',
  },
  {
    fips: '17037',
    label: 'DeKalb',
  },
  {
    fips: '17039',
    label: 'De Witt',
  },
  {
    fips: '17041',
    label: 'Douglas',
  },
  {
    fips: '17043',
    label: 'DuPage',
  },
  {
    fips: '17045',
    label: 'Edgar',
  },
  {
    fips: '17047',
    label: 'Edwards',
  },
  {
    fips: '17049',
    label: 'Effingham',
  },
  {
    fips: '17051',
    label: 'Fayette',
  },
  {
    fips: '17053',
    label: 'Ford',
  },
  {
    fips: '17055',
    label: 'Franklin',
  },
  {
    fips: '17057',
    label: 'Fulton',
  },
  {
    fips: '17059',
    label: 'Gallatin',
  },
  {
    fips: '17061',
    label: 'Greene',
  },
  {
    fips: '17063',
    label: 'Grundy',
  },
  {
    fips: '17065',
    label: 'Hamilton',
  },
  {
    fips: '17067',
    label: 'Hancock',
  },
  {
    fips: '17069',
    label: 'Hardin',
  },
  {
    fips: '17071',
    label: 'Henderson',
  },
  {
    fips: '17073',
    label: 'Henry',
  },
  {
    fips: '17075',
    label: 'Iroquois',
  },
  {
    fips: '17077',
    label: 'Jackson',
  },
  {
    fips: '17079',
    label: 'Jasper',
  },
  {
    fips: '17081',
    label: 'Jefferson',
  },
  {
    fips: '17083',
    label: 'Jersey',
  },
  {
    fips: '17085',
    label: 'Jo Daviess',
  },
  {
    fips: '17087',
    label: 'Johnson',
  },
  {
    fips: '17089',
    label: 'Kane',
  },
  {
    fips: '17091',
    label: 'Kankakee',
  },
  {
    fips: '17093',
    label: 'Kendall',
  },
  {
    fips: '17095',
    label: 'Knox',
  },
  {
    fips: '17097',
    label: 'Lake',
  },
  {
    fips: '17099',
    label: 'LaSalle',
  },
  {
    fips: '17101',
    label: 'Lawrence',
  },
  {
    fips: '17103',
    label: 'Lee',
  },
  {
    fips: '17105',
    label: 'Livingston',
  },
  {
    fips: '17107',
    label: 'Logan',
  },
  {
    fips: '17109',
    label: 'McDonough',
  },
  {
    fips: '17111',
    label: 'McHenry',
  },
  {
    fips: '17113',
    label: 'McLean',
  },
  {
    fips: '17115',
    label: 'Macon',
  },
  {
    fips: '17117',
    label: 'Macoupin',
  },
  {
    fips: '17119',
    label: 'Madison',
  },
  {
    fips: '17121',
    label: 'Marion',
  },
  {
    fips: '17123',
    label: 'Marshall',
  },
  {
    fips: '17125',
    label: 'Mason',
  },
  {
    fips: '17127',
    label: 'Massac',
  },
  {
    fips: '17129',
    label: 'Menard',
  },
  {
    fips: '17131',
    label: 'Mercer',
  },
  {
    fips: '17133',
    label: 'Monroe',
  },
  {
    fips: '17135',
    label: 'Montgomery',
  },
  {
    fips: '17137',
    label: 'Morgan',
  },
  {
    fips: '17139',
    label: 'Moultrie',
  },
  {
    fips: '17141',
    label: 'Ogle',
  },
  {
    fips: '17143',
    label: 'Peoria',
  },
  {
    fips: '17145',
    label: 'Perry',
  },
  {
    fips: '17147',
    label: 'Piatt',
  },
  {
    fips: '17149',
    label: 'Pike',
  },
  {
    fips: '17151',
    label: 'Pope',
  },
  {
    fips: '17153',
    label: 'Pulaski',
  },
  {
    fips: '17155',
    label: 'Putnam',
  },
  {
    fips: '17157',
    label: 'Randolph',
  },
  {
    fips: '17159',
    label: 'Richland',
  },
  {
    fips: '17161',
    label: 'Rock Island',
  },
  {
    fips: '17163',
    label: 'St. Clair',
  },
  {
    fips: '17165',
    label: 'Saline',
  },
  {
    fips: '17167',
    label: 'Sangamon',
  },
  {
    fips: '17169',
    label: 'Schuyler',
  },
  {
    fips: '17171',
    label: 'Scott',
  },
  {
    fips: '17173',
    label: 'Shelby',
  },
  {
    fips: '17175',
    label: 'Stark',
  },
  {
    fips: '17177',
    label: 'Stephenson',
  },
  {
    fips: '17179',
    label: 'Tazewell',
  },
  {
    fips: '17181',
    label: 'Union',
  },
  {
    fips: '17183',
    label: 'Vermilion',
  },
  {
    fips: '17185',
    label: 'Wabash',
  },
  {
    fips: '17187',
    label: 'Warren',
  },
  {
    fips: '17189',
    label: 'Washington',
  },
  {
    fips: '17191',
    label: 'Wayne',
  },
  {
    fips: '17193',
    label: 'White',
  },
  {
    fips: '17195',
    label: 'Whiteside',
  },
  {
    fips: '17197',
    label: 'Will',
  },
  {
    fips: '17199',
    label: 'Williamson',
  },
  {
    fips: '17201',
    label: 'Winnebago',
  },
  {
    fips: '17203',
    label: 'Woodford',
  },
  {
    fips: '18001',
    label: 'Adams',
  },
  {
    fips: '18003',
    label: 'Allen',
  },
  {
    fips: '18005',
    label: 'Bartholomew',
  },
  {
    fips: '18007',
    label: 'Benton',
  },
  {
    fips: '18009',
    label: 'Blackford',
  },
  {
    fips: '18011',
    label: 'Boone',
  },
  {
    fips: '18013',
    label: 'Brown',
  },
  {
    fips: '18015',
    label: 'Carroll',
  },
  {
    fips: '18017',
    label: 'Cass',
  },
  {
    fips: '18019',
    label: 'Clark',
  },
  {
    fips: '18021',
    label: 'Clay',
  },
  {
    fips: '18023',
    label: 'Clinton',
  },
  {
    fips: '18025',
    label: 'Crawford',
  },
  {
    fips: '18027',
    label: 'Daviess',
  },
  {
    fips: '18029',
    label: 'Dearborn',
  },
  {
    fips: '18031',
    label: 'Decatur',
  },
  {
    fips: '18033',
    label: 'DeKalb',
  },
  {
    fips: '18035',
    label: 'Delaware',
  },
  {
    fips: '18037',
    label: 'Dubois',
  },
  {
    fips: '18039',
    label: 'Elkhart',
  },
  {
    fips: '18041',
    label: 'Fayette',
  },
  {
    fips: '18043',
    label: 'Floyd',
  },
  {
    fips: '18045',
    label: 'Fountain',
  },
  {
    fips: '18047',
    label: 'Franklin',
  },
  {
    fips: '18049',
    label: 'Fulton',
  },
  {
    fips: '18051',
    label: 'Gibson',
  },
  {
    fips: '18053',
    label: 'Grant',
  },
  {
    fips: '18055',
    label: 'Greene',
  },
  {
    fips: '18057',
    label: 'Hamilton',
  },
  {
    fips: '18059',
    label: 'Hancock',
  },
  {
    fips: '18061',
    label: 'Harrison',
  },
  {
    fips: '18063',
    label: 'Hendricks',
  },
  {
    fips: '18065',
    label: 'Henry',
  },
  {
    fips: '18067',
    label: 'Howard',
  },
  {
    fips: '18069',
    label: 'Huntington',
  },
  {
    fips: '18071',
    label: 'Jackson',
  },
  {
    fips: '18073',
    label: 'Jasper',
  },
  {
    fips: '18075',
    label: 'Jay',
  },
  {
    fips: '18077',
    label: 'Jefferson',
  },
  {
    fips: '18079',
    label: 'Jennings',
  },
  {
    fips: '18081',
    label: 'Johnson',
  },
  {
    fips: '18083',
    label: 'Knox',
  },
  {
    fips: '18085',
    label: 'Kosciusko',
  },
  {
    fips: '18087',
    label: 'LaGrange',
  },
  {
    fips: '18089',
    label: 'Lake',
  },
  {
    fips: '18091',
    label: 'LaPorte',
  },
  {
    fips: '18093',
    label: 'Lawrence',
  },
  {
    fips: '18095',
    label: 'Madison',
  },
  {
    fips: '18097',
    label: 'Marion',
  },
  {
    fips: '18099',
    label: 'Marshall',
  },
  {
    fips: '18101',
    label: 'Martin',
  },
  {
    fips: '18103',
    label: 'Miami',
  },
  {
    fips: '18105',
    label: 'Monroe',
  },
  {
    fips: '18107',
    label: 'Montgomery',
  },
  {
    fips: '18109',
    label: 'Morgan',
  },
  {
    fips: '18111',
    label: 'Newton',
  },
  {
    fips: '18113',
    label: 'Noble',
  },
  {
    fips: '18115',
    label: 'Ohio',
  },
  {
    fips: '18117',
    label: 'Orange',
  },
  {
    fips: '18119',
    label: 'Owen',
  },
  {
    fips: '18121',
    label: 'Parke',
  },
  {
    fips: '18123',
    label: 'Perry',
  },
  {
    fips: '18125',
    label: 'Pike',
  },
  {
    fips: '18127',
    label: 'Porter',
  },
  {
    fips: '18129',
    label: 'Posey',
  },
  {
    fips: '18131',
    label: 'Pulaski',
  },
  {
    fips: '18133',
    label: 'Putnam',
  },
  {
    fips: '18135',
    label: 'Randolph',
  },
  {
    fips: '18137',
    label: 'Ripley',
  },
  {
    fips: '18139',
    label: 'Rush',
  },
  {
    fips: '18141',
    label: 'St. Joseph',
  },
  {
    fips: '18143',
    label: 'Scott',
  },
  {
    fips: '18145',
    label: 'Shelby',
  },
  {
    fips: '18147',
    label: 'Spencer',
  },
  {
    fips: '18149',
    label: 'Starke',
  },
  {
    fips: '18151',
    label: 'Steuben',
  },
  {
    fips: '18153',
    label: 'Sullivan',
  },
  {
    fips: '18155',
    label: 'Switzerland',
  },
  {
    fips: '18157',
    label: 'Tippecanoe',
  },
  {
    fips: '18159',
    label: 'Tipton',
  },
  {
    fips: '18161',
    label: 'Union',
  },
  {
    fips: '18163',
    label: 'Vanderburgh',
  },
  {
    fips: '18165',
    label: 'Vermillion',
  },
  {
    fips: '18167',
    label: 'Vigo',
  },
  {
    fips: '18169',
    label: 'Wabash',
  },
  {
    fips: '18171',
    label: 'Warren',
  },
  {
    fips: '18173',
    label: 'Warrick',
  },
  {
    fips: '18175',
    label: 'Washington',
  },
  {
    fips: '18177',
    label: 'Wayne',
  },
  {
    fips: '18179',
    label: 'Wells',
  },
  {
    fips: '18181',
    label: 'White',
  },
  {
    fips: '18183',
    label: 'Whitley',
  },
  {
    fips: '19001',
    label: 'Adair',
  },
  {
    fips: '19003',
    label: 'Adams',
  },
  {
    fips: '19005',
    label: 'Allamakee',
  },
  {
    fips: '19007',
    label: 'Appanoose',
  },
  {
    fips: '19009',
    label: 'Audubon',
  },
  {
    fips: '19011',
    label: 'Benton',
  },
  {
    fips: '19013',
    label: 'Black Hawk',
  },
  {
    fips: '19015',
    label: 'Boone',
  },
  {
    fips: '19017',
    label: 'Bremer',
  },
  {
    fips: '19019',
    label: 'Buchanan',
  },
  {
    fips: '19021',
    label: 'Buena Vista',
  },
  {
    fips: '19023',
    label: 'Butler',
  },
  {
    fips: '19025',
    label: 'Calhoun',
  },
  {
    fips: '19027',
    label: 'Carroll',
  },
  {
    fips: '19029',
    label: 'Cass',
  },
  {
    fips: '19031',
    label: 'Cedar',
  },
  {
    fips: '19033',
    label: 'Cerro Gordo',
  },
  {
    fips: '19035',
    label: 'Cherokee',
  },
  {
    fips: '19037',
    label: 'Chickasaw',
  },
  {
    fips: '19039',
    label: 'Clarke',
  },
  {
    fips: '19041',
    label: 'Clay',
  },
  {
    fips: '19043',
    label: 'Clayton',
  },
  {
    fips: '19045',
    label: 'Clinton',
  },
  {
    fips: '19047',
    label: 'Crawford',
  },
  {
    fips: '19049',
    label: 'Dallas',
  },
  {
    fips: '19051',
    label: 'Davis',
  },
  {
    fips: '19053',
    label: 'Decatur',
  },
  {
    fips: '19055',
    label: 'Delaware',
  },
  {
    fips: '19057',
    label: 'Des Moines',
  },
  {
    fips: '19059',
    label: 'Dickinson',
  },
  {
    fips: '19061',
    label: 'Dubuque',
  },
  {
    fips: '19063',
    label: 'Emmet',
  },
  {
    fips: '19065',
    label: 'Fayette',
  },
  {
    fips: '19067',
    label: 'Floyd',
  },
  {
    fips: '19069',
    label: 'Franklin',
  },
  {
    fips: '19071',
    label: 'Fremont',
  },
  {
    fips: '19073',
    label: 'Greene',
  },
  {
    fips: '19075',
    label: 'Grundy',
  },
  {
    fips: '19077',
    label: 'Guthrie',
  },
  {
    fips: '19079',
    label: 'Hamilton',
  },
  {
    fips: '19081',
    label: 'Hancock',
  },
  {
    fips: '19083',
    label: 'Hardin',
  },
  {
    fips: '19085',
    label: 'Harrison',
  },
  {
    fips: '19087',
    label: 'Henry',
  },
  {
    fips: '19089',
    label: 'Howard',
  },
  {
    fips: '19091',
    label: 'Humboldt',
  },
  {
    fips: '19093',
    label: 'Ida',
  },
  {
    fips: '19095',
    label: 'Iowa',
  },
  {
    fips: '19097',
    label: 'Jackson',
  },
  {
    fips: '19099',
    label: 'Jasper',
  },
  {
    fips: '19101',
    label: 'Jefferson',
  },
  {
    fips: '19103',
    label: 'Johnson',
  },
  {
    fips: '19105',
    label: 'Jones',
  },
  {
    fips: '19107',
    label: 'Keokuk',
  },
  {
    fips: '19109',
    label: 'Kossuth',
  },
  {
    fips: '19111',
    label: 'Lee',
  },
  {
    fips: '19113',
    label: 'Linn',
  },
  {
    fips: '19115',
    label: 'Louisa',
  },
  {
    fips: '19117',
    label: 'Lucas',
  },
  {
    fips: '19119',
    label: 'Lyon',
  },
  {
    fips: '19121',
    label: 'Madison',
  },
  {
    fips: '19123',
    label: 'Mahaska',
  },
  {
    fips: '19125',
    label: 'Marion',
  },
  {
    fips: '19127',
    label: 'Marshall',
  },
  {
    fips: '19129',
    label: 'Mills',
  },
  {
    fips: '19131',
    label: 'Mitchell',
  },
  {
    fips: '19133',
    label: 'Monona',
  },
  {
    fips: '19135',
    label: 'Monroe',
  },
  {
    fips: '19137',
    label: 'Montgomery',
  },
  {
    fips: '19139',
    label: 'Muscatine',
  },
  {
    fips: '19141',
    label: "O'Brien",
  },
  {
    fips: '19143',
    label: 'Osceola',
  },
  {
    fips: '19145',
    label: 'Page',
  },
  {
    fips: '19147',
    label: 'Palo Alto',
  },
  {
    fips: '19149',
    label: 'Plymouth',
  },
  {
    fips: '19151',
    label: 'Pocahontas',
  },
  {
    fips: '19153',
    label: 'Polk',
  },
  {
    fips: '19155',
    label: 'Pottawattamie',
  },
  {
    fips: '19157',
    label: 'Poweshiek',
  },
  {
    fips: '19159',
    label: 'Ringgold',
  },
  {
    fips: '19161',
    label: 'Sac',
  },
  {
    fips: '19163',
    label: 'Scott',
  },
  {
    fips: '19165',
    label: 'Shelby',
  },
  {
    fips: '19167',
    label: 'Sioux',
  },
  {
    fips: '19169',
    label: 'Story',
  },
  {
    fips: '19171',
    label: 'Tama',
  },
  {
    fips: '19173',
    label: 'Taylor',
  },
  {
    fips: '19175',
    label: 'Union',
  },
  {
    fips: '19177',
    label: 'Van Buren',
  },
  {
    fips: '19179',
    label: 'Wapello',
  },
  {
    fips: '19181',
    label: 'Warren',
  },
  {
    fips: '19183',
    label: 'Washington',
  },
  {
    fips: '19185',
    label: 'Wayne',
  },
  {
    fips: '19187',
    label: 'Webster',
  },
  {
    fips: '19189',
    label: 'Winnebago',
  },
  {
    fips: '19191',
    label: 'Winneshiek',
  },
  {
    fips: '19193',
    label: 'Woodbury',
  },
  {
    fips: '19195',
    label: 'Worth',
  },
  {
    fips: '19197',
    label: 'Wright',
  },
  {
    fips: '20001',
    label: 'Allen',
  },
  {
    fips: '20003',
    label: 'Anderson',
  },
  {
    fips: '20005',
    label: 'Atchison',
  },
  {
    fips: '20007',
    label: 'Barber',
  },
  {
    fips: '20009',
    label: 'Barton',
  },
  {
    fips: '20011',
    label: 'Bourbon',
  },
  {
    fips: '20013',
    label: 'Brown',
  },
  {
    fips: '20015',
    label: 'Butler',
  },
  {
    fips: '20017',
    label: 'Chase',
  },
  {
    fips: '20019',
    label: 'Chautauqua',
  },
  {
    fips: '20021',
    label: 'Cherokee',
  },
  {
    fips: '20023',
    label: 'Cheyenne',
  },
  {
    fips: '20025',
    label: 'Clark',
  },
  {
    fips: '20027',
    label: 'Clay',
  },
  {
    fips: '20029',
    label: 'Cloud',
  },
  {
    fips: '20031',
    label: 'Coffey',
  },
  {
    fips: '20033',
    label: 'Comanche',
  },
  {
    fips: '20035',
    label: 'Cowley',
  },
  {
    fips: '20037',
    label: 'Crawford',
  },
  {
    fips: '20039',
    label: 'Decatur',
  },
  {
    fips: '20041',
    label: 'Dickinson',
  },
  {
    fips: '20043',
    label: 'Doniphan',
  },
  {
    fips: '20045',
    label: 'Douglas',
  },
  {
    fips: '20047',
    label: 'Edwards',
  },
  {
    fips: '20049',
    label: 'Elk',
  },
  {
    fips: '20051',
    label: 'Ellis',
  },
  {
    fips: '20053',
    label: 'Ellsworth',
  },
  {
    fips: '20055',
    label: 'Finney',
  },
  {
    fips: '20057',
    label: 'Ford',
  },
  {
    fips: '20059',
    label: 'Franklin',
  },
  {
    fips: '20061',
    label: 'Geary',
  },
  {
    fips: '20063',
    label: 'Gove',
  },
  {
    fips: '20065',
    label: 'Graham',
  },
  {
    fips: '20067',
    label: 'Grant',
  },
  {
    fips: '20069',
    label: 'Gray',
  },
  {
    fips: '20071',
    label: 'Greeley',
  },
  {
    fips: '20073',
    label: 'Greenwood',
  },
  {
    fips: '20075',
    label: 'Hamilton',
  },
  {
    fips: '20077',
    label: 'Harper',
  },
  {
    fips: '20079',
    label: 'Harvey',
  },
  {
    fips: '20081',
    label: 'Haskell',
  },
  {
    fips: '20083',
    label: 'Hodgeman',
  },
  {
    fips: '20085',
    label: 'Jackson',
  },
  {
    fips: '20087',
    label: 'Jefferson',
  },
  {
    fips: '20089',
    label: 'Jewell',
  },
  {
    fips: '20091',
    label: 'Johnson',
  },
  {
    fips: '20093',
    label: 'Kearny',
  },
  {
    fips: '20095',
    label: 'Kingman',
  },
  {
    fips: '20097',
    label: 'Kiowa',
  },
  {
    fips: '20099',
    label: 'Labette',
  },
  {
    fips: '20101',
    label: 'Lane',
  },
  {
    fips: '20103',
    label: 'Leavenworth',
  },
  {
    fips: '20105',
    label: 'Lincoln',
  },
  {
    fips: '20107',
    label: 'Linn',
  },
  {
    fips: '20109',
    label: 'Logan',
  },
  {
    fips: '20111',
    label: 'Lyon',
  },
  {
    fips: '20113',
    label: 'McPherson',
  },
  {
    fips: '20115',
    label: 'Marion',
  },
  {
    fips: '20117',
    label: 'Marshall',
  },
  {
    fips: '20119',
    label: 'Meade',
  },
  {
    fips: '20121',
    label: 'Miami',
  },
  {
    fips: '20123',
    label: 'Mitchell',
  },
  {
    fips: '20125',
    label: 'Montgomery',
  },
  {
    fips: '20127',
    label: 'Morris',
  },
  {
    fips: '20129',
    label: 'Morton',
  },
  {
    fips: '20131',
    label: 'Nemaha',
  },
  {
    fips: '20133',
    label: 'Neosho',
  },
  {
    fips: '20135',
    label: 'Ness',
  },
  {
    fips: '20137',
    label: 'Norton',
  },
  {
    fips: '20139',
    label: 'Osage',
  },
  {
    fips: '20141',
    label: 'Osborne',
  },
  {
    fips: '20143',
    label: 'Ottawa',
  },
  {
    fips: '20145',
    label: 'Pawnee',
  },
  {
    fips: '20147',
    label: 'Phillips',
  },
  {
    fips: '20149',
    label: 'Pottawatomie',
  },
  {
    fips: '20151',
    label: 'Pratt',
  },
  {
    fips: '20153',
    label: 'Rawlins',
  },
  {
    fips: '20155',
    label: 'Reno',
  },
  {
    fips: '20157',
    label: 'Republic',
  },
  {
    fips: '20159',
    label: 'Rice',
  },
  {
    fips: '20161',
    label: 'Riley',
  },
  {
    fips: '20163',
    label: 'Rooks',
  },
  {
    fips: '20165',
    label: 'Rush',
  },
  {
    fips: '20167',
    label: 'Russell',
  },
  {
    fips: '20169',
    label: 'Saline',
  },
  {
    fips: '20171',
    label: 'Scott',
  },
  {
    fips: '20173',
    label: 'Sedgwick',
  },
  {
    fips: '20175',
    label: 'Seward',
  },
  {
    fips: '20177',
    label: 'Shawnee',
  },
  {
    fips: '20179',
    label: 'Sheridan',
  },
  {
    fips: '20181',
    label: 'Sherman',
  },
  {
    fips: '20183',
    label: 'Smith',
  },
  {
    fips: '20185',
    label: 'Stafford',
  },
  {
    fips: '20187',
    label: 'Stanton',
  },
  {
    fips: '20189',
    label: 'Stevens',
  },
  {
    fips: '20191',
    label: 'Sumner',
  },
  {
    fips: '20193',
    label: 'Thomas',
  },
  {
    fips: '20195',
    label: 'Trego',
  },
  {
    fips: '20197',
    label: 'Wabaunsee',
  },
  {
    fips: '20199',
    label: 'Wallace',
  },
  {
    fips: '20201',
    label: 'Washington',
  },
  {
    fips: '20203',
    label: 'Wichita',
  },
  {
    fips: '20205',
    label: 'Wilson',
  },
  {
    fips: '20207',
    label: 'Woodson',
  },
  {
    fips: '20209',
    label: 'Wyandotte',
  },
  {
    fips: '21001',
    label: 'Adair',
  },
  {
    fips: '21003',
    label: 'Allen',
  },
  {
    fips: '21005',
    label: 'Anderson',
  },
  {
    fips: '21007',
    label: 'Ballard',
  },
  {
    fips: '21009',
    label: 'Barren',
  },
  {
    fips: '21011',
    label: 'Bath',
  },
  {
    fips: '21013',
    label: 'Bell',
  },
  {
    fips: '21015',
    label: 'Boone',
  },
  {
    fips: '21017',
    label: 'Bourbon',
  },
  {
    fips: '21019',
    label: 'Boyd',
  },
  {
    fips: '21021',
    label: 'Boyle',
  },
  {
    fips: '21023',
    label: 'Bracken',
  },
  {
    fips: '21025',
    label: 'Breathitt',
  },
  {
    fips: '21027',
    label: 'Breckinridge',
  },
  {
    fips: '21029',
    label: 'Bullitt',
  },
  {
    fips: '21031',
    label: 'Butler',
  },
  {
    fips: '21033',
    label: 'Caldwell',
  },
  {
    fips: '21035',
    label: 'Calloway',
  },
  {
    fips: '21037',
    label: 'Campbell',
  },
  {
    fips: '21039',
    label: 'Carlisle',
  },
  {
    fips: '21041',
    label: 'Carroll',
  },
  {
    fips: '21043',
    label: 'Carter',
  },
  {
    fips: '21045',
    label: 'Casey',
  },
  {
    fips: '21047',
    label: 'Christian',
  },
  {
    fips: '21049',
    label: 'Clark',
  },
  {
    fips: '21051',
    label: 'Clay',
  },
  {
    fips: '21053',
    label: 'Clinton',
  },
  {
    fips: '21055',
    label: 'Crittenden',
  },
  {
    fips: '21057',
    label: 'Cumberland',
  },
  {
    fips: '21059',
    label: 'Daviess',
  },
  {
    fips: '21061',
    label: 'Edmonson',
  },
  {
    fips: '21063',
    label: 'Elliott',
  },
  {
    fips: '21065',
    label: 'Estill',
  },
  {
    fips: '21067',
    label: 'Fayette',
  },
  {
    fips: '21069',
    label: 'Fleming',
  },
  {
    fips: '21071',
    label: 'Floyd',
  },
  {
    fips: '21073',
    label: 'Franklin',
  },
  {
    fips: '21075',
    label: 'Fulton',
  },
  {
    fips: '21077',
    label: 'Gallatin',
  },
  {
    fips: '21079',
    label: 'Garrard',
  },
  {
    fips: '21081',
    label: 'Grant',
  },
  {
    fips: '21083',
    label: 'Graves',
  },
  {
    fips: '21085',
    label: 'Grayson',
  },
  {
    fips: '21087',
    label: 'Green',
  },
  {
    fips: '21089',
    label: 'Greenup',
  },
  {
    fips: '21091',
    label: 'Hancock',
  },
  {
    fips: '21093',
    label: 'Hardin',
  },
  {
    fips: '21095',
    label: 'Harlan',
  },
  {
    fips: '21097',
    label: 'Harrison',
  },
  {
    fips: '21099',
    label: 'Hart',
  },
  {
    fips: '21101',
    label: 'Henderson',
  },
  {
    fips: '21103',
    label: 'Henry',
  },
  {
    fips: '21105',
    label: 'Hickman',
  },
  {
    fips: '21107',
    label: 'Hopkins',
  },
  {
    fips: '21109',
    label: 'Jackson',
  },
  {
    fips: '21111',
    label: 'Jefferson',
  },
  {
    fips: '21113',
    label: 'Jessamine',
  },
  {
    fips: '21115',
    label: 'Johnson',
  },
  {
    fips: '21117',
    label: 'Kenton',
  },
  {
    fips: '21119',
    label: 'Knott',
  },
  {
    fips: '21121',
    label: 'Knox',
  },
  {
    fips: '21123',
    label: 'Larue',
  },
  {
    fips: '21125',
    label: 'Laurel',
  },
  {
    fips: '21127',
    label: 'Lawrence',
  },
  {
    fips: '21129',
    label: 'Lee',
  },
  {
    fips: '21131',
    label: 'Leslie',
  },
  {
    fips: '21133',
    label: 'Letcher',
  },
  {
    fips: '21135',
    label: 'Lewis',
  },
  {
    fips: '21137',
    label: 'Lincoln',
  },
  {
    fips: '21139',
    label: 'Livingston',
  },
  {
    fips: '21141',
    label: 'Logan',
  },
  {
    fips: '21143',
    label: 'Lyon',
  },
  {
    fips: '21145',
    label: 'McCracken',
  },
  {
    fips: '21147',
    label: 'McCreary',
  },
  {
    fips: '21149',
    label: 'McLean',
  },
  {
    fips: '21151',
    label: 'Madison',
  },
  {
    fips: '21153',
    label: 'Magoffin',
  },
  {
    fips: '21155',
    label: 'Marion',
  },
  {
    fips: '21157',
    label: 'Marshall',
  },
  {
    fips: '21159',
    label: 'Martin',
  },
  {
    fips: '21161',
    label: 'Mason',
  },
  {
    fips: '21163',
    label: 'Meade',
  },
  {
    fips: '21165',
    label: 'Menifee',
  },
  {
    fips: '21167',
    label: 'Mercer',
  },
  {
    fips: '21169',
    label: 'Metcalfe',
  },
  {
    fips: '21171',
    label: 'Monroe',
  },
  {
    fips: '21173',
    label: 'Montgomery',
  },
  {
    fips: '21175',
    label: 'Morgan',
  },
  {
    fips: '21177',
    label: 'Muhlenberg',
  },
  {
    fips: '21179',
    label: 'Nelson',
  },
  {
    fips: '21181',
    label: 'Nicholas',
  },
  {
    fips: '21183',
    label: 'Ohio',
  },
  {
    fips: '21185',
    label: 'Oldham',
  },
  {
    fips: '21187',
    label: 'Owen',
  },
  {
    fips: '21189',
    label: 'Owsley',
  },
  {
    fips: '21191',
    label: 'Pendleton',
  },
  {
    fips: '21193',
    label: 'Perry',
  },
  {
    fips: '21195',
    label: 'Pike',
  },
  {
    fips: '21197',
    label: 'Powell',
  },
  {
    fips: '21199',
    label: 'Pulaski',
  },
  {
    fips: '21201',
    label: 'Robertson',
  },
  {
    fips: '21203',
    label: 'Rockcastle',
  },
  {
    fips: '21205',
    label: 'Rowan',
  },
  {
    fips: '21207',
    label: 'Russell',
  },
  {
    fips: '21209',
    label: 'Scott',
  },
  {
    fips: '21211',
    label: 'Shelby',
  },
  {
    fips: '21213',
    label: 'Simpson',
  },
  {
    fips: '21215',
    label: 'Spencer',
  },
  {
    fips: '21217',
    label: 'Taylor',
  },
  {
    fips: '21219',
    label: 'Todd',
  },
  {
    fips: '21221',
    label: 'Trigg',
  },
  {
    fips: '21223',
    label: 'Trimble',
  },
  {
    fips: '21225',
    label: 'Union',
  },
  {
    fips: '21227',
    label: 'Warren',
  },
  {
    fips: '21229',
    label: 'Washington',
  },
  {
    fips: '21231',
    label: 'Wayne',
  },
  {
    fips: '21233',
    label: 'Webster',
  },
  {
    fips: '21235',
    label: 'Whitley',
  },
  {
    fips: '21237',
    label: 'Wolfe',
  },
  {
    fips: '21239',
    label: 'Woodford',
  },
  {
    fips: '22001',
    label: 'Acadia',
  },
  {
    fips: '22003',
    label: 'Allen',
  },
  {
    fips: '22005',
    label: 'Ascension',
  },
  {
    fips: '22007',
    label: 'Assumption',
  },
  {
    fips: '22009',
    label: 'Avoyelles',
  },
  {
    fips: '22011',
    label: 'Beauregard',
  },
  {
    fips: '22013',
    label: 'Bienville',
  },
  {
    fips: '22015',
    label: 'Bossier',
  },
  {
    fips: '22017',
    label: 'Caddo',
  },
  {
    fips: '22019',
    label: 'Calcasieu',
  },
  {
    fips: '22021',
    label: 'Caldwell',
  },
  {
    fips: '22023',
    label: 'Cameron',
  },
  {
    fips: '22025',
    label: 'Catahoula',
  },
  {
    fips: '22027',
    label: 'Claiborne',
  },
  {
    fips: '22029',
    label: 'Concordia',
  },
  {
    fips: '22031',
    label: 'De Soto',
  },
  {
    fips: '22033',
    label: 'East Baton Rouge',
  },
  {
    fips: '22035',
    label: 'East Carroll',
  },
  {
    fips: '22037',
    label: 'East Feliciana',
  },
  {
    fips: '22039',
    label: 'Evangeline',
  },
  {
    fips: '22041',
    label: 'Franklin',
  },
  {
    fips: '22043',
    label: 'Grant',
  },
  {
    fips: '22045',
    label: 'Iberia',
  },
  {
    fips: '22047',
    label: 'Iberville',
  },
  {
    fips: '22049',
    label: 'Jackson',
  },
  {
    fips: '22051',
    label: 'Jefferson',
  },
  {
    fips: '22053',
    label: 'Jefferson Davis',
  },
  {
    fips: '22055',
    label: 'Lafayette',
  },
  {
    fips: '22057',
    label: 'Lafourche',
  },
  {
    fips: '22059',
    label: 'LaSalle',
  },
  {
    fips: '22061',
    label: 'Lincoln',
  },
  {
    fips: '22063',
    label: 'Livingston',
  },
  {
    fips: '22065',
    label: 'Madison',
  },
  {
    fips: '22067',
    label: 'Morehouse',
  },
  {
    fips: '22069',
    label: 'Natchitoches',
  },
  {
    fips: '22071',
    label: 'Orleans',
  },
  {
    fips: '22073',
    label: 'Ouachita',
  },
  {
    fips: '22075',
    label: 'Plaquemines',
  },
  {
    fips: '22077',
    label: 'Pointe Coupee',
  },
  {
    fips: '22079',
    label: 'Rapides',
  },
  {
    fips: '22081',
    label: 'Red River',
  },
  {
    fips: '22083',
    label: 'Richland',
  },
  {
    fips: '22085',
    label: 'Sabine',
  },
  {
    fips: '22087',
    label: 'St. Bernard',
  },
  {
    fips: '22089',
    label: 'St. Charles',
  },
  {
    fips: '22091',
    label: 'St. Helena',
  },
  {
    fips: '22093',
    label: 'St. James',
  },
  {
    fips: '22095',
    label: 'St. John the Baptist',
  },
  {
    fips: '22097',
    label: 'St. Landry',
  },
  {
    fips: '22099',
    label: 'St. Martin',
  },
  {
    fips: '22101',
    label: 'St. Mary',
  },
  {
    fips: '22103',
    label: 'St. Tammany',
  },
  {
    fips: '22105',
    label: 'Tangipahoa',
  },
  {
    fips: '22107',
    label: 'Tensas',
  },
  {
    fips: '22109',
    label: 'Terrebonne',
  },
  {
    fips: '22111',
    label: 'Union',
  },
  {
    fips: '22113',
    label: 'Vermilion',
  },
  {
    fips: '22115',
    label: 'Vernon',
  },
  {
    fips: '22117',
    label: 'Washington',
  },
  {
    fips: '22119',
    label: 'Webster',
  },
  {
    fips: '22121',
    label: 'West Baton Rouge',
  },
  {
    fips: '22123',
    label: 'West Carroll',
  },
  {
    fips: '22125',
    label: 'West Feliciana',
  },
  {
    fips: '22127',
    label: 'Winn',
  },
  {
    fips: '23001',
    label: 'Androscoggin',
  },
  {
    fips: '23003',
    label: 'Aroostook',
  },
  {
    fips: '23005',
    label: 'Cumberland',
  },
  {
    fips: '23007',
    label: 'Franklin',
  },
  {
    fips: '23009',
    label: 'Hancock',
  },
  {
    fips: '23011',
    label: 'Kennebec',
  },
  {
    fips: '23013',
    label: 'Knox',
  },
  {
    fips: '23015',
    label: 'Lincoln',
  },
  {
    fips: '23017',
    label: 'Oxford',
  },
  {
    fips: '23019',
    label: 'Penobscot',
  },
  {
    fips: '23021',
    label: 'Piscataquis',
  },
  {
    fips: '23023',
    label: 'Sagadahoc',
  },
  {
    fips: '23025',
    label: 'Somerset',
  },
  {
    fips: '23027',
    label: 'Waldo',
  },
  {
    fips: '23029',
    label: 'Washington',
  },
  {
    fips: '23031',
    label: 'York',
  },
  {
    fips: '24001',
    label: 'Allegany',
  },
  {
    fips: '24003',
    label: 'Anne Arundel',
  },
  {
    fips: '24005',
    label: 'Baltimore',
  },
  {
    fips: '24009',
    label: 'Calvert',
  },
  {
    fips: '24011',
    label: 'Caroline',
  },
  {
    fips: '24013',
    label: 'Carroll',
  },
  {
    fips: '24015',
    label: 'Cecil',
  },
  {
    fips: '24017',
    label: 'Charles',
  },
  {
    fips: '24019',
    label: 'Dorchester',
  },
  {
    fips: '24021',
    label: 'Frederick',
  },
  {
    fips: '24023',
    label: 'Garrett',
  },
  {
    fips: '24025',
    label: 'Harford',
  },
  {
    fips: '24027',
    label: 'Howard',
  },
  {
    fips: '24029',
    label: 'Kent',
  },
  {
    fips: '24031',
    label: 'Montgomery',
  },
  {
    fips: '24033',
    label: "Prince George's",
  },
  {
    fips: '24035',
    label: "Queen Anne's",
  },
  {
    fips: '24037',
    label: "St. Mary's",
  },
  {
    fips: '24039',
    label: 'Somerset',
  },
  {
    fips: '24041',
    label: 'Talbot',
  },
  {
    fips: '24043',
    label: 'Washington',
  },
  {
    fips: '24045',
    label: 'Wicomico',
  },
  {
    fips: '24047',
    label: 'Worcester',
  },
  {
    fips: '24510',
    label: 'Baltimore city',
  },
  {
    fips: '25001',
    label: 'Barnstable',
  },
  {
    fips: '25003',
    label: 'Berkshire',
  },
  {
    fips: '25005',
    label: 'Bristol',
  },
  {
    fips: '25007',
    label: 'Dukes',
  },
  {
    fips: '25009',
    label: 'Essex',
  },
  {
    fips: '25011',
    label: 'Franklin',
  },
  {
    fips: '25013',
    label: 'Hampden',
  },
  {
    fips: '25015',
    label: 'Hampshire',
  },
  {
    fips: '25017',
    label: 'Middlesex',
  },
  {
    fips: '25019',
    label: 'Nantucket',
  },
  {
    fips: '25021',
    label: 'Norfolk',
  },
  {
    fips: '25023',
    label: 'Plymouth',
  },
  {
    fips: '25025',
    label: 'Suffolk',
  },
  {
    fips: '25027',
    label: 'Worcester',
  },
  {
    fips: '26001',
    label: 'Alcona',
  },
  {
    fips: '26003',
    label: 'Alger',
  },
  {
    fips: '26005',
    label: 'Allegan',
  },
  {
    fips: '26007',
    label: 'Alpena',
  },
  {
    fips: '26009',
    label: 'Antrim',
  },
  {
    fips: '26011',
    label: 'Arenac',
  },
  {
    fips: '26013',
    label: 'Baraga',
  },
  {
    fips: '26015',
    label: 'Barry',
  },
  {
    fips: '26017',
    label: 'Bay',
  },
  {
    fips: '26019',
    label: 'Benzie',
  },
  {
    fips: '26021',
    label: 'Berrien',
  },
  {
    fips: '26023',
    label: 'Branch',
  },
  {
    fips: '26025',
    label: 'Calhoun',
  },
  {
    fips: '26027',
    label: 'Cass',
  },
  {
    fips: '26029',
    label: 'Charlevoix',
  },
  {
    fips: '26031',
    label: 'Cheboygan',
  },
  {
    fips: '26033',
    label: 'Chippewa',
  },
  {
    fips: '26035',
    label: 'Clare',
  },
  {
    fips: '26037',
    label: 'Clinton',
  },
  {
    fips: '26039',
    label: 'Crawford',
  },
  {
    fips: '26041',
    label: 'Delta',
  },
  {
    fips: '26043',
    label: 'Dickinson',
  },
  {
    fips: '26045',
    label: 'Eaton',
  },
  {
    fips: '26047',
    label: 'Emmet',
  },
  {
    fips: '26049',
    label: 'Genesee',
  },
  {
    fips: '26051',
    label: 'Gladwin',
  },
  {
    fips: '26053',
    label: 'Gogebic',
  },
  {
    fips: '26055',
    label: 'Grand Traverse',
  },
  {
    fips: '26057',
    label: 'Gratiot',
  },
  {
    fips: '26059',
    label: 'Hillsdale',
  },
  {
    fips: '26061',
    label: 'Houghton',
  },
  {
    fips: '26063',
    label: 'Huron',
  },
  {
    fips: '26065',
    label: 'Ingham',
  },
  {
    fips: '26067',
    label: 'Ionia',
  },
  {
    fips: '26069',
    label: 'Iosco',
  },
  {
    fips: '26071',
    label: 'Iron',
  },
  {
    fips: '26073',
    label: 'Isabella',
  },
  {
    fips: '26075',
    label: 'Jackson',
  },
  {
    fips: '26077',
    label: 'Kalamazoo',
  },
  {
    fips: '26079',
    label: 'Kalkaska',
  },
  {
    fips: '26081',
    label: 'Kent',
  },
  {
    fips: '26083',
    label: 'Keweenaw',
  },
  {
    fips: '26085',
    label: 'Lake',
  },
  {
    fips: '26087',
    label: 'Lapeer',
  },
  {
    fips: '26089',
    label: 'Leelanau',
  },
  {
    fips: '26091',
    label: 'Lenawee',
  },
  {
    fips: '26093',
    label: 'Livingston',
  },
  {
    fips: '26095',
    label: 'Luce',
  },
  {
    fips: '26097',
    label: 'Mackinac',
  },
  {
    fips: '26099',
    label: 'Macomb',
  },
  {
    fips: '26101',
    label: 'Manistee',
  },
  {
    fips: '26103',
    label: 'Marquette',
  },
  {
    fips: '26105',
    label: 'Mason',
  },
  {
    fips: '26107',
    label: 'Mecosta',
  },
  {
    fips: '26109',
    label: 'Menominee',
  },
  {
    fips: '26111',
    label: 'Midland',
  },
  {
    fips: '26113',
    label: 'Missaukee',
  },
  {
    fips: '26115',
    label: 'Monroe',
  },
  {
    fips: '26117',
    label: 'Montcalm',
  },
  {
    fips: '26119',
    label: 'Montmorency',
  },
  {
    fips: '26121',
    label: 'Muskegon',
  },
  {
    fips: '26123',
    label: 'Newaygo',
  },
  {
    fips: '26125',
    label: 'Oakland',
  },
  {
    fips: '26127',
    label: 'Oceana',
  },
  {
    fips: '26129',
    label: 'Ogemaw',
  },
  {
    fips: '26131',
    label: 'Ontonagon',
  },
  {
    fips: '26133',
    label: 'Osceola',
  },
  {
    fips: '26135',
    label: 'Oscoda',
  },
  {
    fips: '26137',
    label: 'Otsego',
  },
  {
    fips: '26139',
    label: 'Ottawa',
  },
  {
    fips: '26141',
    label: 'Presque Isle',
  },
  {
    fips: '26143',
    label: 'Roscommon',
  },
  {
    fips: '26145',
    label: 'Saginaw',
  },
  {
    fips: '26147',
    label: 'St. Clair',
  },
  {
    fips: '26149',
    label: 'St. Joseph',
  },
  {
    fips: '26151',
    label: 'Sanilac',
  },
  {
    fips: '26153',
    label: 'Schoolcraft',
  },
  {
    fips: '26155',
    label: 'Shiawassee',
  },
  {
    fips: '26157',
    label: 'Tuscola',
  },
  {
    fips: '26159',
    label: 'Van Buren',
  },
  {
    fips: '26161',
    label: 'Washtenaw',
  },
  {
    fips: '26163',
    label: 'Wayne',
  },
  {
    fips: '26165',
    label: 'Wexford',
  },
  {
    fips: '27001',
    label: 'Aitkin',
  },
  {
    fips: '27003',
    label: 'Anoka',
  },
  {
    fips: '27005',
    label: 'Becker',
  },
  {
    fips: '27007',
    label: 'Beltrami',
  },
  {
    fips: '27009',
    label: 'Benton',
  },
  {
    fips: '27011',
    label: 'Big Stone',
  },
  {
    fips: '27013',
    label: 'Blue Earth',
  },
  {
    fips: '27015',
    label: 'Brown',
  },
  {
    fips: '27017',
    label: 'Carlton',
  },
  {
    fips: '27019',
    label: 'Carver',
  },
  {
    fips: '27021',
    label: 'Cass',
  },
  {
    fips: '27023',
    label: 'Chippewa',
  },
  {
    fips: '27025',
    label: 'Chisago',
  },
  {
    fips: '27027',
    label: 'Clay',
  },
  {
    fips: '27029',
    label: 'Clearwater',
  },
  {
    fips: '27031',
    label: 'Cook',
  },
  {
    fips: '27033',
    label: 'Cottonwood',
  },
  {
    fips: '27035',
    label: 'Crow Wing',
  },
  {
    fips: '27037',
    label: 'Dakota',
  },
  {
    fips: '27039',
    label: 'Dodge',
  },
  {
    fips: '27041',
    label: 'Douglas',
  },
  {
    fips: '27043',
    label: 'Faribault',
  },
  {
    fips: '27045',
    label: 'Fillmore',
  },
  {
    fips: '27047',
    label: 'Freeborn',
  },
  {
    fips: '27049',
    label: 'Goodhue',
  },
  {
    fips: '27051',
    label: 'Grant',
  },
  {
    fips: '27053',
    label: 'Hennepin',
  },
  {
    fips: '27055',
    label: 'Houston',
  },
  {
    fips: '27057',
    label: 'Hubbard',
  },
  {
    fips: '27059',
    label: 'Isanti',
  },
  {
    fips: '27061',
    label: 'Itasca',
  },
  {
    fips: '27063',
    label: 'Jackson',
  },
  {
    fips: '27065',
    label: 'Kanabec',
  },
  {
    fips: '27067',
    label: 'Kandiyohi',
  },
  {
    fips: '27069',
    label: 'Kittson',
  },
  {
    fips: '27071',
    label: 'Koochiching',
  },
  {
    fips: '27073',
    label: 'Lac qui Parle',
  },
  {
    fips: '27075',
    label: 'Lake',
  },
  {
    fips: '27077',
    label: 'Lake of the Woods',
  },
  {
    fips: '27079',
    label: 'Le Sueur',
  },
  {
    fips: '27081',
    label: 'Lincoln',
  },
  {
    fips: '27083',
    label: 'Lyon',
  },
  {
    fips: '27085',
    label: 'McLeod',
  },
  {
    fips: '27087',
    label: 'Mahnomen',
  },
  {
    fips: '27089',
    label: 'Marshall',
  },
  {
    fips: '27091',
    label: 'Martin',
  },
  {
    fips: '27093',
    label: 'Meeker',
  },
  {
    fips: '27095',
    label: 'Mille Lacs',
  },
  {
    fips: '27097',
    label: 'Morrison',
  },
  {
    fips: '27099',
    label: 'Mower',
  },
  {
    fips: '27101',
    label: 'Murray',
  },
  {
    fips: '27103',
    label: 'Nicollet',
  },
  {
    fips: '27105',
    label: 'Nobles',
  },
  {
    fips: '27107',
    label: 'Norman',
  },
  {
    fips: '27109',
    label: 'Olmsted',
  },
  {
    fips: '27111',
    label: 'Otter Tail',
  },
  {
    fips: '27113',
    label: 'Pennington',
  },
  {
    fips: '27115',
    label: 'Pine',
  },
  {
    fips: '27117',
    label: 'Pipestone',
  },
  {
    fips: '27119',
    label: 'Polk',
  },
  {
    fips: '27121',
    label: 'Pope',
  },
  {
    fips: '27123',
    label: 'Ramsey',
  },
  {
    fips: '27125',
    label: 'Red Lake',
  },
  {
    fips: '27127',
    label: 'Redwood',
  },
  {
    fips: '27129',
    label: 'Renville',
  },
  {
    fips: '27131',
    label: 'Rice',
  },
  {
    fips: '27133',
    label: 'Rock',
  },
  {
    fips: '27135',
    label: 'Roseau',
  },
  {
    fips: '27137',
    label: 'St. Louis',
  },
  {
    fips: '27139',
    label: 'Scott',
  },
  {
    fips: '27141',
    label: 'Sherburne',
  },
  {
    fips: '27143',
    label: 'Sibley',
  },
  {
    fips: '27145',
    label: 'Stearns',
  },
  {
    fips: '27147',
    label: 'Steele',
  },
  {
    fips: '27149',
    label: 'Stevens',
  },
  {
    fips: '27151',
    label: 'Swift',
  },
  {
    fips: '27153',
    label: 'Todd',
  },
  {
    fips: '27155',
    label: 'Traverse',
  },
  {
    fips: '27157',
    label: 'Wabasha',
  },
  {
    fips: '27159',
    label: 'Wadena',
  },
  {
    fips: '27161',
    label: 'Waseca',
  },
  {
    fips: '27163',
    label: 'Washington',
  },
  {
    fips: '27165',
    label: 'Watonwan',
  },
  {
    fips: '27167',
    label: 'Wilkin',
  },
  {
    fips: '27169',
    label: 'Winona',
  },
  {
    fips: '27171',
    label: 'Wright',
  },
  {
    fips: '27173',
    label: 'Yellow Medicine',
  },
  {
    fips: '28001',
    label: 'Adams',
  },
  {
    fips: '28003',
    label: 'Alcorn',
  },
  {
    fips: '28005',
    label: 'Amite',
  },
  {
    fips: '28007',
    label: 'Attala',
  },
  {
    fips: '28009',
    label: 'Benton',
  },
  {
    fips: '28011',
    label: 'Bolivar',
  },
  {
    fips: '28013',
    label: 'Calhoun',
  },
  {
    fips: '28015',
    label: 'Carroll',
  },
  {
    fips: '28017',
    label: 'Chickasaw',
  },
  {
    fips: '28019',
    label: 'Choctaw',
  },
  {
    fips: '28021',
    label: 'Claiborne',
  },
  {
    fips: '28023',
    label: 'Clarke',
  },
  {
    fips: '28025',
    label: 'Clay',
  },
  {
    fips: '28027',
    label: 'Coahoma',
  },
  {
    fips: '28029',
    label: 'Copiah',
  },
  {
    fips: '28031',
    label: 'Covington',
  },
  {
    fips: '28033',
    label: 'DeSoto',
  },
  {
    fips: '28035',
    label: 'Forrest',
  },
  {
    fips: '28037',
    label: 'Franklin',
  },
  {
    fips: '28039',
    label: 'George',
  },
  {
    fips: '28041',
    label: 'Greene',
  },
  {
    fips: '28043',
    label: 'Grenada',
  },
  {
    fips: '28045',
    label: 'Hancock',
  },
  {
    fips: '28047',
    label: 'Harrison',
  },
  {
    fips: '28049',
    label: 'Hinds',
  },
  {
    fips: '28051',
    label: 'Holmes',
  },
  {
    fips: '28053',
    label: 'Humphreys',
  },
  {
    fips: '28055',
    label: 'Issaquena',
  },
  {
    fips: '28057',
    label: 'Itawamba',
  },
  {
    fips: '28059',
    label: 'Jackson',
  },
  {
    fips: '28061',
    label: 'Jasper',
  },
  {
    fips: '28063',
    label: 'Jefferson',
  },
  {
    fips: '28065',
    label: 'Jefferson Davis',
  },
  {
    fips: '28067',
    label: 'Jones',
  },
  {
    fips: '28069',
    label: 'Kemper',
  },
  {
    fips: '28071',
    label: 'Lafayette',
  },
  {
    fips: '28073',
    label: 'Lamar',
  },
  {
    fips: '28075',
    label: 'Lauderdale',
  },
  {
    fips: '28077',
    label: 'Lawrence',
  },
  {
    fips: '28079',
    label: 'Leake',
  },
  {
    fips: '28081',
    label: 'Lee',
  },
  {
    fips: '28083',
    label: 'Leflore',
  },
  {
    fips: '28085',
    label: 'Lincoln',
  },
  {
    fips: '28087',
    label: 'Lowndes',
  },
  {
    fips: '28089',
    label: 'Madison',
  },
  {
    fips: '28091',
    label: 'Marion',
  },
  {
    fips: '28093',
    label: 'Marshall',
  },
  {
    fips: '28095',
    label: 'Monroe',
  },
  {
    fips: '28097',
    label: 'Montgomery',
  },
  {
    fips: '28099',
    label: 'Neshoba',
  },
  {
    fips: '28101',
    label: 'Newton',
  },
  {
    fips: '28103',
    label: 'Noxubee',
  },
  {
    fips: '28105',
    label: 'Oktibbeha',
  },
  {
    fips: '28107',
    label: 'Panola',
  },
  {
    fips: '28109',
    label: 'Pearl River',
  },
  {
    fips: '28111',
    label: 'Perry',
  },
  {
    fips: '28113',
    label: 'Pike',
  },
  {
    fips: '28115',
    label: 'Pontotoc',
  },
  {
    fips: '28117',
    label: 'Prentiss',
  },
  {
    fips: '28119',
    label: 'Quitman',
  },
  {
    fips: '28121',
    label: 'Rankin',
  },
  {
    fips: '28123',
    label: 'Scott',
  },
  {
    fips: '28125',
    label: 'Sharkey',
  },
  {
    fips: '28127',
    label: 'Simpson',
  },
  {
    fips: '28129',
    label: 'Smith',
  },
  {
    fips: '28131',
    label: 'Stone',
  },
  {
    fips: '28133',
    label: 'Sunflower',
  },
  {
    fips: '28135',
    label: 'Tallahatchie',
  },
  {
    fips: '28137',
    label: 'Tate',
  },
  {
    fips: '28139',
    label: 'Tippah',
  },
  {
    fips: '28141',
    label: 'Tishomingo',
  },
  {
    fips: '28143',
    label: 'Tunica',
  },
  {
    fips: '28145',
    label: 'Union',
  },
  {
    fips: '28147',
    label: 'Walthall',
  },
  {
    fips: '28149',
    label: 'Warren',
  },
  {
    fips: '28151',
    label: 'Washington',
  },
  {
    fips: '28153',
    label: 'Wayne',
  },
  {
    fips: '28155',
    label: 'Webster',
  },
  {
    fips: '28157',
    label: 'Wilkinson',
  },
  {
    fips: '28159',
    label: 'Winston',
  },
  {
    fips: '28161',
    label: 'Yalobusha',
  },
  {
    fips: '28163',
    label: 'Yazoo',
  },
  {
    fips: '29001',
    label: 'Adair',
  },
  {
    fips: '29003',
    label: 'Andrew',
  },
  {
    fips: '29005',
    label: 'Atchison',
  },
  {
    fips: '29007',
    label: 'Audrain',
  },
  {
    fips: '29009',
    label: 'Barry',
  },
  {
    fips: '29011',
    label: 'Barton',
  },
  {
    fips: '29013',
    label: 'Bates',
  },
  {
    fips: '29015',
    label: 'Benton',
  },
  {
    fips: '29017',
    label: 'Bollinger',
  },
  {
    fips: '29019',
    label: 'Boone',
  },
  {
    fips: '29021',
    label: 'Buchanan',
  },
  {
    fips: '29023',
    label: 'Butler',
  },
  {
    fips: '29025',
    label: 'Caldwell',
  },
  {
    fips: '29027',
    label: 'Callaway',
  },
  {
    fips: '29029',
    label: 'Camden',
  },
  {
    fips: '29031',
    label: 'Cape Girardeau',
  },
  {
    fips: '29033',
    label: 'Carroll',
  },
  {
    fips: '29035',
    label: 'Carter',
  },
  {
    fips: '29037',
    label: 'Cass',
  },
  {
    fips: '29039',
    label: 'Cedar',
  },
  {
    fips: '29041',
    label: 'Chariton',
  },
  {
    fips: '29043',
    label: 'Christian',
  },
  {
    fips: '29045',
    label: 'Clark',
  },
  {
    fips: '29047',
    label: 'Clay',
  },
  {
    fips: '29049',
    label: 'Clinton',
  },
  {
    fips: '29051',
    label: 'Cole',
  },
  {
    fips: '29053',
    label: 'Cooper',
  },
  {
    fips: '29055',
    label: 'Crawford',
  },
  {
    fips: '29057',
    label: 'Dade',
  },
  {
    fips: '29059',
    label: 'Dallas',
  },
  {
    fips: '29061',
    label: 'Daviess',
  },
  {
    fips: '29063',
    label: 'DeKalb',
  },
  {
    fips: '29065',
    label: 'Dent',
  },
  {
    fips: '29067',
    label: 'Douglas',
  },
  {
    fips: '29069',
    label: 'Dunklin',
  },
  {
    fips: '29071',
    label: 'Franklin',
  },
  {
    fips: '29073',
    label: 'Gasconade',
  },
  {
    fips: '29075',
    label: 'Gentry',
  },
  {
    fips: '29077',
    label: 'Greene',
  },
  {
    fips: '29079',
    label: 'Grundy',
  },
  {
    fips: '29081',
    label: 'Harrison',
  },
  {
    fips: '29083',
    label: 'Henry',
  },
  {
    fips: '29085',
    label: 'Hickory',
  },
  {
    fips: '29087',
    label: 'Holt',
  },
  {
    fips: '29089',
    label: 'Howard',
  },
  {
    fips: '29091',
    label: 'Howell',
  },
  {
    fips: '29093',
    label: 'Iron',
  },
  {
    fips: '29095',
    label: 'Jackson',
  },
  {
    fips: '29097',
    label: 'Jasper',
  },
  {
    fips: '29099',
    label: 'Jefferson',
  },
  {
    fips: '29101',
    label: 'Johnson',
  },
  {
    fips: '29103',
    label: 'Knox',
  },
  {
    fips: '29105',
    label: 'Laclede',
  },
  {
    fips: '29107',
    label: 'Lafayette',
  },
  {
    fips: '29109',
    label: 'Lawrence',
  },
  {
    fips: '29111',
    label: 'Lewis',
  },
  {
    fips: '29113',
    label: 'Lincoln',
  },
  {
    fips: '29115',
    label: 'Linn',
  },
  {
    fips: '29117',
    label: 'Livingston',
  },
  {
    fips: '29119',
    label: 'McDonald',
  },
  {
    fips: '29121',
    label: 'Macon',
  },
  {
    fips: '29123',
    label: 'Madison',
  },
  {
    fips: '29125',
    label: 'Maries',
  },
  {
    fips: '29127',
    label: 'Marion',
  },
  {
    fips: '29129',
    label: 'Mercer',
  },
  {
    fips: '29131',
    label: 'Miller',
  },
  {
    fips: '29133',
    label: 'Mississippi',
  },
  {
    fips: '29135',
    label: 'Moniteau',
  },
  {
    fips: '29137',
    label: 'Monroe',
  },
  {
    fips: '29139',
    label: 'Montgomery',
  },
  {
    fips: '29141',
    label: 'Morgan',
  },
  {
    fips: '29143',
    label: 'New Madrid',
  },
  {
    fips: '29145',
    label: 'Newton',
  },
  {
    fips: '29147',
    label: 'Nodaway',
  },
  {
    fips: '29149',
    label: 'Oregon',
  },
  {
    fips: '29151',
    label: 'Osage',
  },
  {
    fips: '29153',
    label: 'Ozark',
  },
  {
    fips: '29155',
    label: 'Pemiscot',
  },
  {
    fips: '29157',
    label: 'Perry',
  },
  {
    fips: '29159',
    label: 'Pettis',
  },
  {
    fips: '29161',
    label: 'Phelps',
  },
  {
    fips: '29163',
    label: 'Pike',
  },
  {
    fips: '29165',
    label: 'Platte',
  },
  {
    fips: '29167',
    label: 'Polk',
  },
  {
    fips: '29169',
    label: 'Pulaski',
  },
  {
    fips: '29171',
    label: 'Putnam',
  },
  {
    fips: '29173',
    label: 'Ralls',
  },
  {
    fips: '29175',
    label: 'Randolph',
  },
  {
    fips: '29177',
    label: 'Ray',
  },
  {
    fips: '29179',
    label: 'Reynolds',
  },
  {
    fips: '29181',
    label: 'Ripley',
  },
  {
    fips: '29183',
    label: 'St. Charles',
  },
  {
    fips: '29185',
    label: 'St. Clair',
  },
  {
    fips: '29186',
    label: 'Ste. Genevieve',
  },
  {
    fips: '29187',
    label: 'St. Francois',
  },
  {
    fips: '29189',
    label: 'St. Louis',
  },
  {
    fips: '29195',
    label: 'Saline',
  },
  {
    fips: '29197',
    label: 'Schuyler',
  },
  {
    fips: '29199',
    label: 'Scotland',
  },
  {
    fips: '29201',
    label: 'Scott',
  },
  {
    fips: '29203',
    label: 'Shannon',
  },
  {
    fips: '29205',
    label: 'Shelby',
  },
  {
    fips: '29207',
    label: 'Stoddard',
  },
  {
    fips: '29209',
    label: 'Stone',
  },
  {
    fips: '29211',
    label: 'Sullivan',
  },
  {
    fips: '29213',
    label: 'Taney',
  },
  {
    fips: '29215',
    label: 'Texas',
  },
  {
    fips: '29217',
    label: 'Vernon',
  },
  {
    fips: '29219',
    label: 'Warren',
  },
  {
    fips: '29221',
    label: 'Washington',
  },
  {
    fips: '29223',
    label: 'Wayne',
  },
  {
    fips: '29225',
    label: 'Webster',
  },
  {
    fips: '29227',
    label: 'Worth',
  },
  {
    fips: '29229',
    label: 'Wright',
  },
  {
    fips: '29510',
    label: 'St. Louis city',
  },
  {
    fips: '29998',
    label: 'Kansas City',
  },
  {
    fips: '30001',
    label: 'Beaverhead',
  },
  {
    fips: '30003',
    label: 'Big Horn',
  },
  {
    fips: '30005',
    label: 'Blaine',
  },
  {
    fips: '30007',
    label: 'Broadwater',
  },
  {
    fips: '30009',
    label: 'Carbon',
  },
  {
    fips: '30011',
    label: 'Carter',
  },
  {
    fips: '30013',
    label: 'Cascade',
  },
  {
    fips: '30015',
    label: 'Chouteau',
  },
  {
    fips: '30017',
    label: 'Custer',
  },
  {
    fips: '30019',
    label: 'Daniels',
  },
  {
    fips: '30021',
    label: 'Dawson',
  },
  {
    fips: '30023',
    label: 'Deer Lodge',
  },
  {
    fips: '30025',
    label: 'Fallon',
  },
  {
    fips: '30027',
    label: 'Fergus',
  },
  {
    fips: '30029',
    label: 'Flathead',
  },
  {
    fips: '30031',
    label: 'Gallatin',
  },
  {
    fips: '30033',
    label: 'Garfield',
  },
  {
    fips: '30035',
    label: 'Glacier',
  },
  {
    fips: '30037',
    label: 'Golden Valley',
  },
  {
    fips: '30039',
    label: 'Granite',
  },
  {
    fips: '30041',
    label: 'Hill',
  },
  {
    fips: '30043',
    label: 'Jefferson',
  },
  {
    fips: '30045',
    label: 'Judith Basin',
  },
  {
    fips: '30047',
    label: 'Lake',
  },
  {
    fips: '30049',
    label: 'Lewis and Clark',
  },
  {
    fips: '30051',
    label: 'Liberty',
  },
  {
    fips: '30053',
    label: 'Lincoln',
  },
  {
    fips: '30055',
    label: 'McCone',
  },
  {
    fips: '30057',
    label: 'Madison',
  },
  {
    fips: '30059',
    label: 'Meagher',
  },
  {
    fips: '30061',
    label: 'Mineral',
  },
  {
    fips: '30063',
    label: 'Missoula',
  },
  {
    fips: '30065',
    label: 'Musselshell',
  },
  {
    fips: '30067',
    label: 'Park',
  },
  {
    fips: '30069',
    label: 'Petroleum',
  },
  {
    fips: '30071',
    label: 'Phillips',
  },
  {
    fips: '30073',
    label: 'Pondera',
  },
  {
    fips: '30075',
    label: 'Powder River',
  },
  {
    fips: '30077',
    label: 'Powell',
  },
  {
    fips: '30079',
    label: 'Prairie',
  },
  {
    fips: '30081',
    label: 'Ravalli',
  },
  {
    fips: '30083',
    label: 'Richland',
  },
  {
    fips: '30085',
    label: 'Roosevelt',
  },
  {
    fips: '30087',
    label: 'Rosebud',
  },
  {
    fips: '30089',
    label: 'Sanders',
  },
  {
    fips: '30091',
    label: 'Sheridan',
  },
  {
    fips: '30093',
    label: 'Silver Bow',
  },
  {
    fips: '30095',
    label: 'Stillwater',
  },
  {
    fips: '30097',
    label: 'Sweet Grass',
  },
  {
    fips: '30099',
    label: 'Teton',
  },
  {
    fips: '30101',
    label: 'Toole',
  },
  {
    fips: '30103',
    label: 'Treasure',
  },
  {
    fips: '30105',
    label: 'Valley',
  },
  {
    fips: '30107',
    label: 'Wheatland',
  },
  {
    fips: '30109',
    label: 'Wibaux',
  },
  {
    fips: '30111',
    label: 'Yellowstone',
  },
  {
    fips: '31001',
    label: 'Adams',
  },
  {
    fips: '31003',
    label: 'Antelope',
  },
  {
    fips: '31005',
    label: 'Arthur',
  },
  {
    fips: '31007',
    label: 'Banner',
  },
  {
    fips: '31009',
    label: 'Blaine',
  },
  {
    fips: '31011',
    label: 'Boone',
  },
  {
    fips: '31013',
    label: 'Box Butte',
  },
  {
    fips: '31015',
    label: 'Boyd',
  },
  {
    fips: '31017',
    label: 'Brown',
  },
  {
    fips: '31019',
    label: 'Buffalo',
  },
  {
    fips: '31021',
    label: 'Burt',
  },
  {
    fips: '31023',
    label: 'Butler',
  },
  {
    fips: '31025',
    label: 'Cass',
  },
  {
    fips: '31027',
    label: 'Cedar',
  },
  {
    fips: '31029',
    label: 'Chase',
  },
  {
    fips: '31031',
    label: 'Cherry',
  },
  {
    fips: '31033',
    label: 'Cheyenne',
  },
  {
    fips: '31035',
    label: 'Clay',
  },
  {
    fips: '31037',
    label: 'Colfax',
  },
  {
    fips: '31039',
    label: 'Cuming',
  },
  {
    fips: '31041',
    label: 'Custer',
  },
  {
    fips: '31043',
    label: 'Dakota',
  },
  {
    fips: '31045',
    label: 'Dawes',
  },
  {
    fips: '31047',
    label: 'Dawson',
  },
  {
    fips: '31049',
    label: 'Deuel',
  },
  {
    fips: '31051',
    label: 'Dixon',
  },
  {
    fips: '31053',
    label: 'Dodge',
  },
  {
    fips: '31055',
    label: 'Douglas',
  },
  {
    fips: '31057',
    label: 'Dundy',
  },
  {
    fips: '31059',
    label: 'Fillmore',
  },
  {
    fips: '31061',
    label: 'Franklin',
  },
  {
    fips: '31063',
    label: 'Frontier',
  },
  {
    fips: '31065',
    label: 'Furnas',
  },
  {
    fips: '31067',
    label: 'Gage',
  },
  {
    fips: '31069',
    label: 'Garden',
  },
  {
    fips: '31071',
    label: 'Garfield',
  },
  {
    fips: '31073',
    label: 'Gosper',
  },
  {
    fips: '31075',
    label: 'Grant',
  },
  {
    fips: '31077',
    label: 'Greeley',
  },
  {
    fips: '31079',
    label: 'Hall',
  },
  {
    fips: '31081',
    label: 'Hamilton',
  },
  {
    fips: '31083',
    label: 'Harlan',
  },
  {
    fips: '31085',
    label: 'Hayes',
  },
  {
    fips: '31087',
    label: 'Hitchcock',
  },
  {
    fips: '31089',
    label: 'Holt',
  },
  {
    fips: '31091',
    label: 'Hooker',
  },
  {
    fips: '31093',
    label: 'Howard',
  },
  {
    fips: '31095',
    label: 'Jefferson',
  },
  {
    fips: '31097',
    label: 'Johnson',
  },
  {
    fips: '31099',
    label: 'Kearney',
  },
  {
    fips: '31101',
    label: 'Keith',
  },
  {
    fips: '31103',
    label: 'Keya Paha',
  },
  {
    fips: '31105',
    label: 'Kimball',
  },
  {
    fips: '31107',
    label: 'Knox',
  },
  {
    fips: '31109',
    label: 'Lancaster',
  },
  {
    fips: '31111',
    label: 'Lincoln',
  },
  {
    fips: '31113',
    label: 'Logan',
  },
  {
    fips: '31115',
    label: 'Loup',
  },
  {
    fips: '31117',
    label: 'McPherson',
  },
  {
    fips: '31119',
    label: 'Madison',
  },
  {
    fips: '31121',
    label: 'Merrick',
  },
  {
    fips: '31123',
    label: 'Morrill',
  },
  {
    fips: '31125',
    label: 'Nance',
  },
  {
    fips: '31127',
    label: 'Nemaha',
  },
  {
    fips: '31129',
    label: 'Nuckolls',
  },
  {
    fips: '31131',
    label: 'Otoe',
  },
  {
    fips: '31133',
    label: 'Pawnee',
  },
  {
    fips: '31135',
    label: 'Perkins',
  },
  {
    fips: '31137',
    label: 'Phelps',
  },
  {
    fips: '31139',
    label: 'Pierce',
  },
  {
    fips: '31141',
    label: 'Platte',
  },
  {
    fips: '31143',
    label: 'Polk',
  },
  {
    fips: '31145',
    label: 'Red Willow',
  },
  {
    fips: '31147',
    label: 'Richardson',
  },
  {
    fips: '31149',
    label: 'Rock',
  },
  {
    fips: '31151',
    label: 'Saline',
  },
  {
    fips: '31153',
    label: 'Sarpy',
  },
  {
    fips: '31155',
    label: 'Saunders',
  },
  {
    fips: '31157',
    label: 'Scotts Bluff',
  },
  {
    fips: '31159',
    label: 'Seward',
  },
  {
    fips: '31161',
    label: 'Sheridan',
  },
  {
    fips: '31163',
    label: 'Sherman',
  },
  {
    fips: '31165',
    label: 'Sioux',
  },
  {
    fips: '31167',
    label: 'Stanton',
  },
  {
    fips: '31169',
    label: 'Thayer',
  },
  {
    fips: '31171',
    label: 'Thomas',
  },
  {
    fips: '31173',
    label: 'Thurston',
  },
  {
    fips: '31175',
    label: 'Valley',
  },
  {
    fips: '31177',
    label: 'Washington',
  },
  {
    fips: '31179',
    label: 'Wayne',
  },
  {
    fips: '31181',
    label: 'Webster',
  },
  {
    fips: '31183',
    label: 'Wheeler',
  },
  {
    fips: '31185',
    label: 'York',
  },
  {
    fips: '32001',
    label: 'Churchill',
  },
  {
    fips: '32003',
    label: 'Clark',
  },
  {
    fips: '32005',
    label: 'Douglas',
  },
  {
    fips: '32007',
    label: 'Elko',
  },
  {
    fips: '32009',
    label: 'Esmeralda',
  },
  {
    fips: '32011',
    label: 'Eureka',
  },
  {
    fips: '32013',
    label: 'Humboldt',
  },
  {
    fips: '32015',
    label: 'Lander',
  },
  {
    fips: '32017',
    label: 'Lincoln',
  },
  {
    fips: '32019',
    label: 'Lyon',
  },
  {
    fips: '32021',
    label: 'Mineral',
  },
  {
    fips: '32023',
    label: 'Nye',
  },
  {
    fips: '32027',
    label: 'Pershing',
  },
  {
    fips: '32029',
    label: 'Storey',
  },
  {
    fips: '32031',
    label: 'Washoe',
  },
  {
    fips: '32033',
    label: 'White Pine',
  },
  {
    fips: '32510',
    label: 'Carson City',
  },
  {
    fips: '33001',
    label: 'Belknap',
  },
  {
    fips: '33003',
    label: 'Carroll',
  },
  {
    fips: '33005',
    label: 'Cheshire',
  },
  {
    fips: '33007',
    label: 'Coos',
  },
  {
    fips: '33009',
    label: 'Grafton',
  },
  {
    fips: '33011',
    label: 'Hillsborough',
  },
  {
    fips: '33013',
    label: 'Merrimack',
  },
  {
    fips: '33015',
    label: 'Rockingham',
  },
  {
    fips: '33017',
    label: 'Strafford',
  },
  {
    fips: '33019',
    label: 'Sullivan',
  },
  {
    fips: '34001',
    label: 'Atlantic',
  },
  {
    fips: '34003',
    label: 'Bergen',
  },
  {
    fips: '34005',
    label: 'Burlington',
  },
  {
    fips: '34007',
    label: 'Camden',
  },
  {
    fips: '34009',
    label: 'Cape May',
  },
  {
    fips: '34011',
    label: 'Cumberland',
  },
  {
    fips: '34013',
    label: 'Essex',
  },
  {
    fips: '34015',
    label: 'Gloucester',
  },
  {
    fips: '34017',
    label: 'Hudson',
  },
  {
    fips: '34019',
    label: 'Hunterdon',
  },
  {
    fips: '34021',
    label: 'Mercer',
  },
  {
    fips: '34023',
    label: 'Middlesex',
  },
  {
    fips: '34025',
    label: 'Monmouth',
  },
  {
    fips: '34027',
    label: 'Morris',
  },
  {
    fips: '34029',
    label: 'Ocean',
  },
  {
    fips: '34031',
    label: 'Passaic',
  },
  {
    fips: '34033',
    label: 'Salem',
  },
  {
    fips: '34035',
    label: 'Somerset',
  },
  {
    fips: '34037',
    label: 'Sussex',
  },
  {
    fips: '34039',
    label: 'Union',
  },
  {
    fips: '34041',
    label: 'Warren',
  },
  {
    fips: '35001',
    label: 'Bernalillo',
  },
  {
    fips: '35003',
    label: 'Catron',
  },
  {
    fips: '35005',
    label: 'Chaves',
  },
  {
    fips: '35006',
    label: 'Cibola',
  },
  {
    fips: '35007',
    label: 'Colfax',
  },
  {
    fips: '35009',
    label: 'Curry',
  },
  {
    fips: '35011',
    label: 'De Baca',
  },
  {
    fips: '35013',
    label: 'Doña Ana',
  },
  {
    fips: '35015',
    label: 'Eddy',
  },
  {
    fips: '35017',
    label: 'Grant',
  },
  {
    fips: '35019',
    label: 'Guadalupe',
  },
  {
    fips: '35021',
    label: 'Harding',
  },
  {
    fips: '35023',
    label: 'Hidalgo',
  },
  {
    fips: '35025',
    label: 'Lea',
  },
  {
    fips: '35027',
    label: 'Lincoln',
  },
  {
    fips: '35028',
    label: 'Los Alamos',
  },
  {
    fips: '35029',
    label: 'Luna',
  },
  {
    fips: '35031',
    label: 'McKinley',
  },
  {
    fips: '35033',
    label: 'Mora',
  },
  {
    fips: '35035',
    label: 'Otero',
  },
  {
    fips: '35037',
    label: 'Quay',
  },
  {
    fips: '35039',
    label: 'Rio Arriba',
  },
  {
    fips: '35041',
    label: 'Roosevelt',
  },
  {
    fips: '35043',
    label: 'Sandoval',
  },
  {
    fips: '35045',
    label: 'San Juan',
  },
  {
    fips: '35047',
    label: 'San Miguel',
  },
  {
    fips: '35049',
    label: 'Santa Fe',
  },
  {
    fips: '35051',
    label: 'Sierra',
  },
  {
    fips: '35053',
    label: 'Socorro',
  },
  {
    fips: '35055',
    label: 'Taos',
  },
  {
    fips: '35057',
    label: 'Torrance',
  },
  {
    fips: '35059',
    label: 'Union',
  },
  {
    fips: '35061',
    label: 'Valencia',
  },
  {
    fips: '36001',
    label: 'Albany',
  },
  {
    fips: '36003',
    label: 'Allegany',
  },
  {
    fips: '36007',
    label: 'Broome',
  },
  {
    fips: '36009',
    label: 'Cattaraugus',
  },
  {
    fips: '36011',
    label: 'Cayuga',
  },
  {
    fips: '36013',
    label: 'Chautauqua',
  },
  {
    fips: '36015',
    label: 'Chemung',
  },
  {
    fips: '36017',
    label: 'Chenango',
  },
  {
    fips: '36019',
    label: 'Clinton',
  },
  {
    fips: '36021',
    label: 'Columbia',
  },
  {
    fips: '36023',
    label: 'Cortland',
  },
  {
    fips: '36025',
    label: 'Delaware',
  },
  {
    fips: '36027',
    label: 'Dutchess',
  },
  {
    fips: '36029',
    label: 'Erie',
  },
  {
    fips: '36031',
    label: 'Essex',
  },
  {
    fips: '36033',
    label: 'Franklin',
  },
  {
    fips: '36035',
    label: 'Fulton',
  },
  {
    fips: '36037',
    label: 'Genesee',
  },
  {
    fips: '36039',
    label: 'Greene',
  },
  {
    fips: '36041',
    label: 'Hamilton',
  },
  {
    fips: '36043',
    label: 'Herkimer',
  },
  {
    fips: '36045',
    label: 'Jefferson',
  },
  {
    fips: '36049',
    label: 'Lewis',
  },
  {
    fips: '36051',
    label: 'Livingston',
  },
  {
    fips: '36053',
    label: 'Madison',
  },
  {
    fips: '36055',
    label: 'Monroe',
  },
  {
    fips: '36057',
    label: 'Montgomery',
  },
  {
    fips: '36059',
    label: 'Nassau',
  },
  {
    fips: '36063',
    label: 'Niagara',
  },
  {
    fips: '36065',
    label: 'Oneida',
  },
  {
    fips: '36067',
    label: 'Onondaga',
  },
  {
    fips: '36069',
    label: 'Ontario',
  },
  {
    fips: '36071',
    label: 'Orange',
  },
  {
    fips: '36073',
    label: 'Orleans',
  },
  {
    fips: '36075',
    label: 'Oswego',
  },
  {
    fips: '36077',
    label: 'Otsego',
  },
  {
    fips: '36079',
    label: 'Putnam',
  },
  {
    fips: '36083',
    label: 'Rensselaer',
  },
  {
    fips: '36087',
    label: 'Rockland',
  },
  {
    fips: '36089',
    label: 'St. Lawrence',
  },
  {
    fips: '36091',
    label: 'Saratoga',
  },
  {
    fips: '36093',
    label: 'Schenectady',
  },
  {
    fips: '36095',
    label: 'Schoharie',
  },
  {
    fips: '36097',
    label: 'Schuyler',
  },
  {
    fips: '36099',
    label: 'Seneca',
  },
  {
    fips: '36101',
    label: 'Steuben',
  },
  {
    fips: '36103',
    label: 'Suffolk',
  },
  {
    fips: '36105',
    label: 'Sullivan',
  },
  {
    fips: '36107',
    label: 'Tioga',
  },
  {
    fips: '36109',
    label: 'Tompkins',
  },
  {
    fips: '36111',
    label: 'Ulster',
  },
  {
    fips: '36113',
    label: 'Warren',
  },
  {
    fips: '36115',
    label: 'Washington',
  },
  {
    fips: '36117',
    label: 'Wayne',
  },
  {
    fips: '36119',
    label: 'Westchester',
  },
  {
    fips: '36121',
    label: 'Wyoming',
  },
  {
    fips: '36123',
    label: 'Yates',
  },
  {
    fips: '36998',
    label: 'New York City',
  },
  {
    fips: '37001',
    label: 'Alamance',
  },
  {
    fips: '37003',
    label: 'Alexander',
  },
  {
    fips: '37005',
    label: 'Alleghany',
  },
  {
    fips: '37007',
    label: 'Anson',
  },
  {
    fips: '37009',
    label: 'Ashe',
  },
  {
    fips: '37011',
    label: 'Avery',
  },
  {
    fips: '37013',
    label: 'Beaufort',
  },
  {
    fips: '37015',
    label: 'Bertie',
  },
  {
    fips: '37017',
    label: 'Bladen',
  },
  {
    fips: '37019',
    label: 'Brunswick',
  },
  {
    fips: '37021',
    label: 'Buncombe',
  },
  {
    fips: '37023',
    label: 'Burke',
  },
  {
    fips: '37025',
    label: 'Cabarrus',
  },
  {
    fips: '37027',
    label: 'Caldwell',
  },
  {
    fips: '37029',
    label: 'Camden',
  },
  {
    fips: '37031',
    label: 'Carteret',
  },
  {
    fips: '37033',
    label: 'Caswell',
  },
  {
    fips: '37035',
    label: 'Catawba',
  },
  {
    fips: '37037',
    label: 'Chatham',
  },
  {
    fips: '37039',
    label: 'Cherokee',
  },
  {
    fips: '37041',
    label: 'Chowan',
  },
  {
    fips: '37043',
    label: 'Clay',
  },
  {
    fips: '37045',
    label: 'Cleveland',
  },
  {
    fips: '37047',
    label: 'Columbus',
  },
  {
    fips: '37049',
    label: 'Craven',
  },
  {
    fips: '37051',
    label: 'Cumberland',
  },
  {
    fips: '37053',
    label: 'Currituck',
  },
  {
    fips: '37055',
    label: 'Dare',
  },
  {
    fips: '37057',
    label: 'Davidson',
  },
  {
    fips: '37059',
    label: 'Davie',
  },
  {
    fips: '37061',
    label: 'Duplin',
  },
  {
    fips: '37063',
    label: 'Durham',
  },
  {
    fips: '37065',
    label: 'Edgecombe',
  },
  {
    fips: '37067',
    label: 'Forsyth',
  },
  {
    fips: '37069',
    label: 'Franklin',
  },
  {
    fips: '37071',
    label: 'Gaston',
  },
  {
    fips: '37073',
    label: 'Gates',
  },
  {
    fips: '37075',
    label: 'Graham',
  },
  {
    fips: '37077',
    label: 'Granville',
  },
  {
    fips: '37079',
    label: 'Greene',
  },
  {
    fips: '37081',
    label: 'Guilford',
  },
  {
    fips: '37083',
    label: 'Halifax',
  },
  {
    fips: '37085',
    label: 'Harnett',
  },
  {
    fips: '37087',
    label: 'Haywood',
  },
  {
    fips: '37089',
    label: 'Henderson',
  },
  {
    fips: '37091',
    label: 'Hertford',
  },
  {
    fips: '37093',
    label: 'Hoke',
  },
  {
    fips: '37095',
    label: 'Hyde',
  },
  {
    fips: '37097',
    label: 'Iredell',
  },
  {
    fips: '37099',
    label: 'Jackson',
  },
  {
    fips: '37101',
    label: 'Johnston',
  },
  {
    fips: '37103',
    label: 'Jones',
  },
  {
    fips: '37105',
    label: 'Lee',
  },
  {
    fips: '37107',
    label: 'Lenoir',
  },
  {
    fips: '37109',
    label: 'Lincoln',
  },
  {
    fips: '37111',
    label: 'McDowell',
  },
  {
    fips: '37113',
    label: 'Macon',
  },
  {
    fips: '37115',
    label: 'Madison',
  },
  {
    fips: '37117',
    label: 'Martin',
  },
  {
    fips: '37119',
    label: 'Mecklenburg',
  },
  {
    fips: '37121',
    label: 'Mitchell',
  },
  {
    fips: '37123',
    label: 'Montgomery',
  },
  {
    fips: '37125',
    label: 'Moore',
  },
  {
    fips: '37127',
    label: 'Nash',
  },
  {
    fips: '37129',
    label: 'New Hanover',
  },
  {
    fips: '37131',
    label: 'Northampton',
  },
  {
    fips: '37133',
    label: 'Onslow',
  },
  {
    fips: '37135',
    label: 'Orange',
  },
  {
    fips: '37137',
    label: 'Pamlico',
  },
  {
    fips: '37139',
    label: 'Pasquotank',
  },
  {
    fips: '37141',
    label: 'Pender',
  },
  {
    fips: '37143',
    label: 'Perquimans',
  },
  {
    fips: '37145',
    label: 'Person',
  },
  {
    fips: '37147',
    label: 'Pitt',
  },
  {
    fips: '37149',
    label: 'Polk',
  },
  {
    fips: '37151',
    label: 'Randolph',
  },
  {
    fips: '37153',
    label: 'Richmond',
  },
  {
    fips: '37155',
    label: 'Robeson',
  },
  {
    fips: '37157',
    label: 'Rockingham',
  },
  {
    fips: '37159',
    label: 'Rowan',
  },
  {
    fips: '37161',
    label: 'Rutherford',
  },
  {
    fips: '37163',
    label: 'Sampson',
  },
  {
    fips: '37165',
    label: 'Scotland',
  },
  {
    fips: '37167',
    label: 'Stanly',
  },
  {
    fips: '37169',
    label: 'Stokes',
  },
  {
    fips: '37171',
    label: 'Surry',
  },
  {
    fips: '37173',
    label: 'Swain',
  },
  {
    fips: '37175',
    label: 'Transylvania',
  },
  {
    fips: '37177',
    label: 'Tyrrell',
  },
  {
    fips: '37179',
    label: 'Union',
  },
  {
    fips: '37181',
    label: 'Vance',
  },
  {
    fips: '37183',
    label: 'Wake',
  },
  {
    fips: '37185',
    label: 'Warren',
  },
  {
    fips: '37187',
    label: 'Washington',
  },
  {
    fips: '37189',
    label: 'Watauga',
  },
  {
    fips: '37191',
    label: 'Wayne',
  },
  {
    fips: '37193',
    label: 'Wilkes',
  },
  {
    fips: '37195',
    label: 'Wilson',
  },
  {
    fips: '37197',
    label: 'Yadkin',
  },
  {
    fips: '37199',
    label: 'Yancey',
  },
  {
    fips: '38001',
    label: 'Adams',
  },
  {
    fips: '38003',
    label: 'Barnes',
  },
  {
    fips: '38005',
    label: 'Benson',
  },
  {
    fips: '38007',
    label: 'Billings',
  },
  {
    fips: '38009',
    label: 'Bottineau',
  },
  {
    fips: '38011',
    label: 'Bowman',
  },
  {
    fips: '38013',
    label: 'Burke',
  },
  {
    fips: '38015',
    label: 'Burleigh',
  },
  {
    fips: '38017',
    label: 'Cass',
  },
  {
    fips: '38019',
    label: 'Cavalier',
  },
  {
    fips: '38021',
    label: 'Dickey',
  },
  {
    fips: '38023',
    label: 'Divide',
  },
  {
    fips: '38025',
    label: 'Dunn',
  },
  {
    fips: '38027',
    label: 'Eddy',
  },
  {
    fips: '38029',
    label: 'Emmons',
  },
  {
    fips: '38031',
    label: 'Foster',
  },
  {
    fips: '38033',
    label: 'Golden Valley',
  },
  {
    fips: '38035',
    label: 'Grand Forks',
  },
  {
    fips: '38037',
    label: 'Grant',
  },
  {
    fips: '38039',
    label: 'Griggs',
  },
  {
    fips: '38041',
    label: 'Hettinger',
  },
  {
    fips: '38043',
    label: 'Kidder',
  },
  {
    fips: '38045',
    label: 'LaMoure',
  },
  {
    fips: '38047',
    label: 'Logan',
  },
  {
    fips: '38049',
    label: 'McHenry',
  },
  {
    fips: '38051',
    label: 'McIntosh',
  },
  {
    fips: '38053',
    label: 'McKenzie',
  },
  {
    fips: '38055',
    label: 'McLean',
  },
  {
    fips: '38057',
    label: 'Mercer',
  },
  {
    fips: '38059',
    label: 'Morton',
  },
  {
    fips: '38061',
    label: 'Mountrail',
  },
  {
    fips: '38063',
    label: 'Nelson',
  },
  {
    fips: '38065',
    label: 'Oliver',
  },
  {
    fips: '38067',
    label: 'Pembina',
  },
  {
    fips: '38069',
    label: 'Pierce',
  },
  {
    fips: '38071',
    label: 'Ramsey',
  },
  {
    fips: '38073',
    label: 'Ransom',
  },
  {
    fips: '38075',
    label: 'Renville',
  },
  {
    fips: '38077',
    label: 'Richland',
  },
  {
    fips: '38079',
    label: 'Rolette',
  },
  {
    fips: '38081',
    label: 'Sargent',
  },
  {
    fips: '38083',
    label: 'Sheridan',
  },
  {
    fips: '38085',
    label: 'Sioux',
  },
  {
    fips: '38087',
    label: 'Slope',
  },
  {
    fips: '38089',
    label: 'Stark',
  },
  {
    fips: '38091',
    label: 'Steele',
  },
  {
    fips: '38093',
    label: 'Stutsman',
  },
  {
    fips: '38095',
    label: 'Towner',
  },
  {
    fips: '38097',
    label: 'Traill',
  },
  {
    fips: '38099',
    label: 'Walsh',
  },
  {
    fips: '38101',
    label: 'Ward',
  },
  {
    fips: '38103',
    label: 'Wells',
  },
  {
    fips: '38105',
    label: 'Williams',
  },
  {
    fips: '39001',
    label: 'Adams',
  },
  {
    fips: '39003',
    label: 'Allen',
  },
  {
    fips: '39005',
    label: 'Ashland',
  },
  {
    fips: '39007',
    label: 'Ashtabula',
  },
  {
    fips: '39009',
    label: 'Athens',
  },
  {
    fips: '39011',
    label: 'Auglaize',
  },
  {
    fips: '39013',
    label: 'Belmont',
  },
  {
    fips: '39015',
    label: 'Brown',
  },
  {
    fips: '39017',
    label: 'Butler',
  },
  {
    fips: '39019',
    label: 'Carroll',
  },
  {
    fips: '39021',
    label: 'Champaign',
  },
  {
    fips: '39023',
    label: 'Clark',
  },
  {
    fips: '39025',
    label: 'Clermont',
  },
  {
    fips: '39027',
    label: 'Clinton',
  },
  {
    fips: '39029',
    label: 'Columbiana',
  },
  {
    fips: '39031',
    label: 'Coshocton',
  },
  {
    fips: '39033',
    label: 'Crawford',
  },
  {
    fips: '39035',
    label: 'Cuyahoga',
  },
  {
    fips: '39037',
    label: 'Darke',
  },
  {
    fips: '39039',
    label: 'Defiance',
  },
  {
    fips: '39041',
    label: 'Delaware',
  },
  {
    fips: '39043',
    label: 'Erie',
  },
  {
    fips: '39045',
    label: 'Fairfield',
  },
  {
    fips: '39047',
    label: 'Fayette',
  },
  {
    fips: '39049',
    label: 'Franklin',
  },
  {
    fips: '39051',
    label: 'Fulton',
  },
  {
    fips: '39053',
    label: 'Gallia',
  },
  {
    fips: '39055',
    label: 'Geauga',
  },
  {
    fips: '39057',
    label: 'Greene',
  },
  {
    fips: '39059',
    label: 'Guernsey',
  },
  {
    fips: '39061',
    label: 'Hamilton',
  },
  {
    fips: '39063',
    label: 'Hancock',
  },
  {
    fips: '39065',
    label: 'Hardin',
  },
  {
    fips: '39067',
    label: 'Harrison',
  },
  {
    fips: '39069',
    label: 'Henry',
  },
  {
    fips: '39071',
    label: 'Highland',
  },
  {
    fips: '39073',
    label: 'Hocking',
  },
  {
    fips: '39075',
    label: 'Holmes',
  },
  {
    fips: '39077',
    label: 'Huron',
  },
  {
    fips: '39079',
    label: 'Jackson',
  },
  {
    fips: '39081',
    label: 'Jefferson',
  },
  {
    fips: '39083',
    label: 'Knox',
  },
  {
    fips: '39085',
    label: 'Lake',
  },
  {
    fips: '39087',
    label: 'Lawrence',
  },
  {
    fips: '39089',
    label: 'Licking',
  },
  {
    fips: '39091',
    label: 'Logan',
  },
  {
    fips: '39093',
    label: 'Lorain',
  },
  {
    fips: '39095',
    label: 'Lucas',
  },
  {
    fips: '39097',
    label: 'Madison',
  },
  {
    fips: '39099',
    label: 'Mahoning',
  },
  {
    fips: '39101',
    label: 'Marion',
  },
  {
    fips: '39103',
    label: 'Medina',
  },
  {
    fips: '39105',
    label: 'Meigs',
  },
  {
    fips: '39107',
    label: 'Mercer',
  },
  {
    fips: '39109',
    label: 'Miami',
  },
  {
    fips: '39111',
    label: 'Monroe',
  },
  {
    fips: '39113',
    label: 'Montgomery',
  },
  {
    fips: '39115',
    label: 'Morgan',
  },
  {
    fips: '39117',
    label: 'Morrow',
  },
  {
    fips: '39119',
    label: 'Muskingum',
  },
  {
    fips: '39121',
    label: 'Noble',
  },
  {
    fips: '39123',
    label: 'Ottawa',
  },
  {
    fips: '39125',
    label: 'Paulding',
  },
  {
    fips: '39127',
    label: 'Perry',
  },
  {
    fips: '39129',
    label: 'Pickaway',
  },
  {
    fips: '39131',
    label: 'Pike',
  },
  {
    fips: '39133',
    label: 'Portage',
  },
  {
    fips: '39135',
    label: 'Preble',
  },
  {
    fips: '39137',
    label: 'Putnam',
  },
  {
    fips: '39139',
    label: 'Richland',
  },
  {
    fips: '39141',
    label: 'Ross',
  },
  {
    fips: '39143',
    label: 'Sandusky',
  },
  {
    fips: '39145',
    label: 'Scioto',
  },
  {
    fips: '39147',
    label: 'Seneca',
  },
  {
    fips: '39149',
    label: 'Shelby',
  },
  {
    fips: '39151',
    label: 'Stark',
  },
  {
    fips: '39153',
    label: 'Summit',
  },
  {
    fips: '39155',
    label: 'Trumbull',
  },
  {
    fips: '39157',
    label: 'Tuscarawas',
  },
  {
    fips: '39159',
    label: 'Union',
  },
  {
    fips: '39161',
    label: 'Van Wert',
  },
  {
    fips: '39163',
    label: 'Vinton',
  },
  {
    fips: '39165',
    label: 'Warren',
  },
  {
    fips: '39167',
    label: 'Washington',
  },
  {
    fips: '39169',
    label: 'Wayne',
  },
  {
    fips: '39171',
    label: 'Williams',
  },
  {
    fips: '39173',
    label: 'Wood',
  },
  {
    fips: '39175',
    label: 'Wyandot',
  },
  {
    fips: '40001',
    label: 'Adair',
  },
  {
    fips: '40003',
    label: 'Alfalfa',
  },
  {
    fips: '40005',
    label: 'Atoka',
  },
  {
    fips: '40007',
    label: 'Beaver',
  },
  {
    fips: '40009',
    label: 'Beckham',
  },
  {
    fips: '40011',
    label: 'Blaine',
  },
  {
    fips: '40013',
    label: 'Bryan',
  },
  {
    fips: '40015',
    label: 'Caddo',
  },
  {
    fips: '40017',
    label: 'Canadian',
  },
  {
    fips: '40019',
    label: 'Carter',
  },
  {
    fips: '40021',
    label: 'Cherokee',
  },
  {
    fips: '40023',
    label: 'Choctaw',
  },
  {
    fips: '40025',
    label: 'Cimarron',
  },
  {
    fips: '40027',
    label: 'Cleveland',
  },
  {
    fips: '40029',
    label: 'Coal',
  },
  {
    fips: '40031',
    label: 'Comanche',
  },
  {
    fips: '40033',
    label: 'Cotton',
  },
  {
    fips: '40035',
    label: 'Craig',
  },
  {
    fips: '40037',
    label: 'Creek',
  },
  {
    fips: '40039',
    label: 'Custer',
  },
  {
    fips: '40041',
    label: 'Delaware',
  },
  {
    fips: '40043',
    label: 'Dewey',
  },
  {
    fips: '40045',
    label: 'Ellis',
  },
  {
    fips: '40047',
    label: 'Garfield',
  },
  {
    fips: '40049',
    label: 'Garvin',
  },
  {
    fips: '40051',
    label: 'Grady',
  },
  {
    fips: '40053',
    label: 'Grant',
  },
  {
    fips: '40055',
    label: 'Greer',
  },
  {
    fips: '40057',
    label: 'Harmon',
  },
  {
    fips: '40059',
    label: 'Harper',
  },
  {
    fips: '40061',
    label: 'Haskell',
  },
  {
    fips: '40063',
    label: 'Hughes',
  },
  {
    fips: '40065',
    label: 'Jackson',
  },
  {
    fips: '40067',
    label: 'Jefferson',
  },
  {
    fips: '40069',
    label: 'Johnston',
  },
  {
    fips: '40071',
    label: 'Kay',
  },
  {
    fips: '40073',
    label: 'Kingfisher',
  },
  {
    fips: '40075',
    label: 'Kiowa',
  },
  {
    fips: '40077',
    label: 'Latimer',
  },
  {
    fips: '40079',
    label: 'Le Flore',
  },
  {
    fips: '40081',
    label: 'Lincoln',
  },
  {
    fips: '40083',
    label: 'Logan',
  },
  {
    fips: '40085',
    label: 'Love',
  },
  {
    fips: '40087',
    label: 'McClain',
  },
  {
    fips: '40089',
    label: 'McCurtain',
  },
  {
    fips: '40091',
    label: 'McIntosh',
  },
  {
    fips: '40093',
    label: 'Major',
  },
  {
    fips: '40095',
    label: 'Marshall',
  },
  {
    fips: '40097',
    label: 'Mayes',
  },
  {
    fips: '40099',
    label: 'Murray',
  },
  {
    fips: '40101',
    label: 'Muskogee',
  },
  {
    fips: '40103',
    label: 'Noble',
  },
  {
    fips: '40105',
    label: 'Nowata',
  },
  {
    fips: '40107',
    label: 'Okfuskee',
  },
  {
    fips: '40109',
    label: 'Oklahoma',
  },
  {
    fips: '40111',
    label: 'Okmulgee',
  },
  {
    fips: '40113',
    label: 'Osage',
  },
  {
    fips: '40115',
    label: 'Ottawa',
  },
  {
    fips: '40117',
    label: 'Pawnee',
  },
  {
    fips: '40119',
    label: 'Payne',
  },
  {
    fips: '40121',
    label: 'Pittsburg',
  },
  {
    fips: '40123',
    label: 'Pontotoc',
  },
  {
    fips: '40125',
    label: 'Pottawatomie',
  },
  {
    fips: '40127',
    label: 'Pushmataha',
  },
  {
    fips: '40129',
    label: 'Roger Mills',
  },
  {
    fips: '40131',
    label: 'Rogers',
  },
  {
    fips: '40133',
    label: 'Seminole',
  },
  {
    fips: '40135',
    label: 'Sequoyah',
  },
  {
    fips: '40137',
    label: 'Stephens',
  },
  {
    fips: '40139',
    label: 'Texas',
  },
  {
    fips: '40141',
    label: 'Tillman',
  },
  {
    fips: '40143',
    label: 'Tulsa',
  },
  {
    fips: '40145',
    label: 'Wagoner',
  },
  {
    fips: '40147',
    label: 'Washington',
  },
  {
    fips: '40149',
    label: 'Washita',
  },
  {
    fips: '40151',
    label: 'Woods',
  },
  {
    fips: '40153',
    label: 'Woodward',
  },
  {
    fips: '41001',
    label: 'Baker',
  },
  {
    fips: '41003',
    label: 'Benton',
  },
  {
    fips: '41005',
    label: 'Clackamas',
  },
  {
    fips: '41007',
    label: 'Clatsop',
  },
  {
    fips: '41009',
    label: 'Columbia',
  },
  {
    fips: '41011',
    label: 'Coos',
  },
  {
    fips: '41013',
    label: 'Crook',
  },
  {
    fips: '41015',
    label: 'Curry',
  },
  {
    fips: '41017',
    label: 'Deschutes',
  },
  {
    fips: '41019',
    label: 'Douglas',
  },
  {
    fips: '41021',
    label: 'Gilliam',
  },
  {
    fips: '41023',
    label: 'Grant',
  },
  {
    fips: '41025',
    label: 'Harney',
  },
  {
    fips: '41027',
    label: 'Hood River',
  },
  {
    fips: '41029',
    label: 'Jackson',
  },
  {
    fips: '41031',
    label: 'Jefferson',
  },
  {
    fips: '41033',
    label: 'Josephine',
  },
  {
    fips: '41035',
    label: 'Klamath',
  },
  {
    fips: '41037',
    label: 'Lake',
  },
  {
    fips: '41039',
    label: 'Lane',
  },
  {
    fips: '41041',
    label: 'Lincoln',
  },
  {
    fips: '41043',
    label: 'Linn',
  },
  {
    fips: '41045',
    label: 'Malheur',
  },
  {
    fips: '41047',
    label: 'Marion',
  },
  {
    fips: '41049',
    label: 'Morrow',
  },
  {
    fips: '41051',
    label: 'Multnomah',
  },
  {
    fips: '41053',
    label: 'Polk',
  },
  {
    fips: '41055',
    label: 'Sherman',
  },
  {
    fips: '41057',
    label: 'Tillamook',
  },
  {
    fips: '41059',
    label: 'Umatilla',
  },
  {
    fips: '41061',
    label: 'Union',
  },
  {
    fips: '41063',
    label: 'Wallowa',
  },
  {
    fips: '41065',
    label: 'Wasco',
  },
  {
    fips: '41067',
    label: 'Washington',
  },
  {
    fips: '41069',
    label: 'Wheeler',
  },
  {
    fips: '41071',
    label: 'Yamhill',
  },
  {
    fips: '42001',
    label: 'Adams',
  },
  {
    fips: '42003',
    label: 'Allegheny',
  },
  {
    fips: '42005',
    label: 'Armstrong',
  },
  {
    fips: '42007',
    label: 'Beaver',
  },
  {
    fips: '42009',
    label: 'Bedford',
  },
  {
    fips: '42011',
    label: 'Berks',
  },
  {
    fips: '42013',
    label: 'Blair',
  },
  {
    fips: '42015',
    label: 'Bradford',
  },
  {
    fips: '42017',
    label: 'Bucks',
  },
  {
    fips: '42019',
    label: 'Butler',
  },
  {
    fips: '42021',
    label: 'Cambria',
  },
  {
    fips: '42023',
    label: 'Cameron',
  },
  {
    fips: '42025',
    label: 'Carbon',
  },
  {
    fips: '42027',
    label: 'Centre',
  },
  {
    fips: '42029',
    label: 'Chester',
  },
  {
    fips: '42031',
    label: 'Clarion',
  },
  {
    fips: '42033',
    label: 'Clearfield',
  },
  {
    fips: '42035',
    label: 'Clinton',
  },
  {
    fips: '42037',
    label: 'Columbia',
  },
  {
    fips: '42039',
    label: 'Crawford',
  },
  {
    fips: '42041',
    label: 'Cumberland',
  },
  {
    fips: '42043',
    label: 'Dauphin',
  },
  {
    fips: '42045',
    label: 'Delaware',
  },
  {
    fips: '42047',
    label: 'Elk',
  },
  {
    fips: '42049',
    label: 'Erie',
  },
  {
    fips: '42051',
    label: 'Fayette',
  },
  {
    fips: '42053',
    label: 'Forest',
  },
  {
    fips: '42055',
    label: 'Franklin',
  },
  {
    fips: '42057',
    label: 'Fulton',
  },
  {
    fips: '42059',
    label: 'Greene',
  },
  {
    fips: '42061',
    label: 'Huntingdon',
  },
  {
    fips: '42063',
    label: 'Indiana',
  },
  {
    fips: '42065',
    label: 'Jefferson',
  },
  {
    fips: '42067',
    label: 'Juniata',
  },
  {
    fips: '42069',
    label: 'Lackawanna',
  },
  {
    fips: '42071',
    label: 'Lancaster',
  },
  {
    fips: '42073',
    label: 'Lawrence',
  },
  {
    fips: '42075',
    label: 'Lebanon',
  },
  {
    fips: '42077',
    label: 'Lehigh',
  },
  {
    fips: '42079',
    label: 'Luzerne',
  },
  {
    fips: '42081',
    label: 'Lycoming',
  },
  {
    fips: '42083',
    label: 'McKean',
  },
  {
    fips: '42085',
    label: 'Mercer',
  },
  {
    fips: '42087',
    label: 'Mifflin',
  },
  {
    fips: '42089',
    label: 'Monroe',
  },
  {
    fips: '42091',
    label: 'Montgomery',
  },
  {
    fips: '42093',
    label: 'Montour',
  },
  {
    fips: '42095',
    label: 'Northampton',
  },
  {
    fips: '42097',
    label: 'Northumberland',
  },
  {
    fips: '42099',
    label: 'Perry',
  },
  {
    fips: '42101',
    label: 'Philadelphia',
  },
  {
    fips: '42103',
    label: 'Pike',
  },
  {
    fips: '42105',
    label: 'Potter',
  },
  {
    fips: '42107',
    label: 'Schuylkill',
  },
  {
    fips: '42109',
    label: 'Snyder',
  },
  {
    fips: '42111',
    label: 'Somerset',
  },
  {
    fips: '42113',
    label: 'Sullivan',
  },
  {
    fips: '42115',
    label: 'Susquehanna',
  },
  {
    fips: '42117',
    label: 'Tioga',
  },
  {
    fips: '42119',
    label: 'Union',
  },
  {
    fips: '42121',
    label: 'Venango',
  },
  {
    fips: '42123',
    label: 'Warren',
  },
  {
    fips: '42125',
    label: 'Washington',
  },
  {
    fips: '42127',
    label: 'Wayne',
  },
  {
    fips: '42129',
    label: 'Westmoreland',
  },
  {
    fips: '42131',
    label: 'Wyoming',
  },
  {
    fips: '42133',
    label: 'York',
  },
  {
    fips: '44001',
    label: 'Bristol',
  },
  {
    fips: '44003',
    label: 'Kent',
  },
  {
    fips: '44005',
    label: 'Newport',
  },
  {
    fips: '44007',
    label: 'Providence',
  },
  {
    fips: '44009',
    label: 'Washington',
  },
  {
    fips: '45001',
    label: 'Abbeville',
  },
  {
    fips: '45003',
    label: 'Aiken',
  },
  {
    fips: '45005',
    label: 'Allendale',
  },
  {
    fips: '45007',
    label: 'Anderson',
  },
  {
    fips: '45009',
    label: 'Bamberg',
  },
  {
    fips: '45011',
    label: 'Barnwell',
  },
  {
    fips: '45013',
    label: 'Beaufort',
  },
  {
    fips: '45015',
    label: 'Berkeley',
  },
  {
    fips: '45017',
    label: 'Calhoun',
  },
  {
    fips: '45019',
    label: 'Charleston',
  },
  {
    fips: '45021',
    label: 'Cherokee',
  },
  {
    fips: '45023',
    label: 'Chester',
  },
  {
    fips: '45025',
    label: 'Chesterfield',
  },
  {
    fips: '45027',
    label: 'Clarendon',
  },
  {
    fips: '45029',
    label: 'Colleton',
  },
  {
    fips: '45031',
    label: 'Darlington',
  },
  {
    fips: '45033',
    label: 'Dillon',
  },
  {
    fips: '45035',
    label: 'Dorchester',
  },
  {
    fips: '45037',
    label: 'Edgefield',
  },
  {
    fips: '45039',
    label: 'Fairfield',
  },
  {
    fips: '45041',
    label: 'Florence',
  },
  {
    fips: '45043',
    label: 'Georgetown',
  },
  {
    fips: '45045',
    label: 'Greenville',
  },
  {
    fips: '45047',
    label: 'Greenwood',
  },
  {
    fips: '45049',
    label: 'Hampton',
  },
  {
    fips: '45051',
    label: 'Horry',
  },
  {
    fips: '45053',
    label: 'Jasper',
  },
  {
    fips: '45055',
    label: 'Kershaw',
  },
  {
    fips: '45057',
    label: 'Lancaster',
  },
  {
    fips: '45059',
    label: 'Laurens',
  },
  {
    fips: '45061',
    label: 'Lee',
  },
  {
    fips: '45063',
    label: 'Lexington',
  },
  {
    fips: '45065',
    label: 'McCormick',
  },
  {
    fips: '45067',
    label: 'Marion',
  },
  {
    fips: '45069',
    label: 'Marlboro',
  },
  {
    fips: '45071',
    label: 'Newberry',
  },
  {
    fips: '45073',
    label: 'Oconee',
  },
  {
    fips: '45075',
    label: 'Orangeburg',
  },
  {
    fips: '45077',
    label: 'Pickens',
  },
  {
    fips: '45079',
    label: 'Richland',
  },
  {
    fips: '45081',
    label: 'Saluda',
  },
  {
    fips: '45083',
    label: 'Spartanburg',
  },
  {
    fips: '45085',
    label: 'Sumter',
  },
  {
    fips: '45087',
    label: 'Union',
  },
  {
    fips: '45089',
    label: 'Williamsburg',
  },
  {
    fips: '45091',
    label: 'York',
  },
  {
    fips: '46003',
    label: 'Aurora',
  },
  {
    fips: '46005',
    label: 'Beadle',
  },
  {
    fips: '46007',
    label: 'Bennett',
  },
  {
    fips: '46009',
    label: 'Bon Homme',
  },
  {
    fips: '46011',
    label: 'Brookings',
  },
  {
    fips: '46013',
    label: 'Brown',
  },
  {
    fips: '46015',
    label: 'Brule',
  },
  {
    fips: '46017',
    label: 'Buffalo',
  },
  {
    fips: '46019',
    label: 'Butte',
  },
  {
    fips: '46021',
    label: 'Campbell',
  },
  {
    fips: '46023',
    label: 'Charles Mix',
  },
  {
    fips: '46025',
    label: 'Clark',
  },
  {
    fips: '46027',
    label: 'Clay',
  },
  {
    fips: '46029',
    label: 'Codington',
  },
  {
    fips: '46031',
    label: 'Corson',
  },
  {
    fips: '46033',
    label: 'Custer',
  },
  {
    fips: '46035',
    label: 'Davison',
  },
  {
    fips: '46037',
    label: 'Day',
  },
  {
    fips: '46039',
    label: 'Deuel',
  },
  {
    fips: '46041',
    label: 'Dewey',
  },
  {
    fips: '46043',
    label: 'Douglas',
  },
  {
    fips: '46045',
    label: 'Edmunds',
  },
  {
    fips: '46047',
    label: 'Fall River',
  },
  {
    fips: '46049',
    label: 'Faulk',
  },
  {
    fips: '46051',
    label: 'Grant',
  },
  {
    fips: '46053',
    label: 'Gregory',
  },
  {
    fips: '46055',
    label: 'Haakon',
  },
  {
    fips: '46057',
    label: 'Hamlin',
  },
  {
    fips: '46059',
    label: 'Hand',
  },
  {
    fips: '46061',
    label: 'Hanson',
  },
  {
    fips: '46063',
    label: 'Harding',
  },
  {
    fips: '46065',
    label: 'Hughes',
  },
  {
    fips: '46067',
    label: 'Hutchinson',
  },
  {
    fips: '46069',
    label: 'Hyde',
  },
  {
    fips: '46071',
    label: 'Jackson',
  },
  {
    fips: '46073',
    label: 'Jerauld',
  },
  {
    fips: '46075',
    label: 'Jones',
  },
  {
    fips: '46077',
    label: 'Kingsbury',
  },
  {
    fips: '46079',
    label: 'Lake',
  },
  {
    fips: '46081',
    label: 'Lawrence',
  },
  {
    fips: '46083',
    label: 'Lincoln',
  },
  {
    fips: '46085',
    label: 'Lyman',
  },
  {
    fips: '46087',
    label: 'McCook',
  },
  {
    fips: '46089',
    label: 'McPherson',
  },
  {
    fips: '46091',
    label: 'Marshall',
  },
  {
    fips: '46093',
    label: 'Meade',
  },
  {
    fips: '46095',
    label: 'Mellette',
  },
  {
    fips: '46097',
    label: 'Miner',
  },
  {
    fips: '46099',
    label: 'Minnehaha',
  },
  {
    fips: '46101',
    label: 'Moody',
  },
  {
    fips: '46102',
    label: 'Oglala Lakota',
  },
  {
    fips: '46103',
    label: 'Pennington',
  },
  {
    fips: '46105',
    label: 'Perkins',
  },
  {
    fips: '46107',
    label: 'Potter',
  },
  {
    fips: '46109',
    label: 'Roberts',
  },
  {
    fips: '46111',
    label: 'Sanborn',
  },
  {
    fips: '46115',
    label: 'Spink',
  },
  {
    fips: '46117',
    label: 'Stanley',
  },
  {
    fips: '46119',
    label: 'Sully',
  },
  {
    fips: '46121',
    label: 'Todd',
  },
  {
    fips: '46123',
    label: 'Tripp',
  },
  {
    fips: '46125',
    label: 'Turner',
  },
  {
    fips: '46127',
    label: 'Union',
  },
  {
    fips: '46129',
    label: 'Walworth',
  },
  {
    fips: '46135',
    label: 'Yankton',
  },
  {
    fips: '46137',
    label: 'Ziebach',
  },
  {
    fips: '47001',
    label: 'Anderson',
  },
  {
    fips: '47003',
    label: 'Bedford',
  },
  {
    fips: '47005',
    label: 'Benton',
  },
  {
    fips: '47007',
    label: 'Bledsoe',
  },
  {
    fips: '47009',
    label: 'Blount',
  },
  {
    fips: '47011',
    label: 'Bradley',
  },
  {
    fips: '47013',
    label: 'Campbell',
  },
  {
    fips: '47015',
    label: 'Cannon',
  },
  {
    fips: '47017',
    label: 'Carroll',
  },
  {
    fips: '47019',
    label: 'Carter',
  },
  {
    fips: '47021',
    label: 'Cheatham',
  },
  {
    fips: '47023',
    label: 'Chester',
  },
  {
    fips: '47025',
    label: 'Claiborne',
  },
  {
    fips: '47027',
    label: 'Clay',
  },
  {
    fips: '47029',
    label: 'Cocke',
  },
  {
    fips: '47031',
    label: 'Coffee',
  },
  {
    fips: '47033',
    label: 'Crockett',
  },
  {
    fips: '47035',
    label: 'Cumberland',
  },
  {
    fips: '47037',
    label: 'Davidson',
  },
  {
    fips: '47039',
    label: 'Decatur',
  },
  {
    fips: '47041',
    label: 'DeKalb',
  },
  {
    fips: '47043',
    label: 'Dickson',
  },
  {
    fips: '47045',
    label: 'Dyer',
  },
  {
    fips: '47047',
    label: 'Fayette',
  },
  {
    fips: '47049',
    label: 'Fentress',
  },
  {
    fips: '47051',
    label: 'Franklin',
  },
  {
    fips: '47053',
    label: 'Gibson',
  },
  {
    fips: '47055',
    label: 'Giles',
  },
  {
    fips: '47057',
    label: 'Grainger',
  },
  {
    fips: '47059',
    label: 'Greene',
  },
  {
    fips: '47061',
    label: 'Grundy',
  },
  {
    fips: '47063',
    label: 'Hamblen',
  },
  {
    fips: '47065',
    label: 'Hamilton',
  },
  {
    fips: '47067',
    label: 'Hancock',
  },
  {
    fips: '47069',
    label: 'Hardeman',
  },
  {
    fips: '47071',
    label: 'Hardin',
  },
  {
    fips: '47073',
    label: 'Hawkins',
  },
  {
    fips: '47075',
    label: 'Haywood',
  },
  {
    fips: '47077',
    label: 'Henderson',
  },
  {
    fips: '47079',
    label: 'Henry',
  },
  {
    fips: '47081',
    label: 'Hickman',
  },
  {
    fips: '47083',
    label: 'Houston',
  },
  {
    fips: '47085',
    label: 'Humphreys',
  },
  {
    fips: '47087',
    label: 'Jackson',
  },
  {
    fips: '47089',
    label: 'Jefferson',
  },
  {
    fips: '47091',
    label: 'Johnson',
  },
  {
    fips: '47093',
    label: 'Knox',
  },
  {
    fips: '47095',
    label: 'Lake',
  },
  {
    fips: '47097',
    label: 'Lauderdale',
  },
  {
    fips: '47099',
    label: 'Lawrence',
  },
  {
    fips: '47101',
    label: 'Lewis',
  },
  {
    fips: '47103',
    label: 'Lincoln',
  },
  {
    fips: '47105',
    label: 'Loudon',
  },
  {
    fips: '47107',
    label: 'McMinn',
  },
  {
    fips: '47109',
    label: 'McNairy',
  },
  {
    fips: '47111',
    label: 'Macon',
  },
  {
    fips: '47113',
    label: 'Madison',
  },
  {
    fips: '47115',
    label: 'Marion',
  },
  {
    fips: '47117',
    label: 'Marshall',
  },
  {
    fips: '47119',
    label: 'Maury',
  },
  {
    fips: '47121',
    label: 'Meigs',
  },
  {
    fips: '47123',
    label: 'Monroe',
  },
  {
    fips: '47125',
    label: 'Montgomery',
  },
  {
    fips: '47127',
    label: 'Moore',
  },
  {
    fips: '47129',
    label: 'Morgan',
  },
  {
    fips: '47131',
    label: 'Obion',
  },
  {
    fips: '47133',
    label: 'Overton',
  },
  {
    fips: '47135',
    label: 'Perry',
  },
  {
    fips: '47137',
    label: 'Pickett',
  },
  {
    fips: '47139',
    label: 'Polk',
  },
  {
    fips: '47141',
    label: 'Putnam',
  },
  {
    fips: '47143',
    label: 'Rhea',
  },
  {
    fips: '47145',
    label: 'Roane',
  },
  {
    fips: '47147',
    label: 'Robertson',
  },
  {
    fips: '47149',
    label: 'Rutherford',
  },
  {
    fips: '47151',
    label: 'Scott',
  },
  {
    fips: '47153',
    label: 'Sequatchie',
  },
  {
    fips: '47155',
    label: 'Sevier',
  },
  {
    fips: '47157',
    label: 'Shelby',
  },
  {
    fips: '47159',
    label: 'Smith',
  },
  {
    fips: '47161',
    label: 'Stewart',
  },
  {
    fips: '47163',
    label: 'Sullivan',
  },
  {
    fips: '47165',
    label: 'Sumner',
  },
  {
    fips: '47167',
    label: 'Tipton',
  },
  {
    fips: '47169',
    label: 'Trousdale',
  },
  {
    fips: '47171',
    label: 'Unicoi',
  },
  {
    fips: '47173',
    label: 'Union',
  },
  {
    fips: '47175',
    label: 'Van Buren',
  },
  {
    fips: '47177',
    label: 'Warren',
  },
  {
    fips: '47179',
    label: 'Washington',
  },
  {
    fips: '47181',
    label: 'Wayne',
  },
  {
    fips: '47183',
    label: 'Weakley',
  },
  {
    fips: '47185',
    label: 'White',
  },
  {
    fips: '47187',
    label: 'Williamson',
  },
  {
    fips: '47189',
    label: 'Wilson',
  },
  {
    fips: '48001',
    label: 'Anderson',
  },
  {
    fips: '48003',
    label: 'Andrews',
  },
  {
    fips: '48005',
    label: 'Angelina',
  },
  {
    fips: '48007',
    label: 'Aransas',
  },
  {
    fips: '48009',
    label: 'Archer',
  },
  {
    fips: '48011',
    label: 'Armstrong',
  },
  {
    fips: '48013',
    label: 'Atascosa',
  },
  {
    fips: '48015',
    label: 'Austin',
  },
  {
    fips: '48017',
    label: 'Bailey',
  },
  {
    fips: '48019',
    label: 'Bandera',
  },
  {
    fips: '48021',
    label: 'Bastrop',
  },
  {
    fips: '48023',
    label: 'Baylor',
  },
  {
    fips: '48025',
    label: 'Bee',
  },
  {
    fips: '48027',
    label: 'Bell',
  },
  {
    fips: '48029',
    label: 'Bexar',
  },
  {
    fips: '48031',
    label: 'Blanco',
  },
  {
    fips: '48033',
    label: 'Borden',
  },
  {
    fips: '48035',
    label: 'Bosque',
  },
  {
    fips: '48037',
    label: 'Bowie',
  },
  {
    fips: '48039',
    label: 'Brazoria',
  },
  {
    fips: '48041',
    label: 'Brazos',
  },
  {
    fips: '48043',
    label: 'Brewster',
  },
  {
    fips: '48045',
    label: 'Briscoe',
  },
  {
    fips: '48047',
    label: 'Brooks',
  },
  {
    fips: '48049',
    label: 'Brown',
  },
  {
    fips: '48051',
    label: 'Burleson',
  },
  {
    fips: '48053',
    label: 'Burnet',
  },
  {
    fips: '48055',
    label: 'Caldwell',
  },
  {
    fips: '48057',
    label: 'Calhoun',
  },
  {
    fips: '48059',
    label: 'Callahan',
  },
  {
    fips: '48061',
    label: 'Cameron',
  },
  {
    fips: '48063',
    label: 'Camp',
  },
  {
    fips: '48065',
    label: 'Carson',
  },
  {
    fips: '48067',
    label: 'Cass',
  },
  {
    fips: '48069',
    label: 'Castro',
  },
  {
    fips: '48071',
    label: 'Chambers',
  },
  {
    fips: '48073',
    label: 'Cherokee',
  },
  {
    fips: '48075',
    label: 'Childress',
  },
  {
    fips: '48077',
    label: 'Clay',
  },
  {
    fips: '48079',
    label: 'Cochran',
  },
  {
    fips: '48081',
    label: 'Coke',
  },
  {
    fips: '48083',
    label: 'Coleman',
  },
  {
    fips: '48085',
    label: 'Collin',
  },
  {
    fips: '48087',
    label: 'Collingsworth',
  },
  {
    fips: '48089',
    label: 'Colorado',
  },
  {
    fips: '48091',
    label: 'Comal',
  },
  {
    fips: '48093',
    label: 'Comanche',
  },
  {
    fips: '48095',
    label: 'Concho',
  },
  {
    fips: '48097',
    label: 'Cooke',
  },
  {
    fips: '48099',
    label: 'Coryell',
  },
  {
    fips: '48101',
    label: 'Cottle',
  },
  {
    fips: '48103',
    label: 'Crane',
  },
  {
    fips: '48105',
    label: 'Crockett',
  },
  {
    fips: '48107',
    label: 'Crosby',
  },
  {
    fips: '48109',
    label: 'Culberson',
  },
  {
    fips: '48111',
    label: 'Dallam',
  },
  {
    fips: '48113',
    label: 'Dallas',
  },
  {
    fips: '48115',
    label: 'Dawson',
  },
  {
    fips: '48117',
    label: 'Deaf Smith',
  },
  {
    fips: '48119',
    label: 'Delta',
  },
  {
    fips: '48121',
    label: 'Denton',
  },
  {
    fips: '48123',
    label: 'DeWitt',
  },
  {
    fips: '48125',
    label: 'Dickens',
  },
  {
    fips: '48127',
    label: 'Dimmit',
  },
  {
    fips: '48129',
    label: 'Donley',
  },
  {
    fips: '48131',
    label: 'Duval',
  },
  {
    fips: '48133',
    label: 'Eastland',
  },
  {
    fips: '48135',
    label: 'Ector',
  },
  {
    fips: '48137',
    label: 'Edwards',
  },
  {
    fips: '48139',
    label: 'Ellis',
  },
  {
    fips: '48141',
    label: 'El Paso',
  },
  {
    fips: '48143',
    label: 'Erath',
  },
  {
    fips: '48145',
    label: 'Falls',
  },
  {
    fips: '48147',
    label: 'Fannin',
  },
  {
    fips: '48149',
    label: 'Fayette',
  },
  {
    fips: '48151',
    label: 'Fisher',
  },
  {
    fips: '48153',
    label: 'Floyd',
  },
  {
    fips: '48155',
    label: 'Foard',
  },
  {
    fips: '48157',
    label: 'Fort Bend',
  },
  {
    fips: '48159',
    label: 'Franklin',
  },
  {
    fips: '48161',
    label: 'Freestone',
  },
  {
    fips: '48163',
    label: 'Frio',
  },
  {
    fips: '48165',
    label: 'Gaines',
  },
  {
    fips: '48167',
    label: 'Galveston',
  },
  {
    fips: '48169',
    label: 'Garza',
  },
  {
    fips: '48171',
    label: 'Gillespie',
  },
  {
    fips: '48173',
    label: 'Glasscock',
  },
  {
    fips: '48175',
    label: 'Goliad',
  },
  {
    fips: '48177',
    label: 'Gonzales',
  },
  {
    fips: '48179',
    label: 'Gray',
  },
  {
    fips: '48181',
    label: 'Grayson',
  },
  {
    fips: '48183',
    label: 'Gregg',
  },
  {
    fips: '48185',
    label: 'Grimes',
  },
  {
    fips: '48187',
    label: 'Guadalupe',
  },
  {
    fips: '48189',
    label: 'Hale',
  },
  {
    fips: '48191',
    label: 'Hall',
  },
  {
    fips: '48193',
    label: 'Hamilton',
  },
  {
    fips: '48195',
    label: 'Hansford',
  },
  {
    fips: '48197',
    label: 'Hardeman',
  },
  {
    fips: '48199',
    label: 'Hardin',
  },
  {
    fips: '48201',
    label: 'Harris',
  },
  {
    fips: '48203',
    label: 'Harrison',
  },
  {
    fips: '48205',
    label: 'Hartley',
  },
  {
    fips: '48207',
    label: 'Haskell',
  },
  {
    fips: '48209',
    label: 'Hays',
  },
  {
    fips: '48211',
    label: 'Hemphill',
  },
  {
    fips: '48213',
    label: 'Henderson',
  },
  {
    fips: '48215',
    label: 'Hidalgo',
  },
  {
    fips: '48217',
    label: 'Hill',
  },
  {
    fips: '48219',
    label: 'Hockley',
  },
  {
    fips: '48221',
    label: 'Hood',
  },
  {
    fips: '48223',
    label: 'Hopkins',
  },
  {
    fips: '48225',
    label: 'Houston',
  },
  {
    fips: '48227',
    label: 'Howard',
  },
  {
    fips: '48229',
    label: 'Hudspeth',
  },
  {
    fips: '48231',
    label: 'Hunt',
  },
  {
    fips: '48233',
    label: 'Hutchinson',
  },
  {
    fips: '48235',
    label: 'Irion',
  },
  {
    fips: '48237',
    label: 'Jack',
  },
  {
    fips: '48239',
    label: 'Jackson',
  },
  {
    fips: '48241',
    label: 'Jasper',
  },
  {
    fips: '48243',
    label: 'Jeff Davis',
  },
  {
    fips: '48245',
    label: 'Jefferson',
  },
  {
    fips: '48247',
    label: 'Jim Hogg',
  },
  {
    fips: '48249',
    label: 'Jim Wells',
  },
  {
    fips: '48251',
    label: 'Johnson',
  },
  {
    fips: '48253',
    label: 'Jones',
  },
  {
    fips: '48255',
    label: 'Karnes',
  },
  {
    fips: '48257',
    label: 'Kaufman',
  },
  {
    fips: '48259',
    label: 'Kendall',
  },
  {
    fips: '48261',
    label: 'Kenedy',
  },
  {
    fips: '48263',
    label: 'Kent',
  },
  {
    fips: '48265',
    label: 'Kerr',
  },
  {
    fips: '48267',
    label: 'Kimble',
  },
  {
    fips: '48269',
    label: 'King',
  },
  {
    fips: '48271',
    label: 'Kinney',
  },
  {
    fips: '48273',
    label: 'Kleberg',
  },
  {
    fips: '48275',
    label: 'Knox',
  },
  {
    fips: '48277',
    label: 'Lamar',
  },
  {
    fips: '48279',
    label: 'Lamb',
  },
  {
    fips: '48281',
    label: 'Lampasas',
  },
  {
    fips: '48283',
    label: 'La Salle',
  },
  {
    fips: '48285',
    label: 'Lavaca',
  },
  {
    fips: '48287',
    label: 'Lee',
  },
  {
    fips: '48289',
    label: 'Leon',
  },
  {
    fips: '48291',
    label: 'Liberty',
  },
  {
    fips: '48293',
    label: 'Limestone',
  },
  {
    fips: '48295',
    label: 'Lipscomb',
  },
  {
    fips: '48297',
    label: 'Live Oak',
  },
  {
    fips: '48299',
    label: 'Llano',
  },
  {
    fips: '48301',
    label: 'Loving',
  },
  {
    fips: '48303',
    label: 'Lubbock',
  },
  {
    fips: '48305',
    label: 'Lynn',
  },
  {
    fips: '48307',
    label: 'McCulloch',
  },
  {
    fips: '48309',
    label: 'McLennan',
  },
  {
    fips: '48311',
    label: 'McMullen',
  },
  {
    fips: '48313',
    label: 'Madison',
  },
  {
    fips: '48315',
    label: 'Marion',
  },
  {
    fips: '48317',
    label: 'Martin',
  },
  {
    fips: '48319',
    label: 'Mason',
  },
  {
    fips: '48321',
    label: 'Matagorda',
  },
  {
    fips: '48323',
    label: 'Maverick',
  },
  {
    fips: '48325',
    label: 'Medina',
  },
  {
    fips: '48327',
    label: 'Menard',
  },
  {
    fips: '48329',
    label: 'Midland',
  },
  {
    fips: '48331',
    label: 'Milam',
  },
  {
    fips: '48333',
    label: 'Mills',
  },
  {
    fips: '48335',
    label: 'Mitchell',
  },
  {
    fips: '48337',
    label: 'Montague',
  },
  {
    fips: '48339',
    label: 'Montgomery',
  },
  {
    fips: '48341',
    label: 'Moore',
  },
  {
    fips: '48343',
    label: 'Morris',
  },
  {
    fips: '48345',
    label: 'Motley',
  },
  {
    fips: '48347',
    label: 'Nacogdoches',
  },
  {
    fips: '48349',
    label: 'Navarro',
  },
  {
    fips: '48351',
    label: 'Newton',
  },
  {
    fips: '48353',
    label: 'Nolan',
  },
  {
    fips: '48355',
    label: 'Nueces',
  },
  {
    fips: '48357',
    label: 'Ochiltree',
  },
  {
    fips: '48359',
    label: 'Oldham',
  },
  {
    fips: '48361',
    label: 'Orange',
  },
  {
    fips: '48363',
    label: 'Palo Pinto',
  },
  {
    fips: '48365',
    label: 'Panola',
  },
  {
    fips: '48367',
    label: 'Parker',
  },
  {
    fips: '48369',
    label: 'Parmer',
  },
  {
    fips: '48371',
    label: 'Pecos',
  },
  {
    fips: '48373',
    label: 'Polk',
  },
  {
    fips: '48375',
    label: 'Potter',
  },
  {
    fips: '48377',
    label: 'Presidio',
  },
  {
    fips: '48379',
    label: 'Rains',
  },
  {
    fips: '48381',
    label: 'Randall',
  },
  {
    fips: '48383',
    label: 'Reagan',
  },
  {
    fips: '48385',
    label: 'Real',
  },
  {
    fips: '48387',
    label: 'Red River',
  },
  {
    fips: '48389',
    label: 'Reeves',
  },
  {
    fips: '48391',
    label: 'Refugio',
  },
  {
    fips: '48393',
    label: 'Roberts',
  },
  {
    fips: '48395',
    label: 'Robertson',
  },
  {
    fips: '48397',
    label: 'Rockwall',
  },
  {
    fips: '48399',
    label: 'Runnels',
  },
  {
    fips: '48401',
    label: 'Rusk',
  },
  {
    fips: '48403',
    label: 'Sabine',
  },
  {
    fips: '48405',
    label: 'San Augustine',
  },
  {
    fips: '48407',
    label: 'San Jacinto',
  },
  {
    fips: '48409',
    label: 'San Patricio',
  },
  {
    fips: '48411',
    label: 'San Saba',
  },
  {
    fips: '48413',
    label: 'Schleicher',
  },
  {
    fips: '48415',
    label: 'Scurry',
  },
  {
    fips: '48417',
    label: 'Shackelford',
  },
  {
    fips: '48419',
    label: 'Shelby',
  },
  {
    fips: '48421',
    label: 'Sherman',
  },
  {
    fips: '48423',
    label: 'Smith',
  },
  {
    fips: '48425',
    label: 'Somervell',
  },
  {
    fips: '48427',
    label: 'Starr',
  },
  {
    fips: '48429',
    label: 'Stephens',
  },
  {
    fips: '48431',
    label: 'Sterling',
  },
  {
    fips: '48433',
    label: 'Stonewall',
  },
  {
    fips: '48435',
    label: 'Sutton',
  },
  {
    fips: '48437',
    label: 'Swisher',
  },
  {
    fips: '48439',
    label: 'Tarrant',
  },
  {
    fips: '48441',
    label: 'Taylor',
  },
  {
    fips: '48443',
    label: 'Terrell',
  },
  {
    fips: '48445',
    label: 'Terry',
  },
  {
    fips: '48447',
    label: 'Throckmorton',
  },
  {
    fips: '48449',
    label: 'Titus',
  },
  {
    fips: '48451',
    label: 'Tom Green',
  },
  {
    fips: '48453',
    label: 'Travis',
  },
  {
    fips: '48455',
    label: 'Trinity',
  },
  {
    fips: '48457',
    label: 'Tyler',
  },
  {
    fips: '48459',
    label: 'Upshur',
  },
  {
    fips: '48461',
    label: 'Upton',
  },
  {
    fips: '48463',
    label: 'Uvalde',
  },
  {
    fips: '48465',
    label: 'Val Verde',
  },
  {
    fips: '48467',
    label: 'Van Zandt',
  },
  {
    fips: '48469',
    label: 'Victoria',
  },
  {
    fips: '48471',
    label: 'Walker',
  },
  {
    fips: '48473',
    label: 'Waller',
  },
  {
    fips: '48475',
    label: 'Ward',
  },
  {
    fips: '48477',
    label: 'Washington',
  },
  {
    fips: '48479',
    label: 'Webb',
  },
  {
    fips: '48481',
    label: 'Wharton',
  },
  {
    fips: '48483',
    label: 'Wheeler',
  },
  {
    fips: '48485',
    label: 'Wichita',
  },
  {
    fips: '48487',
    label: 'Wilbarger',
  },
  {
    fips: '48489',
    label: 'Willacy',
  },
  {
    fips: '48491',
    label: 'Williamson',
  },
  {
    fips: '48493',
    label: 'Wilson',
  },
  {
    fips: '48495',
    label: 'Winkler',
  },
  {
    fips: '48497',
    label: 'Wise',
  },
  {
    fips: '48499',
    label: 'Wood',
  },
  {
    fips: '48501',
    label: 'Yoakum',
  },
  {
    fips: '48503',
    label: 'Young',
  },
  {
    fips: '48505',
    label: 'Zapata',
  },
  {
    fips: '48507',
    label: 'Zavala',
  },
  {
    fips: '49001',
    label: 'Beaver',
  },
  {
    fips: '49003',
    label: 'Box Elder',
  },
  {
    fips: '49005',
    label: 'Cache',
  },
  {
    fips: '49007',
    label: 'Carbon',
  },
  {
    fips: '49009',
    label: 'Daggett',
  },
  {
    fips: '49011',
    label: 'Davis',
  },
  {
    fips: '49013',
    label: 'Duchesne',
  },
  {
    fips: '49015',
    label: 'Emery',
  },
  {
    fips: '49017',
    label: 'Garfield',
  },
  {
    fips: '49019',
    label: 'Grand',
  },
  {
    fips: '49021',
    label: 'Iron',
  },
  {
    fips: '49023',
    label: 'Juab',
  },
  {
    fips: '49025',
    label: 'Kane',
  },
  {
    fips: '49027',
    label: 'Millard',
  },
  {
    fips: '49029',
    label: 'Morgan',
  },
  {
    fips: '49031',
    label: 'Piute',
  },
  {
    fips: '49033',
    label: 'Rich',
  },
  {
    fips: '49035',
    label: 'Salt Lake',
  },
  {
    fips: '49037',
    label: 'San Juan',
  },
  {
    fips: '49039',
    label: 'Sanpete',
  },
  {
    fips: '49041',
    label: 'Sevier',
  },
  {
    fips: '49043',
    label: 'Summit',
  },
  {
    fips: '49045',
    label: 'Tooele',
  },
  {
    fips: '49047',
    label: 'Uintah',
  },
  {
    fips: '49049',
    label: 'Utah',
  },
  {
    fips: '49051',
    label: 'Wasatch',
  },
  {
    fips: '49053',
    label: 'Washington',
  },
  {
    fips: '49055',
    label: 'Wayne',
  },
  {
    fips: '49057',
    label: 'Weber',
  },
  {
    fips: '50001',
    label: 'Addison',
  },
  {
    fips: '50003',
    label: 'Bennington',
  },
  {
    fips: '50005',
    label: 'Caledonia',
  },
  {
    fips: '50007',
    label: 'Chittenden',
  },
  {
    fips: '50009',
    label: 'Essex',
  },
  {
    fips: '50011',
    label: 'Franklin',
  },
  {
    fips: '50013',
    label: 'Grand Isle',
  },
  {
    fips: '50015',
    label: 'Lamoille',
  },
  {
    fips: '50017',
    label: 'Orange',
  },
  {
    fips: '50019',
    label: 'Orleans',
  },
  {
    fips: '50021',
    label: 'Rutland',
  },
  {
    fips: '50023',
    label: 'Washington',
  },
  {
    fips: '50025',
    label: 'Windham',
  },
  {
    fips: '50027',
    label: 'Windsor',
  },
  {
    fips: '51001',
    label: 'Accomack',
  },
  {
    fips: '51003',
    label: 'Albemarle',
  },
  {
    fips: '51005',
    label: 'Alleghany',
  },
  {
    fips: '51007',
    label: 'Amelia',
  },
  {
    fips: '51009',
    label: 'Amherst',
  },
  {
    fips: '51011',
    label: 'Appomattox',
  },
  {
    fips: '51013',
    label: 'Arlington',
  },
  {
    fips: '51015',
    label: 'Augusta',
  },
  {
    fips: '51017',
    label: 'Bath',
  },
  {
    fips: '51019',
    label: 'Bedford',
  },
  {
    fips: '51021',
    label: 'Bland',
  },
  {
    fips: '51023',
    label: 'Botetourt',
  },
  {
    fips: '51025',
    label: 'Brunswick',
  },
  {
    fips: '51027',
    label: 'Buchanan',
  },
  {
    fips: '51029',
    label: 'Buckingham',
  },
  {
    fips: '51031',
    label: 'Campbell',
  },
  {
    fips: '51033',
    label: 'Caroline',
  },
  {
    fips: '51035',
    label: 'Carroll',
  },
  {
    fips: '51036',
    label: 'Charles City',
  },
  {
    fips: '51037',
    label: 'Charlotte',
  },
  {
    fips: '51041',
    label: 'Chesterfield',
  },
  {
    fips: '51043',
    label: 'Clarke',
  },
  {
    fips: '51045',
    label: 'Craig',
  },
  {
    fips: '51047',
    label: 'Culpeper',
  },
  {
    fips: '51049',
    label: 'Cumberland',
  },
  {
    fips: '51051',
    label: 'Dickenson',
  },
  {
    fips: '51053',
    label: 'Dinwiddie',
  },
  {
    fips: '51057',
    label: 'Essex',
  },
  {
    fips: '51059',
    label: 'Fairfax',
  },
  {
    fips: '51061',
    label: 'Fauquier',
  },
  {
    fips: '51063',
    label: 'Floyd',
  },
  {
    fips: '51065',
    label: 'Fluvanna',
  },
  {
    fips: '51067',
    label: 'Franklin',
  },
  {
    fips: '51069',
    label: 'Frederick',
  },
  {
    fips: '51071',
    label: 'Giles',
  },
  {
    fips: '51073',
    label: 'Gloucester',
  },
  {
    fips: '51075',
    label: 'Goochland',
  },
  {
    fips: '51077',
    label: 'Grayson',
  },
  {
    fips: '51079',
    label: 'Greene',
  },
  {
    fips: '51081',
    label: 'Greensville',
  },
  {
    fips: '51083',
    label: 'Halifax',
  },
  {
    fips: '51085',
    label: 'Hanover',
  },
  {
    fips: '51087',
    label: 'Henrico',
  },
  {
    fips: '51089',
    label: 'Henry',
  },
  {
    fips: '51091',
    label: 'Highland',
  },
  {
    fips: '51093',
    label: 'Isle of Wight',
  },
  {
    fips: '51095',
    label: 'James City',
  },
  {
    fips: '51097',
    label: 'King and Queen',
  },
  {
    fips: '51099',
    label: 'King George',
  },
  {
    fips: '51101',
    label: 'King William',
  },
  {
    fips: '51103',
    label: 'Lancaster',
  },
  {
    fips: '51105',
    label: 'Lee',
  },
  {
    fips: '51107',
    label: 'Loudoun',
  },
  {
    fips: '51109',
    label: 'Louisa',
  },
  {
    fips: '51111',
    label: 'Lunenburg',
  },
  {
    fips: '51113',
    label: 'Madison',
  },
  {
    fips: '51115',
    label: 'Mathews',
  },
  {
    fips: '51117',
    label: 'Mecklenburg',
  },
  {
    fips: '51119',
    label: 'Middlesex',
  },
  {
    fips: '51121',
    label: 'Montgomery',
  },
  {
    fips: '51125',
    label: 'Nelson',
  },
  {
    fips: '51127',
    label: 'New Kent',
  },
  {
    fips: '51131',
    label: 'Northampton',
  },
  {
    fips: '51133',
    label: 'Northumberland',
  },
  {
    fips: '51135',
    label: 'Nottoway',
  },
  {
    fips: '51137',
    label: 'Orange',
  },
  {
    fips: '51139',
    label: 'Page',
  },
  {
    fips: '51141',
    label: 'Patrick',
  },
  {
    fips: '51143',
    label: 'Pittsylvania',
  },
  {
    fips: '51145',
    label: 'Powhatan',
  },
  {
    fips: '51147',
    label: 'Prince Edward',
  },
  {
    fips: '51149',
    label: 'Prince George',
  },
  {
    fips: '51153',
    label: 'Prince William',
  },
  {
    fips: '51155',
    label: 'Pulaski',
  },
  {
    fips: '51157',
    label: 'Rappahannock',
  },
  {
    fips: '51159',
    label: 'Richmond',
  },
  {
    fips: '51161',
    label: 'Roanoke',
  },
  {
    fips: '51163',
    label: 'Rockbridge',
  },
  {
    fips: '51165',
    label: 'Rockingham',
  },
  {
    fips: '51167',
    label: 'Russell',
  },
  {
    fips: '51169',
    label: 'Scott',
  },
  {
    fips: '51171',
    label: 'Shenandoah',
  },
  {
    fips: '51173',
    label: 'Smyth',
  },
  {
    fips: '51175',
    label: 'Southampton',
  },
  {
    fips: '51177',
    label: 'Spotsylvania',
  },
  {
    fips: '51179',
    label: 'Stafford',
  },
  {
    fips: '51181',
    label: 'Surry',
  },
  {
    fips: '51183',
    label: 'Sussex',
  },
  {
    fips: '51185',
    label: 'Tazewell',
  },
  {
    fips: '51187',
    label: 'Warren',
  },
  {
    fips: '51191',
    label: 'Washington',
  },
  {
    fips: '51193',
    label: 'Westmoreland',
  },
  {
    fips: '51195',
    label: 'Wise',
  },
  {
    fips: '51197',
    label: 'Wythe',
  },
  {
    fips: '51199',
    label: 'York',
  },
  {
    fips: '51510',
    label: 'Alexandria city',
  },
  {
    fips: '51520',
    label: 'Bristol city',
  },
  {
    fips: '51530',
    label: 'Buena Vista city',
  },
  {
    fips: '51540',
    label: 'Charlottesville city',
  },
  {
    fips: '51550',
    label: 'Chesapeake city',
  },
  {
    fips: '51570',
    label: 'Colonial Heights city',
  },
  {
    fips: '51580',
    label: 'Covington city',
  },
  {
    fips: '51590',
    label: 'Danville city',
  },
  {
    fips: '51595',
    label: 'Emporia city',
  },
  {
    fips: '51600',
    label: 'Fairfax city',
  },
  {
    fips: '51610',
    label: 'Falls Church city',
  },
  {
    fips: '51620',
    label: 'Franklin city',
  },
  {
    fips: '51630',
    label: 'Fredericksburg city',
  },
  {
    fips: '51640',
    label: 'Galax city',
  },
  {
    fips: '51650',
    label: 'Hampton city',
  },
  {
    fips: '51660',
    label: 'Harrisonburg city',
  },
  {
    fips: '51670',
    label: 'Hopewell city',
  },
  {
    fips: '51678',
    label: 'Lexington city',
  },
  {
    fips: '51680',
    label: 'Lynchburg city',
  },
  {
    fips: '51683',
    label: 'Manassas city',
  },
  {
    fips: '51685',
    label: 'Manassas Park city',
  },
  {
    fips: '51690',
    label: 'Martinsville city',
  },
  {
    fips: '51700',
    label: 'Newport News city',
  },
  {
    fips: '51710',
    label: 'Norfolk city',
  },
  {
    fips: '51720',
    label: 'Norton city',
  },
  {
    fips: '51730',
    label: 'Petersburg city',
  },
  {
    fips: '51735',
    label: 'Poquoson city',
  },
  {
    fips: '51740',
    label: 'Portsmouth city',
  },
  {
    fips: '51750',
    label: 'Radford city',
  },
  {
    fips: '51760',
    label: 'Richmond city',
  },
  {
    fips: '51770',
    label: 'Roanoke city',
  },
  {
    fips: '51775',
    label: 'Salem city',
  },
  {
    fips: '51790',
    label: 'Staunton city',
  },
  {
    fips: '51800',
    label: 'Suffolk city',
  },
  {
    fips: '51810',
    label: 'Virginia Beach city',
  },
  {
    fips: '51820',
    label: 'Waynesboro city',
  },
  {
    fips: '51830',
    label: 'Williamsburg city',
  },
  {
    fips: '51840',
    label: 'Winchester city',
  },
  {
    fips: '53001',
    label: 'Adams',
  },
  {
    fips: '53003',
    label: 'Asotin',
  },
  {
    fips: '53005',
    label: 'Benton',
  },
  {
    fips: '53007',
    label: 'Chelan',
  },
  {
    fips: '53009',
    label: 'Clallam',
  },
  {
    fips: '53011',
    label: 'Clark',
  },
  {
    fips: '53013',
    label: 'Columbia',
  },
  {
    fips: '53015',
    label: 'Cowlitz',
  },
  {
    fips: '53017',
    label: 'Douglas',
  },
  {
    fips: '53019',
    label: 'Ferry',
  },
  {
    fips: '53021',
    label: 'Franklin',
  },
  {
    fips: '53023',
    label: 'Garfield',
  },
  {
    fips: '53025',
    label: 'Grant',
  },
  {
    fips: '53027',
    label: 'Grays Harbor',
  },
  {
    fips: '53029',
    label: 'Island',
  },
  {
    fips: '53031',
    label: 'Jefferson',
  },
  {
    fips: '53033',
    label: 'King',
  },
  {
    fips: '53035',
    label: 'Kitsap',
  },
  {
    fips: '53037',
    label: 'Kittitas',
  },
  {
    fips: '53039',
    label: 'Klickitat',
  },
  {
    fips: '53041',
    label: 'Lewis',
  },
  {
    fips: '53043',
    label: 'Lincoln',
  },
  {
    fips: '53045',
    label: 'Mason',
  },
  {
    fips: '53047',
    label: 'Okanogan',
  },
  {
    fips: '53049',
    label: 'Pacific',
  },
  {
    fips: '53051',
    label: 'Pend Oreille',
  },
  {
    fips: '53053',
    label: 'Pierce',
  },
  {
    fips: '53055',
    label: 'San Juan',
  },
  {
    fips: '53057',
    label: 'Skagit',
  },
  {
    fips: '53059',
    label: 'Skamania',
  },
  {
    fips: '53061',
    label: 'Snohomish',
  },
  {
    fips: '53063',
    label: 'Spokane',
  },
  {
    fips: '53065',
    label: 'Stevens',
  },
  {
    fips: '53067',
    label: 'Thurston',
  },
  {
    fips: '53069',
    label: 'Wahkiakum',
  },
  {
    fips: '53071',
    label: 'Walla Walla',
  },
  {
    fips: '53073',
    label: 'Whatcom',
  },
  {
    fips: '53075',
    label: 'Whitman',
  },
  {
    fips: '53077',
    label: 'Yakima',
  },
  {
    fips: '54001',
    label: 'Barbour',
  },
  {
    fips: '54003',
    label: 'Berkeley',
  },
  {
    fips: '54005',
    label: 'Boone',
  },
  {
    fips: '54007',
    label: 'Braxton',
  },
  {
    fips: '54009',
    label: 'Brooke',
  },
  {
    fips: '54011',
    label: 'Cabell',
  },
  {
    fips: '54013',
    label: 'Calhoun',
  },
  {
    fips: '54015',
    label: 'Clay',
  },
  {
    fips: '54017',
    label: 'Doddridge',
  },
  {
    fips: '54019',
    label: 'Fayette',
  },
  {
    fips: '54021',
    label: 'Gilmer',
  },
  {
    fips: '54023',
    label: 'Grant',
  },
  {
    fips: '54025',
    label: 'Greenbrier',
  },
  {
    fips: '54027',
    label: 'Hampshire',
  },
  {
    fips: '54029',
    label: 'Hancock',
  },
  {
    fips: '54031',
    label: 'Hardy',
  },
  {
    fips: '54033',
    label: 'Harrison',
  },
  {
    fips: '54035',
    label: 'Jackson',
  },
  {
    fips: '54037',
    label: 'Jefferson',
  },
  {
    fips: '54039',
    label: 'Kanawha',
  },
  {
    fips: '54041',
    label: 'Lewis',
  },
  {
    fips: '54043',
    label: 'Lincoln',
  },
  {
    fips: '54045',
    label: 'Logan',
  },
  {
    fips: '54047',
    label: 'McDowell',
  },
  {
    fips: '54049',
    label: 'Marion',
  },
  {
    fips: '54051',
    label: 'Marshall',
  },
  {
    fips: '54053',
    label: 'Mason',
  },
  {
    fips: '54055',
    label: 'Mercer',
  },
  {
    fips: '54057',
    label: 'Mineral',
  },
  {
    fips: '54059',
    label: 'Mingo',
  },
  {
    fips: '54061',
    label: 'Monongalia',
  },
  {
    fips: '54063',
    label: 'Monroe',
  },
  {
    fips: '54065',
    label: 'Morgan',
  },
  {
    fips: '54067',
    label: 'Nicholas',
  },
  {
    fips: '54069',
    label: 'Ohio',
  },
  {
    fips: '54071',
    label: 'Pendleton',
  },
  {
    fips: '54073',
    label: 'Pleasants',
  },
  {
    fips: '54075',
    label: 'Pocahontas',
  },
  {
    fips: '54077',
    label: 'Preston',
  },
  {
    fips: '54079',
    label: 'Putnam',
  },
  {
    fips: '54081',
    label: 'Raleigh',
  },
  {
    fips: '54083',
    label: 'Randolph',
  },
  {
    fips: '54085',
    label: 'Ritchie',
  },
  {
    fips: '54087',
    label: 'Roane',
  },
  {
    fips: '54089',
    label: 'Summers',
  },
  {
    fips: '54091',
    label: 'Taylor',
  },
  {
    fips: '54093',
    label: 'Tucker',
  },
  {
    fips: '54095',
    label: 'Tyler',
  },
  {
    fips: '54097',
    label: 'Upshur',
  },
  {
    fips: '54099',
    label: 'Wayne',
  },
  {
    fips: '54101',
    label: 'Webster',
  },
  {
    fips: '54103',
    label: 'Wetzel',
  },
  {
    fips: '54105',
    label: 'Wirt',
  },
  {
    fips: '54107',
    label: 'Wood',
  },
  {
    fips: '54109',
    label: 'Wyoming',
  },
  {
    fips: '55001',
    label: 'Adams',
  },
  {
    fips: '55003',
    label: 'Ashland',
  },
  {
    fips: '55005',
    label: 'Barron',
  },
  {
    fips: '55007',
    label: 'Bayfield',
  },
  {
    fips: '55009',
    label: 'Brown',
  },
  {
    fips: '55011',
    label: 'Buffalo',
  },
  {
    fips: '55013',
    label: 'Burnett',
  },
  {
    fips: '55015',
    label: 'Calumet',
  },
  {
    fips: '55017',
    label: 'Chippewa',
  },
  {
    fips: '55019',
    label: 'Clark',
  },
  {
    fips: '55021',
    label: 'Columbia',
  },
  {
    fips: '55023',
    label: 'Crawford',
  },
  {
    fips: '55025',
    label: 'Dane',
  },
  {
    fips: '55027',
    label: 'Dodge',
  },
  {
    fips: '55029',
    label: 'Door',
  },
  {
    fips: '55031',
    label: 'Douglas',
  },
  {
    fips: '55033',
    label: 'Dunn',
  },
  {
    fips: '55035',
    label: 'Eau Claire',
  },
  {
    fips: '55037',
    label: 'Florence',
  },
  {
    fips: '55039',
    label: 'Fond du Lac',
  },
  {
    fips: '55041',
    label: 'Forest',
  },
  {
    fips: '55043',
    label: 'Grant',
  },
  {
    fips: '55045',
    label: 'Green',
  },
  {
    fips: '55047',
    label: 'Green Lake',
  },
  {
    fips: '55049',
    label: 'Iowa',
  },
  {
    fips: '55051',
    label: 'Iron',
  },
  {
    fips: '55053',
    label: 'Jackson',
  },
  {
    fips: '55055',
    label: 'Jefferson',
  },
  {
    fips: '55057',
    label: 'Juneau',
  },
  {
    fips: '55059',
    label: 'Kenosha',
  },
  {
    fips: '55061',
    label: 'Kewaunee',
  },
  {
    fips: '55063',
    label: 'La Crosse',
  },
  {
    fips: '55065',
    label: 'Lafayette',
  },
  {
    fips: '55067',
    label: 'Langlade',
  },
  {
    fips: '55069',
    label: 'Lincoln',
  },
  {
    fips: '55071',
    label: 'Manitowoc',
  },
  {
    fips: '55073',
    label: 'Marathon',
  },
  {
    fips: '55075',
    label: 'Marinette',
  },
  {
    fips: '55077',
    label: 'Marquette',
  },
  {
    fips: '55078',
    label: 'Menominee',
  },
  {
    fips: '55079',
    label: 'Milwaukee',
  },
  {
    fips: '55081',
    label: 'Monroe',
  },
  {
    fips: '55083',
    label: 'Oconto',
  },
  {
    fips: '55085',
    label: 'Oneida',
  },
  {
    fips: '55087',
    label: 'Outagamie',
  },
  {
    fips: '55089',
    label: 'Ozaukee',
  },
  {
    fips: '55091',
    label: 'Pepin',
  },
  {
    fips: '55093',
    label: 'Pierce',
  },
  {
    fips: '55095',
    label: 'Polk',
  },
  {
    fips: '55097',
    label: 'Portage',
  },
  {
    fips: '55099',
    label: 'Price',
  },
  {
    fips: '55101',
    label: 'Racine',
  },
  {
    fips: '55103',
    label: 'Richland',
  },
  {
    fips: '55105',
    label: 'Rock',
  },
  {
    fips: '55107',
    label: 'Rusk',
  },
  {
    fips: '55109',
    label: 'St. Croix',
  },
  {
    fips: '55111',
    label: 'Sauk',
  },
  {
    fips: '55113',
    label: 'Sawyer',
  },
  {
    fips: '55115',
    label: 'Shawano',
  },
  {
    fips: '55117',
    label: 'Sheboygan',
  },
  {
    fips: '55119',
    label: 'Taylor',
  },
  {
    fips: '55121',
    label: 'Trempealeau',
  },
  {
    fips: '55123',
    label: 'Vernon',
  },
  {
    fips: '55125',
    label: 'Vilas',
  },
  {
    fips: '55127',
    label: 'Walworth',
  },
  {
    fips: '55129',
    label: 'Washburn',
  },
  {
    fips: '55131',
    label: 'Washington',
  },
  {
    fips: '55133',
    label: 'Waukesha',
  },
  {
    fips: '55135',
    label: 'Waupaca',
  },
  {
    fips: '55137',
    label: 'Waushara',
  },
  {
    fips: '55139',
    label: 'Winnebago',
  },
  {
    fips: '55141',
    label: 'Wood',
  },
  {
    fips: '56001',
    label: 'Albany',
  },
  {
    fips: '56003',
    label: 'Big Horn',
  },
  {
    fips: '56005',
    label: 'Campbell',
  },
  {
    fips: '56007',
    label: 'Carbon',
  },
  {
    fips: '56009',
    label: 'Converse',
  },
  {
    fips: '56011',
    label: 'Crook',
  },
  {
    fips: '56013',
    label: 'Fremont',
  },
  {
    fips: '56015',
    label: 'Goshen',
  },
  {
    fips: '56017',
    label: 'Hot Springs',
  },
  {
    fips: '56019',
    label: 'Johnson',
  },
  {
    fips: '56021',
    label: 'Laramie',
  },
  {
    fips: '56023',
    label: 'Lincoln',
  },
  {
    fips: '56025',
    label: 'Natrona',
  },
  {
    fips: '56027',
    label: 'Niobrara',
  },
  {
    fips: '56029',
    label: 'Park',
  },
  {
    fips: '56031',
    label: 'Platte',
  },
  {
    fips: '56033',
    label: 'Sheridan',
  },
  {
    fips: '56035',
    label: 'Sublette',
  },
  {
    fips: '56037',
    label: 'Sweetwater',
  },
  {
    fips: '56039',
    label: 'Teton',
  },
  {
    fips: '56041',
    label: 'Uinta',
  },
  {
    fips: '56043',
    label: 'Washakie',
  },
  {
    fips: '56045',
    label: 'Weston',
  },
]
