import {
  ModeProps,
  ModifyMode,
  FeatureCollection,
  utils,
  PointerMoveEvent,
  ClickEvent,
  ImmutableFeatureCollection,
} from '@nebula.gl/edit-modes'

/**
 * This customizes the modify mode in 2 ways:
 * 1. deleting a vertex requires SHIFT + click OR right click
 * 2. the cursor renders in 5 ways:
 *    a. grab if no handle is nearby
 *    b. custom arrow when near an existing vertex and no modifier
 *    c. custom arrow plus when near an intermediate handle
 *    d. custom arrow minus when near an existing vertex and SHFIT modifier held
 *    e. not-allowed when hovering over an existing vertex and only 3 vertices remain
 */
export class CustomModifyMode extends ModifyMode {
  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    const pickedExistingHandle = utils.getPickedExistingEditHandle(event.picks)
    const pickedIntermediateHandle = utils.getPickedIntermediateEditHandle(event.picks)
    if (pickedExistingHandle) {
      const { featureIndex, positionIndexes } = pickedExistingHandle.properties

      let updatedData
      try {
        updatedData = new ImmutableFeatureCollection(props.data)
          .removePosition(featureIndex, positionIndexes)
          .getObject()
      } catch (ignored) {
        // This happens if user attempts to remove the last point
      }

      if (updatedData && (event.sourceEvent.shiftKey || event.sourceEvent.button === 2)) {
        props.onEdit({
          updatedData,
          editType: 'removePosition',
          editContext: {
            featureIndexes: [featureIndex],
            positionIndexes,
            position: pickedExistingHandle.geometry.coordinates,
          },
        })
      }
    } else if (pickedIntermediateHandle) {
      const { featureIndex, positionIndexes } = pickedIntermediateHandle.properties

      const updatedData = new ImmutableFeatureCollection(props.data)
        .addPosition(featureIndex, positionIndexes, pickedIntermediateHandle.geometry.coordinates)
        .getObject()

      if (updatedData) {
        props.onEdit({
          updatedData,
          editType: 'addPosition',
          editContext: {
            featureIndexes: [featureIndex],
            positionIndexes,
            position: pickedIntermediateHandle.geometry.coordinates,
          },
        })
      }
    }
  }

  handlePointerMove(event: PointerMoveEvent, props: ModeProps<FeatureCollection>): void {
    const cursor = this.getCustomCursor(event, props)
    props.onUpdateCursor(cursor)
  }

  getCustomCursor(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ): string | null | undefined {
    const picks = (event && event.picks) || []
    const handlesPicked = utils.getPickedEditHandles(picks)

    //hovering an existing point
    if (handlesPicked.length >= 1) {
      if (handlesPicked[0].properties.editHandleType === 'intermediate')
        //Add
        return 'url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2219%22%20viewBox%3D%220%200%2020%2019%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M0%2017H2V19H4V17H6V15H4V13H2V15H0V17Z%22%20fill%3D%22%23000000%22%2F%3E%3Cpath%20d%3D%22M12.247%2010.4017C12.2712%2010.3292%2012.3292%2010.2712%2012.4017%2010.247L20%207.71429L2%200L9.71429%2018L12.247%2010.4017Z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E") -16 -16, cell'
      else if (handlesPicked[0].properties.editHandleType === 'existing') {
        const targetGeometry =
          props.data.features[handlesPicked[0].properties.featureIndex].geometry
        if (
          (targetGeometry.type === 'Polygon' &&
            Array.isArray(targetGeometry.coordinates[0]) &&
            targetGeometry.coordinates[0].length > 4) ||
          (targetGeometry.type === 'MultiPolygon' &&
            Array.isArray(targetGeometry.coordinates[0][0]) &&
            targetGeometry.coordinates[0][0].length > 4)
        ) {
          if (event.sourceEvent.shiftKey) {
            //Remove
            return 'url("data:image/svg+xml,%3Csvg%20width%3D%2220%22%20height%3D%2218%22%20viewBox%3D%220%200%2020%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M6%2015V17H0V15H6Z%22%20fill%3D%22%23000000%22%2F%3E%3Cpath%20d%3D%22M12.247%2010.4017C12.2712%2010.3292%2012.3292%2010.2712%2012.4017%2010.247L20%207.71429L2%200L9.71429%2018L12.247%2010.4017Z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E") -16 -16, cell'
          }
        } else {
          //Not Allowed
          return 'not-allowed'
        }
      }
      //Pointer
      return 'url("data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.247%2010.4017C10.2712%2010.3292%2010.3292%2010.2712%2010.4017%2010.247L18%207.71429L0%200L7.71429%2018L10.247%2010.4017Z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E") -16 -16, cell'
    }
    return 'grab'
  }
}
