import { CiboUiContext } from '@cibo/ui'
import qs from 'query-string'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useDispatch } from 'react-redux'
import { useAuth } from '../../hooks'
import { authCheck, authSessionInvalid, refreshUserInfo } from '../../store'

type Props = {
  jwt: string
}

export const AuthCheck = ({ jwt }: Props) => {
  const { t } = useTranslation('authCheck')
  const { isLoggedIn } = useAuth()
  const { events } = useContext(CiboUiContext)

  const dispatch = useDispatch()

  useEffect(() => {
    if (!isLoggedIn) {
      events.dispatchEvent(new CustomEvent('close_modals'))
    }
  }, [isLoggedIn])

  useEffect(() => {
    // look for auth0 token in url param
    const { id_token } = window.location
      ? (qs.parse(window.location.hash) as { id_token: string })
      : ({} as { id_token: undefined })

    if (id_token && typeof id_token === 'string') {
      dispatch(authCheck({ jwt: id_token, message: t('onLogin') }))
    } else if (jwt) {
      dispatch(authCheck({ jwt })) // look for stored auth token in store
    } else {
      dispatch(authSessionInvalid()) // no auth token found - resolve invalid session
      dispatch(refreshUserInfo())
    }
  }, [])

  return null
}
