import { RDEqipAutogenRequiredFormsTaskEditor } from './RDEqipAutogenRequiredFormsTaskEditor'
export * from './types'

export const RDEqipAutogenRequiredFormsFeatureMap = {
  Component: {
    traitId: 'eqipAutogenRequiredForms' as const,
    TaskEditor: RDEqipAutogenRequiredFormsTaskEditor,
  },
  resourceType: 'workflow' as const,
}
