import { RDEqipProofOfLandControlTaskEditor } from './RDProofOfLandControlTaskEditor'
export * from './types'

export const RDEqipProofOfLandControlFeatureMap = {
  Component: {
    traitId: 'proofOfLandControl' as const,
    TaskEditor: RDEqipProofOfLandControlTaskEditor,
    selfIsland: true,
  },
  resourceType: 'workflow' as const,
}
