import { RDNaturalDisturbanceEvent } from '@cibo/core'
import { Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const NaturalDisturbanceCellDisplay = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/NaturalDisturbance')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.events?.length) {
    return (
      <Tooltip
        title={
          <Stack spacing={1} padding={1}>
            {detail.input?.events.map(
              ({ month, disturbance, lossPer }: RDNaturalDisturbanceEvent, index: number) => (
                <Stack key={index}>
                  <Typography variant="body1" fontWeight="bold">
                    {t(`month_${month}`)}
                  </Typography>
                  <Typography variant="body2">
                    {t(`disturbance_${disturbance}`)} - {t(`percent`, { number: lossPer })}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
        }
      >
        <Typography variant="body1">
          {t('events', { count: detail.input?.events?.length })}
        </Typography>
      </Tooltip>
    )
  }

  return <Typography>{t('none')}</Typography>
}
