// Stolen from react-router.
// https://github.com/remix-run/react-router/blob/main/packages/react-router/index.tsx#L821

export type Params<Key extends string = string> = {
  readonly [key in Key]: string | undefined
}

function invariant(cond: any, message: string): asserts cond {
  if (!cond) throw new Error(message)
}

export function generatePath(path: string, params: Params = {}): string {
  return path
    .replace(/:(\w+)/g, (_, key) => {
      invariant(params[key] != null, `Missing ":${key}" param`)
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      return params[key]!
    })
    .replace(/\/*\*$/, _ => (params['*'] == null ? '' : params['*'].replace(/^\/*/, '/')));
}
