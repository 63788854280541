import { useTheme } from '@mui/material'
import { Layer, Source } from 'react-map-gl/dist/es5'
import { Config } from '../../../types'
import { MapTheme } from '../const'
import { GEOPOLITICAL } from './const'

const GEOPOLITICAL_STATE_OUTLINE_LAYER = 'state-outline'

export const StatesLayer = () => {
  const theme = useTheme<MapTheme>()
  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')

  return (
    <Source
      id={GEOPOLITICAL.SOURCE}
      type="vector"
      tiles={[`${maptilesServiceUrl}geopolitical/{z}/{x}/{y}.pbf`]}
      minzoom={3}
      maxzoom={28}
    >
      <Layer
        id={GEOPOLITICAL_STATE_OUTLINE_LAYER}
        type="line"
        beforeId="data-layer-anchor"
        source-layer={GEOPOLITICAL.STATES.SHAPES}
        paint={{
          'line-color': theme.palette.stateLine,
          'line-width': ['interpolate', ['exponential', 1.51], ['zoom'], 0, 0.5, 5.5, 2],
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 0, 1, 5.5, 1, 5.75, 0.25, 7, 0],
        }}
      />
    </Source>
  )
}
