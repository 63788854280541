import { DetailRequirement, FieldModel, PrgDetail, ResourceDetail } from '@cibo/core'
import { ITraitFeature, TraitCellEditorProps, useDetailEditing } from '@cibo/landmanager'
import { ExtendedTheme } from '@cibo/ui'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import { Box, IconButton, Stack, useTheme } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

interface DetailCellProps
  extends Pick<ITraitFeature, 'cellDisplay' | 'cellEditor'>,
    Pick<TraitCellEditorProps, 'saveDetail' | 'removeDetail' | 'disabled'> {
  detail: ResourceDetail
  requirement: DetailRequirement
  rowParams: GridRenderCellParams<FieldModel>

  filterInputs?: PrgDetail
  supressCellEditUI?: boolean
}

export const DetailCell = ({
  detail,
  filterInputs,
  requirement,
  saveDetail,
  removeDetail,
  cellEditor: CellEditor,
  cellDisplay: CellDisplay,
  rowParams: params,
  supressCellEditUI,
  disabled,
}: DetailCellProps) => {
  const { t: traitT } = useTranslation('traits')
  const { t: tableT } = useTranslation('@cibo/programs/TraitTable')
  const theme = useTheme<ExtendedTheme>()
  const detailEditing = useDetailEditing()

  const { resultsOnly, traitId, year } = requirement

  return (
    <Stack
      direction="row"
      alignItems="center"
      justifyContent="space-between"
      spacing={1}
      flex={1}
      overflow="hidden"
      height="100%"
      sx={{
        padding: 0.5,
        margin: 0,
        backgroundColor: detail?.source === 'cibo' ? theme.palette.ciboSensed : undefined,
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        flex={1}
        overflow="hidden"
        alignItems="center"
        height="100%"
      >
        {!!CellEditor && !detail?.immutable ? (
          <CellEditor
            traitId={traitId}
            year={year}
            params={params}
            removeDetail={removeDetail}
            saveDetail={saveDetail}
            t={tableT}
            disabled={disabled}
          />
        ) : (
          <Box sx={{ width: '100%', overflow: 'hidden' }}>
            {!!detail ? (
              <CellDisplay traitId={traitId} year={year} params={params} />
            ) : (
              <Stack
                sx={{ height: '100%' }}
                justifyContent="center"
                alignItems="center"
                spacing={1}
              >
                <IconButton
                  aria-label={tableT('recordLabel', {
                    trait: traitT(traitId),
                    field: params.row.name,
                    year,
                    context: !!year && 'year',
                  })}
                  onClick={() =>
                    detailEditing.setDetailModal({
                      resourceId: params.row.resourceId,
                      traitId,
                      requirement,
                      filterInputs,
                    })
                  }
                  data-testid="add-detail-button"
                >
                  <AddIcon />
                </IconButton>
              </Stack>
            )}
          </Box>
        )}

        {!!detail && !!detail.immutable && (
          <Stack>
            <IconButton data-testid="detail-locked-button" disabled>
              <LockIcon sx={{ fontSize: 'small' }} />
            </IconButton>
          </Stack>
        )}
        {!!detail && !detail.immutable && !supressCellEditUI && (
          <Stack sx={{ marginLeft: -2 }}>
            {detail.source === 'user' && (
              <IconButton
                aria-label={tableT('removeLabel', {
                  trait: traitT(traitId),
                  field: params.row.name,
                  year,
                  context: !!year && 'year',
                })}
                onClick={() =>
                  removeDetail({
                    resourceId: params.row.resourceId,
                    traitId,
                    year,
                  })
                }
                data-testid="remove-detail-button"
              >
                <ClearIcon sx={{ fontSize: 'small' }} />
              </IconButton>
            )}
            {!resultsOnly && (
              <IconButton
                aria-label={tableT('editLabel', {
                  trait: traitT(traitId),
                  field: params.row.name,
                  year,
                  context: !!year && 'year',
                })}
                onClick={() =>
                  detailEditing.setDetailModal({
                    resourceId: params.row.resourceId,
                    traitId,
                    requirement,
                    filterInputs,
                  })
                }
                data-testid="edit-detail-button"
              >
                <EditIcon sx={{ fontSize: 'small' }} />
              </IconButton>
            )}
          </Stack>
        )}
      </Stack>
    </Stack>
  )
}
