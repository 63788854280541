import { CASH_CROP_HARVEST_TYPES, RDCashCropSpecies } from '@cibo/core'

export const SCOPED_PRIORITY_HARVEST_TYPES: Partial<Record<RDCashCropSpecies, string[]>> = {
  corn: ['grain', 'seed', 'silage', 'bale'],
  soybeans: ['grain', 'seed', 'silage', 'bale'],
  cotton: ['lint', 'bale', 'seed'],
  wheat: ['grain', 'seed', 'silage', 'bale'],
  tomatoes: ['fruit'],
  sugarbeets: ['root'],
  potatoes: ['tuber'],
}

export const SCOPED_HARVEST_TYPE_OPTIONS: Partial<Record<RDCashCropSpecies, string[]>> = {}

Object.keys(SCOPED_PRIORITY_HARVEST_TYPES).forEach((key, index) => {
  const priorityCrops = SCOPED_PRIORITY_HARVEST_TYPES[key as RDCashCropSpecies]
  //@ts-ignore ??
  SCOPED_HARVEST_TYPE_OPTIONS[key as RDCashCropSpecies] = priorityCrops
    ? [...priorityCrops, ...CASH_CROP_HARVEST_TYPES.filter(c => !priorityCrops.includes(c))]
    : CASH_CROP_HARVEST_TYPES
})

export const perAcreUnitKeyByHarvest = (harvestType?: string) => {
  switch (harvestType) {
    case 'grain':
    case 'seed':
      return 'bushelsPerAcre'
    case 'lint':
      return 'poundsOfLint'
    case 'bale':
    case 'silage':
    case 'haylage':
    case 'fruit':
    case 'tuber':
    case 'root':
    default:
      return 'tonsPerAcre'
  }
}
