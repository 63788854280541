import { useFigures } from '@cibo/ui'
import { useTranslation } from 'react-i18next'
import { DetailSimpleCellProps } from '../types'

export const BiomassBurningSimpleCell = ({ detail }: DetailSimpleCellProps) => {
  const { t } = useTranslation('@cibo/landmanager/BiomassBurningPanelTable')
  const { percent } = useFigures()

  return detail?.result === undefined ? (
    <></>
  ) : detail.result > 0 ? (
    <>{percent(detail.result / 100)}</>
  ) : (
    <>{t('none')}</>
  )
}
