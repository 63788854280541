import { TFunction } from 'i18next'

import { intuitiveFromNow } from '@cibo/ui'

export const fieldCreatedAtColumn = ({ t }: { t: TFunction }) => ({
  field: 'createdAt',
  headerName: t('createdAt', { ns: '@cibo/landmanager/FieldColumns' }),
  minWidth: 160,
  flex: 1,
  valueFormatter: (params: any) => intuitiveFromNow(params.value),
})
