import {
  AssignFieldsRequest,
  CreateFarmRequest,
  FarmSearchQuery,
  FarmsAPI,
  UpdateFarmRequest,
} from '@cibo/core'
import { useAuth } from '@cibo/profile'
import { logRequestError } from '@cibo/ui'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEY } from './queryKey'

export const useFarm = (termsetId: string | undefined) => {
  const { isLoggedIn } = useAuth()
  return useQuery({
    queryKey: [QUERY_KEY.FARM, termsetId],
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    queryFn: () => FarmsAPI.read(termsetId!).then(response => response?.item),

    enabled: isLoggedIn && !!termsetId,
  })
}

export const usePaginatedFarms = (query?: FarmSearchQuery) => {
  const { isLoggedIn } = useAuth()
  return useQuery({
    queryKey: [QUERY_KEY.FARMS_SEARCH, query],
    queryFn: () => FarmsAPI.search(query),
    enabled: isLoggedIn,
  })
}

export const useCreateFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: CreateFarmRequest) => FarmsAPI.create(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS_SEARCH] })
    },
    onError: (error: any, variables, context) => {
      logRequestError(error, { query: 'useCreateFarm', variables, context })
      return error
    },
  })
}

export const useUpdateFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: UpdateFarmRequest) => FarmsAPI.update(request),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARM] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS_SEARCH] })
    },
  })
}

export const useAssignFieldsToFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: AssignFieldsRequest) => FarmsAPI.assignFields(request),
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      request.fieldIds.forEach(resourceId =>
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS, resourceId] })
      )
    },
  })
}
export const useUnassignFieldsToFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: AssignFieldsRequest) => FarmsAPI.unassignFields(request),
    onSuccess: (_, request) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      request.fieldIds.forEach(resourceId =>
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS, resourceId] })
      )
    },
  })
}

export const useDeleteFarm = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (resourceId: string) => FarmsAPI.delete(resourceId),
    onSuccess: (_, termsetId) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARM, termsetId] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FARMS_SEARCH] })
    },
  })
}
