import { RadioBooleanField } from '@cibo/ui'
import { FormControl, FormLabel, Stack } from '@mui/material'
import { FieldProps, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../../types'
import { RDByrBareGround } from './types'

export const ByrBareGroundEditor = ({
  filterInputs,
  fieldModels,
  name = 'byrBareGroundEditor',
  requirement,
}: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/ByrBareGround')

  return (
    <FormikField name={name} id={name}>
      {({ field }: FieldProps<RDByrBareGround>) => (
        <Stack spacing={2}>
          <FormControl>
            <FormLabel>{t('cta')}</FormLabel>
            <RadioBooleanField name={`${name}.bareGround`} data-testid={`${name}.bareGround`} />
          </FormControl>
        </Stack>
      )}
    </FormikField>
  )
}
