/**
 * @todo
 * refactor this component to extend MUI's TransitionProps
 */
import { Box, BoxProps, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

interface BlurImplProps extends BoxProps {
  blur?: boolean
}

const BlurImpl = ({ blur, ...props }: BlurImplProps) => <Box {...props} />

export const Blur = styled<OverridableComponent<any>>(BlurImpl)(({ blur, theme }) => ({
  '&.MuiBox-root': {
    filter: blur ? 'blur(2px)' : undefined,
    pointerEvents: blur ? 'none' : undefined,
    opacity: blur ? 0.1 : undefined,
    transition: theme.transitions.create('all'),
  },
}))
