import { jsonSchemaFormikValidator } from '@cibo/ui'
import { evolve, omit, pathEq } from 'ramda'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { WaterPumpIrrigationCellDisplay } from './WaterPumpIrrigationDisplay'
import { WaterPumpIrrigationEditor } from './WaterPumpIrrigationEditor'
import { WaterPumpIrrigationRollup } from './WaterPumpIrrigationRollup'
import { RDWaterPumpIrrigation } from './types'

import SCHEMA from './schema.json'

const TRAIT_ID = 'waterPumpIrrigation'

const str2bool = (value: string) =>
  value === 'true' ? true : value === 'false' ? false : undefined
const bool2str = (value?: boolean) => (value ? 'true' : value === false ? 'false' : undefined)

export const WaterPumpIrrigation: ITraitFeatureByIdYear<RDWaterPumpIrrigation> = {
  traitId: TRAIT_ID,
  cellDisplay: WaterPumpIrrigationCellDisplay,
  editor: WaterPumpIrrigationEditor,

  forbidBulkEdit: false,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)

    const formValues = evolve(
      {
        pumpsUsed: bool2str,
        liftingOccurs: bool2str,
      },
      detail?.input || {}
    )

    return omit(['complete'], formValues)
  },
  ns: '@cibo/landmanager/WaterPumpIrrigation',

  validate: jsonSchemaFormikValidator(SCHEMA),

  detailFromEditorValues: (formValues, requirement) => {
    const detail = {
      traitId: requirement?.traitId,
      year: requirement?.year,
      input: evolve(
        {
          pumpsUsed: str2bool,
          liftingOccurs: str2bool,
        },
        formValues.input
      ),
    }

    if (!detail.input.pumpsUsed) {
      detail.input = omit(
        [
          'energySource',
          'energyAmount',
          'energyUnit',
          'totalWater',
          'waterUnit',
          'waterSource',
          'operatingPressurePSI',
          'pumpingDepthFeet',
          'surfaceWaterSource',
          'liftingOccurs',
        ],
        detail.input
      )
    }

    if (detail.input.energySource === 'unknown') {
      detail.input = omit(['energyAmount', 'energyUnit', 'userEnergyUnit'], detail.input)
    } else {
      detail.input = omit(
        [
          'totalWater',
          'waterUnit',
          'waterSource',
          'operatingPressurePSI',
          'pumpingDepthFeet',
          'surfaceWaterSource',
          'liftingOccurs',
        ],
        detail.input
      )
    }

    // backend requires unit, but it's known by source
    switch (detail.input.energySource) {
      case 'diesel':
        detail.input.energyUnit = 'gal'
        break
      case 'electricity':
      case 'renewable':
        detail.input.energyUnit = 'kWh'
        break
      default:
        break
    }

    return detail
  },

  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: WaterPumpIrrigationRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          pumpsUsed: false,
        },
      }),
      isRollupValue: pathEq(['input', 'pumpsUsed'], false),
    },
  },
}
