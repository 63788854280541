import { SupplyShedAPI, UserSupplyShedsRequest, UserSupplyShedsResponse } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'

import { QUERY_KEY } from './queryKey'

export const useUserSupplySheds = (
  page: number,
  pageSize: number,
  params?: UserSupplyShedsRequest
) => {
  return useQuery<UserSupplyShedsResponse, unknown, UserSupplyShedsResponse, string[]>({
    queryKey: [
      QUERY_KEY.SUPPLY_SHEDS_PAGINATED,
      `${page}`,
      `${pageSize}`,
      `${JSON.stringify(params)}`,
    ],
    queryFn: () =>
      SupplyShedAPI.list({
        offset: page * pageSize,
        limit: pageSize,
        ...params,
      }),
  })
}
