import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'
import { ProgramAccessor } from './ConservationProgramsTypes'

export const ConservationProgramsCell =
  (displayProgram: ProgramAccessor) => (props: TraitCellDisplayProps) =>
    <ConservationProgramsCellWithoutProgram {...props} displayProgram={displayProgram} />

const ConservationProgramsCellWithoutProgram = ({
  traitId,
  year,
  params,
  displayProgram,
}: TraitCellDisplayProps & { displayProgram: ProgramAccessor }) => {
  const { t } = useTranslation('@cibo/landmanager/ConservationPrograms')
  const detail = params.row.resolveStandingDetail(traitId, year)
  return <Typography>{t(`editorAnswer_${detail.result[displayProgram]}`)}</Typography>
}
