import { useQuery } from '@tanstack/react-query'

import { collateLandReport } from './collateLandReport'
import { LAND_REPORT_QUERY_KEY } from './consts'

export const useLandReport = (resourceId?: string) => {
  return useQuery({
    queryKey: [LAND_REPORT_QUERY_KEY.LAND_REPORT, resourceId],
    // @ts-ignore
    queryFn: () => collateLandReport(resourceId),

    enabled: !!resourceId,
    retry: (failureCount, error: any) => {
      const status = error?.response?.status

      return status !== 429 && failureCount <= 3
    },
  })
}
