import { FieldModel, RDEqipCurrentPractices, TraitId } from '@cibo/core'
import { fieldNameColumn } from '@cibo/landmanager'
import { DataGridPro, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Alert, Stack, Typography } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRowHeightParams,
  GridRowHeightReturnValue,
} from '@mui/x-data-grid-pro'
import { flatten } from 'ramda'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementDetails } from '../../../../hooks/useProgramEngagementDetails'
import { useProgramEngagementFields } from '../../../../queries'
import { RDEqipCurrentPracticesInput } from './RDEqipCurrentPracticesInput'

const SOURCES = [
  'eqipPracticeChoiceAgroforestry',
  'eqipPracticeChoiceEngineeringLandErosionStructures',
  'eqipPracticeChoiceEngineeringWaterControlStructures',
  'eqipPracticeChoiceSoil',
  'eqipPracticeChoiceWildlife',
] as TraitId[]

export const RDEqipCurrentPracticesTaskEditor = ({
  detailRequirements,
  engagementId,
  onError,
  onSuccess,
  onUpdating,
  ownerName,
  resourceIds,
  userRole,
}: ResourceDetailFeatureTaskEditorProps<RDEqipCurrentPractices>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipCurrentPracticesTaskEditor')
  const fieldModels = useProgramEngagementFields({ id: engagementId })
  const engagementDetails = useProgramEngagementDetails({ resourceId: engagementId })

  const options = useMemo(
    () =>
      flatten(
        engagementDetails.data
          ?.filter(({ traitId }) => SOURCES.includes(traitId))
          .map(({ result }) => result) ?? []
      ) as string[],
    [engagementDetails.dataUpdatedAt]
  )

  const columns = useMemo(() => {
    return [
      fieldNameColumn({ t }),
      {
        field: 'currentPractices',
        headerName: t('currentPracticesHeader'),
        flex: 3,
        renderCell: (props: GridCellParams<FieldModel>) => {
          const year = detailRequirements?.[0]?.year
          return !year ? (
            <Alert severity="error">{t('programMissingYear')}</Alert>
          ) : (
            <RDEqipCurrentPracticesInput
              {...props}
              onError={onError}
              onSuccess={() => {
                onSuccess?.()
                fieldModels.refetch()
              }}
              onUpdating={onUpdating}
              options={options}
              year={year}
            />
          )
        },
      },
    ] as GridColDef[]
  }, [options.join(), detailRequirements?.[0]?.year])

  const getRowHeight = useCallback(
    (p: GridRowHeightParams) => 'auto' as GridRowHeightReturnValue,
    []
  )

  return (
    <Stack spacing={3}>
      <Typography>
        {t('question', { context: userRole, ownerName, count: resourceIds.length })}
      </Typography>
      <DataGridPro
        rows={fieldModels.data?.items ?? []}
        getRowHeight={getRowHeight}
        columns={columns}
        loading={fieldModels.isPending}
        autoHeight
      />
    </Stack>
  )
}
