import { LandThumbnail, LandThumbnailSkin } from '@cibo/landmanager'
import { Loading } from '@cibo/profile'
import { ResponsiveDialog } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Stack, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateWorkflow } from '../../queries'
import { useFieldOverlaps } from './useFieldOverlaps'

type FieldOverlapsModalProps = {
  open: boolean
  onClose(): void
  workflowId?: string
  onOverlapsRemoved?: () => void
}

export const FieldOverlapsModal = ({
  open,
  onClose,
  workflowId,
  onOverlapsRemoved,
}: FieldOverlapsModalProps) => {
  const { t } = useTranslation('@cibo/programs/FieldOverlapsModal')
  const fieldOverlaps = useFieldOverlaps(workflowId)

  const updateWorkflow = useUpdateWorkflow()

  const { overlaps } = fieldOverlaps

  useEffect(() => {
    if (fieldOverlaps.isFetched && fieldOverlaps.isReady && !overlaps?.length) {
      onClose()

      onOverlapsRemoved && onOverlapsRemoved()
    }
  }, [fieldOverlaps.isFetched, fieldOverlaps.isReady, overlaps?.length])

  const removeField = (fieldId: string) =>
    !!workflowId &&
    updateWorkflow.mutateAsync({
      workflowId,
      updates: { fields: [fieldId], action: 'remove' },
    })

  const currentOverlap = overlaps && overlaps[0]

  const isPending = !fieldOverlaps.isReady || updateWorkflow.isPending

  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle>{t('overlapWarningTitle')}</DialogTitle>
      <DialogContent>
        {!currentOverlap && (isPending ? <Loading /> : <Typography>{t('noOverlap')}</Typography>)}

        {!!currentOverlap && (
          <Stack alignItems="center" spacing={2}>
            <Typography>{t('cta')}</Typography>
            <Stack direction="row" spacing={4}>
              <Stack alignItems="center">
                <LandThumbnail
                  field={currentOverlap[0]}
                  width={60}
                  height={60}
                  skin={LandThumbnailSkin.SMALL}
                />

                <Typography>{currentOverlap[0].name}</Typography>

                <Button
                  disabled={isPending}
                  onClick={() => removeField(currentOverlap[1].resourceId)}
                >
                  {t('chooseField')}
                </Button>
              </Stack>

              <Stack alignItems="center">
                <LandThumbnail
                  field={currentOverlap[1]}
                  width={60}
                  height={60}
                  skin={LandThumbnailSkin.SMALL}
                />

                <Typography>{currentOverlap[1].name}</Typography>

                <Button
                  disabled={isPending}
                  onClick={() => removeField(currentOverlap[0].resourceId)}
                >
                  {t('chooseField')}
                </Button>
              </Stack>
            </Stack>
          </Stack>
        )}
      </DialogContent>

      <DialogActions>
        <LoadingButton loading={isPending} variant="contained" onClick={onClose}>
          {!currentOverlap ? t('continue') : t('resolveLater')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
