import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RDGrazing } from '@cibo/core'
import { EventDetailEditor } from '../EventDetail'
import { DetailEditorPropsByYear } from '../types'
import { GrazingEventEditor } from './GrazingEventEditor'

export const GrazingEditor = ({
  fieldModels,
  year,
  name = 'grazing',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/GrazingEditor')
  const GrazingEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <Typography>{t('date')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('species')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('stockingRate')}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
  return (
    <EventDetailEditor<RDGrazing['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={GrazingEventTableHead}
      EventComponent={GrazingEventEditor}
      translationContext="grazing"
      defaultEvent={{ species: '', stockingRate: 0, startDate: null, endDate: null }}
    />
  )
}
