import { MpxAggMetricFieldType, MpxQueryTrigger, MpxSearchQuery, MpxSearchResult } from '@cibo/core'
import { PropsWithChildren, createContext, useState } from 'react'

interface ParcelSearchContextProps {
  dataLayer: MpxAggMetricFieldType
  previousResult?: MpxSearchResult
  query?: MpxSearchQuery
  result?: MpxSearchResult
  setDataLayer: (dataLayer: MpxAggMetricFieldType) => void
  setQuery: (query: MpxSearchQuery) => void
  setResult: (result: MpxSearchResult) => void
  setTrigger: (trigger?: MpxQueryTrigger) => void
  trigger?: MpxQueryTrigger
}

export const ParcelSearchContext = createContext<ParcelSearchContextProps>({
  dataLayer: 'count',
  setDataLayer: () => null,
  setQuery: () => null,
  setResult: () => null,
  setTrigger: () => null,
})
ParcelSearchContext.displayName = 'ParcelSearchContext'

export const ParcelSearchProvider = ({ children }: PropsWithChildren<{}>) => {
  const [result, setResult] = useState<MpxSearchResult>()
  const [query, setQuery] = useState<MpxSearchQuery>({
    fldUsage: ['-any'],
    fldHistory: ['-any'],
    tags: ['lcav'],
  })
  const [previousResult, setPreviousResult] = useState<MpxSearchResult>()
  const [trigger, setTrigger] = useState<MpxQueryTrigger>()
  const [dataLayer, setDataLayer] = useState<MpxAggMetricFieldType>('count')

  const rememberLastResult = (newResult: MpxSearchResult) => {
    setPreviousResult(result)
    setResult(newResult)
  }

  return (
    <ParcelSearchContext.Provider
      value={{
        dataLayer,
        previousResult,
        query,
        result,
        setDataLayer,
        setQuery,
        setResult: rememberLastResult,
        setTrigger,
        trigger,
      }}
    >
      {children}
    </ParcelSearchContext.Provider>
  )
}
