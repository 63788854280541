export * from './CropHistory'
export * from './CropPalette'
export * from './Elevations'
export * from './USCounties'
export * from './USStates'

export const MAX_FIELD_PAGE_SIZE = 500

export enum MapPreferenceDataLayer {
  COUNT = 'count',
  REGENERATIVE_POTENTIAL = 'regenPotentialPerAcre',
  PROJECTED_YIELD_CORN = 'corn',
  PROJECTED_YIELD_SOY = 'soybeans',
  PROJECTED_YIELD_COTTON = 'cotton',
  VALUATION = 'valuePerAcre',
  LEASE_VALUATION = 'operatorRent',
  NCCPI = 'nccpi',
  PRODUCTIVITY = 'productivity',
  SUSTAINABILITY = 'sustainability',
  STABILITY = 'stability',
  ACREAGE = 'acreage',
  TILLABLE_PERCENT = 'tillablePercent',
}

export const ZONE_FILL = {
  0: {
    color: '#939393',
    opacity: 1,
    key: 'Insufficient Data',
  },
  1: {
    color: '#d63b10',
    opacity: 1,
    key: 'Unstable',
  },
  2: {
    color: '#cdd657',
    opacity: 1,
    key: 'Low',
  },
  3: {
    color: '#79ba10',
    opacity: 1,
    key: 'Medium',
  },
  4: {
    color: '#008b01',
    opacity: 1,
    key: 'High',
  },
}

export type ZONE = keyof typeof ZONE_FILL

export const SOIL_TEXTURES = [
  '#b15928',
  '#1f78b4',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
]

export const ELEVATION_COLORS = [
  '#cdf4f9',
  '#b4e6ed',
  '#9bd8e1',
  '#81cad5',
  '#64bcc9',
  '#43afbe',
  '#00a1b3',
  '#0c8e9e',
  '#127c89',
  '#156b76',
  '#165a62',
  '#154950',
  '#13393e',
]

export const soilType_aCategoricalRange = [
  '#b15928',
  '#1f78b4',
  '#33a02c',
  '#fb9a99',
  '#e31a1c',
  '#fdbf6f',
  '#ff7f00',
  '#cab2d6',
  '#6a3d9a',
  '#1f77b4',
  '#ff7f0e',
  '#2ca02c',
  '#d62728',
  '#9467bd',
  '#8c564b',
  '#e377c2',
  '#7f7f7f',
  '#bcbd22',
  '#17becf',
]
