import { RDUsdaAgiThreshold } from '@cibo/core'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'

import { useAuth, useUpdateUserDetails, useUserDetails } from '../../../../hooks'
import { useOrgUser } from '../../../../queries'

/**
 * Assumptions:
 * 1. you will only pass a single resourceId in the resourceIds array
 * 2. there will only ever be a single detail for a given resourceId
 */
export const RDUsdaAgiThresholdTaskEditor = ({
  detailRequirements,
  resourceIds: [resourceId],
  onUpdating,
  onError,
  onSuccess,
}: ResourceDetailFeatureTaskEditorProps<RDUsdaAgiThreshold>) => {
  const { t } = useTranslation('@cibo/profile/RDUsdaAgiThresholdTaskEditor')
  const details = useUserDetails({ resourceId, detailRequirements })
  const updateUserDetails = useUpdateUserDetails<RDUsdaAgiThreshold>()
  const { userId } = useAuth()
  const user = useOrgUser(resourceId)
  const context = resourceId === userId?.toString() ? 'participant' : 'manager'
  const ownerName = user.data?.displayNameFull ?? ''

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdating?.()
    updateUserDetails
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'usdaAgiThreshold',
            year: detailRequirements[0].year as number,
            result: event.target.value === 'true' ? true : false,
          } as RDUsdaAgiThreshold,
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }

  const disabled =
    details.data?.[0]?.immutable || details?.isFetching || updateUserDetails?.isPending

  return (
    <Stack>
      <Typography>{t('label', { context, name: ownerName })}</Typography>
      <FormControl component="fieldset" variant="standard" error={updateUserDetails.isError}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={details?.data?.[0]?.result?.toString() ?? ''}
          onChange={onChange}
        >
          <FormControlLabel
            data-testid="yesBtn"
            value="true"
            control={<Radio />}
            label={t('yes')}
            disabled={disabled}
          />
          <FormControlLabel
            data-testid="noBtn"
            value="false"
            control={<Radio />}
            label={t('no')}
            disabled={disabled}
          />
        </RadioGroup>
        <FormHelperText>
          <Markdown overrides={{ small: { component: Typography, props: { variant: 'caption' } } }}>
            {t('helperMd', { context, name: ownerName })}
          </Markdown>
        </FormHelperText>
        <Collapse in={updateUserDetails.isError}>
          <FormHelperText>{updateUserDetails.error?.response?.data?.message}</FormHelperText>
        </Collapse>
      </FormControl>
    </Stack>
  )
}
