/* istanbul ignore file */
import { RecommendationPointsResponse } from '../types/RecommendationPointsTypes'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  SUMMARY: '/program-engagement-recommendations/:programEngagementId',
}

// @deprecated - Still here for mp-node PDF generation
export class ProgramEngagementRecommendationsAPI {
  static summary(id: string, options?: any) {
    return agent
      .get<RecommendationPointsResponse>(
        generatePath(PATHS.SUMMARY, { programEngagementId: id }),
        options
      )
      .then(a => a.data)
  }
}
