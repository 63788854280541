import { RDShipmentDistance } from '@cibo/core'
import * as Yup from 'yup'
import { ITraitFeatureById } from '../types'
import { findInitialValuesDetail } from '../utils'
import { ShipmentDistanceCell } from './ShipmentDistanceCell'
import { ShipmentDistanceEditor } from './ShipmentDistanceEditor'

const TRAIT_ID = 'shipmentDistance'

export const ShipmentDistance: ITraitFeatureById<RDShipmentDistance> = {
  traitId: TRAIT_ID,
  resultsOnly: true,
  cellDisplay: ShipmentDistanceCell,
  editor: ShipmentDistanceEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail ? detail.result : ''
  },
  ns: '@cibo/landmanager/ShipmentDistance',
  validationSchema: ({ t }) => Yup.number().min(0).required(t('required')),
  detailFromEditorValues: (values: { input: number }) => ({
    traitId: TRAIT_ID,
    result: values.input,
  }),
}
