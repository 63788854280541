/* istanbul ignore file */
import { stringify } from 'query-string'
import { LandProfileResult } from '../types'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  LAND_PROFILE: '/insights/landProfile',
  STABILITY_MAP: '/insights/stabmap',
}
export class InsightsApi {
  static landProfiles(resourceIds: string[]): Promise<LandProfileResult> {
    return agent.get(`${PATHS.LAND_PROFILE}?${stringify({ resourceIds: resourceIds.join(',') })}`)
  }

  static stabilityMap(resourceId: string): Promise<any> {
    return agent.get(`${PATHS.STABILITY_MAP}?${stringify({ resourceId })}`, { timeout: 60 * 1000 })
  }
}
