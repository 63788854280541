import { ResourceDetail } from '../ResourceDetail'

export const EQIP_FURROW_IRRIGATION_OPTIONS = ['yes', 'no', 'unknown'] as const
export type EqipFurrowIrrigationOption = typeof EQIP_FURROW_IRRIGATION_OPTIONS[number]

export interface RDEqipPracticesIrrigationMethodInput {
  furrowIrrigation: EqipFurrowIrrigationOption
}

export interface RDEqipPracticesIrrigationMethod extends ResourceDetail {
  traitId: 'eqipPracticesIrrigationMethod'
  year: number
  result: RDEqipPracticesIrrigationMethodInput
  input: RDEqipPracticesIrrigationMethodInput
}
