import { FormControlLabel, Radio, Stack, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../../types'
import { useFieldsOwner } from '../../../../hooks'
import { RadioGroup } from 'formik-mui'
import { EQIP_FURROW_IRRIGATION_OPTIONS } from '@cibo/core'

export const EqipIrrigationMethodEditor = ({
  name = 'eqipPracticesIrrigationMethod',
  requirement,
  fieldModels,
  year,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/EqipIrrigationMethod')

  const owner = useFieldsOwner(fieldModels)

  return (
    <Field name={name} id={name}>
      {({ form: { isSubmitting } }: FieldProps) => {
        return (
          <Stack spacing={2}>
            <Typography>
              {t('cta', {
                context: owner?.isSelf ? 'owner' : 'notOwner',
                count: fieldModels?.length,
                ownerName: owner?.displayName,
                period: year,
              })}
            </Typography>

            <Field name={`${name}.furrowIrrigation`} component={RadioGroup} row>
              {EQIP_FURROW_IRRIGATION_OPTIONS.map(option => (
                <FormControlLabel
                  key={option}
                  value={option}
                  control={<Radio />}
                  label={`${t(`furrowIrrigation_${option}`)}`}
                  disabled={isSubmitting}
                  data-testid={`${name}::${option}`}
                />
              ))}
            </Field>
          </Stack>
        )
      }}
    </Field>
  )
}
