import 'jimp/browser/lib/jimp'

export default class Image {
  constructor(options = {}) {
    this.options = options
    this.width = this.options.width
    this.height = this.options.height
    this.quality = this.options.quality || 100
  }

  /**
   * Prepare all tiles to fit the baselayer
   */
  prepareTileParts(tile) {
    const { image } = tile

    return new Promise(resolve => {
      const metadata = { width: image.bitmap.width, height: image.bitmap.height }

      const x = tile.box[0]
      const y = tile.box[1]
      const sx = x < 0 ? 0 : x
      const sy = y < 0 ? 0 : y
      const dx = x < 0 ? -x : 0
      const dy = y < 0 ? -y : 0
      const extraWidth = x + (metadata.width - this.width)
      const extraHeight = y + (metadata.width - this.height)
      const w = metadata.width + (x < 0 ? x : 0) - (extraWidth > 0 ? extraWidth : 0)
      const h = metadata.height + (y < 0 ? y : 0) - (extraHeight > 0 ? extraHeight : 0)

      // Fixed #20 https://github.com/StephanGeorg/staticmaps/issues/20
      if (!w || !h) {
        resolve({ success: false })
        return null
      }

      try {
        image.crop(dx, dy, w, h)
        resolve({
          success: true,
          position: { top: Math.round(sy), left: Math.round(sx) },
          image,
        })
      } catch (err) {
        resolve({ success: false })
      }
    })
  }

  async draw(tiles) {
    if (!this.image) {
      const { width, height } = this

      const baselayer = await new Promise(function (resolve, reject) {
        new Jimp(width, height, 0x00000000, (err, image) => {
          if (err) reject(err)
          resolve(image)
        })
      })
      this.tempImage = baselayer
    } else {
      this.tempImage = this.image
    }

    // Prepare tiles for composing baselayer
    const tileParts = []
    tiles.forEach((tile, i) => {
      tileParts.push(this.prepareTileParts(tile, i))
    })

    const results = await Promise.all(tileParts)

    const preparedTiles = results.filter(v => v.success)

    for (const tile of preparedTiles) {
      this.tempImage.composite(
        tile.image,
        Math.round(tile.position.left),
        Math.round(tile.position.top)
      )
    }

    this.image = this.tempImage
    return true
  }

  /**
   * Return image as buffer
   */
  async buffer(mime = 'image/png', outOpts = {}) {
    const outputOptions = outOpts
    outputOptions.quality = outputOptions.quality || this.quality
    switch (mime.toLowerCase()) {
      case 'image/jpeg':
      case 'image/jpg':
        return this.image.getBufferAsync(Jimp.MIME_JPEG)
      case 'image/png':
      default:
        return this.image.getBufferAsync(Jimp.MIME_PNG)
    }
  }
}
