import { UserId } from '@cibo/core'
import { ProgressBar, ProgressBarProps } from '@cibo/ui'
import { Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useProgramStats } from '../../queries'
import { useProgramLimit } from '../ProgramLimitFeedback/ProgramLimitContext'

interface ProgramLimitMeterProps extends Omit<ProgressBarProps, 'percent'> {
  programId: string
  hideUnderPercent?: number
  userId?: UserId
  displayUnit?: 'acres' | 'fields'
}

export const ProgramLimitMeter = ({
  hideUnderPercent = 0,
  programId,
  userId,
  displayUnit,
  ...progressBarProps
}: ProgramLimitMeterProps) => {
  const { t } = useTranslation('@cibo/programs/ProgramLimitMeter')
  const { limits } = useProgramLimit()
  const stats = useProgramStats({ userId })
  const {
    palette: { error: light },
  } = useTheme()
  const limitForDisplayUnit = limits
    ? displayUnit
      ? limits.find(a => (a.display ? a.unit === displayUnit : false))
      : limits[0]
    : undefined

  if (!limitForDisplayUnit || limitForDisplayUnit.display === false) {
    return null
  }

  const {
    limitValue,
    unit,
    committed,
    limitType,
    context,
    totalResourceCountForLimit,
    currentGrowerCommitted,
    workflowResourceCountForUnit,
  } = limitForDisplayUnit

  const userIsOverCommitting = limitType === 'overCommit' && context === 'grower'
  const commitOfConcern = userIsOverCommitting
    ? currentGrowerCommitted + workflowResourceCountForUnit
    : committed

  const stat = stats.data?.programs.find(program => program.programId === programId)
  if (
    !stat ||
    !limitValue ||
    commitOfConcern === undefined ||
    commitOfConcern / limitValue < hideUnderPercent
  ) {
    return null
  }
  const percent = commitOfConcern / limitValue

  return (
    <Stack>
      <ProgressBar
        {...progressBarProps}
        percent={percent}
        fillColor={percent >= 1 ? light.main : undefined}
      />
      <Typography ml={0.5} variant="caption">
        {userIsOverCommitting
          ? t(`${unit}UserLimit`, {
              enrollmentContains: totalResourceCountForLimit,
              limit: limitValue,
            })
          : t(`${unit}Limit`, { committed, limit: limitValue })}
      </Typography>
    </Stack>
  )
}
