import { max, min, reduce } from 'ramda'
import { assertBBoxArray, Bounds, Array2d, BBoxArray } from './bboxTypes'

export const boundsFromArray2d = (bbox: Array2d) => ({
  lowerLeft: { Longitude: bbox[0][0], Latitude: bbox[0][1] },
  upperRight: { Longitude: bbox[1][0], Latitude: bbox[1][1] },
})

export const array2dFromBounds = (bounds: Bounds) =>
  [
    [bounds.lowerLeft.Longitude, bounds.lowerLeft.Latitude],
    [bounds.upperRight.Longitude, bounds.upperRight.Latitude],
  ] as Array2d

export const bbox2dFromBboxArray = ([llLon, llLat, urLon, urLat]: BBoxArray) => [
  [llLon, llLat],
  [urLon, urLat],
]
export const bboxArrayFromBbox2d = ([[llLon, llLat], [urLon, urLat]]: Array2d) => {
  const bbox = [llLon, llLat, urLon, urLat]
  assertBBoxArray(bbox)
  return bbox
}

const isLatitude = (num: number) => isFinite(num) && Math.abs(num) <= 90
const isLongitude = (num: number) => isFinite(num) && Math.abs(num) <= 180

export const arrayFromBboxString = (bbox: string) => {
  const parts = bbox.split(',').map(parseFloat)

  const lengthIsFour = parts.length === 4
  const allNumbers = parts.every(coord => !isNaN(coord))

  const valid =
    isLongitude(parts[0]) && isLatitude(parts[1]) && isLongitude(parts[2]) && isLatitude(parts[3])

  if (!lengthIsFour || !allNumbers || !valid) {
    throw Error(`invalid bbox string "${bbox}"`)
  }

  return parts as [number, number, number, number]
}

export const bbox2dFromBboxString = (bbox: string) => {
  const parts = arrayFromBboxString(bbox)
  assertBBoxArray(parts)
  return bbox2dFromBboxArray(parts)
}

export const boundsOfArrayOf2dBounds = (bboxes: Array2d[]) => [
  [
    reduce(
      min,
      Infinity,
      bboxes.map(item => item[0][0])
    ) as number,
    reduce(
      min,
      Infinity,
      bboxes.map(item => item[0][1])
    ) as number,
  ],
  [
    reduce(
      max,
      -Infinity,
      bboxes.map(item => item[1][0])
    ) as number,
    reduce(
      max,
      -Infinity,
      bboxes.map(item => item[1][1])
    ) as number,
  ],
]
