import { CropType } from '../types'
import { FieldUpdate, PortfolioUpdate, UserMessage, YieldChange } from '../types/Messages'

export const hasYieldChangeMessages = ({
  crop,
  includeUnread,
  message,
}: {
  message: UserMessage
  crop: CropType
  includeUnread?: boolean
}) => {
  if (!includeUnread && message.messageReadStatus) {
    return false
  }
  if (message.messageType === 'fieldUpdate') {
    return (message.messageData as FieldUpdate).changes?.some(
      change => (change as YieldChange).latestForecast?.data.crop === crop
    )
  } else if (message.messageType === 'pfUpdate') {
    return Object.values((message.messageData as PortfolioUpdate).fieldData)?.some(fieldUpdate => {
      return fieldUpdate.changes?.some(
        change => (change as YieldChange).latestForecast?.data.crop === crop
      )
    })
  }
  return false
}
