import { ResourceDetail } from './ResourceDetail'

export const LIMING_UNIT = ['poundsPerAcre', 'tonsPerAcre'] as const
export type LimingUnit = typeof LIMING_UNIT[number]
export const LIMING_TYPE = ['limestone', 'dolomite'] as const
export type LimingType = typeof LIMING_UNIT[number]

export type LimingEvent = {
  date: Date
  amount?: number
  unit: LimingUnit
  type: LimingType
}

export type LimingEvents = {
  events: LimingEvent[]
}

export interface RDLiming extends ResourceDetail {
  traitId: 'liming'
  year: number
  result: number
  input: LimingEvents
}
