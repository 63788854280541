import { RDEqipPracticesIrrigationQuantity } from '@cibo/core'

import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const EqipIrrigationQuantityCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDEqipPracticesIrrigationQuantity>) => {
  const { t } = useTranslation('@cibo/landmanager/EqipIrrigationQuantity')
  const detail = params.row.resolveStandingDetail<RDEqipPracticesIrrigationQuantity>(traitId, year)

  if (!detail) return <>--</>

  return <>{t(`irrigationQuantity_${detail.result?.irrigationQuantity}`)}</>
}
