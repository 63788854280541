import { Box, Chip, ChipProps } from '@mui/material'
import { styled } from '@mui/material/styles'

interface FieldLabelProps extends ChipProps {
  multiple?: boolean
}

export const Label = styled(Chip)<ChipProps>(() => ({
  '&.MuiChip-root': {
    borderRadius: 4,
    '&.MuiChip-sizeSmall': {
      height: 18,
    },
  },
  '& .MuiChip-label': {
    paddingTop: 0,
    paddingBottom: 0,
    paddingLeft: 8,
    paddingRight: 8,
    fontSize: 12,
    lineHeight: '1.25rem',
    textOverflow: 'ellipsis',
  },
}))

export const FieldLabel = ({ multiple, onClick, ...chipProps }: FieldLabelProps) => (
  <Box position="relative" sx={{ overflow: 'hidden', pr: multiple ? 1 : 0 }}>
    <Label
      color="secondary"
      onClick={onClick}
      {...chipProps}
      sx={{ ...chipProps.sx, zIndex: 1, position: 'relative' }}
      size="small"
    />
    {multiple && (
      <>
        <Label
          {...chipProps}
          color="secondary"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 4,
            zIndex: 0,
            opacity: 0.4,
            color: 'transparent',
          }}
          size="small"
        />
        <Label
          {...chipProps}
          color="secondary"
          sx={{
            position: 'absolute',
            bottom: 0,
            left: 8,
            zIndex: 0,
            opacity: 0.4,
            color: 'transparent',
          }}
          size="small"
        />
      </>
    )}
  </Box>
)
