import { PRIORITIZED_DISPLAYABLE_CASH_CROPS, PRIORITY_CASH_CROPS } from '@cibo/core'
import { AutocompleteField } from '@cibo/ui'
import { FormControl, FormLabel, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const FutureCashCropEditor = ({
  fieldModels = [],
  year,
  name = 'futureCashCropEditor',
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/FutureCashCropEditor')
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')

  return (
    <Stack spacing={2}>
      <Typography>{t('cta', { year })}</Typography>

      <FormControl required>
        <FormLabel>{t('whatCrop')}</FormLabel>

        <AutocompleteField
          name={`${name}.crop`}
          groupBy={c =>
            PRIORITY_CASH_CROPS && PRIORITY_CASH_CROPS.includes(c) ? t('suggested') : t('all')
          }
          options={PRIORITIZED_DISPLAYABLE_CASH_CROPS}
          getOptionLabel={(option: string) => cropT(option)}
          data-testid={`${name}.crop`}
          selectOnFocus
        />
      </FormControl>
    </Stack>
  )
}
