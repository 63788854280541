import { RDCornNitrogenApplication, RDCornNitrogenApplicationInput, TraitId } from '@cibo/core'
import { boolean, object } from 'yup'

import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { CornNitrogenApplicationCell } from './CornNitrogenApplicationCell'
import { CornNitrogenApplicationEditor } from './CornNitrogenApplicationEditor'

const TRAIT_ID: TraitId = 'cornNitrogenApplication'

export const CornNitrogenApplication: ITraitFeatureByIdYear<RDCornNitrogenApplication> = {
  traitId: TRAIT_ID,
  cellDisplay: CornNitrogenApplicationCell,
  editor: CornNitrogenApplicationEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail<RDCornNitrogenApplication>(TRAIT_ID)(props)
    return detail?.input ?? ({} as RDCornNitrogenApplicationInput)
  },
  ns: '@cibo/landmanager/CornNitrogenApplication',
  validationSchema: ({ t }) =>
    object()
      .shape({
        addedNitrogenStabilizer: boolean().required(t('required')),
        hasPreviousManagementData: boolean().required(t('required')),
        nitrogenApplicationReduced: boolean().required(t('required')),
      })
      .required(t('required')),
}
