import { AuthUserLevel } from '@cibo/core'
export { AuthUserLevel, AuthUserPermission } from '@cibo/core'
export * from './MpxFavoritesTypes'
export * from './UserMpData'

export enum ENV {
  DEV = 'dev',
  PROD = 'prod',
}

export interface UserProperties {
  environment: ENV
  devUserId?: string | null
  auth_user_level?: AuthUserLevel | null
}
