import { MpxUser, AuthUserLevel } from './MpxUser'
import { AxiosResponse } from 'axios'
export const MP_USER_INFO_PENDING = 'MP_USER_INFO_PENDING'
export const MP_USER_INFO_SUCCESS = 'MP_USER_INFO_SUCCESS'
export const MP_USER_INFO_ERROR = 'MP_USER_INFO_ERROR'
export const MP_USER_INFO_LEVEL_CHANGED = 'MP_USER_INFO_LEVEL_CHANGED'

export interface UserInfoResult extends MpxUser {}

export interface UserInfoError {
  activity: string
  duration: number
  httpCode: number
  message: string
  source: string
}
interface UserInfoPendingAction {
  type: typeof MP_USER_INFO_PENDING
}

export interface UserInfoSuccessAction {
  type: typeof MP_USER_INFO_SUCCESS
  results: AxiosResponse<UserInfoResult>
}

interface UserInfoErrorAction {
  type: typeof MP_USER_INFO_ERROR
  error: UserInfoError
}

export interface UserInfoLevelChangedAction {
  type: typeof MP_USER_INFO_LEVEL_CHANGED
  payload: { authUserLevel: AuthUserLevel }
}

export type UserInfoActionTypes =
  | UserInfoPendingAction
  | UserInfoSuccessAction
  | UserInfoErrorAction
  | UserInfoLevelChangedAction
