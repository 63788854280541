import { BenchmarkStateTraitReason, BenchmarkStatusReason, TraitId } from '@cibo/core'
import { collectBy } from 'ramda'
import { useTranslation } from 'react-i18next'

export const useReasonMessages = () => {
  const { t } = useTranslation(['@cibo/ui/useReasonMessages'])

  const byReasonCode = ({
    reasonCode,
    traitId,
    count,
    years,
  }: {
    reasonCode: BenchmarkStateTraitReason
    traitId: TraitId
    count?: number
    years?: string
  }) => t(`reasonCode_${reasonCode}`, {
      context: traitId,
      traitName: t(`${traitId}`, { ns: 'traits' }),
      count,
      years,
    })

  const groupByCode = (reasons: BenchmarkStatusReason[]) => {
    // @ts-ignore
    const reasonGroups: BenchmarkStatusReason[][] = collectBy(a => `${a.reason}${a.traitId}`)(
      reasons
    )
    return reasonGroups.map(group => {
      const years = group.map(a => a.year).sort()
      const yearString =
        years.length > 2
          ? `${years.shift()}-${years.pop()}`
          : years.length === 2
          ? years.join(' & ')
          : years.join(', ')
      return byReasonCode({
        reasonCode: group[0].reason,
        traitId: group[0].traitId,
        years: yearString,
        count: years.length,
      })
    })
  }

  return {
    byReasonCode,
    groupByCode,
  }
}
