import { UseMutationOptions, useMutation, useQueryClient } from '@tanstack/react-query'
import { PROGRAMS_QUERY_KEY } from '../types'
import { QUERY_KEY } from './queryKey'

import { FieldsAPI, MpxResponseError, ResourceDetailsUpdate } from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { AxiosError } from 'axios'

export const useUpdateMultipleFields = (
  mutationOptions?: Pick<UseMutationOptions, 'onSuccess'>
) => {
  const queryClient = useQueryClient()

  return useMutation<unknown, AxiosError<MpxResponseError>, ResourceDetailsUpdate[]>({
    mutationKey: [QUERY_KEY.UPDATE_MULTIPLE_FIELDS],
    mutationFn: (updates: ResourceDetailsUpdate[]) => FieldsAPI.updateMultipleFields(updates),

    onSuccess: (event, updates) => {
      updates.forEach(({ resourceId }) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS, resourceId] })
        queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES] })
      })
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY.FIELDSET,
          updates
            .map(({ resourceId }) => resourceId)
            .filter(Boolean)
            .join(','),
        ],
      })

    },
    onError: (error, variables, context) => {
      logRequestError(error, { query: 'useUpdateMultipleFields', variables, context })
      return error
    },
  })
}
