import AddIcon from '@mui/icons-material/Add'
import ExploreIcon from '@mui/icons-material/Explore'
import RemoveIcon from '@mui/icons-material/Remove'
import { ButtonGroup, IconButton } from '@mui/material'
import { Crosshairs } from 'mdi-material-ui'

interface NavigationControlProps {
  onOrientationReset?: () => void
  onRecenter?: () => void
  onZoomIn: () => void
  onZoomOut: () => void
}

export const NavigationControl = ({
  onZoomIn,
  onZoomOut,
  onOrientationReset,
  onRecenter,
}: NavigationControlProps) => (
  <ButtonGroup orientation="vertical" size="small">
    {!!onRecenter && (
      <IconButton size="small" onClick={onRecenter}>
        <Crosshairs />
      </IconButton>
    )}

    <IconButton onClick={onZoomIn} size="small" data-testid="zoomIn">
      <AddIcon />
    </IconButton>
    <IconButton onClick={onZoomOut} size="small" data-testid="zoomOut">
      <RemoveIcon />
    </IconButton>

    {!!onOrientationReset && (
      <IconButton onClick={onOrientationReset}>
        <ExploreIcon />
      </IconButton>
    )}
  </ButtonGroup>
)
