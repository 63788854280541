import { FieldModel, FieldQueryOptions } from '@cibo/core'
import { FieldLabel, fieldThumbnailColumn, infoColumns } from '@cibo/landmanager'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'
import { ProgramProgressChip } from '../ProgramProgressChip'

interface ProgramColumnFunctionProps {
  filters?: FieldQueryOptions
  onClickLabel?: (label: string) => () => void
  programId?: string
  t: TFunction
  tColumn: TFunction
}

const nameColumn = ({ filters, onClickLabel, t, tColumn }: ProgramColumnFunctionProps) => ({
  field: 'name',
  headerName: tColumn('name'),
  hideable: false,
  flex: 2,
  minWidth: 220,
  renderCell: (params: GridRenderCellParams<FieldModel, string>) => {
    const displayedLabels =
      !!filters?.labels && filters.labels.length > 0
        ? params.row.labels?.filter(l => filters.labels?.includes(l))
        : !!params.row.labels && params.row.labels[0]
        ? [params.row.labels[0]]
        : []
    const hiddenLabels = params.row.labels?.filter(l => !displayedLabels.includes(l))

    return (
      <Stack direction="row" spacing={1}>
        {displayedLabels.length > 0 && (
          <Stack direction="row" spacing={0.5}>
            {displayedLabels.map(label => (
              <FieldLabel
                key={label}
                label={label}
                size="small"
                color="secondary"
                onClick={!!onClickLabel ? onClickLabel(label) : undefined}
              />
            ))}
            {hiddenLabels.length === 1 && (
              <FieldLabel
                label={hiddenLabels[0]}
                size="small"
                color="secondary"
                onClick={!!onClickLabel ? onClickLabel(hiddenLabels[0]) : undefined}
              />
            )}
            {hiddenLabels.length > 1 && (
              <Tooltip
                title={
                  <Box sx={{ mb: -1 }}>
                    <Stack spacing={1} direction="row" flexWrap="wrap">
                      {hiddenLabels.map(label => (
                        <FieldLabel
                          key={label}
                          label={label}
                          size="small"
                          color="secondary"
                          sx={{ mb: 1 }}
                          onClick={!!onClickLabel ? onClickLabel(label) : onClickLabel}
                        />
                      ))}
                    </Stack>
                  </Box>
                }
                placement="top"
                arrow
              >
                <FieldLabel
                  label={`+${hiddenLabels.length}`}
                  size="small"
                  color="secondary"
                  variant="outlined"
                />
              </Tooltip>
            )}
          </Stack>
        )}
        <Typography>{params.row.name}</Typography>
      </Stack>
    )
  },
})
const growerColumn = ({ t }: ProgramColumnFunctionProps) => ({
  field: 'grower',
  headerName: t('grower'),
  flex: 1,
  minWidth: 200,
  valueGetter: (params: GridValueGetterParams<FieldModel, string>) => {
    return params.row.ownedBy.userPrimaryEmail
  },
})

const progressColumn = ({ programId, t }: ProgramColumnFunctionProps) => ({
  field: 'progress',
  headerName: t('progress'),
  minWidth: 200,
  valueGetter: (params: GridValueGetterParams<FieldModel, string>) => {
    const program = params.row.program(programId || '')

    return program?.progress
  },
  renderCell: (params: GridRenderCellParams<FieldModel>) => (
    <ProgramProgressChip progress={params.value} />
  ),
})

export const prequalificationColumns = ({
  filters,
  onClickLabel,
  programId,
  t,
  tColumn,
}: ProgramColumnFunctionProps) => [
  fieldThumbnailColumn({ t }),
  nameColumn({ filters, onClickLabel, programId, t, tColumn }),
  progressColumn({ filters, onClickLabel, programId, t, tColumn }),
  growerColumn({ filters, onClickLabel, programId, t, tColumn }),
  ...infoColumns({ t: tColumn }),
]
