import { Loading } from '@cibo/profile'
import { Markdown, RadioGroupFormikInput, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Radio,
  Stack,
  Typography,
} from '@mui/material'
import { Field, Form, Formik, useFormikContext } from 'formik'
import { equals } from 'ramda'
import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { BUSINESS_STRUCTURE, EqipFarmInformation, FSA_FILES_INFO, FarmInformation } from './types'

export const EqipFarmInformationTaskEditorForm = ({
  detailRequirements,
  resourceIds: [resourceId],
  onUpdating,
  onError,
  onSuccess,
  ownerName,
  userRole,
}: ResourceDetailFeatureTaskEditorProps<EqipFarmInformation>) => {
  const { t } = useTranslation('@cibo/programs/FarmInformationTaskEditor')
  // @ts-ignore not sure how to tell our types this detail uses value: {...}
  const updateResourceDetail = useUpdateProgramEngagementDetails<EqipFarmInformation>()
  const details = useProgramEngagementDetails({ resourceId, detailRequirements })
  const detail = details?.data?.[0]

  const { values, isValid, dirty } = useFormikContext()

  // @todo read from requirement
  const required = true

  useEffect(() => {
    if (!isValid || equals(detail?.value, values) || !dirty) return

    onUpdating?.()
    updateResourceDetail
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'eqipFarmInformation',
            year: detailRequirements[0].year,
            value: values,
          } as EqipFarmInformation,
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }, [values, isValid, dirty])

  const disabled =
    details.data?.[0]?.immutable || details?.isFetching || updateResourceDetail?.isPending

  return (
    <Form>
      <Stack spacing={2}>
        <Stack>
          <Typography variant="h6">{t('title')}</Typography>
          <Typography variant="caption">{t('cta')}</Typography>
        </Stack>

        <Stack>
          <FormLabel required={required}>
            {t('businessStructureCta', { context: userRole, name: ownerName })}
          </FormLabel>

          <FormControl component="fieldset" variant="standard" error={updateResourceDetail.isError}>
            <Field component={RadioGroupFormikInput} name="businessStructure">
              <FormControlLabel
                value="entity"
                data-testid="option-entity"
                control={<Radio />}
                label={t('businessStructure_entity', { context: userRole, name: ownerName })}
                disabled={disabled}
              />
              <FormControlLabel
                value="individual"
                data-testid="option-individual"
                control={<Radio />}
                label={t('businessStructure_individual', { context: userRole, name: ownerName })}
                disabled={disabled}
              />
            </Field>
          </FormControl>
        </Stack>

        <Stack>
          <FormLabel required={required}>
            {t('fsaCta', { context: userRole, name: ownerName })}
          </FormLabel>

          <FormControl component="fieldset" variant="standard" error={updateResourceDetail.isError}>
            <Field component={RadioGroupFormikInput} name="fsaFilesInfo">
              <FormControlLabel
                value="registeredUpToDate"
                data-testid="option-registeredUpToDate"
                control={<Radio />}
                label={t('registeredUpToDate', { context: userRole, name: ownerName })}
                disabled={disabled}
              />
              <FormControlLabel
                value="notRegistered"
                data-testid="option-notRegistered"
                control={<Radio />}
                label={t('notRegistered', { context: userRole, name: ownerName })}
                disabled={disabled}
              />
              <FormControlLabel
                value="registeredOutOfDate"
                data-testid="option-registeredOutOfDate"
                control={<Radio />}
                label={t('registeredOutOfDate', { context: userRole, name: ownerName })}
                disabled={disabled}
              />
            </Field>

            <FormHelperText>
              <Markdown
                overrides={{ small: { component: Typography, props: { variant: 'caption' } } }}
              >
                {t('helperMd', { context: userRole, name: ownerName })}
              </Markdown>
            </FormHelperText>
          </FormControl>
        </Stack>

        <Collapse in={updateResourceDetail.isError}>
          <FormHelperText>{updateResourceDetail.error?.response?.data?.message}</FormHelperText>
        </Collapse>
      </Stack>
    </Form>
  )
}

export const EqipFarmInformationTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<EqipFarmInformation>
) => {
  // @ts-ignore not sure how to tell our types this detail uses value: {...}
  const details = useProgramEngagementDetails<EqipFarmInformation>({
    resourceId: props.resourceIds[0],
    detailRequirements: props.detailRequirements,
  })
  const detail = details?.data?.[0]

  const schema = useMemo(
    () =>
      yup.object({
        fsaFilesInfo: yup.mixed().oneOf(FSA_FILES_INFO).required(),
        businessStructure: yup.mixed().oneOf(BUSINESS_STRUCTURE).required(),
      }),
    []
  )

  if (details.isPending) return <Loading />

  return (
    <Formik<FarmInformation>
      // @ts-ignore How to allow empty strings for these initial values?
      initialValues={detail?.value ?? { fsaFilesInfo: '', businessStructure: '' }}
      onSubmit={() => {}}
      validationSchema={schema}
      validateOnMount
    >
      <EqipFarmInformationTaskEditorForm {...props} />
    </Formik>
  )
}
