import { RDEqipEligibilityConservationCSP } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipEligibilityConservationCSPResourceDetailFeature: ResourceDetailFeature<RDEqipEligibilityConservationCSP> =
  {
    traitId: 'eqipEligibilityConservationCSP',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityConservationCSP>) => (
      <BooleanRollup<RDEqipEligibilityConservationCSP>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' } }}
        detailKey="cspEnrolled"
        ns="@cibo/landmanager/RDEqipEligibilityConservationCSPResourceDetailFeature"
        {...props}
      />
    ),
  }
