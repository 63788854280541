import { useOtherFieldStyles } from '@cibo/mapgl'
import { FeatureCollection, MultiPolygon, Polygon } from '@turf/turf'
import { Layer, Source } from 'react-map-gl/dist/es5'

type ControlGroupFieldsProps = {
  fieldShapes?: FeatureCollection<Polygon | MultiPolygon>
}

export const ControlGroupFields = ({ fieldShapes }: ControlGroupFieldsProps) => {
  const FIELD_STYLE = useOtherFieldStyles()

  if (!fieldShapes) return null

  return (
    <>
      <Source id="field-shapes" type="geojson" maxzoom={23} data={fieldShapes}>
        <Layer
          type="fill"
          // @ts-ignore
          paint={FIELD_STYLE.fillPaint}
        />
        <Layer
          type="line"
          layout={{
            // @ts-ignore
            'line-cap': 'round',
            'line-join': 'round',
          }}
          // @ts-ignore
          paint={FIELD_STYLE.linePaint}
        />
      </Source>
    </>
  )
}
