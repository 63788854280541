import React, { ReactNode, useMemo } from 'react'
import { Navigate, RouteProps } from 'react-router-dom'

import { usePermissions } from '../../hooks'
import { PermissionsArgs } from '../../utils'

interface CanBaseProps {
  children: ReactNode
}

interface CanFallbackProps extends PermissionsArgs, CanBaseProps {
  fallback: ReactNode
  redirectPath?: never
}
interface CanRedirectPathProps extends PermissionsArgs, CanBaseProps {
  fallback?: never
  redirectPath?: RouteProps['path']
}

const isFallback = (props: CanFallbackProps | CanRedirectPathProps): props is CanFallbackProps =>
  !!(props as CanFallbackProps).fallback

/**
 * Control structure which checks a user's permissions thus
 * unlocking access to child components.
 *
 * useAny can be either a string or array of strings
 * useAll can be either a string or an array of strings
 *
 * unlessAny can be either a string or array of strings
 * useAll can be either a string or an array of strings
 */
const Can = (props: CanFallbackProps | CanRedirectPathProps) => {
  const { isDebugUser, permissions, can } = usePermissions()
  const { children, unlessAny, unlessAll, useAny, useAll } = props

  // recheck permissions anytime the incoming props change
  const isAllowed = useMemo(
    () => can({ unlessAll, unlessAny, useAll, useAny }),
    [
      can,
      permissions.join(),
      Array.isArray(unlessAny) ? unlessAny.join() : unlessAny,
      Array.isArray(unlessAll) ? unlessAll.join() : unlessAll,
      Array.isArray(useAny) ? useAny.join() : useAny,
      Array.isArray(useAll) ? useAll.join() : useAll,
    ]
  )

  if (isDebugUser && !isFallback(props) && !!props.redirectPath) {
    console.log('redirect', { props })
  }

  if (isAllowed) {
    return <React.Fragment>{children}</React.Fragment>
  }

  return isFallback(props) ? (
    <React.Fragment>{props.fallback}</React.Fragment>
  ) : !!props.redirectPath ? (
    <Navigate to={props.redirectPath} replace />
  ) : null
}

export default Can
