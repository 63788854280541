import { AgreementStatus, AgreementType } from '@cibo/core'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import EventBusyRoundedIcon from '@mui/icons-material/EventBusyRounded'
import HighlightOffRoundedIcon from '@mui/icons-material/HighlightOffRounded'
import HourglassEmptyRoundedIcon from '@mui/icons-material/HourglassEmptyRounded'
import { ChipProps } from '@mui/material'

export const statusChipColor: Record<AgreementType, Record<AgreementStatus, ChipProps['color']>> = {
  dataCollection: {
    agreed: 'success',
    concluded: 'default', // not present
    pending: 'warning',
    declined: 'error',
    expired: 'default', // not present
  },
  offer: {
    agreed: 'default',
    concluded: 'success',
    pending: 'warning',
    declined: 'error',
    expired: 'warning',
  },
  adminReview: {
    agreed: 'success',
    concluded: 'default', // not present
    pending: 'warning',
    declined: 'error',
    expired: 'default', // not present
  },
  soilHealth: {
    agreed: 'default',
    concluded: 'default',
    pending: 'default',
    declined: 'default',
    expired: 'default', // not present
  },
  insights: {
    agreed: 'default',
    concluded: 'default',
    pending: 'default',
    declined: 'default',
    expired: 'default', // not present
  },
}

export const statusIcon: Record<AgreementStatus, any> = {
  agreed: <CheckCircleOutlineRoundedIcon color="success" />,
  concluded: <CheckCircleOutlineRoundedIcon />,
  pending: <HourglassEmptyRoundedIcon />,
  declined: <HighlightOffRoundedIcon color="error" />,
  expired: <EventBusyRoundedIcon />,
}
