import { SupplyShedAPI, SupplyShedSearchResult, SupplyShedSearchSortType } from '@cibo/core'
import { trim } from 'ramda'
import { useEffect, useState } from 'react'
import { useSupplyShedFilters } from '../../hooks'

export const useSupplyShedSearch = () => {
  const [isFetching, setIsFetching] = useState(false)
  const [queryText, setQueryText] = useState('')
  const [data, setData] = useState<SupplyShedSearchResult[]>()
  const [error, setError] = useState<Error>()
  const { aggregationType } = useSupplyShedFilters()

  useEffect(() => {
    const trimmed = trim(queryText)
    if (!trimmed) {
      setData([])
      return
    }

    let sortType: SupplyShedSearchSortType
    if (aggregationType === 'fips') {
      sortType = trimmed.length === 2 ? 'state' : 'county'
    } else {
      sortType = aggregationType
    }

    setIsFetching(true)
    setData([])

    SupplyShedAPI.search({ q: trimmed, limit: 25, sortType })
      .then(response => {
        setData(response.items)
      })
      .catch((error: Error) => {
        setError(error)
      })
      .finally(() => setIsFetching(false))
  }, [queryText, aggregationType])

  return {
    data,
    error,
    isFetching,
    queryText,
    setQueryText,
  }
}
