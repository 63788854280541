import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import { directLoginReducer } from './DirectLogin'
import { registerVerifiedUserReducer } from './RegisterVerifiedUser'
import { sessionReducer } from './Session'

export const getAuthReducer = (reduxPersistStorage: any) => {
  /**
   * The persisted storage should probably be of the 'secure' variety
   */
  const sessionPersistConfig = {
    key: 'session',
    storage: reduxPersistStorage,
    whitelist: ['userJwt'],
  }

  return combineReducers({
    registerVerifiedUser: registerVerifiedUserReducer,
    directLogin: directLoginReducer,
    session: persistReducer(sessionPersistConfig, sessionReducer),
  })
}

export type AuthState = ReturnType<typeof getAuthReducer>
