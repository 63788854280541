// @ts-nocheck
import { CollectionApi, GeometryApi, InsightsApi, LandProfileType, MpxParcelsAPI } from '@cibo/core'

/**
 * Focused on a single resourceId
 * @param resourceId
 *
 * @TODO: Define a type for LandReport based on LandProfile so that we know the
 * intended shape after this.
 */
export const collateLandReport = async (resourceId: string) => {
  const {
    data: {
      items: [landProfile],
    },
  } = await InsightsApi.landProfiles([resourceId])

  const tillableGeometryPromise =
    landProfile.tillableGeometrySlug && GeometryApi.getGeometry(landProfile.tillableGeometrySlug)

  if (landProfile.type === LandProfileType.PARCEL) {
    // @ts-ignore
    const [[parcel], tillableGeometry] = await Promise.all([
      MpxParcelsAPI.fetch({ parcelPkList: [resourceId] }),
      tillableGeometryPromise,
    ])

    return {
      ...parcel.properties,
      ...landProfile,
      entries: [
        {
          id: parcel.properties.parcelId,
          type: 'parcel',
          refItemId: parcel.properties.parcelPkId,
          geometrySlug: parcel.properties.geometrySlug,
          _meta: {
            landProfile,
          },
          refItem: {
            label: parcel.properties.label,
            taxInfo: {
              assessDate: parcel.properties.assessDate,
              assessVal: parcel.properties.assessVal,
              taxAmount: parcel.properties.taxAmount,
            },
          },
        },
      ],
      geometry: parcel.geometry,
      resourceId,
      tillableGeometry,
    }
  } else {
    const [
      {
        data: {
          items: [collection],
        },
      },
      tillableGeometry,
    ] = await Promise.all([CollectionApi.fetchCollection(resourceId), tillableGeometryPromise])

    return {
      ...collection,
      ...landProfile,
      resourceId,
      tillableGeometry,
    }
  }
}
