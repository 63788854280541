import { Checkbox, CheckboxProps } from '@mui/material'
import { Field, FieldProps, useField } from 'formik'
import { ChangeEvent, useCallback } from 'react'

interface CheckboxFieldProps extends CheckboxProps {
  name: string
}

export const CheckboxField = ({ name, ...checkboxProps }: CheckboxFieldProps) => {
  const field = useField(name)
  const value = field[0].value
  const { setValue, setTouched } = field[2]

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setTouched(true)
      setValue(event.target.checked)
    },
    [setValue, setTouched]
  )

  return (
    <Field name={name}>
      {({ form: { isSubmitting } }: FieldProps) => (
        <Checkbox
          id={name}
          checked={value || false}
          onChange={handleChange}
          {...checkboxProps}
          disabled={checkboxProps.disabled || isSubmitting}
        />
      )}
    </Field>
  )
}
