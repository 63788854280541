import { FormControl, FormLabel, Stack, TextField } from '@mui/material'
import { useField } from 'formik'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useLabelsSearch } from '../../../queries'
import { FieldLabelAutocomplete } from '../../FieldLabelAutocomplete'

export const LabelFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/LabelFilter')
  const [query, setQuery] = useState<string>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, { value: labels = [] }, { setValue: setLabels }] = useField('labels')

  const { data: labelOptions } = useLabelsSearch(query)

  const handleQueryChange = (event: any, value: string) => {
    setQuery(value)
  }

  const handleChangeLabels = (event: any, value: string[]) => {
    setLabels(value)
  }

  return (
    <FormControl>
      <FormLabel>{t('labels')}</FormLabel>
      <Stack spacing={1}>
        <FieldLabelAutocomplete
          multiple
          limitTags={2}
          //@ts-ignore
          options={labelOptions || []}
          renderInput={params => (
            <TextField {...params} placeholder={labels.length > 0 ? t('any') : ''} />
          )}
          onChange={handleChangeLabels}
          onInputChange={handleQueryChange}
          size="small"
          value={labels}
          freeSolo
          fullWidth
        />
      </Stack>
    </FormControl>
  )
}
