import { MpxResponseBase, UserId } from '@cibo/core'
import { mpAgent as agent } from './agent'

const PATHS = {
  FETCH_USER_DATA: '/user/mpdata/query',
  UPDATE_USER_DATA: '/user/mpdata/update',
}

export const USER_DATA_KEYS = {
  MAP_LAYERS: 'mapLayers',
  TERMS_AND_CONDITIONS: 'termsAndConditions',
  CUSTOM_PERMISSIONS: 'customPermissions',
  FIELDS_TABLE_GLOBAL: 'fieldsTableGlobal',
  FIELDS_TABLE_GLOBAL2: 'fieldsTableGlobal2',
  USERS_TABLE_GLOBAL: 'usersTableGlobal',
  FIRST_LOGIN: 'firstLogin',
}

export type UserDataKey = typeof USER_DATA_KEYS[keyof typeof USER_DATA_KEYS]

export interface MpxUserDataResponse extends MpxResponseBase {
  userMpData: Record<UserDataKey, any>
}

export interface MpxUserDataRequest {
  key: UserDataKey
  value: any
  userId?: UserId
}

export class MpxUserDataAPI {
  static fetch(userId?: UserId) {
    return agent
      .get<MpxUserDataResponse>(PATHS.FETCH_USER_DATA, { params: { userId } })
      .then(result => result && result.data.userMpData)
  }

  static update({ key, value, userId }: MpxUserDataRequest) {
    return agent
      .put(PATHS.UPDATE_USER_DATA, { userId, userMpData: { [key]: value } })
      .then(result => result && result.data)
  }
}
