import { DetailRequirement, FieldModel, ResourceDetail, TraitId } from '@cibo/core'
import { equals, max, min, pluck, uniqWith } from 'ramda'
import { InitialValuesRequest } from './types'

export const radioValue = (value: any) => {
  if (value === true) return 'true'
  if (value === false) return 'false'
  return ''
}

type FieldModelsHelpersArgs = {
  fieldModels: FieldModel[]
  traitId: TraitId
  year?: number
}

export const uniqueExceptDates = (detail1?: ResourceDetail, detail2?: ResourceDetail) => {
  return (
    equals(detail1?.confirmed, detail2?.confirmed) &&
    equals(detail1?.input, detail2?.input) &&
    equals(detail1?.result, detail2?.result) &&
    equals(detail1?.source, detail2?.source)
  )
}

export const countUniqueUnlockedValues = ({ fieldModels, traitId, year }: FieldModelsHelpersArgs) =>
  fieldModels.length > 1
    ? uniqWith(
        uniqueExceptDates,
        fieldModels
          .map(field =>
            !field.resolveStandingDetail(traitId, year)?.immutable
              ? field.resolveStandingDetail(traitId, year)
              : undefined
          )
          .filter(Boolean)
      ).length
    : 0

export const findInitialValuesDetail =
  <T extends ResourceDetail>(traitId: TraitId) =>
  ({ fieldModels, year }: InitialValuesRequest) => {
    if (fieldModels.length === 0) {
      return undefined
    }

    if (fieldModels.length === 1) {
      return fieldModels[0].resolveStandingDetail(traitId, year) as T
    }

    const standingDetails = fieldModels.map(field => field.resolveStandingDetail(traitId, year))

    const uniqueDetails = uniqWith(uniqueExceptDates, standingDetails)
    if (uniqueDetails.length === 1) {
      return uniqueDetails[0] as T
    }
  }

export const displayYearsRange = (requirements?: DetailRequirement[]) => {
  if (!requirements) {
    return '--'
  }

  const years = pluck('year', requirements).filter(Boolean)

  if (years.length === 0) {
    return '--'
  }
  if (years.length === 1) {
    return `${years[0]}`
  }

  const minYear = years.reduce(min, Infinity)
  const maxYear = years.reduce(max, -Infinity)

  return `${minYear}-${maxYear}`
}
