import { Box, Skeleton, Stack } from '@mui/material'

export function ListCell<T>({
  listItems,
  accessor,
  isPending,
}: {
  listItems?: T[]
  accessor: (arg: T) => string
  isPending: boolean
}) {
  return isPending ? (
    <Skeleton />
  ) : (
    <Stack direction="column" spacing={1} paddingY={2}>
      {listItems?.map((object: T) => (
        <Box key={accessor(object)}>{accessor(object)}</Box>
      ))}
    </Stack>
  )
}
