import { TILLAGE_TYPE } from '@cibo/core'
import { SelectField } from '@cibo/ui'
import { FormControl, FormLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const FutureTillageEditor = ({
  year,
  name = 'futureTillageEditor',
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/FutureTillageEditor')

  return (
    <FormControl>
      <FormLabel>{t('cta', { year: year - 1 })}</FormLabel>

      <SelectField
        fieldName={`${name}.type`}
        options={TILLAGE_TYPE}
        renderOption={tillageType => t(`tillage_${tillageType}`)}
      />
    </FormControl>
  )
}
