import { FUTURE_COVER_CROP_SPECIES } from '@cibo/core'
import { AutocompleteField } from '@cibo/ui'
import { FormControl, FormLabel, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const FutureCoverCropEditor = ({
  year,
  name = 'futureCoverCropEditor',
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/FutureCoverCropEditor')

  return (
    <Stack spacing={2}>
      <Typography>{t('cta', { year })}</Typography>

      <FormControl required>
        <FormLabel>{t('whatCrop')}</FormLabel>

        <AutocompleteField
          name={`${name}.crop`}
          options={FUTURE_COVER_CROP_SPECIES}
          getOptionLabel={(option: string) => t(option, { ns: '@cibo/ui/cropNames' })}
          data-testid={`${name}.crop`}
          selectOnFocus
        />
      </FormControl>
    </Stack>
  )
}
