import MoreVertical from '@mui/icons-material/MoreVert'
import {
  Box,
  FormControlLabel,
  FormGroup,
  IconButton,
  Paper,
  Popover,
  Slider,
  Stack,
  Switch,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
  styled,
} from '@mui/material'
import { ReactNode, useContext } from 'react'
import { useTranslation } from 'react-i18next'

import { AuthUserPermission, Can } from '@cibo/profile'
import { useState } from 'react'
import { CDL_YEARS } from './CDLLayer'
import { LayerContext } from './LayerContext'
import { MapStyles } from './const'

import cdlThumbnail from './img/cdl_thumbnail.png'
import satelliteThumbnail from './img/satellite_thumbnail.png'
import terrainThumbnail from './img/terrain_thumbnail.png'

export interface LayerSwitcherProps {
  toggler?: ReactNode
  mapLayers?: {
    [MapStyles.CDL]?: boolean
    [MapStyles.SATELLITE]?: boolean
    [MapStyles.FOCUSED]?: boolean
  }
  parcels?: boolean
  sections?: boolean
  townships?: boolean
  counties?: boolean
  terrain?: boolean
  hucWatersheds?: boolean
}

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  '&.Mui-selected': {
    backgroundColor: theme.palette.primary.main,
  },
}))

export const LayerSwitcher = ({
  toggler,
  mapLayers = {
    [MapStyles.CDL]: true,
    [MapStyles.SATELLITE]: true,
    [MapStyles.FOCUSED]: true,
  },
  parcels = true,
  sections = true,
  townships = true,
  counties = true,
  terrain = true,
  hucWatersheds = true,
}: LayerSwitcherProps) => {
  const { t } = useTranslation('LayerSwitcher')
  const {
    cdlYear,
    mapStyle,
    setCdlYear,
    setMapStyle,
    showCounties,
    showParcels,
    showSections,
    showTerrain,
    showTownships,
    showWatersheds,
    terrainExaggeration,
    setTerrainExaggeration,
    toggleCounties,
    toggleParcels,
    toggleSections,
    toggleTerrain,
    toggleTownships,
    toggleWatersheds,
  } = useContext(LayerContext)

  const handleMapStyle = (event: React.MouseEvent, value: MapStyles) =>
    setMapStyle && !!value && setMapStyle(value)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const layerOptions = []

  if (mapLayers.SATELLITE) {
    layerOptions.push({
      value: MapStyles.SATELLITE,
      label: 'satellite',
      thumbnail: satelliteThumbnail,
    })
  }

  if (mapLayers.FOCUSED) {
    layerOptions.push({
      value: MapStyles.FOCUSED,
      label: 'focused',
      thumbnail: terrainThumbnail,
    })
  }

  if (mapLayers.CDL) {
    layerOptions.push({ value: MapStyles.CDL, label: 'cdl', thumbnail: cdlThumbnail })
  }

  const open = Boolean(anchorEl)
  const id = open ? 'options-popover' : undefined

  return (
    <>
      <Stack spacing={2} direction="row">
        <Paper>
          <ToggleButtonGroup
            value={mapStyle}
            exclusive
            onChange={handleMapStyle}
            aria-label={t('mapDisplayOptions')}
            color="primary"
            size="small"
          >
            {layerOptions.map(({ value, label, thumbnail }) => (
              <StyledToggleButton
                key={value}
                value={value}
                aria-label={t(label)}
                data-testid={`${value}_select_button`}
              >
                <Tooltip placement="top" title={`${t(label)}`} arrow disableInteractive>
                  <img
                    src={thumbnail}
                    style={{
                      height: 36,
                      width: 36,
                      borderRadius: 3,
                      border: '2px white solid',
                    }}
                    alt=""
                  />
                </Tooltip>
              </StyledToggleButton>
            ))}

            {/* @TODO: fix the fullWidth prop warning in here */}
            <Tooltip placement="top" title={`${t('moreOptions')}`} arrow disableInteractive>
              <IconButton aria-describedby={id} onClick={handleClick} aria-label={t('moreOptions')}>
                <MoreVertical />
              </IconButton>
            </Tooltip>

            {toggler && toggler}
          </ToggleButtonGroup>
        </Paper>
      </Stack>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ userSelect: 'none' }} p={2}>
          <FormGroup>
            {parcels && (
              <FormControlLabel
                onChange={toggleParcels}
                checked={showParcels}
                control={<Switch size="small" />}
                label={`${t('Parcels')}`}
              />
            )}
            {sections && (
              <FormControlLabel
                onChange={toggleSections}
                checked={showSections}
                control={<Switch size="small" />}
                label={`${t('Sections')}`}
              />
            )}
            {townships && (
              <FormControlLabel
                onChange={toggleTownships}
                checked={showTownships}
                control={<Switch size="small" />}
                label={`${t('Townships')}`}
              />
            )}
            {counties && (
              <FormControlLabel
                onChange={toggleCounties}
                checked={showCounties}
                control={<Switch size="small" />}
                label={`${t('Counties')}`}
              />
            )}
            {terrain && (
              <FormControlLabel
                onChange={toggleTerrain}
                checked={showTerrain}
                control={<Switch size="small" />}
                label={`${t('Terrain')}`}
              />
            )}

            {terrain && (
              <Box sx={{ px: 2, py: 1 }}>
                <Typography id="terrain-exaggeration-slider" gutterBottom>
                  {t('Height Exaggeration')}
                </Typography>

                <Slider
                  aria-labelledby="terrain-exaggeration-slider"
                  disabled={!showTerrain}
                  value={terrainExaggeration}
                  onChange={(e, value) => setTerrainExaggeration && setTerrainExaggeration(+value)}
                  size="small"
                  min={1.0}
                  max={10.0}
                  step={0.1}
                  valueLabelDisplay="auto"
                  marks={[
                    { value: 0, label: '1x' },
                    { value: 5, label: '5x' },
                    { value: 10, label: '10x' },
                  ]}
                />
              </Box>
            )}

            {mapLayers.CDL && (
              <Box sx={{ px: 2, py: 1 }}>
                <Typography id="cdl-year-select-label" gutterBottom>
                  {t('cdlYear')}
                </Typography>

                <Slider
                  disabled={mapStyle !== MapStyles.CDL}
                  aria-labelledby="cdl-year-select-label"
                  value={!!cdlYear ? +cdlYear : +CDL_YEARS[CDL_YEARS.length - 1]}
                  onChange={(event, value) => {
                    setCdlYear && setCdlYear(`${value}`)
                  }}
                  size="small"
                  min={+CDL_YEARS[0]}
                  max={+CDL_YEARS[CDL_YEARS.length - 1]}
                  step={1}
                  valueLabelDisplay="auto"
                  marks={[
                    { value: +CDL_YEARS[0], label: CDL_YEARS[0] },
                    {
                      value: +CDL_YEARS[CDL_YEARS.length - 1],
                      label: CDL_YEARS[CDL_YEARS.length - 1],
                    },
                  ]}
                />
              </Box>
            )}

            <Can useAny={AuthUserPermission.DEBUG_USER}>
              {hucWatersheds && (
                <FormControlLabel
                  onChange={toggleWatersheds}
                  checked={showWatersheds}
                  control={<Switch size="small" />}
                  label={`${t('HUC Watersheds')}`}
                />
              )}
            </Can>
          </FormGroup>
        </Box>
      </Popover>
    </>
  )
}
