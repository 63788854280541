/* istanbul ignore file */
import * as contentful from 'contentful'

import { Config } from '../types/Config'

export const getContentfulClient = () =>
  contentful.createClient({
    space: Config.get('CONTENTFUL_SPACE_ID'),
    accessToken: Config.get('CONTENTFUL_DELIVERY_TOKEN'),
  })

export const getPreviewClient = () => {
  return contentful.createClient({
    space: Config.get('CONTENTFUL_SPACE_ID'),
    host: 'preview.contentful.com',
    accessToken: Config.get('CONTENTFUL_PREVIEW_TOKEN'),
  })
}
