import { SQUARE_METERS_PER_ACRE } from '@cibo/core'
import {
  ClickEvent,
  FeatureCollection,
  FeatureOf,
  GeoJsonEditMode,
  GuideFeatureCollection,
  ModeProps,
  MultiPolygon,
  PointerMoveEvent,
  Polygon,
} from '@nebula.gl/edit-modes'
import areaSqM from '@turf/area'
import bbox from '@turf/bbox'
import bearing from '@turf/bearing'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import destination from '@turf/destination'
import distance from '@turf/distance'
import { Feature, Position, feature, featureCollection, polygon } from '@turf/helpers'
import intersect from '@turf/intersect'
import { Point } from 'geojson'

const MAX_INSET_AREA_AC = 44
const MAX_INSET_AREA_SQ_M = MAX_INSET_AREA_AC * SQUARE_METERS_PER_ACRE

type PlotProperties = {
  clientAreaSqM: number // for backend consensus of area calculation
}

export const generateId = () => Math.round(Math.random() * Number.MAX_SAFE_INTEGER)

export class PizzaSliceMode extends GeoJsonEditMode {
  pivot: Feature<Point> | null = null
  plot: Feature<Polygon, PlotProperties> | null = null
  mousePosition: any

  handlePointerMove(event: PointerMoveEvent, props: ModeProps<FeatureCollection>): void {
    const cursor = this.getCustomCursor(event, props)
    props.onUpdateCursor(cursor)

    const { mapCoords: mousePosition } = event

    this.mousePosition = mousePosition

    const mouseoverFeature = props.modeConfig.fieldShapes?.features.find(
      (feature: Feature<Polygon | MultiPolygon>) =>
        booleanPointInPolygon(mousePosition, feature as Feature<Polygon>)
    ) as Feature<Polygon>

    if (!mouseoverFeature) {
      return
    }

    // @ts-ignore
    const groupAreaSqM = areaSqM(props.modeConfig.fieldShapes)
    const featureAreaSqM = areaSqM(mouseoverFeature)

    if (featureAreaSqM / groupAreaSqM < 0.1) {
      this.plot = feature(
        mouseoverFeature.geometry,
        { clientAreaSqM: featureAreaSqM, plotType: 'smallField' },
        { id: generateId() }
      )
    } else {
      if (!this.pivot) return

      const fourtyPercentAreaSqM = 0.4 * featureAreaSqM
      const targetAreaSqM = Math.min(MAX_INSET_AREA_SQ_M, fourtyPercentAreaSqM)

      const insetPlot = insetPlotForMousePosition(
        this.pivot,
        mouseoverFeature,
        mousePosition,
        targetAreaSqM
      )

      if (insetPlot) {
        // @ts-ignore having a hard time coercing an acceptable GuideFeature type
        this.plot = insetPlot
      }
    }
  }

  getCustomCursor(
    event: PointerMoveEvent,
    props: ModeProps<FeatureCollection>
  ): string | null | undefined {
    if (!this.pivot) {
      //Pointer
      return 'url("data:image/svg+xml,%3Csvg%20width%3D%2218%22%20height%3D%2218%22%20viewBox%3D%220%200%2018%2018%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20d%3D%22M10.247%2010.4017C10.2712%2010.3292%2010.3292%2010.2712%2010.4017%2010.247L18%207.71429L0%200L7.71429%2018L10.247%2010.4017Z%22%20fill%3D%22%23000000%22%2F%3E%3C%2Fsvg%3E") -16 -16, cell'
    }
    return 'grab'
  }

  getGuides(props: ModeProps<FeatureCollection>): GuideFeatureCollection {
    const guides = featureCollection([])

    if (this.plot) {
      guides.features.push(this.plot)
    }
    if (!this.pivot) {
      const mouseX = this.mousePosition[0]
      const mouseY = this.mousePosition[1]

      guides.features.push({
        id: 'ew',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [mouseX - 1, mouseY],
            [mouseX + 1, mouseY],
          ],
        },
        properties: {},
      })
      guides.features.push({
        id: 'ew',
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [
            [mouseX, mouseY - 1],
            [mouseX, mouseY + 1],
          ],
        },
        properties: {},
      })
    }
    // @ts-ignore
    return guides
  }

  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    if (!this.pivot) {
      const { mapCoords: mousePosition } = event
      this.pivot = {
        type: 'Feature',
        geometry: { type: 'Point', coordinates: mousePosition },
        properties: {},
      }
    } else if (this.plot) {
      props.onEdit(
        this.getAddFeatureAction(this.plot as FeatureOf<Polygon>, {
          type: 'FeatureCollection',
          features: [],
        })
      )
    }
  }

  handleKeyUp(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.pivot = null
      this.plot = null
    }
  }
}

const getSlicePolygon = (
  pivot: Feature<Point>,
  mouseBearing: number,
  diagonalLength: number,
  halfAngle: number
) => {
  const sliceStart = destination(pivot, diagonalLength, mouseBearing + halfAngle).geometry
    .coordinates
  const sliceMid = destination(pivot, diagonalLength, mouseBearing).geometry.coordinates
  const sliceEnd = destination(pivot, diagonalLength, mouseBearing - halfAngle).geometry.coordinates

  return polygon([
    [pivot.geometry.coordinates, sliceStart, sliceMid, sliceEnd, pivot.geometry.coordinates],
  ])
}

const insetPlotForMousePosition = (
  pivot: Feature<Point>,
  mouseoverFeature: Feature<Polygon | MultiPolygon>,
  mousePosition: Position,
  targetAreaSqM: number
) => {
  const [b1, b2, b3, b4] = bbox(mouseoverFeature)
  const diagonalLength = distance([b1, b2], [b3, b4])

  const distanceToCenter = distance(pivot, mousePosition)

  // if mouse near center, select the entire field
  if (distanceToCenter < 0.1 * diagonalLength) {
    const plotAreaSqM = areaSqM(mouseoverFeature)

    return feature(
      mouseoverFeature.geometry,
      {
        plotType: 'wholeField',
        clientAreaSqM: plotAreaSqM,
      },
      { id: generateId() }
    )
  }

  // direction toward mouse position from centroid
  const mouseBearing = bearing(pivot, mousePosition)

  let halfAngle = 10, // start ten degrees left and right of mouseBearing
    // If the step is too small, it might get laggy to find the right position.
    step = 5,
    insetPlot: Feature | null,
    lastError = 0

  while (true) {
    const slicePolygon = getSlicePolygon(pivot, mouseBearing, diagonalLength, halfAngle)

    const candidate = intersect(
      mouseoverFeature.properties?.parentField || (mouseoverFeature.geometry as Polygon),
      slicePolygon
    )

    if (!candidate) {
      console.log('no intersection with slicePolygon')
      return
    }

    const plotAreaSqM = areaSqM(candidate)
    insetPlot = feature(
      candidate.geometry,
      { plotType: 'inset', clientAreaSqM: plotAreaSqM },
      { id: generateId() }
    )

    const error = plotAreaSqM - targetAreaSqM

    // if not enough area in plot
    if (error < 0) {
      // slice more
      halfAngle = halfAngle + step
      if (lastError > 0) {
        step /= 2
      }
    }
    // if more than an acre over
    else if (error > SQUARE_METERS_PER_ACRE) {
      // slice less
      halfAngle = halfAngle - step
      if (lastError < 0) {
        step /= 2
      }
    } else {
      break
    }

    lastError = error
  }

  return insetPlot
}
