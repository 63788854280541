import { yupToFormErrors } from 'formik'
import { Schema } from 'yup'

export const validateFieldWithYup = (schema: Schema<any>) => (values: any) =>
  schema
    .validate(values, { abortEarly: false })
    .then((values: any) => null)
    .catch((error: any) => {
      return yupToFormErrors(error)
    })
