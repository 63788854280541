import { EQIP_PRACTICE_CHOICE_SOIL, RDEqipPracticeChoiceSoil } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { WorkflowDetailChoiceSelection } from '../../../../components/WorkflowDetailChoiceSelection'

export const RDEqipPracticeChoiceSoilResourceDetailFeature: ResourceDetailFeature<RDEqipPracticeChoiceSoil> =
  {
    traitId: 'eqipPracticeChoiceSoil',
    TaskEditor: props => (
      <WorkflowDetailChoiceSelection {...props} choices={EQIP_PRACTICE_CHOICE_SOIL} />
    ),
  }
