import { RDEqipEligibilityConservationCRP } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipEligibilityConservationCRPResourceDetailFeature: ResourceDetailFeature<RDEqipEligibilityConservationCRP> =
  {
    traitId: 'eqipEligibilityConservationCRP',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityConservationCRP>) => (
      <BooleanRollup<RDEqipEligibilityConservationCRP>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' } }}
        detailKey="crpEnrolled"
        ns="@cibo/landmanager/RDEqipEligibilityConservationCRPResourceDetailFeature"
        {...props}
      />
    ),
  }
