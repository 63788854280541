/* istanbul ignore file */
import { getMpAgent } from './utils'

const agent = getMpAgent()

type InsightsElevationResult = any

const PATHS = {
  FETCH_BY_RESOURCE_ID: '/insights/elevation',
}

export class ElevationApi {
  static fetchById(resourceId: string, options?: any): Promise<InsightsElevationResult> {
    return agent.get(`${PATHS.FETCH_BY_RESOURCE_ID}?resourceId=${resourceId}`, options)
  }
}
