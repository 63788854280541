import { ResourceDetailFeature } from '@cibo/ui'
import { CheckboxBooleanYearRollupEditor } from '../CheckboxBooleanYearRollupEditor'
import { RDOnFarmDairyFeed } from './types'

export const RDOnFarmDairyFeedFeature: ResourceDetailFeature<RDOnFarmDairyFeed> = {
  traitId: 'onFarmDairyFeed',
  TaskEditor: (props: any) => (
    <CheckboxBooleanYearRollupEditor
      traitId="onFarmDairyFeed"
      data-testid="onFarmDairyFeed"
      detailKey="result"
      ns="@cibo/landmanager/OnFarmDairyFeed"
      {...props}
    />
  ),
}
