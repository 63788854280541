import { Tabs as MUITabs, Palette, alpha, emphasize, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const v2UiTabOverrideColors = (palette: Palette) => ({
  selectedBackgroundColor: alpha(palette.secondary.main, 0.3),
  selectedTextColor: palette.text.primary,
  hoverBackgroundColor: alpha(palette.secondary.main, 0.08),
})

/**
 * A styled version of MUI Tabs that includes a custom indicator color and shadow
 */
export const Tabs = styled<OverridableComponent<any>>(MUITabs)<{
  indicatorColorOverrides?: {
    selectedBackgroundColor: string
    selectedTextColor: string
    hoverBackgroundColor: string
  }
  shadow?: boolean
  color?: 'primary' | 'secondary'
  size?: 'small' | 'medium'
}>(({ color, size, theme, indicatorColor = 'primary', indicatorColorOverrides, shadow = true }) => {
  return {
    '&.MuiTabs-root': {
      overflow: 'visible',
      minHeight: size === 'small' ? 34 : 36,
    },

    '&.MuiTabs-vertical': {
      '& .MuiTabs-indicator': {
        width: 'auto',
      },
      '& .MuiButtonBase-root': {
        marginBottom: 8,
      },
    },
    '& .MuiTabs-scroller': {
      //not ideal; this component shouldn't be used if the tabs will scroll :(
      overflow: 'visible !important',
    },
    '& .MuiTabs-flexContainer': { position: 'relative', zIndex: 1 },
    '& .MuiButtonBase-root': {
      paddingTop: 8,
      paddingBottom: 8,
      paddingRight: size === 'small' ? 14 : 16,
      paddingLeft: size === 'small' ? 14 : 16,
      minHeight: size === 'small' ? 34 : 36,
      marginRight: size === 'small' ? undefined : 8,
      borderRadius: theme.shape.borderRadius,
      overflow: 'visible',
      color: 'inherit',
      fontSize: size === 'small' ? 13 : undefined,

      '&:hover': {
        color: 'inherit',
        backgroundColor:
          indicatorColorOverrides?.hoverBackgroundColor ?? color === 'secondary'
            ? alpha(theme.palette.secondary.main, 0.08)
            : alpha(theme.palette[indicatorColor].light, 0.1),
      },

      '&:last-child': {
        marginRight: 0,
      },
      '&.Mui-selected': {
        color:
          indicatorColorOverrides?.selectedTextColor ?? color === 'secondary'
            ? theme.palette.text.primary
            : theme.palette[indicatorColor].contrastText,
      },
      transition: [
        theme.transitions.create('background-color'),
        theme.transitions.create('color', { duration: 300 }),
      ].join(),
    },
    '& .MuiTabs-indicator': {
      zIndex: 0,
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      height: 'auto',
      background:
        indicatorColorOverrides?.selectedBackgroundColor ?? color === 'secondary'
          ? alpha(theme.palette.secondary.main, 0.3)
          : theme.palette[indicatorColor].light,
      borderRadius: theme.shape.borderRadius,
      /**
       * it'd be super cool if there were a mui function for customizing colors on computed shadows.
       * this is just copy/paste from the output of `theme.shadows[4]` with the colors replaced using
       * our theme color
       * */
      boxShadow: shadow
        ? `0px 2px 4px -1px ${alpha(
            emphasize(theme.palette[indicatorColor].light),
            0.2
          )}, 0px 4px 5px 0px ${alpha(
            emphasize(theme.palette[indicatorColor].light),
            0.14
          )}, 0px 1px 10px 0px ${alpha(emphasize(theme.palette[indicatorColor].light), 0.12)}`
        : undefined,
    },
  }
})
