import { GrowerProgramsAPI, ProgramContentListItem } from '@cibo/core'
import { useAuth } from '@cibo/profile'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import { pathEq } from 'ramda'
import { useEffect } from 'react'

import { PROGRAMS_QUERY_KEY } from './queryKey'

export const useAllProgramContent = () => {
  const queryClient = useQueryClient()
  const { authResolved, isLoggedIn } = useAuth()
  useEffect(() => {
    if (!authResolved || !isLoggedIn) {
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.ALL_PROGRAM_CONTENT] })
    }
  }, [authResolved, isLoggedIn])

  return useQuery<ProgramContentListItem[], unknown, ProgramContentListItem[]>({
    queryKey: [PROGRAMS_QUERY_KEY.ALL_PROGRAM_CONTENT],
    queryFn: async () => {
      const programs = await GrowerProgramsAPI.allPrograms()
      return programs
    },

    enabled: authResolved && isLoggedIn,
    gcTime: Infinity,
    staleTime: Infinity,
  })
}

export const useProgramContent = (programId?: string) => {
  const { data, ...rest } = useAllProgramContent()

  return {
    ...rest,
    data: programId ? data?.find(pathEq(['programId'], programId)) : undefined,
  }
}
