import { RDEqipEligibilityWetlandCompliance } from '@cibo/core'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const WetlandComplianceCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDEqipEligibilityWetlandCompliance>) => {
  const { t } = useTranslation('@cibo/landmanager/WetlandCompliance')
  const detail = params.row.resolveStandingDetail<RDEqipEligibilityWetlandCompliance>(traitId, year)

  if (!detail) return <>--</>

  return <>{t(`wetlandCompliance_${detail.result?.wetlandCompliance}`)}</>
}
