import { FieldProgramProgress, ProgramConfig } from '@cibo/core'
import ClockIcon from '@mui/icons-material/AccessTimeOutlined'
import AddchartRounded from '@mui/icons-material/AddchartRounded'
import CheckRoundedIcon from '@mui/icons-material/CheckRounded'
import DoneIcon from '@mui/icons-material/Done'
import DoneAllIcon from '@mui/icons-material/DoneAll'
import CancelIcon from '@mui/icons-material/NotInterestedOutlined'
import RuleRounded from '@mui/icons-material/RuleRounded'
import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded'
import { Chip, ChipProps } from '@mui/material'
import { TFunction } from 'i18next'
import numeral from 'numeral'
import { FC } from 'react'
import { useTranslation } from 'react-i18next'

interface BaseProgressChipProps extends ChipProps {
  engageType?: ProgramConfig['engageType']
  unit?: 'dollars' | 'credits' | 'multi'
}

interface ProgressChipProps extends BaseProgressChipProps {
  amount?: string
  t: TFunction
}

const NeedsInfo = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip icon={<AddchartRounded />} label={<>{t('needsInfo')}</>} color="warning" {...rest} />
)
const Qualifying = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip
    icon={<ClockIcon />}
    label={<>{t('qualifying')}</>}
    variant="outlined"
    color="secondary"
    {...rest}
  />
)
const Enrolling = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip
    icon={<RuleRounded />}
    color="warning"
    label={<>{t('enrolling')}</>}
    variant="outlined"
    {...rest}
  />
)
const Engaged = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip
    icon={<RuleRounded />}
    color="warning"
    label={<>{t('isEngaged', { context: engageType })}</>}
    variant="outlined"
    {...rest}
  />
)
const Enrolled = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip icon={<CheckRoundedIcon />} color="success" label={<>{t('enrolled')}</>} {...rest} />
)
const UnderReview = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip icon={<ClockIcon />} color="info" label={<>{t('underReview')}</>} {...rest} />
)
const Disqualified = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip icon={<CancelIcon />} color="error" label={<>{t('disqualified')}</>} {...rest} />
)
const EnrollmentWindowClosed = ({ t, engageType, ...rest }: ProgressChipProps) => (
  <Chip
    icon={<WarningAmberRounded />}
    color="error"
    variant="outlined"
    label={<>{t('enrollmentWindowClosed')}</>}
    {...rest}
  />
)
const Qualified = ({ t, amount, engageType, unit, ...rest }: ProgressChipProps) => (
  <Chip
    icon={<DoneAllIcon />}
    label={<>{t('qualified', { amount, context: unit })}</>}
    color="secondary"
    {...rest}
  />
)
const PreQualified = ({ t, amount, engageType, unit, ...rest }: ProgressChipProps) => (
  <Chip
    icon={<DoneIcon />}
    label={<>{t('preQualified', { amount, context: unit })}</>}
    color="info"
    {...rest}
  />
)

// @ts-ignore "completed" is not in the backend yet
const progressToChip: Record<FieldProgramProgress, FC<ProgressChipProps>> = {
  needs_info: NeedsInfo,
  prequalified: PreQualified,
  qualifying: Qualifying,
  qualified: Qualified,
  engaged: Engaged,
  enrolling: Enrolling,
  enrolled: Enrolled,
  disqualified: Disqualified,
  under_review: UnderReview,
  window_closed: EnrollmentWindowClosed,
}

export const ProgramProgressChip = ({
  engageType,
  progress,
  amount,
  unit,
  ...rest
}: BaseProgressChipProps & {
  amount?: number
  progress: FieldProgramProgress
}) => {
  const { t } = useTranslation('@cibo/programs/ProgramProgressChip')
  const ChipToRender = progressToChip[progress] || NeedsInfo

  return (
    <ChipToRender
      t={t}
      amount={numeral(amount).format('0a')}
      engageType={engageType}
      unit={unit}
      {...rest}
      clickable={!!rest.onClick}
    />
  )
}
