import { AxiosError } from 'axios'

export enum RequestErrorStatus {
  UNAUTHORIZED = 401,
  NEED_SUBSCRIPTION = 403,
  OVER_QUOTA = 429,
}

export const REQUEST_ERROR_UNAUTHORIZED = 'REQUEST_ERROR_UNAUTHORIZED'
export const REQUEST_ERROR_NEED_SUBSCRIPTION = 'REQUEST_ERROR_NEED_SUBSCRIPTION'
export const REQUEST_ERROR_OVER_QUOTA = 'REQUEST_ERROR_OVER_QUOTA'

interface UnauthorizedRequestErrorAction {
  type: typeof REQUEST_ERROR_UNAUTHORIZED
  error: AxiosError
}

interface NeedSubscriptionErrorAction {
  type: typeof REQUEST_ERROR_NEED_SUBSCRIPTION
  error: AxiosError
}

interface OverQuotaErrorAction {
  type: typeof REQUEST_ERROR_OVER_QUOTA
  error: AxiosError
}

export type RequestErrorTypes =
  | UnauthorizedRequestErrorAction
  | NeedSubscriptionErrorAction
  | OverQuotaErrorAction
