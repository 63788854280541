import CONFIG from 'consts/envdex'
import { APP_CONFIG, Config } from 'types/Config'
import { homepage } from '../package.json'

export const init = () => {
  for (const key in CONFIG) {
    // @ts-ignore
    Config.set(key as APP_CONFIG, CONFIG[key])
  }

  Config.set('homepage', homepage)
}
