import {
  EQIP_PRACTICES_SOIL_COVER_OPTIONS,
  EqipPracticesSoilCoverOption,
  RDEqipPracticesSoilCover,
} from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../SelectRollupEditor'

export const RDEqipPracticesSoilCoverDetailFeature: ResourceDetailFeature<RDEqipPracticesSoilCover> =
  {
    traitId: 'eqipPracticesSoilCover',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSoilCover>) => (
      <SelectRollupEditor<RDEqipPracticesSoilCover, readonly EqipPracticesSoilCoverOption[]>
        options={EQIP_PRACTICES_SOIL_COVER_OPTIONS}
        detailKey="soilCondition"
        ns="@cibo/landmanager/RDEqipPracticesSoilCoverDetailFeature"
        {...props}
      />
    ),
  }
