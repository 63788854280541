import {
  LandManagementModalStateSingleField,
  useDetailsChanged,
  useFieldsChanged,
} from '@cibo/landmanager'
import { useAuth } from '@cibo/profile'
import { useQueryClient } from '@tanstack/react-query'
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { UnenrollFieldModal } from '../components/UnenrollFieldModal'
import { PROGRAMS_QUERY_KEY, useAllProgramContent } from '../queries'

export interface GrowerProgramsState {
  programsQuery: any
  addFields: boolean
  setAddFields: (status: boolean) => void
  onUnenroll(fieldId: string): void
}

const DEFAULT_VALUES: GrowerProgramsState = {
  programsQuery: undefined,
  addFields: false,
  setAddFields: () => null,
  onUnenroll: console.log,
}

export const GrowerProgramsContext = createContext<GrowerProgramsState>(DEFAULT_VALUES)
GrowerProgramsContext.displayName = 'GrowerProgramsContext'

export const GrowerProgramsProvider = ({ children }: PropsWithChildren<{}>) => {
  const programsQuery = useAllProgramContent()
  const { isLoggedIn } = useAuth()
  const queryClient = useQueryClient()
  const [addFields, setAddFields] = useState(false)
  const [unenrollModal, setUnenrollModal] = useState<LandManagementModalStateSingleField>({
    open: false,
  })

  useFieldsChanged(() => {
    queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
    queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_STATS] })
  })

  useDetailsChanged(() => {
    queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.WORKFLOW_FIELDS] })
    queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_STATS] })
  })

  useEffect(() => {
    queryClient.resetQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
    queryClient.resetQueries({ queryKey: [PROGRAMS_QUERY_KEY.ALL_PROGRAM_CONTENT] })
    queryClient.resetQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_CONTENT] })
    queryClient.resetQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_CONFIGS] })
  }, [isLoggedIn])

  return (
    <GrowerProgramsContext.Provider
      value={{
        programsQuery,
        addFields,
        setAddFields,
        onUnenroll: (fieldId: string) => setUnenrollModal({ open: true, fieldId }),
      }}
    >
      {children}

      <UnenrollFieldModal
        open={unenrollModal.open}
        onRequestClose={() => setUnenrollModal({ open: false })}
        fieldId={unenrollModal.fieldId}
      />
    </GrowerProgramsContext.Provider>
  )
}

export const useGrowerPrograms = () => useContext(GrowerProgramsContext)
