import { useEffect, useState } from 'react'

export function useLocalState<T>(defaultValue: T, key: string) {
  const [value, setValue] = useState<T>(() => {
    const persistedValueString = window.localStorage.getItem(key)

    if (persistedValueString !== null) {
      try {
        return JSON.parse(persistedValueString)
      } catch (e) {
        console.log(e)
      }
    }

    return defaultValue
  })

  useEffect(() => {
    window.localStorage.setItem(key, JSON.stringify(value))
  }, [key, value])

  return [value, setValue]
}
