import { ContentfulApi, DisplayableAsset } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { RequiredDocumentsAPI } from '../RequiredDocumentsAPI'

export const useRequiredDocuments = ({
  engagementId,
  taskId,
}: {
  engagementId?: string
  taskId?: string
}) => {
  return useQuery({
    queryKey: ['document-required', engagementId],
    queryFn: async () => {
      if (!engagementId || !taskId) return undefined

      const requirements = await RequiredDocumentsAPI.list({ engagementId, taskId }).then(
        ({ documents }) => documents.map(doc => doc.traitKey)
      )
      const displayableAssets = await ContentfulApi.findEntries<DisplayableAsset>({
        content_type: 'displayableAsset',
        'fields.traitKey[in]': requirements.join(','),
      })

      return displayableAssets.items
    },
    enabled: !!engagementId,
  })
}
