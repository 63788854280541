import { StaticMaps } from '@cibo/mapgl'
import { BASE_SATELLITE_TILE, BASE_SATELLITE_TILE_MAX_ZOOM } from '@cibo/ui'
import { useQuery } from '@tanstack/react-query'
import bbox from '@turf/bbox'
import { AxiosError } from 'axios'
import { Geometry } from 'geojson'
import { layersForProfileSkin } from './layersForProfileSkin'
import { LandThumbnailProps, LandThumbnailSkin } from './types'

export const getThumbnailData = async ({
  geometry,
  width,
  height,
  skin = LandThumbnailSkin.SELECTED,
  pixelRatio = 2,
}: LandThumbnailProps & { geometry: Geometry }) => {
  const layers = layersForProfileSkin(skin, pixelRatio)

  if (!geometry) return null

  const bounds = bbox(geometry)

  const rasterMap = new StaticMaps({
    zoomRange: {
      min: 6,
      max: BASE_SATELLITE_TILE_MAX_ZOOM,
    },
    tileLayers: [
      {
        tileUrl: BASE_SATELLITE_TILE,
        zoomRange: {
          min: 6,
          max: BASE_SATELLITE_TILE_MAX_ZOOM,
        },
      },
    ],
    width: width * pixelRatio,
    height: height * pixelRatio,
  })

  layers.forEach(layer => {
    if (!geometry) return

    switch (geometry.type) {
      case 'Polygon':
        rasterMap.addMultiPolygon({
          coords: geometry.coordinates,
          ...layer,
        })
        break

      case 'MultiPolygon':
        geometry.coordinates.forEach(coords => {
          rasterMap.addMultiPolygon({
            coords,
            ...layer,
          })
        })
        break
    }
  })

  const image = await rasterMap.render(bounds)

  const data = await new Promise((resolve, reject) =>
    image.getBase64('image/png', (err: Error, data: string) => (err ? reject(err) : resolve(data)))
  )

  return data
}

export const useThumbnailData = (imgProps: LandThumbnailProps & { geometry?: Geometry }) =>
  useQuery<string, AxiosError, string, any>({
    queryKey: ['land_thumbnail', imgProps],
    // @ts-ignore
    queryFn: async () => getThumbnailData(imgProps),
    enabled: !!imgProps.geometry,
    useErrorBoundary: false,
  })
