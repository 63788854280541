import { RDEqipEligibilityLandControl } from '@cibo/core'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const LandControlCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDEqipEligibilityLandControl>) => {
  const { t } = useTranslation('@cibo/landmanager/LandControl')
  const detail = params.row.resolveStandingDetail<RDEqipEligibilityLandControl>(traitId, year)

  if (!detail) return <>--</>

  return <>{t(`landControl_${detail.result?.landOwnershipEligible}`)}</>
}
