import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const TillageCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.source === 'cibo') {
    return (
      <Stack>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {t(detail.result)}
        </Typography>
      </Stack>
    )
  } else {
    return (
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {t(`tillage_${detail.result}`)}
      </Typography>
    )
  }
}
