export const US_STATES = [
  {
    fips: '01',
    label: 'Alabama',
    abbreviation: 'al',
  },
  {
    fips: '02',
    label: 'Alaska',
    abbreviation: 'ak',
  },
  {
    fips: '04',
    label: 'Arizona',
    abbreviation: 'az',
  },
  {
    fips: '05',
    label: 'Arkansas',
    abbreviation: 'ar',
  },
  {
    fips: '06',
    label: 'California',
    abbreviation: 'ca',
  },
  {
    fips: '08',
    label: 'Colorado',
    abbreviation: 'co',
  },
  {
    fips: '09',
    label: 'Connecticut',
    abbreviation: 'ct',
  },
  {
    fips: '10',
    label: 'Delaware',
    abbreviation: 'de',
  },
  {
    fips: '11',
    label: 'District of Columbia',
    abbreviation: 'dc',
  },
  {
    fips: '12',
    label: 'Florida',
    abbreviation: 'fl',
  },
  {
    fips: '13',
    label: 'Geogia',
    abbreviation: 'ga',
  },
  {
    fips: '15',
    label: 'Hawaii',
    abbreviation: 'hi',
  },
  {
    fips: '16',
    label: 'Idaho',
    abbreviation: 'id',
  },
  {
    fips: '17',
    label: 'Illinois',
    abbreviation: 'il',
  },
  {
    fips: '18',
    label: 'Indiana',
    abbreviation: 'in',
  },
  {
    fips: '19',
    label: 'Iowa',
    abbreviation: 'ia',
  },
  {
    fips: '20',
    label: 'Kansas',
    abbreviation: 'ks',
  },
  {
    fips: '21',
    label: 'Kentucky',
    abbreviation: 'ky',
  },
  {
    fips: '22',
    label: 'Louisiana',
    abbreviation: 'la',
  },
  {
    fips: '23',
    label: 'Maine',
    abbreviation: 'me',
  },
  {
    fips: '24',
    label: 'Maryland',
    abbreviation: 'md',
  },
  {
    fips: '25',
    label: 'Massachusetts',
    abbreviation: 'ma',
  },
  {
    fips: '26',
    label: 'Michigan',
    abbreviation: 'mi',
  },
  {
    fips: '27',
    label: 'Minnesota',
    abbreviation: 'mn',
  },
  {
    fips: '28',
    label: 'Mississippi',
    abbreviation: 'ms',
  },
  {
    fips: '29',
    label: 'Missouri',
    abbreviation: 'mo',
  },
  {
    fips: '30',
    label: 'Montana',
    abbreviation: 'mt',
  },
  {
    fips: '31',
    label: 'Nebraska',
    abbreviation: 'ne',
  },
  {
    fips: '32',
    label: 'Nevada',
    abbreviation: 'nv',
  },
  {
    fips: '33',
    label: 'New Hampshire',
    abbreviation: 'nh',
  },
  {
    fips: '34',
    label: 'New Jersey',
    abbreviation: 'nj',
  },
  {
    fips: '35',
    label: 'New Mexico',
    abbreviation: 'nm',
  },
  {
    fips: '36',
    label: 'New York',
    abbreviation: 'ny',
  },
  {
    fips: '37',
    label: 'North Carolina',
    abbreviation: 'nc',
  },
  {
    fips: '38',
    label: 'North Dakota',
    abbreviation: 'nd',
  },
  {
    fips: '39',
    label: 'Ohio',
    abbreviation: 'oh',
  },
  {
    fips: '40',
    label: 'Oklahoma',
    abbreviation: 'ok',
  },
  {
    fips: '41',
    label: 'Oregon',
    abbreviation: 'or',
  },
  {
    fips: '42',
    label: 'Pennsylvania',
    abbreviation: 'pa',
  },
  {
    fips: '44',
    label: 'Rhode Island',
    abbreviation: 'ri',
  },
  {
    fips: '45',
    label: 'South Carolina',
    abbreviation: 'sc',
  },
  {
    fips: '46',
    label: 'South Dakota',
    abbreviation: 'sd',
  },
  {
    fips: '47',
    label: 'Tennessee',
    abbreviation: 'tn',
  },
  {
    fips: '48',
    label: 'Texas',
    abbreviation: 'tx',
  },
  {
    fips: '49',
    label: 'Utah',
    abbreviation: 'ut',
  },
  {
    fips: '50',
    label: 'Vermont',
    abbreviation: 'vt',
  },
  {
    fips: '51',
    label: 'Virginia',
    abbreviation: 'va',
  },
  {
    fips: '53',
    label: 'Washington',
    abbreviation: 'wa',
  },
  {
    fips: '54',
    label: 'West Virginia',
    abbreviation: 'wv',
  },
  {
    fips: '55',
    label: 'Wisconsin',
    abbreviation: 'wi',
  },
  {
    fips: '56',
    label: 'Wyoming',
    abbreviation: 'wy',
  },
]
