import { Chip, styled } from '@mui/material'
import NavigationIcon from '@mui/icons-material/Navigation'

import { PILL_TYPE } from '@cibo/core'

const OpaqueChip = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  '&.MuiChip-clickable:hover': {
    backgroundColor: theme.palette.background.default,
  },
  '&:focus': {
    backgroundColor: theme.palette.background.default,
    outline: `2px solid ${theme.palette.info.light}`,
    outlineOffset: '2px',
  },
  '.navigatePillIcon': {
    fill: theme.palette.primary.main,
    transform: 'translate(2px, -1px) rotate(45deg)',
    padding: '0.125em',
  },
}))

interface SearchPillProps {
  label: string
  onRemove: () => void
  onPress?: () => void
  pillType: PILL_TYPE
}

const SearchPill = ({ label, onPress, onRemove, pillType }: SearchPillProps) => {
  const navigatable = [PILL_TYPE.COUNTY, PILL_TYPE.TOWNSHIP, PILL_TYPE.STATE].includes(pillType)
  return (
    <OpaqueChip
      // @ts-ignore the styled component doesn't want to accept this prop that it can accept and use
      component="li"
      variant="outlined"
      onClick={navigatable ? onPress : undefined}
      onDelete={onRemove}
      icon={navigatable ? <NavigationIcon className="navigatePillIcon" /> : undefined}
      label={label}
    />
  )
}

export default SearchPill
