import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const OwnershipCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/OwnershipEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  const { yearsInOperation, operatorIsOwner } = detail.input || {}

  return (
    <Stack>
      {!!detail.input?.hasOwnProperty('operatorIsOwner') && (
        <Typography>{t(operatorIsOwner ? 'owned' : 'rented')}</Typography>
      )}

      {!!detail.input?.hasOwnProperty('yearsInOperation') && (
        <Typography variant="caption">
          {t('yearsInOperation', { count: yearsInOperation })}
        </Typography>
      )}
    </Stack>
  )
}
