import { DatePickerField } from '@cibo/ui'
import { Collapse, FormControl, FormControlLabel, FormLabel, Radio, Stack } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { Field, FieldProps } from 'formik'
import { RadioGroup } from 'formik-mui'
import { useTranslation } from 'react-i18next'

import { ConversionDateResult } from '@cibo/core'
import { DetailEditorProps } from '../types'

const NOW = new Date()
const TEN_YEARS_AGO = new Date(NOW.getFullYear() - 10, NOW.getMonth(), NOW.getDate() + 1)

export const NativeConversionDateEditor = ({
  name = 'nativeConversionDateEditor',
}: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/NativeConversionDateEditor')

  return (
    <Field name={name} id={name}>
      {({ field: { value } }: FieldProps<ConversionDateResult>) => {
        return (
          <Stack spacing={2}>
            <Stack>
              <InputLabel htmlFor={`${name}.moreThanTenYearsAgo`}>{t('whenConverted')}</InputLabel>

              <Field component={RadioGroup} name={`${name}.moreThanTenYearsAgo`}>
                <FormControlLabel
                  value={true}
                  control={<Radio />}
                  label={`${t('moreThanTenYearsAgo')}`}
                  data-testid="input.moreThanTenYearsAgo"
                />
                <FormControlLabel
                  value={false}
                  control={<Radio />}
                  label={`${t('withinLastTenYears')}`}
                  data-testid="input.withinLastTenYears"
                />
              </Field>
            </Stack>
            <Collapse
              in={
                value?.moreThanTenYearsAgo === false ||
                // @ts-ignore - MUI casts booleans to string onChange
                value?.moreThanTenYearsAgo === 'false'
              }
            >
              <FormControl>
                <FormLabel>{t('whatDateConverted')}</FormLabel>
                <DatePickerField
                  name={`${name}.conversionDate`}
                  minDate={TEN_YEARS_AGO}
                  maxDate={NOW}
                  data-testid={`${name}.conversionDate`}
                  inlineClearButton
                />
              </FormControl>
            </Collapse>
          </Stack>
        )
      }}
    </Field>
  )
}
