import { ResourceDetailYearly } from '../ResourceDetail'

export const EQIP_PRACTICES_WATER_TABLE_OPTIONS = [
  'occurs_no_effect',
  'occurs_negative_effect',
  'no_occurrence',
  'unknown',
] as const

export type EqipPracticesWaterTableOption = typeof EQIP_PRACTICES_WATER_TABLE_OPTIONS[number]

export type RDEqipPracticesWaterTableInput = {
  highWaterTable: EqipPracticesWaterTableOption
}

export interface RDEqipPracticesWaterTable extends ResourceDetailYearly {
  traitId: 'eqipPracticesWaterTable'
  input: RDEqipPracticesWaterTableInput
  result: RDEqipPracticesWaterTableInput
}
