import { GridCallbackDetails, GridRowId, GridRowSelectionModel } from '@mui/x-data-grid-pro'
import { useContext, useRef } from 'react'

import { PaginatedFieldsContext } from './PaginatedFieldsContext'
import { PaginatedFieldsTable } from './PaginatedFieldsTable'
import { PaginatedFieldsTableProps } from './PrivatePaginatedFieldsTableTypes'

export type SelectableFieldsTableProps = Omit<
  PaginatedFieldsTableProps,
  'handlePageChange' | 'rowSelectionModel' | 'onNumAvailableChange'
>

export const SelectablePaginatedFieldsTable = ({
  onRowSelectionModelChange,
  ...dataGridProProps
}: SelectableFieldsTableProps) => {
  const { setPage, selectedRowIds, setSelectedRowIds } = useContext(PaginatedFieldsContext)
  const prevSelectionModel = useRef<GridRowId[] | undefined>(selectedRowIds)

  const handlePageChange = (newPage: number) => {
    prevSelectionModel.current = selectedRowIds
    setPage(newPage)
  }

  const onNumAvailableChange = () => {
    prevSelectionModel.current = []
    setSelectedRowIds && setSelectedRowIds([])
  }

  const handleSelectionModelChange = (
    rowSelectionModel: GridRowSelectionModel,
    details: GridCallbackDetails
  ) => {
    !!onRowSelectionModelChange && onRowSelectionModelChange(rowSelectionModel, details)
    setSelectedRowIds(rowSelectionModel)
  }

  return (
    <PaginatedFieldsTable
      sx={{ borderStyle: 'none' }}
      rowSelectionModel={selectedRowIds}
      onRowSelectionModelChange={handleSelectionModelChange}
      onNumAvailableChange={onNumAvailableChange}
      checkboxSelection
      handlePageChange={handlePageChange}
      {...dataGridProProps}
    />
  )
}
