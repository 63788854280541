/* istanbul ignore file */
import { PlatformUser } from '../types'
import {
  CreateTermsetRequest,
  Termset,
  TermsetSearchQuery,
  UpdateTermsetRequest,
} from '../types/Termset'
import { TermsetAPI } from './TermsetAPI'

export interface FSAFarm extends Termset<PlatformUser> {
  farmSerialNumber: string
}

const PATHS = {
  LIST: '/termset/tsFsaNumber',
  ENTITY: '/termset/tsFsaNumber/:id',
  FIELDS: '/termset/tsFsaNumber/linkWithResources',
  QUERY: '/termset/tsFsaNumber/query',
}

export const FSAFarmsAPI = new TermsetAPI<FSAFarm>(PATHS)

export type CreateFSAFarmRequest = CreateTermsetRequest<FSAFarm>
export type UpdateFSAFarmRequest = UpdateTermsetRequest<FSAFarm>
export type FSAFarmSearchQuery = TermsetSearchQuery
