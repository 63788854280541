import { RDAmendmentSummary } from '@cibo/core'
import { LineLimitTypography } from '@cibo/ui'
import { Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const AmendmentSummaryCellDisplay = ({
  params: { row },
  year,
  traitId,
}: TraitCellDisplayProps<RDAmendmentSummary>) => {
  const { t } = useTranslation('@cibo/landmanager/AmendmentSummary')
  const amendmentSummary = row.resolveStandingDetail<RDAmendmentSummary>(traitId, year)
  const { input } = amendmentSummary
  return !!amendmentSummary ? (
    !!amendmentSummary.input ? (
      <Stack>
        <LineLimitTypography variant="body2">
          {input?.applicationType === 'not_available'
            ? t('n/a')
            : t('cellDisplayRate', {
                rate: input?.rate,
                applicationType: t(`applicationType_${input?.applicationType}`),
                units: t(`units_${input?.units}`),
              })}
        </LineLimitTypography>
        <LineLimitTypography variant="caption">
          {[
            !!input?.compost && t('compost'),
            !!input?.manure && t('manure'),
            !!input?.biochar && t('biochar'),
          ]
            .filter(Boolean)
            .join(', ')}
        </LineLimitTypography>
      </Stack>
    ) : (
      <>{t('none')}</>
    )
  ) : (
    <></>
  )
}
