import {
  OrganizationContentModel,
  getContentfulClient,
  getLocale,
  getPreviewClient,
} from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { Entry } from 'contentful'
import { useSelector } from 'react-redux'
import { selectOrg } from '../store'
import { QUERY_KEY } from './queryKey'

const isIntegrationTest = window.localStorage.driver === 'playwright'

type ContentOptions = { contentfulOrgId?: string }

export const useOrganizationContent = (args?: ContentOptions) => {
  const myOrganization = useSelector(selectOrg)

  const locale = getLocale()

  /**
   * See: https://cibotech.atlassian.net/browse/CPD-3215
   *
   * There are no guarantees that the organization will actually resolve a contentful id.
   *
   */
  const contentId =
    args?.contentfulOrgId || (myOrganization?.contentfulOrgId ?? '53swPiZrsyjM758PXQ4RfB')

  return useQuery<Entry<OrganizationContentModel>, unknown, Entry<OrganizationContentModel>>({
    queryKey: [QUERY_KEY.ORGANIZATION_CONTENT, contentId],
    queryFn: () => {
      if (!contentId) throw new Error('can not fetch org content without id')

      // @ts-ignore meta has env
      const client =
        import.meta.env.PROD || isIntegrationTest ? getContentfulClient() : getPreviewClient()

      return client.getEntry(contentId, { locale })
    },

    enabled: !!myOrganization,
    staleTime: Infinity,
    retry: 3,
  })
}
