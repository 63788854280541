import { RDFutureCoverCrop, RDFutureCoverCropSpecies } from '@cibo/core'
import { object, string } from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FutureCoverCropCell } from './FutureCoverCropCell'
import { FutureCoverCropCellEditor } from './FutureCoverCropCellEditor'
import { FutureCoverCropEditor } from './FutureCoverCropEditor'

const TRAIT_ID = 'futureCoverCrop'

const FutureCoverCropBase: ITraitFeatureByIdYear<RDFutureCoverCrop> = {
  traitId: TRAIT_ID,
  editor: FutureCoverCropEditor,
  cellDisplay: FutureCoverCropCell,
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail ? detail.input ?? { crop: detail.result === true ? 'other' : 'none' } : {}
  },
  ns: '@cibo/landmanager/FutureCoverCropEditor',
  validationSchema: ({ t }) =>
    object()
      .shape({
        crop: string<RDFutureCoverCropSpecies>()
          .required(t('required', { context: 'crop' }))
          .typeError(t('required', { context: 'crop' })),
      })
      .required(t('required')),
}

export const FutureCoverCropInput = {
  ...FutureCoverCropBase,
}

export const FutureCoverCropResult = {
  ...FutureCoverCropBase,
  cellEditor: FutureCoverCropCellEditor,
}
