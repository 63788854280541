import {
  AuthAPI,
  AuthDirectLoginActionTypes,
  AuthDirectLoginError,
  AuthDirectLoginResult,
  AuthDirectLoginWithPasswordQuery,
  AuthDirectLoginWithVerificationCodeQuery,
  AuthDirectLogoutActionTypes,
  AuthDirectLogoutError,
  AuthDirectRequestVerificationCodeActionTypes,
  AuthDirectRequestVerificationCodeError,
  AuthDirectRequestVerificationCodeQuery,
  AuthDirectRequestVerificationCodeResult,
  GlobalMpAuth,
  LOGIN_DENIED_REASON,
  MP_AUTH_DIRECT_LOGIN_ERROR,
  MP_AUTH_DIRECT_LOGIN_PENDING,
  MP_AUTH_DIRECT_LOGIN_SUCCESS,
  MP_AUTH_DIRECT_LOGOUT_ERROR,
  MP_AUTH_DIRECT_LOGOUT_PENDING,
  MP_AUTH_DIRECT_LOGOUT_SUCCESS,
  MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR,
  MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING,
  MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS,
  MpxResponseError,
} from '@cibo/core'
import { AxiosError, AxiosResponse } from 'axios'
import { omit, path } from 'ramda'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { authSessionInvalid, authSessionValid } from './Session'
import { refreshUserInfo } from './UserInfo'

const authDirectLoginPending = (): AuthDirectLoginActionTypes => ({
  type: MP_AUTH_DIRECT_LOGIN_PENDING,
})
export const authDirectLoginSuccess = (
  results: AxiosResponse<AuthDirectLoginResult>,
  message?: string
): AuthDirectLoginActionTypes => ({
  type: MP_AUTH_DIRECT_LOGIN_SUCCESS,
  results,
  message,
})
export const authDirectLoginError = (error: AuthDirectLoginError): AuthDirectLoginActionTypes => ({
  type: MP_AUTH_DIRECT_LOGIN_ERROR,
  error,
})

export const authDirectLoginWithPassword =
  (
    query: AuthDirectLoginWithPasswordQuery,
    message?: string
  ): ThunkAction<void, any, null, Action<string>> =>
  async dispatch => {
    dispatch(authDirectLoginPending())
    try {
      const results = await AuthAPI.directLoginWithPassword(query)

      GlobalMpAuth.setAuthToken(results.data.userJwt)
      const fullUser = await AuthAPI.userInfo()

      dispatch(authDirectLoginSuccess({ ...fullUser, ...results }, message))
      dispatch(authSessionValid())
      dispatch(refreshUserInfo())
    } catch (error: any) {
      const data = path(['response', 'data'])(error)

      if (!data) {
        return
      }
      dispatch(authDirectLoginError(data as AuthDirectLoginError))

      if ((data as AuthDirectLoginError).deniedReason === LOGIN_DENIED_REASON.NEED_CODE) {
        const requestParams = omit(['password'])(query)

        dispatch(
          authDirectRequestVerificationCode(requestParams as AuthDirectRequestVerificationCodeQuery)
        )
      }
    }
  }

export const authDirectLoginWithVerificationCode =
  (
    query: AuthDirectLoginWithVerificationCodeQuery,
    message?: string
  ): ThunkAction<void, any, null, Action<string>> =>
  async dispatch => {
    dispatch(authDirectLoginPending())
    try {
      const results = await AuthAPI.directLoginWithVerificationCode(query)

      GlobalMpAuth.setAuthToken(results.data.userJwt)
      const fullUser = await AuthAPI.userInfo()

      dispatch(authDirectLoginSuccess({ ...fullUser, ...results }, message))
      dispatch(authSessionValid())
      dispatch(refreshUserInfo())
    } catch (error: AxiosError | unknown) {
      if ((error as AxiosError).isAxiosError) {
        dispatch(authDirectLoginError((error as AxiosResponse<MpxResponseError>).data))
      }
    }
  }

const authDirectLogoutPending = (): AuthDirectLogoutActionTypes => ({
  type: MP_AUTH_DIRECT_LOGOUT_PENDING,
})
const authDirectLogoutSuccess = (message?: string): AuthDirectLogoutActionTypes => ({
  type: MP_AUTH_DIRECT_LOGOUT_SUCCESS,
  message,
})
const authDirectLogoutError = (error: AuthDirectLogoutError): AuthDirectLogoutActionTypes => ({
  type: MP_AUTH_DIRECT_LOGOUT_ERROR,
  error,
})

export const authDirectLogout =
  ({ message }: { message?: string } = {}): ThunkAction<void, any, null, Action<string>> =>
  async dispatch => {
    dispatch(authDirectLogoutPending)
    try {
      await AuthAPI.directLogout()
    } catch (err) {
      console.log(err)
    }
    try {
      GlobalMpAuth.clearAuthToken()
      dispatch(authDirectLogoutSuccess(message))
      dispatch(authSessionInvalid())
      dispatch(refreshUserInfo())
    } catch (error: AxiosError | unknown) {
      if ((error as AxiosError).isAxiosError) {
        dispatch(authDirectLogoutError((error as AxiosError).response?.data))
      }
    }
  }

const authDirectRequestVerificationCodePending =
  (): AuthDirectRequestVerificationCodeActionTypes => ({
    type: MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_PENDING,
  })

const authDirectRequestVerificationCodeError = (
  error: AuthDirectRequestVerificationCodeError
): AuthDirectRequestVerificationCodeActionTypes => ({
  type: MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_ERROR,
  error,
})

const authDirectRequestVerificationCodeSuccess = (
  results: AxiosResponse<AuthDirectRequestVerificationCodeResult>
): AuthDirectRequestVerificationCodeActionTypes => ({
  type: MP_AUTH_DIRECT_REQUEST_VERIFICATION_CODE_SUCCESS,
  results,
})

export const authDirectRequestVerificationCode =
  (query: AuthDirectRequestVerificationCodeQuery): ThunkAction<void, any, null, Action<string>> =>
  async dispatch => {
    dispatch(authDirectRequestVerificationCodePending())
    try {
      const results = await AuthAPI.directLoginRequestVerificationCode({ ...query, forLogin: true })

      dispatch(authDirectRequestVerificationCodeSuccess(results))
    } catch (error: AxiosError | unknown) {
      if ((error as AxiosError).isAxiosError) {
        dispatch(authDirectRequestVerificationCodeError((error as AxiosError).response?.data))
      }
    }
  }
