import { RDEqipApplicationResults } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { RDEqipApplicationResultsTaskEditor } from './RDEqipApplicationResultsTaskEditor'
export * from './evaluateStatus'

export const RDEqipApplicationResultsResourceDetailFeature: ResourceDetailFeature<RDEqipApplicationResults> =
  {
    traitId: 'eqipApplicationResults',
    TaskEditor: RDEqipApplicationResultsTaskEditor,
    selfIsland: true,
  }
