import { Box, Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { Markdown } from '../Markdown'
import imgUrl from './no-results.png'

export const EmptyState = ({
  headerMarkdown,
  helperText,
}: {
  children?: ReactNode
  headerMarkdown: string
  helperText: string
}) => (
  <Stack spacing={3} alignItems="center" py={3} data-testid="no-results">
    <Box
      sx={{
        backgroundImage: `url(${imgUrl})`,
        backgroundPositionX: 'center',
        height: 218,
        width: 218,
        borderRadius: '50%',
      }}
    />
    <Stack spacing={1} alignItems="center">
      <Markdown typographyProps={{ variant: 'h6' }}>{headerMarkdown}</Markdown>
      <Typography variant="body1" color="text.disabled">
        {helperText}
      </Typography>
    </Stack>
  </Stack>
)
