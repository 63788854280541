import { validateFieldWithYup } from '@cibo/ui/src/components/Form/Formik/validateFieldWithYup'
import { FormLabel, Grid2 as Grid, Stack } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

export const UsdaMailingAddressField = ({
  name,
  disabled,
}: {
  name: string
  disabled?: boolean
}) => {
  const { t } = useTranslation('@cibo/profile/UsdaMailingAddressField')

  const schema = useMemo(
    () =>
      yup
        .object()
        .shape({
          street1: yup
            .string()
            .required(
              t('addressRequired', { fieldName: t('street1', { ns: '@cibo/ui/AddressField' }) })
            ),
          street2: yup.string().nullable(),
          city: yup
            .string()
            .required(
              t('addressRequired', { fieldName: t('city', { ns: '@cibo/ui/AddressField' }) })
            ),
          county: yup
            .string()
            .required(
              t('addressRequired', { fieldName: t('county', { ns: '@cibo/ui/AddressField' }) })
            ),
          state: yup
            .string()
            .required(
              t('addressRequired', { fieldName: t('state', { ns: '@cibo/ui/AddressField' }) })
            ),
          zipCode: yup.string().required(
            t('addressRequired', {
              fieldName: t('postalCode', { ns: '@cibo/ui/AddressField' }),
            })
          ),
        })
        .nullable()
        .default(null),
    []
  )

  // @todo read from requirement
  const required = false

  return (
    <Field name={name} validate={validateFieldWithYup(schema)}>
      {() => (
        <Grid container columns={2} rowSpacing={2}>
          <Grid size={{ xs: 1 }}>
            <Stack spacing={1} mr={2}>
              <FormLabel required={required}>{t('street')}</FormLabel>
              <Field
                id="street1"
                name={`${name}.street1`}
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'street1',
                    autoComplete: 'section-address address-line1',
                  },
                }}
                maxLength={255}
                placeholder={t('enterStreet')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Grid>

          <Grid size={{ xs: 1 }}>
            <Stack spacing={1} mr={2}>
              <FormLabel>{t('street2')}</FormLabel>
              <Field
                id="street2"
                name={`${name}.street2`}
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'street2',
                    autoComplete: 'section-address address-line2',
                  },
                }}
                maxLength={255}
                placeholder={t('enterStreet')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Grid>

          <Grid size={{ xs: 1 }}>
            <Stack spacing={1} mr={2}>
              <FormLabel required={required}>{t('city')}</FormLabel>
              <Field
                id="city"
                name={`${name}.city`}
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'city',
                    autoComplete: 'section-address address-level2',
                  },
                }}
                maxLength={255}
                placeholder={t('enterCity')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Grid>

          <Grid size={{ xs: 1 }}>
            <Stack spacing={1} mr={2}>
              <FormLabel required={required}>{t('county')}</FormLabel>
              <Field
                id="county"
                name={`${name}.county`}
                component={TextField}
                InputProps={{
                  inputProps: { 'data-testid': 'county', autoComplete: 'section-address' },
                }}
                maxLength={255}
                placeholder={t('enterCounty')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Grid>

          <Grid size={{ xs: 1 }}>
            <Stack spacing={1} mr={2}>
              <FormLabel required={required}>{t('state')}</FormLabel>
              <Field
                id="state"
                name={`${name}.state`}
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'state',
                    autoComplete: 'section-address address-level1',
                  },
                }}
                maxLength={255}
                placeholder={t('enterState')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Grid>

          <Grid size={{ xs: 1 }}>
            <Stack spacing={1} mr={2}>
              <FormLabel required={required}>{t('zipCode')}</FormLabel>
              <Field
                id="zipCode"
                name={`${name}.zipCode`}
                component={TextField}
                InputProps={{
                  inputProps: {
                    'data-testid': 'zipCode',
                    autoComplete: 'section-address postal-code',
                  },
                }}
                maxLength={255}
                placeholder={t('enterZipcode')}
                disabled={disabled}
                fullWidth={true}
              />
            </Stack>
          </Grid>
        </Grid>
      )}
    </Field>
  )
}
