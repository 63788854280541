import {
  MpxSearchQuery,
  MpxServerQuery,
  MpxSearchTagTypes,
  MpxGeoPoliticalAggregationTypes,
  MpxFieldHistoryTypes,
  MpxFieldUsageTypes,
  MpxAggMetricFieldType,
  MpxGeoPoliticalAggregationType,
} from '../types'
import { LatLong, Bounds } from '../utils/geometry'
import { queryForLatLong, latLongForQuery } from '../utils/queryLatLong'

export class MpxSearchQueryModel {
  acreageGe?: number
  acreageLt?: number
  aggMetricField?: MpxAggMetricFieldType
  allowedAutoAggregations?: MpxGeoPoliticalAggregationType
  comparisonFields?: string
  countOnly?: boolean
  county?: string
  fldHistory?: MpxFieldHistoryTypes[]
  fldUsage?: MpxFieldUsageTypes[]
  fullTextSearch?: string
  geoPoliticalAggType?: MpxGeoPoliticalAggregationTypes
  lowerLeft?: LatLong
  nccpiGe?: number
  nccpiLt?: number
  operatorRentGe?: number
  operatorRentLt?: number
  owner?: string
  parcelId?: string
  parcelLimit?: number
  parcelPkId?: string
  productivityGe?: number
  productivityLt?: number
  regenPotentialPerAcreGe?: number
  regenPotentialPerAcreLt?: number
  stabilityGe?: number
  stabilityLt?: number
  state?: string
  statePiGe?: number
  statePiLt?: number
  sustainabilityGe?: number
  sustainabilityLt?: number
  tags?: MpxSearchTagTypes[]
  tillablePercentGe?: number
  tillablePercentLt?: number
  upperRight?: LatLong
  valuePerAcreGe?: number
  valuePerAcreLt?: number
  zoom?: number

  constructor(query: MpxSearchQuery = {}) {
    this.acreageGe = query.acreageGe
    this.acreageLt = query.acreageLt
    this.aggMetricField = query.aggMetricField
    this.allowedAutoAggregations = query.allowedAutoAggregations
    this.comparisonFields = query.comparisonFields
    this.countOnly = query.countOnly
    this.county = query.county
    this.fldHistory = query.fldHistory
    this.fldUsage = query.fldUsage
    this.fullTextSearch = query.fullTextSearch
    this.geoPoliticalAggType = query.geoPoliticalAggType
    this.lowerLeft = query.lowerLeft
    this.nccpiGe = query.nccpiGe
    this.nccpiLt = query.nccpiLt
    this.operatorRentGe = query.operatorRentGe
    this.operatorRentLt = query.operatorRentLt
    this.owner = query.owner
    this.parcelId = query.parcelId
    this.parcelLimit = query.parcelLimit
    this.parcelPkId = query.parcelPkId
    this.productivityGe = query.productivityGe
    this.productivityLt = query.productivityLt
    this.regenPotentialPerAcreGe = query.regenPotentialPerAcreGe
    this.regenPotentialPerAcreLt = query.regenPotentialPerAcreLt
    this.stabilityGe = query.stabilityGe
    this.stabilityLt = query.stabilityLt
    this.state = query.state
    this.statePiGe = query.statePiGe
    this.statePiLt = query.statePiLt
    this.sustainabilityGe = query.sustainabilityGe
    this.sustainabilityLt = query.sustainabilityLt
    this.tags = query.tags
    this.tillablePercentGe = query.tillablePercentGe
    this.tillablePercentLt = query.tillablePercentLt
    this.upperRight = query.upperRight
    this.valuePerAcreGe = query.valuePerAcreGe
    this.valuePerAcreLt = query.valuePerAcreLt
  }

  static fromServerQuery(serverQuery: MpxServerQuery): MpxSearchQuery {
    const serverTags = serverQuery.tags
    const serverFldHistory = serverQuery.fldHistory

    return new MpxSearchQueryModel({
      ...serverQuery,
      lowerLeft: latLongForQuery(serverQuery.lowerLeft),
      upperRight: latLongForQuery(serverQuery.upperRight),
      fldHistory: serverFldHistory
        ? serverFldHistory.split('|').map((history: string) => history as MpxFieldHistoryTypes)
        : [],
      tags: serverTags ? serverTags.split('|').map((tag: string) => tag as MpxSearchTagTypes) : [],
    })
  }

  toServerQuery(): MpxServerQuery {
    const serverQuery = { ...this } as any

    if (this.lowerLeft) {
      serverQuery.lowerLeft = queryForLatLong(this.lowerLeft)
    }
    if (this.upperRight) {
      serverQuery.upperRight = queryForLatLong(this.upperRight)
    }

    if (this.tags) {
      serverQuery.tags = this.tags && this.tags.join('|')
    }

    if (this.fldHistory) {
      serverQuery.fldHistory = this.fldHistory && this.fldHistory.join('|')
    }

    serverQuery.allowedAutoAggregations = serverQuery.allowedAutoAggregations || [
      MpxGeoPoliticalAggregationType.COUNTY,
    ]

    return serverQuery
  }

  static getBounds(query: MpxSearchQuery): Bounds | undefined {
    if (query && query.lowerLeft && query.upperRight) {
      return {
        lowerLeft: query.lowerLeft,
        upperRight: query.upperRight,
      }
    }
  }
  getBounds(): Bounds | undefined {
    return MpxSearchQueryModel.getBounds(this)
  }
}
