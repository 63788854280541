import { maxBy, prop } from 'ramda'

import { CiboResult, CiboResultStatus } from '../types'

export class CiboResultModel implements CiboResult {
  expectedCalcDate: string
  jobId: string
  modelVersion: string
  status: CiboResultStatus
  updatedAt: string

  constructor(result: CiboResult) {
    this.expectedCalcDate = result.expectedCalcDate
    this.jobId = result.jobId
    this.modelVersion = result.modelVersion
    this.status = result.status
    this.updatedAt = result.updatedAt
  }

  // TSC is difficult to figure out for ramda sometimes.
  // There is a test for this function and the TS signature is in place.
  static mostRecent: (results: CiboResult[]) => CiboResult | undefined = results =>
    // @ts-ignore
    results.length ? results.reduce(maxBy(prop('updatedAt'))) : undefined

  get isError() {
    return this.status === 'failed'
  }
  get isPending() {
    return this.status === 'queued' || this.status === 'processing'
  }
  get isSuccess() {
    return this.status === 'success'
  }
}
