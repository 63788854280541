import { PrgDetail, ResourceDetailInputBase } from '@cibo/core'
import { useMemo } from 'react'

/**
 * Similar to useIsRequired, but returns true if the field is missing.
 * @returns function which takes a string and returns a boolean if the field is required or missing
 */
export const useShowDetailAttribute = <T extends ResourceDetailInputBase = ResourceDetailInputBase>(
  prgDetail?: PrgDetail
) => {
  const isRequired: Record<keyof T, boolean> | undefined = useMemo(
    () =>
      prgDetail?.reqAttrs
        ?.filter(path => path[0] === 'input')
        .reduce(
          (acc, item) => ({ ...acc, [item[item.length - 1]]: true }),
          {} as Record<keyof T, boolean>
        ),
    [prgDetail]
  )

  return (fieldName: keyof T) => (!!isRequired ? isRequired[fieldName] : true)
}
