import { GeneralResourceDetail, PracticeContentDataTypes } from '@cibo/core'
import { usePracticesContent } from '@cibo/landmanager'
import { DataGridPro, FriendlyError, ResponsiveDialog } from '@cibo/ui'
import Star from '@mui/icons-material/Star'
import {
  Alert,
  Box,
  Button,
  Checkbox,
  DialogActions,
  DialogContent,
  FormControlLabel,
  Stack,
  Typography,
  useTheme,
} from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { useQueryClient } from '@tanstack/react-query'
import { prop } from 'ramda'
import { ChangeEvent, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../hooks/useProgramEngagementDetails'
import { PROGRAMS_QUERY_KEY } from '../../queries'
import { PracticeDrawer } from './PracticeDrawer'
import {
  practiceAddOrRemoveColumn,
  practiceDescriptionColumn,
  practicePointsColumn,
} from './PracticesListColumns'
import { CombinedPractice, PracticesListProps } from './practicesListTypes'

export const PracticesList = <T extends GeneralResourceDetail = GeneralResourceDetail>({
  userRole,
  ownerName,
  onError,
  onSuccess,
  onUpdating,
  resourceIds: [resourceId],
  selectedPoolName,
  selectedPractices,
  setSelectedPractices,
  recommendationsPointsSummary,
}: PracticesListProps<T>) => {
  const { palette } = useTheme()
  const queryClient = useQueryClient()
  const { t } = useTranslation('@cibo/programs/PracticesList')

  const [paginationModel, setPaginationModel] = useState({
    pageSize: 5,
    page: 0,
  })
  const [dialogState, setDialogState] = useState<{
    open: boolean
    practice?: PracticeContentDataTypes
  }>({ open: false, practice: undefined })
  const [drawerState, setDrawerState] = useState<{
    open: boolean
    practice?: PracticeContentDataTypes
  }>({ open: false, practice: undefined })
  const [isChecked, setIsChecked] = useState(false)

  const updateEngagementDetails = useUpdateProgramEngagementDetails<T>()
  const details = useProgramEngagementDetails({
    resourceId,
    detailRequirements: [{ traitId: 'eqipRecommendedPracticesIntent', dataType: 'workflow' }],
  })

  const pools = recommendationsPointsSummary.data?.poolReports.map(prop('rankingPool'))
  const selectedPool = pools?.find(({ name }) => name === selectedPoolName)

  const practiceContent = usePracticesContent()

  useEffect(() => {
    setSelectedPractices((details?.data?.[0]?.result as string[]) || [])
  }, [details.dataUpdatedAt])

  const practicesContentItems = practiceContent.data?.items as PracticeContentDataTypes[]
  const practices = useMemo<CombinedPractice[]>(() => {
    const relevantPractices: CombinedPractice[] = []

    practicesContentItems?.forEach((allPractices: PracticeContentDataTypes) => {
      const matchingPractice = recommendationsPointsSummary.data?.practicePointsByPoolName(
        allPractices.fields.practiceId
      )

      if (matchingPractice) {
        relevantPractices.push({ ...allPractices, points: matchingPractice })
      }
    })

    relevantPractices.sort((practiceA: CombinedPractice, practiceB: CombinedPractice) => {
      if (selectedPoolName) {
        const pointEffectDiff =
          (practiceB.points[selectedPoolName]?.effect || -0) -
          (practiceA.points[selectedPoolName]?.effect || -0)

        if (pointEffectDiff !== 0) {
          return pointEffectDiff
        }
        if (
          practiceA.points[selectedPoolName] !== undefined &&
          practiceB.points[selectedPoolName] === undefined
        )
          return -1
        if (
          practiceA.points[selectedPoolName] === undefined &&
          practiceB.points[selectedPoolName] !== undefined
        )
          return 1

        const addonA = practiceA.points[selectedPoolName]?.isAddon
        const addonB = practiceB.points[selectedPoolName]?.isAddon
        if (addonB && !addonA) return 1
        if (addonA && !addonB) return -1
        return 0
      } else {
        return 0
      }
    })

    return relevantPractices
  }, [practiceContent.dataUpdatedAt, recommendationsPointsSummary.dataUpdatedAt, selectedPoolName])

  const onClickSelect = (practiceId: string) => {
    const newSelection = selectedPractices.includes(practiceId)
      ? selectedPractices.filter(id => id !== practiceId)
      : [...selectedPractices, practiceId]

    setSelectedPractices(newSelection)

    onUpdating?.()
    updateEngagementDetails
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'eqipRecommendedPracticesIntent',
            result: newSelection.length ? newSelection : undefined,
          } as T,
        ],
      })
      .then(() => {
        onSuccess && onSuccess()
        queryClient.refetchQueries({
          queryKey: [PROGRAMS_QUERY_KEY.EQIP_RECOMMENDATIONS, resourceId],
        })
      })
      .catch(onError)
  }

  const columns = useMemo(
    () =>
      [
        practiceDescriptionColumn({ t, setDrawerState, selectedPoolName }),
        practicePointsColumn({
          t,
          recommendationsPointsSummary: recommendationsPointsSummary.data,
        }),
        practiceAddOrRemoveColumn({
          t,
          selectedPractices,
          onClickSelect,
          setDialogState,
          disabled: details.data?.[0]?.immutable,
        }),
      ].filter(Boolean) as GridColDef<CombinedPractice>[],
    [recommendationsPointsSummary.dataUpdatedAt, selectedPractices, selectedPoolName]
  )

  const handleCloseDialog = () => {
    setIsChecked(false)
    setDialogState({ open: false, practice: undefined })
  }
  const handleCheckBox = (event: ChangeEvent<HTMLInputElement>) => {
    setIsChecked(event.target.checked)
  }

  if (practiceContent.isError) {
    return <FriendlyError message={t('errorMessage')} />
  }

  return (
    <Stack spacing={3}>
      <Typography variant="h6" component="h2">
        {t('practiceSelectionTitle')}
      </Typography>

      {!!selectedPool?.addonText && (
        <Alert
          icon={
            <Star
              sx={{ alignSelf: 'center' }}
              fontSize="large"
              // @ts-ignore mui hasn't appropriately typed the 'color' prop to accept extended themes
              color="rating"
            />
          }
          variant={palette.mode === 'dark' ? 'filled' : 'standard'}
          style={{ backgroundColor: palette.background.default }}
        >
          <Typography variant="body1" color="text.primary">
            {t('bonusTitle')}
          </Typography>
          <Typography variant="body1" color="text.secondary">
            {selectedPool.addonText}
          </Typography>
        </Alert>
      )}

      <DataGridPro
        autoHeight
        getRowHeight={() => 'auto'}
        pagination
        disableRowSelectionOnClick
        paginationModel={paginationModel}
        pageSizeOptions={[5]}
        onPaginationModelChange={setPaginationModel}
        columns={columns}
        rows={practices}
        loading={practiceContent.isPending || recommendationsPointsSummary.isPending}
        getRowId={row => row.sys.id}
      />
      <ResponsiveDialog
        open={dialogState.open}
        onClose={handleCloseDialog}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <DialogContent>
          <Stack spacing={3}>
            <Typography variant="h6" component="h2">
              {t('dialogTitle')}
            </Typography>
            <Typography variant="body1" color="text.secondary">
              {t('dialogSubtitle')}
            </Typography>
            <Box
              padding={1.5}
              sx={{
                backgroundColor: 'background.default',
                overflowY: 'auto',
              }}
            >
              <Typography variant="body1">
                {dialogState.practice?.fields?.requirementText}
              </Typography>
            </Box>
            <FormControlLabel
              control={
                <Checkbox checked={isChecked} onChange={handleCheckBox} name="confirmCheckbox" />
              }
              label={t('confirmCheckboxLabel')}
            />
            <DialogActions>
              <Stack spacing={3} direction="row">
                <Button color="secondary" onClick={handleCloseDialog}>
                  {t('cancel')}
                </Button>
                <Button
                  color="primary"
                  onClick={() => {
                    dialogState.practice?.fields?.practiceId &&
                      onClickSelect(dialogState.practice?.fields?.practiceId)
                    handleCloseDialog()
                  }}
                  variant="contained"
                  disabled={!isChecked}
                >
                  {t('addPractice')}
                </Button>
              </Stack>
            </DialogActions>
          </Stack>
        </DialogContent>
      </ResponsiveDialog>

      <PracticeDrawer
        practice={drawerState.practice}
        isSelected={selectedPractices.includes(drawerState.practice?.fields.practiceId || '')}
        userRole={userRole}
        ownerName={ownerName}
        open={drawerState.open}
        onCloseDrawer={() => setDrawerState({ open: false, practice: undefined })}
        onClickSelect={() =>
          drawerState.practice?.fields.practiceId &&
          onClickSelect(drawerState.practice?.fields.practiceId)
        }
      />
    </Stack>
  )
}
