import { CoverCropChip, NotObservedChip } from '@cibo/ui'
import { DetailSimpleCellProps } from '../types'

export const CoverCropSimpleCell = ({ detail }: DetailSimpleCellProps) => {
  return !!detail?.input?.crop ? (
    <CoverCropChip crop={detail?.input?.crop} source={detail.source} />
  ) : detail?.result !== undefined ? (
    <CoverCropChip crop={detail.result} source={detail.source} />
  ) : (
    <NotObservedChip />
  )
}
