import { AssetsAPI, RDEqipOptionalForms } from '@cibo/core'
import { Loading } from '@cibo/profile'
import {
  ResourceDetailFeatureTaskEditorProps,
  downloadAxiosResponse,
  useTraitContent,
} from '@cibo/ui'
import InsertDriveFileOutlined from '@mui/icons-material/InsertDriveFileOutlined'
import { Button, Card, FormHelperText, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUpdateProgramEngagementDetails } from '../../../../hooks/useProgramEngagementDetails'

export const RDEqipOptionalFormsTaskEditor = ({
  detailRequirements,
  resourceIds: [resourceId],
}: ResourceDetailFeatureTaskEditorProps<RDEqipOptionalForms>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipOptionalFormsTaskEditor')
  const content = useTraitContent('eqipOptionalForms')
  const updateDetail = useUpdateProgramEngagementDetails()

  const onClick = async (url: string, fileName: string) => {
    AssetsAPI.proxy({ url: `${window.location.protocol}${url}` }).then(response => {
      downloadAxiosResponse(fileName)(response)
      updateDetail.mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'eqipOptionalForms',
            year: detailRequirements[0].year,
            result: {
              //TODO: only update the form that was clicked
              historicallyUnderserved: true,
              powerOfAttorney: true,
            },
          } as RDEqipOptionalForms,
        ],
      })
    })
  }

  if (content.isPending) {
    return <Loading />
  }

  return (
    <Stack spacing={3}>
      <Stack>
        <Typography variant="h6">{t('optionalForms')}</Typography>
      </Stack>

      {content.data?.fields.displayableAssets?.map(({ fields: { asset, title, description } }) => (
        <Card key={asset.sys.id} variant="outlined">
          <Stack direction="row" alignItems="center" padding={2} spacing={3} flex={1}>
            <InsertDriveFileOutlined color="action" />
            <Stack flex={1}>
              <Typography variant="body1">{title}</Typography>
              <Typography variant="body2" color="text.secondary">
                {asset.fields.title}
              </Typography>
              {!!description && (
                <Typography variant="body2" color="text.secondary">
                  {description}
                </Typography>
              )}
            </Stack>
            <Button
              color="secondary"
              onClick={() => onClick(asset.fields.file.url, asset.fields.file.fileName)}
            >
              {t('download')}
            </Button>
          </Stack>
        </Card>
      ))}

      <FormHelperText>{t('formHelperText')}</FormHelperText>
    </Stack>
  )
}
