import { useState, useEffect } from 'react'
import { ResourceDetail } from '@cibo/core'
import { ToggleButtonGroup, ToggleButton } from '@mui/material'
import { TraitCellEditorProps } from '../../types'

import { useTranslation } from 'react-i18next'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result === undefined ? '' : detail?.result?.furrowIrrigation
}

export const EqipIrrigationMethodCellEditor = ({
  traitId,
  year,
  params,
  saveDetail,
  removeDetail,
}: TraitCellEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/EqipIrrigationMethod')
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleChange = (_: any, value: any) => {
    setAnswer(value)

    if (value === '') {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
        year,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, year, input: { furrowIrrigation: value } }],
      })
    }
  }

  return (
    <ToggleButtonGroup
      exclusive
      value={answer}
      onChange={handleChange}
      sx={{ marginRight: 1 }}
      data-testid={`eqip-irrigation-method_editor`}
    >
      {['yes', 'no', 'unknown'].map(answer => (
        <ToggleButton key={answer} value={answer} aria-label={t(`furrowIrrigation_${answer}`)}>
          {t(`furrowIrrigation_${answer}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
