import { WorkflowItem } from '@cibo/core'
import { AuthUserPermission, Can } from '@cibo/profile'
import { FriendlyError, Markdown, ResponsiveDialog } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Stack, TextField } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSendEnrollmentMessage } from '../../../queries'

export const EnrollmentMessageDialog = ({
  open,
  onCancel,
  onSuccess,
  onError,
  workflow,
}: {
  open: boolean
  onCancel: () => void
  onSuccess: () => void
  onError?: (e: any) => void
  workflow?: WorkflowItem
}) => {
  const { t } = useTranslation('@cibo/programs/EnrollmentMessageDialog')
  const sendEnrollmentMessage = useSendEnrollmentMessage()
  const [customMessage, setCustomMessage] = useState<string>()

  useEffect(() => {
    sendEnrollmentMessage.reset()
    setCustomMessage(undefined)
  }, [open])

  if (!workflow) return null

  const handleSendUserMessage = () => {
    sendEnrollmentMessage
      .mutateAsync({ customMessage, workflowId: workflow.id })
      .then(() => {
        onSuccess()
      })
      .catch(e => !!onError && onError(e))
  }

  return (
    <ResponsiveDialog open={open} onClose={onCancel}>
      <DialogTitle>{t('sendMessageToUserTitle')}</DialogTitle>
      <DialogContent>
        <Stack spacing={3}>
          <Markdown>
            {t('sendMessageToUserInstructions', {
              context:
                !!workflow.ownedBy.familyName && !!workflow.ownedBy.givenName
                  ? 'fullName'
                  : 'email',
              familyName: workflow.ownedBy.familyName,
              givenName: workflow.ownedBy.givenName,
              email: workflow.ownedBy.userPrimaryEmail,
            })}
          </Markdown>

          <Can useAny={AuthUserPermission.COMMIT_NON_CUSTODIAL_MESSAGE_CUSTOM}>
            <TextField
              multiline
              rows={6}
              placeholder={t('optionalCustomMessage')}
              value={customMessage}
              onChange={({ target: { value } }) => setCustomMessage(value)}
              data-testid="custom-enrollment-message"
              disabled={sendEnrollmentMessage.isPending}
            />
          </Can>

          {sendEnrollmentMessage.isError && <FriendlyError message={t('sendMessageError')} />}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button disabled={sendEnrollmentMessage.isPending} onClick={onCancel}>
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={sendEnrollmentMessage.isPending}
          variant="contained"
          onClick={handleSendUserMessage}
          data-testid="send-enrollment-message"
        >
          {t('send')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
