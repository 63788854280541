import { ResourceDetailYearly } from './ResourceDetail'

export const RDAmendmentSummaryUnits = [
  'gallonsPerAcre',
  'poundsPerAcre',
  'tonsPerAcre',
  'acreInchesPerHour',
  'gallonsPerMin',
  'millionGallonsPerDay',
] as const
export type RDAmendmentSummaryUnit = typeof RDAmendmentSummaryUnits[number]
export const RDAmendmentSummaryApplicationTypes = [
  'dry',
  'liquid',
  'slurry',
  'not_available',
] as const
export type RDAmendmentSummaryApplicationType = typeof RDAmendmentSummaryApplicationTypes[number]

export interface RDAmendmentSummaryInput {
  rate?: number
  units?: RDAmendmentSummaryUnit
  compost: boolean
  manure: boolean
  biochar: boolean
  applicationType: RDAmendmentSummaryApplicationType
}

export interface RDAmendmentSummary extends ResourceDetailYearly<RDAmendmentSummaryInput, boolean> {
  traitId: 'amendmentSummary'
}
