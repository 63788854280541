import { Array2d, CDLLandUse } from '@cibo/core'
import { UseQueryResult } from '@tanstack/react-query'
import { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { SupplyShedComparisonReport, useSupplyShedComparisonReport } from '../../queries'

const FOCUS_CROPS = ['corn', 'soybeans', 'cotton', 'wheat']
const DEFAULT_FOCUS_YEAR = 2021

export interface SupplyShedComparisonContextProps {
  comparisonBox?: Array2d
  resourceIds?: string[]
  cashCrops: {
    getDisplayCrops(year: number): Array<CDLLandUse>
    expanded: boolean
    setExpanded(state: boolean): void
  }
  coverCrops: {
    expanded: boolean
    setExpanded(state: boolean): void
  }
  tillage: {
    expanded: boolean
    setExpanded(state: boolean): void
  }
  reportsQuery: UseQueryResult<SupplyShedComparisonReport | undefined>
  focusYear: number
  setFocusYear(year: number): void
  reportYears?: number[]
}

// @ts-ignore can create the required reportsQuery: UseQueryResult outside of an FC
export const SupplyShedComparisonContext = createContext<SupplyShedComparisonContextProps>({
  cashCrops: {
    getDisplayCrops: () => [],
    expanded: false,
    setExpanded: () => null,
  },
  coverCrops: {
    expanded: false,
    setExpanded: () => null,
  },
  tillage: {
    expanded: false,
    setExpanded: () => null,
  },
  focusYear: new Date().getFullYear(),
  setFocusYear: () => null,
})
SupplyShedComparisonContext.displayName = 'SupplyShedComparisonContext'

export const SupplyShedComparisonProvider = ({ children }: PropsWithChildren<{}>) => {
  const [params] = useSearchParams()

  const resourceIdsParams = params?.get('resourceIds')
  const resourceIds = resourceIdsParams?.split(',').filter(Boolean)

  const reportsQuery = useSupplyShedComparisonReport(resourceIds)

  const [cashCropsExpanded, setCashCropsExpanded] = useState<boolean>(false)
  const [coverCropsExpanded, setCoverCropsExpanded] = useState<boolean>(false)
  const [tillageExpanded, setTillageExpanded] = useState<boolean>(false)

  const [reportYears, setReportYears] = useState<number[]>([
    DEFAULT_FOCUS_YEAR - 1,
    DEFAULT_FOCUS_YEAR,
  ])
  const [focusYear, setFocusYear] = useState<number>(new Date().getFullYear())

  const reports =
    reportsQuery.data &&
    [...reportsQuery.data.shedReports, reportsQuery.data.fieldsReport].filter(Boolean)

  const getDisplayCrops = (year: number) => {
    const displayCrops = new Set()

    reports?.forEach(reportModel => {
      const reportCrops = reportModel.yearCrops(year) || {}

      for (const crop in reportCrops) {
        if (FOCUS_CROPS.includes(crop)) {
          displayCrops.add(crop)
        }
      }
    })

    return Array.from(displayCrops) as CDLLandUse[]
  }

  useEffect(() => {
    const reportYearSet = new Set()

    reports?.forEach(report => {
      report?.reportYears?.forEach(year => {
        reportYearSet.add(year)
      })
    })

    setReportYears(Array.from(reportYearSet) as number[])
    setFocusYear(DEFAULT_FOCUS_YEAR)
  }, [reportsQuery.isPending])

  return (
    <SupplyShedComparisonContext.Provider
      value={{
        resourceIds,
        reportsQuery,
        comparisonBox: reportsQuery.data?.comparisonBox,
        reportYears,
        focusYear,
        setFocusYear,
        cashCrops: {
          getDisplayCrops,
          expanded: cashCropsExpanded,
          setExpanded: setCashCropsExpanded,
        },
        coverCrops: {
          expanded: coverCropsExpanded,
          setExpanded: setCoverCropsExpanded,
        },
        tillage: {
          expanded: tillageExpanded,
          setExpanded: setTillageExpanded,
        },
      }}
    >
      {children}
    </SupplyShedComparisonContext.Provider>
  )
}
