import ChevronLeftRounded from '@mui/icons-material/ChevronLeftRounded'
import MultipleStop from '@mui/icons-material/MultipleStopSharp'
import { Box, Button, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'

interface NotFoundProps {
  returnTo?: string
}

export const NotFound = ({ returnTo }: NotFoundProps) => {
  const { t } = useTranslation('impact/pages/NotFound')

  return (
    <Stack flex={1} data-testid="notFound">
      <Stack flex={1} alignItems="center" justifyContent="center">
        <Stack spacing={1} maxWidth={500} textAlign="center">
          <Stack direction="row" alignItems="center" justifyContent="center" flex={1} spacing={2}>
            <MultipleStop color="error" sx={{ fontSize: 32 }} />
            <Typography variant="h4">{t('notFoundTitle')}</Typography>
          </Stack>
          <Typography color="text.secondary">{t('notFoundBody')}</Typography>
        </Stack>
      </Stack>
      {!!returnTo && (
        <Box>
          <Button
            component={Link}
            to={returnTo}
            variant="outlined"
            startIcon={<ChevronLeftRounded />}
          >
            {t('returnTo')}
          </Button>
        </Box>
      )}
    </Stack>
  )
}
