import { FieldModel, FieldsAPI, FileDetails } from '@cibo/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { Dispatch, SetStateAction } from 'react'
import { QUERY_KEY } from '../../../queries/queryKey'

interface UseUploadDetailProps {
  setProgress: Dispatch<SetStateAction<number>>
  controller: AbortController
}

export interface FieldDetailUploadRequest extends FileDetails {
  resourceId: string
  year?: number
  traitId: string
  id?: string
}

export const useUploadDetailFile = ({ setProgress, controller }: UseUploadDetailProps) => {
  const queryClient = useQueryClient()
  return useMutation<any, Error, any>({
    mutationFn: ({ file, resourceId, traitId, ...rest }: FieldDetailUploadRequest) => {
      const formData = new FormData()
      formData.append('file', file)
      formData.append('resourceId', resourceId)
      formData.append('traitId', traitId)
      Object.keys(rest).map(key =>
        formData.append(
          key,
          // @ts-ignore Yes, this is any. That's intentional.
          rest[key]
        )
      )
      return FieldsAPI.uploadFileDetail(resourceId, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: progressEvent =>
          setProgress((progressEvent.loaded / progressEvent.total) * 50),
        onDownloadProgress: progressEvent =>
          setProgress(50 + (progressEvent.loaded / progressEvent.total) * 50),
        // signal: controller.signal as AbortSignal, // This is apparently broken until axios1.1.3 :-(
      })
    },

    mutationKey: [QUERY_KEY.SAVE_DETAIL_FILE],
    onSuccess: (result: FieldModel, request: any) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.resetQueries({ queryKey: [QUERY_KEY.FIELDS, request.resourceId] })
    },
  })
}
