import { LivestockSpecies, RewardToken, TillageType } from '@cibo/core'
import { useTranslation } from 'react-i18next'

export const useFigures = () => {
  const { t } = useTranslation('@ui/useFigures')

  return {
    acres: (acres?: number, abbreviate?: boolean, includeString?: boolean) =>
      typeof acres === 'undefined'
        ? '--'
        : t('acres', {
            count: acres,
            context: includeString === false ? 'short' : !!abbreviate ? 'abbreviate' : 'long',
          }),
    abbreviated: (value: number) => t('abbreviated', { value }),
    cropYield: ({ cropYield, crop }: { cropYield: number; crop: string }) =>
      t('yield', { cropYield, context: crop }),
    cropYieldPerAcre: ({ cropYield, crop }: { cropYield: number; crop: string }) =>
      t('yieldPerAcre', { cropYield, context: crop }),
    earnedAmount: ({ amount, token }: { amount: number; token: RewardToken }) =>
      t('earnedAmount', { count: amount, amount, context: token }),
    fields: (fields: number) => t('fields', { count: fields }),
    livestockSpecies: (species: LivestockSpecies) => t('livestockSpecies', { context: species }),
    livestockHead: (headCount: number) => t('livestockHead', { count: headCount }),
    modifiedDate: (modifiedDate: Date) => t('modifiedDate', { modifiedDate }),
    percent: (fraction: number, withPlus?: boolean) =>
      t('percent', { percent: fraction, context: withPlus ? 'plus' : undefined }),
    tillage: (tillageType: TillageType | string) => t(`tillage_${tillageType}`),
    tonsCO2: (tons: number, perAcre?: boolean) =>
      t(perAcre ? 'tonsCO2PerAcre' : 'tonsCO2', { tons }),
  }
}

export type UseFigures = ReturnType<typeof useFigures>
