import { MAINLAND_US_BOUNDS } from '@cibo/ui'

export const clampViewState = ({ viewState }: any) => {
  if (viewState.zoom > 18) {
    viewState.zoom = 18
  }

  if (viewState.zoom < 3) {
    viewState.zoom = 3
  }

  if (viewState.longitude < MAINLAND_US_BOUNDS[0][0]) {
    viewState.longitude = MAINLAND_US_BOUNDS[0][0]
  }
  if (viewState.longitude > MAINLAND_US_BOUNDS[1][0]) {
    viewState.longitude = MAINLAND_US_BOUNDS[1][0]
  }
  if (viewState.latitude < MAINLAND_US_BOUNDS[0][1]) {
    viewState.latitude = MAINLAND_US_BOUNDS[0][1]
  }
  if (viewState.latitude > MAINLAND_US_BOUNDS[1][1]) {
    viewState.latitude = MAINLAND_US_BOUNDS[1][1]
  }

  viewState.bearing = 0
  viewState.pitch = 0

  return viewState
}
