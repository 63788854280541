/* istanbul ignore file */
import { RecommendationPointsResponse } from '../types/RecommendationPointsTypes'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  SUMMARY: '/program-engagement-eqip-acceptance-score/:engagementId',
}

export type RecommendationsDataType = {
  engagementId: string
  doFullLoad?: boolean
  selectedPractices?: string[] // If this parameter is not present, then the selected potential practices assigned to the engagement workflow will be used.
}

export class RecommendationsAndAcceptanceScoreForPoolsInputAPI {
  static summary({ engagementId, ...data }: RecommendationsDataType, options?: any) {
    return agent
      .post<RecommendationPointsResponse>(
        generatePath(PATHS.SUMMARY, { engagementId }),
        data,
        options
      )
      .then(response => response.data)
  }
}
