import { Markdown, RadioBooleanField } from '@cibo/ui'
import { Stack } from '@mui/material'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../../types'

export const WetlandComplianceEditor = ({
  name = 'eqipPracticesWetlandCompliance',
  requirement,
  year,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/WetlandCompliance')

  return (
    <Field name={name} id={name}>
      {() => {
        return (
          <Stack spacing={2}>
            <Markdown>{t('editorQuestion', { period: year })}</Markdown>

            <RadioBooleanField
              name={`${name}.wetlandCompliance`}
              data-testid={`rdWetlandCompliance.${year}.wetlandCompliance`}
            />
          </Stack>
        )
      }}
    </Field>
  )
}
