// @ts-nocheck
import { CoverCrop, RDCashCropSpecies, ResourceDetailSource } from '@cibo/core'
import { Chip, ChipProps, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SourceIcon } from '../SourceIcon'
import { NoneChip } from './NoneChip'

interface CropChipProps extends Omit<ChipProps, 'icon'> {
  crop: CoverCrop | RDCashCropSpecies | boolean
  source?: ResourceDetailSource | 'none' | 'notObserved'
}

export const CoverCropChip = ({ crop, source, size = 'small', ...chipProps }: CropChipProps) => {
  const { t } = useTranslation('@cibo/ui/cropNames')
  const { t: tOther } = useTranslation('@cibo/ui/CoverCropChip')
  const { palette } = useTheme()

  if (palette[crop]) {
    chipProps.color = crop
  }

  if (crop === false) {
    return <NoneChip source={source} />
  } else if (crop === true) {
    return (
      <Chip
        label={tOther('unknown')}
        size={size}
        {...chipProps}
        sx={{ border: `solid 1px ${palette.grey[palette.mode === 'dark' ? '400' : '600']}` }}
        icon={source ? <SourceIcon source={source} sx={{ opacity: 0.8 }} /> : null}
      />
    )
  }

  return (
    <Chip
      size={size}
      {...chipProps}
      label={t(crop)}
      sx={
        chipProps.color
          ? { border: `solid 1px ${palette[crop][palette.mode === 'dark' ? 'light' : 'dark']}` }
          : { border: `solid 1px ${palette.grey[palette.mode === 'dark' ? '400' : '600']}` }
      }
      icon={source ? <SourceIcon source={source} sx={{ opacity: 0.8 }} /> : null}
    />
  )
}
