import dayjs from 'dayjs'
import formatcoords from 'formatcoords'
import { Feature } from 'geojson'
import numeral from 'numeral'
import { flatten, path, prop } from 'ramda'

import {
  LandProfile,
  LandProfileModel,
  LandProfileType,
  MpxParcel,
  MpxParcelModel,
  SQUARE_METERS_PER_ACRE,
} from '@cibo/core'

import { NAN_VALUE, nanCheck, nullCheck } from '../nanCheck'

export class LandProfileDisplay {
  profile: LandProfileModel

  constructor(profile: LandProfile) {
    this.profile = new LandProfileModel(profile)
  }

  static formatDate = (value: string) => dayjs(value).format('MM/DD/YYYY')

  static fromMpxParcel = (parcel: MpxParcel) => {
    const parcelModel = new MpxParcelModel(parcel)
    return new LandProfileDisplay(parcelModel.landProfile)
  }

  static fromUserFeature = (feature: Feature) => {
    const profile = {
      ...feature.properties,
      label: feature.properties && JSON.parse(feature.properties.label),
    } as LandProfile

    return new LandProfileDisplay(profile)
  }

  labelPosition = () => this.profile.labelPosition

  resourceId = () => this.profile.resourceId

  static locationDMS = nullCheck(([lon, lat]: number[]) =>
    formatcoords([lat, lon]).format('DDMMssX', { decimalPlaces: 0, latLonSeparator: ', ' })
  )
  locationDMS = () => LandProfileDisplay.locationDMS(this.profile.labelPosition)

  static acreage = nanCheck((value: number) => numeral(value).format('0,0'))
  acreage = () => LandProfileDisplay.acreage(this.profile.acreage)

  static acreageForSquareMeters = (squareMeters: number) =>
    LandProfileDisplay.acreage(squareMeters / SQUARE_METERS_PER_ACRE)

  static sustainability = nanCheck((value: number) => `${Math.round(value)}`)
  sustainability = () => LandProfileDisplay.sustainability(this.profile.sustainability)
  static sustainabilityUpdatedDate = nullCheck(LandProfileDisplay.formatDate)
  sustainabilityUpdatedDate = () =>
    LandProfileDisplay.sustainabilityUpdatedDate(this.profile.sustainabilityValuesUpdatedAt)

  static stability = nanCheck((value: number) => `${Math.round(value)}`)
  stability = () => LandProfileDisplay.stability(this.profile.stability)

  static productivity = nanCheck((value: number) => `${Math.round(value)}`)
  productivity = (precision = 0) => this.profile.productivity.toFixed(precision)

  static leasePerAcreEstimate = nanCheck(
    (value: number, format?: string) => `${numeral(value).format(format || '$0,0')}/ac`
  )
  leasePerAcreEstimate(format?: string) {
    const value = prop('leaseValuePerAcre', this.profile)
    return LandProfileDisplay.leasePerAcreEstimate(value, format)
  }

  static leasePerAcreEstimateNoSuffix = nanCheck(
    (value: number, format?: string) => `${numeral(value).format(format || '$0,0')}`
  )
  leasePerAcreEstimateNoSuffix(format?: string) {
    const value = prop('leaseValuePerAcre', this.profile)
    return LandProfileDisplay.leasePerAcreEstimateNoSuffix(value, format)
  }

  static leaseEstimate = nanCheck((value: number, format?: string) =>
    numeral(value).format(format || '$0,0')
  )
  leaseEstimate(format?: string) {
    const leaseValue = this.profile.leaseValue

    return LandProfileDisplay.leaseEstimate(leaseValue, format)
  }

  static valuation = nanCheck((value: any, format?: string) =>
    numeral(value).format(format || '$0,0', Math.floor)
  )
  static valuationAbbreviated = nanCheck((value: any) =>
    numeral(value).format('$0[.]0a', Math.floor)
  )
  valuation(format?: string) {
    const totalValue = this.profile.valuation

    return LandProfileDisplay.valuation(totalValue, format)
  }

  static valuePerAcre = nanCheck((value: number) => `${numeral(value).format('$0,0')}/ac`)
  static valuePerAcreNumberOnly = nanCheck((value: number) => numeral(value).format('0,0'))
  valuePerAcre() {
    const value = this.profile.valuePerAcre
    return LandProfileDisplay.valuePerAcre(value)
  }
  static valuePerAcreNoSuffix = nanCheck((value: number) => `${numeral(value).format('$0,0')}`)
  valuePerAcreNoSuffix = () => LandProfileDisplay.valuePerAcreNoSuffix(this.profile.valuePerAcre)

  static tillableAcres = nanCheck((value: number, precision = 0) => value.toFixed(precision))
  tillableAcres(precision?: number) {
    return LandProfileDisplay.tillableAcres(this.profile.tillableAcres, precision)
  }

  static tillablePercent = nanCheck(
    (value: number, precision = 0) => `${value.toFixed(precision)}%`
  )
  tillablePercent = (precision = 0) => {
    const value = this.profile.tillablePercent
    return LandProfileDisplay.tillablePercent(value, precision)
  }

  owners() {
    if (this.profile.type === LandProfileType.PARCEL) {
      return [
        {
          geometrySlug: this.profile.geometrySlug as string,
          names: this.ownerList(),
          address: this.ownerAddress(),
        },
      ]
    } else if (this.profile.type === LandProfileType.FIELD) {
      if (this.profile.entries) {
        return this.profile.entries.map(
          ({ geometrySlug, refItem: { ownerInfo: { owner, ownerAddress } = {} } }) => ({
            names: LandProfileDisplay.ownerList(owner),
            address: ownerAddress,
            geometrySlug,
          })
        )
      }
    }
    return []
  }

  owner() {
    return this.profile.owner
  }

  static ownerList(value?: string) {
    return value ? value.split('  ') : [NAN_VALUE]
  }
  ownerList = () => {
    if (this.profile.type === 'field') {
      const { entries } = this.profile
      return flatten(
        (entries || []).map(entry => {
          const ownerInfo = path(['refItem', 'ownerInfo'])(entry) as {
            owner: string
            ownerAddress: string
          }
          return ownerInfo ? LandProfileDisplay.ownerList(ownerInfo.owner) : undefined
        })
      ).filter(owner => !!owner)
    } else {
      return LandProfileDisplay.ownerList(this.profile.owner)
    }
  }

  static ownerAddress(value?: string) {
    //break the address by the first comma, this is not, like, an exact science or anything
    return value ? value.replace(/, /, '\n') : '';
  }
  ownerAddress = () => {
    if (this.profile.type === 'field') {
      const { entries } = this.profile
      return flatten(
        (entries || []).map(({ refItem: { ownerInfo } }) =>
          ownerInfo ? LandProfileDisplay.ownerAddress(ownerInfo.ownerAddress) : undefined
        )
      ).filter(owner => !!owner)
    } else {
      return LandProfileDisplay.ownerAddress(this.profile.ownerAddress)
    }
  }

  getLabelParts() {
    const labels = prop('label', this.profile)
    return labels ? [labels.primary, labels.secondary, labels.tertiary].filter(Boolean) : []
  }

  getLabelString() {
    return this.getLabelParts().join(', ')
  }
  getNonPrimaryString() {
    return [this.labelSecondary(), this.labelTertiary()].filter(label => !!label).join(', ')
  }
  labelPrimary() {
    return this.profile.label?.primary
  }
  labelSecondary() {
    return this.profile.label?.secondary
  }
  labelTertiary() {
    return this.profile.label?.tertiary
  }

  geometrySlug() {
    return this.profile.geometrySlug
  }

  description() {
    return this.profile.description
  }

  taxInfo() {
    if (this.profile.type === LandProfileType.PARCEL) {
      return this.profile.entries
        ? [
            {
              resourceId: this.profile.resourceId,
              geometrySlug: this.profile.geometrySlug,
              ...this.profile.entries[0].refItem.taxInfo,
              ...this.profile.entries[0].refItem.label,
            },
          ]
        : []
    } else if (this.profile.entries) {
      return this.profile.entries
        .map(
          ({ refItemId, geometrySlug, refItem }) =>
            refItem && {
              ...refItem.taxInfo,
              ...refItem.label,
              resourceId: refItemId,
              geometrySlug,
            }
        )
        .filter(Boolean)
    }
    return []
  }

  ghgEmissions() {
    return numeral(this.profile.ghgEmissions).format('0,0')
  }

  nitrogenLeaching() {
    return numeral(this.profile.nitrogenLeaching).format('0,0')
  }

  stateProductivity() {
    const { statePi } = this.profile
    return isNaN(statePi as number) ? 'n/a' : numeral(statePi).format('0,0')
  }
  nationalProductivity() {
    const { nccpi } = this.profile
    return isNaN(nccpi as number) ? 'n/a' : numeral(nccpi).format('0,0')
  }
  static productivityUpdatedDate = nullCheck(LandProfileDisplay.formatDate)
  productivityUpdatedDate = () =>
    LandProfileDisplay.productivityUpdatedDate(this.profile.productivityUpdatedAt)

  saleDate(format = 'M/d/YYYY') {
    return this.profile.transferDate ? dayjs(this.profile.transferDate).format(format) : 'n/a'
  }

  saleAmount(format = '$0,0') {
    if (this.profile.transferVal) {
      return numeral(this.profile.transferVal).format(format)
    } else if (this.profile.aggTransferVal) {
      return `${numeral(this.profile.aggTransferVal).format(format)}`
    } else {
      return 'n/a'
    }
  }

  aggTransferVal() {
    return this.profile.aggTransferVal ? numeral(this.profile.aggTransferVal).format('$0,0') : 'n/a'
  }
  transferVal() {
    return this.profile.transferVal ? numeral(this.profile.transferVal).format('$0,0') : 'n/a'
  }
}
