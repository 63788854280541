import { TextField, TextFieldProps } from '@mui/material'
import { Field, useField } from 'formik'
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from 'formik-mui'

export interface AutocompleteFieldProps extends Partial<AutocompleteProps<any, any, any, any>> {
  name: string
  InputProps?: TextFieldProps
}

export const AutocompleteField = ({
  name,
  InputProps,
  ...autocompleteProps
}: AutocompleteFieldProps) => {
  const [field, { error, touched }] = useField(name)

  return (
    <Field
      name={name}
      component={Autocomplete}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <TextField
          {...params}
          {...InputProps}
          name={name}
          error={touched && !!error}
          helperText={touched ? error : undefined}
        />
      )}
      value={field.value || null}
      {...autocompleteProps}
    />
  )
}
