import bbox from '@turf/bbox'
import centerOfMass from '@turf/center-of-mass'
import { Feature, MultiPolygon, Point, Polygon } from '@turf/helpers'
import {
  equals,
  filter,
  flatten,
  intersection,
  isEmpty,
  isNil,
  pluck,
  propEq,
  reject,
  sum,
  uniq,
  where,
} from 'ramda'
import {
  AgreementShape,
  Benchmark,
  BenchmarkDefinition,
  BenchmarkStateTraitReason,
  FDRStatus,
  FDRStatusEntry,
  Field,
  FieldActions,
  FieldMetadata,
  OrgUserSearchResult,
  PlatformUser,
  ProgramBenchmarkName,
  ProgramStatus,
  RDAgronomicDataCSV,
  RDAmendment,
  RDBiomassBurning,
  RDCashCrop,
  RDConversionDate,
  RDCoverCrop,
  RDFertilizer,
  RDGeometry,
  RDGrazing,
  RDIrrigation,
  RDLivestock,
  RDNativeConversion,
  RDOffer,
  RDTillage,
  RDWetlandDesignation,
  ResourceDetail,
  ResourceDetailYearly,
  ResourceProgram,
  RewardToken,
  TraitId,
  UserRef,
} from '../types'
import { TermsetFarmNumber } from '../types/Termset'
import { DetailModel } from './DetailModel'

export const descendDateProp = (prop: string) => (a: any, b: any) => {
  const dateA = a[prop] && a[prop].valueOf()
  const dateB = b[prop] && b[prop].valueOf()

  if (dateA && !dateB) {
    return -1
  }
  if (!dateA && dateB) {
    return 1
  }
  if (!dateA && !dateB) {
    return 0
  }

  return dateA - dateB
}

const SIZE_REASONS = [
  'FieldTooSmall',
  'fieldTooLarge',
  'fieldSizeNotInValidRange',
  'fieldPolygonTooSmall',
] as BenchmarkStateTraitReason[]

interface ParsedFieldModel extends Partial<Omit<Field, 'expectedCalcDate'>> {
  expectedCalcDate?: Date
}

export class FieldModel implements ParsedFieldModel {
  createdAt?: string | undefined
  updatedAt?: string | undefined
  detailsUpdatedAt?: string | undefined
  resourceId: string
  details: ResourceDetail[]
  geometryDetail?: RDGeometry
  programs?: ResourceProgram[]
  programStatuses?: ProgramStatus[]
  metadata: FieldMetadata
  feature?: Feature<Polygon | MultiPolygon, any>
  geometry?: Polygon | MultiPolygon
  centerOfMass?: Feature<Point, {}>
  ownedBy: UserRef
  updatedBy: UserRef
  expectedCalcDate?: Date
  footprintResolved?: boolean
  fdrStatus: FDRStatusEntry[]
  fdrGlobalStatus: FDRStatus[]

  constructor(params: Field) {
    this.resourceId = params.resourceId
    this.details = params.details?.map(detail => new DetailModel(detail)) || ([] as DetailModel[])
    this.programs = params.programs
    this.programStatuses = params.programStatuses
    this.createdAt = params.createdAt
    this.updatedAt = params.updatedAt
    this.detailsUpdatedAt = params.detailsUpdatedAt
    this.footprintResolved = params.footprintResolved
    this.expectedCalcDate = params.expectedCalcDate ? new Date(params.expectedCalcDate) : undefined
    this.metadata = params.metadata
    this.ownedBy = params.ownedBy
    this.updatedBy = params.updatedBy
    this.fdrGlobalStatus = params.fdrGlobalStatus
    this.fdrStatus = params.fdrStatus

    this.geometryDetail = this.resolveStandingDetail<RDGeometry>('geometry')

    if (!!this.geometryDetail?.input) {
      //@ts-ignore - mismatch on `feature`??
      this.feature = this.geometryDetail.input?.feature
      this.geometry = this.feature?.geometry
      this.centerOfMass = centerOfMass(this.feature)
    }
    this.fdrGlobalStatus = params.fdrGlobalStatus
    this.fdrStatus = params.fdrStatus
  }

  get bbox() {
    return this.geometry && bbox(this.geometry)
  }

  get id() {
    return this.resourceId
  }

  get author() {
    return this.metadata.author
  }

  get sharedWith() {
    return this.metadata.sharedWith
  }

  get supplyShed() {
    return this.metadata.supplyShed
  }

  get name() {
    const detail = this.resolveStandingDetail('name')

    return detail?.result
  }
  get geometrySlug() {
    const detail = this.resolveStandingDetail('geometry')

    return detail?.result?.geometrySlug
  }
  get acres() {
    const detail = this.resolveStandingDetail('geometry')

    return detail?.result?.acres
  }
  get tillableAcres() {
    const detail = this.resolveStandingDetail('geometry')
    return detail?.result?.tillableAcres
  }
  get county() {
    const detail = this.resolveStandingDetail('geometry')

    return detail?.result?.county
  }
  get state() {
    const detail = this.resolveStandingDetail('geometry')

    return detail?.result?.state?.toUpperCase()
  }
  get locationLabel() {
    if (!this.state) {
      return undefined
    }
    if (this.county) {
      return `${this.county}, ${this.state.toUpperCase()}`
    } else {
      return `${this.state.toUpperCase()}`
    }
  }
  get ownerLabel() {
    if (!this.owner.familyName) {
      return this.owner.userPrimaryEmail
    } else {
      return `${this.owner.givenName} ${this.owner.familyName}`
    }
  }

  get latitude() {
    const point = this.centerOfMass
    if (!!point) {
      return point.geometry.coordinates[0]
    }
  }
  get longitude() {
    const point = this.centerOfMass
    if (!!point) {
      return point.geometry.coordinates[1]
    }
  }

  get labels() {
    return this.metadata.labels || []
  }

  get grower() {
    return this.metadata.grower
  }

  get farm() {
    return this.metadata.termsets?.find(propEq('type', 'TSFarm'))
  }

  get fsaFarm() {
    return this.metadata.termsets?.find(propEq('type', 'FsaNumber')) as TermsetFarmNumber<
      PlatformUser
    >
  }

  get owner() {
    return this.ownedBy
  }

  get ownerId() {
    return this.ownedBy.userId
  }

  get reportYears() {
    return filter(
      (a: number) => a > 0,
      reject(isNil, uniq(pluck('year', this.details))) as number[]
    )
      .sort()
      .reverse()
  }

  traitDetails<T extends ResourceDetail>(traitId: TraitId, year?: number) {
    const details = this.details.filter(propEq('traitId', traitId)) as T[]

    if (!year) {
      return details
    }

    return (details as ResourceDetailYearly[]).filter(propEq('year', year))
  }

  get amendment() {
    return this.details.filter(({ traitId }) => traitId === 'amendment') as RDAmendment[]
  }

  get cashCrops() {
    return this.details.filter(({ traitId }) => traitId === 'cashCrop') as RDCashCrop[]
  }

  get coverCrops() {
    return this.details.filter(({ traitId }) => traitId === 'coverCrop') as RDCoverCrop[]
  }

  get fertilizerEvents() {
    return this.details.filter(({ traitId }) => traitId === 'fertilizer') as RDFertilizer[]
  }

  get tillageEvents() {
    return this.details.filter(({ traitId }) => traitId === 'tillage') as RDTillage[]
  }

  get irrigationEvents() {
    return this.details.filter(({ traitId }) => traitId === 'irrigation') as RDIrrigation[]
  }

  get livestock() {
    return this.details.filter(({ traitId }) => traitId === 'livestock') as RDLivestock[]
  }

  get grazingEvents() {
    return this.details.filter(({ traitId }) => traitId === 'grazing') as RDGrazing[]
  }

  get biomassBurning() {
    return this.details.filter(({ traitId }) => traitId === 'biomassBurning') as RDBiomassBurning[]
  }

  get nativeVegetationConversionDate(): RDConversionDate | undefined {
    return this.details.filter(({ traitId }) => traitId === 'nativeVegetationConversionDate')[0] as
      | RDConversionDate
      | undefined
  }
  get nativeVegetationConversion(): RDNativeConversion | undefined {
    return this.details.filter(({ traitId }) => traitId === 'nativeVegetationConversion')[0] as
      | RDNativeConversion
      | undefined
  }

  get commitments(): ResourceDetail<{}>[] {
    return this.details.filter(({ traitId }) => traitId === 'commitment')
  }

  get offers() {
    return this.details.filter(({ traitId }) => traitId === 'offer') as RDOffer[]
  }

  get organizationId() {
    return this.metadata.orgId
  }

  get wetlandDesignation() {
    return this.details.filter(
      ({ traitId }) => traitId === 'includesWetlandDesignation'
    ) as RDWetlandDesignation[]
  }

  get agronomicDataCsv() {
    return this.details.filter(
      ({ traitId }) => traitId === 'agronomicDataCsv'
    ) as RDAgronomicDataCSV[]
  }

  get isPartnerField() {
    // TODO: CPD-3829
    // These are the best indicators that a field is a partner field in a syndication relationship.
    return (!this.fdrGlobalStatus || this.fdrGlobalStatus.length === 0) && !this.programStatuses
  }

  get participatingPrograms() {
    return this.programStatuses?.filter(program => program.participating)
  }

  can = (permissions: FieldActions[]) => {
    let pseudoPermissions = true
    if (permissions.includes('assign_owner')) {
      const status = this.programStatuses?.find(
        s => !!s.workflow || s.benchmarks.find(b => b.benchmark === 'commit' && b.status === 'pass')
      )
      pseudoPermissions = !status
    }
    return (
      pseudoPermissions &&
      permissions
        .filter(p => p !== 'assign_owner')
        .every(permission => this.metadata.permissions?.includes(permission))
    )
  }

  canAny = (permissions: FieldActions[]) => {
    let pseudoPermissions = true
    if (permissions.includes('assign_owner')) {
      const status = this.programStatuses?.find(
        s => !!s.workflow || s.benchmarks.find(b => b.benchmark === 'commit' && b.status === 'pass')
      )
      pseudoPermissions = !status
    }
    return (
      pseudoPermissions &&
      permissions
        .filter(p => p !== 'assign_owner')
        .find(permission => this.metadata.permissions?.includes(permission))
    )
  }

  resolveStandingDetail = <T extends ResourceDetail>(traitId: TraitId, year?: number): T => {
    const traitDetails = this.details.filter(
      detail => detail.traitId === traitId && (!year || detail.year === year)
    )

    if (traitDetails.length === 1) {
      return traitDetails[0] as T
    }

    const confirmedDetails = traitDetails.filter(detail => detail.confirmed)

    if (confirmedDetails.length) {
      return confirmedDetails.sort(descendDateProp('confirmedAt'))[0] as T
    }

    const userDetails = traitDetails.filter(detail => detail.source === 'user')

    if (userDetails.length) {
      return userDetails.sort(descendDateProp('updatedAt'))[0] as T
    }

    return traitDetails.sort(descendDateProp('updatedAt'))[0] as T
  }

  findMissingData = (programId: string) => {
    const fieldProgram = this.programs?.find(propEq('programId', programId))
    return fieldProgram?.validReason?.filter(
      propEq('reason', 'missingData' as BenchmarkStateTraitReason)
    )
  }

  findMissingTraits = (programId: string) => {
    const missingData: Array<{ traitId: TraitId }> = this.findMissingData(programId) || []

    return uniq<TraitId>(pluck('traitId', missingData))
  }

  getLabels = () => this.metadata?.labels || []

  getProgramStatus = (programId: string) => {
    return this.programStatuses?.find(p => p.programId === programId)
  }

  getBenchmarkStatus = (programId: string, benchmark: Benchmark) => {
    return this.getProgramStatus(programId)?.benchmarks?.find<BenchmarkDefinition>(
      // @ts-ignore typescript is not picking up ramda overload typing
      propEq<ProgramBenchmarkName, BenchmarkDefinition>('benchmark', benchmark)
    )
  }

  getBenchmarkReasons = (
    programId: string,
    benchmark: Benchmark,
    traitId: TraitId,
    year?: number
  ) => {
    const benchmarkStatus = this.getProgramStatus(programId)?.benchmarks.find<BenchmarkDefinition>(
      // @ts-ignore typescript is not picking up ramda overload typing
      propEq<ProgramBenchmarkName, BenchmarkDefinition>('benchmark', benchmark)
    )

    const reasons = benchmarkStatus?.reasons

    return reasons?.filter(
      where({
        traitId: equals(traitId),
        year: year ? equals(year) : () => true,
      })
    )
  }
  isDetailAbsentOrIncomplete = (
    programIds: string[],
    benchmark: Benchmark,
    traitId: TraitId,
    year?: number
  ) => {
    const detail = this.resolveStandingDetail(traitId, year)

    if (detail === undefined) {
      return true
    }

    // This only works if field.programStatuses includes info for this program&benchmark
    const reasons = flatten(
      programIds.map(programId => this.getBenchmarkReasons(programId, benchmark, traitId, year))
    )

    // @ts-ignore TS doesn't understand these are filtered to be defined only
    return reasons.filter(Boolean).some(propEq('reason', 'missingData'))
  }

  program = (programId: string) => this.programs?.find(p => p.programId === programId)

  reasons = (programId: string) => {
    const program = this.programs?.find(propEq('programId', programId))

    return program?.validReason
  }

  committedAt = (programId: string) =>
    this.programs?.find(p => p.programId === programId)?.committedAt

  inBounds = (programId: string) => {
    const program = this.programs?.find(p => p.programId === programId)
    return program
      ? !program?.validReason?.find(
          ({ reason, traitId }) => traitId === 'geometry' && reason === 'notInArea'
        )
      : false
  }

  isWithinSizeRange = (programId: string) => {
    const program = this.programs?.find(p => p.programId === programId)
    return program
      ? !program?.validReason?.find(
          ({ reason, traitId }) => traitId === 'geometry' && SIZE_REASONS.includes(reason)
        )
      : false
  }

  sizeRangeReason = (programId: string) => {
    const program = this.programs?.find(p => p.programId === programId)
    return program?.validReason?.find(
      ({ reason, traitId }) => traitId === 'geometry' && SIZE_REASONS.includes(reason)
    )
  }

  hasLedgerConfict = (programId: string) => {
    const program = this.programs?.find(p => p.programId === programId)
    return program?.validReason?.find(({ reason }) => reason === 'ledgerConflict')
  }

  workflowStatus = (workflowId: string, benchmark: Benchmark) => {
    // @todo: investigate:
    return this.programStatuses
      ?.find(({ workflow }) => workflow === workflowId)
      ?.benchmarks.find(b => b.benchmark === benchmark)?.status
  }

  isParticipatingInProgram = (programId: string) => {
    const programStatus = this.getProgramStatus(programId)
    return programStatus ? programStatus.participating : false
  }

  canAddToWorkflow = (programId: string) => {
    return !!this.getProgramStatus(programId)?.availableForWorkflow
  }

  canEditBoundary = () => {
    return !!(this.can(['edit_land']) && !this.resolveStandingDetail('geometry').immutable)
  }

  traitsPermitEnrollment = (programId: string) => {
    if (this.isWithinSizeRange(programId) && this.inBounds(programId)) {
      //see if any traits with reasons are locked
      const program = this.programs?.find(p => p.programId === programId)
      return !program?.validReason?.some(
        ({ reason, traitId, year }) => this.resolveStandingDetail(traitId, year)?.immutable
      )
    }
    return false
  }

  programPerAcrePotential = (programId: string) =>
    this.programs?.find(p => p.programId === programId)?.perAcrePotential

  programCreditPerAcrePotential = (programId: string) => {
    const potential = this.programPerAcrePotential(programId)
    return !!potential ? potential.filter(({ credits }) => credits !== undefined) : []
  }

  programDollarPerAcrePotential = (programId: string) => {
    const potential = this.programPerAcrePotential(programId)
    return !!potential ? potential.filter(({ value }) => value !== undefined) : []
  }

  programTokenPotential = ({ programId, token }: { programId: string; token: RewardToken }) => {
    const rewards = this.programs
      ?.find(p => p.programId === programId)
      ?.rewards?.potential?.filter(p => p.token === token)
      ?.map(r => r.amount * this.acres)
    return !!rewards ? sum(rewards) : 0
  }

  programEarned = (programId: string) => {
    return this.programs?.find(p => p.programId === programId)?.rewards?.earned
  }
  programDollarsEarned = (programId: string) => {
    const earned = this.programEarned(programId)
    return !!earned ? earned.filter(({ token }) => token === 'usd') : []
  }
  programCreditsEarned = (programId: string) => {
    const earned = this.programEarned(programId)
    return !!earned ? earned.filter(({ token }) => token === 'carbonCredit') : []
  }

  eligibleForEnrollment = (programId?: string) => {
    if (programId) {
      return this.fdrStatus
        .find(fdr => fdr.programId === programId)
        ?.status.some(status => status === 'eligibleForEnrollment')
    } else {
      return this.fdrStatus.some(fdr =>
        fdr.status.some(status => status === 'eligibleForEnrollment')
      )
    }
  }

  availableForEnrollment = (programId?: string) => {
    if (programId) {
      return this.fdrStatus
        .find(fdr => fdr.programId === programId)
        ?.status.some(status => status === 'availableForEnrollment')
    } else {
      return this.fdrStatus.some(fdr =>
        fdr.status.some(status => status === 'availableForEnrollment')
      )
    }
  }

  get isMissingProfile() {
    return this.programStatuses?.some(p =>
      p.benchmarks.some(b => b.benchmark === 'profile' && b.status === 'incomplete')
    )
  }

  workflowForProgram(programId: string) {
    return this.getProgramStatus(programId)?.workflow
  }

  // paymentAmount is in cents
  offerPayment = (offerGroup?: string) => {
    const offerDetail = this.resolveStandingDetail('offer')
    if (!!offerGroup && offerDetail.input?.offerGroup === offerGroup) {
      return offerDetail.input?.paymentAmount / 100
    } else if (!offerGroup) {
      return offerDetail.input?.paymentAmount / 100
    }
    return undefined
  }

  /**
   * Returns the most recent offer for the given offerGroup sorted by updatedAt
   * @param offerGroup: string
   * @returns the most recent offer for the given offerGroup
   */
  offer({ offerGroup }: Pick<AgreementShape<OrgUserSearchResult>, 'offerGroup'>) {
    return this.offers
      .filter(offer => offer.input.offerGroup === offerGroup)
      .sort(
        (a, b) => new Date(b.updatedAt ?? 0).getTime() - new Date(a.updatedAt ?? 0).getTime()
      )[0]
  }

  get enrolledPrograms() {
    return this.fdrStatus.filter(({ status }) => status.includes(FDRStatus.enrolled))
  }

  get eligiblePrograms() {
    return this.fdrStatus.filter(({ status }) => status.includes(FDRStatus.eligibleForEnrollment))
  }

  get hasOverlaps() {
    return this.fdrGlobalStatus && this.fdrGlobalStatus.includes(FDRStatus.overlapsField)
  }

  get hasBoundaryProblem() {
    return (
      this.fdrGlobalStatus &&
      !isEmpty(
        intersection(this.fdrGlobalStatus, [FDRStatus.overlapsField, FDRStatus.overlapsSelf])
      )
    )
  }

  get hasOverlapsForOtherUser() {
    return (
      this.fdrGlobalStatus &&
      this.fdrGlobalStatus.includes(FDRStatus.overlapsField) &&
      !this.fdrGlobalStatus.includes(FDRStatus.overlapsSameOwner)
    )
  }

  programIsFuture(programId: string) {
    const programStatus = this.fdrStatus.find(status => status.programId === programId)
    return programStatus ? programStatus.status.includes(FDRStatus.futureEnrollment) : false
  }
}
