import { path } from 'ramda'

import { MpxParcel, LandProfile, LandProfileType } from '../types'

export class MpxParcelModel implements MpxParcel {
  id: string
  geometry: any
  properties: any

  constructor(params: MpxParcel) {
    this.id = params.id
    this.geometry = params.geometry
    this.properties = params.properties
  }

  get tillableAcres(): number {
    const { tillablePercent, acreage } = this.properties

    return (tillablePercent / 100) * acreage
  }

  get landProfile(): LandProfile {
    const properties = this.properties

    return {
      type: LandProfileType.PARCEL,
      acreage: properties.acreage,
      capabilities: ['carbon_lab', 'yield_forecast'],
      tillableAcres: properties.tillableAcres,
      productivity: properties.productivity,
      leaseValuePerAcre: properties.operatorRent,
      stability: properties.stability,
      sustainability: path(
        ['properties', 'ciboData', 'sustainability', 'sustainabilityIndex'],
        this
      ),
      geometrySlug: properties.geometrySlug,
      tillableGeometrySlug: properties.tillableGeometrySlug,
      boundingBox: properties.boundingBox
    }
  }
}
