import { RankingPoolSymbol } from '@cibo/core/src/types/RecommendationPointsTypes'
import AgricultureIcon from '@mui/icons-material/AgricultureOutlined'
import BugReportOutlinedIcon from '@mui/icons-material/BugReportOutlined'
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined'
import ForestOutlinedIcon from '@mui/icons-material/ForestOutlined'
import GrainOutlinedIcon from '@mui/icons-material/GrainOutlined'
import GrassIcon from '@mui/icons-material/Grass'
import ScienceOutlined from '@mui/icons-material/ScienceOutlined'
import WaterIcon from '@mui/icons-material/WaterDropOutlined'
import WbSunnyOutlinedIcon from '@mui/icons-material/WbSunnyOutlined'

export const iconMap: Record<RankingPoolSymbol, any> = {
  agriculture: AgricultureIcon,
  bug: BugReportOutlinedIcon,
  description: DescriptionOutlinedIcon,
  forest: ForestOutlinedIcon,
  grain: GrainOutlinedIcon,
  grass: GrassIcon,
  science: ScienceOutlined,
  sunny: WbSunnyOutlinedIcon,
  waterDrop: WaterIcon,
  water: WaterIcon,
}
