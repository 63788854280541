import { CarbonFootprintResult, Footprint } from '../types'

import { CiboResultModel } from './CiboResultModel'
import { FootprintModel } from './FootprintModel'

export class CarbonFootprintResultModel extends CiboResultModel {
  results: Footprint
  footprint: FootprintModel

  constructor(result: CarbonFootprintResult) {
    super(result)

    this.results = result.results
    this.footprint = result.results && new FootprintModel(result.results)
  }

  get total() {
    if (!this.results) return undefined

    const { acreage, total } = this.results

    return acreage * total
  }
}
