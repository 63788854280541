import {
  EQIP_PRACTICES_WATER_TABLE_OPTIONS,
  EqipPracticesWaterTableOption,
  RDEqipPracticesWaterTable,
} from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../SelectRollupEditor'

export const RDEqipPracticesWaterTableDetailFeature: ResourceDetailFeature<RDEqipPracticesWaterTable> =
  {
    traitId: 'eqipPracticesWaterTable',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesWaterTable>) => (
      <SelectRollupEditor<RDEqipPracticesWaterTable, readonly EqipPracticesWaterTableOption[]>
        options={EQIP_PRACTICES_WATER_TABLE_OPTIONS}
        detailKey="highWaterTable"
        ns="@cibo/landmanager/RDEqipPracticesWaterTableDetailFeature"
        {...props}
      />
    ),
  }
