import { RDFertilizerEvent } from '@cibo/core'
import { Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const FertilizerCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.events?.length) {
    return (
      <Tooltip
        title={
          <Stack spacing={1} padding={1}>
            {detail.input?.events.map(
              (
                { date, type, nitrogenAmnt, product, rate, units }: RDFertilizerEvent,
                index: number
              ) => (
                <Stack key={index}>
                  <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
                    {t('eventDate', { date: new Date(date) })}
                  </Typography>
                  <Typography variant="body2">
                    {type === 'other' ? `${product} (${t('other')})` || t('other') : t(type)}
                    {' - '}
                    {nitrogenAmnt
                      ? t('amntLbsNac', { value: nitrogenAmnt })
                      : t('applicationRateDisplay', {
                          rate: rate,
                          units: t(units),
                        })}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
        }
      >
        <Stack spacing={0.5}>
          <Typography variant="body1">
            {t('events', { count: detail.input?.events?.length })}
          </Typography>
          {detail.result && (
            <Typography variant="caption">
              {t('totalAmntLbsNac', { value: detail.result })}
            </Typography>
          )}
        </Stack>
      </Tooltip>
    )
  }

  return <Typography>{t('none')}</Typography>
}
