import { ResourceDetail } from './ResourceDetail'

export const BIOLOGICAL_TYPE = ['locus', 'general', 'none'] as const

export type BiologicalType = typeof BIOLOGICAL_TYPE[number]

export type BiologicalInput = {
  type: BiologicalType
  product?: string | null
}

export interface RDBiological extends ResourceDetail {
  traitId: 'biological'
  year: number
  result: BiologicalType
  input: BiologicalInput
}
