import { ResourceDetail, TraitId } from '@cibo/core'
import { FriendlyError, ResponsiveDialog } from '@cibo/ui'
import Cancel from '@mui/icons-material/Cancel'
import DeleteForever from '@mui/icons-material/DeleteForeverRounded'
import ReportProblemRounded from '@mui/icons-material/ReportProblemRounded'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { FileRejection, useDropzone } from 'react-dropzone'
import { useTranslation } from 'react-i18next'
import { SaveDetailRequest, useRemoveDetail } from '../queries'
import { useDeleteFileAndDetails } from '../queries/useDeleteFileAndDetails'
import { useUploadDetailFile } from '../queries/useUploadDetailFile'
import { AgronomicDataCSVErrors } from './AgronomicDataCSVErrors'

export interface FileUploadFormValues {
  file: { file: File; name?: string }
}

const ACCEPTED_FILES = ['.csv']
const MAX_FILE_SIZE = 5 * 1024 * 1024

type Props = {
  traitId: TraitId
  year?: number
  params: GridRenderCellParams
  t: TFunction
  saveDetail(request: SaveDetailRequest): void
  uploadError?: string
  name?: string
}

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result?.file[0]
}

export const AgronomicDataCSVCellEditor = ({
  traitId,
  year,
  params,
  name = 'agronomicDataCsv',
}: Props) => {
  const theme = useTheme()
  const { t } = useTranslation('@cibo/landmanager/AgronomicDataCSVEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [file, setFile] = useState(answerFromDetail(detail))
  const controller = new AbortController()
  const [progress, setProgress] = useState(0)
  const [deleteDetailsOpen, setDeleteDetailsOpen] = useState(false)
  const [errorsOpen, setErrorsOpen] = useState(false)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [deleteError, setDeleteError] = useState<any>()
  const [deleteFileIsLoading, setDeleteFileIsLoading] = useState<boolean>(false)

  const { mutateAsync: removeDetail } = useRemoveDetail()
  const { mutateAsync: uploadFileDetail, isSuccess } = useUploadDetailFile({
    setProgress,
    controller,
  })
  const { mutateAsync: deleteFileAndRelatedDetails } = useDeleteFileAndDetails()

  useEffect(() => {
    setFile(answerFromDetail(detail))
  }, [detail])

  const removeFile = () => {
    setDeleteFileIsLoading(true)
    removeDetail(
      {
        resourceId: params.row.resourceId,
        traitId,
      },
      { onSuccess: () => setDeleteDetailsOpen(false) }
    )
      .then(() => setDeleteFileIsLoading(false))
      .catch(setDeleteError)
  }
  const removeFileAndDetails = () => {
    setDeleteFileIsLoading(true)
    deleteFileAndRelatedDetails(
      {
        resourceId: params.row.resourceId,
        traitId,
      },
      { onSuccess: () => setDeleteDetailsOpen(false) }
    )
      .then(() => setDeleteFileIsLoading(false))
      .catch(setDeleteError)
  }

  const cancelUpload = () => {
    controller.abort()
    setFile(undefined)
    setErrorMessage(t('cancelledUpload'))
  }

  const onDrop = useCallback((acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
    if (acceptedFiles.length > 0) {
      setErrorMessage(undefined)
      setFile(acceptedFiles[0])
      uploadFileDetail({
        file: acceptedFiles[0],
        resourceId: params.row.resourceId,
        traitId,
      }).catch(error => {
        setErrorMessage(error.response.data.message)
        setFile(undefined)
      })
    } else {
      setErrorMessage(t(rejectedFiles[0].errors[0].code || 'rejectedFile'))
      setFile(undefined)
    }
  }, [])

  const uploadInProgress = !!progress && !isSuccess

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop,
    accept: ACCEPTED_FILES,
    maxSize: MAX_FILE_SIZE,
    multiple: false,
    noClick: true,
  })

  const linkProps = file
    ? {
        component: Link,
        href: file.uri,
        download: file.name,
        underline: 'none',
      }
    : {
        onClick: open,
      }
  return (
    <>
      <Stack sx={{ width: '100%', zIndex: 5 }} justifyContent="center" {...getRootProps()}>
        <input {...getInputProps()} />
        <Stack ml={1.5}>
          {file ? (
            <>
              <Stack direction="row" justifyContent="space-between" alignItems="center">
                <Stack direction="row" alignItems="center" spacing={1}>
                  {detail?.input?.errors && detail.input.errors.length > 0 && (
                    <ReportProblemRounded color="error" />
                  )}
                  <Typography
                    color={uploadInProgress ? 'default' : 'secondary'}
                    variant="body1"
                    sx={{
                      cursor: 'pointer',
                      display: 'flex',
                      overflowWrap: 'anywhere',
                      textWrap: 'wrap',
                    }}
                    {...linkProps}
                  >
                    {file.name}
                  </Typography>
                </Stack>
                <Stack direction="row">
                  {detail?.input?.errors && detail.input.errors.length > 0 && (
                    <Button
                      variant="outlined"
                      color="error"
                      size="small"
                      onClick={() => setErrorsOpen(true)}
                    >
                      {t('parsingErrors')}
                    </Button>
                  )}
                  {uploadInProgress ? (
                    <IconButton aria-label={t('cancelUpload')} onClick={cancelUpload}>
                      <Cancel />
                    </IconButton>
                  ) : (
                    <IconButton
                      aria-label={t('clearFile')}
                      onClick={() => setDeleteDetailsOpen(true)}
                    >
                      <DeleteForever />
                    </IconButton>
                  )}
                </Stack>
              </Stack>
              {!uploadInProgress && (
                <Typography variant="caption" sx={{ mt: -0.5 }}>
                  {file.size && t('sizeBytes', { size: file.size })}
                </Typography>
              )}
            </>
          ) : (
            <Typography
              color="secondary"
              variant="body2"
              sx={{ flex: 1, alignSelf: 'flex-start', cursor: 'pointer' }}
              {...linkProps}
            >
              {t('upload')}
            </Typography>
          )}
          {errorMessage && (
            <Tooltip title={<Typography variant="body1">{errorMessage}</Typography>}>
              <Typography
                variant="caption"
                color="error"
                sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {errorMessage}
              </Typography>
            </Tooltip>
          )}
        </Stack>
      </Stack>
      {uploadInProgress && (
        <Box
          id="hat"
          sx={{
            background: theme.palette.info.light,
            opacity: 0.3,
            width: `${progress}%`,
          }}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          alignItems="stretch"
          justifyContent="flex-end"
        />
      )}

      {isDragActive && (
        <Box
          sx={{
            background: 'rgba(255,255,255,0.95)',
            zIndex: 20,
          }}
          position="absolute"
          top={0}
          left={0}
          width="100%"
          height="100%"
          alignItems="stretch"
          justifyContent="flex-end"
        >
          <Box
            alignSelf="stretch"
            my={0.5}
            mx={1}
            display="flex"
            justifyContent="center"
            alignItems="center"
            sx={{
              borderRadius: '8px',
              border: `1px ${theme.palette.secondary.main} dashed`,
              height: 'calc(100% - 8px)',
            }}
          >
            <Typography variant="body2" color="secondary">
              {t('dropFileHere')}
            </Typography>
          </Box>
        </Box>
      )}
      <ResponsiveDialog
        fullWidth
        maxWidth="sm"
        open={errorsOpen}
        onClose={() => setErrorsOpen(false)}
      >
        <DialogTitle>{t('errorDialogTitle')}</DialogTitle>
        <DialogContent>
          <AgronomicDataCSVErrors errors={detail?.input?.errors} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorsOpen(false)}>{t('close')}</Button>
        </DialogActions>
      </ResponsiveDialog>
      <ResponsiveDialog
        fullWidth
        maxWidth="sm"
        open={deleteDetailsOpen}
        onClose={() => setDeleteDetailsOpen(false)}
      >
        <DialogTitle>{t('deleteDetailsDialogTitle')}</DialogTitle>
        <DialogContent>
          <Typography>{t('deleteDetailsDialogContent')}</Typography>
          {deleteError && <FriendlyError message={deleteError.response.data.message} />}
        </DialogContent>
        <DialogActions>
          <LoadingButton loading={deleteFileIsLoading} onClick={() => setDeleteDetailsOpen(false)}>
            {t('cancel')}
          </LoadingButton>
          <LoadingButton
            loading={deleteFileIsLoading}
            onClick={removeFile}
            variant="outlined"
            color="error"
          >
            {t('deleteDetailsDecline')}
          </LoadingButton>
          <LoadingButton
            loading={deleteFileIsLoading}
            onClick={removeFileAndDetails}
            variant="contained"
            color="error"
          >
            {t('deleteDetailsConfirm')}
          </LoadingButton>
        </DialogActions>
      </ResponsiveDialog>
    </>
  )
}
