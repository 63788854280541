import { FERTILIZER_UNIT, UNITS_BY_TYPE } from '@cibo/core'
import { SelectField } from '@cibo/ui'
import { Box, Collapse, FormControl, FormLabel, Grid2 as Grid } from '@mui/material'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FertilizerEventEditorProps } from '../FertilizerEventTypes'

export const FertilizerRateAndUnit = ({
  name,
  ...props
}: Pick<FertilizerEventEditorProps, 'name'> & {
  in: boolean
}) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')
  const { values, setFieldValue } = useFormikContext<{ type: any }>()
  const fertilizerUnits = UNITS_BY_TYPE[values?.type] || FERTILIZER_UNIT
  useEffect(() => {
    if (fertilizerUnits.length === 1) {
      setFieldValue('units', fertilizerUnits[0], true)
    }
  }, [fertilizerUnits, values.type])

  return (
    <Collapse {...props}>
      <Box>
        <Grid spacing={2} container>
          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl fullWidth required>
              <FormLabel>{t('rate')}</FormLabel>
              <Field
                component={TextField}
                name={`rate`}
                InputProps={{
                  type: 'number',
                  inputProps: {
                    min: 0,
                  },
                }}
                data-testid={`${name}.rate`}
              />
            </FormControl>
          </Grid>

          <Grid size={{ xs: 12, md: 6 }}>
            <FormControl required fullWidth>
              <FormLabel htmlFor={`${name}.units`}>{t('unit')}</FormLabel>
              <SelectField
                fieldName={'units'}
                options={fertilizerUnits}
                renderOption={t}
                data-testid={`${name}.units`}
                disabled={'' === values?.type || fertilizerUnits.length === 1}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Collapse>
  )
}
