import CancelIcon from '@mui/icons-material/Cancel'
import { Button, Divider, Stack, Typography } from '@mui/material'
import { GridPagination, useGridApiContext } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

export const ServerPaginationFooter = ({
  hideFooterPagination,
  hideFooterSelectedRowCount,
  disableRowDeselect,
}: // Changed to any because this typing was not correct
any) => {
  const { t } = useTranslation('@cibo/ui/ServerPaginationFooter')
  const { current: grid } = useGridApiContext()

  return (
    <>
      <Divider />
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" p={1} alignItems="center" spacing={2}>
          {!hideFooterSelectedRowCount && grid.getSelectedRows().size > 0 ? (
            !disableRowDeselect ? (
              <>
                <Button onClick={() => grid.setRowSelectionModel([])} startIcon={<CancelIcon />}>
                  {t('clearSelection', { count: grid.getSelectedRows().size })}
                </Button>
              </>
            ) : (
              <Typography>{t('selectionCount', { count: grid.getSelectedRows().size })}</Typography>
            )
          ) : null}
        </Stack>

        {!hideFooterPagination && <GridPagination {...({} as any)} />}
      </Stack>
    </>
  )
}
