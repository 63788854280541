import { useTranslation } from 'react-i18next'
import { useSupplyShedFilters } from '../../hooks'
import { MenuItem, Select, SelectChangeEvent, useTheme } from '@mui/material'
import { SupplyShedAggregationType } from '@cibo/core'

export const ShedTypeSelector = () => {
  const { t } = useTranslation('@cibo/landmanager/SupplyShed/Search')
  const { palette } = useTheme()
  const { aggregationType, setAggregationType } = useSupplyShedFilters()

  const handleChangeAggregationType = (event: SelectChangeEvent) => {
    setAggregationType(event.target.value as SupplyShedAggregationType)
  }

  return (
    <Select
      value={aggregationType}
      onChange={handleChangeAggregationType}
      sx={{
        backgroundColor: palette.primary.main,
        color: palette.primary.contrastText,
        '& .MuiSelect-icon': {
          color: palette.primary.contrastText,
        },
        borderBottomLeftRadius: 0,
        borderTopLeftRadius: 0,
      }}
    >
      <MenuItem value="state">{t('state')}</MenuItem>
      <MenuItem value="county">{t('county')}</MenuItem>
      <MenuItem value="watershed">{t('watershed')}</MenuItem>
    </Select>
  )
}
