import { ResourceDetail, TraitId } from '@cibo/core'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Checkbox,
  Collapse,
  FormControl,
  FormControlLabel,
  FormGroup,
  FormHelperText,
  Stack,
  Typography,
  styled,
} from '@mui/material'
import { ChangeEvent, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth, useUpdateUserDetails, useUserDetails } from '../../../hooks'
import { useOrgUser } from '../../../queries'

// @todo - should this be defaulted throughout the app???
const StyledFormControlLabel = styled(FormControlLabel)(() => ({
  marginLeft: 0,
}))

export type MulticheckEditorProps<T extends ResourceDetail> =
  ResourceDetailFeatureTaskEditorProps<T> & {
    detailKey: keyof T['input'] | keyof T['result']
    ns: string
    keys: string[]
  }

/**
 * Assumptions:
 * 1. you will only pass a single resourceId in the resourceIds array
 * 2. there will only ever be a single detail for a given resourceId
 */
export const MulticheckEditor = <T extends ResourceDetail>({
  detailRequirements,
  resourceIds: [resourceId],
  onError,
  onSuccess,
  onUpdating,
  ns,
  detailKey,
  keys,
}: MulticheckEditorProps<T>) => {
  const { t } = useTranslation('@cibo/profile/MulticheckEditor')
  const details = useUserDetails({
    resourceId,
    // @ts-ignore detailRequirements is impossible to type as far as I can tell. it must be both `never` and `undefined`
    detailRequirements,
  })
  const updateUserDetails = useUpdateUserDetails({})
  const { userId } = useAuth()
  const user = useOrgUser(resourceId)
  const context = resourceId === userId?.toString() ? 'participant' : 'manager'
  const ownerName = user.data?.displayNameFull ?? ''
  const [values, setValues] = useState<string[]>((details.data?.[0]?.result as string[]) ?? [])

  useEffect(() => {
    setValues((details.data?.[0]?.result as string[]) ?? [])
  }, [details.dataUpdatedAt])

  const onChange = (event: ChangeEvent<HTMLInputElement>, checked: boolean) => {
    onUpdating?.()

    let newValues = [...values]

    if (checked) {
      if (event.target.value === 'none') {
        newValues = [event.target.value]
      } else {
        newValues.includes('none') && newValues.splice(newValues.indexOf('none'), 1)
        newValues.push(event.target.value)
      }
    } else {
      newValues.splice(newValues.indexOf(event.target.value), 1)
    }

    setValues(newValues)

    updateUserDetails
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: detailKey as TraitId,
            result: newValues.length ? newValues : undefined,
            year: detailRequirements[0]?.year,
          },
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }

  return (
    <Stack>
      <Typography>{t('label', { context, ns, name: ownerName })}</Typography>
      <FormControl component="fieldset" variant="standard" error={updateUserDetails.isError}>
        <FormGroup sx={{ pt: 1 }}>
          {keys.map((key: string) => (
            <StyledFormControlLabel
              key={key}
              control={
                <Checkbox checked={!!values.includes(key)} onChange={onChange} value={key} />
              }
              label={<Markdown>{t(`multiCheckOption_${key}`, { ns, context })}</Markdown>}
              disabled={
                details.data?.[0]?.immutable || details?.isPending || updateUserDetails?.isPending
              }
            />
          ))}
        </FormGroup>
        <FormHelperText>
          <Markdown overrides={{ small: { component: Typography, props: { variant: 'caption' } } }}>
            {t('helperMd', { context, ns, name: ownerName })}
          </Markdown>
        </FormHelperText>
        <Collapse in={updateUserDetails.isError}>
          <FormHelperText>{updateUserDetails.error?.response?.data?.message}</FormHelperText>
        </Collapse>
      </FormControl>
    </Stack>
  )
}
