import { ContentfulApi, TraitContentEntry, TraitId } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'

export const useTraitContent = (traitId?: TraitId) =>
  useQuery({
    queryKey: ['@cibo/ui/traitContent', traitId],
    queryFn: () =>
      ContentfulApi.find({ content_type: 'trait', 'fields.traitId': traitId }).then(items => {
        return items?.[0] as TraitContentEntry
      }),
  })
