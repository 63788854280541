import { OrgUserModel, Persona, UserId } from '@cibo/core'
import { UserSelectionDisplay } from '@cibo/ui'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Autocomplete,
  FormControl,
  FormLabel,
  InputLabelProps,
  TextField,
  TextFieldProps,
  Typography,
} from '@mui/material'
import { Dispatch, SetStateAction, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useOrgUser, usePaginatedOrgUsers } from '../../queries'
import { trackEvent } from '../../utils'

export interface AssignUserControlProps {
  disabled?: boolean
  hideLabel?: boolean
  inputLabelProps?: InputLabelProps
  owningOrgId?: string
  persona?: Persona[]
  readOnly?: boolean
  required?: boolean
  setUserId: Dispatch<SetStateAction<UserId | undefined>> | ((userId?: UserId) => void)
  textFieldProps?: TextFieldProps
  userId?: UserId
}

export const AssignUserControl = (props: AssignUserControlProps) => {
  const {
    disabled,
    hideLabel,
    inputLabelProps,
    owningOrgId,
    persona = ['grower'],
    readOnly,
    required,
    setUserId,
    textFieldProps,
    userId,
  } = props

  const { t } = useTranslation('@cibo/profile/AssignUserControl')
  const [inputValue, setInputValue] = useState<string>()

  const paginatedUsersQuery = usePaginatedOrgUsers({
    isActive: true,
    persona,
    fullText: inputValue,
    orgId: owningOrgId,
  })
  const searchResults = useMemo(
    () => paginatedUsersQuery.data?.items.map(user => new OrgUserModel(user)),
    [paginatedUsersQuery.dataUpdatedAt]
  )
  const user = useOrgUser(userId)

  const value = useMemo(() => {
    return user.data
  }, [user.data?.userId, user.dataUpdatedAt])

  return (
    <FormControl required={required} sx={{ width: '100%' }}>
      {!hideLabel && <FormLabel {...inputLabelProps}>{t('user')}</FormLabel>}
      {!!readOnly ? (
        <Typography>{user.data?.displayNameFull || t('none')}</Typography>
      ) : !!disabled || user.isFetching ? (
        <TextField
          disabled
          data-testid="assign-user-control"
          label={!user.data?.displayNameFull && t('user')}
          value={user.data?.displayNameFull}
          InputProps={{
            endAdornment: <ArrowDropDownIcon />,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
          {...textFieldProps}
        />
      ) : (
        <Autocomplete<OrgUserModel>
          autoHighlight
          id="user-select"
          disabled={user.isFetching}
          loading={paginatedUsersQuery.isPending || user.isFetching}
          selectOnFocus
          clearOnBlur
          handleHomeEndKeys
          options={searchResults || ([] as OrgUserModel[])} // paginatedUsers??
          getOptionLabel={option => option?.displayNameFull || ''}
          isOptionEqualToValue={(option, value) => {
            return option.user.userId === value?.user?.userId
          }}
          // https://mui.com/material-ui/react-autocomplete/#search-as-you-type
          filterOptions={x => x}
          renderOption={(props, option: OrgUserModel) => (
            <UserSelectionDisplay option={option} {...props} key={option.userId} />
          )}
          value={value}
          onChange={(event, newValue) => {
            setUserId(newValue?.user?.userId)
            trackEvent(
              { name: 'global_user_filter', params: ['filter_user'] },
              { filter_user: newValue?.user?.userId }
            )
          }}
          renderInput={params => {
            return (
              <TextField
                data-testid="searchForUsers"
                placeholder={textFieldProps?.placeholder || t('searchForUsers')}
                {...params}
                {...textFieldProps}
              />
            )
          }}
          inputValue={inputValue || ''}
          onInputChange={(e, v) => {
            setInputValue(v)
          }}
        />
      )}
    </FormControl>
  )
}
