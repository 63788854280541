import { RecommendationPointsModel, sanitizeTestId } from '@cibo/core'
import { stripedLinearGradient } from '@cibo/ui'
import LandscapeOutlinedIcon from '@mui/icons-material/LandscapeOutlined'
import {
  Box,
  BoxProps,
  Grid2 as Grid,
  Skeleton,
  Stack,
  Typography,
  alpha,
  styled,
  useTheme,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { iconMap } from '../PracticesList/iconMap'

const ClickableBox = styled((props: BoxProps & { selected: boolean }) => <Box {...props} />)(
  ({ theme, selected }) => ({
    borderRadius: 4,
    borderWidth: 1,
    padding: 12,
    borderStyle: 'solid',
    background: selected ? alpha(theme.palette.secondary.main, 0.08) : 'inherit',
    borderColor: selected ? theme.palette.secondary.main : theme.palette.divider,
    '&:hover': {
      background: alpha(theme.palette.secondary.main, 0.08),
      borderColor: theme.palette.secondary.main,
    },
  })
)

type RankingPoolSummaryProps = {
  selectedPoolName?: string
  setSelectedPoolName: any
  recommendationsPointsSummary: {
    dataUpdatedAt?: Date
    data?: RecommendationPointsModel
    isPending: boolean
  }
}

export const RankingPoolSummary = ({
  selectedPoolName,
  setSelectedPoolName,
  recommendationsPointsSummary,
}: RankingPoolSummaryProps) => {
  const { t } = useTranslation('@cibo/programs/RankingPoolSummary')
  const {
    palette: {
      secondary: { main, light },
      mode,
      success,
      error,
      warning,
    },
  } = useTheme()

  const pools = recommendationsPointsSummary.data?.allPools

  useEffect(() => {
    selectedPoolName === undefined &&
      pools &&
      pools.length > 0 &&
      setSelectedPoolName(pools[0].name)
  }, [pools])

  if (!recommendationsPointsSummary.data) {
    return <Skeleton />
  }

  return (
    <Stack>
      <Stack spacing={0.5}>
        <Typography variant="h6">{t('applicationStrength')}</Typography>
        <Typography variant="subtitle2" color="text.secondary">
          {t('applicationStrengthSummary')}
        </Typography>
      </Stack>
      <Grid container spacing={2} style={{ marginTop: 2, marginLeft: '-16px' }}>
        {pools?.map(pool => {
          const Icon = iconMap[pool.symbol] || LandscapeOutlinedIcon
          const percentage = pool.currentPoints / pool.maxPoints
          return (
            <Grid key={pool.name} size={{ md: 6, xs: 12 }}>
              <ClickableBox
                selected={pool.name === selectedPoolName}
                onClick={() => setSelectedPoolName(pool.name)}
                data-testid={`pool-${sanitizeTestId(pool.name)}`}
              >
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row">
                    <Icon />
                    <Typography variant="body1">{pool.label}</Typography>
                  </Stack>
                  <Typography
                    variant="subtitle2"
                    color={
                      percentage < 0.15
                        ? error.main
                        : percentage > 0.5
                        ? success.main
                        : warning.main
                    }
                  >
                    {percentage < 0.15 ? t('weak') : percentage > 0.5 ? t('strong') : t('good')}
                  </Typography>
                </Stack>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Box
                    sx={{
                      background: stripedLinearGradient(
                        [main, mode === 'light' ? alpha(light, 0.2) : alpha(light, 0.2)],
                        90,
                        percentage * 100
                      ),
                      overflow: 'hidden',
                      height: 4,
                      width: '90%',
                    }}
                  />
                  <Typography variant="body2" color="text.secondary">
                    {t('percent', { number: percentage * 100 })}
                  </Typography>
                </Stack>
              </ClickableBox>
            </Grid>
          )
        })}
      </Grid>
    </Stack>
  )
}
