import {
  CreateProgramEngagementParams,
  EqipApplicationResultsParams,
  FieldModel,
  MpxItemResponse,
  MpxResponseError,
  ProgramEngagement,
  ProgramEngagementConfirmTaskParams,
  ProgramEngagementLockDetailsError,
  ProgramEngagementModel,
  ProgramEngagementUnconfirmTasksParams,
  ProgramEngagementsAPI,
  ProgramEngagementsDetailParams,
  ProgramEngagementsQueryParams,
  UpdateProgramEngagementError,
  UpdateProgramEngagementParams,
} from '@cibo/core'
import { QUERY_KEY } from '@cibo/landmanager'
import { logRequestError } from '@cibo/ui'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { PROGRAMS_QUERY_KEY } from './queryKey'

export const useProgramEngagements = (
  params?: ProgramEngagementsQueryParams,
  options?: { enabled?: boolean }
) => {
  const finalParams = {
    ...params,
  }

  // @todo: remove after https://cibotech.atlassian.net/browse/CPD-3767
  if (Array.isArray(finalParams.filters)) {
    for (let i = 0; i < finalParams.filters.length; i++) {
      if (finalParams.filters[i].userId) {
        if (typeof finalParams.filters[i].userId === 'number') {
          // @ts-ignore
          finalParams.filters[i].userId = finalParams.filters[i].userId.toString()
        } else if (Array.isArray(finalParams.filters[i].userId)) {
          // @ts-ignore
          finalParams.filters[i].userId = finalParams.filters[i].userId.map(id => id.toString())
        }
      }
    }
  }

  return useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, finalParams],
    queryFn: async () => ProgramEngagementsAPI.list(finalParams).then(({ data }) => data),
    enabled: !!params,
    ...options,
  })
}

export const useProgramEngagement = ({ id }: Partial<ProgramEngagementsDetailParams>) =>
  useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, id],
    queryFn: async () => {
      if (!id) return null
      return ProgramEngagementsAPI.detail({ id }).then(
        ({ data }) => new ProgramEngagementModel(data)
      )
    },
    enabled: !!id,
  })

export const useCreateProgramEngagement = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: async (params: CreateProgramEngagementParams) =>
      ProgramEngagementsAPI.create(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS] })
    },
  })
}

export const useUpdateProgramEngagement = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: UpdateProgramEngagementParams) =>
      ProgramEngagementsAPI.update(params).then(({ data }) => {
        queryClient.setQueryData(
          [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, params.id],
          new ProgramEngagementModel(data)
        )
        queryClient.invalidateQueries({
          queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS],
          exact: true,
        })
        queryClient.invalidateQueries({
          queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, 'fields'],
        })
        return new ProgramEngagementModel(data)
      }),
    onError: (error: AxiosError<UpdateProgramEngagementError>) => error,
  })
}

export const useDeleteProgramEngagement = () => {
  const queryClient = useQueryClient()
  return useMutation<
    AxiosResponse<MpxItemResponse<ProgramEngagement>>,
    AxiosError<MpxResponseError>,
    ProgramEngagementsDetailParams
  >({
    mutationFn: async ({ id }: ProgramEngagementsDetailParams) =>
      ProgramEngagementsAPI.delete({ id }).then(data => {
        queryClient.setQueryData([PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, id], null)
        queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS] })
        return data
      }),
  })
}

export const useProgramEngagementFields = (
  params: Partial<ProgramEngagementsDetailParams>,
  enabled = true
) =>
  useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, 'fields', params],
    queryFn: async () =>
      !!params.id
        ? ProgramEngagementsAPI.fields(params as ProgramEngagementsDetailParams).then(
            ({ data }) => ({
              ...data,
              items: data.items.map(field => new FieldModel(field)),
            })
          )
        : { items: [] as FieldModel[] },
    enabled: enabled && !!params.id,
  })

export const useProgramEngagementConfirmTask = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: ProgramEngagementConfirmTaskParams) =>
      ProgramEngagementsAPI.confirmTask(params).then(({ data }) => {
        queryClient.setQueryData(
          [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, params.id],
          new ProgramEngagementModel(data)
        )
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDSET, data.fields.join(',')] })
        queryClient.invalidateQueries({
          queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, 'fields'],
        })
        return data
      }),
    onError: (error: AxiosError<ProgramEngagementLockDetailsError>, variables, context) => {
      logRequestError(error, { query: 'useProgramEngagementConfirmTask', variables, context })
      return error
    },
  })
}

export const useProgramEngagementUnconfirmTasks = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: ProgramEngagementUnconfirmTasksParams) =>
      ProgramEngagementsAPI.unconfirmTasks(params).then(({ data }) => {
        queryClient.setQueryData(
          [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, params.id],
          new ProgramEngagementModel(data)
        )
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDSET, data.fields.join(',')] })
        return data
      }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS] })
    },
    onError: (error: AxiosError<ProgramEngagementLockDetailsError>) => error,
  })
}

export const useUploadEqipApplicationResults = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: async (params: EqipApplicationResultsParams) =>
      ProgramEngagementsAPI.uploadApplicationResults(params).then(({ data }) => {
        queryClient.invalidateQueries({
          queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, params.id, 'resourceDetails'],
        })
        return data
      }),
  })
}

export const useProgramEngagementInvalidation = () => {
  const queryClient = useQueryClient()
  return {
    invalidateAllEngagements: () =>
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS] }),
  }
}
