import {
  RDEqipRecommendedPracticesIntent,
  RecommendationPointsModel,
  RecommendationsAndAcceptanceScoreForPoolsInputAPI,
} from '@cibo/core'
import { logRequestError, NotFound, ResourceDetailFeatureTaskEditorProps, Section } from '@cibo/ui'
import { Stack } from '@mui/material'
import { useEffect, useState } from 'react'
import { PracticesList } from '../../../../components/PracticesList/PracticesList'
import { RankingPoolSummary } from '../../../../components/RankingPoolSummary'

export const PracticesIntentTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<RDEqipRecommendedPracticesIntent>
) => {
  const [selectedPoolName, setSelectedPoolName] = useState<string>()
  const [selectedPractices, setSelectedPractices] = useState<string[]>([])

  const [recommendationsPointsSummary, setRecommendationsPointsSummary] =
    useState<RecommendationPointsModel>()
  const [isPending, setIsPending] = useState(false)
  const [loadFailed, setLoadFailed] = useState(false)
  const [dataUpdatedAt, setDataUpdatedAt] = useState<Date>()

  const [doFullLoad, setDoFullLoad] = useState<boolean>(true)

  const engagementId = props.resourceIds?.[0]

  useEffect(() => {
    setIsPending(true)
    RecommendationsAndAcceptanceScoreForPoolsInputAPI.summary({
      engagementId,
      selectedPractices,
      doFullLoad,
    })
      .then(data => {
        setDoFullLoad(false)
        setRecommendationsPointsSummary(new RecommendationPointsModel(data))
        setDataUpdatedAt(new Date())
      })
      .catch(error => {
        logRequestError(error)
        setLoadFailed(true)
      })
      .finally(() => setIsPending(false))
  }, [engagementId, selectedPractices.join(',')])

  if (loadFailed) {
    return <NotFound />
  }

  return (
    <Stack spacing={3} flex={1}>
      <Section sx={{ flex: 1 }}>
        <Stack padding={3} spacing={3}>
          <RankingPoolSummary
            selectedPoolName={selectedPoolName}
            setSelectedPoolName={setSelectedPoolName}
            {...props}
            recommendationsPointsSummary={{
              isPending,
              dataUpdatedAt,
              data: recommendationsPointsSummary,
            }}
          />
        </Stack>
      </Section>

      <Section sx={{ flex: 1 }}>
        <Stack padding={3} spacing={3}>
          <PracticesList
            selectedPractices={selectedPractices}
            selectedPoolName={selectedPoolName}
            setSelectedPractices={setSelectedPractices}
            {...props}
            recommendationsPointsSummary={{
              isPending,
              dataUpdatedAt,
              data: recommendationsPointsSummary,
            }}
          />
        </Stack>
      </Section>
    </Stack>
  )
}
