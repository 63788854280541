import { FieldModel } from '@cibo/core'
import { Theme } from '@cibo/ui'
import ImageNotSupported from '@mui/icons-material/ImageNotSupported'
import { Skeleton } from '@mui/material'
import { Box } from 'mdi-material-ui'
import { memo, useState } from 'react'
import { useField } from '../../queries/useFields'
import { useThumbnailData } from './getThumbnailData'
import { LandThumbnailProps } from './types'

const pixelRatio = window.devicePixelRatio

// consumer can send a field or a resourceId
type ThumbnailGeometry =
  | { field: FieldModel; resourceId?: string }
  | { field?: FieldModel; resourceId: string }

const LandThumbnailBase = ({
  field,
  resourceId,
  width,
  height,
  skin,
  style = undefined,
}: LandThumbnailProps & ThumbnailGeometry) => {
  const [unresolvedImage, setUnresolvedImage] = useState<boolean>(false)

  // this will be disabled if the field comes with geometry
  const localField = useField(!field?.geometry ? resourceId : undefined)

  const geometry = field?.geometry || localField.data?.geometry

  const thumbnailData = useThumbnailData({
    geometry,
    width,
    height,
    skin,
    pixelRatio,
  })

  if ([width, height].includes(Number.NaN)) return null

  const imageStyle = {
    height,
    width,
    minHeight: height,
    maxWidth: width,
    ...style,
    backgroundColor: Theme.color.surface.contrast.primary,
  }

  if (thumbnailData.isError || unresolvedImage) {
    return (
      <Box
        sx={{
          ...(imageStyle as any),
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <ImageNotSupported
          sx={{ color: 'background.paper' }}
          height={height * 0.85}
          width={width * 0.85}
        />
      </Box>
    )
  }

  if (thumbnailData.isLoading || localField.isLoading) {
    return <Skeleton variant="rectangular" {...{ width, height }} style={{ borderRadius: '4px' }} />
  }

  return (
    <img
      alt={field?.name || localField.data?.name}
      style={{ borderRadius: '4px', overflow: 'hidden', ...imageStyle }}
      src={thumbnailData.data}
      loading="lazy"
      onError={() => setUnresolvedImage(true)}
    />
  )
}

export const LandThumbnail = memo(LandThumbnailBase)
