import { DebugButton } from '@cibo/profile'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'
import { RDEqipAutogenRequiredForms } from '../RDEqipAutogenRequiredFormsResourceDetailFeature'
import { EqipApplicationDocusign } from './EqipApplicationDocusign'
import { PaymentConsentDocusign } from './PaymentConsentDocusign'
import { RDEqipDocuments } from './types'

export const RDEqipDocumentsTaskEditor = ({
  detailRequirements,
  engagementId,
  task,
}: ResourceDetailFeatureTaskEditorProps<RDEqipDocuments, { docusignType: string }>) => {
  // @todo get a better year. the happenstance first requirement may not be yearly
  const detailYear = detailRequirements[0].year as number

  const eqipDocumentsDetails = useProgramEngagementDetails({
    detailRequirements: [{ year: detailYear, traitId: 'eqipDocuments', dataType: 'workflow' }],
    resourceId: engagementId,
  })
  const eqipDocumentsDetail = eqipDocumentsDetails.data?.[0] as RDEqipDocuments

  const updateDetail = useUpdateProgramEngagementDetails()

  const debugButton = (
    <DebugButton
      loading={updateDetail.isPending}
      disabled={!eqipDocumentsDetail}
      onClick={() => {
        updateDetail.mutateAsync({
          resourceId: engagementId,
          details: [
            {
              traitId: 'eqipDocuments',
              year: detailYear,
            } as RDEqipAutogenRequiredForms,
          ],
        })
      }}
    >
      Reset Docusign
    </DebugButton>
  )

  switch (task.rule.params.docusignType) {
    case 'eqipPrgAssignPaymentContractDoc':
      return (
        <>
          <PaymentConsentDocusign
            engagementId={engagementId}
            taskId={task.id}
            isSigned={!!eqipDocumentsDetail?.result?.eqipPrgAssignPaymentContractDoc?.signed}
          />
          {debugButton}
        </>
      )

    case 'eqipPrgDoc':
      return (
        <>
          <EqipApplicationDocusign
            detailYear={detailYear}
            engagementId={engagementId}
            taskId={task.id}
          />
          {debugButton}
        </>
      )
  }
}
