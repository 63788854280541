import { FieldModel } from '@cibo/core'
import { createContext, useContext } from 'react'
import { QuestionRollup, RollupQuestionProps } from './types'

export interface RollupQuestionState extends RollupQuestionProps {
  detailsPresent: boolean
  detailsMissing: boolean
  unsavedUpdates: number
  rollupRequested?: boolean
  setRollupRequested(status: boolean): void
  formParams?: any
  setFormParams(params: any): void
  rollup?: QuestionRollup
  commitChanges(): Promise<any>
  fieldModels: FieldModel[]
}

export const DEFAULT_VALUES = {
  detailsPresent: false,
  detailsMissing: false,
  unsavedUpdates: 0,
  commitChanges: () => Promise.resolve(),
  rollupRequested: false,
  setRollupRequested: () => null,
  setFormParams: () => null,
  fieldModels: [],
}

export const RollupQuestionContext = createContext<RollupQuestionState>(DEFAULT_VALUES)
RollupQuestionContext.displayName = 'RollupQuestionContext'

export const useRollupQuestion = () => useContext(RollupQuestionContext)
