export const getLocale = () => {
  // @todo: use the browser-setting locale
  return 'en-US'

  if (navigator.languages !== undefined) {
    return navigator.languages[0]
  }

  return navigator.language
}
