import { ResourceDetailInputBase } from '@cibo/core'
import { ObjectSchema, Schema, array, object } from 'yup'
import { ValidationSchemaFunctionArguments } from '../types'

export const eventValidationSchema =
  <L extends ResourceDetailInputBase>(
    eventObjectValidation: (
      passthroughProps: ValidationSchemaFunctionArguments
    ) => Schema<unknown, object>
  ) =>
  (passthroughProps: ValidationSchemaFunctionArguments) =>
    object()
      .shape({
        events: array()
          .of(eventObjectValidation(passthroughProps))
          .test('events', 'required', value => {
            return value !== undefined
          }),
      })
      .required() as unknown as ObjectSchema<L, object>
