import { ResourceDetail } from './ResourceDetail'

export interface RDCoverCropInput {
  crop: CoverCrop
  plantedAt?: Date
  terminatedAt?: Date
  acres?: number
  harvestedGrain?: boolean
  harvestedHay?: boolean
  terminationMethod?: TerminationMethod
}

export interface RDCoverCrop extends ResourceDetail {
  traitId: 'coverCrop'
  year: number
  result: boolean
  input: RDCoverCropInput
}

export const COVER_CROP = [
  'none',
  'alfalfa',
  'amaranth',
  'annualFescue',
  'barley',
  'berseemClover',
  'birdsfootTrefoil',
  'buckwheat',
  'canola',
  'carrots',
  'cerealRye',
  'chickpeas',
  'chicory',
  'corn',
  'cowpea',
  'fieldPea',
  'flaxseed',
  'foxtailMillet',
  'grainSorghum',
  'kale',
  'lentils',
  'lupin',
  'medic',
  'mungbean',
  'mustard',
  'oats',
  'other',
  'pearlMillet',
  'phacelia',
  'proscoMillet',
  'radishes',
  'redClover',
  'ryegrass',
  'safflower',
  'sainfoin',
  'soybeans',
  'spinach',
  'squash',
  'sudanGrass',
  'sugarbeets',
  'sunflower',
  'sweetClover',
  'teff',
  'triticale',
  'turnips',
  'vetch',
  'wheat',
  'whiteClover',
  'winterwheat',
] as const

export type CoverCrop = typeof COVER_CROP[number] | 'true' | 'false'

export const TERMINATION_METHOD = [
  'bale',
  'burning',
  'grazing',
  'harvesting',
  'herbicide',
  'mowing',
  'mulching',
  'noEmergence',
  'other',
  'rollerCrimping',
  'tillage',
  'winterKill',
] as const

export type TerminationMethod = typeof TERMINATION_METHOD[number] | 'true' | 'false'
