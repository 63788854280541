import {
  EQIP_PRACTICES_FLOODING_OPTIONS,
  EqipPracticesFloodingOption,
  RDEqipPracticesFlooding,
} from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../SelectRollupEditor'

export const RDEqipPracticesFloodingResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesFlooding> =
  {
    traitId: 'eqipPracticesFlooding',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesFlooding>) => (
      <SelectRollupEditor<RDEqipPracticesFlooding, readonly EqipPracticesFloodingOption[]>
        options={EQIP_PRACTICES_FLOODING_OPTIONS}
        detailKey="ponding"
        ns="@cibo/landmanager/RDEqipPracticesFloodingDetailFeature"
        {...props}
      />
    ),
  }
