/* istanbul ignore file */
import { getMpNodeAgent } from './utils'

const agent = getMpNodeAgent()

const PATHS = {
  BUNDLE: '/assets/v1/bundle',
  PROXY: '/assets/v1/proxy',
}

export class AssetsAPI {
  static proxy(params: ProxyParams): Promise<any> {
    return agent.get(PATHS.PROXY, { params, responseType: 'blob' })
  }

  static bundle(params: BundleParams): Promise<any> {
    return agent.post(PATHS.BUNDLE, params, { responseType: 'blob' })
  }
}

type ProxyParams = {
  url: string
}

type BundleParams = {
  urls: string[]
  name: string
}
