import { ContentfulApi, TraitId } from '@cibo/core'
import { useQuery } from '@tanstack/react-query'

export const useTraitContent = (traitId?: TraitId) =>
  useQuery({
    queryKey: ['trait-content', traitId],
    queryFn: () =>
      ContentfulApi.find({ content_type: 'trait', 'fields.traitId': traitId }).then(items => {
        return items?.[0]?.fields?.definition
      }),
  })
