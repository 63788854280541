import { ReactNode, forwardRef } from 'react'
import { Stack, Box, Paper, PaperProps, styled } from '@mui/material'

const StyledPaper = styled(Paper)(({ theme }) => ({
  zIndex: 1,
  '&.MuiPaper-root': {
    width: '100%',
    position: 'sticky',
    bottom: 0,
    borderRadius: 0,
    borderTop: `solid 1px ${theme.palette.divider}`,
    [theme.breakpoints.up('xs')]: {
      paddingBlock: theme.spacing(1),
      paddingInline: theme.spacing(2),
    },
    [theme.breakpoints.up('md')]: {
      paddingBlock: theme.spacing(2),
      paddingInline: theme.spacing(3),
    },
  },
  '&.MuiPaper-elevation': {
    boxShadow: '0 0 4px rgba(0,0,0,0.25)',
  },
}))

interface ActionBarProps extends PaperProps {
  children?: ReactNode
  leftContent?: ReactNode
  rightContent?: ReactNode
}

export const ActionBar = forwardRef<HTMLDivElement | null, ActionBarProps>(
  ({ children, leftContent, rightContent, ...paperProps }, ref) => (
    <StyledPaper ref={ref} variant="elevation" {...paperProps}>
      {!!leftContent ||
        (!!rightContent && (
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            {leftContent ? leftContent : <Box sx={{ h: 1, w: 1 }} />}
            {rightContent ? rightContent : <Box sx={{ h: 1, w: 1 }} />}
          </Stack>
        ))}
      {children}
    </StyledPaper>
  )
)
