import { ListItemButton, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const ThemedButtonListItem = styled<OverridableComponent<any>>(ListItemButton)(
  ({ theme }) => ({
    '&.Mui-selected': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
    },
    '&.Mui-selected:hover': {
      backgroundColor: theme.palette.primary.light,
      color: theme.palette.primary.contrastText,
    },
  })
)
