export const GEOPOLITICAL = {
  SOURCE: 'geopolitical',
  STATES: {
    SHAPES: 'state-shapes',
    CENTERS: 'state-centers',
  },
  COUNTIES: {
    SHAPES: 'county-shapes',
    CENTERS: 'county-centers',
  },
  TOWNSHIPS: {
    SHAPES: 'township-shapes',
    CENTERS: 'township-centers',
  },
}

export const BEFORE_ID = 'data-layer-anchor'

export enum MapStyles {
  SATELLITE = 'SATELLITE',
  FOCUSED = 'FOCUSED',
  CDL = 'CDL',
}

export type MapStylesType = MapStyles.SATELLITE | MapStyles.FOCUSED | MapStyles.CDL
