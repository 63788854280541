import { Footprint } from '../types'

export class FootprintModel implements Footprint {
  acreage: number
  co2Diesel: number
  co2FertilizerProduction: number
  co2Soils: number
  n2oSoils: number
  total: number

  constructor(params: Footprint) {
    this.acreage = params.acreage
    this.co2Diesel = params.co2Diesel
    this.co2FertilizerProduction = params.co2FertilizerProduction
    this.co2Soils = params.co2Soils
    this.n2oSoils = params.n2oSoils
    this.total = params.total
  }

  get totalCarbon() {
    const { acreage, total } = this
    return acreage * total
  }
  get totalCo2Diesel() {
    const { acreage, co2Diesel } = this
    return acreage * co2Diesel
  }
  get totalCo2FertilizerProduction() {
    const { acreage, co2FertilizerProduction } = this
    return acreage * co2FertilizerProduction
  }
  get totalCo2Soils() {
    const { acreage, co2Soils } = this
    return acreage * co2Soils
  }
  get totalN2oSoils() {
    const { acreage, n2oSoils } = this
    return acreage * n2oSoils
  }
}
