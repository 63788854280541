import { FieldQueryOptions } from '@cibo/core'
import { uniq } from 'ramda'

const countCrops = (cropFilters?: string[]) => {
  if (!cropFilters) {
    return 0
  }

  const crops = cropFilters.map(filterString => filterString.split(':')[1])
  return uniq(crops).length
}

// High "complexity" but clear what is happening below
// eslint-disable-next-line complexity
export const filterCount = (filters: FieldQueryOptions) => {
  let count = 0

  if (filters.acresGt || filters.acresLt) count++
  if (filters.lastUpdatedLt || filters.lastUpdatedGt) count++
  if (filters.createdLt || filters.createdGt) count++

  count += filters.fips?.length || 0
  count += filters.resourceIds?.length || 0
  count += filters.fdrStatusAnyOf?.length || 0
  count += countCrops(filters.cashCrop)
  count += countCrops(filters.coverCrop)
  count += filters.termsets?.length || 0
  count += filters.labels?.length || 0

  if (filters.tillage) count++
  if (filters.grower) count++
  if (filters.owner) count++

  return count
}
