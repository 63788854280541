import { GeneralResourceDetail } from '../ResourceDetail'

/**
 * !IMPORTANT
 *
 * When updating the list of practices, make sure to ALSO update the Contentful
 * entry associated with this resource detail.
 *
 * https://app.contentful.com/spaces/mqztk29gethb/entries/3NMgPkBj9SojQQZqLJfprV
 *
 */

export const EQIP_PRACTICE_CHOICE_SOIL = [
  '340',
  '512',
  '528',
  '612',
  '590',
  '338',
  '329',
  '345',
  '550',
  '328',
  '595',
  '449',
  '484',
  '327',
  '412',
  '420',
  '342',
  '422',
  '460',
  '610',
  '333',
  '386',
  '390',
  '324',
  '511',
  '393',
  '330',
  '331',
  '586',
  '332',
  '326',
  '336',
  'none',
]
export type EqipPracticeChoiceSoil = typeof EQIP_PRACTICE_CHOICE_SOIL[number]

export type RDEqipPracticeChoiceSoil = GeneralResourceDetail<
  EqipPracticeChoiceSoil[],
  'eqipPracticeChoiceSoil'
>
