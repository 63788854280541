export * from './AppleAuthTypes'
export * from './AuthConfig'
export * from './MpxUser'
export * from './AuthCheck'
export * from './ChangePassword'
export * from './CreateFormToken'
export * from './DirectLogin'
export * from './EnvironmentAccessTypes'
export * from './RegisterVerifiedUser'
export * from './RegisterVisitor'
export * from './UserInfoTypes'

export const MP_AUTH_SESSION_VALID = 'MP_AUTH_SESSION_VALID'
export const MP_AUTH_SESSION_INVALID = 'MP_AUTH_SESSION_INVALID'

interface AuthSessionValidAction {
  type: typeof MP_AUTH_SESSION_VALID
}
interface AuthSessionInvalidAction {
  type: typeof MP_AUTH_SESSION_INVALID
}

export type AuthSessionActionTypes = AuthSessionValidAction | AuthSessionInvalidAction
