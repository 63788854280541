import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const FutureCoverCropCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/ui/cropNames')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <>
      {detail
        ? detail.input
          ? t(detail.input.crop)
          : detail.result === true
          ? t('other')
          : t('none')
        : ''}
    </>
  )
}
