import { PracticeContentDataTypes, ProgramTaskRole } from '@cibo/core'
import { Markdown } from '@cibo/ui'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import {
  Alert,
  AlertTitle,
  Card,
  Grid2 as Grid,
  IconButton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import Button from '@mui/material/Button'
import CardContent from '@mui/material/CardContent'
import Drawer from '@mui/material/Drawer'
import { useTranslation } from 'react-i18next'

type PracticeDrawerProps = {
  resourceConcernList?: string[]
  userRole?: ProgramTaskRole
  ownerName?: string
  isSelected?: boolean
  cardButton?: React.ReactNode
  onClickSelect: (practiceId?: string) => void
  onCloseDrawer: () => void
  open: boolean
  practice?: PracticeContentDataTypes
}

export const PracticeDrawer = ({
  practice,
  isSelected,
  onClickSelect,
  ownerName,
  resourceConcernList,
  userRole,
  open,
  onCloseDrawer,
}: PracticeDrawerProps) => {
  const theme = useTheme()
  const { t } = useTranslation('@cibo/programs/PracticesList')
  const { palette } = useTheme()

  const isLgScreen = useMediaQuery(theme.breakpoints.up('lg'))

  //TODO: determine the actual reimbursement rates based on user detail & state
  const year = practice?.fields?.reimbursementRates[0].year
  const rate = practice?.fields?.reimbursementRates[0].rate
  const isEligible = !practice?.tags?.includes('Ineligible')

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onCloseDrawer}
      PaperProps={{
        style: {
          width: isLgScreen ? '65%' : '100%',
        },
      }}
    >
      <Stack
        role="presentation"
        onClick={onCloseDrawer}
        onKeyDown={onCloseDrawer}
        padding={3}
        direction="column"
      >
        <Grid container spacing={1.5} columns={{ xs: 4, md: 8, lg: 12 }}>
          <Grid size={{ xs: 12 }}>
            <Stack direction="row" alignItems="center" justifyContent="space-between">
              <Stack direction="row" alignItems="center">
                <IconButton
                  onClick={onCloseDrawer}
                  aria-label={t('closeDrawer', {
                    practiceName: practice?.fields.name,
                  })}
                >
                  <KeyboardArrowLeftIcon />
                </IconButton>
                <Typography variant="h6" component="h2">
                  {practice?.fields.name}
                </Typography>
              </Stack>
              {isSelected || !isEligible ? (
                <Button
                  size="small"
                  variant="outlined"
                  sx={{
                    color: palette.text.secondary,
                    borderColor: palette.text.primary,
                  }}
                  startIcon={<RemoveCircleOutlineIcon />}
                  onClick={() => {
                    onClickSelect(practice?.fields?.practiceId)
                  }}
                  disabled={!isEligible}
                >
                  {isSelected ? t('removePractice') : t('addPractice')}
                </Button>
              ) : (
                <Button
                  size="small"
                  variant="outlined"
                  color="secondary"
                  startIcon={<AddCircleOutlineIcon />}
                  onClick={() => {
                    onClickSelect(practice?.fields?.practiceId)
                  }}
                >
                  {t('addPractice')}
                </Button>
              )}
            </Stack>
          </Grid>
          {!isEligible && (
            <Grid size={{ xs: 12 }}>
              <Alert severity="error">
                <AlertTitle>{t('alertTitle')}</AlertTitle>
                {t('alertContent')}
              </Alert>
            </Grid>
          )}

          <Grid size={{ xs: 12 }}>
            <Card variant="outlined">
              <CardContent sx={{ padding: 3 }}>
                <Stack spacing={1.5}>
                  <Typography variant="h6" component="h2">
                    {t('practiceDefinition')}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {practice?.fields?.definition}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {t('practiceDefinitionDisclaimer', {
                      context: userRole,
                      name: ownerName,
                    })}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Card variant="outlined">
              <CardContent sx={{ padding: 3 }}>
                {/* TODO: finish the reimbursement rate part*/}
                <Stack spacing={3}>
                  <Typography variant="h6" component="h2">
                    {t('reimbursementRate', { year })}
                  </Typography>
                  <Stack>
                    <Typography variant="h5" component="h1">
                      {t('reimbursementRatePerYear', { rate })}
                    </Typography>

                    <Typography variant="body2" color="text.primary">
                      {t('reimbursementRateDisclaimer', {
                        context: userRole,
                        name: ownerName,
                      })}
                    </Typography>
                  </Stack>
                  <Markdown
                    typographyProps={{ variant: 'body2' }}
                    //@ts-ignore adding color in typographyProps is not working
                    sx={{
                      '& a': { color: palette.secondary.main },
                    }}
                  >
                    {t('reimbursementRateDescription')}
                  </Markdown>
                </Stack>
              </CardContent>
            </Card>
          </Grid>

          <Grid size={{ xs: 12 }}>
            <Card variant="outlined">
              <CardContent sx={{ padding: 3 }}>
                <Stack spacing={1.5}>
                  <Typography variant="h6" component="h2">
                    {t('practiceBenefits')}
                  </Typography>

                  {practice?.fields?.commitmentDetails && (
                    <>
                      <Typography variant="body2">{t('commitmentDetails')}</Typography>
                      <Markdown color="text.secondary" typographyProps={{ variant: 'body2' }}>
                        {practice?.fields?.commitmentDetails}
                      </Markdown>
                    </>
                  )}

                  <Typography variant="body1">{t('positiveEffects')}</Typography>
                  <Markdown
                    //@ts-ignore adding color in typographyProps is not working
                    sx={{
                      color: palette.text.secondary,
                    }}
                    typographyProps={{
                      variant: 'body2',
                    }}
                  >
                    {practice?.fields?.positiveEffects}
                  </Markdown>

                  <Typography variant="body1">{t('negativeEffects')}</Typography>
                  <Markdown
                    //@ts-ignore adding color in typographyProps is not working
                    sx={{
                      color: palette.text.secondary,
                    }}
                    typographyProps={{ variant: 'body2' }}
                  >
                    {practice?.fields?.negativeEffects}
                  </Markdown>

                  {resourceConcernList && (
                    <>
                      <Typography variant="body1">{t('majorConcernAddressed')}</Typography>
                      <Markdown
                        //@ts-ignore adding color in typographyProps is not working
                        sx={{
                          color: palette.text.secondary,
                        }}
                        typographyProps={{ variant: 'body2' }}
                      >
                        {resourceConcernList
                          .map(resourceConcern => `- ${resourceConcern}`)
                          .join('\n')}
                      </Markdown>
                    </>
                  )}
                  <Typography variant="body2" color="text.secondary">
                    {t('practiceBenefitsDisclaimer', {
                      context: userRole,
                      name: ownerName,
                    })}
                  </Typography>
                </Stack>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Stack>
    </Drawer>
  )
}
