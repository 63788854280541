import { ProgramConfig, ProgramStat, ProgramStatsResponse } from '@cibo/core'
import { groupBy } from 'ramda'

export type LimitType = 'limited' | 'noSpace' | 'overCommit' | 'none'
export type LimitContext = 'program' | 'grower' | 'org'

interface FullLimit {
  limitTypeName: string
  limitType: LimitType
  totalResourceCountForLimit: number
  limitValue: number
  unit: 'acres' | 'fields'
  display: boolean
  workflowResourceCountForUnit: number
  currentGrowerCommitted: number
  committed: number
  displayAtPercent: number
  context: LimitContext
  enrollmentWouldOvercommitLimit: boolean
}

export type LimitDefinition = { display: false } | FullLimit

interface LimitTypeInput {
  stat: ProgramStat
  program: ProgramConfig
  orgId?: string
}
interface LimitTypeOutput
  extends Pick<FullLimit, 'context' | 'unit' | 'displayAtPercent'>,
    Partial<Pick<FullLimit, 'limitValue'>> {}

const limitTypes: Record<string, ({ stat, program, orgId }: LimitTypeInput) => LimitTypeOutput> = {
  programAcres: ({ stat }) => ({
    limitValue: stat?.globalLimit.max.acres,
    context: 'program',
    unit: 'acres',
    displayAtPercent: 0.95,
  }),
  programFields: ({ stat }) => ({
    limitValue: stat?.globalLimit.max.fields,
    context: 'program',
    unit: 'fields',
    displayAtPercent: 0.95,
  }),
  orgAcres: ({ program, orgId }) => {
    const orgCaps = !orgId
      ? Object.values(program.ledger.capsPerOrg)[0]
      : program?.ledger.capsPerOrg[parseInt(orgId)]
    return (
      orgCaps && {
        limitValue: orgCaps.maxAcres,
        context: 'org',
        unit: 'acres',
        displayAtPercent: 0.85,
      }
    )
  },
  orgFields: ({ program, orgId }) => {
    const orgCaps = !orgId
      ? Object.values(program.ledger.capsPerOrg)[0]
      : program?.ledger.capsPerOrg[parseInt(orgId)]
    return (
      orgCaps && {
        limitValue: orgCaps.maxFields,
        context: 'org',
        unit: 'fields',
        displayAtPercent: 0.85,
      }
    )
  },
  growerAcres: ({ program }) => ({
    limitValue: program?.ledger.maxAcresPerGrower,
    context: 'grower',
    unit: 'acres',
    displayAtPercent: 0.85,
  }),
  growerFields: ({ program }) => ({
    limitValue: program?.ledger.maxFieldsPerGrower,
    context: 'grower',
    unit: 'fields',
    displayAtPercent: 0.85,
  }),
}

export const evaluateLimitsByPriority = ({
  orgId,
  program,
  programId,
  stats,
  workflowAcreageTotal,
  workflowFieldcountTotal,
}: {
  orgId?: string
  program?: ProgramConfig
  programId: string
  stats?: ProgramStatsResponse
  workflowAcreageTotal: number
  workflowFieldcountTotal: number
}) => {
  const stat = stats?.programs?.find(program => program.programId === programId)

  const workflowResourceCount = {
    fields: workflowFieldcountTotal,
    acres: workflowAcreageTotal,
  }

  if (!stat || !program) {
    return undefined
  }

  const availableLimits: FullLimit[] = Object.keys(limitTypes)
    .map(limitTypeName => {
      const limit = limitTypes[limitTypeName]({
        stat,
        program,
        orgId,
      })

      if (!limit || !limit.limitValue) {
        return undefined
      }
      const { limitValue, unit, displayAtPercent, context } = limit

      const workflowResourceCountForUnit = workflowResourceCount[unit]
      const currentGrowerCommitted = stat?.committed[unit] || 0
      const globalCommitted = stat?.globalLimit.committed[unit]
      const orgCommitted = stat?.committed[unit]
      const committed =
        context === 'org'
          ? orgCommitted
          : context === 'grower'
          ? currentGrowerCommitted
          : globalCommitted
      const totalResourceCountForLimit = workflowResourceCountForUnit + committed
      const enrollmentWouldOvercommitLimit = limitValue < totalResourceCountForLimit
      const limitType = findLimitType({
        limitValue,
        committed,
        workflowResourceCountForUnit,
        displayAtPercent,
        enrollmentWouldOvercommitLimit,
        context,
      }) as LimitType

      return {
        limitTypeName,
        workflowResourceCountForUnit,
        currentGrowerCommitted,
        totalResourceCountForLimit,
        committed,
        unit,
        displayAtPercent,
        context,
        limitValue,
        enrollmentWouldOvercommitLimit,
        limitType,
        display: limitType !== 'none',
      }
    })
    .filter(x => x !== undefined && x.limitType !== 'none') as FullLimit[]

  if (availableLimits.length === 0 || availableLimits === undefined) {
    return [{ display: false }] as [LimitDefinition]
  } else if (availableLimits.length === 1) {
    // for debugging in prod https://cibotech.atlassian.net/browse/CPD-5689
    console.log({ limits: 'single', availableLimits, workflowResourceCount, stat, program, orgId })
    return availableLimits as [LimitDefinition]
  } else {
    const { acres, fields } = groupBy((a: FullLimit) => a.unit)(availableLimits)
    if (!!acres && !!fields) {
      const acreLimit = findMostUrgentLimit(acres) as FullLimit
      const fieldLimit = findMostUrgentLimit(fields) as FullLimit
      const priorityLimit = findMostUrgentLimit([acreLimit, fieldLimit])
      // for debugging in prod https://cibotech.atlassian.net/browse/CPD-5689
      console.log({
        limits: 'multiple - acres & fields',
        availableLimits,
        workflowResourceCount,
        stat,
        program,
        orgId,
        acreLimit,
        fieldLimit,
        priorityLimit,
      })
      return [priorityLimit, priorityLimit === fieldLimit ? acreLimit : fieldLimit] as [
        LimitDefinition,
        LimitDefinition
      ]
    }
    if (!!acres) {
      // for debugging in prod https://cibotech.atlassian.net/browse/CPD-5689
      console.log({
        limits: 'multiple - acres',
        availableLimits,
        workflowResourceCount,
        stat,
        program,
        orgId,
        mostUrgent: findMostUrgentLimit(acres),
      })
      return [findMostUrgentLimit(acres)] as [LimitDefinition]
    }
    if (!!fields) {
      // for debugging in prod https://cibotech.atlassian.net/browse/CPD-5689
      console.log({
        limits: 'multiple - fields',
        availableLimits,
        workflowResourceCount,
        stat,
        program,
        orgId,
        mostUrgent: findMostUrgentLimit(fields),
      })
      return [findMostUrgentLimit(fields)] as [LimitDefinition]
    }
  }
}

export const findMostUrgentLimit = (limits: FullLimit[]) => {
  const { noSpace, limited, overCommit } = groupBy((a: FullLimit) => a.limitType)(limits)
  // noSpace - largest available number
  if (noSpace?.length > 0) {
    return noSpace.length === 1
      ? (noSpace[0] as LimitDefinition)
      : (noSpace.sort((b, a) => a.limitValue - b.limitValue)[0] as LimitDefinition)
  }
  // overCommit - smallest available number
  if (overCommit?.length > 0) {
    return overCommit.length === 1
      ? (overCommit[0] as LimitDefinition)
      : (overCommit.sort((b, a) => b.limitValue - a.limitValue)[0] as LimitDefinition)
  }
  // limited - smallest available number
  if (limited?.length > 0) {
    return limited.length === 1
      ? (limited[0] as LimitDefinition)
      : (limited.sort(
          (b, a) =>
            b.limitValue -
            b.totalResourceCountForLimit -
            (a.limitValue - a.totalResourceCountForLimit)
        )[0] as LimitDefinition)
  }
  return { display: false } as LimitDefinition
}

export const findLimitType = ({
  limitValue,
  committed,
  workflowResourceCountForUnit,
  displayAtPercent,
  enrollmentWouldOvercommitLimit,
  context,
}: {
  limitValue: number
  committed?: number
  workflowResourceCountForUnit: number
  displayAtPercent: number
  enrollmentWouldOvercommitLimit?: boolean
  context: LimitContext
}) => {
  let limitType = 'none'
  if (
    limitValue &&
    committed &&
    !((committed + workflowResourceCountForUnit) / limitValue < displayAtPercent) &&
    context !== 'grower'
  ) {
    limitType = 'limited'
  }
  if (limitValue && committed && limitValue <= committed) {
    limitType = 'noSpace'
  } else if (enrollmentWouldOvercommitLimit) {
    limitType = 'overCommit'
  }
  return limitType
}
