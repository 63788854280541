import { RegenerativeTillage } from '@cibo/core'
import { Chip } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SourceIcon } from '../SourceIcon'
import { NotObservedChip } from './NotObservedChip'
import { CommonChipProps } from './types'

const typeToColor = (type?: TillageChipProps['type']) => {
  switch (type) {
    case 'conservationTillage':
      return 'info'
    case 'conventionalTillage':
      return 'error'
    case 'noTill':
      return 'success'
    default:
      return undefined
  }
}

interface TillageChipProps extends Omit<CommonChipProps, 'crop'> {
  type?: RegenerativeTillage | boolean
}

export const TillageChip = ({ size = 'small', source, type, ...chipProps }: TillageChipProps) => {
  const { t } = useTranslation('@cibo/ui/TillageChip')

  if (!type) {
    return <NotObservedChip />
  }

  return (
    <Chip
      size={size}
      label={t(`tillage_${type}`)}
      variant={!!type ? 'filled' : 'outlined'}
      {...chipProps}
      color={typeToColor(type)}
      icon={<SourceIcon source={source} sx={{ opacity: 0.8 }} />}
    />
  )
}
