/* istanbul ignore file */
import { FeatureCollection, MultiPolygon } from 'geojson'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  GEOMETRY: '/mpx/getGeometry',
  GEOMETRIES: '/mpx/getGeometries',
}

interface GeometryRequestResult {
  data: {
    geometry: MultiPolygon
  }
}
interface GeometriesRequestResult {
  data: {
    featureCollection: FeatureCollection<MultiPolygon>
  }
}

export class GeometryApi {
  static getGeometry(geometrySlug: string): Promise<MultiPolygon> {
    return (
      agent
        .get(`${PATHS.GEOMETRY}?geometryId=${geometrySlug}`)
        //@ts-ignore
        .then((result: GeometryRequestResult) => result.data.geometry)
    )
  }

  static getGeometries(geometryIds: string[]): Promise<FeatureCollection<MultiPolygon>> {
    return (
      agent
        .get(`${PATHS.GEOMETRIES}?geometryIds=${geometryIds.join(',')}`)
        //@ts-ignore
        .then((response: GeometriesRequestResult) => response.data.featureCollection)
    )
  }
}
