import Ajv from 'ajv'

const ajv = new Ajv({ allErrors: true })

export const jsonSchemaFormikValidator = (schema: object) => {
  const validate = ajv.compile(schema)

  return (values: any) => {
    const errors: Record<string, string> = {}

    const valid = validate(values)

    validate.errors?.forEach(report => {
      if (report.params.missingProperty) {
        errors[report.params.missingProperty] = 'required'
      }

      switch (report.keyword) {
        case 'required':
          break

        case 'minimum':
        case 'maximum':
          errors[report.instancePath?.replace(/^\//, '')] = `${report.message}`

          break

        default:
          if (report.schemaPath) {
            errors[report.schemaPath] = `${report.message}`
          }
      }
    })

    return { valid, errors }
  };
}
