import { Benchmark, DetailRequirement, FieldModel, PrgDetail, ProgramTask } from '@cibo/core'
import {
  RemoveDetailRequest,
  SaveDetailRequest,
  TraitFeature,
  useDetailEditing,
} from '@cibo/landmanager'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementContext } from '../../context'
import { DefaultCell } from './DefaultCell'
import { DetailCell } from './DetailCell'
import { InfoNeededCorner } from './InfoNeededCorner'

type RequirementColumnsProps = {
  requirements: DetailRequirement[]
  programIds?: string[]
  benchmark?: Benchmark
  filterInputs?: PrgDetail
  task?: ProgramTask
}

export const useRequirementColumns = ({
  requirements,
  programIds,
  benchmark,
  filterInputs,
  task,
}: RequirementColumnsProps) => {
  const { t: traitT } = useTranslation('traits')
  const { t: tableT } = useTranslation('@cibo/programs/TraitTable')
  const detailEditing = useDetailEditing()
  const { enqueueSnackbar } = useSnackbar()

  const { engagement } = useProgramEngagementContext()

  const handleSaveDetail = (request: SaveDetailRequest, onSuccess?: (value?: any) => any) =>
    detailEditing
      .saveDetail(request)
      .then(onSuccess)
      .catch(error => {
        enqueueSnackbar(`${tableT('saveDetailError')}`, {
          variant: 'error',
        })
      })

  const handleRemoveDetail = (request: RemoveDetailRequest, onSuccess?: (value?: any) => any) =>
    detailEditing
      .removeDetail(request)
      .then(onSuccess)
      .catch(error => {
        enqueueSnackbar(`${tableT('removeDetailError')}`, {
          variant: 'error',
        })
      })

  const columns: GridColDef[] = []

  requirements.forEach(requirement => {
    const Trait = TraitFeature.forDetailRequirement(requirement)

    const CellEditor = Trait.cellEditor
    const CellDisplay = Trait.cellDisplay || DefaultCell

    const { traitId, year, resultsOnly } = requirement

    columns.push({
      field: `${traitId}_${resultsOnly ? 'simple' : 'full'}${year ? `_${year}` : ''}`,
      headerName: year ? `${year}` : traitT(traitId),
      sortable: false,
      filterable: false,
      hideable: false,
      flex: 1,
      minWidth: 160,
      disableReorder: true,
      renderCell: (params: GridRenderCellParams<FieldModel, ReactNode>) => {
        const detail = params.row.resolveStandingDetail(traitId, year)

        const infoNeededV1 =
          !!programIds &&
          !!benchmark &&
          params.row.isDetailAbsentOrIncomplete(programIds, benchmark, traitId, year)

        const infoNeededV2 =
          task && engagement.data?.taskIsMissingFieldInfo(task.id, params.row.id, requirement)

        return (
          <>
            <DetailCell
              detail={detail}
              requirement={requirement}
              rowParams={params}
              removeDetail={handleRemoveDetail}
              saveDetail={handleSaveDetail}
              //@ts-ignore TODO: fix this CPD-3100
              cellEditor={CellEditor}
              //@ts-ignore TODO: fix this CPD-3100
              cellDisplay={CellDisplay}
              supressCellEditUI={!!Trait.forbidCellEdit}
              filterInputs={filterInputs}
            />

            {(infoNeededV1 || infoNeededV2) && <InfoNeededCorner />}
          </>
        )
      },
    })
  })

  return columns
}
