import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationContent } from '../../queries'

interface useWindowTitleOptions {
  format?: boolean
}

export const useWindowTitle = (
  title?: string,
  { format }: useWindowTitleOptions = { format: true }
) => {
  const { t } = useTranslation('cibo/profile/useWindowTitle')
  const orgContent = useOrganizationContent()
  useEffect(() => {
    const orgName = orgContent.data?.fields.name ? orgContent.data?.fields.name : '...'
    if (!!title) {
      window.document.title = format ? t('title', { title, orgName }) : title
    } else {
      window.document.title = orgName
    }

    // restore orgName as title on dismount in case no other title is provided by destination page
    return () => {
      window.document.title = orgName
    }
  }, [title, format, orgContent.isFetched])
  return window.document.title
}
