import { RegenerativeTillage, TILLAGE_TYPE } from '@cibo/core'
import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Slider,
  Stack,
  TextField,
} from '@mui/material'
import { useField } from 'formik'
import { uniq } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { minMaxYears } from './utils'

const currentYear = new Date().getFullYear()
const TILLAGE_YEARS = new Array(5).fill(0).map((v, i) => currentYear - i)

export const TillageFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/TillageFilter')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, { value: tillageValues = [] }, { setValue }] = useField('tillage')

  const [tillage, setTillage] = useState<string[]>(
    uniq(tillageValues.map((c: string) => c.split(':')[1])) as RegenerativeTillage[]
  )
  const [years, setYears] = useState<number[]>(
    tillageValues.length > 0
      ? minMaxYears(tillageValues.map((c: string) => parseInt(c.split(':')[0])).sort())
      : [TILLAGE_YEARS[TILLAGE_YEARS.length - 1], TILLAGE_YEARS[0]]
  )

  const handleChangeYear = (event: any, newYears: number | number[], activeThumb: number) => {
    if (Array.isArray(newYears)) {
      setYears(newYears)
    }
  }

  const handleChangeTillage = (event: any, newTillage: string[], reason: any) => {
    setTillage(newTillage)
  }

  /**
   * update the formik field when we have a valid input - which means
   * at least one tillage practice and a range of years
   */
  useEffect(() => {
    if (tillage.length > 0 && years.length > 0) {
      const newTillage: string[] = []
      for (let year = years[0]; year <= years[1]; year++) {
        tillage.forEach(crop => newTillage.push(`${year}:${crop}`))
      }
      setValue(newTillage)
    } else {
      setValue(undefined)
    }
  }, [tillage.join(), years.join()])

  return (
    <FormControl>
      <FormLabel>{t('tillage')}</FormLabel>
      <Stack>
        <Autocomplete
          multiple
          limitTags={2}
          options={TILLAGE_TYPE}
          getOptionLabel={tillage => t(`tillage_${tillage}`)}
          renderInput={params => (
            <TextField {...params} placeholder={tillage.length === 0 ? t('any') : ''} />
          )}
          onChange={handleChangeTillage}
          size="small"
          //@ts-ignore - incorrectly inferring the type from TILLAGE_TYPE as string[]
          value={tillage}
          fullWidth
        />
        <Collapse in={tillage.length > 0}>
          <Box sx={{ px: 2 }}>
            <Slider
              min={TILLAGE_YEARS[TILLAGE_YEARS.length - 1]}
              max={TILLAGE_YEARS[0]}
              value={years}
              onChange={handleChangeYear}
              valueLabelDisplay="auto"
              disabled={tillage.length === 0}
              marks={[
                {
                  value: TILLAGE_YEARS[TILLAGE_YEARS.length - 1],
                  label: TILLAGE_YEARS[TILLAGE_YEARS.length - 1],
                },
                { value: TILLAGE_YEARS[0], label: TILLAGE_YEARS[0] },
              ]}
              size="small"
            />
          </Box>
        </Collapse>
      </Stack>
    </FormControl>
  )
}
