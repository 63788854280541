/* istanbul ignore file */
import { stringify } from 'query-string'

import { MpxSearchQueryModel } from '../models/MpxSearchQuery'
import {
  MpxSearchQuery,
  MpxSearchResult,
  MpxQueryTrigger,
  MpxSearchSimilarQuery,
} from '../types/MpxSearchTypes'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  SEARCH: '/mpx/search/fullTextNonCompact',
  MORE_LIKE_THIS: '/mpx/search/moreLikeThis',
}

export class MpxSearch {
  static async query(query: MpxSearchQuery, trigger?: MpxQueryTrigger) {
    const queryModel = new MpxSearchQueryModel(query)
    const serverQuery = queryModel.toServerQuery()

    serverQuery.trigger = trigger
    serverQuery.showPoints = true

    return agent.get<MpxSearchResult>(`${PATHS.SEARCH}?${stringify(serverQuery)}`).then(r => r.data)
  }

  static async similarParcels(query: MpxSearchSimilarQuery) {
    const { parcelPkId, limitTo50km, comparisonFields } = query
    let comparison
    if (comparisonFields) {
      comparison = comparisonFields
        .map(({ field, weight, scale }) => `${field}:${weight}:${scale}`)
        .join(',')
    }
    return agent.get<MpxSearchResult>(
      `${PATHS.MORE_LIKE_THIS}?${stringify({
        parcelPkId,
        limitTo50km,
        comparisonFields: comparison,
      })}`
    )
  }
}
