import { RDEqipEligibilityLandControl, TraitId } from '@cibo/core'
import { path } from 'ramda'
import { boolean, object } from 'yup'
import { ITraitFeatureByIdYear } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { LandControlCTA } from './LandControlCTA'
import { LandControlCell } from './LandControlCell'
import { LandControlCellEditor } from './LandControlCellEditor'
import { LandControlEditor } from './LandControlEditor'

const TRAIT_ID: TraitId = 'eqipEligibilityLandControl'

export const LandControl: ITraitFeatureByIdYear<RDEqipEligibilityLandControl> = {
  traitId: TRAIT_ID,
  editor: LandControlEditor,
  cellEditor: LandControlCellEditor,
  cellDisplay: LandControlCell,

  tableCta: LandControlCTA,
  rollups: {
    row: {
      traitId: TRAIT_ID,
      traitValue: path(['result', 'landOwnershipEligible']),
      detailBaseValue: (value: boolean) => ({
        input: {
          landOwnershipEligible: value,
        },
      }),
    },
  },
  ns: '@cibo/landmanager/LandControl',
  validationSchema: ({ t }) =>
    object()
      .shape({
        landOwnershipEligible: boolean().required(t('required')),
      })
      .required(),
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
}
