import {
  EQIP_IRRIGATION_QUANTITY_OPTIONS,
  EqipPracticesIrrigationQuantity,
  RDEqipPracticesIrrigationQuantity,
} from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../SelectRollupEditor'

export const RDEqipPracticesIrrigationQuantityResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesIrrigationQuantity> =
  {
    traitId: 'eqipPracticesIrrigationQuantity',
    TaskEditor: (
      props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesIrrigationQuantity>
    ) => (
      <SelectRollupEditor<
        RDEqipPracticesIrrigationQuantity,
        readonly EqipPracticesIrrigationQuantity[]
      >
        options={EQIP_IRRIGATION_QUANTITY_OPTIONS}
        detailKey="irrigationQuantity"
        ns="@cibo/landmanager/RDEqipPracticesIrrigationQuantityResourceDetailFeature"
        {...props}
      />
    ),
  }
