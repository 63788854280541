import {
  EQIP_PRACTICE_CHOICE_ENGINERING_WATER_CONTROL_STRUCTURES,
  RDEqipPracticeChoiceEngineeringWaterControlStructures,
} from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { WorkflowDetailChoiceSelection } from '../../../../components/WorkflowDetailChoiceSelection'

export const RDEqipPracticeChoiceEngineeringWaterControlStructuresResourceDetailFeature: ResourceDetailFeature<RDEqipPracticeChoiceEngineeringWaterControlStructures> =
  {
    traitId: 'eqipPracticeChoiceEngineeringWaterControlStructures',
    TaskEditor: props => (
      <WorkflowDetailChoiceSelection
        {...props}
        choices={EQIP_PRACTICE_CHOICE_ENGINERING_WATER_CONTROL_STRUCTURES}
      />
    ),
  }
