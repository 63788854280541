import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Stack, Button, Paper } from '@mui/material'

import { MpxSearchQuery } from '@cibo/core'
import { mpxSearchInitialState } from './mpxSearchInitialState'

import FilterSection, { FILTER_TYPES } from './FilterSection'
import { SearchBarPopper } from '../SearchBarPopper'
import UpdateSearch from './UpdateSearch'

interface SearchFiltersProps {
  onCommit(event: MpxSearchQuery): void
  query?: MpxSearchQuery
  searchResultCount?: number
  open: boolean
  anchorEl: any
}

// Use initial search conditions and take out bounds
export const DEFAULT_QUERY: any = {
  ...mpxSearchInitialState.query,
  lowerLeft: undefined,
  upperRight: undefined,
}

const RANGE_FILTERS = [FILTER_TYPES.NCCPI, FILTER_TYPES.REGEN_POTENTIAL]

const FILTERS = [...RANGE_FILTERS, FILTER_TYPES.HISTORY, FILTER_TYPES.TYPE]

const SearchFilters = ({
  open,
  anchorEl,
  onCommit,
  query = DEFAULT_QUERY,
  searchResultCount,
}: SearchFiltersProps) => {
  const { t } = useTranslation('searchFilters')
  const [localQuery, setLocalQuery] = useState(query)

  useEffect(() => {
    setLocalQuery({
      ...localQuery,
      ...query,
    })
  }, [query])

  const handleUpdate = (newQuery: MpxSearchQuery) => {
    setLocalQuery(newQuery)
  }

  const clearFilters = () => {
    const newQuery = { ...query }
    FILTERS.forEach(filter => {
      if (RANGE_FILTERS.includes(filter)) {
        newQuery[`${filter}Ge`] = DEFAULT_QUERY[`${filter}Ge`]
        newQuery[`${filter}Lt`] = DEFAULT_QUERY[`${filter}Lt`]
      } else {
        newQuery[filter] = DEFAULT_QUERY[filter]
      }
    })
    setLocalQuery(newQuery)
  }

  return (
    <SearchBarPopper open={open} anchorEl={anchorEl} placement="bottom">
      <Paper sx={{ padding: 2 }}>
        <Stack spacing={2}>
          <FilterSection query={localQuery} onUpdate={handleUpdate} />
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Button onClick={clearFilters} variant="outlined">
              {t('reset')}
            </Button>
            <UpdateSearch
              changed={JSON.stringify(query) !== JSON.stringify(localQuery)}
              onUpdate={() => {
                onCommit && onCommit(localQuery)
              }}
              query={localQuery}
              searchResultCount={searchResultCount}
            />
          </Stack>
        </Stack>
      </Paper>
    </SearchBarPopper>
  )
}

export default SearchFilters
