import { intuitiveFromNow } from '@cibo/ui'
import { FieldColumnProps } from './types'

export const fieldUpdatedAtColumn = ({ t, ...columnProps }: FieldColumnProps) => ({
  field: 'updatedAt',
  headerName: t('updatedAt', { ns: '@cibo/landmanager/FieldColumns' }),
  minWidth: 160,
  flex: 2,
  valueFormatter: (params: any) => intuitiveFromNow(params.value),
  ...columnProps,
})
