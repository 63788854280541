import { RDEqipPracticesNaturalMoisture } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesNaturalMoistureResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesNaturalMoisture> = {
  traitId: 'eqipPracticesNaturalMoisture',
  TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesNaturalMoisture>) => (
    <BooleanRollup<RDEqipPracticesNaturalMoisture>
      answerValues={{
        yes: { value: 'yes' },
        no: { value: 'no' },
        unknown: { value: 'unknown' },
      }}
      detailKey="naturalMoisture"
      ns="@cibo/landmanager/RDEqipPracticesNaturalMoistureResourceDetailFeature"
      {...props}
    />
  ),
}
