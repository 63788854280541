import { OrgUserModel, OrgUserSearchResult, UserModel } from '@cibo/core'
import { Box, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const UserResultSelectionDisplay = ({
  option,
  ...rest
}: { option: OrgUserSearchResult } & any) => (
  <UserSelectionDisplay option={new OrgUserModel(option)} {...rest} />
)

export const UserSelectionDisplay = ({
  option,
  ...rest
}: { option: UserModel | OrgUserModel } & any) => {
  const { t } = useTranslation('@cibo/profile/PersonaCards')
  const headline = option.displayNameFull
  const subhead = option.displayNameFull === option.email ? undefined : option.email

  return (
    <Box component="li" {...rest} data-testid={rest['data-testid'] || 'user-selection-display'}>
      <Stack sx={{ width: '100%' }}>
        <Typography variant="body2">
          <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
            <Typography variant="body1">{headline}</Typography>
            <Typography variant="caption">
              {t('persona', { context: option.user.persona })}
            </Typography>
          </Stack>
          {!!subhead && <Typography variant="caption">{subhead}</Typography>}
        </Typography>
      </Stack>
    </Box>
  )
}
