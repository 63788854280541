import { ResourceDetail } from '@cibo/core'
import { ExtendedTheme } from '@cibo/ui'
import { Box, Stack, Typography, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const CiboSensedLegend = ({ traitDetails }: { traitDetails: ResourceDetail[] }) => {
  const showCiboSensedLegend = traitDetails.some(detail => detail.source === 'cibo')
  const { t } = useTranslation('privatelabel')
  const theme = useTheme<ExtendedTheme>()

  return !!showCiboSensedLegend ? (
    <Stack direction="row" alignItems="center">
      <Box style={{ width: 16, height: 16, backgroundColor: theme.palette.ciboSensed }} />
      <Typography variant="caption" paddingLeft={0.5}>
        {t('ciboSensed')}
      </Typography>
    </Stack>
  ) : null
}
