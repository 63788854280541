import { TermsPolicyDataUseEntry } from '@cibo/core'
import { TermsAgreement, TermsUserData } from '../../types'

export const generateTermsState = ({
  currentState = {},
  policies,
  status,
}: {
  currentState?: TermsUserData
  policies: TermsPolicyDataUseEntry[]
  status: TermsAgreement['status']
}) => {
  const termsState = { ...currentState }
  const date = new Date().toISOString()
  policies.forEach(({ sys: { id }, fields: { version } }) => {
    termsState[id] = {
      currentAgreement: {
        status,
        date,
        version,
      },
      pastAgreements: [
        // only keep one version
        termsState[id]?.currentAgreement,
        ...(termsState[id]?.pastAgreements?.filter(
          agreement =>
            (agreement as TermsAgreement).version !== termsState[id]?.currentAgreement?.version
        ) ?? []),
      ].filter(Boolean) as TermsAgreement[],
    }
  })
  return termsState
}

export const pendingPolicies = ({
  content,
  history,
}: {
  content: TermsPolicyDataUseEntry[]
  history: TermsUserData
}) =>
  content.filter(
    agreement =>
      !history?.[agreement.sys.id] ||
      history[agreement.sys.id]?.currentAgreement?.status !== 'accepted' ||
      history[agreement.sys.id]?.currentAgreement?.version !== agreement.fields.version
  )
