import { LandProfileDisplay } from '@cibo/ui'
import { FieldColumnProps } from './types'

interface ColumnProps extends FieldColumnProps {
  acres?: (acres: number) => string
}

export const fieldAcresColumn = ({ t, acres, ...overrides }: ColumnProps) => ({
  field: 'acres',
  headerName: t('acres', { ns: '@cibo/landmanager' }),
  type: 'number',
  minWidth: 80,
  flex: 1,
  valueFormatter: (params: any) =>
    acres ? acres(params.value) : LandProfileDisplay.acreage(params.value),
  ...overrides,
})
