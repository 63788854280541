import { UserWsMessage, UserWsMessageSubject } from '@cibo/core'
import { PropsWithChildren, createContext, useEffect, useState } from 'react'
import { useAuth } from '../useAuth'

type MessagesBySubject = Partial<Record<UserWsMessageSubject, UserWsMessage>>

export type UserWebSocketContextValue = {
  connected: boolean
  error?: Error
  lastMessage?: UserWsMessage
  messagesBySubject: MessagesBySubject
}

export const UserWebSocketContext = createContext<UserWebSocketContextValue>({
  connected: false,
  messagesBySubject: {},
})
UserWebSocketContext.displayName = 'UserWebSocketContext'

interface UserWebSocketProviderProps {
  baseUrl: string
  reconnectAttempts?: number
  disable?: boolean
}

export const UserWebSocketProvider = ({
  baseUrl,
  reconnectAttempts = 10,
  children,
  disable,
}: PropsWithChildren<UserWebSocketProviderProps>) => {
  const [lastMessage, setLastMessage] = useState<UserWsMessage>()
  const [messagesBySubject, setMessagesBySubject] = useState<MessagesBySubject>({})
  const [error, setError] = useState<any>()
  const [localReconnectAttempts, setLocalReconnectAttempts] = useState(reconnectAttempts)
  const [websocket, setWebsocket] = useState<WebSocket>()
  const { isLoggedIn } = useAuth()

  const cleanup = () => {
    /**
     * close an open websocket when cleaning up
     */
    websocket?.readyState === WebSocket.OPEN && websocket.close()
    if (websocket) {
      websocket.onclose = null
      websocket.onerror = null
      websocket.onmessage = null
      websocket.onopen = null
    }
  }

  useEffect(() => {
    if (!isLoggedIn) {
      cleanup()
      return
    }

    if (disable) {
      return
    }

    if (!websocket || websocket.readyState === WebSocket.CLOSED) {
      const socket = new WebSocket(`${baseUrl}ws/user`)
      /**
       * when reopening a socket, ask for some recent history
       */
      socket.onopen = () => {
        socket.send(JSON.stringify({ replay: true }))
        // reset reconnection tries
        setLocalReconnectAttempts(reconnectAttempts)
      }

      socket.onerror = error => setError(error)

      socket.onmessage = ({ data }) => {
        try {
          const pData = JSON.parse(data)
          setLastMessage(pData)
          setMessagesBySubject({
            ...messagesBySubject,
            [pData.subject]: pData,
          })
          setError(undefined)
        } catch (error) {
          console.log('socket message error', error)
        }
      }

      setWebsocket(socket)
    }

    return () => {
      cleanup()
    }
  }, [websocket, isLoggedIn])

  useEffect(() => {
    if (websocket && localReconnectAttempts > 0) {
      /**
       * when the socket closes, decrement the retry counter to ensure we don't
       * run away trying to reconnect
       */
      websocket.onclose = () => {
        setLocalReconnectAttempts(localReconnectAttempts - 1)
        setWebsocket(undefined)
      }
    }
  }, [localReconnectAttempts, websocket])

  return (
    <UserWebSocketContext.Provider
      value={{
        connected: websocket?.readyState === WebSocket.OPEN,
        error,
        lastMessage,
        messagesBySubject,
      }}
    >
      {children}
    </UserWebSocketContext.Provider>
  )
}
