import { useContext, useMemo } from 'react'
import { UserWebSocketContext } from './UserWebSocketContext'
import { UserWsMessageSubject } from '@cibo/core'

export const useUserWebSocket = (subject: UserWsMessageSubject) => {
  const context = useContext(UserWebSocketContext)
  const lastMessage = context.messagesBySubject[subject]

  return useMemo(() => {
    return { lastMessage }
  }, [lastMessage?.messageId])
}
