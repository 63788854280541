import { LIVESTOCK_SPECIES, RDLivestock } from '@cibo/core'
import { NumberInputField, RadioBooleanField, SelectField } from '@cibo/ui'
import { Collapse, FormControl, FormLabel, Stack, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'

import { DetailEditorPropsByYear } from '../types'

export const LivestockEditor = ({ year, name = 'livestock' }: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/LivestockEditor')

  return (
    <Field name={name} id={name}>
      {(props: FieldProps<RDLivestock['input'] & { livestockPresent: 'true' | 'false' }>) => {
        const {
          field: { value },
        } = props

        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { year })}</Typography>
            <FormControl>
              <FormLabel htmlFor={`${name}.livestockPresent`} required>
                {t('didYouGraze')}
              </FormLabel>
              <RadioBooleanField name={`${name}.livestockPresent`} />
            </FormControl>

            <Collapse in={value?.livestockPresent === 'true'}>
              <Stack spacing={2}>
                <FormControl>
                  <FormLabel htmlFor={`${name}.species`}>
                    <Typography>{t('whatType')}</Typography>
                  </FormLabel>

                  <SelectField
                    fieldName={`${name}.species`}
                    options={LIVESTOCK_SPECIES}
                    renderOption={context =>
                      t('species', { context, ns: '@cibo/landmanager/LivestockSpecies' })
                    }
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor={`${name}.headcount`}>{t('howMany')}</FormLabel>
                  <NumberInputField
                    name={`${name}.headcount`}
                    min={1}
                    step={1}
                    data-testid={`${name}.headcount`}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel htmlFor={`${name}.daysGrazing`}>{t('howManyDays')}</FormLabel>
                  <NumberInputField
                    name={`${name}.daysGrazing`}
                    min={1}
                    max={365}
                    step={1}
                    data-testid={`${name}.daysGrazing`}
                  />
                </FormControl>
              </Stack>
            </Collapse>
          </Stack>
        )
      }}
    </Field>
  )
}
