import { Corn, Cotton, Crop, Dairy, Forest, IconWithDefaults, Ranch, Soy, Wheat } from '../../Icons'

const ANY = '-any'

export const SEARCH_FILTERS = {
  ANY: ANY,
  PROPERTY_TYPES: [
    {
      name: 'all_landTypes',
      value: ANY,
    },
    {
      name: 'crop',
      value: 'lcav',
      Icon: Crop,
      color: '#74b52c',
    },
    {
      name: 'dairy',
      value: 'dairy',
      Icon: Dairy,
      color: '#818cbd',
    },
    {
      name: 'ranch',
      value: 'ranch',
      Icon: Ranch,
      color: '#e37513',
    },
    {
      name: 'forest',
      value: 'forest',
      Icon: Forest,
      color: '#74b52c',
    },
  ],

  FIELD_HISTORY: [
    {
      name: 'all_usecases',
      value: ANY,
    },
    {
      name: 'corn',
      value: 'corn',
      Icon: Corn,
      color: '#fed500',
    },
    {
      name: 'soy',
      value: 'soybeans',
      Icon: Soy,
      color: '#60bb21',
    },
    {
      name: 'cotton',
      value: 'cotton',
      Icon: Cotton,
      color: '#828cb9',
    },
    {
      name: 'wheat',
      value: 'durumWheat,springWheat,springWheat',
      Icon: Wheat,
      color: '#d67932',
    },
    {
      name: 'other',
      value: '-corn,-soybeans,-cotton,-durumWheat,-springWheat,-springWheat',
      Icon: IconWithDefaults('More-Horizontal', { size: 24 }),
      color: '#999',
    },
  ],
  SOIL_TEXTURES: [
    {
      name: 'all_textures',
      value: ANY,
    },
    {
      name: 'loam',
      value: 'loam',
    },
    {
      name: 'clay',
      value: 'clay',
    },
    {
      name: 'sand',
      value: 'sand',
    },
  ],
}
