import { RDBiological } from '@cibo/core'
import { object, string } from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { BiologicalCell } from './BiologicalCell'
import { BiologicalEditor } from './BiologicalEditor'

const TRAIT_ID = 'biological'

export const Biological: ITraitFeatureByIdYear<RDBiological> = {
  traitId: TRAIT_ID,
  cellDisplay: BiologicalCell,
  editor: BiologicalEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)

    return {
      type: detail?.result ?? '',
      product: '',
      ...detail?.input,
    }
  },
  ns: '@cibo/landmanager/BiologicalEditor',
  validationSchema: ({ t }) =>
    object()
      .shape({
        type: string<'locus' | 'general' | 'none'>()
          .required(t('required', { context: 'type' }))
          .typeError(t('required', { context: 'type' })),
        product: string().nullable(),
      })
      .required(t('required')),
}
