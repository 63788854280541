import { FieldModel, FieldQueryFilterName, FieldQueryOptions, UserId } from '@cibo/core'
import { GridRowId } from '@mui/x-data-grid-pro'
import { createContext } from 'react'

export interface HideFilters extends Partial<Record<FieldQueryFilterName, boolean>> {
  fdrStatusAnyOf?: boolean
}

export interface PaginatedFieldsState {
  // these convenience functions translate from table-row spec to resourceIds
  onDelete(fieldId?: string): void
  onLabel(): void
  onAssignFields(): void
  onAddInformation(): void

  selectedFields: FieldModel[]
  ownedByUsers: UserId[]
  selectedRowIds: GridRowId[]
  setSelectedRowIds(ids: GridRowId[]): void
  deselectRowIds(ids: GridRowId[]): void
  clearSelectedRowIds(): void

  page: number
  setPage(page: number): void
  pageSize: number
  setPageSize(page: number): void
  filters: FieldQueryOptions
  setFilters: (filters: FieldQueryOptions) => void
  baseFilters: FieldQueryOptions
  setBaseFilters: (filters: FieldQueryOptions) => void
  hideFilters: HideFilters

  isReady: boolean
}

export const PaginatedFieldsContext = createContext<PaginatedFieldsState>({
  filters: {},
  setFilters: console.log,
  baseFilters: {},
  setBaseFilters: console.log,
  hideFilters: {},
  selectedFields: [],
  ownedByUsers: [],
  selectedRowIds: [],
  setSelectedRowIds: console.log,
  deselectRowIds: console.log,
  clearSelectedRowIds: console.log,

  onDelete: console.log,
  onLabel: console.log,
  onAssignFields: console.log,
  onAddInformation: console.log,

  page: 0,
  setPage: console.log,
  pageSize: 10,
  setPageSize: console.log,
  isReady: false,
})
PaginatedFieldsContext.displayName = 'PaginatedFieldsContext'
