import { DetailRequirement, PrgDetail } from '@cibo/core'
import { useMemo } from 'react'

export const useIsRequired = (requirement?: DetailRequirement | PrgDetail) => {
  const isRequired: Record<string, boolean> = useMemo(
    () =>
      requirement?.reqAttrs
        ?.filter(path => path[0] === 'input')
        .reduce((acc, item) => ({ ...acc, [item[item.length - 1]]: true }), {}) || {},
    [requirement]
  )

  return (fieldName: string) => isRequired[fieldName]
}

export const isDetailRequired = (props: {
  requirement?: DetailRequirement | PrgDetail
  fieldName: string
}) => {
  return props.requirement?.reqAttrs?.some((attrs: Array<string | number | symbol>) =>
    attrs.includes(props.fieldName)
  )
}
