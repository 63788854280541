import { createContext, useContext } from 'react'
import { LandManagerRoutes } from '../../types'
import { UseAssignFarmDialog } from './hooks/useAssignFarmDialog/AsignFarmDialogTypes'
import { UseFarmDialog } from './hooks/useFarmDialog/useFarmDialog'
import { UseImportFieldAlyticsDialog } from './hooks/useImportFieldAlyticsDialog'

export type LandManagementModalState = {
  open: boolean
  selectedIds?: string[]
  onSuccess?: (value?: any) => void
}
export type LandManagementModalStateSingleField = {
  open: boolean
  fieldId?: string
  onSuccess?: (value?: any) => void
}

export interface LandManagementState
  extends Pick<UseFarmDialog, 'openFarmDialog'>,
    Pick<UseAssignFarmDialog, 'openAssignFarmDialog'>,
    Pick<UseImportFieldAlyticsDialog, 'openImportFieldAlyticsDialog'> {
  setAssignFieldsModal(state: LandManagementModalState): void
  setDeleteModal(state: LandManagementModalState): void
  setLabelModal(state: LandManagementModalState): void

  confirmDeleteField(resourceId: string): Promise<any>
  confirmDeleteFarm(resourceId: string): Promise<any>

  LAND_MANAGER_ROUTES: LandManagerRoutes
}

export const LandManagementContext = createContext<LandManagementState>({
  setAssignFieldsModal: console.log,
  setDeleteModal: console.log,
  setLabelModal: console.log,

  confirmDeleteField: (resourceId: string) => new Promise(resolve => {}),
  confirmDeleteFarm: (resourceId: string) => new Promise(resolve => {}),

  LAND_MANAGER_ROUTES: {
    DETAIL: '',
    EDIT: '',
    UPLOAD: '',
    DRAW: '',
    ROOT: '',
    PROFILE_FIELDS: '',
  },

  openFarmDialog: console.log,
  openAssignFarmDialog: console.log,
  openImportFieldAlyticsDialog: console.log,
})
LandManagementContext.displayName = 'LandManagementContext'

export const useLandManagement = () => useContext(LandManagementContext)
