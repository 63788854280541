import { FieldModel, FieldQueryOptions } from '@cibo/core'
import { GridRowId } from '@mui/x-data-grid-pro'
import { useQueries } from '@tanstack/react-query'
import { uniq } from 'ramda'
import { PropsWithChildren, ReactNode, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { QUERY_KEY } from '../../queries/queryKey'
import { useLandManagement } from '../LandManagementProvider'
import { HideFilters, PaginatedFieldsContext } from './PaginatedFieldsContext'

export const PAGE_SIZES = [10, 25, 50]

type PaginatedFieldsProviderProps = {
  hideFilters?: HideFilters
  children?: ReactNode
}

export const PaginatedFieldsProvider = ({
  children,
  hideFilters = {},
}: PropsWithChildren<PaginatedFieldsProviderProps>) => {
  const navigate = useNavigate()
  const [filters, setFilters] = useState<FieldQueryOptions>({})
  const [baseFilters, setBaseFilters] = useState<FieldQueryOptions>({})

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const [selectedRowIds, setSelectedRowIds] = useState<string[]>([])
  const clearSelectedRowIds = () => setSelectedRowIds([])

  const queries = useQueries({
    queries: selectedRowIds
      ? selectedRowIds.map(id => ({
          // react-query throws an error if this is not provided
          // @ts-ignore this query fn returns the wrong value but is not necessary
          queryFn: () => undefined as FieldModel,
          queryKey: [QUERY_KEY.FIELDS_PAGINATED_BY_RESOURCE_ID, id],
        }))
      : [],
  })

  const { setDeleteModal, setLabelModal, setAssignFieldsModal, LAND_MANAGER_ROUTES } =
    useLandManagement()

  const handleSetSelectedRowIds = (ids: GridRowId[]) => {
    setSelectedRowIds(ids as string[])
  }
  const handleDeselectRowIds = (ids: GridRowId[]) => {
    setSelectedRowIds(selectedRowIds.filter(id => !ids.includes(id)))
  }

  const selectedFields = queries.map(query => query.data as FieldModel)

  const ownedByUsers = uniq(selectedFields.filter(Boolean).map(f => f.ownedBy?.userId))

  return (
    <PaginatedFieldsContext.Provider
      value={{
        filters,
        setFilters,
        hideFilters,
        baseFilters,
        setBaseFilters,

        isReady: !queries.some(query => query.isPending),

        selectedFields,
        ownedByUsers,
        selectedRowIds,
        setSelectedRowIds: handleSetSelectedRowIds,
        deselectRowIds: handleDeselectRowIds,
        clearSelectedRowIds,

        page,
        setPage,

        pageSize,
        setPageSize,

        onDelete: (fieldId?: string) =>
          setDeleteModal({
            open: true,
            selectedIds: fieldId ? [fieldId] : selectedRowIds,
            onSuccess: clearSelectedRowIds,
          }),

        onLabel: () => setLabelModal({ open: true, selectedIds: selectedRowIds }),
        onAssignFields: () => setAssignFieldsModal({ open: true, selectedIds: selectedRowIds }),
        onAddInformation: () =>
          navigate(LAND_MANAGER_ROUTES.PROFILE_FIELDS, {
            state: { profileFieldIds: selectedRowIds },
          }),
      }}
    >
      {children}
    </PaginatedFieldsContext.Provider>
  )
}
