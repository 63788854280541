import { AmendmentEvent } from '@cibo/core'
import { Stack, Tooltip, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const AmendmentCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/AmendmentEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.events?.length) {
    return (
      <Tooltip
        title={
          <Stack spacing={1} padding={1}>
            {detail.input?.events?.map(
              ({ date, amendmentType, method, rate, units }: AmendmentEvent, index: number) => (
                <Stack key={index}>
                  <Typography variant="body1">
                    {t('eventDate', { date: new Date(date) })}
                  </Typography>
                  <Typography variant="caption" sx={{ color: 'background.default' }}>
                    {t(`type_${amendmentType}`)} - {t(`method_${method}`)}{' '}
                    {t('eventRate', { rate })} {t(`units_${units}`)}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
        }
      >
        <Typography variant="body1">
          {t('events', { count: detail.input?.events?.length })}
        </Typography>
      </Tooltip>
    )
  }

  return <Typography>{t('none')}</Typography>
}
