import {
  FieldReports,
  MaxPoints,
  PointsReport,
  PoolReport,
  RankingPool,
  RankingPoolSymbol,
  RecommendationPointsResponse,
} from '../types/RecommendationPointsTypes'

export class RecommendationPointsModel implements Pick<RecommendationPointsResponse, 'reportId'> {
  poolReports: PoolReportModel[]
  reportId: string

  constructor(params: any = {}) {
    this.poolReports = params.poolReports.map(
      (poolReport: PoolReport) => new PoolReportModel(poolReport)
    )
    this.reportId = params.reportId
  }
  get allPools() {
    return this.poolReports.map(({ rankingPool, currentPoints, maxPoints }) => {
      return {
        name: rankingPool.name,
        label: rankingPool.label,
        symbol: rankingPool.symbol,
        currentPoints: currentPoints.total,
        maxPoints: maxPoints.total,
      }
    })
  }

  practicePoints = (practiceId: string) =>
    this.poolReports.map(pool => pool.practices[practiceId]).filter(Boolean)

  practicePointsByPoolName = (practiceId: string) => {
    const practicePointsByPoolName: Record<string, { effect: number; isAddon: boolean }> = {}
    let hasResult = false
    this.poolReports.forEach(pool => {
      const poolPractice = pool.practices[practiceId]
      if (poolPractice) {
        hasResult = true
        practicePointsByPoolName[poolPractice.rankingPoolName] = {
          effect: poolPractice.effect,
          isAddon: poolPractice.isAddon,
        }
      }
    })
    return hasResult ? practicePointsByPoolName : undefined
  }

  get highestValuePracticeAcrossPrograms() {
    return this.poolReports
      .map(pool => pool.practicePointsList.sort((a, b) => b - a)[0])
      .sort((a, b) => b - a)[0]
  }
}

export class PoolReportModel implements PoolReport {
  currentPoints: PointsReport
  fieldReports: FieldReports[]
  maxPoints: MaxPoints
  rankingPool: RankingPool

  constructor(params: any = {}) {
    this.currentPoints = params.currentPoints
    this.fieldReports = params.fieldReports
    this.maxPoints = params.maxPoints
    this.rankingPool = params.rankingPool
  }

  get practices() {
    const practicePoints: Record<
      string,
      {
        effect: number
        isAddon: boolean
        rankingPoolSymbol: RankingPoolSymbol
        rankingPoolName: string
      }
    > = {}
    this.currentPoints.practicePoints.forEach(
      practice =>
        (practicePoints[practice.practice.code] = {
          effect: practice.effect,
          isAddon: practice.isAddon,
          rankingPoolSymbol: this.rankingPool.symbol,
          rankingPoolName: this.rankingPool.name,
        })
    )
    return practicePoints
  }

  get practicePointsList() {
    return this.currentPoints.practicePoints.map(practice => practice.effect).filter(Boolean)
  }
}
