// based on https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/RadioGroup.tsx
//
// This is mostly a copy of the formik-mui version, but for whatever reason does not suffer from
// the problems with onBlur that make the formik-mui version not work in dev mode.
import { RadioGroup as MuiRadioGroup, RadioGroupProps as MuiRadioGroupProps } from '@mui/material'
import { FieldProps } from 'formik'

export interface RadioGroupProps extends FieldProps, Omit<MuiRadioGroupProps, 'name' | 'value'> {}

export function fieldToRadioGroup({
  field: { onBlur: fieldOnBlur, ...field },
  form,
  onBlur,
  ...props
}: RadioGroupProps): MuiRadioGroupProps {
  return {
    onBlur:
      onBlur ??
      function(e) {
        fieldOnBlur(e ?? field.name)
      },
    ...field,
    ...props,
  }
}

export const RadioGroupFormikInput = (props: RadioGroupProps) => (
  <MuiRadioGroup {...fieldToRadioGroup(props)} />
)

RadioGroupFormikInput.displayName = 'RadioGroupFormikInput'
