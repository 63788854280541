import { Chip, useTheme } from '@mui/material'
import { SourceIcon } from '../SourceIcon'
import { useTranslation } from 'react-i18next'
import { CommonChipProps } from './types'

export const NoneChip = ({ size = 'small', ...chipProps }: Omit<CommonChipProps, 'crop'>) => {
  const { t } = useTranslation('@cibo/ui/NoneChip')
  const { palette } = useTheme()

  return (
    <Chip
      label={t('none')}
      size={size}
      {...chipProps}
      sx={{ border: `solid 1px ${palette.grey[palette.mode === 'dark' ? '400' : '600']}` }}
      icon={<SourceIcon source={chipProps.source || 'none'} sx={{ opacity: 0.8 }} />}
    />
  )
}
