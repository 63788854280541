import {
  BenchmarkStatusReason,
  FieldEligibilityState,
  FieldModel,
  ResourceProgramState,
} from '@cibo/core'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { TFunction } from 'i18next'
import {
  fieldAcresColumn,
  fieldCreatedAtColumn,
  fieldNameLocationColumn,
  fieldThumbnailColumn,
  fieldUpdatedAtColumn,
} from '../FieldColumns'

dayjs.extend(relativeTime)

type RowPrograms = {
  title: string
  state: ResourceProgramState
}[]

export interface FieldRow {
  model: FieldModel
  id: string
  name: string
  acres?: number
  county?: string
  state?: string
  profiled: boolean
  prequalified?: [
    {
      recommendationScore: number
      title: string
      status: FieldEligibilityState
      validReason: BenchmarkStatusReason[]
    }
  ]
  programCount?: RowPrograms
  updatedAt: string
}

export const infoColumns = ({ t, acres }: { t: TFunction; acres?: (acres: number) => string }) => [
  {
    field: 'state',
    headerName: t('state'),
    minWidth: 80,
    flex: 1,
    valueFormatter: (params: any) => `${params.value || '-'}`.toUpperCase(),
  },
  {
    field: 'county',
    headerName: t('county'),
    minWidth: 80,
    flex: 2,
    valueFormatter: (params: any) => `${params.value || '-'}`,
  },
  fieldAcresColumn({ t, acres }),
  fieldUpdatedAtColumn({ t }),
  fieldCreatedAtColumn({ t }),
]

export const DEFAULT_PAGINATED_FIELDS_COLUMNS = (t: TFunction) => [
  fieldThumbnailColumn({ t }),
  fieldNameLocationColumn({ t }),
  ...infoColumns({ t }),
]
