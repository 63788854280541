import { RDXtremeAgCode } from '@cibo/core'
import { pathEq } from 'ramda'
import { object, string } from 'yup'
import { ITraitFeatureById } from '../types'
import { findInitialValuesDetail } from '../utils'
import { XtremeAgCodeCell } from './XtremeAgCodeCell'
import { XtremeAgCodeCellEditor } from './XtremeAgCodeCellEditor'
import { XtremeAgCodeEditor } from './XtremeAgCodeEditor'
import { XtremeAgCodeRollup } from './XtremeAgCodeRollup'

const TRAIT_ID = 'xtremeAgCode'

export const XtremeAgCode: ITraitFeatureById<RDXtremeAgCode> = {
  traitId: TRAIT_ID,
  cellDisplay: XtremeAgCodeCell,
  editor: XtremeAgCodeEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail ? detail.input ?? detail.result : {}
  },
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: XtremeAgCodeRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          xtremeAgCode: false,
        },
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  cellEditor: XtremeAgCodeCellEditor,
  ns: '@cibo/landmanager/XtremeAgCode',
  validationSchema: ({ t }) =>
    object()
      .shape({
        xtremeAgCode: string()
          .matches(/^XA[0-9]{4}$/, t('codeFormatValidator'))
          .required(),
      })
      .required(),
}
