import {
  SupplyShedAggregationType,
  SupplyShedCashCropChoice,
  SupplyShedCoverCropChoice,
  SupplyShedTillageChoice,
} from '@cibo/core'
import { PropsWithChildren, createContext, useState } from 'react'

export interface SupplyShedFiltersContextProps {
  aggregationType: SupplyShedAggregationType
  setAggregationType: (type: SupplyShedAggregationType) => void
  cashCrops?: SupplyShedCashCropChoice[]
  setCashCrops: (cashCrops: SupplyShedCashCropChoice[]) => void
  coverCrop?: SupplyShedCoverCropChoice
  setCoverCrop: (coverCrops?: SupplyShedCoverCropChoice) => void
  tillageTypes?: SupplyShedTillageChoice[]
  setTillageTypes: (tillageTypes: SupplyShedTillageChoice[]) => void
  year?: number
  setYear: (year: number) => void
}

export const SupplyShedFiltersContext = createContext<SupplyShedFiltersContextProps>({
  aggregationType: 'watershed',
  setAggregationType: () => null,
  setCashCrops: () => null,
  setCoverCrop: () => null,
  setTillageTypes: () => null,
  setYear: () => null,
})
SupplyShedFiltersContext.displayName = 'SupplyShedFiltersContext'

const DEFAULT_YEAR = new Date().getFullYear() - 1

export const SupplyShedProvider = ({ children }: PropsWithChildren<{}>) => {
  const [aggregationType, setAggregationType] = useState<SupplyShedAggregationType>('state')
  const [cashCrops, setCashCrops] = useState<SupplyShedCashCropChoice[]>([])
  const [tillageTypes, setTillageTypes] = useState<SupplyShedTillageChoice[]>([])
  const [coverCrop, setCoverCrop] = useState<SupplyShedCoverCropChoice>()
  const [year, setYear] = useState(DEFAULT_YEAR)

  return (
    <SupplyShedFiltersContext.Provider
      value={{
        aggregationType,
        setAggregationType,
        coverCrop,
        setCoverCrop,
        cashCrops,
        setCashCrops,
        tillageTypes,
        setTillageTypes,
        year,
        setYear,
      }}
    >
      {children}
    </SupplyShedFiltersContext.Provider>
  )
}
