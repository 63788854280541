import { FriendlyError } from '@cibo/ui'
import { useTranslation } from 'react-i18next'

const plantingRateByCrop: Record<
  string,
  Record<string, { min: number; max: number } | undefined>
> = {
  corn: {
    seedsPerAcre: {
      min: 25000,
      max: 45000,
    },
  },
  soybeans: {
    seedsPerAcre: {
      min: 75000,
      max: 175000,
    },
  },
}

export const PlantingRateWarning = ({
  plantingRate,
  plantingRateUnit,
  crop,
}: {
  crop: string
  plantingRateUnit?: string
  plantingRate?: number
}) => {
  const { t } = useTranslation('@cibo/landmanager/CashCropEditor')
  const plantingRateRecommendations =
    crop &&
    plantingRateUnit &&
    plantingRateByCrop[crop] &&
    plantingRateByCrop[crop][plantingRateUnit]

  return plantingRateRecommendations &&
    plantingRate &&
    (plantingRate > plantingRateRecommendations.max ||
      plantingRate < plantingRateRecommendations.min) ? (
    <FriendlyError
      small
      type="warning"
      message={t('rateSuggestion', {
        crop,
        plantingRateUnit: t('plantingRate', { context: plantingRateUnit }).toLocaleLowerCase(),
        ...plantingRateRecommendations,
      })}
      textProps={{ sx: { pt: 1 } }}
    />
  ) : null
}
