import { GeoJsonEditMode } from '@nebula.gl/edit-modes'
import { FeatureCollection, Position } from '@turf/turf'
import { CSSProperties, ForwardRefExoticComponent, ReactNode, RefAttributes } from 'react'

import { LayerRendererProps } from '../Layers'

export const GEOMETRY_SNAPSHOTS = [
  'addPosition',
  'removePosition',
  'addFeature',
  'finishMovePosition',
  'split',
  'extruded',
  'unionGeometry',
  'scaled',
  'rotated',
  'translated',
]
export interface GeoJSONLayerState {
  features: FeatureCollection
  selectedIndexes: number[]
}

export interface GeoJSONEditHistory {
  editLayer: GeoJSONLayerState
  redoStack: GeoJSONLayerState[]
  undoStack: GeoJSONLayerState[]
  transforming: boolean
}

export interface EditorMode {
  marqueeLayer: {
    mode: typeof GeoJsonEditMode
    modeConfig: any
  }
  editLayer: {
    mode: typeof GeoJsonEditMode
    modeConfig: any
  }
  isMarquee: boolean
  isTransform: boolean
}

export interface GeometryEditorProps {
  children?: (props: { touched: boolean; features: FeatureCollection }) => ReactNode
  disabled?: boolean
  filters?: LayerRendererProps
  initialPosition?: Position
  onChange?: (props: { features: FeatureCollection; touched: boolean }) => void
  MapChildren?: ReactNode
  LayerOverlay?: ForwardRefExoticComponent<{} & RefAttributes<HTMLElement>>
  displayTools?: boolean
  onBack?: () => void
  onNext?: () => void
  style?: CSSProperties
  onUnmount?: () => void
  displayFieldsetLayer?: boolean
  deckGlLayers?: any[]
}

export interface ViewState {
  longitude: number
  latitude: number
  zoom: number
}
