import { ReactNode } from 'react'
import { pick } from 'ramda'
import { Typography, TypographyProps, Stack, ThemeProvider } from '@mui/material'
import { THEME_DARK } from '../../../theme'

export interface InputLabelProps {
  dark?: boolean
  label?: string

  labelProps?: TypographyProps
  rightLabelComponent?: ReactNode
  showRequiredStar?: boolean
}

export const pickInputLabelProps = pick([
  'dark',
  'label',
  'labelProps',
  'rightLabelComponent',
  'showRequiredStar',
])

export const InputLabel = ({
  dark = false,
  label,
  labelProps,
  rightLabelComponent,
  showRequiredStar = false,
}: InputLabelProps) => {
  const displayComponent = (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      sx={{
        paddingBottom: 1,
      }}
    >
      {!!label && (
        <Typography variant="caption" {...labelProps}>
          {label}
          {showRequiredStar && (
            <Typography variant="caption" color="error" component="span">
              {' *'}
            </Typography>
          )}
        </Typography>
      )}
      {!!rightLabelComponent && rightLabelComponent}
    </Stack>
  )
  if (!!label || !!rightLabelComponent || !!showRequiredStar) {
    if (dark) {
      return <ThemeProvider theme={THEME_DARK}>{displayComponent}</ThemeProvider>
    }
    return displayComponent
  }
  return null
}
