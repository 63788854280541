import { allPass, flatten, propEq } from 'ramda'
import {
  BenchmarkStatusReason,
  DetailRequirement,
  EngagementArtifact,
  EngagementStatusBlock,
  ProgramEngagement,
  ProgramEngagementStatus,
  ProgramEngagementUser,
  ProgramLedger,
  ProgramStageType,
  ProgramStep,
  TaskStatusReason,
  UserId,
} from '../types'

export class ProgramEngagementModel implements ProgramEngagement {
  id: string
  programId: string
  displayActor: 'participant' | 'manager' | 'managerAdmin' | 'ciboSupport'
  artifacts: EngagementArtifact[]
  createdBy: ProgramEngagementUser
  currentStep: string
  currentStepType: ProgramStageType
  newFieldStep?: string
  fieldCounts?: { total: number } | undefined
  fields: string[]
  name?: string | undefined
  ownedBy: ProgramEngagementUser
  space?: 'account' | 'user' | undefined
  status: ProgramEngagementStatus[]
  userId: UserId
  updatedAt: string
  updatedBy: ProgramEngagementUser
  overallStatus?: EngagementStatusBlock

  constructor(engagement: ProgramEngagement) {
    this.displayActor = engagement.displayActor
    this.artifacts = engagement.artifacts
    this.createdBy = engagement.createdBy
    this.currentStep = engagement.currentStep
    this.newFieldStep = engagement.newFieldStep
    this.currentStepType = engagement.currentStepType
    this.fieldCounts = engagement.fieldCounts
    this.fields = engagement.fields
    this.id = engagement.id
    this.name = engagement.name
    this.ownedBy = engagement.ownedBy
    this.programId = engagement.programId
    this.space = engagement.space
    this.status = engagement.status
    this.userId = engagement.userId
    this.updatedAt = engagement.updatedAt
    this.updatedBy = engagement.updatedBy
    this.overallStatus = engagement.overallStatus
  }

  get ownerDisplayName() {
    return this.ownedBy.givenName
      ? `${this.ownedBy.givenName} ${this.ownedBy.familyName}`
      : this.ownedBy.userPrimaryEmail
  }

  findArtifact(slot: string) {
    return this.artifacts.find(a => a.slot === slot)
  }

  get currentStepId() {
    return this.currentStep?.split(':')?.[1]
  }

  /**
   * The first incomplete task in the given step, excluding the tasks with the filterIds.
   */
  defaultTask({
    filterIds = [],
    stepId,
  }: { stepId?: ProgramStep['id']; filterIds?: string[] } = {}) {
    const step = this.getStepStatus(stepId)
    if (!step) return
    const cadidateTasks = step.children?.filter(({ id }) => filterIds.includes(id) === false)
    return cadidateTasks?.find(t => t.status?.progress?.completed === false) ?? cadidateTasks?.[0]
  }

  /*
   * Each program will have a single `entrance` task that covers the benchmark for adding a field to an
   * engagement. It is possible to add a field which meets the benchmark to an engagement, but then the
   * field's data changes and no longer meets the benchmark. In this case, we want to show the user that
   * the field is no longer eligible for the program.
   *
   * This function returns the status of the `entrance` task.
   */
  entranceTaskStatus() {
    return this.getTaskStatus('entranceTask')
  }

  firstIncompleteTaskInStage(stageId: ProgramStageType) {
    const stage = this.getStageStatus(stageId)
    if (!stage) return
    return findNodeByComparator({
      comparator: (status: ProgramEngagementStatus) =>
        status.type === 'task' && status.status.progress?.completed === false,
      status: stage.children,
    })
  }

  firstIncompleteStep() {
    return findNodeByComparator({
      comparator: (status: ProgramEngagementStatus) =>
        status.type === 'step' && status.status.progress?.completed === false,
      status: this.status,
    })
  }

  firstFailedTask(stepId: ProgramStep['id']) {
    const step = this.getStepStatus(stepId)
    if (!step) return
    return step.children?.find(t => !t.status?.eligibility?.eligible)
  }

  get currentStage() {
    return this.status.find(s => s.type === 'stage' && s.status?.progress?.completed === false)
  }

  getStatus(props: { id: string }) {
    return findNode({ ...props, status: this.status })
  }

  getTaskStatus(id?: string) {
    return id ? findNode({ id, status: this.status }) : undefined
  }

  getStepStatus(id?: string) {
    return id ? findNode({ id, status: this.status }) : undefined
  }

  getStageStatus(id: string) {
    return findNode({ id, status: this.status })
  }

  ineligibleTaskFieldReasons(taskId: string) {
    const task = this.getTaskStatus(taskId)
    return (
      task?.status?.eligibility?.reasonItems?.filter(({ dataType }) => dataType === 'field') ?? []
    )
  }

  ineligibleTaskEngagementReasons(taskId: string) {
    const task = this.getTaskStatus(taskId)
    return (
      task?.status?.eligibility?.reasonItems?.filter(({ dataType }) => dataType === 'workflow') ??
      []
    )
  }

  ineligibleTaskUserReasons(taskId: string) {
    const task = this.getTaskStatus(taskId)
    return (
      task?.status?.eligibility?.reasonItems?.filter(({ dataType }) => dataType === 'user') ?? []
    )
  }

  isFailedTask(id: string) {
    const task = this.getTaskStatus(id)
    return task?.status?.valid === false && task?.status?.progress?.completed
  }

  exceedsProgramGrowerLimits({ ledger, acres }: { ledger: ProgramLedger; acres: number }) {
    return (
      (ledger.maxAcresPerGrower && acres > ledger.maxAcresPerGrower) ||
      (ledger.maxFieldsPerGrower && this.fields.length > ledger.maxFieldsPerGrower)
    )
  }

  isComplete() {
    return (
      this.overallStatus?.eligibility?.eligible &&
      this.overallStatus?.valid &&
      this.overallStatus?.warnings?.noWarnings &&
      this.overallStatus?.progress?.completed
    )
  }

  // @todo: prefer backend status with https://cibotech.atlassian.net/browse/CPD-5899
  systemWideStatus() {
    if (this.isComplete()) return 'completed'

    const currentStage = this.currentStage

    if (!currentStage) return 'unknown'

    return ['enroll', 'apply'].includes(currentStage.id) ? 'inProgress' : 'active'
  }

  lastStepAndStage() {
    const lastStage = this.status?.filter(a => a.type === 'stage').slice(-1)[0]
    const lastStep = lastStage?.children?.filter(a => a.type === 'step').slice(-1)[0]
    return { stage: lastStage, step: lastStep }
  }

  taskIsMissingFieldInfo(taskId: string, fieldId: string, requirement: DetailRequirement) {
    const taskStatus = this.getTaskStatus(taskId)

    const fieldProgressStatus = taskStatus?.status.progress?.reasonItems.find(
      allPass([propEq('dataType', 'field'), propEq('id', fieldId)])
    )

    return fieldProgressStatus?.reasons?.some(
      allPass([
        propEq('reason', 'missingData'),
        reason => reason['traitId'] === requirement.traitId,
        reason => !requirement.year || reason['year'] === requirement.year,
      ])
    )
  }

  warningsForTask(taskId: string) {
    const task = this.getTaskStatus(taskId)
    return task?.status?.warnings?.reasonItems ?? []
  }

  taskHasWarning(taskId: string, warningReason: string) {
    const task = this.getTaskStatus(taskId)
    return task?.status?.warnings?.reasonItems?.some(item =>
      item.reasons?.some(reason => reason.reason === warningReason)
    )
  }

  stepDescendentIneligibilityReasons(stepId?: string) {
    if (!stepId) return []
    const status = this.getStepStatus(stepId)
    const allIneligibleNodes =
      !!status &&
      findNodesByComparator({
        comparator: a => !a.status.valid && !!a.status.progress && a.status.progress.completed,
        status: status,
      })
    return !!allIneligibleNodes
      ? (flatten(allIneligibleNodes.map(status => status.status.eligibility?.reasonItems)).filter(
          Boolean
        ) as TaskStatusReason<BenchmarkStatusReason>[])
      : []
  }
}

const findNode = ({
  id,
  status,
}: {
  id: string
  status: ProgramEngagementStatus[]
}): ProgramEngagementStatus | undefined => {
  if (!status) return
  const hit = status.find(s => s.id === id)
  if (hit) return hit
  for (const s of status) {
    if (s.children) {
      const childHit = findNode({ id, status: s.children })
      if (childHit) return childHit
    }
  }
}

const findNodeByComparator = ({
  comparator,
  status,
}: {
  comparator: (status: ProgramEngagementStatus) => boolean
  status: ProgramEngagementStatus[]
}): ProgramEngagementStatus | undefined => {
  if (!status) return
  const hit = status.find(comparator)
  if (hit) return hit
  for (const s of status) {
    if (s.children) {
      const childHit = findNodeByComparator({ comparator, status: s.children })
      if (childHit) return childHit
    }
  }
}

const findNodesByComparator = ({
  comparator,
  status,
}: {
  comparator: (status: ProgramEngagementStatus) => boolean
  status: ProgramEngagementStatus
}): ProgramEngagementStatus[] | undefined => {
  const hits = []
  const hit = status.children?.filter(comparator)
  if (hit) hits.push(...hit)
  for (const s of status.children) {
    if (s.children) {
      const childHits = findNodesByComparator({ comparator, status: s })
      if (childHits) hits.push(...childHits)
    }
  }
  return hits.filter(Boolean).length > 0 ? flatten(hits) : undefined
}
