import { ResourceDetail } from './ResourceDetail'

export type NativeConversionInput = {
  converted?: ConvertedChoices
  nativeVegetation?: NativeVegetationChoices
  conversionYear?: number
  unsureOfYear?: boolean
}

export type NativeConversionResult = ConvertedChoices

export type ConvertedChoices = 'yes' | 'no' | 'unsure'

export const NATIVE_VEGETATION_TYPES = [
  'forest',
  'grassland',
  'wetland',
  'perennialCropland',
  'unsure',
] as const

export type NativeVegetationChoices = typeof NATIVE_VEGETATION_TYPES[number]

export interface RDNativeConversion extends ResourceDetail {
  traitId: 'nativeVegetationConversion'
  result: NativeConversionResult
  input: NativeConversionInput
}
