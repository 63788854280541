//@ts-ignore
import { RGBAColor } from 'deck.gl'
//@ts-ignore
import { PathStyleExtension } from '@deck.gl/extensions'

import { MapStyles } from '../Layers'

type ColorSet = Record<
  MapStyles,
  {
    selected: RGBAColor
    unselected: RGBAColor
  }
>

const LINE_COLOR = {
  [MapStyles.FOCUSED]: {
    selected: [85, 85, 85],
    unselected: [134, 198, 208],
  },
  [MapStyles.SATELLITE]: {
    selected: [255, 255, 255],
    unselected: [134, 198, 208],
  },
  [MapStyles.CDL]: {
    selected: [255, 255, 255],
    unselected: [134, 198, 208],
  },
} as ColorSet

export const getFeatureLineColor = (
  mapStyle: MapStyles = MapStyles.SATELLITE,
  isSelected: boolean
): RGBAColor => (isSelected ? LINE_COLOR[mapStyle].selected : LINE_COLOR[mapStyle].unselected)

const FILL_COLOR = {
  [MapStyles.FOCUSED]: {
    selected: [134, 198, 208, 200],
    unselected: [134, 198, 208, 102],
  },
  [MapStyles.SATELLITE]: {
    selected: [134, 198, 208, 200],
    unselected: [134, 198, 208, 102],
  },
  [MapStyles.CDL]: {
    selected: [134, 198, 208, 200],
    unselected: [134, 198, 208, 102],
  },
} as ColorSet
export const getFeatureFillColor = (
  mapStyle: MapStyles = MapStyles.SATELLITE,
  isSelected: boolean
): RGBAColor => (isSelected ? FILL_COLOR[mapStyle].selected : FILL_COLOR[mapStyle].unselected)

const SELECT_SUBLAYERS = {
  [MapStyles.FOCUSED]: {
    geojson: {
      _subLayerProps: {
        'polygons-stroke': {
          getWidth: 4,
          // props added by PathStyleExtension
          getDashArray: [4, 3],
          dashJustified: false,
          extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
        },
      },
    },
    guides: {
      _subLayerProps: {
        linestrings: {
          getWidth: 4,
          // props added by PathStyleExtension
          getDashArray: [4, 3],
          dashJustified: false,
          extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
        },
        'points-circle': {
          getFillColor: [0, 0, 0],
          getLineColor: [255, 255, 255],
          radiusUnits: 'pixels',
          getRadius: 5,
        },
        'polygons-stroke': {
          getColor: [0, 0, 0],
          getWidth: 4,
          // props added by PathStyleExtension
          getDashArray: [4, 3],
          dashJustified: false,
          extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
        },
      },
    },
  },
  [MapStyles.CDL]: {},
  [MapStyles.SATELLITE]: {
    geojson: {
      _subLayerProps: {
        'polygons-stroke': {
          getColor: [255, 255, 255],
          getWidth: 4,
          // props added by PathStyleExtension
          getDashArray: [4, 3],
          dashJustified: false,
          extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
        },
      },
    },
    guides: {
      _subLayerProps: {
        linestrings: {
          getWidth: 4,
          getColor: [225, 225, 225],
          // props added by PathStyleExtension
          getDashArray: [4, 3],
          dashJustified: false,
          extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
        },
        'points-circle': {
          getFillColor: [0, 0, 0],
          getLineColor: [255, 255, 255],
          radiusUnits: 'pixels',
          getRadius: 5,
        },
        'polygons-stroke': {
          getColor: [255, 255, 255],
          getWidth: 4,
          // props added by PathStyleExtension
          getDashArray: [4, 3],
          dashJustified: false,
          extensions: [new PathStyleExtension({ dash: true, highPrecisionDash: true })],
        },
      },
    },
  },
}

export const getSelectSubLayers = (mapStyle: MapStyles) => SELECT_SUBLAYERS[mapStyle]
