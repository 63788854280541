import { SupplyShedFeature } from '@cibo/core'
import { UseFigures, intuitiveFromNow } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { TFunction } from 'i18next'

dayjs.extend(relativeTime)

export const nameColumn = (t: TFunction) => ({
  field: 'name',
  headerName: t('name', { ns: '@cibo/landmanager/UserSupplyShedTable' }),
  hideable: false,
  flex: 1,
  minWidth: 160,
  renderCell: (params: GridRenderCellParams<any, string, SupplyShedFeature>) => {
    const { name, reportStatus, report } = params.row || {}

    const subtitle =
      reportStatus && reportStatus[0] && reportStatus[0].status === 'success'
        ? t('parcelCount', { count: report?.parcelCount })
        : t('processing')

    return (
      <Stack>
        <Typography variant="body2">{name}</Typography>
        <Typography variant="caption">{subtitle}</Typography>
      </Stack>
    )
  },
  sortable: false,
})

export const sizeColumn = (t: TFunction, { acres }: UseFigures) => ({
  field: 'size',
  headerName: t('size', { ns: '@cibo/landmanager/UserSupplyShedTable' }),
  hideable: false,
  flex: 1,
  minWidth: 160,
  renderCell: (params: GridRenderCellParams<any, string, SupplyShedFeature>) => (
    <Typography>{acres(params.row.acreage)}</Typography>
  ),
  sortable: false,
})

export const ownerColumn = (t: TFunction) => ({
  field: 'owner',
  headerName: t('owner', { ns: '@cibo/landmanager/UserSupplyShedTable' }),
  hideable: false,
  flex: 1,
  minWidth: 160,
  renderCell: (params: GridRenderCellParams<any, string, SupplyShedFeature>) => (
    <Typography>{params.row.author.email}</Typography>
  ),
  sortable: false,
})

export const sharedColumn = (t: TFunction) => ({
  field: 'shared',
  headerName: t('shared', { ns: '@cibo/landmanager/UserSupplyShedTable' }),
  hideable: false,
  flex: 1,
  minWidth: 160,
  renderCell: (params: GridRenderCellParams<any, string, SupplyShedFeature>) => (
    <>
      {/* @ts-ignore */}
      <Typography>{params.row.shared}</Typography>
    </>
  ),
  sortable: false,
})

export const updatedColumn = (t: TFunction) => ({
  field: 'updatedAt',
  headerName: t('updatedAt', { ns: '@cibo/landmanager/UserSupplyShedTable' }),
  minWidth: 160,
  flex: 2,
  valueFormatter: (params: GridRenderCellParams<any, string, SupplyShedFeature>) =>
    params.value ? intuitiveFromNow(params.value) : '--',
  sortable: false,
})

export const createdColumn = (t: TFunction) => ({
  field: 'createdAt',
  headerName: t('createdAt', { ns: '@cibo/landmanager/UserSupplyShedTable' }),
  minWidth: 160,
  flex: 2,
  valueFormatter: (params: GridRenderCellParams<any, string, SupplyShedFeature>) =>
    params.value ? intuitiveFromNow(params.value) : '--',
  sortable: false,
})

export const DEFAULT_USER_SUPPLY_SHEDS_COLUMNS = (t: TFunction, useFigures: UseFigures) => [
  nameColumn(t),
  sizeColumn(t, useFigures),
  ownerColumn(t),
  // sharedColumn(t), @todo: we don't share yet
  createdColumn(t),
  // updatedColumn(t) @todo: we don't modify yet
]
