import { EVENT_IRRIGATION_UNITS } from '@cibo/core'
import { DatePickerField, NumberInputField, SelectField } from '@cibo/ui'
import ClearIcon from '@mui/icons-material/Clear'
import { FormControl, IconButton, Stack, TableCell, TableRow } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { EventComponentProps } from '../EventDetail'

export const IrrigationEventEditor = ({ year, name, onPressRemove }: EventComponentProps) => {
  const { t } = useTranslation('@cibo/landmanager/EventIrrigationEditor')

  const startMonth = new Date(year, 4)
  const endMonth = new Date(year, 8)

  return (
    <TableRow key={name}>
      <TableCell sx={{ verticalAlign: 'top' }}>
        <Stack direction="row" spacing={1}>
          <Stack>
            <FormControl style={{ flex: 1 }} required>
              <DatePickerField
                name={`${name}.startDate`}
                minDate={new Date(year, 0, 1)}
                maxDate={new Date(year, 11, 31)}
                defaultCalendarMonth={startMonth}
                label={t('start')}
                InputProps={{
                  //@ts-ignore this will be passed to the input element, but is not in the type definition
                  'data-testid': `${name}.startDate`,
                }}
              />
            </FormControl>
          </Stack>

          <Stack>
            <FormControl style={{ flex: 1 }} required>
              <DatePickerField
                name={`${name}.endDate`}
                minDate={new Date(year, 0, 1)}
                maxDate={new Date(year, 11, 31)}
                defaultCalendarMonth={endMonth}
                label={t('end')}
                InputProps={{
                  //@ts-ignore this will be passed to the input element, but is not in the type definition
                  'data-testid': `${name}.endDate`,
                }}
              />
            </FormControl>
          </Stack>
        </Stack>
      </TableCell>

      <TableCell sx={{ verticalAlign: 'top' }}>
        <Stack
          direction="row"
          spacing={1}
          sx={{
            '& .MuiFormControl-root': {
              flex: 1,
            },
          }}
        >
          <NumberInputField
            name={`${name}.amount`}
            style={{ flex: 1 }}
            min={0}
            data-testid={`${name}.amount`}
          />

          <SelectField
            fieldName={`${name}.unit`}
            options={EVENT_IRRIGATION_UNITS}
            renderOption={value => t('irrigationType', { context: value })}
          />
        </Stack>
      </TableCell>

      <TableCell>
        <IconButton aria-label={t('removeEvent')} onClick={onPressRemove}>
          <ClearIcon />
        </IconButton>
      </TableCell>
    </TableRow>
  )
}
