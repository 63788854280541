import { arrayFromBboxString } from '@cibo/core'
import { useEffect, useMemo, useState } from 'react'
import { useMapBounds } from '../useMapBounds'
import { HistogramLegend, LegendResponse, ReadyResponse } from './types'

// tilesetBaseUrl must be the path to a metadata.json description of a raster
// tileset and is also the base url of the tiles eg.
//   `${tilesetBaseUrl}{z}/{x}/{y}.png`
//
// @todo: Fetch tileset metadata with a hook & use that tileurl in raster layer
//
export const useHistogramLegend = ({
  tilesetBaseUrl,
  maptilesServiceUrl,
}: {
  tilesetBaseUrl: string
  maptilesServiceUrl: string
}) => {
  const bounds = useMapBounds()
  const [data, setData] = useState<HistogramLegend>()

  const worker: Worker = useMemo(
    () => new Worker(new URL('./rasterLegendWorker.ts', import.meta.url), { type: 'module' }),
    []
  )

  useEffect(() => {
    if (worker) {
      worker.postMessage({ type: 'init', config: { tilesetBaseUrl, maptilesServiceUrl } })
    }
  }, [worker, tilesetBaseUrl])

  useEffect(() => {
    if (window.Worker) {
      worker.onmessage = ({ data }: MessageEvent<ReadyResponse | LegendResponse>) => {
        switch (data.type) {
          case 'ready':
            break
          case 'legend':
            setData(data.legend)
            break
        }
      }
    }
  }, [worker])

  useEffect(() => {
    if (!bounds) return

    try {
      const bbox = arrayFromBboxString(bounds)

      worker?.postMessage({ type: 'legend', bbox })
    } catch (error) {
      // this would crash if zoomed way out or rotated to a diabolical bbox
    }
  }, [bounds, worker])

  return data
}
