import { COVER_CROP } from '@cibo/core'

export const SUGGESTED_COVER_CROP_OPTIONS = [
  'cerealRye',
  'oats',
  'radishes',
  'redClover',
  'ryegrass',
  'triticale',
  'vetch',
  'winterwheat',
  'none',
]
export const COVER_CROP_OPTIONS = [
  ...SUGGESTED_COVER_CROP_OPTIONS,
  ...COVER_CROP.filter(c => !SUGGESTED_COVER_CROP_OPTIONS.includes(c)),
]
