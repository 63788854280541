import { RDEqipPracticeSelectionConfirmation } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps, Section } from '@cibo/ui'
import { Stack } from '@mui/material'
import {
  PracticeSelectionConfirmation,
  PracticeSelectionSummaryTable,
} from '../../../../components/PracticeSelectionConfirmation'

export const PracticeSelectionConfirmationTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticeSelectionConfirmation>
) => (
  <Stack spacing={3} flex={1}>
    <Section sx={{ flex: 1 }}>
      <Stack padding={3} spacing={3}>
        <PracticeSelectionConfirmation {...props} />
      </Stack>
    </Section>
    <Section>
      <PracticeSelectionSummaryTable {...props} />
    </Section>
  </Stack>
)
