import { useUserWebSocket } from '@cibo/profile'
import { useQueryClient } from '@tanstack/react-query'
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { DeleteSupplyShedModal } from './Modals/DeleteSupplyShedModal'
import { ShareSupplyShedModal } from './Modals/ShareSupplyShedModal'

type ModalState = {
  open: boolean
  selectedIds?: string[]
  onSuccess?: (value?: any) => void
}

export interface UserSupplyShedManagementState {
  setDeleteModal(state: ModalState): void
  setShareModal(state: ModalState): void

  confirmDeleteUserSupplyShed(resourceId: string): Promise<any>
}

export const UserSupplyShedManagementContext = createContext<UserSupplyShedManagementState>({
  setDeleteModal: console.log,
  setShareModal: console.log,

  confirmDeleteUserSupplyShed: (resourceId: string) => new Promise(resolve => {}),
})
UserSupplyShedManagementContext.displayName = 'UserSupplyShedManagementContext'

export const UserSupplyShedManagementProvider = ({ children }: PropsWithChildren<{}>) => {
  const queryClient = useQueryClient()
  const { lastMessage } = useUserWebSocket('autoCalc')

  const [deleteModal, setDeleteModal] = useState<ModalState>({ open: false })
  const [shareModal, setShareModal] = useState<ModalState>({ open: false })

  useEffect(() => {
    if (lastMessage?.subject === 'autoCalc') {
      // @todo: invalidate the supplyshed report that is ready
      // const resourceIds = lastMessage.entityIds as string[]
    }
  }, [lastMessage, queryClient])

  return (
    <UserSupplyShedManagementContext.Provider
      value={{
        setDeleteModal,
        setShareModal,

        confirmDeleteUserSupplyShed: (resourceId: string) =>
          new Promise(resolve => {
            setDeleteModal({ open: true, selectedIds: [resourceId], onSuccess: resolve })
          }),
      }}
    >
      {children}

      <DeleteSupplyShedModal
        visible={deleteModal.open}
        selectedIds={deleteModal.selectedIds}
        onSuccess={deleteModal.onSuccess}
        onRequestClose={() => setDeleteModal({ open: false })}
      />

      <ShareSupplyShedModal
        visible={shareModal.open}
        selectedIds={deleteModal.selectedIds}
        onRequestClose={() => setShareModal({ open: false })}
      />
    </UserSupplyShedManagementContext.Provider>
  )
}

export const useUserSupplyShedManagement = () => useContext(UserSupplyShedManagementContext)
