import { RDEqipRecommendedPracticesIntent } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { PracticesIntentTaskEditor } from './PracticesIntent'

export const RDEqipRecommendedPracticesIntentResourceDetailFeature: ResourceDetailFeature<RDEqipRecommendedPracticesIntent> =
  {
    traitId: 'eqipRecommendedPracticesIntent',
    selfIsland: true,
    TaskEditor: PracticesIntentTaskEditor,
  }
export const RDEqipRecommendedPracticesIntentFromPointsResourceDetailFeature: ResourceDetailFeature<RDEqipRecommendedPracticesIntent> =
  {
    traitId: 'eqipRecommendedPracticesIntent',
    selfIsland: true,
    TaskEditor: PracticesIntentTaskEditor,
  }
