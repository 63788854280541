import { useEffect, useRef } from 'react'

export const useInterval = (callback: () => void, delay: number | null, disabled?: boolean) => {
  const savedCallback = useRef<() => void>()

  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  useEffect(() => {
    const tick = () => {
      savedCallback.current && savedCallback.current()
    }
    if (delay !== null) {
      let id: any
      if (!disabled) {
        id = setInterval(tick, delay)
      }
      return () => id && clearInterval(id)
    }
  }, [delay, disabled])
}
