import { DatePickerProps, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { Field } from 'formik'
import { DatePicker } from './DatePicker'

interface DatePickerFieldProps extends Partial<DatePickerProps<any, any>> {
  name: string
  'data-testid'?: string
  inlineClearButton?: boolean
}

export const DatePickerField = (props: DatePickerFieldProps) => {
  const { name, ...datePickerProps } = props

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Field
        component={DatePicker}
        name={name}
        {...datePickerProps}
        InputProps={{
          //@ts-ignore this will be passed to the input element, but is not in the type definition
          'data-testid': props['data-testid'],
          ...datePickerProps.InputProps,
        }}
      />
    </LocalizationProvider>
  )
}
