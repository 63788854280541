import { PRODUCT_APPLIED_OPTIONS, ProductAppliedOptions, RDProductAppliedPerPlan } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../ResourceDetailFeature/SelectRollupEditor'

export const ProductAppliedPerPlan: ResourceDetailFeature<RDProductAppliedPerPlan> = {
  traitId: 'productAppliedPerPlan',
  TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDProductAppliedPerPlan>) => (
    <SelectRollupEditor<RDProductAppliedPerPlan, readonly ProductAppliedOptions[]>
      options={PRODUCT_APPLIED_OPTIONS}
      detailKey="result"
      ns="@cibo/landmanager/ProductAppliedPerPlan"
      {...props}
    />
  ),
}
