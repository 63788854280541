import area from '@turf/area'
import { polygon } from '@turf/helpers'
import bbox from '@turf/bbox'
import { Polygon, MultiPolygon, Point, MultiPoint } from 'geojson'
import polylabel from 'polylabel'
import { assertBBoxArray } from './bboxTypes'
import { centerCoordinateOfBBox } from './geojson'

export const areaForCoords = (coordinates: number[][][]) => {
  const poly = polygon(coordinates)

  return area(poly)
}

export const findLargestPolygon = (multipolygon: MultiPolygon) => {
  const withAreas = multipolygon.coordinates.map(coordinates => ({
    coordinates,
    area: areaForCoords(coordinates),
  }))

  const biggest = withAreas.reduce((acc, item) => (item.area > acc.area ? item : acc))

  return { type: 'Polygon', coordinates: biggest.coordinates } as Polygon
}

export const getCoordinates = (geometry: Polygon | MultiPolygon) => {
  const { type } = geometry

  switch (type) {
    case 'MultiPolygon':
      const biggestPolygon: Polygon = findLargestPolygon(geometry as MultiPolygon)
      return biggestPolygon.coordinates

    case 'Polygon':
    default:
      return (geometry as Polygon).coordinates
  }
}

export const labelPositionForGeometry = (geometry: Polygon | MultiPolygon | Point | MultiPoint) => {
  if (geometry.type === 'Point' || geometry.type === 'MultiPoint') {
    return geometry.coordinates
  }

  const coordinates = getCoordinates(geometry)

  const poleOfInaccessibility = polylabel(coordinates)

  if (!isNaN(poleOfInaccessibility[0]) && !isNaN(poleOfInaccessibility[1])) {
    return poleOfInaccessibility
  }

  const boundingBox = bbox(geometry)
  assertBBoxArray(boundingBox)
  return centerCoordinateOfBBox(boundingBox)
}
