import { RDFertilizer } from '@cibo/core'
import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailSimpleCellProps } from '../types'

export const FertilizerSimpleCell = ({ detail }: DetailSimpleCellProps<RDFertilizer>) => {
  const { t } = useTranslation('@cibo/landmanager/FertilizerEditor')

  return (
    <>
      {detail?.input?.events.length === 0
        ? '--'
        : detail?.input?.events.map(({ product, type }, idx) => (
            <Typography key={idx} variant="body2">
              {!type || type === 'other' ? product : t(`${type}`) || '--'}
            </Typography>
          ))}
    </>
  )
}
