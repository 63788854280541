import { useState } from 'react'
import {
  Box,
  Collapse,
  IconButton,
  Paper,
  Stack,
  styled,
  Typography,
  ThemeProvider,
} from '@mui/material'
import LayersIcon from '@mui/icons-material/Layers'
import { THEME_DARK } from '@cibo/ui'

import { LayerSwitcher, LayerSwitcherProps } from './LayerSwitcher'

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  height: 52,
  svg: {
    fill: theme.palette.primary.light,
  },
  span: {
    color: theme.palette.text.primary,
  },
}))

export const ToggleableLayerSwitcher = (props: LayerSwitcherProps) => {
  const [open, setOpen] = useState(false)

  const handleClickLayersIcon = () => {
    if (!open) {
      setOpen(true)
    } else {
      setOpen(false)
    }
  }

  const LayersButton = ({ paperStyle }: { paperStyle?: any }) => {
    return (
      <ThemeProvider theme={THEME_DARK}>
        <Paper style={paperStyle}>
          <StyledIconButton aria-label="toggle layer visibility" onClick={handleClickLayersIcon}>
            <Stack direction="column" alignItems="center">
              <LayersIcon />
              <Typography variant="caption">Layers</Typography>
            </Stack>
          </StyledIconButton>
        </Paper>
      </ThemeProvider>
    )
  }

  return (
    <Box sx={{ position: 'relative' }}>
      <Collapse orientation="horizontal" in={open}>
        <Stack sx={{ pointerEvents: 'all' }} direction="row">
          <LayerSwitcher
            {...props}
            toggler={<LayersButton paperStyle={{ borderRadius: '0 4px 4px 0' }} />}
          />
        </Stack>
      </Collapse>
      <LayersButton paperStyle={{ position: 'absolute', top: 0, right: 0 }} />
    </Box>
  )
}
