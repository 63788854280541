import ClearIcon from '@mui/icons-material/Clear'
import { Box, IconButton, IconButtonProps } from '@mui/material'
import { useTranslation } from 'react-i18next'

export const CloseButton = ({ ariaLabel, ...rest }: IconButtonProps & { ariaLabel: string }) => {
  const { t } = useTranslation('@cibo/ui')
  return (
    <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
      <IconButton aria-label={ariaLabel || t('close')} {...rest}>
        <ClearIcon />
      </IconButton>
    </Box>
  )
}
