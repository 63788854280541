import { TableCell, TableHead, TableRow, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { DetailEditorPropsByYear } from '../types'
import { IrrigationEventEditor } from './IrrigationEventEditor'

import { RDEventIrrigation } from '@cibo/core'
import { EventDetailEditor } from '../EventDetail'

export const EventIrrigationEditor = ({
  fieldModels,
  year,
  name = 'eventIrrigation',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/EventIrrigationEditor')
  const EventIrrigationEventTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell sx={{ minWidth: '140px' }}>
          <Typography>{t('timing')}</Typography>
        </TableCell>
        <TableCell>
          <Typography>{t('rate')}</Typography>
        </TableCell>
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  )
  return (
    <EventDetailEditor<RDEventIrrigation['input']>
      traitId={traitId}
      fieldModels={fieldModels}
      year={year}
      name={name}
      requirement={requirement}
      filterInputs={filterInputs}
      EventTableHead={EventIrrigationEventTableHead}
      EventComponent={IrrigationEventEditor}
      translationContext="eventIrrigation"
      defaultEvent={{ startDate: null, endDate: null, amount: '', unit: '' }}
    />
  )
}
