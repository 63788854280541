import { RDCornNitrogenApplication } from '@cibo/core'
import CheckRounded from '@mui/icons-material/CheckRounded'
import DoDisturbRounded from '@mui/icons-material/DoDisturbRounded'
import { Box, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const CornNitrogenApplicationCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDCornNitrogenApplication>) => {
  const { t } = useTranslation('@cibo/landmanager/CornNitrogenApplication')
  const detail = params.row.resolveStandingDetail<RDCornNitrogenApplication>(traitId, year)

  if (!detail) return <>--</>

  const answer =
    detail.result === undefined ? '--' : !!detail.result ? t('positive') : t('negative')

  return (
    <Stack direction="row" spacing={2}>
      {detail.result ? (
        <CheckRounded fontSize="small" color="success" />
      ) : (
        <DoDisturbRounded fontSize="small" />
      )}
      <Stack>
        <Box>{answer}</Box>
        <Box color="text.secondary">
          {detail.input?.addedNitrogenStabilizer !== undefined
            ? detail.input?.addedNitrogenStabilizer
              ? t('nitrogenStabilizerAdded')
              : t('nitrogenStabilizerNotAdded')
            : null}
        </Box>
      </Stack>
    </Stack>
  )
}
