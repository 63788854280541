// @ts-nocheck I tried but the global Jimp is too much right now
import geoutils from './helper/geo'

export type TilesetConfig = {
  tileUrl: string
  tileSubdomains?: string[]
  zoomRange: {
    min: number
    max: number
  }
  tileSize: number
}

type TileAddress = {
  x: number
  y: number
  z: number
}

type TileConfig = {
  address: TileAddress
  box: [number, number, number, number]
}

export class TilesetService {
  static findTileBounds({ x, y, z }: TileAddress) {
    return [
      geoutils.xToLon(x, z),
      geoutils.yToLat(y, z),
      geoutils.xToLon(x + 1, z),
      geoutils.yToLat(y + 1, z),
    ]
  }

  static urlForTile(tilesetConfig: TilesetConfig, address: TileAddress) {
    const { x, y, z } = address

    let tileUrl
    if (tilesetConfig.tileUrl.includes('{quadkey}')) {
      const quadKey = geoutils.tileXYToQuadKey(x, y, z)
      tileUrl = tilesetConfig.tileUrl.replace('{quadkey}', quadKey)
    } else {
      tileUrl = tilesetConfig.tileUrl
        .replace('{z}', `${z}`)
        .replace('{x}', `${x}`)
        .replace('{y}', `${y}`)
    }

    if (tilesetConfig.tileSubdomains && tilesetConfig.tileSubdomains.length > 0) {
      // replace subdomain with random domain from tileSubdomains array
      tileUrl = tileUrl.replace(
        '{s}',
        tilesetConfig.tileSubdomains[
          Math.floor(Math.random() * tilesetConfig.tileSubdomains.length)
        ]
      )
    }

    return tileUrl
  }

  static getChildBounds(
    parentAddress: TileAddress,
    childAddress: TileAddress
  ): { x: number; y: number; dimension: number } {
    if (parentAddress.z > childAddress.z) throw new Error('parent must include child')
    if (parentAddress.z === childAddress.z) return { x: 0, y: 0, dimension: 1 }

    const divisions = 2 ** (childAddress.z - parentAddress.z)

    const baseAddressAtChildZ = {
      x: parentAddress.x * divisions,
      y: parentAddress.y * divisions,
      z: childAddress.z,
    }

    const xOffset = childAddress.x - baseAddressAtChildZ.x
    const yOffset = childAddress.y - baseAddressAtChildZ.y

    const dimension = 1 / divisions

    return {
      x: xOffset * dimension,
      y: yOffset * dimension,
      dimension,
    }
  }

  /**
   *  Fetching tile from endpoint
   */
  static async getTile(tilesetConfig: TilesetConfig, tileConfig: TileConfig) {
    const { address } = tileConfig
    const { x, y, z } = address

    const { lonToX, latToY, xToLon, yToLat } = geoutils
    const maxZoom = tilesetConfig.zoomRange.max

    if (z > maxZoom) {
      const parentAddress = {
        x: Math.floor(lonToX(xToLon(x, z), maxZoom)),
        y: Math.floor(latToY(yToLat(y, z), maxZoom)),
        z: maxZoom,
      }

      const tileUrl = TilesetService.urlForTile(tilesetConfig, parentAddress)

      const childBounds = TilesetService.getChildBounds(parentAddress, address)

      return Jimp.read(tileUrl)
        .then(async (image: typeof Jimp) => {
          const { tileSize } = tilesetConfig
          await image.crop(
            Math.floor(childBounds.x * tileSize),
            Math.floor(childBounds.y * tileSize),
            Math.floor(childBounds.dimension * tileSize),
            Math.floor(childBounds.dimension * tileSize)
          )

          await image.resize(tileSize, tileSize, Jimp.RESIZE_NEAREST_NEIGHBOR)

          return {
            success: true,
            tile: {
              url: tileUrl,
              box: tileConfig.box,
              image,
            },
          }
        })
        .catch((error: Error) => {
          return {
            success: false,
            error,
          }
        })
    }

    const tileUrl = TilesetService.urlForTile(tilesetConfig, address)

    return Jimp.read(tileUrl)
      .then((image: typeof Jimp) => {
        return {
          success: true,
          tile: {
            url: tileUrl,
            box: tileConfig.box,
            image,
          },
        }
      })
      .catch((error: Error) => {
        return {
          success: false,
          error,
        }
      })
  }

  /**
   *  Fetching tiles and limit concurrent connections
   */
  static async getTiles(tilesetConfig: TilesetConfig, tileConfigs: TileConfig[]) {
    const tilePromises: Promise<TileResult>[] = []
    tileConfigs.forEach(tileConfig => {
      tilePromises.push(TilesetService.getTile(tilesetConfig, tileConfig))
    })
    return Promise.all(tilePromises)
  }
}

type TileResult = {
  success: boolean
  tile: any
  error: Error
}
