import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

import {
  REQUEST_ERROR_NEED_SUBSCRIPTION,
  REQUEST_ERROR_OVER_QUOTA,
  REQUEST_ERROR_UNAUTHORIZED,
} from '@cibo/core'
import { WithActionsProps, withActions } from '@cibo/ui'
import {
  selectAuthDirectLoginLoading,
  selectAuthToken,
  selectAuthUserIsLoggedIn,
} from '../../store'

import { useQueryClient } from '@tanstack/react-query'
import { AuthCheck } from './AuthCheck'

const AuthCheckContainer = ({ subscribeAction }: WithActionsProps) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const jwt = useSelector(selectAuthToken)
  const loginPending = useSelector(selectAuthDirectLoginLoading)
  const isLoggedIn = useSelector(selectAuthUserIsLoggedIn)

  useEffect(() => {
    if (!isLoggedIn) {
      queryClient.clear()
    }
  }, [isLoggedIn])

  const authFlow = (reason: string) => () => {
    if (reason !== REQUEST_ERROR_UNAUTHORIZED) {
      if (!loginPending && !isLoggedIn) {
        navigate('/login', { state: { modal: true, prompt } })
      }
    }
  }

  useEffect(() => {
    subscribeAction(REQUEST_ERROR_UNAUTHORIZED, authFlow)
    subscribeAction(REQUEST_ERROR_NEED_SUBSCRIPTION, authFlow)
    subscribeAction(REQUEST_ERROR_OVER_QUOTA, authFlow)
  }, [])

  return <AuthCheck jwt={jwt as string} />
}

export default withActions(AuthCheckContainer)
