import { RadioBooleanField } from '@cibo/ui'
import { FormControl, FormLabel, Stack } from '@mui/material'
import { FieldProps, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../../types'
import { RDByrCoverCrops } from './types'

export const ByrCoverCropsEditor = ({
  filterInputs,
  fieldModels,
  name = 'byrCoverCropsEditor',
  requirement,
}: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/ByrCoverCrops')

  return (
    <FormikField name={name} id={name}>
      {({ field }: FieldProps<RDByrCoverCrops>) => (
        <Stack spacing={2}>
          <FormControl>
            <FormLabel required>{t('cta')}</FormLabel>
            <RadioBooleanField name={`${name}.result`} data-testid={`${name}.result`} />
          </FormControl>
        </Stack>
      )}
    </FormikField>
  )
}
