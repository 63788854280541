import { AxiosResponse } from 'axios'

export const downloadAxiosResponse =
  (defaultFileName = 'download.txt') =>
  (response: AxiosResponse | void) => {
    if (!response) return

    if (typeof response?.data === 'string') {
      if (!import.meta.env.PROD) {
        console.error("Received string data. Set axios option { responseType: 'blob' }")
      }
      throw Error('incorrect response type')
    }

    // create file link in browser's memory
    const href = URL.createObjectURL(response.data)
    const contentDispostion = response.headers['content-disposition'] as string
    const fileName = contentDispostion?.match(/filename="(.*)"/)?.[1]

    // create "a" HTML element with href to file & click
    const link = document.createElement('a')
    link.href = href
    link.setAttribute('download', !!fileName ? fileName : defaultFileName)
    document.body.appendChild(link)
    link.click()

    try {
      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    } catch (error) {
      console.error('failed to remove child', error)
    }
  }
