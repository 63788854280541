import { ResourceDetailYearly } from '@cibo/core'

export const FSA_FILES_INFO = [
  'registeredUpToDate',
  'notRegistered',
  'registeredOutOfDate',
] as const
export type FsaFilesInfo = typeof FSA_FILES_INFO[number]

export const BUSINESS_STRUCTURE = ['individual', 'entity'] as const
export type BusinessStructure = typeof BUSINESS_STRUCTURE[number]

export type FarmInformation = {
  businessStructure: BusinessStructure
  directDepositForm?: boolean
  fsaFilesInfo: FsaFilesInfo
}

// @ts-ignore This detail uses value: but that may mean deep changes to our Input/Result types
export interface EqipFarmInformation extends ResourceDetailYearly<never, never> {
  traitId: 'eqipFarmInformation'
  value: FarmInformation
}
