import { RDEqipPracticesSoilErosionEphemeralGully } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipPracticesSoilErosionEphemeralGullyResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSoilErosionEphemeralGully> =
  {
    traitId: 'eqipPracticesSoilErosionEphemeralGully',
    TaskEditor: (
      props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSoilErosionEphemeralGully>
    ) => (
      <BooleanRollup<RDEqipPracticesSoilErosionEphemeralGully>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' }, unknown: { value: 'unknown' } }}
        detailKey="ephemeralGully"
        ns="@cibo/landmanager/RDEqipPracticesSoilErosionEphemeralGullyResourceDetailFeature"
        {...props}
      />
    ),
  }
