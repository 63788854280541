import { ResourceDetail } from '@cibo/core'
import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TraitCellEditorProps } from '../../types'

type EditorProgram = 'cspEnrolled' | 'crpEnrolled' | 'acepEnrolled'

const answerFromDetail = (editorProgram: EditorProgram, detail?: ResourceDetail) => {
  return detail?.result === undefined ? '' : detail?.result[editorProgram]
}

export const ConservationProgramsCellEditor =
  (editorProgram: EditorProgram) => (props: TraitCellEditorProps) =>
    <ConservationProgramsCellEditorWithoutProgram {...props} editorProgram={editorProgram} />

const ConservationProgramsCellEditorWithoutProgram = ({
  editorProgram,
  params,
  removeDetail,
  saveDetail,
  traitId,
  year,
}: { editorProgram: EditorProgram } & TraitCellEditorProps) => {
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(editorProgram, detail))
  const { t } = useTranslation('@cibo/landmanager/ConservationPrograms')

  useEffect(() => {
    setAnswer(answerFromDetail(editorProgram, detail))
  }, [detail])

  const handleChange = (_: any, value: any) => {
    if (value !== null) {
      setAnswer(value)

      if (value === '') {
        removeDetail({
          resourceId: params.row.resourceId,
          traitId,
          year,
        })
      } else {
        saveDetail({
          resourceId: params.row.resourceId,
          details: [{ traitId, year, input: { [editorProgram]: value } }],
        })
      }
    }
  }

  return (
    <ToggleButtonGroup value={answer} exclusive onChange={handleChange} aria-label="Platform">
      {['yes', 'no', 'unknown'].map(answer => (
        <ToggleButton key={answer} value={answer} aria-label={t(`editorAnswer_${answer}`)}>
          {t(`editorAnswer_${answer}`)}
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  )
}
