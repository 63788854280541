import {
  EQIP_IRRIGATION_QUANTITY_OPTIONS,
  RDEqipPracticesIrrigationQuantity,
  RDEqipPracticesIrrigationQuantityInput,
  TraitId,
} from '@cibo/core'
import { object, string } from 'yup'

import { ITraitFeatureByIdYear } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { EqipIrrigationQuantityCell } from './EqipIrrigationQuantityCell'
import { EqipIrrigationQuantityCellEditor } from './EqipIrrigationQuantityCellEditor'
import { EqipIrrigationQuantityEditor } from './EqipIrrigationQuantityEditor'
import { EqipIrrigationQuantityTableCta } from './EqipIrrigationQuantityTableCta'

const TRAIT_ID: TraitId = 'eqipPracticesIrrigationQuantity'

export const EqipIrrigationQuantity: ITraitFeatureByIdYear<RDEqipPracticesIrrigationQuantity> = {
  traitId: TRAIT_ID,
  cellDisplay: EqipIrrigationQuantityCell,
  editor: EqipIrrigationQuantityEditor,
  cellEditor: EqipIrrigationQuantityCellEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail<RDEqipPracticesIrrigationQuantity>(TRAIT_ID)(props)
    return (
      detail?.input ||
      ({ irrigationQuantity: '' } as unknown as RDEqipPracticesIrrigationQuantityInput)
    )
  },
  ns: '@cibo/landmanager/EqipIrrigationQuantity',
  validationSchema: ({ t }) =>
    object({
      irrigationQuantity: string().oneOf(EQIP_IRRIGATION_QUANTITY_OPTIONS).required(t('required')),
    }).required(t('required')),

  tableCta: EqipIrrigationQuantityTableCta,
}
