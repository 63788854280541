import { styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import { DataGridPro, DataGridProProps } from '@mui/x-data-grid-pro'

const StyledDataGridProp = styled(DataGridPro)(({ theme }) => ({
  border: 'none',
  boxShadow: 'none',
  '& .MuiDataGrid-columnHeaderTitleContainer': {
    padding: 0,
  },
  '& .MuiDataGrid-columnHeaders': {
    backgroundColor: theme.palette.primary.dark,
    '& .MuiButtonBase-root': {
      color: theme.palette.primary.contrastText,
    },
    '& .MuiDataGrid-columnHeader--dragging': {
      backgroundColor: theme.palette.primary.light,
    },
  },
  '& .MuiDataGrid-columnHeaderTitle': {
    color: grey[100],
    textTransform: 'uppercase',
    fontSize: 10,
    lineHeight: '12px',
    fontWeight: '700 !important',
  },
  '& .MuiDataGrid-columnSeparator': {
    display: 'none',
  },
}))

interface DenseDataGridProps extends DataGridProProps {
  disableCellFocus?: boolean
}

export const DenseDataGrid = ({ disableCellFocus, sx, ...dataGridProps }: DenseDataGridProps) => {
  return (
    <StyledDataGridProp
      autoHeight
      density="compact"
      hideFooter
      disableColumnResize
      disableColumnMenu
      disableRowSelectionOnClick
      slots={{}}
      sx={{
        '.MuiDataGrid-row': {
          cursor: !!dataGridProps.onRowClick ? 'pointer' : undefined,
        },
        ...(!!disableCellFocus
          ? {
              '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
                outline: 'none !important',
              },
            }
          : {}),
        ...sx,
      }}
      editMode="row"
      {...dataGridProps}
    />
  )
}
