import { RDUsdaFederalProgramEnrollment } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../BooleanRollup'

export const RDUsdaFederalProgramEnrollmentResourceDetailFeature: ResourceDetailFeature<RDUsdaFederalProgramEnrollment> =
  {
    traitId: 'usdaFederalProgramEnrollment',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDUsdaFederalProgramEnrollment>) => (
      <BooleanRollup<RDUsdaFederalProgramEnrollment>
        answerValues={{ yes: { value: true }, no: { value: false } }}
        ns="@cibo/landmanager/UsdaFederalProgramEnrollment"
        {...props}
      />
    ),
  }
