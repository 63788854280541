import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Divider, Skeleton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { RDEqipAutogenRequiredForms } from './types'
import { useRequiredDocuments } from './useRequiredDocuments'

export const RDEqipAutogenRequiredFormsTaskEditor = ({
  detailRequirements,
  engagementId,
  resourceIds: [resourceId],
  task,
}: ResourceDetailFeatureTaskEditorProps<RDEqipAutogenRequiredForms>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipAutogenRequiredFormsTaskEditor')

  const forms = useRequiredDocuments({ engagementId, taskId: task?.id })

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
        <Typography variant="h6">{t('title')}</Typography>
      </Stack>

      <Stack divider={<Divider />}>
        {!forms.isPending
          ? forms.data?.map(item => {
              const {
                sys: { id },
                fields: { asset, title },
              } = item

              return (
                <Stack key={id} mt={1} mb={1}>
                  <Typography variant="body1">{title}</Typography>
                  <Typography variant="body2" color="text.secondary">
                    {asset?.fields.title}
                  </Typography>
                </Stack>
              )
            })
          : Array(3)
              .fill(0)
              .map((_, index) => [
                <Stack key={index} mt={1} mb={1}>
                  <Typography variant="body1">
                    <Skeleton />
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    <Skeleton />
                  </Typography>
                </Stack>,
              ])}
      </Stack>
    </Stack>
  )
}
