import { BASE_SATELLITE_TILE, BASE_SATELLITE_TILE_MAX_ZOOM } from '@cibo/ui'
import { Layer, Source } from 'react-map-gl/dist/es5'

export const SatelliteLayer = ({ show }: { show?: boolean }) => {
  return (
    <Source
      id="arcgis"
      type="raster"
      tiles={[BASE_SATELLITE_TILE]}
      tileSize={256}
      maxzoom={BASE_SATELLITE_TILE_MAX_ZOOM}
    >
      <Layer
        beforeId="tunnel-street-minor-low"
        id="ArcGISTiles"
        source="arcgis"
        type="raster"
        layout={{
          visibility: !!show ? 'visible' : 'none',
        }}
        paint={{
          'raster-opacity': [
            'interpolate',
            ['cubic-bezier', 0.2, 0, 0.7, 0],
            ['zoom'],
            10,
            0.5,
            12,
            1,
          ],
        }}
      />
    </Source>
  )
}
