import {
  ClickableCard,
  ClickableCardProps,
  ContentfulBackgroundImage,
  intuitiveFromNow,
} from '@cibo/ui'
import { CardContent, Stack, Typography } from '@mui/material'
import { grey } from '@mui/material/colors'
import { Box } from '@mui/system'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramStatusCardStatus } from './types'

const STATUS_COLOR: Record<ProgramStatusCardStatus, string> = {
  under_review: grey[600],
  active: 'success.main',
  action_required: 'warning.main',
  disqualified: 'error.main',
  completed: 'info.main',
  in_progress: 'warning.main',
}

interface ProgramStatusCardDisplayProps extends ClickableCardProps {
  date?: string
  fieldCount: number
  logoContent: any
  status: ProgramStatusCardStatus
  title: string
  children?: ReactNode
}

export const ProgramStatusCardDisplay = ({
  children,
  date,
  fieldCount,
  logoContent,
  status,
  title,
  ...clickableCardProps
}: ProgramStatusCardDisplayProps) => {
  const { t } = useTranslation('@cibo/programs/ProgramStatusCard')

  return (
    <ClickableCard elevation={2} {...clickableCardProps}>
      <Box sx={{ backgroundColor: STATUS_COLOR[status], paddingInline: 2 }} alignSelf="stretch">
        <Typography variant="overline" color="white">
          {t(status)}
          {date && ` ${intuitiveFromNow(date, true)}`}
        </Typography>
      </Box>

      <CardContent component={Stack} spacing={2} sx={{ paddingTop: 1, paddingBottom: 2 }}>
        <Stack direction="row" alignItems="flex-end" spacing={2}>
          <ContentfulBackgroundImage
            height={64}
            width={64}
            fields={logoContent}
            backgroundSize="contain"
            preferTransparency
          />
          <Typography variant="h6">{title}</Typography>
        </Stack>

        {!!fieldCount && (
          <Stack spacing={2}>
            <Typography>{t('fields', { count: fieldCount })}</Typography>
          </Stack>
        )}
        {children}
      </CardContent>
    </ClickableCard>
  )
}
