import { Button, Stack, styled, useTheme } from '@mui/material'
import { PropsWithChildren, useEffect, useState } from 'react'

const SwatchButton = styled(Button)(() => ({
  borderRadius: 0,
  minWidth: 25,
  width: 25,
  height: 25,
  borderStyle: 'solid',
  borderWidth: 3,
}))

export interface ColorPickerProps {
  colors: string[]
  disabled?: boolean
  isValid?: boolean
  onChange(value: string): void
  value?: string
}

export const ColorPickerInput = ({
  children,
  colors,
  disabled,
  isValid = true,
  onChange,
  value,
  ...rest
}: PropsWithChildren<ColorPickerProps>) => {
  const { palette } = useTheme()
  const [selectedColor, setSelectedColor] = useState(value)

  useEffect(() => {
    setSelectedColor(value)
  }, [value])

  const handlePressColor = (color: string) => {
    setSelectedColor(color)
    onChange(color)
  }

  const selectedBorderColor = isValid ? palette.primary.main : palette.error.main

  return (
    <>
      <Stack direction="row" spacing={0.75}>
        {colors.map(color => (
          <SwatchButton
            key={color}
            style={{
              backgroundColor: color,
              borderColor: color === selectedColor ? selectedBorderColor : 'transparent',
            }}
            disabled={disabled}
            onClick={() => handlePressColor(color)}
          />
        ))}
      </Stack>
      {children}
    </>
  )
}
