import { RDUsdaGrowerType } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { MulticheckEditor } from '../../MulticheckEditor'

const keys = ['limitedResources', 'beginnerFarmer', 'sociallyDisadvantaged', 'veteran', 'none']

export const RDUsdaGrowerTypeTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<RDUsdaGrowerType>
) => {
  return (
    <MulticheckEditor
      keys={keys}
      ns="@cibo/profile/RDUsdaGrowerTypeTaskEditor"
      detailKey="usdaGrowerType"
      {...props}
    />
  )
}
