import { FormControlLabel, Radio, RadioGroup, RadioGroupProps } from '@mui/material'
import { Field, useField, useFormikContext } from 'formik'
import { ChangeEvent, useCallback } from 'react'
import { useTranslation } from 'react-i18next'

interface RadioBooleanFieldProps extends RadioGroupProps {
  name: string
  disabled?: boolean
}

export const RadioBooleanField = ({ name, ...props }: RadioBooleanFieldProps) => {
  const { t } = useTranslation('@cibo/ui/RadioBooleanField')
  const { isSubmitting } = useFormikContext()

  const field = useField(name)
  const meta = field[1]
  const helpers = field[2]
  const { value } = meta
  const { setValue, setTouched } = helpers

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>, value: string) => {
      setTouched(true)
      setValue(value)
    },
    [setValue, setTouched]
  )

  return (
    <Field name={name}>
      {() => {
        return (
          <RadioGroup value={typeof value !== 'undefined' ? value : ''} onChange={handleChange} row>
            <FormControlLabel
              value={'false'}
              control={<Radio />}
              label={`${t('no')}`}
              disabled={props.disabled || isSubmitting}
              data-testid={`${name}::no`}
              name={`${name}::no`}
            />
            <FormControlLabel
              value={'true'}
              control={<Radio />}
              label={`${t('yes')}`}
              disabled={props.disabled || isSubmitting}
              data-testid={`${name}::yes`}
              name={`${name}::yes`}
            />
          </RadioGroup>
        )
      }}
    </Field>
  )
}
