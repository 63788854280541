import { RDFutureTillage, RegenerativeTillage } from '@cibo/core'
import * as Yup from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FutureTillageCell } from './FutureTillageCell'
import { FutureTillageCellEditor } from './FutureTillageCellEditor'
import { FutureTillageEditor } from './FutureTillageEditor'

const TRAIT_ID = 'futureTillage'

export const FutureTillageInput: ITraitFeatureByIdYear<RDFutureTillage> = {
  traitId: TRAIT_ID,
  editor: FutureTillageEditor,
  cellDisplay: FutureTillageCell,
  ns: '@cibo/landmanager/FutuerTillageEditor',
  validationSchema: ({ t }) =>
    Yup.object()
      .shape({
        type: Yup.string<RegenerativeTillage>()
          .required(t('required', { context: 'type' }))
          .typeError(t('required', { context: 'type' })),
      })
      .required(t('required')),

  initialValues: request => {
    const detail = findInitialValuesDetail<RDFutureTillage>(TRAIT_ID)(request)

    return detail?.input || ({ type: detail?.result || '' } as RDFutureTillage['input'])
  },
}

export const FutureTillageResult: ITraitFeatureByIdYear<RDFutureTillage> = {
  traitId: TRAIT_ID,
  editor: FutureTillageEditor,
  cellDisplay: FutureTillageCell,
  cellEditor: FutureTillageCellEditor,
  ns: '@cibo/landmanager/FutuerTillageEditor',
  validationSchema: ({ t }) =>
    Yup.string<RegenerativeTillage>()
      .required(t('required', { context: 'type' }))
      .typeError(t('required', { context: 'type' })),

  initialValues: request => {
    const detail = findInitialValuesDetail<RDFutureTillage>(TRAIT_ID)(request)

    return detail?.result || ('' as RDFutureTillage['result'])
  },
}
