import BugReportRounded from '@mui/icons-material/BugReportRounded'
import { LoadingButton, LoadingButtonProps } from '@mui/lab'
import { AuthUserPermission } from '../../types'
import { Can } from '../Can'

export const DebugButton = (props: LoadingButtonProps) => {
  return (
    <Can useAny={AuthUserPermission.DEBUG_USER}>
      <LoadingButton
        color="secondary"
        variant="outlined"
        {...props}
        startIcon={<BugReportRounded color="error" />}
      />
    </Can>
  )
}
