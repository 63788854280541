import { RadioBooleanField } from '@cibo/ui'
import { Stack } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../types'

export const WetlandDesignationEditor = ({
  fieldModels,
  name = 'wetlandDesignationEditor',
}: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/WetlandDesignationEditor')
  return (
    <Stack spacing={2}>
      <Stack>
        {/* {overrideWarning} */}
        <InputLabel htmlFor={`${name}.overlaysWetland`}>
          {t('overlaysWetland', { count: fieldModels.length })}
        </InputLabel>
        <RadioBooleanField name={`${name}.overlaysWetland`} />
      </Stack>
    </Stack>
  )
}
