import { useTheme } from '@mui/material'
import { ExtendedTheme } from '@cibo/ui'

export const InfoNeededCorner = () => {
  const { palette } = useTheme<ExtendedTheme>()

  return (
    <svg style={{ position: 'absolute', top: 0, left: 0 }} width={16} height={16}>
      {/* @ts-ignore */}
      <path fill={palette.warning.main} d="M 0 0 L 16 0 L 0 16 Z" />
    </svg>
  )
}
