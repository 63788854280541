import { Stack, Typography } from '@mui/material'
import { TraitCellDisplayProps } from '../types'

export const FarmManagementSystemCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.source === 'cibo') {
    return (
      <Stack>
        <Typography
          sx={{
            overflow: 'hidden',
            textOverflow: 'ellipsis',
          }}
        >
          {detail.result}
        </Typography>
      </Stack>
    )
  } else {
    return (
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {detail.result}
      </Typography>
    )
  }
}
