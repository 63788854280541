export enum CropType {
  CORN = 'corn',
  SOYBEANS = 'soybeans',
  COTTON = 'cotton',
  WHEAT = 'wheat',
  OATS = 'oats',
}
export enum ManagementPracticeType {
  LOW_TILLAGE = 'lowTillage',
  COVER_CROP = 'coverCrop',
  REDUCED_FERTILIZER = 'reducedFertilizer',
  ROTATION = 'rotation',
}
export enum TillageType {
  CONVENTIONAL_TILLAGE = 'conventionalTillage',
  NO_TILL = 'noTill',
  CONSERVATION_TILLAGE = 'conservationTillage',
}
