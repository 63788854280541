import { RDAgronomicDataCSV } from '@cibo/core'

import * as Yup from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { AgronomicDataCSVCell } from './AgronomicDataCSVCell'
import { AgronomicDataCSVCellEditor } from './AgronomicDataCSVCellEditor'
import { AgronomicDataCSVEditor } from './AgronomicDataCSVEditor'

export const AgronomicDataCSV: ITraitFeatureByIdYear<RDAgronomicDataCSV> = {
  traitId: 'agronomicDataCsv',
  initialValues: request =>
    findInitialValuesDetail<RDAgronomicDataCSV>('agronomicDataCsv')(request)?.input,

  cellDisplay: AgronomicDataCSVCell,
  cellEditor: AgronomicDataCSVCellEditor,
  editor: AgronomicDataCSVEditor,
  forbidBulkEdit: true,
  forbidCellEdit: true,
  ns: '@cibo/landmanager/AgronomicDataCSVEditor',
  // @ts-ignore Users don't directly edit the input values, rather this editor allows for uploading a file
  validationSchema: () => Yup.object().nullable(),
}
