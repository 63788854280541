import { RDNativeConversion } from '@cibo/core'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const NativeConversionCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDNativeConversion>) => {
  const { t } = useTranslation('@cibo/landmanager/NativeConversionEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Stack>
      <Typography>
        {/* result is 'no' when converted is 'unsure' 
        AND  
        result is 'unsure' when converted is 'yes' and 'unsureOfYear' is true */}
        {detail.result === 'no' && detail.input?.converted === 'unsure'
          ? t('unsure')
          : detail.result === 'no' && detail.input?.converted === 'no'
          ? t('greaterThanTwentyYearsAgo')
          : detail.input?.conversionYear
          ? t('convertedInYear', { year: detail.input?.conversionYear })
          : t('lessThanTwentyYearsAgo')}
      </Typography>
      {detail.input?.converted === 'yes' && !!detail.input?.nativeVegetation && (
        <Typography variant="caption">
          {t(`originalUse_${detail.input?.nativeVegetation}`)}
        </Typography>
      )}
    </Stack>
  )
}
