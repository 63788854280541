import { LivestockSpecies, RDGrazing } from '@cibo/core'
import { date, number, object, string } from 'yup'

import { pathEq } from 'ramda'
import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { GrazingCellDisplay } from './GrazingCellDisplay'
import { GrazingEditor } from './GrazingEditor'
import { GrazingRollup } from './GrazingRollup'
import { GrazingSimpleCell } from './GrazingSimpleCell'

const TRAIT_ID = 'grazing'

export const Grazing: ITraitFeatureByIdYear<RDGrazing> = {
  traitId: TRAIT_ID,
  cellDisplay: GrazingCellDisplay,
  SimpleCellView: GrazingSimpleCell,
  editor: GrazingEditor,
  forbidBulkEdit: false,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: GrazingRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {},
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  ns: '@cibo/landmanager/GrazingEditor',
  //@ts-ignore For some reason it's yelling about RDGrazing['input'] not being assignable to undefined, but it also doesn't like that
  validationSchema: eventValidationSchema<RDGrazing['input']>(({ t }) =>
    object().shape({
      startDate: date()
        .required(t('errorRequired', { context: 'dateRange' }))
        .typeError(t('errorRequired', { context: 'dateRange' })),
      endDate: date()
        .required(t('errorRequired', { context: 'dateRange' }))
        .typeError(t('errorRequired', { context: 'dateRange' })),
      species: string<LivestockSpecies>()
        .required(t('errorRequired', { context: 'species' }))
        .typeError(t('errorRequired', { context: 'species' })),
      stockingRate: number()
        .min(0.01, t('errorMin', { context: 'stockingRate' }))
        .required(t('errorRequired', { context: 'stockingRate' }))
        .typeError(t('errorRequired', { context: 'stockingRate' })),
    })
  ),
}
