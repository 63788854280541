import { EQIP_PRACTICE_CHOICE_WILDLIFE, RDEqipPracticeChoiceWildlife } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { WorkflowDetailChoiceSelection } from '../../../../components/WorkflowDetailChoiceSelection'

export const RDEqipPracticeChoiceWildlifeResourceDetailFeature: ResourceDetailFeature<RDEqipPracticeChoiceWildlife> =
  {
    traitId: 'eqipPracticeChoiceWildlife',
    TaskEditor: props => (
      <WorkflowDetailChoiceSelection {...props} choices={EQIP_PRACTICE_CHOICE_WILDLIFE} />
    ),
  }
