import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../BooleanRollup'
import { RDCottonNitrogenReduction } from './types'

export const RDCottonNitrogenReductionResourceDetailFeature: ResourceDetailFeature<RDCottonNitrogenReduction> =
  {
    traitId: 'cottonNitrogenReduction',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDCottonNitrogenReduction>) => (
      <BooleanRollup<RDCottonNitrogenReduction>
        answerValues={{ yes: { value: true }, no: { value: false } }}
        ns="@cibo/landmanager/CottonNitrogenReduction"
        {...props}
      />
    ),
  }
