/* istanbul ignore file */
import { Feature, featureCollection, point } from '@turf/helpers'
import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { MultiPolygon, Point, Polygon } from 'geojson'
import queryString from 'query-string'
import { assocPath, flatten, pick, pipe, splitEvery } from 'ramda'
import {
  Field,
  MpxListResponse,
  PaginationResponse,
  ResourceDetail,
  ResourceDetailsUpdate,
  SortModel,
} from '../types'
import {
  AssignFieldsRequestParams,
  ConfirmImportsRequest,
  CreateFieldRequest,
  FieldFDRStatsResponse,
  FieldGeoJSONQueryOptions,
  FieldMetadataUpdateRequest,
  FieldQueryOptions,
  FieldQuerySortFields,
  FieldResponse,
  FieldShapefilesQueryOptions,
  FieldsResponse,
  FieldsStatsQueryOptions,
  LocalLedgerFieldsRequest,
} from '../types/FieldQueryTypes'
import { MpxResponseBase, MpxResponseBaseSingle } from '../types/MpxTypes'
import { assertBBoxArray, generatePath, labelPositionForGeometry } from '../utils'
import { getMpAgent } from './utils'
import { queueRequest } from './utils/requestQueue'

const mpAgent = getMpAgent()

const PATHS = {
  ASSIGN: '/fields/assign',
  BATCH_DETAILS: '/fields/batchDetails',
  CONFIRM_IMPORT: '/fields/importFromScratch',
  FIELD: '/fields/:resourceId',
  FIELDS_QUERY_SHAPEFILE: '/fields/query/shapefile',
  FIELDS_QUERY: '/fields/query',
  FIELDS: '/fields',
  FORCE_UPDATE: '/fields/:resourceId/forceUpdate',
  GEOJSON: '/fields/query/geojson',
  LOCAL_LEDGER: '/fields/ledgerFieldsInViewport',
  METADATA: '/fields/metadata',
  STATS: '/fields/query/stats',
  UPLOAD: '/fields/:resourceId/detail/file',
}

const pickStatus = (statuses?: string) => {
  if (!statuses) return
  if (statuses.includes('enrolled')) return 'enrolled'
  if (statuses.includes('qualified')) return 'qualified'
  return statuses[0]
}

type FieldMapFeature = Feature<Point | Polygon | MultiPolygon, any>

export class FieldsAPI {
  static ErrorMessages = {
    DUPLICATE_FIELD_NAME:
      'Failed to preprocess inputs for trait name Found field with duplicate name in field group.',
  }

  static assign(request: AssignFieldsRequestParams) {
    return mpAgent.patch(PATHS.ASSIGN, request)
  }

  static create(request: CreateFieldRequest) {
    return mpAgent
      .post<MpxResponseBaseSingle<Field>>(generatePath(PATHS.FIELDS), {
        ...pick(['details', 'metadata', 'owner'], request),
        targetCollectionId: request.space,
      })
      .then(response => response && response.data)
  }

  static bulkUpdateFields(resourceIds: string[], details: ResourceDetail[]) {
    return mpAgent
      .patch<MpxListResponse<Field>>(generatePath(PATHS.FIELDS), {
        resourceIds,
        details,
      })
      .then(response => response && response.data)
  }

  static async fields(resourceIds?: string[], queryOptions?: FieldQueryOptions, options?: any) {
    const { sort, ...otherParams } = queryOptions || {}

    const getBatch = (batchParams?: FieldQueryOptions) =>
      mpAgent
        .get<FieldsResponse>(PATHS.FIELDS, {
          params: {
            ...batchParams,
            ...otherParams,
            searchOption: 'all',
            sort: !!sort ? sort.map(({ sort, field }) => `${sort}:${field}`).join() : undefined,
          },
          paramsSerializer: p => queryString.stringify(p),
          ...options,
        })
        .then(response => response && response.data.items)

    if (!resourceIds) {
      return getBatch(queryOptions)
    }

    const batches = splitEvery(25, resourceIds)

    //@ts-ignore
    const responses: Field[][] = await Promise.all(
      batches.map(batchIds => getBatch({ resourceIds: batchIds.join(','), ...queryOptions }))
    )

    return flatten(responses)
  }

  static paginatedFields(params?: FieldQueryOptions, config?: AxiosRequestConfig) {
    return mpAgent
      .post<PaginationResponse<Field, FieldQueryOptions>>(
        PATHS.FIELDS_QUERY,
        FieldsAPI.transformFieldQueryParams(
          params ? { searchOption: 'all', ...params } : { searchOption: 'all' }
        ),
        config
      )
      .then(
        response =>
          response && {
            ...response.data,
            params,
          }
      )
  }

  /**
   * similar to, but not exactly like `pagintedFields`.  Specifically
   * this endpoint doesn't support pagination
   */
  static shapefiles(params: FieldShapefilesQueryOptions, config?: AxiosRequestConfig) {
    const {
      grower,
      programs = [],
      owner,
      prgGlobalSearchStatus,
      prgGlobalSearchStatusProgramId,
      ...passthroughParams
    } = params || {}

    return mpAgent.post<any>(
      PATHS.FIELDS_QUERY_SHAPEFILE,
      {
        ...passthroughParams,
        owner: !!owner ? `${owner}` : undefined,
        grower: grower && grower !== '' ? grower : undefined,
        programs: programs.length === 0 ? undefined : programs,
        prgGlobalSearchStatus: !!prgGlobalSearchStatus?.length
          ? prgGlobalSearchStatus.join(',')
          : undefined,
        prgGlobalSearchStatusProgramId,
        // paramsSerializer: p => queryString.stringify(p),
      },
      config
    )
  }

  static localLedgerFields({ lowerLeft, upperRight }: LocalLedgerFieldsRequest) {
    return mpAgent
      .get<PaginationResponse<FieldMapFeature>>(PATHS.LOCAL_LEDGER, {
        params: {
          lowerLeft: lowerLeft?.join(),
          upperRight: upperRight?.join(),
        },
      })
      .then(response => {
        if (response.data) {
          response.data.items.forEach(item => assertBBoxArray(item.bbox))

          return featureCollection(response.data.items)
        }
        return null
      })
  }

  static geoJSONSearch(
    params?: Omit<FieldGeoJSONQueryOptions, 'resourceIds'>,
    skipPointVersions = false
  ) {
    const {
      sort,
      grower,
      programState,
      programStatus,
      programPhase,
      lowerLeft,
      upperRight,
      prgGlobalSearchStatus,
      prgGlobalSearchStatusProgramId,
      /* eslint-disable @typescript-eslint/no-unused-vars */
      // @ts-ignore geojson endpoint does not accept this
      resourceIds,
      /* eslint-enable */
      ...passthroughParams
    } = params || {}

    return mpAgent
      .post<PaginationResponse<FieldMapFeature>>(PATHS.GEOJSON, {
        ...passthroughParams,
        grower: grower && grower !== '' ? grower : undefined,
        programPhase: !!programPhase ? programPhase.join() : undefined,
        programState: !!programState ? programState.join() : undefined,
        programStatus: !!programStatus ? programStatus.join() : undefined,
        sort: FieldsAPI.transformQuerySortParam(sort),
        lowerLeft: lowerLeft?.join(),
        upperRight: upperRight?.join(),
        prgGlobalSearchStatus: !!prgGlobalSearchStatus?.length
          ? prgGlobalSearchStatus.join(',')
          : undefined,
        prgGlobalSearchStatusProgramId,
      })
      .then(response => {
        if (!!response) {
          response.data.items.forEach(item => {
            if (item.geometry.type !== 'Point') {
              assertBBoxArray(item.bbox)
            }
          })

          const items = response.data.items.map(
            feature =>
              pipe(
                assocPath(
                  ['properties', 'status'],
                  pickStatus(feature?.properties?.prgGlobalSearchStatuses)
                )
              )(feature) as FieldMapFeature
          )

          if (skipPointVersions) {
            return featureCollection(items)
          }

          const withPointFeatures: FieldMapFeature[] = []

          items.forEach((item: FieldMapFeature) => {
            withPointFeatures.push(item)

            if (item.geometry.type !== 'Point') {
              const position = labelPositionForGeometry(item.geometry)
              const label = point(position as number[], item.properties)
              withPointFeatures.push(label)
            }
          })

          return featureCollection(withPointFeatures)
        }
        return null
      })
  }

  // This should only be used to fetch the field count (and acreage) of
  static getMultipleFieldQueries = async (
    queries: FieldQueryOptions[],
    queryKey: keyof FieldQueryOptions
  ) => ({
    results: await Promise.all(
      queries.map(query =>
        FieldsAPI.paginatedFields(query).then(response => ({
          queryKey: query[queryKey],
          ...response,
        }))
      )
    ),
  })

  static field(resourceId: string, options?: any) {
    return mpAgent
      .get<FieldResponse>(generatePath(PATHS.FIELD, { resourceId }), options)
      .then(response => response && response.data.item)
  }

  static deleteField(resourceId: string) {
    return mpAgent.delete<MpxResponseBase>(generatePath(PATHS.FIELD, { resourceId }))
  }

  static deleteFields(resourceIds: string[]) {
    return mpAgent.delete<MpxResponseBase>(generatePath(PATHS.FIELDS), {
      data: { resourceIds: resourceIds.join() },
      params: { deleteWfOnEmpty: 'true' },
    })
  }

  static forceCommitField(resourceId: string, programId: string) {
    return mpAgent.put<FieldResponse>(generatePath(PATHS.FORCE_UPDATE, { resourceId }), {
      prgIdToEnroll: programId,
    })
  }

  static unenrollField(resourceId: string, programId: string) {
    return mpAgent.put<FieldResponse>(generatePath(PATHS.FORCE_UPDATE, { resourceId }), {
      prgIdToUnenroll: programId,
    })
  }

  // This is a Partial detail because sending no input means DELETE
  static updateField(resourceId: string, details: Array<Partial<ResourceDetail>>) {
    return queueRequest<AxiosResponse<MpxResponseBaseSingle<Field>>>(() =>
      mpAgent.patch<MpxResponseBaseSingle<Field>>(
        { template: PATHS.FIELD, params: { resourceId } },
        {
          details,
        }
      )
    )
  }

  static updateMultipleFields(batchDetails: ResourceDetailsUpdate[]) {
    return queueRequest(() =>
      mpAgent.patch<MpxListResponse<Field>>(generatePath(PATHS.BATCH_DETAILS), {
        batchDetails,
      })
    )
  }

  static updateMetadata(payload: FieldMetadataUpdateRequest) {
    return mpAgent.patch(generatePath(PATHS.METADATA), payload)
  }

  static uploadFileDetail(resourceId: string, payload: FormData, config?: AxiosRequestConfig) {
    return mpAgent.put<FieldResponse>(generatePath(PATHS.UPLOAD, { resourceId }), payload, config)
  }

  static deleteFileDetailAndRelatedDetails(resourceId: string, traitId: string, year?: number) {
    return mpAgent.delete<FieldResponse>(generatePath(PATHS.UPLOAD, { resourceId }), {
      params: { traitId, year },
    })
  }

  private static transformQuerySortParam(sort: SortModel<FieldQuerySortFields> | undefined) {
    return !!sort
      ? sort
          .map(({ sort, field }) => {
            if (field.startsWith('recommendationScore:')) {
              return `${field.split(':')[0]}:${sort}`
            }
            return `${field}:${sort}`
          })
          .join()
      : undefined
  }

  static confirmImports(request: ConfirmImportsRequest) {
    return mpAgent.put<MpxListResponse<{ resourceId: string }>>(PATHS.CONFIRM_IMPORT, request)
  }

  static stats(params: FieldsStatsQueryOptions) {
    return mpAgent.post<FieldFDRStatsResponse>(
      PATHS.STATS,
      FieldsAPI.transformFieldQueryParams(params ? params : {})
    )
  }

  static transformFieldQueryParams({
    owner,
    grower,
    programs = [],
    sort,
    prgGlobalSearchStatus,
    prgGlobalSearchStatusProgramId,
    ...passthroughParams
  }: FieldQueryOptions) {
    //@todo make this interface better :shrug:
    sort?.forEach(sort => {
      if (sort.field.startsWith('recommendationScore:')) {
        programs.push({ id: sort.field.split(':')[1] })
      }
    })

    return {
      ...passthroughParams,
      // worksaround until https://cibotech.atlassian.net/browse/CPD-3767
      owner: !!owner ? `${owner}` : undefined,
      grower: grower && grower !== '' ? grower : undefined,
      sort: FieldsAPI.transformQuerySortParam(sort),
      programs: programs?.length === 0 ? undefined : programs,
      prgGlobalSearchStatus: !!prgGlobalSearchStatus?.length
        ? prgGlobalSearchStatus.join(',')
        : undefined,
      prgGlobalSearchStatusProgramId,
      // paramsSerializer: p => queryString.stringify(p),
    }
  }

  static nameAvailable({
    excludeResourceId,
    name,
    owner,
  }: { excludeResourceId?: Field['resourceId'] } & Pick<FieldQueryOptions, 'name' | 'owner'>) {
    return mpAgent
      .get<PaginationResponse<Field>>(PATHS.FIELDS, {
        params: {
          fieldName: name,
          owner,
          limit: 1,
        },
        paramsSerializer: p => queryString.stringify(p),
      })
      .then(response =>
        // this query looks for any items with matching names and owners up to one item long.
        // if the query returns an item
        response.data?.numAvailable === 1
          ? // and we're editing an item
            !!excludeResourceId
            ? // the name IS available if the item we're editing is the item returned
              response.data.items[0].resourceId === excludeResourceId
            : // otherwise the name is NOT available
              false
          : // the name IS available if there are no query results
            response.data?.numAvailable === 0
      )
  }
}
