import { WorkflowItem } from '@cibo/core'
import { FriendlyError, ResponsiveDialog } from '@cibo/ui'
import ClearIcon from '@mui/icons-material/Clear'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { AxiosResponse } from 'axios'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
  useCreateWorkflow,
  useDeleteWorkflow,
  usePaginatedWorkflowFields,
  useProgramContent,
  useWorkflow,
} from '../../queries'

type DeleteWorkflowModalProps = {
  requestClose(): void
  workflowId?: string
  onSuccess?(): void
  onUndo?(arg: AxiosResponse<WorkflowItem>): void
}

export const DeleteWorkflowModal = ({
  requestClose,
  workflowId,
  onSuccess,
  onUndo,
}: DeleteWorkflowModalProps) => {
  const { t } = useTranslation('@cibo/programs/DeleteWorkflowModal')
  const workflowQuery = useWorkflow(workflowId)
  const { data: workflowFields } = usePaginatedWorkflowFields(workflowId)
  const deleteWorkflow = useDeleteWorkflow()
  const createWorkflow = useCreateWorkflow()
  const content = useProgramContent(workflowQuery.data?.programId)

  const { enqueueSnackbar, closeSnackbar } = useSnackbar()

  const benchmark = workflowQuery.data?.benchmark
  const name = workflowQuery.data?.name

  const onPressDelete = () => {
    if (!workflowId) return
    deleteWorkflow.mutateAsync(workflowId).then(() => {
      onSuccess && onSuccess()
      requestClose()
      enqueueSnackbar(
        t('successMessage', {
          count: workflowFields?.numItems,
          programName: content.data?.content.fields.title,
        }),
        {
          variant: 'success',
          action: key => (
            <>
              {workflowQuery.data && workflowFields && (
                <Button
                  sx={{ color: 'inherit' }}
                  onClick={() => {
                    closeSnackbar(key)
                    createWorkflow
                      .mutateAsync({
                        name: '',
                        userId: workflowQuery.data.ownedBy.userId,
                        fields: workflowFields.items.map(f => f.resourceId),
                        benchmark: workflowQuery.data.benchmark,
                        programId: workflowQuery.data.programId,
                      })
                      .then(e => onUndo && onUndo(e))
                  }}
                >
                  {t('undo')}
                </Button>
              )}
              <IconButton
                aria-label={t('close')}
                sx={{ color: 'inherit' }}
                onClick={() => {
                  closeSnackbar(key)
                }}
              >
                <ClearIcon />
              </IconButton>
            </>
          ),
        }
      )
    })
  }

  return (
    <ResponsiveDialog maxWidth="xs" open={!!workflowId} onClose={requestClose}>
      <DialogTitle>{t('deleteTitle', { context: benchmark })}</DialogTitle>
      <DialogContent>
        <Stack spacing={2}>
          <>
            <DialogContentText variant="body1" color="text.primary">
              {t('deleteInstructions', { context: benchmark, name })}
            </DialogContentText>
            <DialogContentText variant="caption">
              {t('deleteInstructionsSecondary', { context: benchmark })}
            </DialogContentText>
            <>{/* @ts-ignore */}</>
            {deleteWorkflow.error && <FriendlyError message={deleteWorkflow.error.message} />}
          </>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          variant="text"
          sx={{ color: 'text.primary' }}
          onClick={requestClose}
          disabled={deleteWorkflow.isPending}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          variant="contained"
          color="error"
          onClick={onPressDelete}
          loading={deleteWorkflow.isPending}
        >
          {t('deleteDialog')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
