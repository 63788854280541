import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const ShipmentDistanceCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const detail = params.row.resolveStandingDetail(traitId, year)
  const { t } = useTranslation('@cibo/landmanager/ShipmentDistance')
  return (
    <Typography
      sx={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
      }}
    >
      {detail.result} {t('miles')}
    </Typography>
  )
}
