import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const ByrBareGroundCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/ByrBareGround')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Stack>
      <Typography>{t(`${detail.result}`)}</Typography>
    </Stack>
  )
}
