import { ProgramEngagement } from '@cibo/core'
import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material/Button'
import { useState } from 'react'
import { useProgramContent, useProgramEngagement } from '../../../../queries'
import { fetchSelectedPracticesPDF } from './fetchSelectedPracticesPDF'

type DownloadPracticeRecommendationPDFProps = Omit<ButtonProps, 'onClick'> & {
  engagementId: ProgramEngagement['id']
  onDownload: () => void
}

export const DownloadPracticeRecommendationPDF = ({
  engagementId,
  onDownload,
  ...buttonProps
}: DownloadPracticeRecommendationPDFProps) => {
  const engagement = useProgramEngagement({ id: engagementId })
  const programId = engagement.data?.programId
  const program = useProgramContent(programId)

  const [loading, setLoading] = useState(false)

  const filename = `${program.data ? program.data?.content.fields.title + ' ' : ''}Recommendations`

  return (
    <LoadingButton
      {...buttonProps}
      loading={loading}
      onClick={() => {
        setLoading(true)

        fetchSelectedPracticesPDF({ engagementId })
          .then(pdfBlob => {
            const href = URL.createObjectURL(pdfBlob)

            // create "a" HTML element with href to file & click
            const link = document.createElement('a')
            link.href = href
            link.setAttribute('download', `${filename}.pdf`)
            document.body.appendChild(link)
            link.click()

            // clean up "a" element & remove ObjectURL
            document.body.removeChild(link)
            URL.revokeObjectURL(href)

            onDownload()
          })
          .finally(() => {
            setLoading(false)
          })
      }}
    />
  )
}
