import { ProgramEngagementUserModel } from '@cibo/core'
import { usePaginatedFields } from '@cibo/landmanager'
import { Loading, useAuth } from '@cibo/profile'
import { Markdown } from '@cibo/ui'
import { Stack, Typography, styled } from '@mui/material'
import { grey } from '@mui/material/colors'
import { uniq } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementContext } from '../../context'
import { useProgramEngagementFields } from '../../queries'
import { ProgramEngagementWarning } from './ProgramEngagementWarning'

const StyledStack = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'light' ? grey[100] : grey[900],
  borderRadius: 0.5,
}))

type EngagementInfoProps = {
  engagementId: string
}

export const EngagementInfo = ({ engagementId }: EngagementInfoProps) => {
  const { t } = useTranslation('@cibo/programs/EnagementInfo')
  const { persona } = useAuth()
  const { engagement } = useProgramEngagementContext()
  const fields = useProgramEngagementFields({ id: engagementId })
  const totalFieldCount = usePaginatedFields(0, 1, { owner: engagement.data?.ownedBy?.userId })
  const owner = useMemo(
    () => engagement.data?.ownedBy && new ProgramEngagementUserModel(engagement.data?.ownedBy),
    [engagement.data?.ownedBy]
  )

  const [farmNames, farmSerialNumbers] = useMemo(() => {
    const fieldModels = fields.data?.items
    return [
      fieldModels?.map(field => field.farm?.item.name).filter(Boolean),
      uniq(fieldModels?.map(field => field.fsaFarm?.item.farmSerialNumber).filter(Boolean) || []),
    ]
  }, [fields.dataUpdatedAt])

  if (engagement.isPending) {
    return <Loading />
  }

  return !!engagement.data && persona !== 'grower' ? (
    <StyledStack>
      <ProgramEngagementWarning />
      <Stack padding={3} spacing={1.5}>
        <Typography variant="body2" color="text.secondary">
          {t('growerInfo')}
        </Typography>
        <Stack
          direction="row"
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Typography variant="caption" color="text.secondary" whiteSpace={'nowrap'}>
            {t('growerName')}
          </Typography>
          <Typography sx={{ wordBreak: 'break-all', textAlign: 'right' }}>
            {owner?.displayNameFull ?? '-'}
          </Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Typography variant="caption" color="text.secondary" whiteSpace={'nowrap'}>
            {t('farmName', { count: farmNames?.length })}
          </Typography>
          <Markdown typographyProps={{ variant: 'body1', textAlign: 'right' }}>
            {!!farmNames?.length ? farmNames.join('  \n') : '-'}
          </Markdown>
        </Stack>
        <Stack
          direction="row"
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Typography variant="caption" color="text.secondary" whiteSpace={'nowrap'}>
            {t('enrollingFarmNumber', { count: farmSerialNumbers.length })}
          </Typography>
          <Markdown typographyProps={{ variant: 'body1', textAlign: 'right' }}>
            {!!farmSerialNumbers?.length ? farmSerialNumbers.join('  \n') : '-'}
          </Markdown>
        </Stack>
        <Stack
          direction="row"
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Typography variant="caption" color="text.secondary" whiteSpace={'nowrap'}>
            {t('numberOfFields')}
          </Typography>
          <Typography>{t('fieldCount', { count: engagement.data.fieldCounts?.total })}</Typography>
        </Stack>
        <Stack
          direction="row"
          alignItems={'flex-end'}
          justifyContent={'space-between'}
          spacing={1.5}
        >
          <Typography variant="caption" color="text.secondary" whiteSpace={'nowrap'}>
            {t('totalNumberOfFields')}
          </Typography>
          <Typography>{t('fieldCount', { count: totalFieldCount.data?.numAvailable })}</Typography>
        </Stack>
      </Stack>
    </StyledStack>
  ) : null
}
