interface SwatchProps {
  color: string
  opacity?: number
  size?: number
  outline?: boolean
  diamond?: boolean
  line?: boolean
  dashed?: boolean
}

export const Swatch = ({
  color,
  opacity = 1,
  size = 10,
  outline,
  diamond,
  dashed,
  line,
}: SwatchProps) => {
  if (dashed || line) {
    return (
      <div
        style={{
          backgroundColor: 'transparent',
          opacity,
          width: size,
          height: size,
        }}
      >
        <svg width={size} height={size} viewBox="0 0 100 100">
          <path
            d="M 0 50 L 100 50 Z"
            stroke={color}
            strokeWidth={10}
            strokeDasharray={dashed ? '27' : undefined}
          />
        </svg>
      </div>
    )
  }

  return (
    <div
      style={{
        backgroundColor: outline ? 'transparent' : color,
        opacity,
        width: diamond ? Math.sqrt(Math.pow(size, 2) / 2) : size,
        height: diamond ? Math.sqrt(Math.pow(size, 2) / 2) : size,
        borderColor: color,
        borderWidth: outline ? 2 : 0,
        borderRadius: diamond ? 0 : size / 2,
        transform: diamond ? "rotate('45deg')" : undefined,
      }}
    />
  )
}
