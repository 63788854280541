export const NON_AG_USAGE = [
  'fallow',
  'forest',
  'shrubland',
  'barren',
  'cloudsNodata',
  'developed',
  'water',
  'wetlands',
  'nonagUndefined',
  'aquaculture',
  'openwater',
  'perennialiceSnow',
  'developedOpenspace',
  'developedLowintensity',
  'developedMedintensity',
  'developedHighintensity',
  'deciduousforest',
  'evergreenforest',
  'mixedforest',
  'woodywetlands',
  'herbaceouswetlands',
]

export const NON_AG_CDL_LABEL = [
  'Background',
  'Fallow/Idle Cropland',
  'Forest',
  'Shrubland',
  'Barren',
  'Clouds/No Data ',
  'Developed',
  'Water',
  'Wetlands',
  'Nonag/Undefined',
  'Aquaculture',
  'Open Water',
  'Perennial Ice/Snow',
  'Developed/Open Space',
  'Developed/Low Intensity',
  'Developed/Med Intensity',
  'Developed/High Intensity',
  'Barren',
  'Deciduous Forest',
  'Evergreen Forest',
  'Mixed Forest',
  'Shrubland',
  'Grassland/Pasture',
  'Woody Wetlands',
  'Herbaceous Wetlands',
]
