import { ResourceDetailYearly } from '../ResourceDetail'

export const EQIP_PRACTICES_SNOW_DRIFTS_OPTIONS = [
  'causes_damage',
  'does_not_cause_damage',
  'unknown',
] as const
export type EqipPracticesSnowDriftsOption = typeof EQIP_PRACTICES_SNOW_DRIFTS_OPTIONS[number]

export interface RDEqipPracticesSnowDriftsInput {
  driftedSnow: EqipPracticesSnowDriftsOption
}

export interface RDEqipPracticesSnowDrifts extends ResourceDetailYearly {
  traitId: 'eqipPracticesSnowDrifts'
  result: RDEqipPracticesSnowDriftsInput
  input: RDEqipPracticesSnowDriftsInput
}
