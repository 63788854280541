import { RadioBooleanField } from '@cibo/ui'
import { FormControl, FormLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'
import { useIsRequired } from '../useIsRequired'

export const FertilizerReductionEditor = ({
  fieldModels,
  name = 'fertilizerReductionEditor',
  year,
  requirement,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/DetailEditing/FertilizerReduction')
  const benchmarkRequires = useIsRequired(requirement)

  return (
    <FormControl required={benchmarkRequires('reduction')}>
      <FormLabel htmlFor={`${name}.reduction`}>{t('fertilizerReduction')}</FormLabel>
      <RadioBooleanField name={`${name}.reduction`} />
    </FormControl>
  )
}
