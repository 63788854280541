import {
  AddCellValueButton,
  DataGridPro,
  ResourceDetailFeatureTaskEditorProps,
  useSaveIndicator,
} from '@cibo/ui'
import { Stack } from '@mui/material'
import { GridRenderCellParams, GridRowHeightReturnValue } from '@mui/x-data-grid-pro'
import { useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFieldColumns } from '../../../../components/FieldColumns'
import { useFields } from '../../../../queries/useFields'
import { useUpdateMultipleFields } from '../../../../queries/useUpdateMultipleFields'
import { RDEqipPLUId } from './types'

const TRAIT_ID = 'eqipPLUId'

export const PLUIdTaskEditor = ({
  resourceIds,
}: ResourceDetailFeatureTaskEditorProps<RDEqipPLUId>) => {
  const { data: fieldModels, isPending, isFetched } = useFields(resourceIds)
  const { t } = useTranslation('@cibo/landmanager/PLUId')
  const updateFields = useUpdateMultipleFields()

  const { setIsSaving, setIsError } = useSaveIndicator()

  const commitPluValue = (resourceId: string, plu: string) => {
    setIsError(false)
    setIsSaving(true)
    updateFields
      .mutateAsync([{ resourceId, details: [{ traitId: TRAIT_ID, result: plu }] }])
      .catch((error: any) => setIsError(true))
      .finally(() => setIsSaving(false))
  }

  // Flatten initial rows to make datagrid edits work.
  // DataGrid edits flatten the row object, which breaks getters on a FieldModel.
  const rows = useMemo(
    () =>
      fieldModels?.map(field => ({
        id: field.resourceId,
        geometrySlug: field.geometrySlug,
        state: field.state,
        name: field.name,
        county: field.county,
        acres: field.acres,
        pluNumber: field.resolveStandingDetail(TRAIT_ID)?.result,
      })),
    [isFetched]
  )

  const { fieldNameColumn } = useFieldColumns()

  const columns = useMemo(
    () => [
      fieldNameColumn,
      {
        field: 'pluNumber',
        headerName: t('pluNumber'),
        flex: 1,
        editable: true,
        renderCell: (params: GridRenderCellParams) =>
          params.value && params.value !== '' ? (
            params.value
          ) : (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              flex={1}
              height="100%"
            >
              <AddCellValueButton {...params} />
            </Stack>
          ),
      },
    ],
    []
  )

  const getRowHeight = useCallback((): GridRowHeightReturnValue => 'auto', [])

  return (
    <Stack spacing={3}>
      <DataGridPro
        disableRowSelectionOnClick
        rows={rows || []}
        getRowHeight={getRowHeight}
        columns={columns}
        loading={isPending}
        autoHeight
        processRowUpdate={updatedRow => {
          commitPluValue(updatedRow.id, updatedRow.pluNumber)
          return updatedRow
        }}
        onProcessRowUpdateError={console.log}
      />
    </Stack>
  )
}
