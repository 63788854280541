import { FieldModel, FieldsAPI, ResourceDetail } from '@cibo/core'
import { logRequestError } from '@cibo/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEY } from '../../../queries/queryKey'

type SaveBulkDetailsRequest = {
  resourceIds: string[]
  details: ResourceDetail[]
}

export const useSaveBulkDetails = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({ resourceIds, details }: SaveBulkDetailsRequest) =>
      FieldsAPI.bulkUpdateFields(resourceIds, details).then(r =>
        r.items.map(f => new FieldModel(f))
      ),

    mutationKey: [QUERY_KEY.SAVE_BULK_DETAILS],
    onSuccess: (fields: Array<FieldModel>, request: SaveBulkDetailsRequest) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDSET] })
      request.resourceIds.forEach(resourceId => {
        queryClient.resetQueries({ queryKey: [QUERY_KEY.FIELDS, resourceId] })
      })
    },
    onError: (error: any, variables, context) => {
      logRequestError(error, { query: 'useSaveBulkDetails', variables, context })
      return error
    },
  })
}
