import { LinearProgress, alpha, styled } from '@mui/material'
import { lime } from '@mui/material/colors'
import {
  DataGridProProps,
  GridValidRowModel,
  DataGridPro as MUIDataGridPro,
} from '@mui/x-data-grid-pro'
import { omit } from 'ramda'
import { useMemo } from 'react'
import { BulkActionToolbar } from './BulkActionToolbar'
import { ServerPaginationFooter } from './ServerPaginationFooter'
import { ExtendedDataGridProProps } from './types'

function ExtendedDataGridPro<R extends GridValidRowModel>(
  props: DataGridProProps & ExtendedDataGridProProps
) {
  return <MUIDataGridPro<R> {...props} />
}

const StyledDataGridPro = styled(ExtendedDataGridPro)(
  ({ theme, slots, headerPaletteColor, hideBorders, hideCellFocus, onRowClick }) => ({
    border: hideBorders ? 'none' : undefined,
    '.MuiDataGrid-cell, .MuiDataGrid-columnHeaders': {
      border: hideBorders ? 'none' : undefined,
    },
    '.MuiDataGrid-cell:focus': {
      outline: hideCellFocus ? 'none' : undefined,
    },
    '.MuiDataGrid-row': {
      cursor: !!onRowClick ? 'pointer' : undefined,
    },
    '& .MuiDataGrid-columnHeaders': {
      backgroundColor: headerPaletteColor
        ? theme.palette[headerPaletteColor].main
        : theme.palette.grey[theme.palette.mode === 'light' ? 100 : 900],
      borderRadius: slots?.bulkActions || slots?.toolbar ? 0 : undefined,
      '& .MuiButtonBase-root': {
        color: headerPaletteColor ? theme.palette[headerPaletteColor].contrastText : undefined,
      },
      '& .MuiDataGrid-columnHeader--dragging': {
        color: theme.palette.primary.contrastText,
        backgroundColor: theme.palette.primary.light,
      },
    },
    '& .MuiDataGrid-columnHeaderTitle': {
      color: headerPaletteColor ? theme.palette[headerPaletteColor].contrastText : undefined,
    },
    '& .MuiDataGrid-columnHeader:last-child > .MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '& .MuiDataGrid-cellCheckbox:focus-within, & .MuiDataGrid-columnHeaderCheckbox:focus-within': {
      outline: 'none',
    },
    '& .MuiDataGrid-row.Mui-selected': {
      backgroundColor: alpha(theme.palette.primary.light, 0.1),
      transition: theme.transitions.create('background-color'),
    },
    '& .MuiDataGrid-pinnedColumns': {
      backgroundColor: theme.palette.background.paper,
    },
    '& .MuiDataGrid-row.highlight': {
      backgroundColor: lime[theme.palette.mode === 'light' ? 100 : 900],
    },
    '& .MuiDataGrid-row.disabled': {
      cursor: 'not-allowed',
      opacity: 0.6,
    },
    '& .MuiDataGrid-row.disabled:hover': {
      backgroundColor: 'unset',
    },
  })
)

/**
 * Extends MUI's DataGridPro in 2 significant ways:
 *
 * 1. Enhanced styling: colored header, paper colored pinned columns
 * 2. Support for a BulkActions component slot
 *
 * #BulkActions
 * When specified, this component will appear in the Toolbar area when any rows are selected.
 * If a Toolbar is specified, that component is 'backgrounded' behind the BulkActions.  This
 * is useful if your toolbar affects the table in a way that would conflict with selected rows.
 *
 * For example:
 * Your Toolbar contains a filter, when activating the filter, table rows are dropped.  If a user
 * had previously hand-picked a number of rows, and those rows are now suddenly missing, the user
 * would need to know their selection set changed.  To avoid this potentially confusing situation,
 * specify BulkActions that appear over top of the Toolbar in this case.
 *
 * BulkActions is completely opt-in and will also have the following side-effect:
 * When BulkActions is defined and `hideFooterSelectedRowCount=false` and `paginationMode='server'`,
 * the row count is rendered as text, else a button to deselect all rows is rendered.  We do this
 * b/c MUI's DataGridPro does not support cross-page row selection, however we have a frequent need
 * to support cross-page row selection.
 *
 */

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function DataGridPro<R extends GridValidRowModel>({
  slots = {},
  ...props
}: DataGridProProps & ExtendedDataGridProProps) {
  const bulkActions = useMemo(
    () => (props: ExtendedDataGridProProps) =>
      (
        <BulkActionToolbar
          {...props}
          slots={{ toolbar: slots.toolbar, bulkActions: slots.bulkActions }}
        />
      ),
    []
  )

  // @ts-ignore a reasonable dev may try to pass bulkActions despite it not being typed
  if (props.slotProps?.bulkActions || props.slotProps?.toolbar) {
    // @ts-ignore meta does include env, despite typing
    if (!import.meta.env.PROD) {
      console.warn(
        'the custom BulkActions component prevents successful passing of slotProps to the `BulkActions` and `Toolbar` slots'
      )
    }
  }

  const finalSlots = {
    loadingOverlay: () => <LinearProgress sx={{ marginBottom: -0.5 }} />,
    ...omit(['bulkActions'], slots),
    toolbar: slots.bulkActions ? bulkActions : slots.toolbar,
  }

  const footer = slots.footer
    ? slots.footer
    : props.paginationMode === 'server' && !slots.bulkActions
    ? (props: DataGridProProps) => <ServerPaginationFooter {...props} />
    : undefined

  if (footer) {
    finalSlots.footer = footer
  }

  return <StyledDataGridPro {...props} slots={finalSlots} />
}
