import { NumberInputField } from '@cibo/ui'
import { FormControl, FormLabel } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../types'

export const ShipmentDistanceEditor = ({ name = 'shipmentDistance' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/ShipmentDistance')

  return (
    <FormControl required>
      <FormLabel htmlFor={`${name}`}>{t('shipmentDistance')}</FormLabel>
      <NumberInputField name={name} min={0} unit={t('miles')} data-testid={name} />
    </FormControl>
  )
}
