import { Markdown } from '@cibo/ui'
import ArrowBack from '@mui/icons-material/ArrowBack'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDownRounded'
import CheckOutline from '@mui/icons-material/Check'
import DeleteIcon from '@mui/icons-material/DeleteRounded'
import HelpIcon from '@mui/icons-material/HelpOutlineRounded'
import {
  Box,
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fade,
  Icon,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
} from '@mui/material'
import { MouseEvent, forwardRef, useContext, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { GeometryEditorToolsContext, useGeometryEditorStateContext } from './GeometryEditorContext'
import { DRAW_TOOLS, MAP_TOOLS, SELECTION_TOOLS, TRANSFORM_TOOLS } from './ToolGroups'

const TOOLS = [MAP_TOOLS, TRANSFORM_TOOLS, DRAW_TOOLS, SELECTION_TOOLS]

export interface ToolboxProps {
  onDifference?: () => void
  onDifferenceSelected?: () => void
  onUnion?: () => void
  onBack?: () => void
  onNext?: () => void
  onUnionSelected?: () => void
}

/**
 * @todo: adapt layout to mobile
 */
export const Toolbox = forwardRef<HTMLDivElement, ToolboxProps>(({ onBack, onNext }, ref) => {
  const { mode, handleDifference, handleDeleteSelected, handleUpdateMode } = useContext(
    GeometryEditorToolsContext
  )

  const {
    editState,
    baseFeatureCollection: initialFeatures,
    handleUndo,
    handleRedo,
  } = useGeometryEditorStateContext()
  const initialTool =
    initialFeatures && initialFeatures?.features?.length > 0 ? 'modify' : 'polygon'

  const selectedCount = mode.isMarquee
    ? 0
    : editState.editLayer.selectedIndexes[0] === -1
    ? 0
    : editState.editLayer.selectedIndexes.length

  const onDeleteSelected = mode.isMarquee ? handleDifference : handleDeleteSelected
  const { t } = useTranslation('GeometryEditorToolbox')
  const [visibleTools, setVisibleTools] = useState(new Array(TOOLS.length).fill(0))
  const [currentTool, setCurrentTool] = useState([0, 0])
  const [open, setOpen] = useState(false)
  const [helpDialogOpen, setHelpDialogOpen] = useState(false)
  const [flyoutMenuCategory, setFlyoutMenuCategory] = useState(0)
  const anchorRef = useRef(null)
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>()

  const toggleHelpDialog = () => setHelpDialogOpen(prevValue => !prevValue)

  // tell the map which mode to start with
  useEffect(() => {
    let category = 0
    let tool = 0
    if (!!initialTool) {
      //@ts-ignore
      category = TOOLS.findIndex(c => !!c.find((t: any) => t.id === initialTool))
      tool = TOOLS[category].findIndex((t: any) => t.id === initialTool)
      category = category >= 0 ? category : 0
      tool = tool >= 0 ? tool : 0
    }
    selectMode(category, tool)
  }, [])

  const handleClose = (event: Event) => {
    //@ts-ignore figure out correct useRef type to use
    if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
      return
    }
    setOpen(false)
  }
  const handleToggle = (event: MouseEvent<HTMLElement>, category: number) => {
    event.stopPropagation()
    setAnchorEl(event.currentTarget)
    setOpen(prevValue => (category === flyoutMenuCategory ? !prevValue : true))
    setFlyoutMenuCategory(category)
  }

  const selectMode = (category: number, tool: number) => {
    const newVisibleTools = [...visibleTools]
    newVisibleTools[category] = tool
    setVisibleTools(newVisibleTools)
    setCurrentTool([category, tool])
    setOpen(false)
    handleUpdateMode(
      TOOLS[category][tool].mode,
      //@ts-ignore @todo better types on tool configs
      TOOLS[category][tool].modeConfig
    )
  }

  return (
    <>
      <Stack
        direction="row"
        data-testid="drawing-tools"
        ref={ref}
        spacing={1.5}
        sx={{ pointerEvents: 'none', height: 64 }}
      >
        {onBack && (
          <Paper variant="outlined" style={{ pointerEvents: 'all' }}>
            <ButtonGroup disableElevation sx={{ height: '100%', marginInline: 1 }}>
              <Button
                variant="text"
                key={'back'}
                value={'back'}
                onClick={onBack}
                sx={{ paddingInline: 1.5 }}
                size="small"
              >
                <Tooltip placement="bottom" title={t('back')} arrow disableInteractive>
                  <ArrowBack />
                </Tooltip>
              </Button>
            </ButtonGroup>
          </Paper>
        )}
        <Paper variant="outlined" style={{ pointerEvents: 'all' }}>
          <ButtonGroup size="small" disableElevation sx={{ height: '100%' }}>
            {visibleTools.map((tool, category) => (
              <Button
                key={`${category}:${tool}`}
                value={`${category}:${tool}`}
                onClick={() => selectMode(category, tool)}
                //maybe disable based on number of selected items
                variant={
                  currentTool[0] === category && currentTool[1] === tool ? 'contained' : 'text'
                }
                sx={{ paddingInline: 1.5 }}
                size="small"
                endIcon={
                  TOOLS[category].length > 1 ? (
                    // @ts-ignore @todo figrure out proper event type above
                    <ArrowDropDownIcon onClick={event => handleToggle(event, category)} />
                  ) : null
                }
              >
                <Tooltip
                  placement="bottom"
                  title={`${t(TOOLS[category][tool].id)}`}
                  arrow
                  disableInteractive
                >
                  {TOOLS[category][tool].icon()}
                </Tooltip>
              </Button>
            ))}
          </ButtonGroup>
        </Paper>

        <Paper variant="outlined" style={{ pointerEvents: 'all' }}>
          <ButtonGroup size="small" sx={{ height: '100%', pl: 1 }}>
            <Button
              variant="text"
              sx={{ paddingInline: 1.5 }}
              aria-label={t('undo')}
              disabled={!handleUndo}
              onClick={handleUndo}
            >
              <Tooltip placement="bottom" title={`${t('undo')}`} arrow disableInteractive>
                <Icon baseClassName="icon-Undo" />
              </Tooltip>
            </Button>
            <Button
              variant="text"
              sx={{ paddingInline: 1.5 }}
              aria-label={t('redo')}
              disabled={!handleRedo}
              onClick={handleRedo}
            >
              <Tooltip placement="bottom" title={`${t('redo')}`} arrow disableInteractive>
                <Icon baseClassName="icon-Redo" />
              </Tooltip>
            </Button>
          </ButtonGroup>
          <ButtonGroup size="small" sx={{ height: '100%' }}>
            <Button
              variant="text"
              sx={{ paddingInline: 1.5 }}
              aria-label={t('deleteSelected')}
              disabled={selectedCount === 0}
              onClick={onDeleteSelected}
              size="small"
            >
              <Tooltip placement="bottom" title={`${t('deleteSelected')}`} arrow disableInteractive>
                <DeleteIcon />
              </Tooltip>
            </Button>
            <Button
              variant="text"
              aria-label={t('help')}
              onClick={toggleHelpDialog}
              size="small"
              sx={{ paddingInline: 1.5 }}
            >
              <Tooltip placement="bottom" title={`${t('help')}`} arrow disableInteractive>
                <HelpIcon />
              </Tooltip>
            </Button>
          </ButtonGroup>
        </Paper>
        {onNext && (
          <Paper variant="outlined" style={{ pointerEvents: 'all' }}>
            <ButtonGroup disableElevation sx={{ height: '100%', marginInline: 1 }}>
              <Button
                variant="text"
                key={'next'}
                value={'next'}
                onClick={onNext}
                sx={{ paddingInline: 1.5 }}
                size="large"
                startIcon={<CheckOutline />}
              >
                {t('setBoundary')}
              </Button>
            </ButtonGroup>
          </Paper>
        )}
      </Stack>
      <Dialog onClose={toggleHelpDialog} open={helpDialogOpen}>
        <DialogTitle>{t('geometryEditorHelp')}</DialogTitle>
        <DialogContent>
          <Box sx={{ paddingBottom: 2 }}>
            <Markdown>{t('toolsHelpDescription')}</Markdown>
          </Box>
          <Table size="small">
            <TableHead>
              <TableCell align="center" variant="head">
                {t('tool')}
              </TableCell>
              <TableCell variant="head">
                <Markdown>{t('description')}</Markdown>
              </TableCell>
            </TableHead>
            <TableBody>
              {TOOLS.map(tool =>
                //@ts-ignore
                tool.map(({ id, icon }) => (
                  <TableRow>
                    <TableCell align="center">
                      {icon && icon()}
                      <br />
                      {t(id)}
                    </TableCell>
                    <TableCell>
                      <Markdown>{t(`${id}Description`)}</Markdown>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={toggleHelpDialog}>
            {t('close')}
          </Button>
        </DialogActions>
      </Dialog>

      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
      >
        {TOOLS[flyoutMenuCategory].map((e, tool) => (
          <MenuItem
            key={TOOLS[flyoutMenuCategory][tool].id}
            value={`${flyoutMenuCategory}:${tool}`}
            selected={currentTool[0] === flyoutMenuCategory && currentTool[1] === tool}
            onClick={() => selectMode(flyoutMenuCategory, tool)}
          >
            <ListItemIcon>{e.icon()}</ListItemIcon>
            <ListItemText>{t(e.id)}</ListItemText>
          </MenuItem>
        ))}
      </Menu>
    </>
  )
})
