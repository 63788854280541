import { useTranslation } from 'react-i18next'
import { DetailSimpleCellProps } from '../types'

export const IrrigationSimpleCell = ({ detail }: DetailSimpleCellProps) => {
  const { t } = useTranslation('@cibo/landmanager/IrrigationSimpleCell')

  return (
    <>
      {!!detail?.result !== undefined
        ? detail?.result
          ? t('irrigated')
          : t('notIrrigated')
        : '--'}
    </>
  )
}
