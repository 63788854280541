import { FieldHistoryCrop } from '@cibo/core'
import { Icon as Component } from './Icon'

export const Icon = Component

type IconProps = {
  color?: string
  size?: number
}

export const IconWithDefaults = (name: string, defaults: IconProps) => (props: IconProps) =>
  <Icon name={name} {...{ ...defaults, ...props }} />

// Crop
const cropIconMap: Record<FieldHistoryCrop, string> = {
  corn: 'Corn',
  soybeans: 'Soy',
  cotton: 'Cotton',
  wheat: 'Wheat',
}

export const iconNameForCrop = (crop: FieldHistoryCrop) => `Crop-${cropIconMap[crop]}`

export const Corn = IconWithDefaults('Crop-Corn', { size: 24 })
export const Cotton = IconWithDefaults('Crop-Cotton', { size: 24 })
export const Soy = IconWithDefaults('Crop-Soy', { size: 24 })
export const Wheat = IconWithDefaults('Crop-Wheat', { size: 24 })

// Land Use
export const Crop = IconWithDefaults('Land-Use-Crop', { size: 24 })
export const Dairy = IconWithDefaults('Land-Use-Dairy', { size: 24 })
export const Forest = IconWithDefaults('Land-Use-Forest', { size: 24 })
export const Ranch = IconWithDefaults('Land-Use-Ranch', { size: 24 })

export * from './IconProps'
