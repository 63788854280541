import { Autocomplete, AutocompleteProps, Box } from '@mui/material'
import { FieldLabel } from '../FieldLabel'

export const FieldLabelAutocomplete = (
  autocompleteProps: AutocompleteProps<
    any,
    boolean | undefined,
    boolean | undefined,
    boolean | undefined
  >
) => {
  return (
    <Autocomplete
      renderOption={(props, option) => {
        return (
          <li {...props}>
            <FieldLabel
              label={option}
              color="secondary"
              sx={{ minWidth: 0, flex: 1, pointerEvents: 'none' }}
            />
          </li>
        )
      }}
      renderTags={(value, getTagProps) =>
        value.map((label, index) => {
          const { onDelete, ...props } = getTagProps({ index })
          return (
            <Box mr={0.5} {...props}>
              <FieldLabel
                label={label}
                color="secondary"
                onClick={onDelete}
                sx={{ marginRight: 0.5 }}
              />
            </Box>
          )
        })
      }
      {...autocompleteProps}
    />
  )
}
