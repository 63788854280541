import { RDIrrigation } from '@cibo/core'
import { Divider, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const IrrigationCell = ({ traitId, year, params }: TraitCellDisplayProps<RDIrrigation>) => {
  const { t } = useTranslation('@cibo/landmanager/IrrigationEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Stack>
      <Typography>
        {!detail.result
          ? t('no')
          : !!detail.input?.rate && detail.input?.units
          ? `${detail.input?.rate} ${t('unit', { context: detail.input?.units })}`
          : t('yes')}
      </Typography>

      <Stack direction="row" spacing={1} divider={<Divider orientation="vertical" flexItem />}>
        {!!detail?.input?.irrigationType && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('type', { context: detail.input.irrigationType })}
          </Typography>
        )}
        {!!detail?.input?.schedule && (
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {t('schedule', { context: detail.input.schedule })}
          </Typography>
        )}
      </Stack>
    </Stack>
  )
}
