import {
  FieldModel,
  IntegerString,
  PracticeContentDataTypes,
  PracticeDetailTypesEntry,
} from '@cibo/core'
import { usePracticesContent } from '@cibo/landmanager/src/queries'
import { ListCell } from '@cibo/ui'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'

export const PracticesSelectedCell = (
  params: GridRenderCellParams<FieldModel, IntegerString[]>
) => {
  const selectedPractices = params.value
  const practicesContent = usePracticesContent()

  const currentPractices =
    selectedPractices
      ?.map(practiceId =>
        practicesContent.data?.items.find(
          (practice: PracticeContentDataTypes) => practice.fields.practiceId === practiceId
        )
      )
      .filter(Boolean) ?? []

  return (
    <ListCell<PracticeDetailTypesEntry>
      isPending={practicesContent.isPending}
      listItems={currentPractices as PracticeContentDataTypes[]}
      accessor={(practice: PracticeContentDataTypes) => practice.fields.name}
    />
  )
}
