export const NAN_VALUE = 'n/a'

export const nanCheck = (formatter: (value: number, ...args: any[]) => string) => (
  value: any,
  ...args: any[]
) => (value === undefined || isNaN(value) ? NAN_VALUE : formatter(value, ...args))

export const nullCheck = (formatter: (value: any, ...args: any[]) => string) => (
  value: any,
  ...args: any[]
) => (value === undefined || value === null ? NAN_VALUE : formatter(value, ...args))
