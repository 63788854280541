import {
  DisturbanceTypes,
  MonthList,
  RDNaturalDisturbance,
  RDNaturalDisturbanceEvent,
} from '@cibo/core'
import { pathEq, sum } from 'ramda'
import { number, object, string } from 'yup'
import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { NaturalDisturbanceCellDisplay } from './NaturalDisturbanceDisplay'
import { NaturalDisturbanceEditor } from './NaturalDisturbanceEditor'
import { NaturalDisturbanceRollup } from './NaturalDisturbanceRollup'

const TRAIT_ID = 'naturalDisturbance'

export const NaturalDisturbance: ITraitFeatureByIdYear<RDNaturalDisturbance> = {
  traitId: TRAIT_ID,
  cellDisplay: NaturalDisturbanceCellDisplay,
  editor: NaturalDisturbanceEditor,
  forbidBulkEdit: false,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: NaturalDisturbanceRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {},
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  ns: '@cibo/landmanager/NaturalDisturbance',
  //@ts-ignore For some reason it's yelling about RDNaturalDisturbance['input'] not being assignable to undefined
  validationSchema: eventValidationSchema<RDNaturalDisturbance['input']>(({ t }) =>
    object()
      .shape({
        month: string<MonthList>()
          .required(t('errorRequired', { context: 'month' }))
          .typeError(t('errorRequired', { context: 'month' })),
        disturbance: string<DisturbanceTypes>()
          .required(t('errorRequired', { context: 'disturbance' }))
          .typeError(t('errorRequired', { context: 'disturbance' })),
        lossPer: number()
          .min(1, t('errorMin', { context: 'lossPer' }))
          .max(100, t('errorMax', { context: 'lossPer' }))
          .required(t('errorRequired', { context: 'lossPer' }))
          .typeError(t('errorRequired', { context: 'lossPer' })),
      })
      .test('totalPercentage', t('totalPercentageTooHigh'), function (password2) {
        const totalLoss = sum(this.parent.map((a: RDNaturalDisturbanceEvent) => a.lossPer))
        return totalLoss < 100
      })
  ),
}
