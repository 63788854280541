import { ResourceDetail, TILLAGE_TYPE } from '@cibo/core'
import { useEffect, useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { TraitCellEditorProps } from '../types'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result || ''
}

export const FutureTillageCellEditor = ({
  traitId,
  year,
  params,
  saveDetail,
  removeDetail,
  t,
}: TraitCellEditorProps) => {
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target

    setAnswer(value)

    if (value === '') {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
        year,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, year, result: value }],
      })
    }
  }

  const source = detail?.source

  return (
    <Select value={answer} onChange={handleChange} fullWidth data-testid={`futureTillage_editor`}>
      {source === 'user' && (
        <MenuItem value={''}>
          <>{t('remove')}</>
        </MenuItem>
      )}
      {TILLAGE_TYPE.map(tillageName => (
        <MenuItem key={tillageName} value={tillageName}>
          <>{t(`tillage_${tillageName}`)}</>
        </MenuItem>
      ))}
    </Select>
  )
}
