import { USER_DATA_KEYS, useUserData } from '@cibo/profile'
import { useStateToggle } from '@cibo/ui'
import { PropsWithChildren, useContext, useEffect, useState } from 'react'
import { useMap } from 'react-map-gl/dist/es5'
import { CDL_YEARS } from './CDLLayer'
import { LayerContext } from './LayerContext'
import { MapStyles } from './const'

export const LayerProvider = ({ children }: PropsWithChildren<{}>) => {
  const [cdlYear, setCdlYear] = useState<string>(`${CDL_YEARS[CDL_YEARS.length - 1]}`)
  const [showParcels, setShowParcels] = useState<boolean | undefined>()
  const [showSections, setShowSections] = useState<boolean | undefined>()
  const [showTerrain, toggleTerrain] = useStateToggle(true)
  const [showWatersheds, toggleWatersheds] = useStateToggle(false)
  const [showTownships, setShowTownships] = useState<boolean | undefined>()
  const [showCounties, setShowCounties] = useState<boolean | undefined>()
  const [mapStyle, setMapStyle] = useState<MapStyles | undefined>()
  const [terrainExaggeration, setTerrainExaggeration] = useState(1.0)
  const [bounds, setBounds] = useState<string | undefined>()

  const {
    data: userData,
    update: saveUserData,
    fetchError,
    isPending,
    isFetched,
  } = useUserData<any>(USER_DATA_KEYS.MAP_LAYERS)

  useEffect(() => {
    if (userData) {
      if (userData.hasOwnProperty('mapStyleValue')) {
        setMapStyle(userData.mapStyleValue)
      } else {
        setMapStyle(MapStyles.FOCUSED)
      }
      if (userData.hasOwnProperty('showParcelsLayer')) {
        setShowParcels(userData.showParcelsLayer)
      }
      if (userData.hasOwnProperty('showSectionsLayer')) {
        setShowSections(userData.showSectionsLayer)
      }
      if (userData.hasOwnProperty('showTownshipsLayer')) {
        setShowTownships(userData.showTownshipsLayer)
      }
      if (userData.hasOwnProperty('showCountiesLayer')) {
        setShowCounties(userData.showCountiesLayer)
      }
    } else {
      setMapStyle(MapStyles.FOCUSED)
    }
  }, [JSON.stringify(userData)])

  if (fetchError) {
    console.log('error loading user data', fetchError)
  }

  const toggleCounties = () => setShowCounties(!showCounties)
  const toggleParcels = () => setShowParcels(!showParcels)
  const toggleSections = () => setShowSections(!showSections)
  const toggleTownships = () => setShowTownships(!showTownships)

  useEffect(() => {
    if (!isFetched || isPending) {
      return
    }

    const layerState = {
      mapStyleValue: mapStyle,
      showCountiesLayer: showCounties,
      showParcelsLayer: showParcels,
      showSectionsLayer: showSections,
      showTownshipsLayer: showTownships,
    }

    if (
      layerState.mapStyleValue !== undefined &&
      JSON.stringify(layerState) !== JSON.stringify(userData)
    ) {
      saveUserData(layerState)
    }
  }, [showParcels, showSections, showTownships, showCounties, mapStyle])

  return (
    <LayerContext.Provider
      value={{
        cdlYear,
        mapStyle,
        setCdlYear,
        setMapStyle,
        setShowCounties,
        setShowParcels,
        setShowSections,
        setShowTownships,
        setTerrainExaggeration,
        showCounties,
        showParcels,
        showSections,
        showTerrain,
        showTownships,
        showWatersheds,
        terrainExaggeration,
        toggleCounties,
        toggleParcels,
        toggleSections,
        toggleTerrain,
        toggleTownships,
        toggleWatersheds,
        bounds,
        setBounds,
      }}
    >
      {children}
    </LayerContext.Provider>
  )
}

export const BoundsTracker = () => {
  const { current: map } = useMap()
  const { setBounds } = useContext(LayerContext)

  const update = () => {
    const bounds = map?.getBounds()

    if (bounds) {
      const ll = bounds.getSouthWest()
      const ur = bounds.getNorthEast()

      setBounds && setBounds(`${ll.lng},${ll.lat},${ur.lng},${ur.lat}`)
    }
  }

  useEffect(() => {
    update()

    map?.on('moveend', update)

    return () => {
      map?.off('moveend', update)
    }
  }, [map])

  return null
}
