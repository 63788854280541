import * as Yup from 'yup'
import { RDByrBareGround } from './types'

import { ITraitFeatureById, InitialValuesRequest } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { ByrBareGroundCell } from './ByrBareGroundCell'
import { ByrBareGroundEditor } from './ByrBareGroundEditor'

const TRAIT_ID = 'byrBareGround'

export const ByrBareGroundInput: ITraitFeatureById<RDByrBareGround> = {
  rollups: {},
  forbidBulkEdit: false,
  traitId: TRAIT_ID,
  editor: ByrBareGroundEditor,
  cellDisplay: ByrBareGroundCell,
  ns: '@cibo/landmanager/ByrBareGround',
  validationSchema: ({ requirement, t }) =>
    Yup.object({
      bareGround: Yup.boolean().required(t('required')),
    }).required(t('required')),

  initialValues: (request: InitialValuesRequest) => {
    const detail = findInitialValuesDetail<RDByrBareGround>('byrBareGround')(request)
    return { bareGround: `${detail?.result}` }
  },
  detailFromEditorValues: (values: { input: { bareGround: boolean } }) => ({
    traitId: TRAIT_ID,
    result: values.input.bareGround, // this is coming in stringified bool but works fine
  }),
}
