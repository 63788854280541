import {
  Box,
  Collapse,
  Divider,
  Fade,
  IconButton,
  Stack,
  StackProps,
  styled,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { ReactNode, useState } from 'react'

interface ExpansionPanelProps extends StackProps {
  disabled?: boolean
  header: ReactNode | string
  collapsed: ReactNode
  expanded?: ReactNode
}

const StyledStack = styled(Stack)(() => ({
  ':hover, :focus': {
    cursor: 'pointer',
  },
}))

export const ExpansionPanel = ({
  disabled,
  collapsed,
  expanded,
  header,
  ...stackProps
}: ExpansionPanelProps) => {
  const [open, setOpen] = useState(false)

  return (
    <Stack divider={<Divider />} {...stackProps}>
      <StyledStack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        onClick={disabled || !expanded ? undefined : () => setOpen(!open)}
      >
        {typeof header === 'string' ? (
          <Typography variant="subtitle1">{header}</Typography>
        ) : (
          header
        )}
        {!!expanded ? (
          <IconButton onClick={() => setOpen(!open)} disabled={disabled}>
            <ExpandMoreIcon
              sx={{
                transform: `rotate(${open ? '180deg' : '0deg'})`,
                transition: '150ms transform',
              }}
            />
          </IconButton>
        ) : (
          <Box />
        )}
      </StyledStack>
      <Stack>
        <Collapse in={!open}>
          <Fade in={!open}>
            <Box>{collapsed}</Box>
          </Fade>
        </Collapse>
        <Collapse in={open}>
          <Fade in={open}>
            <Box>{expanded}</Box>
          </Fade>
        </Collapse>
      </Stack>
    </Stack>
  )
}
