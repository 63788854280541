import { logRequestError } from '@cibo/ui'
import { Mutation, MutationCache, Query, QueryCache, QueryClient } from '@tanstack/react-query'
import { LOCAL_DATA_TTL, MAX_RETRIES } from 'consts'

export const getQueryClient = () =>
  new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: LOCAL_DATA_TTL,
        refetchOnWindowFocus: false,
        retry: (count: number, error: any) => {
          if ([403, 404].includes(error?.response?.status)) {
            return false
          }
          return count < MAX_RETRIES
        },
      },
    },
    queryCache: new QueryCache({
      onError: (error, query: Query<unknown, unknown, unknown>) => {
        logRequestError(error, {
          method: 'rq-query',
          firstQueryKey: query.queryKey?.[0],
          queryKeyLength: query.queryKey?.length,
        })
      },
    }),
    mutationCache: new MutationCache({
      onError: (error, _, __, mutation: Mutation<unknown, unknown, unknown>) => {
        // If this mutation has an onError defined, skip this
        if (mutation.options.onError) return
        logRequestError(
          { isAxiosError: false, ...error },
          {
            method: 'rq-mutation',
            firstMutationKey: mutation.options.mutationKey?.[0],
            mutationKeyLength: mutation.options.mutationKey?.length,
          }
        )
      },
    }),
  })
