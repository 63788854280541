import { CDLLandUse } from '@cibo/core'
import {
  Palette,
  PaletteColor,
  PaletteOptions,
  Theme,
  ThemeOptions,
  createTheme,
} from '@mui/material'
import { blueGrey, lightBlue } from '@mui/material/colors'
import chroma from 'chroma-js'

const defaultTheme = createTheme()

export type ExtendedPaletteColorOptions = PaletteColor & {
  lightContrastText: string
}

export interface ExtendedPalette extends Omit<Palette, 'primary' | 'secondary'> {
  ciboSensed: string
  future: PaletteColor
  primary: ExtendedPaletteColorOptions
  programClosed: PaletteColor
  programEnrollmentClosed: PaletteColor
  secondary: PaletteColor
  rating: PaletteColor
}

interface BasePalette extends ExtendedPalette, Record<CDLLandUse, PaletteOptions> {}

export interface ExtendedTheme extends Omit<ThemeOptions, 'palette'> {
  palette: BasePalette
}

export const BASE_THEME: Omit<ExtendedTheme, 'palette'> & { shape: { borderRadius: number } } = {
  typography: {
    fontFamily: 'Nunito Sans',
    // @ts-ignore this is not a prop in mui typography and has been added by us.
    italicBody1: {
      fontStyle: 'italic',
    },
    h1: {
      fontSize: 96,
      fontWeight: 300,
      letterSpacing: -1.5,
      lineHeight: '112px',
    },
    h2: {
      fontSize: 60,
      fontWeight: 300,
      letterSpacing: -0.5,
      lineHeight: '72px',
    },
    h3: {
      fontSize: 48,
      fontWeight: 300,
      letterSpacing: -0.2,
      lineHeight: '52px',
    },
    h4: {
      fontSize: 34,
      fontWeight: 300,
      letterSpacing: -0.75,
      lineHeight: '42px',
    },
    h5: {
      fontSize: 24,
      fontWeight: 400,
      letterSpacing: -0.5,
      lineHeight: '32px',
    },
    h6: {
      fontSize: 20,
      fontWeight: 500,
      letterSpacing: -0.5,
      lineHeight: '24px',
    },
    body1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: -0.2,
      lineHeight: '24px',
    },
    body2: {
      fontSize: 14,
      fontWeight: 400,
      letterSpacing: -0.2,
      lineHeight: '20px',
    },
    subtitle1: {
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0.15,
      lineHeight: '28px',
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 500,
      letterSpacing: 0.1,
      lineHeight: '22px',
    },
    overline: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: 1,
      lineHeight: '32px',
    },
    caption: {
      fontSize: 12,
      fontWeight: 400,
      letterSpacing: -0.2,
    },
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiPopper: {
      // @ts-ignore this is not a typed prop in the mui popper, but provides needed styling.
      styleOverrides: {
        root: {
          '&.MuiAutocomplete-popper': {
            minWidth: 'fit-content !important',
          },
        },
      },
    },
    MuiSelect: {
      defaultProps: { size: 'small' },
    },
    MuiTextField: {
      defaultProps: { size: 'small' },
    },
    MuiToggleButtonGroup: {
      defaultProps: { color: 'secondary' },
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-selected': {
            fontWeight: 600,
            color: theme.palette.secondary.contrastText,
            backgroundColor: theme.palette.secondary.main,
            '&:hover': { backgroundColor: theme.palette.secondary.dark },
          },
          '&:hover': { backgroundColor: chroma(theme.palette.secondary.main).alpha(0.1).hex() },
          color: theme.palette.secondary.main,
          borderColor: chroma(theme.palette.secondary.main).alpha(0.5).hex(),
          textTransform: 'unset',
          paddingTop: '5px',
          paddingBottom: '5px',
        }),
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: ({ theme }) => ({
          '&.Mui-required': {
            fontWeight: 600,
            color: theme.palette.text.primary,
          },
        }),
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'unset',
        },
        textPrimary: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
        outlinedPrimary: ({ theme }) => ({
          color: theme.palette.primary.light,
          borderColor: theme.palette.primary.light,
        }),
        containedPrimary: ({ theme }) => ({
          backgroundColor: theme.palette.primary.light,
          color: (theme.palette.primary as ExtendedPaletteColorOptions).lightContrastText,
        }),
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          height: 36,
          paddingBlock: 6,
        },
        dense: {
          height: 'unset',
          paddingBlock: 4,
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        colorPrimary: ({ theme }) => ({
          color: theme.palette.primary.light,
        }),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: { textTransform: 'unset' },
      },
    },
    MuiTooltip: {
      defaultProps: { arrow: true },
    },
    MuiTableContainer: {
      styleOverrides: {
        root: ({ theme }) => ({
          backgroundColor: theme.palette.background.paper,
          borderColor: theme.palette.divider,
          borderWidth: 1,
          borderStyle: 'solid',
          borderRadius: 5,
          '& tr:first-of-type td:first-of-type': {
            borderTopLeftRadius: 5,
          },
          '& tr:first-of-type td:last-child': {
            borderTopRightRadius: 5,
          },
          '& tr:last-child td:first-of-type': {
            borderBottomLeftRadius: 5,
          },
          '& tr:last-child td:last-child': {
            borderBottomRightRadius: 5,
          },
          '& tr:last-child td': {
            borderBottom: 'none',
          },
        }),
      },
    },
    MuiTypography: {
      styleOverrides: {
        caption: ({ theme }) => ({
          color: theme.palette.text.secondary,
        }),
      },
    },
    // @ts-ignore I don't understand this yet
    MuiDataGrid: {
      styleOverrides: {
        root: ({ theme }: { theme: Omit<Theme, 'components'> }) => ({
          backgroundColor: theme.palette.background.paper,
          borderWidth: 0,
        }),
        columnHeader: ({ theme }: { theme: Omit<Theme, 'components'> }) => ({
          backgroundColor: theme.palette.background.paper,
        }),
        'columnSeparator--resizable': {
          display: 'flex !important',
        },
        columnSeparator: {
          display: 'none',
        },
        pinnedColumnHeaders: {
          boxShadow: 'none',
        },
        pinnedColumns: {
          boxShadow: 'none',
        },
        columnHeaderTitle: ({ theme }: { theme: Omit<Theme, 'components'> }) => ({
          color: `${theme.palette.text.primary} !important`,
          fontWeight: 600,
        }),
        columnHeaderRow: ({ theme }: { theme: Omit<Theme, 'components'> }) => ({
          backgroundColor: theme.palette.background.paper,
        }),
        columnHeaderCheckbox: ({ theme }: { theme: Omit<Theme, 'components'> }) => ({
          backgroundColor: 'transparent !important',
        }),
      },
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1440,
      xl: 1600,
    },
  },
}

const CROP_PALETTE = {
  alfalfa: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,166,226)' } }),
  almonds: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,166,130)' } }),
  apples: defaultTheme.palette.augmentColor({ color: { main: 'rgb(185,0,79)' } }),
  apricots: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,143,171)' } }),
  aquaculture: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,255,255)' } }),
  asparagus: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  barley: defaultTheme.palette.augmentColor({ color: { main: 'rgb(226,0,124)' } }),
  barren: defaultTheme.palette.augmentColor({ color: { main: 'rgb(204,190,162)' } }),
  blueberries: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,0,153)' } }),
  broccoli: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  buckwheat: defaultTheme.palette.augmentColor({ color: { main: 'rgb(213,158,188)' } }),
  cabbage: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  camelina: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  caneberries: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  canola: defaultTheme.palette.augmentColor({ color: { main: 'rgb(209,255,0)' } }),
  cantaloupes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  carrots: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  cauliflower: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  celery: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  cherries: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,0,255)' } }),
  chickpeas: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  christmastrees: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,120,120)' } }),
  citrus: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,255,124)' } }),
  cloudsNodata: defaultTheme.palette.augmentColor({ color: { main: 'rgb(241,241,241)' } }),
  cloverWildflowers: defaultTheme.palette.augmentColor({ color: { main: 'rgb(232,190,255)' } }),
  corn: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,211,0)' } }),
  cotton: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,37,37)' } }),
  cranberries: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  cucumbers: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  deciduousforest: defaultTheme.palette.augmentColor({ color: { main: 'rgb(147,204,147)' } }),
  developed: defaultTheme.palette.augmentColor({ color: { main: 'rgb(153,153,153)' } }),
  developedHighintensity: defaultTheme.palette.augmentColor({
    color: { main: 'rgb(153,153,153)' },
  }),
  developedLowintensity: defaultTheme.palette.augmentColor({ color: { main: 'rgb(153,153,153)' } }),
  developedMedintensity: defaultTheme.palette.augmentColor({ color: { main: 'rgb(153,153,153)' } }),
  developedOpenspace: defaultTheme.palette.augmentColor({ color: { main: 'rgb(153,153,153)' } }),
  drybeans: defaultTheme.palette.augmentColor({ color: { main: 'rgb(166,0,0)' } }),
  durumwheat: defaultTheme.palette.augmentColor({ color: { main: 'rgb(137,96,83)' } }),
  eggplants: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  evergreenforest: defaultTheme.palette.augmentColor({ color: { main: 'rgb(147,204,147)' } }),
  fallow: defaultTheme.palette.augmentColor({ color: { main: 'rgb(190,190,120)' } }),
  flaxseed: defaultTheme.palette.augmentColor({ color: { main: 'rgb(124,153,255)' } }),
  forest: defaultTheme.palette.augmentColor({ color: { main: 'rgb(147,204,147)' } }),
  garlic: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  gourds: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  grapes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(111,69,137)' } }),
  greens: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  herbaceouswetlands: defaultTheme.palette.augmentColor({ color: { main: 'rgb(124,175,175)' } }),
  herbs: defaultTheme.palette.augmentColor({ color: { main: 'rgb(124,211,255)' } }),
  honeydewmelons: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  hops: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  lentils: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,222,175)' } }),
  lettuce: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  millet: defaultTheme.palette.augmentColor({ color: { main: 'rgb(111,0,73)' } }),
  mint: defaultTheme.palette.augmentColor({ color: { main: 'rgb(124,211,255)' } }),
  miscvegsandfruits: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  mixedforest: defaultTheme.palette.augmentColor({ color: { main: 'rgb(147,204,147)' } }),
  mustard: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  nectarines: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,143,171)' } }),
  nonagUndefined: defaultTheme.palette.augmentColor({ color: { main: 'rgb(232,255,190)' } }),
  oats: defaultTheme.palette.augmentColor({ color: { main: 'rgb(160,88,137)' } }),
  olives: defaultTheme.palette.augmentColor({ color: { main: 'rgb(51,73,51)' } }),
  onions: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,204,102)' } }),
  openwater: defaultTheme.palette.augmentColor({ color: { main: 'rgb(73,111,162)' } }),
  oranges: defaultTheme.palette.augmentColor({ color: { main: 'rgb(226,111,37)' } }),
  othercrops: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  otherhayNonalfalfa: defaultTheme.palette.augmentColor({ color: { main: 'rgb(166,241,139)' } }),
  othersmallgrains: defaultTheme.palette.augmentColor({ color: { main: 'rgb(213,158,188)' } }),
  othertreecrops: defaultTheme.palette.augmentColor({ color: { main: 'rgb(175,153,111)' } }),
  pasture: defaultTheme.palette.augmentColor({ color: { main: 'rgb(239,255,193)' } }),
  peaches: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,143,171)' } }),
  peanuts: defaultTheme.palette.augmentColor({ color: { main: 'rgb(111,166,0)' } }),
  pears: defaultTheme.palette.augmentColor({ color: { main: 'rgb(175,153,111)' } }),
  peas: defaultTheme.palette.augmentColor({ color: { main: 'rgb(83,255,0)' } }),
  pecans: defaultTheme.palette.augmentColor({ color: { main: 'rgb(181,111,92)' } }),
  peppers: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  perennialiceSnow: defaultTheme.palette.augmentColor({ color: { main: 'rgb(211,226,249)' } }),
  pistachios: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,255,139)' } }),
  plums: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,143,171)' } }),
  pomegranates: defaultTheme.palette.augmentColor({ color: { main: 'rgb(175,153,111)' } }),
  popororncorn: defaultTheme.palette.augmentColor({ color: { main: 'rgb(222,166,9)' } }),
  potatoes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(111,37,0)' } }),
  prunes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,143,171)' } }),
  pumpkins: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  radishes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  rapeseed: defaultTheme.palette.augmentColor({ color: { main: 'rgb(209,255,0)' } }),
  rice: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,168,226)' } }),
  rye: defaultTheme.palette.augmentColor({ color: { main: 'rgb(171,0,124)' } }),
  safflower: defaultTheme.palette.augmentColor({ color: { main: 'rgb(213,213,0)' } }),
  shrubland: defaultTheme.palette.augmentColor({ color: { main: 'rgb(198,213,158)' } }),
  sodGrassseed: defaultTheme.palette.augmentColor({ color: { main: 'rgb(175,255,222)' } }),
  sorghum: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,158,9)' } }),
  soybeans: defaultTheme.palette.augmentColor({ color: { main: 'rgb(37,111,0)' } }),
  speltz: defaultTheme.palette.augmentColor({ color: { main: 'rgb(213,158,188)' } }),
  springwheat: defaultTheme.palette.augmentColor({ color: { main: 'rgb(217,181,107)' } }),
  squash: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  strawberries: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  sugarbeets: defaultTheme.palette.augmentColor({ color: { main: 'rgb(168,0,226)' } }),
  sugarcane: defaultTheme.palette.augmentColor({ color: { main: 'rgb(175,124,255)' } }),
  sunflower: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,255,0)' } }),
  sweetcorn: defaultTheme.palette.augmentColor({ color: { main: 'rgb(222,166,9)' } }),
  sweetpotatoes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(111,37,0)' } }),
  switchgrass: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  tobacco: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  tomatoes: defaultTheme.palette.augmentColor({ color: { main: 'rgb(241,162,120)' } }),
  triticale: defaultTheme.palette.augmentColor({ color: { main: 'rgb(213,158,188)' } }),
  turnips: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  vetch: defaultTheme.palette.augmentColor({ color: { main: 'rgb(0,175,73)' } }),
  walnuts: defaultTheme.palette.augmentColor({ color: { main: 'rgb(232,213,175)' } }),
  water: defaultTheme.palette.augmentColor({ color: { main: 'rgb(73,111,162)' } }),
  watermelons: defaultTheme.palette.augmentColor({ color: { main: 'rgb(255,102,102)' } }),
  wetlands: defaultTheme.palette.augmentColor({ color: { main: 'rgb(124,175,175)' } }),
  wheat: defaultTheme.palette.augmentColor({ color: { main: '#d67932' } }),
  winterwheat: defaultTheme.palette.augmentColor({ color: { main: 'rgb(166,111,0)' } }),
  woodywetlands: defaultTheme.palette.augmentColor({ color: { main: 'rgb(124,175,175)' } }),
  yellowPeas: defaultTheme.palette.augmentColor({ color: { main: '#bbdd33' } }),
}

export const THEME_LIGHT_OPTIONS: ThemeOptions = {
  palette: {
    mode: 'light',
    type: 'light',
    primary: {
      main: '#183038',
      dark: '#000712',
      light: '#405962',
      contrastText: '#FFFFFF',
      lightContrastText: '#FFFFFF',
    },
    secondary: {
      main: '#00A1B3',
      light: '#59D3E5',
      dark: '#007283',
      contrastText: '#FFFFFF',
    },
    error: defaultTheme.palette.augmentColor({
      color: {
        main: '#D32F2F',
      },
    }),
    warning: defaultTheme.palette.augmentColor({
      color: {
        main: '#ED6C02',
      },
    }),
    info: defaultTheme.palette.augmentColor({
      color: {
        main: '#0288D1',
      },
    }),
    success: defaultTheme.palette.augmentColor({
      color: {
        main: '#2E7D32',
      },
    }),
    background: {
      default: '#FAFAFA',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#000000de',
      secondary: '#00000099',
      disabled: '#00000061',
    },
    table: { hilightedRow: '#e7e7e7' },
    rating: { main: '#ffb400', light: '#ffb400', dark: '#ffb400', contrastText: '#000' },
    //historic is used to indicate that a program's enrollment status is closed - which
    programEnrollmentClosed: defaultTheme.palette.augmentColor({
      color: {
        main: '#405962',
      },
    }),
    programClosed: defaultTheme.palette.augmentColor({
      color: {
        main: '#333333',
        contrastText: '#ffffff',
      },
    }),
    future: defaultTheme.palette.augmentColor({
      color: {
        main: '#8db6cc',
      },
    }),
    ciboSensed: lightBlue[50],
    ...CROP_PALETTE,
  } as Partial<BasePalette>,
  ...BASE_THEME,
}

export const THEME_LIGHT = createTheme(THEME_LIGHT_OPTIONS)

export const THEME_DARK_OPTIONS: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#3C7B90',
      dark: '#004F62',
      light: '#6DAAC0',
      contrastText: '#ffffffde',
      lightContrastText: '#000000',
    },
    secondary: {
      main: '#C5E185',
      dark: '#93AF56',
      light: '#F9FFB6',
      contrastText: '#000000de',
    },
    error: defaultTheme.palette.augmentColor({
      color: {
        main: '#F44336',
      },
    }),
    warning: defaultTheme.palette.augmentColor({
      color: {
        main: '#FFA726',
      },
    }),
    info: defaultTheme.palette.augmentColor({
      color: {
        main: '#29B6F6',
      },
    }),
    success: defaultTheme.palette.augmentColor({
      color: {
        main: '#66BB6A',
      },
    }),
    text: {
      primary: '#FFFFFF',
      secondary: '#ffffffb3',
      disabled: '#ffffff80',
    },
    table: { hilightedRow: '#333333' },
    programEnrollmentClosed: defaultTheme.palette.augmentColor({
      color: {
        main: '#6DAAC0',
      },
    }),
    programClosed: defaultTheme.palette.augmentColor({
      color: {
        main: '#555555',
        contrastText: '#ffffff',
      },
    }),
    future: defaultTheme.palette.augmentColor({
      color: {
        main: '#232d33',
        contrastText: '#ffffff',
      },
    }),
    rating: { main: '#ffb400', light: '#ffb400', dark: '#ffb400', contrastText: '#000' },
    ciboSensed: blueGrey[800],
    ...CROP_PALETTE,
  } as Partial<BasePalette>,
  ...BASE_THEME,
}
export const THEME_DARK = createTheme(THEME_DARK_OPTIONS)
