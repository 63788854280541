import { RDCoverCropInput, TERMINATION_METHOD } from '@cibo/core'
import { AutocompleteField, DatePickerField, RadioBooleanField } from '@cibo/ui'
import { Collapse, FormControl, FormLabel, InputLabel, Stack, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'
import { useIsRequired } from '../useIsRequired'
import { useShowDetailAttribute } from '../useShowDetailAttribute'
import { COVER_CROP_OPTIONS, SUGGESTED_COVER_CROP_OPTIONS } from './types'

export const CoverCropEditor = ({
  filterInputs,
  year,
  name = 'coverCropEditor',
  requirement,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/CoverCropEditor')

  const benchmarkRequires = useIsRequired(requirement)
  const show = useShowDetailAttribute<RDCoverCropInput>(filterInputs)

  return (
    <Field name={name} id={name}>
      {({ field: { value }, form }: FieldProps<RDCoverCropInput>) => {
        const setFieldValue = form.setFieldValue
        const { crop, plantedAt, terminatedAt } = value || {
          crop: undefined,
          plantedAt: undefined,
          terminatedAt: undefined,
        }
        const hideCropDetails = !crop || crop === 'none'
        // default planting in previous fall
        const defaultPlantingMonth = new Date(year - 1, 8)
        // default termination similar to cash crop planting date
        const defaultTerminationMonth = new Date(year, crop === 'corn' ? 3 : 4)
        const previousYear = year - 1
        const minTerminatedAt = !!plantedAt ? new Date(plantedAt) : new Date(year, 0, 1)
        const maxPlantedAt = !!terminatedAt ? new Date(terminatedAt) : new Date(year + 1, 0, 1)
        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { year, previousYear })}</Typography>

            {show('crop') && (
              <FormControl required>
                <FormLabel htmlFor={`${name}.crop`}>{t('whatCrop')}</FormLabel>

                <AutocompleteField
                  name={`${name}.crop`}
                  groupBy={c =>
                    SUGGESTED_COVER_CROP_OPTIONS.includes(c) ? t('suggested') : t('all')
                  }
                  options={COVER_CROP_OPTIONS}
                  getOptionLabel={(option: string) => t(option, { ns: '@cibo/ui/cropNames' })}
                  data-testid={`coverCrop.${year}.crop`}
                  onChange={(event, newCrop) => {
                    if (newCrop === 'none') {
                      setFieldValue(name, {
                        crop: newCrop,
                        plantedAt: null,
                        terminatedAt: null,
                      })
                    } else {
                      setFieldValue(`${name}.crop`, newCrop)
                    }
                  }}
                  selectOnFocus
                />
              </FormControl>
            )}

            <Collapse in={!hideCropDetails}>
              <Stack spacing={1}>
                <Stack spacing={1}>
                  <FormLabel>{t('whatDates')}</FormLabel>

                  <Stack direction="row" spacing={2}>
                    {show('plantedAt') && (
                      <FormControl required={crop !== 'none' && benchmarkRequires('plantedAt')}>
                        <FormLabel htmlFor={`${name}.plantedAt`}>{t('planted')}</FormLabel>
                        <DatePickerField
                          name={`${name}.plantedAt`}
                          minDate={new Date(year - 1, 6, 1)}
                          maxDate={maxPlantedAt}
                          defaultCalendarMonth={defaultPlantingMonth}
                          InputProps={{
                            //@ts-ignore data-testid is not a valid prop for InputProps but is passed to the underlying TextField
                            'data-testid': `coverCrop.${year}.plantedAt`,
                          }}
                        />
                      </FormControl>
                    )}

                    {show('terminatedAt') && (
                      <FormControl required={crop !== 'none' && benchmarkRequires('terminatedAt')}>
                        <FormLabel htmlFor={`${name}.terminatedAt`}>{t('terminated')}</FormLabel>
                        <DatePickerField
                          name={`${name}.terminatedAt`}
                          minDate={minTerminatedAt}
                          maxDate={new Date(year + 1, 6, 1)}
                          defaultCalendarMonth={defaultTerminationMonth}
                          InputProps={{
                            //@ts-ignore data-testid is not a valid prop for InputProps but is passed to the underlying TextField
                            'data-testid': `coverCrop.${year}.terminatedAt`,
                          }}
                        />
                      </FormControl>
                    )}
                  </Stack>
                </Stack>
                {show('terminationMethod') && (
                  <FormControl required={benchmarkRequires('terminationMethod')}>
                    <FormLabel htmlFor={`${name}.terminationMethod`}>
                      {t('howTerminated')}
                    </FormLabel>

                    <AutocompleteField
                      name={`${name}.terminationMethod`}
                      options={TERMINATION_METHOD}
                      getOptionLabel={(option: string) => t(option)}
                      data-testid={`coverCrop.${year}.terminationMethod`}
                      onChange={(event, newTerminationMethod) => {
                        setFieldValue(`${name}.terminationMethod`, newTerminationMethod)
                      }}
                      selectOnFocus
                    />
                  </FormControl>
                )}

                {show('harvestedGrain') && (
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <InputLabel
                      required={benchmarkRequires('harvestedGrain')}
                      htmlFor={`${name}.harvestedGrain`}
                    >
                      {t('harvestedGrain')}
                    </InputLabel>
                    <RadioBooleanField
                      name={`${name}.harvestedGrain`}
                      data-testid={`coverCrop.${year}.harvestedGrain`}
                    />
                  </Stack>
                )}
                {show('harvestedHay') && (
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                    <InputLabel
                      required={benchmarkRequires('harvestedHay')}
                      htmlFor={`${name}.harvestedHay`}
                    >
                      {t('harvestedHay')}
                    </InputLabel>
                    <RadioBooleanField
                      name={`${name}.harvestedHay`}
                      data-testid={`coverCrop.${year}.harvestedHay`}
                    />
                  </Stack>
                )}
              </Stack>
            </Collapse>
          </Stack>
        )
      }}
    </Field>
  )
}
