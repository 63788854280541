import { SQUARE_METERS_PER_ACRE, labelPositionForGeometry } from '@cibo/core'
import {
  ClickEvent,
  FeatureCollection,
  FeatureOf,
  GeoJsonEditMode,
  GuideFeatureCollection,
  ModeProps,
  MultiPolygon,
  PointerMoveEvent,
  Polygon,
} from '@nebula.gl/edit-modes'
import areaSqM from '@turf/area'
import bbox from '@turf/bbox'
import bearing from '@turf/bearing'
import booleanPointInPolygon from '@turf/boolean-point-in-polygon'
import destination from '@turf/destination'
import distance from '@turf/distance'
import { Coord, Feature, Position, feature, featureCollection, polygon } from '@turf/helpers'
import intersect from '@turf/intersect'

const MAX_INSET_AREA_AC = 44
const MAX_INSET_AREA_SQ_M = MAX_INSET_AREA_AC * SQUARE_METERS_PER_ACRE

type PlotProperties = {
  clientAreaSqM: number // for backend consensus of area calculation
}

export const generateId = () => Math.round(Math.random() * Number.MAX_SAFE_INTEGER)

export class PivotWallMode extends GeoJsonEditMode {
  plot: Feature<Polygon, PlotProperties> | null = null

  handlePointerMove(event: PointerMoveEvent, props: ModeProps<FeatureCollection>): void {
    const { mapCoords: mousePosition } = event

    const mouseoverFeature = props.modeConfig.fieldShapes?.features.find(
      (feature: Feature<Polygon | MultiPolygon>) =>
        booleanPointInPolygon(mousePosition, feature as Feature<Polygon>)
    ) as Feature<Polygon>

    if (!mouseoverFeature) {
      this.plot = null
      return
    }

    // @ts-ignore
    const groupAreaSqM = areaSqM(props.modeConfig.fieldShapes)
    const featureAreaSqM = areaSqM(mouseoverFeature)

    if (featureAreaSqM / groupAreaSqM < 0.1) {
      this.plot = feature(
        mouseoverFeature.geometry,
        { clientAreaSqM: featureAreaSqM, plotType: 'smallField' },
        { id: generateId() }
      )
    } else {
      const fourtyPercentAreaSqM = 0.4 * featureAreaSqM
      const targetAreaSqM = Math.min(MAX_INSET_AREA_SQ_M, fourtyPercentAreaSqM)

      const insetPlot = insetPlotForMousePosition(mouseoverFeature, mousePosition, targetAreaSqM)

      if (insetPlot) {
        // @ts-ignore having a hard time coercing an acceptable GuideFeature type
        this.plot = insetPlot
      }
    }
  }

  getGuides(props: ModeProps<FeatureCollection>): GuideFeatureCollection {
    const guides = featureCollection([])

    if (this.plot) {
      guides.features.push(this.plot)
    }
    // @ts-ignore
    return guides
  }

  handleClick(event: ClickEvent, props: ModeProps<FeatureCollection>) {
    if (this.plot) {
      props.onEdit(
        this.getAddFeatureAction(this.plot as FeatureOf<Polygon>, {
          type: 'FeatureCollection',
          features: [],
        })
      )
    }
  }
}

const getSliceRect = (position: Coord, fillDirection: number, diagonalLength: number) => {
  const sliceDirection = fillDirection < 90 ? fillDirection + 90 : fillDirection - 90

  const sliceStart = destination(position, diagonalLength, sliceDirection).geometry.coordinates
  const sliceStartExtent = destination(sliceStart, -diagonalLength, fillDirection).geometry
    .coordinates
  const sliceEnd = destination(position, -diagonalLength, sliceDirection).geometry.coordinates
  const sliceEndExtent = destination(sliceEnd, -diagonalLength, fillDirection).geometry.coordinates

  return polygon([[sliceStart, sliceEnd, sliceEndExtent, sliceStartExtent, sliceStart]])
}

const insetPlotForMousePosition = (
  mouseoverFeature: Feature<Polygon | MultiPolygon>,
  mousePosition: Position,
  targetAreaSqM: number
) => {
  const [b1, b2, b3, b4] = bbox(mouseoverFeature)
  const diagonalLength = distance([b1, b2], [b3, b4])

  // central point within the polygon
  const pivot = labelPositionForGeometry(mouseoverFeature.geometry)
  if (!pivot) return

  const distanceToCenter = distance(pivot as number[], mousePosition)

  // if mouse near center, select the entire field
  if (distanceToCenter < 0.1 * diagonalLength) {
    const plotAreaSqM = areaSqM(mouseoverFeature)

    return feature(
      mouseoverFeature.geometry,
      {
        plotType: 'wholeField',
        clientAreaSqM: plotAreaSqM,
      },
      { id: generateId() }
    )
  }

  // direction toward center from mouse position
  const fillDirection = bearing(mousePosition, pivot as Position)

  let slicePosition = pivot as Position,
    // If the step is too large, the clipRect could get moved off the edge (no intersection).
    // If the step is too small, it might get laggy to find the right position.
    step = diagonalLength / 20,
    insetPlot: Feature | null,
    lastError = 0

  while (true) {
    const sliceRect = getSliceRect(slicePosition, fillDirection, diagonalLength)

    const candidate = intersect(
      mouseoverFeature.properties?.parentField || (mouseoverFeature.geometry as Polygon),
      sliceRect
    )

    if (!candidate) {
      console.log('no intersection with sliceRect')
      return
    }

    const plotAreaSqM = areaSqM(candidate)
    insetPlot = feature(
      candidate.geometry,
      { plotType: 'inset', clientAreaSqM: plotAreaSqM },
      { id: generateId() }
    )

    const error = plotAreaSqM - targetAreaSqM

    // if not enough area in plot
    if (error < 0) {
      // slice more
      slicePosition = destination(slicePosition, step, fillDirection).geometry.coordinates
      if (lastError > 0) {
        step /= 2
      }
    }
    // if more than an acre over
    else if (error > SQUARE_METERS_PER_ACRE) {
      // slice less
      slicePosition = destination(slicePosition, -step, fillDirection).geometry.coordinates
      if (lastError < 0) {
        step /= 2
      }
    } else {
      break
    }

    lastError = error
  }

  return insetPlot
}
