import { RDXtremeAgCode, ResourceDetail } from '@cibo/core'
import { FocusEventHandler, useEffect, useState } from 'react'

import { TextField } from '@mui/material'
import { TraitCellEditorProps } from '../types'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.input?.xtremeAgCode
}

export const XtremeAgCodeCellEditor = ({
  traitId,
  params,
  saveDetail,
  removeDetail,
  t,
}: TraitCellEditorProps<RDXtremeAgCode>) => {
  const detail = params.row.resolveStandingDetail(traitId)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = event => {
    const { value } = event.target

    setAnswer(value)

    if (value === '') {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, input: { xtremeAgCode: value } }],
      })
    }
  }

  return (
    <TextField
      value={answer}
      placeholder={t('enterCode', { ns: '@cibo/landmanager/XtremeAgCode' })}
      variant="outlined"
      onChange={event => setAnswer(event.target.value)}
      onBlur={handleBlur}
      data-testid={`extremeAgCodeCellEditor`}
      sx={{ flex: 1 }}
    />
  )
}
