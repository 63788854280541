import {
  IRRIGATION_SCHEDULE,
  IRRIGATION_TYPE,
  IRRIGATION_UNITS,
  IrrigationInput,
  RDIrrigation,
} from '@cibo/core'
import { NumberInputField, SelectField } from '@cibo/ui'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  Grid2 as Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  Stack,
} from '@mui/material'
import FormLabel from '@mui/material/FormLabel'
import { Field, useField } from 'formik'
import { TextField as FMTextField } from 'formik-mui'
import { filter } from 'ramda'
import { ChangeEvent, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'
import { useIsRequired } from '../useIsRequired'
import { useShowDetailAttribute } from '../useShowDetailAttribute'
import { findInitialValuesDetail } from '../utils'

const clearEmptyStrings = filter(attribute => attribute !== '')

const SECONDARY_ATTRIBUTES = ['schedule', 'irrigationType', 'rate', 'units']
const includesSecondaryAttribute = (values: any) =>
  Object.keys(clearEmptyStrings(values)).some(key => SECONDARY_ATTRIBUTES.includes(key))

export const IrrigationEditor = ({
  fieldModels,
  name = 'irrigationEditor',
  year,
  requirement,
  filterInputs,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/IrrigationEditor')
  const isRequired = useIsRequired(requirement)

  const show = useShowDetailAttribute<IrrigationInput>(filterInputs)
  const detail = findInitialValuesDetail('irrigation')({ fieldModels, year })

  const isResultsOnly = requirement?.resultsOnly

  const fieldHelpers = useField<RDIrrigation['input'] | {}>(name)
  const { value } = fieldHelpers[0]
  const { setTouched, setValue } = fieldHelpers[2]

  const [sufficientIrrigation, setSufficientIrrigation] = useState(
    !detail ? '' : typeof value === 'object' && includesSecondaryAttribute(value) ? 'yes' : 'no'
  )

  const handleSufficientIrrigationChange = (event: ChangeEvent, value: string) => {
    setTouched(true, false)
    setSufficientIrrigation(value)
    if (value === 'yes') {
      // intentionally invalid to prompt the user to provide values
      setValue({
        irrigationType: isRequired('irrigationType') ? 'invalid' : undefined,
        schedule: isRequired('schedule') ? 'invalid' : '',
        rate: isRequired('rate') ? 'invalid' : '',
        units: isRequired('units') ? 'invalid' : undefined,
        totalAcreInches: isRequired('totalAcreInches') ? 'invalid' : null,
      })
    } else {
      setValue({})
    }
  }

  return (
    <FormControl>
      <Stack spacing={2}>
        <FormControl>
          <FormLabel required>{t('sufficientIrrigation')}</FormLabel>

          <RadioGroup
            data-testid={`${name}.sufficientIrrigation`}
            value={sufficientIrrigation}
            onChange={handleSufficientIrrigationChange}
          >
            <FormControlLabel
              value={'yes'}
              control={<Radio />}
              label={`${t('yes')}`}
              data-testid={`${name}.sufficientIrrigation.yes`}
            />
            <FormControlLabel
              value={'no'}
              control={<Radio />}
              label={`${t('no')}`}
              data-testid={`${name}.sufficientIrrigation.no`}
            />
          </RadioGroup>
        </FormControl>

        <Collapse in={sufficientIrrigation === 'yes' && !isResultsOnly}>
          <Stack spacing={2}>
            <FormControl>
              <FormLabel required={isRequired('schedule')}>{t('schedule')}</FormLabel>

              <SelectField
                fieldName={`${name}.schedule`}
                options={IRRIGATION_SCHEDULE}
                renderOption={context => t('schedule', { context })}
              />
            </FormControl>

            <FormControl>
              <FormLabel required={isRequired('irrigationType')}>{t('irrigationType')}</FormLabel>

              <SelectField
                fieldName={`${name}.irrigationType`}
                options={IRRIGATION_TYPE}
                renderOption={context => t('type', { context })}
              />
            </FormControl>
            {show('totalAcreInches') && (
              <FormControl>
                <FormLabel required={isRequired('totalAcreInches')}>
                  {t('acreInchesApplied')}
                </FormLabel>
                <Field
                  component={FMTextField}
                  name={`${name}.totalAcreInches`}
                  InputProps={{
                    type: 'number',
                    endAdornment: (
                      <InputAdornment position="end">{t('acre-inches')}</InputAdornment>
                    ),
                    inputProps: {
                      min: 0,
                    },
                  }}
                  data-testid={`${name}.totalAcreInches`}
                />
              </FormControl>
            )}

            <FormControl>
              <FormLabel required={isRequired('units') || isRequired('rate')}>
                {t('rateUnitLabel')}
              </FormLabel>

              <Grid container spacing={1}>
                <Grid size={{ xs: 4 }}>
                  <NumberInputField
                    name={`${name}.rate`}
                    data-testid={`${name}.rate`}
                    min={0}
                    InputProps={{ sx: { width: '100%' } }}
                  />
                </Grid>

                <Grid
                  size={{ xs: 8 }}
                  sx={{
                    '& .MuiFormControl-root': {
                      width: '100%',
                    },
                  }}
                >
                  <SelectField
                    fieldName={`${name}.units`}
                    options={IRRIGATION_UNITS}
                    renderOption={context => t('unit', { context })}
                  />
                </Grid>
              </Grid>
            </FormControl>
          </Stack>
        </Collapse>
      </Stack>
    </FormControl>
  )
}
