import { Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'

import { TraitId } from '@cibo/core'
import { ResourceDetailDisplay } from '@cibo/ui'

type Props = {
  traitId: TraitId
  year?: number
  params: GridRenderCellParams
}

export const DefaultCell = ({ traitId, year, params }: Props) => {
  const detail = params.row.resolveStandingDetail(traitId, year)

  return <Typography>{ResourceDetailDisplay.cellLabel(detail.result, traitId)}</Typography>
}
