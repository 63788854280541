import { FieldQueryOptions } from '@cibo/core'
import { AcreageFilter, ResponsiveDialog, USCountyFilter, USStateFilter } from '@cibo/ui'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fade,
  Popover,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { Form, Formik, useFormikContext } from 'formik'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginatedFieldsContext } from '../PaginatedFieldsContext'
import { CashCropFilter } from './CashCropFilter'
import { CoverCropFilter } from './CoverCropFilter'
import { FarmFilter } from './FarmFilter'
import { GrowerFilter } from './GrowerFilter'
import { LabelFilter } from './LabelFilter'
import { LastUpdatedFilter } from './LastUpdatedFilter'
import { ProgramStatusFilter } from './ProgramStatusFilter'
import { TillageFilter } from './TillageFilter'

interface PaginatedFieldsTableFiltersProps {
  anchorEl?: HTMLButtonElement
  filters: FieldQueryOptions
  baseFilters?: FieldQueryOptions
  onClose: () => void
  onReset: () => void
  onSearch: (filters: FieldQueryOptions) => void
  filterCount?: number
}

// complexity here is due to conditionally displaying filters, splitting out would not benefit clarity
// eslint-disable-next-line complexity
const PaginatedFieldsTableFiltersBase = ({
  anchorEl,
  onClose,
  onReset,
  filterCount = 0,
}: Omit<PaginatedFieldsTableFiltersProps, 'filters' | 'onSearch'>) => {
  const { t } = useTranslation('@cibo/landmanager/PaginatedFieldsTableFilters')
  const theme = useTheme()
  const isMdDown = useMediaQuery(theme.breakpoints.down('md'))
  const { handleSubmit } = useFormikContext()
  const { hideFilters } = useContext(PaginatedFieldsContext)

  const filterForm = (
    <Stack
      spacing={2}
      divider={
        <Divider orientation={isMdDown ? 'horizontal' : 'vertical'} variant="middle" flexItem />
      }
      direction={isMdDown ? 'column' : 'row'}
      data-testid="fields-table-filters"
    >
      {!hideFilters['fdrStatusAnyOf'] && (
        <Stack spacing={2} sx={{ width: isMdDown ? '100%' : 300 }}>
          <Typography variant="subtitle1">{t('programs')}</Typography>
          {!hideFilters['fdrStatusAnyOf'] && <ProgramStatusFilter />}
        </Stack>
      )}
      {(!hideFilters['cashCrop'] || !hideFilters['coverCrop'] || !hideFilters['tillage']) && (
        <Stack spacing={2} sx={{ width: isMdDown ? '100%' : 300 }}>
          <Typography variant="subtitle1">{t('practices')}</Typography>
          {!hideFilters['cashCrop'] && <CashCropFilter />}
          {!hideFilters['coverCrop'] && <CoverCropFilter />}
          {!hideFilters['tillage'] && <TillageFilter />}
        </Stack>
      )}

      {(!hideFilters['fips'] || !hideFilters['acresLt']) && (
        <Stack spacing={2} sx={{ width: isMdDown ? '100%' : 300 }}>
          <Typography variant="subtitle1">{t('location')}</Typography>
          {!hideFilters['fips'] && <USStateFilter />}
          {!hideFilters['fips'] && <USCountyFilter />}
          {!hideFilters['acresLt'] && !hideFilters['acresGt'] && <AcreageFilter />}
        </Stack>
      )}

      {(!hideFilters['userId'] ||
        !hideFilters['termsets'] ||
        !hideFilters['labels'] ||
        !hideFilters['lastUpdatedGt']) && (
        <Stack spacing={2} sx={{ width: isMdDown ? '100%' : 300 }}>
          <Typography variant="subtitle1">{t('other')}</Typography>
          {!hideFilters['userId'] && <GrowerFilter />}
          {!hideFilters['termsets'] && <FarmFilter />}
          {!hideFilters['labels'] && <LabelFilter />}
          {!hideFilters['lastUpdatedGt'] && !hideFilters['lastUpdatedLt'] && <LastUpdatedFilter />}
        </Stack>
      )}
    </Stack>
  )

  const formActions = (
    <Stack direction="row" justifyContent="flex-end" spacing={1}>
      {filterCount > 0 && (
        <Stack spacing={2} direction="row">
          <Button onClick={onReset} variant="outlined">
            {t('clearFilters', { count: filterCount })}
          </Button>
        </Stack>
      )}
      <Button variant="text" onClick={onClose}>
        {t('cancel')}
      </Button>
      <Button variant="contained" onClick={(arg: any) => handleSubmit(arg)}>
        {t('search')}
      </Button>
    </Stack>
  )

  return (
    <>
      {isMdDown ? (
        <ResponsiveDialog open={!!anchorEl} onClose={onClose}>
          <DialogTitle>{t('filters')}</DialogTitle>
          <DialogContent>{filterForm}</DialogContent>
          <DialogActions>{formActions}</DialogActions>
        </ResponsiveDialog>
      ) : (
        <Popover
          open={!!anchorEl}
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          TransitionComponent={Fade}
          sx={{ marginTop: 1 }}
        >
          <Stack padding={2} spacing={2}>
            {filterForm}

            {formActions}
          </Stack>
        </Popover>
      )}
    </>
  )
}

export const PaginatedFieldsTableFilters = ({
  filters,
  baseFilters,
  onSearch,
  ...rest
}: PaginatedFieldsTableFiltersProps) => (
  <Formik initialValues={{ ...baseFilters, ...filters }} onSubmit={onSearch} enableReinitialize>
    <Form>
      <PaginatedFieldsTableFiltersBase {...rest} />
    </Form>
  </Formik>
)
