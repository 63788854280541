import { useTheme } from '@mui/material'
import { PropsWithChildren, useMemo } from 'react'
import { Layer, Source } from 'react-map-gl/dist/es5'
import { MapTheme } from '../const'

import { FillPaint, LinePaint } from 'mapbox-gl'

import { Config } from '../../../types'

export const PARCEL_HOVER_LAYER = 'parcel-hover'
export const PARCEL_SOURCE_ID = 'parcels'
export const PARCEL_SOURCE_LAYER_ID = 'parcel-shapes'

interface ParcelLayerProps {
  disable?: boolean
  fillPaint?: FillPaint
  linePaint?: LinePaint
  search?: boolean
}

export const ParcelLayer = ({
  children,
  fillPaint = {},
  linePaint = {},
  disable,
}: PropsWithChildren<ParcelLayerProps>) => {
  const theme = useTheme<MapTheme>()

  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')
  const tiles = useMemo(
    () => [`${maptilesServiceUrl}parcels/{z}/{x}/{y}.pbf`],
    [maptilesServiceUrl]
  )

  return (
    <Source id={PARCEL_SOURCE_ID} type="vector" tiles={tiles} minzoom={10.9} maxzoom={13}>
      <Layer
        id="parcel-outline"
        type="line"
        source-layer={PARCEL_SOURCE_LAYER_ID}
        beforeId="data-layer-anchor"
        layout={useMemo(
          () => ({
            visibility: disable ? 'none' : 'visible',
            'line-cap': 'round',
            'line-join': 'round',
          }),
          [disable]
        )}
        paint={{
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 11.25, 0, 11.75, 1],
          'line-color': useMemo(() => theme.palette.parcelLine, [theme]),
          'line-width': useMemo(() => ['interpolate', ['linear'], ['zoom'], 10, 0.75, 19, 5], []),
          ...linePaint,
        }}
      />
      <Layer
        id={PARCEL_HOVER_LAYER}
        type="fill"
        source-layer={PARCEL_SOURCE_LAYER_ID}
        layout={useMemo(() => ({ visibility: disable ? 'none' : 'visible' }), [disable])}
        paint={{
          'fill-color': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            theme.palette.hoverFill,
            'transparent',
          ],
          'fill-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            11.25,
            0,
            11.75,
            0.5,
            13,
            0.5,
            15,
            0.25,
            17,
            0.0,
          ],
          ...fillPaint,
        }}
      />
      {children}
    </Source>
  )
}
