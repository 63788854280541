import WarningIcon from '@mui/icons-material/WarningAmberOutlined'
import { Box, Stack, Tooltip, Typography } from '@mui/material'
import {
  DataGridPro,
  GridCellParams,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRow,
  GridRowParams,
  GridRowProps,
  GridValueFormatterParams,
  GridValueGetterParams,
  GridValueSetterParams,
} from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'
import { assocPath } from 'ramda'
import { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'

import { Collection } from '@cibo/core'
import { LandProfileDisplay } from '@cibo/ui'
import { LandThumbnail, LandThumbnailSkin } from '../../../components/LandThumbnail'

const nameSchema = Yup.string().required().min(1).matches(/\S+/).max(255)

const TooltipRow = (props: GridRowProps) => {
  const { t } = useTranslation('@cibo/landmanager/features/UploadFlow')

  return props.row?.alreadyExists ? (
    <Tooltip title={`${t('duplicateRow')}`}>
      <GridRow {...props} />
    </Tooltip>
  ) : (
    <GridRow {...props} />
  )
}

const DEFAULT_COLUMNS = (t: TFunction) => [
  {
    field: 'thumbnail',
    headerName: '',
    width: 80,
    resizable: false,
    renderCell: ({ row }: GridRenderCellParams) => (
      <LandThumbnail
        field={row._meta.landProfile}
        width={60}
        height={60}
        skin={LandThumbnailSkin.SMALL}
      />
    ),
  },
  {
    field: 'name',
    headerName: t('name'),
    hideable: false,
    editable: true,
    flex: 5,
    valueGetter: ({ row: { name } }: GridValueGetterParams) => `${name}`,
    renderCell: ({ row: { name, alreadyExists } }: GridRenderCellParams) => (
      <Stack direction="row" spacing={1}>
        {!!alreadyExists && <WarningIcon color="warning" />}
        <Typography>{name}</Typography>
      </Stack>
    ),
    valueSetter: (params: GridValueSetterParams) => {
      const name = params?.value?.toString()
      return { ...params.row, name }
    },
    preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
      try {
        nameSchema.validateSync(params.props.value)
        return { ...params.props }
      } catch (error) {
        return { ...params.props, error: true }
      }
    },
  },
  {
    field: 'acres',
    headerName: t('acres'),
    type: 'number',
    sortable: true,
    width: 150,
    flex: 1,
    valueFormatter: (params: GridValueFormatterParams<number | undefined>) =>
      LandProfileDisplay.acreage(params.value),
    valueGetter: (params: GridValueGetterParams<Collection, number | undefined>) => {
      return params.row._meta.landProfile.acreage
    },
  },
]

type Props = {
  fields?: any[]
  onEditsChanged(edits: any): void
  selectedFieldIds?: string[]
  setSelectedFields(selectedFieldIds: any): void
  setIsEditing: Dispatch<SetStateAction<boolean>>
}

export const UploadFieldTable = ({
  selectedFieldIds,
  setSelectedFields,
  fields,
  onEditsChanged,
  setIsEditing,
}: Props) => {
  const { t } = useTranslation('@cibo/landmanager/features/UploadFlow')
  const [edits, setEdits] = useState<Record<string, Record<string, any>>>({})

  const handleCommitCell = (params: { id: string; field: string; value: string }) => {
    setIsEditing(false)
    setEdits(assocPath([params.id, params.field], params.value, edits))
    setSelectedFields([...(selectedFieldIds || []), params.id])
  }

  useEffect(() => {
    onEditsChanged(edits)
  }, [edits])

  if (!fields) {
    return null
  }

  return (
    <Box sx={{ flex: 1, height: '300', display: 'flex' }}>
      <DataGridPro
        autoHeight
        slots={{ row: TooltipRow }}
        columns={DEFAULT_COLUMNS(t)}
        rows={fields}
        pageSizeOptions={[10]}
        checkboxSelection={true}
        pagination={true}
        paginationMode="client"
        rowSelectionModel={selectedFieldIds}
        onRowSelectionModelChange={setSelectedFields}
        processRowUpdate={row => {
          const params = {
            id: row.id,
            field: 'name',
            value: row.name,
          }
          handleCommitCell(params)
          return row
        }}
        onProcessRowUpdateError={console.log}
        onCellEditStart={() => setIsEditing(true)}
        disableRowSelectionOnClick
        isRowSelectable={({ row }: GridRowParams) => !row.alreadyExists}
        isCellEditable={({ row }: GridCellParams) => !row.alreadyExists}
      />
    </Box>
  )
}
