import { MapboxGeoJSONFeature } from 'mapbox-gl'
import { createContext, PropsWithChildren, useCallback, useState } from 'react'

interface LayerInteractionContextProps {
  clickedFeatures?: MapboxGeoJSONFeature[]
  setClickedFeatures: (features?: MapboxGeoJSONFeature[]) => void
  hoverFeatures?: MapboxGeoJSONFeature[]
  setHoverFeatures: (features?: MapboxGeoJSONFeature[]) => void
  dwellFeature?: any
  setDwellFeature: (feature: any) => void
}

export const LayerInteractionContext = createContext<LayerInteractionContextProps>({
  setClickedFeatures: () => null,
  setHoverFeatures: () => null,
  setDwellFeature: () => null,
})
LayerInteractionContext.displayName = 'LayerInteractionContext'

export const LayerInteractionProvider = ({ children }: PropsWithChildren<{}>) => {
  const [hoverFeatures, setHoverFeatures] = useState<MapboxGeoJSONFeature[]>()
  const [clickedFeatures, setClickedFeatures] = useState<MapboxGeoJSONFeature[]>()
  const [dwellFeature, setDwellFeature] = useState<any>()

  const optimizedSetHoveredFeatures = useCallback(
    (newFeatures?: MapboxGeoJSONFeature[]) => {
      if (!newFeatures) {
        setHoverFeatures(newFeatures)
      } else {
        if (!hoverFeatures) {
          setHoverFeatures(newFeatures)
        } else {
          //check for dupes
          const diff = hoverFeatures.filter(
            f =>
              !newFeatures.find(
                ({ id, sourceLayer }) => f.id === id && f.sourceLayer === sourceLayer
              )
          )
          if (diff.length > 0) {
            setHoverFeatures(newFeatures)
          }
        }
      }
    },
    [hoverFeatures]
  )

  const optimizedSetClickedFeatures = useCallback(
    (newFeatures?: MapboxGeoJSONFeature[]) => {
      if (!newFeatures) {
        setClickedFeatures(newFeatures)
      } else {
        if (!clickedFeatures) {
          setClickedFeatures(newFeatures)
        } else {
          //check for dupes
          const diff = clickedFeatures.filter(
            f =>
              !newFeatures.find(
                ({ id, sourceLayer }) => f.id === id && f.sourceLayer === sourceLayer
              )
          )
          if (diff.length > 0) {
            setClickedFeatures(newFeatures)
          }
        }
      }
    },
    [clickedFeatures]
  )

  return (
    <LayerInteractionContext.Provider
      value={{
        clickedFeatures,
        setClickedFeatures: optimizedSetClickedFeatures,
        hoverFeatures,
        setHoverFeatures: optimizedSetHoveredFeatures,
        dwellFeature,
        setDwellFeature,
      }}
    >
      {children}
    </LayerInteractionContext.Provider>
  )
}
