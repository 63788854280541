import { MutationCacheNotifyEvent, useMutationSuccessCallback } from '@cibo/ui'
import { QUERY_KEY } from '../../../queries/queryKey'

// Notify a listener that a user's field list has potentially changed.
export const useFieldsChanged = <T>(callback: (event: MutationCacheNotifyEvent<T>) => void) =>
  useMutationSuccessCallback(
    [
      QUERY_KEY.FIELD_ASSIGN_USER,
      QUERY_KEY.FIELD_CONFIRM_IMPORT,
      QUERY_KEY.FIELD_CREATE,
      QUERY_KEY.FIELD_DELETE,
    ],
    callback
  )
