import { Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const BiomassBurningCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/BiomassBurningEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  return (
    <Typography>{detail.result === 0 ? t('no') : t('result', { count: detail.result })}</Typography>
  )
}
