import {
  RDEqipPracticesIrrigationMethod,
  RDEqipPracticesIrrigationMethodInput,
  TraitId,
} from '@cibo/core'
import { object, string } from 'yup'

import { ITraitFeatureByIdYear } from '../../types'
import { findInitialValuesDetail } from '../../utils'
import { EqipIrrigationMethodCell } from './EqipIrrigationMethodCell'
import { EqipIrrigationMethodCellEditor } from './EqipIrrigationMethodCellEditor'
import { EqipIrrigationMethodEditor } from './EqipIrrigationMethodEditor'

const TRAIT_ID: TraitId = 'eqipPracticesIrrigationMethod'

export const EqipIrrigationMethod: ITraitFeatureByIdYear<RDEqipPracticesIrrigationMethod> = {
  traitId: TRAIT_ID,
  hasContent: true,
  cellDisplay: EqipIrrigationMethodCell,
  editor: EqipIrrigationMethodEditor,
  cellEditor: EqipIrrigationMethodCellEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail<RDEqipPracticesIrrigationMethod>(TRAIT_ID)(props)
    return (
      detail?.input || ({ furrowIrrigation: '' } as unknown as RDEqipPracticesIrrigationMethodInput)
    )
  },
  ns: '@cibo/landmanager/EqipIrrigationMethod',
  validationSchema: ({ t }) =>
    object({
      furrowIrrigation: string().oneOf(['yes', 'no', 'unknown']).required(t('required')),
    }).required(t('required')),
}
