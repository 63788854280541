import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'

import { MP_AUTH_SESSION_INVALID, MP_AUTH_SESSION_VALID } from '@cibo/core'

export const authSessionValidAction = () => ({
  type: MP_AUTH_SESSION_VALID,
})

export const authSessionValid =
  (): ThunkAction<void, any, null, Action<string>> => async dispatch => {
    dispatch(authSessionValidAction())
  }

export const authSessionInvalidAction = () => ({
  type: MP_AUTH_SESSION_INVALID,
})

export const authSessionInvalid =
  (): ThunkAction<void, any, null, Action<string>> => async dispatch => {
    dispatch(authSessionInvalidAction())
  }
