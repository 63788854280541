import { SupplyShedAPI } from '@cibo/core'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { QUERY_KEY } from './queryKey'

export const useDeleteUserSupplySheds = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (resourceIds: string[]) => SupplyShedAPI.delete({ resourceIds }),
    onSuccess: (data, response: any) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.SUPPLY_SHEDS_PAGINATED] })
      response.resourceIds?.forEach((resourceId: string) => {
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.SUPPLY_SHED, resourceId] })
      })
    },
  })
}
