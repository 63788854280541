import { GridRowId } from '@mui/x-data-grid-pro'
import { UseQueryResult } from '@tanstack/react-query'
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'

import { CarbonComparisonRequest, FieldModel, MpxResponseError, TillageType } from '@cibo/core'
import { AuthUserPermission, usePermissions } from '@cibo/profile'

import { useNavigate } from 'react-router-dom'
import { PaginatedFieldsContext } from '../../components/PaginatedFieldsTable/PaginatedFieldsContext'
import { useCarbonLabPractices } from './queries'

export interface CarbonLabState {
  comparisonQuery: CarbonComparisonRequest
  resetComparisonQuery: () => void
  setComparisonQuery: (query: CarbonComparisonRequest) => void
  selectedFields: GridRowId[]
  setSelectedFields: (selectedFields: GridRowId[]) => void
  navigateToLab: () => void
  isPresent: boolean
}

export const CarbonLabContext = createContext<CarbonLabState>({
  comparisonQuery: {
    resourceIds: [],
    baselinePracticesName: 'ActualPractices',
    practicesName: 'RegionalPractices',
    fertilizerReduction: 1,
    hasCoverCrop: false,
    tillage: TillageType.CONVENTIONAL_TILLAGE,
  },
  resetComparisonQuery: console.log,
  setComparisonQuery: console.log,
  selectedFields: [],
  setSelectedFields: console.log,
  navigateToLab: console.log,
  isPresent: false,
})
CarbonLabContext.displayName = 'CarbonLabContext'

const DEFAULT_CARBON_COMPARISON_QUERY = {
  resourceIds: [],
  baselinePracticesName: 'ActualPractices',
  practicesName: 'RegionalPractices',
  fertilizerReduction: 1,
  hasCoverCrop: false,
  tillage: TillageType.CONVENTIONAL_TILLAGE,
} as CarbonComparisonRequest

export const CarbonLabProvider = ({
  children,
  labPath,
}: PropsWithChildren<{ labPath: string }>) => {
  const navigate = useNavigate()
  const { can } = usePermissions()
  const { selectedRowIds: selectedFields, setSelectedRowIds: setSelectedFields } =
    useContext(PaginatedFieldsContext)
  const [comparisonQuery, setComparisonQuery] = useState<CarbonComparisonRequest>(
    DEFAULT_CARBON_COMPARISON_QUERY
  )
  const resetComparisonQuery = useCallback(() => {
    setComparisonQuery(DEFAULT_CARBON_COMPARISON_QUERY)
  }, [])

  return (
    <CarbonLabContext.Provider
      value={{
        comparisonQuery,
        resetComparisonQuery,
        setComparisonQuery,
        selectedFields,
        setSelectedFields,
        isPresent: can({ useAny: AuthUserPermission.CARBON_LAB }),
        navigateToLab: () => navigate(labPath),
      }}
    >
      {children}
    </CarbonLabContext.Provider>
  )
}

export interface UseCarbonLabState extends CarbonLabState {
  result?: UseQueryResult<FieldModel | undefined, MpxResponseError>
}

export const useCarbonLab = () => {
  const context = useContext(CarbonLabContext)
  const practices = useCarbonLabPractices()
  return {
    ...context,
    practices,
  }
}
