import { useQueryClient } from '@tanstack/react-query'
import { path } from 'ramda'
import { useEffect } from 'react'

// MutationCacheNotifyEvent is described in tanstack docs but not exported????
// This enforces the shape of .action for our convenience.
export type MutationCacheNotifyEvent<T> = {
  type?: 'updated'
  action: {
    type?: 'success'
    data: T
  }
}

export const getEventMutationKey = path<string[]>(['mutation', 'options', 'mutationKey'])

export const useMutationSuccessCallback = <T>(
  mutationKeyPrefixes: string[],
  callback: (event: MutationCacheNotifyEvent<T>) => void
) => {
  const queryClient = useQueryClient()

  const mutationListener = (event: any) => {
    if (!event.action) return

    const mutationKey = getEventMutationKey(event)

    if (mutationKey && mutationKeyPrefixes.includes(mutationKey[0])) {
      if (event.type === 'updated' && event.action.type === 'success') {
        callback(event as MutationCacheNotifyEvent<T>)
      }
    }
  }

  useEffect(() => {
    const unsubscribe = queryClient.getMutationCache().subscribe(mutationListener)
    return unsubscribe
  }, [])
}
