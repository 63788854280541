import { Dispatch, SetStateAction, createContext } from 'react'
import { MapStyles, MapStylesType } from './const'

export type LayerContextProps = {
  cdlYear: string
  mapStyle: MapStyles
  setCdlYear(year: string): void
  setMapStyle: Dispatch<SetStateAction<MapStylesType | undefined>>
  setShowCounties: Dispatch<SetStateAction<boolean | undefined>>
  setShowParcels: Dispatch<SetStateAction<boolean | undefined>>
  setShowSections: Dispatch<SetStateAction<boolean | undefined>>
  setShowTownships: Dispatch<SetStateAction<boolean | undefined>>
  setTerrainExaggeration: Dispatch<SetStateAction<number>>
  showCounties: boolean
  showParcels: boolean
  showSections: boolean
  showTerrain: boolean
  showTownships: boolean
  showWatersheds: boolean
  terrainExaggeration: number
  toggleCounties: () => void
  toggleParcels: () => void
  toggleSections: () => void
  toggleTerrain: () => void
  toggleTownships: () => void
  toggleWatersheds: () => void
  bounds?: string
  setBounds: Dispatch<SetStateAction<string | undefined>>
}

export const LayerContext = createContext<Partial<LayerContextProps>>({})
LayerContext.displayName = 'LayerContext'
