import { ResourceDetailYearly } from './ResourceDetail'

export const CASH_CROP_HARVEST_TYPES = [
  'bale',
  'fruit',
  'grain',
  'grazed',
  'haylage',
  'none',
  'not_available',
  'root',
  'seed',
  'silage',
  'tuber',
] as const
export type RDCashCropHarvestType = typeof CASH_CROP_HARVEST_TYPES[number]

export const CASH_CROP_PLANTING_RATE_UNIT = ['seedsPerAcre', 'poundsPerAcre'] as const
type RDCashCropPlantingRateUnit = typeof CASH_CROP_PLANTING_RATE_UNIT[number]

export interface RDCashCropInput {
  crop: RDCashCropSpecies
  plantedAt?: Date | null
  harvestedAt?: Date | null
  residuePercentageRemoved?: number
  yieldPerAcre?: number // does yield warrant it's own detail?
  acres?: number
  plantingRate?: number
  plantingRateUnit?: RDCashCropPlantingRateUnit
  harvestType?: RDCashCropHarvestType
  herbicideBurndown?: boolean
  fireBurndown?: boolean
}

export interface RDCashCrop extends ResourceDetailYearly<RDCashCropInput, RDCashCropSpecies> {
  traitId: 'cashCrop'
}

export const CASH_CROP_SPECIES = [
  'alfalfa',
  'almonds',
  'amaranth',
  'annualFescue',
  'apples',
  'apricots',
  'argentinecanola',
  'asparagus',
  'barley',
  'barren',
  'bermudagrass',
  'berseemClover',
  'birdsfootTrefoil',
  'blueberries',
  'broccoli',
  'buckwheat',
  'cabbage',
  'camelina',
  'caneberries',
  'canola',
  'cantaloupes',
  'carrots',
  'cauliflower',
  'celery',
  'cerealRye',
  'cherries',
  'chickpeas',
  'chicory',
  'citrus',
  'cloverWildflowers',
  'cocklebur',
  'corn',
  'cornSilage',
  'cotton',
  'cottonPima',
  'cottonUpland',
  'cowpea',
  'cranberries',
  'crestedwheatgrass',
  'cucumbers',
  'drybeans',
  'durumwheat',
  'eggplants',
  'fallow',
  'fieldPea',
  'flaxseed',
  'forest',
  'foxtailMillet',
  'garlic',
  'giantfoxtail',
  'gourds',
  'grainsorghum',
  'grapes',
  'greenfoxtail',
  'greens',
  'hay',
  'hayGrass',
  'herbs',
  'honeydewmelons',
  'hops',
  'johnsongrass',
  'kale',
  'lentils',
  'lettuce',
  'lupin',
  'meadowbromegrass',
  'medic',
  'mesquite',
  'millet',
  'mint',
  'miscvegsandfruits',
  'mungbean',
  'mustard',
  'nectarines',
  'noMajority',
  'oak',
  'oats',
  'oilpalm',
  'olives',
  'onions',
  'oranges',
  'other',
  'otherhayNonalfalfa',
  'othersmallgrains',
  'othertreecrops',
  'pasture',
  'peaches',
  'peanuts',
  'pearlMillet',
  'pears',
  'peas',
  'pecans',
  'peppers',
  'phacelia',
  'pine',
  'pistachios',
  'plums',
  'polishcanola',
  'pomegranates',
  'poplar',
  'popororncorn',
  'potatoes',
  'proscoMillet',
  'prunes',
  'pumpkins',
  'radishes',
  'rapeseed',
  'redClover',
  'rice',
  'russianwildrye',
  'rye',
  'ryegrass',
  'safflower',
  'sainfoin',
  'seedCorn',
  'seedSoybeans',
  'sodGrassseed',
  'sorghum',
  'soybeanorganic',
  'soybeans',
  'speltz',
  'spinach',
  'springbarley',
  'springOsr',
  'springwheat',
  'squash',
  'strawberries',
  'sudanGrass',
  'sugarbeets',
  'sugarcane',
  'sunflower',
  'sweetClover',
  'sweetcorn',
  'sweetpotatoes',
  'switchgrass',
  'teff',
  'tobacco',
  'tomatoes',
  'triticale',
  'turnips',
  'velvetleaf',
  'vetch',
  'walnuts',
  'watermelons',
  'westernwheatgrass',
  'wheat',
  'wheatSilage',
  'wheatorganic',
  'whiteClover',
  'winterwheat',
  'yellowPeas',
  'noMajority',
] as const

export type RDCashCropSpecies = typeof CASH_CROP_SPECIES[number]

const HIDDEN_CASH_CROPS = ['noMajority']
export const DISPLAYABLE_CASH_CROPS = CASH_CROP_SPECIES.filter(
  c => !HIDDEN_CASH_CROPS.includes(c)
) as RDCashCropSpecies[]

export const PRIORITY_CASH_CROPS = [
  'cotton',
  'corn',
  'soybeans',
  /* 
    The following options were added at the request of Truterra (CPD-2869).
    The issue is, of course, that the list of prioritized crops is meant to
    align to the crops that we can model, but we don't have models for these.
  **/
  'durumwheat',
  'springwheat',
  'winterwheat',
  'fallow',
] as RDCashCropSpecies[]
export const PRIORITIZED_DISPLAYABLE_CASH_CROPS = [
  ...PRIORITY_CASH_CROPS,
  ...DISPLAYABLE_CASH_CROPS.filter(c => !PRIORITY_CASH_CROPS.includes(c)),
] as RDCashCropSpecies[]
