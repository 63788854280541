import { Config, getMpNodeAgent } from '@cibo/core'

export const fetchSelectedPracticesPDF = async ({ engagementId }: { engagementId: string }) => {
  const agent = getMpNodeAgent()
  const PDF_PATH = `${Config.get(
    'NODE_SERVICE_URL'
  )}selected-practices/pdf?engagementId=${engagementId}`
  return agent.get(PDF_PATH).then(response => {
    const pdfBytes = base64ToArrayBuffer(response.data as string)
    return new Blob([new Uint8Array(pdfBytes)], { type: 'application/pdf' })
  })
}

function base64ToArrayBuffer(base64: string) {
  const binaryString = atob(base64)
  const bytes = new Uint8Array(binaryString.length)
  for (let i = 0; i < binaryString.length; i++) {
    bytes[i] = binaryString.charCodeAt(i)
  }
  return bytes.buffer
}
