import { Store, applyMiddleware, combineReducers, createStore } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly'
import { persistReducer } from 'redux-persist'
import thunkMiddleware from 'redux-thunk'

import { getAuthReducer } from '@cibo/profile'
import { withActionsMiddleware } from '@cibo/ui'

import { storage } from './defaultStorage'

const authReducerConfig = {
  key: 'auth',
  storage,
  whitelist: ['environmentAccess'],
  blacklist: ['session'],
}

const combinedReducers = combineReducers({
  authReducer: persistReducer(authReducerConfig, getAuthReducer(storage)),
})

export type AppState = ReturnType<typeof combinedReducers>

const middlewares = [withActionsMiddleware, thunkMiddleware]
const middleWareEnhancer = applyMiddleware(...middlewares)

const store: Store = createStore(combinedReducers, composeWithDevTools(middleWareEnhancer))

export { store }
