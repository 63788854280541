import { MultiPolygon, Point } from 'geojson'
import { CarbonCreditSaleStatus } from '../types/CarbonCreditTypes'
import { Array2d, LatLong } from '../utils/geometry'

export enum MpxQueryTrigger {
  SEARCH_FILTER_CHANGE = 'SEARCH_FILTER_CHANGE',
  TYPEAHEAD_RESULT_SELECTED = 'TYPEAHEAD_RESULT_SELECTED',
  MAP_MOVED = 'MAP_MOVED',
  CLUSTER_CLICKED = 'CLUSTER_CLICKED',
  SEARCH_SUBMITTED = 'SEARCH_SUBMITTED',
  ADDRESS_BAR = 'ADDRESS_BAR',
  SEARCH_ACTION = 'SEARCH_ACTION',
  QUERY_LINK = 'QUERY_LINK',
  AUTH_STATE_CHANGED = 'AUTH_STATE_CHANGED',
}

export type MpxFieldHistoryTypes = 'corn' | 'soybeans' | '-any'
export type MpxFieldUsageTypes = 'dairy' | 'pasture' | 'forest' | 'ranch' | '-any'

export type MpxGeoPoliticalAggregationTypes =
  | 'state'
  | 'county'
  | 'stateCounty'
  | 'stateCountyTownship'
  | 'noAggregation'

export enum MpxGeoPoliticalAggregationType {
  STATE = 'state',
  COUNTY = 'stateCounty',
  NONE = 'noAggregation',
  TOWNSHIP = 'stateCountyTownship',
}

export type MpxSearchTagTypes = 'lcav' | 'residential' | '-any'

export type MpxAggMetricFieldType =
  | 'valuePerAcre'
  | 'nccpi'
  | 'statePi'
  | 'productivity'
  | 'sustainability'
  | 'stability'
  | 'tillablePercent'
  | 'acreage'
  | 'operatorRent'
  | 'count' // not sure if this should be allowed but adding to match MapPreferenceDataLayer
  | 'regenerativePotential' // not sure if this should be allowed but adding to match MapPreferenceDataLayer

export const MpxAggMetricField: Record<string, MpxAggMetricFieldType> = {
  VALUATION: 'valuePerAcre',
  LEASE_VALUATION: 'operatorRent',
  NCCPI: 'nccpi',
  PRODUCTIVITY: 'productivity',
  SUSTAINABILITY: 'sustainability',
  STABILITY: 'stability',
  ACREAGE: 'acreage',
  TILLABLE_PERCENT: 'tillablePercent',
  REGENERATIVE_POTENTIAL: 'regenerativePotential',
}

export interface MpxServerQuery extends DynamicLookup {
  fullTextSearch?: string
  lowerLeft?: string
  upperRight?: string
  tags?: string
}

export interface MpxSearchActionQuery extends DynamicLookup {
  fullTextSearch?: string
  lowerLeft?: [number, number]
  upperRight?: [number, number]
}

interface DynamicLookup {
  [key: string]: any
}

export interface MpxSearchQuery extends DynamicLookup {
  aggMetricField?: MpxAggMetricFieldType
  fullTextSearch?: string
  lowerLeft?: LatLong
  upperRight?: LatLong
  zoom?: number
  state?: string
  countOnly?: boolean
  county?: string
  parcelId?: string
  parcelPkId?: string
  comparisonFields?: string
  owner?: string
  tags?: MpxSearchTagTypes[]
  geoPoliticalAggType?: MpxGeoPoliticalAggregationTypes
  acreageGe?: number
  acreageLt?: number
  valuePerAcreGe?: number
  valuePerAcreLt?: number
  operatorRentGe?: number
  operatorRentLt?: number
  nccpiGe?: number
  nccpiLt?: number
  productivityGe?: number
  productivityLt?: number
  regenPotentialPerAcreGe?: number
  regenPotentialPerAcreLt?: number
  stabilityGe?: number
  stabilityLt?: number
  statePiGe?: number
  statePiLt?: number
  sustainabilityGe?: number
  sustainabilityLt?: number
  fldHistory?: MpxFieldHistoryTypes[]
  fldUsage?: MpxFieldUsageTypes[]
  tillablePercentGe?: number
  tillablePercentLt?: number
}

export type YieldForecastCrop = 'corn' | 'soybeans' | 'cotton'

export interface MpxSearchAggregationValueProperties {
  clusterId: number
  fips: string
  label: string
  labelParts: { primary: string; secondary?: string }
  geoPoliticalAggType: MpxGeoPoliticalAggregationType
  boundingBox: Array2d
  formattedLabel: string
  count: number
  formattedCount?: string

  aggMetricField: MpxAggMetricFieldType
  aggMetricValue: number
  formattedAverage?: string

  fillColor?: string
}

export interface MpxSearchAggregationValue {
  geometry: Point
  properties: MpxSearchAggregationValueProperties
}

export interface MpxSearchResultParcelProperties {
  'symbol-sort-key': number
  acreage: number
  agPerAcre?: number
  displayValue?: string
  featureId: number
  fillColor?: string
  geometrySlug: string
  isLcavField: boolean
  isSearchResult: boolean
  labelPosition: [number, number]
  location: [number, number]
  mpGeoHash: number
  nccpi?: number
  operatorRent?: number
  productivity?: number
  regenPotentialPerAcre?: number
  regenPotential?: number
  sustainability?: number
  tags: string
  tillableGeometrySlug: string
  tillablePercent: number
  valuePerAcre: number
  label?: {
    primary: string
    secondary?: string
    tertiary?: string
  }
}

export interface MpxSearchResultParcel {
  id: string
  type: 'Feature'
  geometry: MultiPolygon | Point
  properties: MpxSearchResultParcelProperties
}

export enum MpxSearchActionType {
  CLEAR_LOCATION = 'clearLocation',
}

export interface MpxSearchAction {
  id: string
  label: string
  type: MpxSearchActionType
  query: MpxSearchActionQuery
  displayBox?: { upperRight: [number, number]; lowerLeft: [number, number] }
}

export interface MpxSearchParsedParams {
  actionQuery: MpxSearchQuery
  fullText: string
  label: string
  removeQuery: MpxSearchQuery
  type: PILL_TYPE
}

export enum PILL_TYPE {
  COUNTY = 'county',
  TOWNSHIP = 'township',
  STATE = 'state',
  OWNER = 'owner',
  PARCEL_ID = 'parcelId',
}

export enum FeedItemType {
  FIELD = 'field',
}

// @deprecated
interface InventoryContainer {}

// @deprecated
export interface FeedItem extends InventoryContainer {
  type: FeedItemType
  name: string
  featureId: number
  area: number
  tillableAcres: number
  geometrySlug: string
  tillableGeometrySlug: string
  modifiedDate: Date
}

// @deprecated
export interface CarbonCreditSearchResult extends InventoryContainer {
  featureId: number
  impactProfile: { saleStatus: CarbonCreditSaleStatus }
}

export interface MpxSearchResult {
  feed?: FeedItem[]
  duration: number
  requestUri: string
  aggregating: boolean
  numBuckets: number
  numSearchResults: number
  numResultsInBoundingBox: number
  samplingMultiplier: number
  showingPoints: boolean
  showingShapes: boolean
  aggregationValues?: MpxSearchAggregationValue[]
  sampleResults?: MpxSearchResultParcel[]
  parcelResults?: MpxSearchResultParcel[]
  features?: {
    items: CarbonCreditSearchResult[]
  }
  lowerLeft?: [number, number]
  upperRight?: [number, number]
  searchActions?: MpxSearchAction[]
  geoPoliticalAggType?: MpxGeoPoliticalAggregationType
  parsedParams?: MpxSearchParsedParams[]
  unparsedFullText?: string
}

export enum MpxSearchSimilarOptionsField {
  ACREAGE = 'acreage',
  LEASE_VALUE = 'operatorRent',
  PRODUCTIVITY = 'productivity',
  PUBLIC_PRODUCTIVITY = 'nccpi',
  STABILITY = 'stability',
  SUSTAINABILITY = 'sustainability',
  TAX_ASSESSED_VALUE = 'assessPerAcre',
  TILLABLE_PERCENT = 'tillablePercent',
  TILLABLE = 'tillableAcres',
  VALUATION = 'valuePerAcre',
}

export interface MpxSearchSimilarComparisonFields {
  field: MpxSearchSimilarOptionsField
  weight: number
  scale: number
}

export interface MpxSearchSimilarQuery {
  parcelPkId: string
  comparisonFields?: MpxSearchSimilarComparisonFields[]
  limitTo50km?: boolean
}
