import { Field, FieldModel, FieldsAPI, ResourceDetail } from '@cibo/core'
import { QUERY_KEY as MAPGL_QUERY_KEY } from '@cibo/mapgl'
import { logRequestError } from '@cibo/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { QUERY_KEY } from '../../../queries/queryKey'

export type SaveDetailRequest<T extends ResourceDetail = ResourceDetail> = {
  resourceId: string
  details: T[]
}

export const useSaveDetail = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: (request: SaveDetailRequest) =>
      FieldsAPI.updateField(request.resourceId, request.details).then(
        f => new FieldModel(f.data.item as Field)
      ),

    mutationKey: [QUERY_KEY.SAVE_DETAIL],

    onSuccess: (result: FieldModel, request: SaveDetailRequest) => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDSET] })
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.FIELDS, request.resourceId] })
      queryClient.invalidateQueries({ queryKey: [MAPGL_QUERY_KEY.FIELDS_MAP_LAYER] })
    },
    onError: (error: AxiosError) => {
      logRequestError(error, { useQuery: 'useSaveDetail' })
    },
  })
}
