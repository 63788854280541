import { useTheme } from '@mui/material'
import { useContext } from 'react'
import { Layer, Source } from 'react-map-gl/dist/es5'
import { MapTheme } from '../const'

import { LayerContext } from './LayerContext'

export const SectionLayer = () => {
  const theme = useTheme<MapTheme>()
  const { showSections } = useContext(LayerContext)

  return (
    <Source id="sections" type="vector" url="mapbox://cibo-apps.plss">
      <Layer
        id="section-outline"
        type="line"
        source-layer="sections"
        source="sections"
        minzoom={11}
        layout={{
          visibility: showSections ? 'visible' : 'none',
        }}
        paint={{
          'line-dasharray': [1, 2],
          'line-color': theme.palette.sectionLine,
          'line-width': ['interpolate', ['linear'], ['zoom'], 11, 1.75, 14.5, 2.25],
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 11, 0, 11.25, 1],
        }}
      />
      <Layer
        id="section-label"
        type="symbol"
        source-layer="section-labels"
        source="sections"
        layout={{
          visibility: showSections ? 'visible' : 'none',
          'text-field': ['to-string', ['get', 'label']],
          'text-font': ['Nunito Sans', 'Arial Unicode MS Regular'],
          'text-size': [
            'interpolate',
            ['cubic-bezier', 0.2, 0, 0.7, 1],
            ['zoom'],
            11,
            8,
            12,
            12,
            15,
            32,
          ],
          'text-letter-spacing': 0.05,
        }}
        paint={{
          'text-color': theme.palette.sectionText,
          'text-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            11,
            0,
            11.25,
            0.85,
            12,
            0.85,
            14,
            0.55,
            14.5,
            0,
          ],
          'text-halo-color': ['interpolate', ['linear'], ['zoom'], 11, '#673e0e', 15, '#181006'],
          'text-halo-width': ['interpolate', ['linear'], ['zoom'], 12, 0, 15, 1.8],
        }}
      />
      <Layer
        id="section-edge-label"
        type="symbol"
        source-layer="sections"
        source="sections"
        minzoom={14}
        layout={{
          visibility: showSections ? 'visible' : 'none',
          'text-field': ['to-string', ['get', 'label']],
          'text-font': ['Arial Unicode MS Regular'],
          'text-size': ['interpolate', ['cubic-bezier', 0.2, 0, 0.7, 1], ['zoom'], 13, 8, 15, 18],
          'text-letter-spacing': 0.05,
        }}
        paint={{
          'text-color': theme.palette.sectionText,
          'text-opacity': ['interpolate', ['linear'], ['zoom'], 14, 0, 15, 0.85],
          'text-halo-color': ['interpolate', ['linear'], ['zoom'], 11, '#673e0e', 15, '#181006'],
          'text-halo-width': 1,
        }}
      />
    </Source>
  )
}
