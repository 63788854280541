/* istanbul ignore file */
import { MpxResponseBaseSingle, MpxResponseMeta, ProgramEngagement, getMpAgent } from '@cibo/core'
import { AxiosResponse } from 'axios'

const agent = getMpAgent()

const PATHS = {
  DOWNLOAD: '/eqip-document/download',
  REQUIRED: '/eqip-document/required',
  GENERATE: '/eqip-document/generate',
  URL: '/eqip-document/url',
  PROCESS_RETURN: '/eqip-document/processSigningReturnUrlParams',
}

export type RequiredDocumentsResponse = MpxResponseMeta & { documents: { traitKey: string }[] }

export class RequiredDocumentsAPI {
  static list({ engagementId, taskId }: { engagementId: string; taskId: string }) {
    return agent
      .get<RequiredDocumentsResponse>(PATHS.REQUIRED, { params: { id: engagementId, taskId } })
      .then(response => response.data)
  }

  static generate({ engagementId, taskId }: { engagementId: string; taskId: string }) {
    return agent
      .post<MpxResponseMeta>(`${PATHS.GENERATE}`, { id: engagementId, taskId })
      .then(response => response.data)
  }

  static url({
    engagementId,
    taskId,
    returnUrl,
  }: {
    engagementId: string
    taskId: string
    returnUrl: string
  }) {
    return agent
      .get<MpxResponseMeta & { docusignUrl: string }>(PATHS.URL, {
        params: {
          id: engagementId,
          returnUrl,
          taskId,
        },
      })
      .then(response => response.data)
  }

  static download({ engagementId, taskId }: { engagementId: string; taskId: string }) {
    return agent.get<AxiosResponse>(PATHS.DOWNLOAD, {
      params: {
        id: engagementId,
        taskId,
      },
      responseType: 'blob',
    })
  }

  static processSigningReturn({
    engagementId,
    taskId,
    eventParam,
  }: {
    engagementId: string
    taskId: string
    eventParam: string
  }) {
    return agent.post<MpxResponseBaseSingle<ProgramEngagement>>(PATHS.PROCESS_RETURN, {
      id: engagementId,
      event: eventParam,
      taskId,
    })
  }
}
