import { PracticeContentDataTypes, RecommendationPointsModel, sanitizeTestId } from '@cibo/core'
import { LineLimitTypography } from '@cibo/ui'
import Add from '@mui/icons-material/Add'
import Check from '@mui/icons-material/Check'
import Info from '@mui/icons-material/InfoOutlined'
import Lock from '@mui/icons-material/LockOutlined'
import Star from '@mui/icons-material/Star'
import { Button, Chip, IconButton, Stack, Typography } from '@mui/material'
import { Dispatch, SetStateAction } from 'react'
import { TFunction } from 'react-i18next'
import { PracticePointsBar } from './PracticePointsBar'
import { CombinedPractice } from './practicesListTypes'

export const practiceDescriptionColumn = ({
  setDrawerState,
  selectedPoolName,
  t,
}: {
  t: TFunction
  setDrawerState: Dispatch<
    SetStateAction<{
      open: boolean
      practice?: PracticeContentDataTypes
    }>
  >
  selectedPoolName?: string
}) => ({
  field: 'practice',
  headerName: t('practice'),
  flex: 2,
  renderCell: ({ row }: { row: CombinedPractice }) => {
    const title = row.fields?.name
    const subTitle = row?.fields?.definition
    const isEligible = !row.tags?.includes('Ineligible')

    return (
      <Stack sx={{ width: '100%', padding: '10px 0' }} spacing={0.5}>
        <Stack direction="row" alignItems="center">
          <Typography component="div" textOverflow="ellipsis" noWrap data-testid={`practice-${sanitizeTestId(title)}`}>
            {title}
          </Typography>

          <IconButton color="info" onClick={() => setDrawerState({ open: true, practice: row })}>
            <Info />
          </IconButton>
          {selectedPoolName && row.points[selectedPoolName]?.isAddon && (
            <Star
              fontSize="medium"
              // @ts-ignore mui hasn't appropriately typed the 'color' prop to accept extended themes
              color="rating"
            />
          )}
          {!isEligible && <Chip label={t('ineligible')} color="error" size="small" />}
        </Stack>

        <LineLimitTypography
          lineLimit={2}
          minHeight="40px"
          textOverflow="ellipsis"
          variant="body2"
          color="text.secondary"
        >
          {subTitle}
        </LineLimitTypography>
      </Stack>
    )
  },
})

export const practicePointsColumn = ({
  t,
  recommendationsPointsSummary,
}: {
  t: TFunction
  recommendationsPointsSummary?: RecommendationPointsModel
}) => ({
  field: 'points',
  headerName: t('points'),
  maxWidth: recommendationsPointsSummary?.allPools.length
    ? recommendationsPointsSummary?.allPools.length * 25
    : 25,
  renderCell: ({ row }: { row: CombinedPractice }) => {
    const practiceSummary = recommendationsPointsSummary?.practicePoints(row.fields.practiceId)

    return (
      <Stack direction="row" spacing={0.5}>
        {recommendationsPointsSummary?.allPools?.map((pool, index) => {
          const summary = practiceSummary?.find(summary => summary.rankingPoolName === pool.name)
          return (
            pool.maxPoints && (
              <PracticePointsBar
                key={pool.name}
                effect={summary?.effect || 0}
                maxPoints={recommendationsPointsSummary?.highestValuePracticeAcrossPrograms}
                symbol={pool.symbol}
              />
            )
          )
        })}
      </Stack>
    )
  },
})

export const practiceAddOrRemoveColumn = ({
  t,
  selectedPractices,
  onClickSelect,
  setDialogState,
  disabled,
}: {
  t: TFunction
  selectedPractices: string[]
  onClickSelect: (practiceId: string) => void
  setDialogState: Dispatch<
    SetStateAction<{
      open: boolean
      practice?: PracticeContentDataTypes
    }>
  >
  disabled?: boolean
}) => ({
  field: 'addOrRemove',
  headerName: '',
  minWidth: 120,
  maxWidth: 120,
  align: 'right',
  renderCell: ({ row }: { row: CombinedPractice }) => {
    const isEligible = !row.tags?.includes('Ineligible')
    const isSelected = selectedPractices.includes(row.fields.practiceId)

    return !isEligible ? (
      <Button disabled>{t('ineligible')}</Button>
    ) : isSelected ? (
      <Button
      data-testid='addedBtn'
        disabled={disabled}
        color="success"
        variant="contained"
        startIcon={disabled ? <Lock /> : <Check />}
        onClick={event => {
          event.stopPropagation()
          onClickSelect(row.fields?.practiceId)
        }}
      >
        {t('added')}
      </Button>
    ) : (
      <Button
      data-testid='addBtn'
        disabled={disabled}
        color="success"
        variant="outlined"
        startIcon={!disabled && <Add />}
        onClick={event => {
          event.stopPropagation()
          if (row.fields?.requirementText) {
            setDialogState({ open: true, practice: row })
          } else {
            onClickSelect(row.fields.practiceId)
          }
        }}
      >
        {t('add')}
      </Button>
    )
  },
})
