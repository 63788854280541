import { Box, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const Scrim = styled<OverridableComponent<any>>(Box)(({ theme }) => ({
  '&.MuiBox-root': {
    position: 'absolute',
    top: 0,
    bottom: 0,
    right: 0,
    left: 0,
    mixBlendMode: 'multiply',
    background:
      'linear-gradient(0deg, rgba(64, 64, 64, 0.75) 33.33%, rgba(64, 64, 64, 0.375) 100%)',
    zIndex: 0,
  },
}))
