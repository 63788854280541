interface PolygonPointGeneratorOptions {
  width: number
  height: number
  cellSize?: number
  variance?: number
}

export const generatePoints = ({
  width,
  height,
  cellSize = 75,
  variance = 0.75,
}: PolygonPointGeneratorOptions) => {
  /**
   * overscan by 2 cells in all directions
   */
  const overscanX = 2 * cellSize
  const overscanY = 2 * cellSize
  const columnCount = Math.floor(width / cellSize) + 4
  const rowCount = Math.floor(height / cellSize) + 4
  const pointCount = columnCount * rowCount
  const halfCell = cellSize / 2

  return {
    overscanX,
    overscanY,
    points: Array(pointCount)
      .fill(null)
      .map((e, i) => {
        const col = i % columnCount
        const row = Math.floor(i / columnCount)
        return [
          -overscanX + col * cellSize + halfCell + (Math.random() - 0.5) * cellSize * variance,
          -overscanY + row * cellSize + halfCell + (Math.random() - 0.5) * cellSize * variance,
        ]
      }),
  }
}
