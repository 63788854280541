import { RDFertilizerReduction } from '@cibo/core'
import { pathEq } from 'ramda'
import { boolean, object } from 'yup'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FertilizerReductionCell } from './FertilizerReductionCell'
import { FertilizerReductionCellEditor } from './FertilizerReductionCellEditor'
import { FertilizerReductionEditor } from './FertilizerReductionEditor'
import { FertilizerReductionRollup } from './FertilizerReductionRollup'

const TRAIT_ID = 'fertilizerReduction'

export const FertilizerReduction: ITraitFeatureByIdYear<RDFertilizerReduction> = {
  traitId: TRAIT_ID,
  cellDisplay: FertilizerReductionCell,
  cellEditor: FertilizerReductionCellEditor,
  editor: FertilizerReductionEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: FertilizerReductionRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          reduction: true,
        },
      }),
      isRollupValue: pathEq(['result'], true),
    },
  },
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail ? detail.input ?? { reduction: detail.result } : {}
  },
  ns: '@cibo/landmanager/DetailEditing/FertilizerReduction',
  validationSchema: ({ t }) =>
    object()
      .shape({
        reduction: boolean().required(
          t('required', {
            context: 'reduction',
          })
        ),
      })
      .required(t('required')),
}
