import { MenuItem, Select, SelectProps } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { ReactElement } from 'react'

type SelectFieldProps = SelectProps & {
  fieldName: string
  options: readonly string[]
  renderOption(option: string): ReactElement
}

export const SelectField = ({ fieldName, options, renderOption, ...rest }: SelectFieldProps) => (
  <Field id={fieldName} name={fieldName} data-testid={fieldName}>
    {({ field }: FieldProps) => (
      <Select
        id={fieldName}
        name={fieldName}
        data-testid={fieldName}
        onChange={field.onChange}
        value={field.value ?? ''}
        onBlur={field.onBlur}
        {...rest}
      >
        {options.map((option: string) => (
          <MenuItem key={option} value={option} data-testid={`${fieldName}::${option}`}>
            {renderOption(option)}
          </MenuItem>
        ))}
      </Select>
    )}
  </Field>
)
