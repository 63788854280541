import { FieldModel } from '@cibo/core'
import { LandThumbnail, LandThumbnailSkin } from '@cibo/landmanager'
import { Markdown, ResponsiveDialog, useFigures } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid2 as Grid,
  Stack,
  Typography,
} from '@mui/material'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useUpdateWorkflow, useWorkflow } from '../../../queries'
import { useLedgerConflicts } from './useLedgerConflicts'

type LedgerConflictsModalProps = {
  open: boolean
  onClose(): void
  workflowId?: string
  onResolved?: () => void
  context?: 'enroll' | 'commit'
  onResolveConflicts: (boundaryOverlapFields: FieldModel[]) => void
}

export const LedgerConflictsModal = ({
  open,
  onClose,
  workflowId,
  onResolved,
  context,
  onResolveConflicts,
}: LedgerConflictsModalProps) => {
  const { t } = useTranslation('@cibo/programs/LedgerConflictsModal')
  const { acres } = useFigures()
  const workflow = useWorkflow(workflowId)
  const updateWorkflow = useUpdateWorkflow()
  const ledgerConflicts = useLedgerConflicts(workflowId)

  const { conflicts } = ledgerConflicts

  const nonConflictFieldsCount = workflow.data
    ? workflow.data.fieldCounts.total - conflicts.length
    : 0

  useEffect(() => {
    if (ledgerConflicts.isFetched && !conflicts?.length) {
      onClose()

      onResolved && onResolved()
    }
  }, [ledgerConflicts.isFetched, conflicts?.length])

  const removeConflicts = () =>
    !!workflowId &&
    updateWorkflow.mutateAsync({
      workflowId,
      updates: { fields: conflicts.map(({ resourceId }) => resourceId), action: 'remove' },
    })

  const isPending = ledgerConflicts.isFetching || updateWorkflow.isPending

  return (
    <ResponsiveDialog open={open} onClose={onClose}>
      <DialogTitle sx={{ backgroundColor: 'error.light' }}>
        {t('title', { context, count: conflicts?.length })}
      </DialogTitle>

      <DialogContent>
        <Stack my={1} spacing={2}>
          <Typography>{t('description', { count: conflicts?.length })}</Typography>

          <Markdown>
            {t('cta', {
              context,
              count: conflicts?.length,
              remaining: t('fields', { count: nonConflictFieldsCount }),
            })}
          </Markdown>

          <Grid container columns={{ xs: 4, md: 8 }} spacing={2}>
            {conflicts?.map(field => (
              <Grid size={{ xs: 4 }}>
                <Stack direction="row" spacing={1}>
                  <LandThumbnail
                    field={field}
                    width={48}
                    height={48}
                    skin={LandThumbnailSkin.SMALL}
                  />
                  <Stack spacing={0}>
                    <Typography variant="body2">{field.name}</Typography>
                    <Typography variant="caption">{field.locationLabel}</Typography>
                    <Typography variant="caption">{acres(field.acres)}</Typography>
                  </Stack>
                </Stack>
              </Grid>
            ))}
          </Grid>
        </Stack>
      </DialogContent>

      <DialogActions>
        <DialogActions>
          <LoadingButton color="secondary" loading={isPending} onClick={removeConflicts}>
            {t('remove')}
          </LoadingButton>
          <Button
            variant="contained"
            onClick={() => {
              onClose()
              onResolveConflicts(conflicts)
            }}
          >
            {t('addressErrors')}
          </Button>
        </DialogActions>
      </DialogActions>
    </ResponsiveDialog>
  )
}
