/* istanbul ignore file */
import { EntryCollection } from 'contentful'
import { PracticeDetailTypes, UsdaResourceConcern } from '../types/contentful/USDATypes'
import { getContentfulClient } from './contentful'

export class ContentfulApi {
  static async requestAll(type: string): Promise<any> {
    return getContentfulClient()
      .getEntries({ content_type: type })
      .then((result: any) => result.items)
  }

  static async requestMarkdownByTitle(title: string): Promise<any> {
    return getContentfulClient()
      .getEntries({ content_type: 'markdown', 'fields.title': title })
      .then((result: any) => result.items[0])
  }
  static async requestTerms(): Promise<any> {
    return getContentfulClient()
      .getEntries({ content_type: 'termsPolicyDataUse' })
      .then((result: any) => result.items)
  }

  static async requestTermsByName(name: string): Promise<any> {
    return getContentfulClient()
      .getEntries({ content_type: 'termsPolicyDataUse', 'fields.name': name })
      .then((result: any) => result.items[0])
  }

  static async getAsset(id: string): Promise<any> {
    return getContentfulClient().getAsset(id)
  }

  static async find(query: any): Promise<any> {
    return getContentfulClient()
      .getEntries(query)
      .then((result: any) => result.items)
  }

  static async getEntry(id: string): Promise<any> {
    return getContentfulClient().getEntry(id)
  }

  static async findEntries<T>(query: any): Promise<EntryCollection<T>> {
    return getContentfulClient().getEntries<T>(query)
  }

  static async requestPracticesInfo(locale: string): Promise<EntryCollection<PracticeDetailTypes>> {
    return getContentfulClient().getEntries<PracticeDetailTypes>({
      content_type: 'practiceRecommendation',
      locale,
    })
  }

  static async requestResourceConcernsDefinition(
    locale: string
  ): Promise<EntryCollection<UsdaResourceConcern>> {
    return getContentfulClient().getEntries<UsdaResourceConcern>({
      content_type: 'usdaResourceConcern',
      locale,
    })
  }
}
