import { ResponsiveDialog } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import { useUpdateWorkflow } from '../../queries'

type RemoveFieldsModalProps = {
  requestClose(): void
  workflowId?: string
  fieldsToRemove?: string[]
}

export const RemoveFieldsModal = ({
  requestClose,
  workflowId,
  fieldsToRemove,
}: RemoveFieldsModalProps) => {
  const updateWorkflow = useUpdateWorkflow()
  const { t } = useTranslation('@cibo/programs/RemoveFieldsModal')
  const { enqueueSnackbar } = useSnackbar()

  const handleRemoveSelectedFields = async () => {
    if (!workflowId || !fieldsToRemove) return
    try {
      await updateWorkflow.mutateAsync({
        workflowId,
        updates: { fields: fieldsToRemove, action: 'remove' },
      })

      enqueueSnackbar(t('removeSuccess', { count: fieldsToRemove.length }), {
        variant: 'success',
      })
      requestClose()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <ResponsiveDialog open={!!workflowId} onClose={requestClose}>
      <DialogTitle>{t('confirmRemoveTitle', { count: fieldsToRemove?.length || 0 })}</DialogTitle>
      <DialogContent>
        {updateWorkflow.isError ? (
          <Typography color="error">{t('removeError')}</Typography>
        ) : (
          <Typography>{t('confirmRemoveBody', { count: fieldsToRemove?.length || 0 })}</Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => {
            requestClose()
          }}
        >
          {t('cancel')}
        </Button>
        <LoadingButton
          color="error"
          loading={updateWorkflow.isPending}
          onClick={handleRemoveSelectedFields}
          disabled={updateWorkflow.isError}
          variant="contained"
        >
          {t('confirmRemove')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
