import { RDFarmManagementSystem } from '@cibo/core'
import { object, string } from 'yup'
import { ITraitFeatureById } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FarmManagementSystemCell } from './FarmManagementSystemCell'
import { FarmManagementSystemEditor } from './FarmManagementSystemEditor'

const TRAIT_ID = 'farmManagementSystem'

export const FarmManagementSystem: ITraitFeatureById<RDFarmManagementSystem> = {
  traitId: TRAIT_ID,
  cellDisplay: FarmManagementSystemCell,
  editor: FarmManagementSystemEditor,
  initialValues: props => {
    const detail = findInitialValuesDetail(TRAIT_ID)(props)
    return detail ? detail.input ?? { fmsName: detail.result } : {}
  },
  ns: '@cibo/landmanager/FarmManagementSystem',
  validationSchema: ({ t }) =>
    object()
      .shape({
        fmsName: string().required(t('required', { context: 'fmsName' })),
      })
      .required(t('required')),
}
