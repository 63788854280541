import {
  GeneralResourceDetail,
  IntegerString,
  PracticeContentDataTypes,
  ProgramEngagementModel,
} from '@cibo/core'
import { usePracticesContent } from '@cibo/landmanager/src/queries'
import { FriendlyError, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Grid2 as Grid, Skeleton, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useProgramEngagementDetails } from '../../hooks/useProgramEngagementDetails'
import { FieldSelectionCard } from './FieldSelectionCard'

export type FieldsSelectionListProps<T extends GeneralResourceDetail = GeneralResourceDetail> =
  ResourceDetailFeatureTaskEditorProps<T> & {
    engagement?: ProgramEngagementModel
  }

export const FieldsSelectionList = <T extends GeneralResourceDetail = GeneralResourceDetail>({
  engagement,
  engagementId,
  userRole,
  ownerName,
  detailRequirements,
  onError,
  onSuccess,
  onUpdating,
  resourceIds: [resourceId],
  task,
}: FieldsSelectionListProps<T>) => {
  const { t } = useTranslation('@cibo/programs/FieldsSelection')
  const intendedPractices =
    useProgramEngagementDetails({
      // TODO: should this be less explicitly tied together?
      detailRequirements: [{ traitId: 'eqipRecommendedPracticesIntent', dataType: 'workflow' }],
      resourceId: engagementId,
    }).data || []

  const selectedPracticesId = intendedPractices[0]?.result as string[]

  const { data, isPending, isError } = usePracticesContent()
  const allPracticesData = (data?.items || []) as PracticeContentDataTypes[]

  if (isError) {
    return <FriendlyError message={t('errorMessage')} />
  }

  return (
    <Stack spacing={3}>
      <Stack spacing={1.5}>
        <Typography variant="h6" component="h2">
          {t('title', {
            context: userRole,
            name: ownerName,
          })}
        </Typography>
        <Typography variant="body1" color="text.secondary">
          {t('subtitle', {
            context: userRole,
            name: ownerName,
          })}
        </Typography>
      </Stack>
      <Stack spacing={2}>
        {isPending ? (
          <Grid columns={{ xs: 4, md: 8, lg: 12 }} spacing={2} container>
            {selectedPracticesId?.map((practiceID: string) => (
              <Grid key={practiceID} size={{ xs: 4 }}>
                <Skeleton variant="rounded" height={150} />
              </Grid>
            ))}
          </Grid>
        ) : (
          allPracticesData
            .filter(
              card =>
                card.fields?.practiceId &&
                selectedPracticesId &&
                selectedPracticesId.includes(card.fields.practiceId)
            )
            ?.map((card: PracticeContentDataTypes, index: number) => (
              <FieldSelectionCard<T>
                key={card.fields.practiceId}
                practiceName={card.fields?.name}
                practiceId={card.fields.practiceId as IntegerString}
                practiceDefinition={card.fields?.definition}
                resourceConcernsAddressed={card.fields?.resourceConcernsAddresed}
                engagement={engagement}
                resourceIds={[resourceId]}
                detailRequirements={detailRequirements}
                onError={onError}
                onSuccess={onSuccess}
                onUpdating={onUpdating}
                engagementId={engagementId}
                userRole={userRole}
                ownerName={ownerName}
                expanded={index === 0}
                task={task}
              />
            ))
        )}
      </Stack>
    </Stack>
  )
}
