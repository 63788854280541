import { useEffect } from 'react'
import i18n from '../index'

let orgLanguage: string | undefined
const handleKeyDown = (event: KeyboardEvent) => {
  if (i18n.language === 'cimode' || !event.shiftKey || !event.ctrlKey) {
    return
  }
  if (event.key === 'T') {
    orgLanguage = i18n.language
    i18n.changeLanguage('cimode')
  }
}

const handleKeyUp = (event: KeyboardEvent) => {
  if (orgLanguage && event.key === 'T') {
    i18n.changeLanguage(orgLanguage)
    orgLanguage = undefined
  }
}

export const TranslationPreviewer = () => {
  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [])

  return null
}
