import { FieldModel, MpxResponseError } from '@cibo/core'
import { UseQueryResult } from '@tanstack/react-query'
import { AxiosError } from 'axios'
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react'
import { useField, useLandReport } from '../../../queries'

const CURRENT_YEAR = new Date().getFullYear()

export enum LandReportMapDataLayer {
  ACRES = 'acres',
  PERFORMANCE = 'stability',
  SOIL = 'soil',
  ELEVATION = 'elevation',
}
export interface LandReportViewState {
  dataLayer: LandReportMapDataLayer
  focusYear: number
  setDataLayer: (dataLayer: LandReportMapDataLayer) => void
  setFocusYear: (year: number) => void
}

export const LandReportViewContext = createContext<LandReportViewState>({
  dataLayer: LandReportMapDataLayer.ACRES,
  focusYear: 0,
  setDataLayer: console.log,
  setFocusYear: console.log,
})
LandReportViewContext.displayName = 'LandReportViewContext'

export const LandReportViewProvider = ({ children }: PropsWithChildren<{}>) => {
  const [focusYear, setFocusYear] = useState<number>(CURRENT_YEAR)
  const [dataLayer, setDataLayer] = useState<LandReportMapDataLayer>(LandReportMapDataLayer.ACRES)

  return (
    <LandReportViewContext.Provider
      value={{
        dataLayer,
        focusYear,
        setDataLayer,
        setFocusYear,
      }}
    >
      {children}
    </LandReportViewContext.Provider>
  )
}

export interface UseLandReportViewState {
  dataLayer: LandReportMapDataLayer
  focusYear: number
  setDataLayer: (dataLayer: LandReportMapDataLayer) => void
  setFocusYear: (year: number) => void
  field: UseQueryResult<FieldModel | undefined, AxiosError<MpxResponseError>>
}

export const useLandReportView = ({ resourceId }: { resourceId?: string }) => {
  const field = useField(resourceId)
  const report = useLandReport(resourceId)
  const context = useContext(LandReportViewContext)

  const reportYears = field.data?.reportYears

  useEffect(() => {
    if (reportYears?.length) {
      if (reportYears.includes(CURRENT_YEAR - 1)) {
        context.setFocusYear(CURRENT_YEAR - 1)
      } else {
        context.setFocusYear(reportYears[0])
      }
    }
  }, [reportYears?.join(',')])

  return {
    ...context,
    field,
    reportYears,
    report,
  }
}
