import { Markdown, RadioBooleanField } from '@cibo/ui'
import { Stack } from '@mui/material'
import { Field } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../../types'

export const LandControlEditor = ({ name = 'eqipPracticesLandControl' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/LandControl')

  return (
    <Field name={name} id={name}>
      {() => {
        return (
          <Stack spacing={2}>
            <Markdown>{t('editorQuestion')}</Markdown>

            <RadioBooleanField
              name={`${name}.landOwnershipEligible`}
              data-testid={'rdLandControl.landOwnershipEligible'}
            />
          </Stack>
        )
      }}
    </Field>
  )
}
