import { AuthUserPermission, UserId } from '@cibo/core'
import { AssignUserControl, Can, useOrgUser } from '@cibo/profile'
import { useParamAsInteger } from '@cibo/ui'
import { FormControl, FormLabel } from '@mui/material'
import { useField } from 'formik'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginatedFieldsContext } from '../PaginatedFieldsContext'

export const GrowerFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/UserFilter')
  const { baseFilters } = useContext(PaginatedFieldsContext)
  const routedUserId = useParamAsInteger('userId')

  const { data: routedUserScope } = useOrgUser(routedUserId)

  const userScope = routedUserScope

  const field = useField('owner')
  const { value = null } = field[1]
  const { setValue } = field[2]

  const handleChangeGrower = (userId?: UserId) => setValue(userId)

  const userId = userScope ? userScope.userId : value || routedUserId

  return (
    <Can useAny={AuthUserPermission.GROWER_CONTACT_READ}>
      <FormControl>
        <FormLabel>{t('user')}</FormLabel>
        <AssignUserControl
          hideLabel
          userId={userId}
          setUserId={handleChangeGrower}
          disabled={!!routedUserId || !!baseFilters.owner}
        />
      </FormControl>
    </Can>
  )
}
