import { RDOwnershipInput } from '@cibo/core'
import { CheckboxField, NumberInputField } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { FieldProps, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../types'
import { useIsRequired } from '../useIsRequired'
import { useShowDetailAttribute } from '../useShowDetailAttribute'

export const OwnershipEditor = ({
  filterInputs,
  fieldModels,
  name = 'ownershipEditor',
  requirement,
}: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/OwnershipEditor')
  const benchmarkRequires = useIsRequired(requirement)
  const show = useShowDetailAttribute<RDOwnershipInput>(filterInputs)

  return (
    <FormikField name={name} id={name}>
      {({ field }: FieldProps<RDOwnershipInput>) => (
        <Stack spacing={2}>
          <Typography>{t('cta')}</Typography>

          {show('operatorIsOwner') && (
            <Stack direction="row" alignItems="center">
              <CheckboxField
                name={`${name}.operatorIsOwner`}
                data-testid={`${name}.operatorIsOwner`}
              />
              <InputLabel htmlFor={`${name}.operatorIsOwner`}>{t('operatorIsOwner')}</InputLabel>
            </Stack>
          )}
          {show('isRented') && (
            <Stack direction="row" alignItems="center">
              <CheckboxField name={`${name}.isRented`} data-testid={`${name}.isRented`} />
              <InputLabel htmlFor={`${name}.isRented`}>{t('isRented')}</InputLabel>
            </Stack>
          )}

          {show('yearsInOperation') && (
            <Stack>
              <InputLabel
                htmlFor={`${name}.yearsInOperation`}
                required={!!benchmarkRequires('yearsInOperation')}
              >
                {t('howManyYears')}
              </InputLabel>
              <NumberInputField
                name={`${name}.yearsInOperation`}
                min={0}
                data-testid={`${name}.yearsInOperation`}
              />
            </Stack>
          )}
        </Stack>
      )}
    </FormikField>
  )
}
