import { FormControl, FormLabel } from '@mui/material'
import { Field as FormikField } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../types'

export const FarmManagementSystemEditor = ({ name = 'fmsEditor' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/FarmManagementSystem')

  return (
    <FormControl required>
      <FormLabel htmlFor={`${name}.fmsName`}>{t('fmsName')}</FormLabel>
      <FormikField
        component={TextField}
        name={`${name}.fmsName`}
        inputProps={{ 'data-testid': `${name}.fmsName` }}
      />
    </FormControl>
  )
}
