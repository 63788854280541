import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const WaterPumpIrrigationCellDisplay = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/WaterPumpIrrigation')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (!detail.input) return <></>

  const {
    pumpsUsed,
    totalWater,
    waterUnit,
    energySource,
    energyAmount,
    userEnergyUnit,
    energyUnit,
  } = detail.input

  if (pumpsUsed === false) {
    return <Typography>{t('noPumps')}</Typography>
  }

  const secondaryText =
    energySource === 'unknown'
      ? `${totalWater} ${t(`totalWater_${waterUnit}`)}`
      : `${energyAmount} ${energyUnit ? t(`energyUnit_${energyUnit}`) : userEnergyUnit}`

  return (
    <Stack>
      <Typography variant="body1">{t(`energyTypeLong_${energySource}`)}</Typography>
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {secondaryText}
      </Typography>
    </Stack>
  )
}
