import {
  DetailSourceData,
  FileShape,
  ResourceDetail,
  ResourceDetailSource,
  TraitId,
} from '../types'

export class DetailModel implements Partial<ResourceDetail> {
  source?: ResourceDetailSource
  traitId: TraitId
  year?: number

  input: any
  result: any

  updatedAt?: Date
  confirmed?: boolean
  confirmedAt?: Date
  file?: FileShape[]

  immutable?: boolean
  sourceData?: DetailSourceData

  constructor(params: ResourceDetail) {
    this.source = params.source
    this.traitId = params.traitId
    this.year = params.year

    this.input = params.input
    this.result = params.result

    this.updatedAt = params.updatedAt && new Date(params.updatedAt)
    this.confirmed = params.confirmed
    this.confirmedAt = params.confirmedAt && new Date(params.confirmedAt)
    this.file = params.file as FileShape[]

    this.immutable = params.immutable
    this.sourceData = params.sourceData
  }
}
