import {
  EQIP_PRACTICES_SNOW_DRIFTS_OPTIONS,
  EqipPracticesSnowDriftsOption,
  RDEqipPracticesSnowDrifts,
} from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../SelectRollupEditor'

export const RDEqipPracticesSnowDriftsResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSnowDrifts> =
  {
    traitId: 'eqipPracticesSnowDrifts',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSnowDrifts>) => (
      <SelectRollupEditor<RDEqipPracticesSnowDrifts, readonly EqipPracticesSnowDriftsOption[]>
        options={EQIP_PRACTICES_SNOW_DRIFTS_OPTIONS}
        detailKey="driftedSnow"
        ns="@cibo/landmanager/RDEqipPracticesSnowDriftsResourceDetailFeature"
        {...props}
      />
    ),
  }
