import { useTheme } from '@mui/material'
import { useContext } from 'react'
import { Layer, Source } from 'react-map-gl/dist/es5'
import { MapTheme } from '../const'

import { Config } from '../../../types'
import { LayerContext } from './LayerContext'

interface CountiesLayerProps {
  filter?: any[]
}

export const CountiesLayer = (props: CountiesLayerProps) => {
  const theme = useTheme<MapTheme>()
  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')
  const { showCounties } = useContext(LayerContext)

  return (
    <Source
      id="counties"
      type="vector"
      tiles={[`${maptilesServiceUrl}geopolitical/{z}/{x}/{y}.pbf`]}
      minzoom={1}
      maxzoom={10}
    >
      <Layer
        id="county-shapes"
        type="line"
        source="geopolitical"
        source-layer="county-shapes"
        layout={{
          visibility: showCounties ? 'visible' : 'none',
        }}
        paint={{
          'line-color': theme.palette.countyLine,
          'line-width': ['interpolate', ['exponential', 1.51], ['zoom'], 1, 1.2, 8.5, 2],
          'line-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            6,
            0,
            6.25,
            1,
            8.5,
            1,
            8.75,
            0.25,
            10,
            0,
          ],
        }}
      />
      <Layer
        id="county-label"
        type="symbol"
        source="geopolitical"
        source-layer="county-centers"
        layout={{
          visibility: showCounties ? 'visible' : 'none',
          'text-field': ['to-string', ['get', 'label']],
          'text-font': ['Nunito Sans SemiBold', 'Arial Unicode MS Regular'],
          'text-size': ['interpolate', ['linear'], ['zoom'], 1, 4, 8.5, 16],
        }}
        paint={{
          'text-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            6,
            0,
            6.25,
            1,
            8.5,
            1,
            8.75,
            0.25,
            10,
            0,
          ],
          'text-halo-color': theme.palette.countyTextHalo,
          'text-halo-width': 1,
          'text-halo-blur': 1,
          'text-color': theme.palette.countyText,
        }}
      />
    </Source>
  )
}
