import {
  AuthAPI,
  AuthChangePasswordResult,
  AuthCheckActionTypes,
  AuthCheckError,
  AuthCheckResult,
  GlobalMpAuth,
  MP_AUTH_CHECK_ERROR,
  MP_AUTH_CHECK_PENDING,
  MP_AUTH_CHECK_SUCCESS,
} from '@cibo/core'
import { AxiosResponse } from 'axios'
import { Action } from 'redux'
import { ThunkAction } from 'redux-thunk'
import { selectAuthSessionValid } from '../AuthReducer/Session'

import { authSessionInvalid, authSessionValid } from './Session'

const authCheckPending = (): AuthCheckActionTypes => ({
  type: MP_AUTH_CHECK_PENDING,
})
export const authCheckSuccess = (
  results: AxiosResponse<AuthCheckResult | AuthChangePasswordResult>
): AuthCheckActionTypes => ({
  type: MP_AUTH_CHECK_SUCCESS,
  results,
})
const authCheckError = (error: AuthCheckError): AuthCheckActionTypes => ({
  type: MP_AUTH_CHECK_ERROR,
  error,
})

export interface AuthCheckActionArgs {
  jwt?: string
  message?: string
}

export const authCheck = ({
  jwt,
  message,
}: AuthCheckActionArgs): ThunkAction<void, any, null, Action<string>> => async (
  dispatch,
  getState
) => {
  dispatch(authCheckPending())
  const wasValid = selectAuthSessionValid(getState())
  try {
    const response = await AuthAPI.authCheck({ headers: { Authorization: `Bearer ${jwt}` } })
    const { data } = response
    /**
     * This endpoint will refresh the user token and suggest a new one for us
     * to use going forward by including it in `data.userJwt`
     */
    GlobalMpAuth.setAuthToken(data.userJwt || jwt)
    dispatch(
      authCheckSuccess({
        ...response,
        data: {
          ...data,
          userJwt: data.userJwt || jwt,
        },
      })
    )
    // only if the auth is new
    !wasValid && dispatch(authSessionValid())
  } catch ({ response: { data } = { data: {} } }) {
    dispatch(authCheckError(data as AuthCheckError))
    dispatch(authSessionInvalid())
    GlobalMpAuth.clearAuthToken()
  }
}
