import { DetailRequirement, PrgDetail, TraitId } from '@cibo/core'
import { createContext, useContext } from 'react'
import { RemoveDetailRequest, SaveDetailRequest } from '../queries'

type BaseModalState = {
  onSuccess?: () => void
  filterInputs?: PrgDetail
}

export type DetailModalState = {
  traitId?: TraitId
  requirement?: DetailRequirement
  resourceId?: string
} & BaseModalState

export type BulkEditModalState = {
  traitId: TraitId
  requirements?: DetailRequirement[]
  resourceIds: string[]
} & BaseModalState

export interface DetailEditingState {
  removeDetail(request: RemoveDetailRequest): Promise<any>
  saveDetail(request: SaveDetailRequest): Promise<any>
  setDetailModal(state: DetailModalState): void
  setBulkEditModal(state: BulkEditModalState): void
  setImportPracticesModal(state: { open: boolean; fieldId?: string }): void
}

export const DetailEditingContext = createContext<DetailEditingState>({
  removeDetail: (request: RemoveDetailRequest) => {
    console.log('removeDetail', request)
    return Promise.reject()
  },
  saveDetail: (request: SaveDetailRequest) => {
    console.log('saveDetail', request)
    return Promise.reject()
  },
  setDetailModal: console.log,
  setBulkEditModal: console.log,
  setImportPracticesModal: console.log,
})
DetailEditingContext.displayName = 'DetailEditingContext'

export const useDetailEditing = () => useContext(DetailEditingContext)
