/* istanbul ignore file */
import { boundsFromArray2d } from '../utils'
import { stringify } from 'query-string'

import { MpxSearchQuery, MpxSearchResult } from '../types'
import {
  MpxTypeaheadQuery,
  MpxTypeaheadResults,
  MpxTypeaheadSuggestionQuery,
} from '../types/MpxTypeaheadTypes'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const TYPEAHEAD_PATHS = {
  SUGGEST: '/mpx/search/typeahead',
  RESOLVE_QUERY: '/mpx/getFullTextInputFromTypeAheadResult',
  GET_RESULT_COUNT: '/mpx/countFromTypeAhead',
}

export class MpxTypeaheadAPI {
  static async suggest(query: MpxTypeaheadQuery): Promise<MpxTypeaheadResults> {
    return agent
      .get(
        `${TYPEAHEAD_PATHS.SUGGEST}?${stringify({
          _f: 'taRawResult',
          ...query,
        })}`
      )
      .then((response: any) => ({ items: response.data.items }))
  }

  static async resolveQuery(suggestionQuery: MpxTypeaheadSuggestionQuery): Promise<MpxSearchQuery> {
    return agent
      .get(`${TYPEAHEAD_PATHS.RESOLVE_QUERY}?${stringify(suggestionQuery)}`)
      .then((response: any) => {
        const lowerLeft = response.data?.query?.lowerLeft?.split(',').map(parseFloat)
        const upperRight = response.data?.query?.upperRight?.split(',').map(parseFloat)
        const bounds = boundsFromArray2d([lowerLeft, upperRight])

        return {
          ...response.data.query,
          ...bounds,
        }
      })
  }

  static async getResultCount(suggestionQuery: MpxTypeaheadSuggestionQuery) {
    return agent
      .get<MpxSearchResult>(`${TYPEAHEAD_PATHS.GET_RESULT_COUNT}?${stringify(suggestionQuery)}`)
      .then(r => r?.data)
  }
}
