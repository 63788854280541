import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { useOrganizationContent } from '@cibo/profile'

import { ENV } from '@cibo/profile'
import { Config } from 'types/Config'

export const useSetAppMeta = () => {
  const { t } = useTranslation('cibo/profile/useSetAppMeta')
  const { isPending, isFetching, isError } = useOrganizationContent()
  const ogTitleEl = useMemo(() => document.querySelector("meta[property='og:title']"), [])
  const ogDescriptionEl = useMemo(
    () => document.querySelector("meta[property='og:description']"),
    []
  )
  const ogSitenameEl = useMemo(() => document.querySelector("meta[property='og:site_name']"), [])
  const twitterTitleEl = useMemo(() => document.querySelector("meta[name='twitter:title']"), [])
  const twitterDescriptionEl = useMemo(
    () => document.querySelector("meta[name='twitter:description']"),
    []
  )
  const twitterSiteEl = useMemo(() => document.querySelector("meta[name='twitter:site']"), [])
  const manifestEl = useMemo(() => document.querySelector('#manifest-placeholder'), [])
  useEffect(() => {
    if (Config.get('DEV_ENV') === ENV.PROD) {
      if (!isPending && !isFetching && !isError) {
        const myDynamicManifest = {
          short_name: t('manifestShortname'),
          name: 'Scale Regenerative Ag',
          icons: [
            {
              src: 'favicon.ico',
              sizes: '64x64 32x32 24x24 16x16',
              type: 'image/x-icon',
            },
          ],
          start_url: '/index.html',
          display: 'standalone',
          theme_color: '#ffffff',
          background_color: '#ffffff',
        }
        const stringManifest = JSON.stringify(myDynamicManifest)
        const blob = new Blob([stringManifest], { type: 'application/json' })
        const manifestURL = URL.createObjectURL(blob)

        ogTitleEl?.setAttribute('content', t('ogTitle'))
        ogDescriptionEl?.setAttribute('content', t('ogDescription'))
        ogSitenameEl?.setAttribute('content', t('ogSite_name'))
        twitterTitleEl?.setAttribute('content', t('ogTitle'))
        twitterDescriptionEl?.setAttribute('content', t('ogDescription'))
        twitterSiteEl?.setAttribute('content', t('twitterSite'))
        manifestEl?.setAttribute('href', manifestURL)
        manifestEl?.setAttribute('crossorigin', 'use-credentials')
      }
    }
    return () => {}
  }, [isPending, isFetching, isError])
}
