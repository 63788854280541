import {
  CASH_CROP_HARVEST_TYPES,
  CASH_CROP_PLANTING_RATE_UNIT,
  PRIORITIZED_DISPLAYABLE_CASH_CROPS,
  PRIORITY_CASH_CROPS,
  RDCashCropInput,
  RDCashCropSpecies,
} from '@cibo/core'
import {
  AutocompleteField,
  CheckboxField,
  DatePickerField,
  NumberInputField,
  SelectField,
} from '@cibo/ui'
import { Collapse, FormHelperText, Stack, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { FieldProps, Field as FormikField } from 'formik'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'
import { useIsRequired } from '../useIsRequired'
import { useShowDetailAttribute } from '../useShowDetailAttribute'
import { PlantingRateWarning } from './PlantingRateWarning'
import {
  SCOPED_HARVEST_TYPE_OPTIONS,
  SCOPED_PRIORITY_HARVEST_TYPES,
  perAcreUnitKeyByHarvest,
} from './types'

const ConditionallyShow = ({ show, children }: { show: boolean; children: ReactNode }) =>
  show ? <>{children}</> : <></>

const findDefaultMonths = (year: number, crop: string) => ({
  defaultPlantingMonth: new Date(year, crop === 'corn' ? 3 : 4),
  defaultHarvestMonth: new Date(year, crop === 'corn' ? 7 : 8),
})

export const CashCropEditor = ({
  filterInputs,
  name = 'cashCropEditor',
  requirement,
  year,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/CashCropEditor')
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')

  const benchmarkRequires = useIsRequired(requirement)
  const show = useShowDetailAttribute<RDCashCropInput>(filterInputs)

  return (
    <FormikField name={name} id={name}>
      {({
        field: {
          value: {
            crop,
            plantedAt,
            harvestType,
            plantingRate,
            plantingRateUnit,
          } = {} as RDCashCropInput,
        },
      }: FieldProps<RDCashCropInput>) => {
        const { defaultPlantingMonth, defaultHarvestMonth } = findDefaultMonths(year, crop)
        const hideCropExtras = !crop || crop === 'fallow'
        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { year })}</Typography>
            <ConditionallyShow show={show('crop')}>
              <Stack>
                <InputLabel required={!!benchmarkRequires('crop')} htmlFor={`${name}.crop`}>
                  {t('whatCrop')}
                </InputLabel>
                <AutocompleteField
                  name={`${name}.crop`}
                  groupBy={c =>
                    PRIORITY_CASH_CROPS && PRIORITY_CASH_CROPS.includes(c)
                      ? t('suggested')
                      : t('all')
                  }
                  options={PRIORITIZED_DISPLAYABLE_CASH_CROPS}
                  getOptionLabel={(option: string) => cropT(option)}
                  data-testid={`cashCrop.${year}.crop`}
                  selectOnFocus
                />
              </Stack>
            </ConditionallyShow>
            <Collapse in={!hideCropExtras}>
              <Stack spacing={2}>
                <ConditionallyShow show={show('plantedAt') || show('harvestedAt')}>
                  <Stack>
                    <Typography>{t('whatDates')}</Typography>

                    <Stack direction="row" spacing={2}>
                      <ConditionallyShow show={show('plantedAt')}>
                        <Stack>
                          <InputLabel
                            required={!!benchmarkRequires('plantedAt')}
                            htmlFor={`${name}.plantedAt`}
                          >
                            {t('planted')}
                          </InputLabel>
                          <DatePickerField
                            name={`${name}.plantedAt`}
                            minDate={new Date(year, 0, 1)}
                            maxDate={new Date(year + 1, 0, 1)}
                            defaultCalendarMonth={defaultPlantingMonth}
                            data-testid={`cashCrop.${year}.plantedAt`}
                          />
                        </Stack>
                      </ConditionallyShow>
                      <ConditionallyShow show={show('harvestedAt')}>
                        <Stack>
                          <InputLabel
                            required={!!benchmarkRequires('harvestedAt')}
                            htmlFor={`${name}.harvestedAt`}
                          >
                            {t('harvested')}
                          </InputLabel>
                          <DatePickerField
                            name={`${name}.harvestedAt`}
                            minDate={
                              // @ts-ignore plantedAt is cast to string in MUI DatePickerField
                              plantedAt && plantedAt !== ''
                                ? new Date(plantedAt)
                                : new Date(year, 0, 1)
                            }
                            maxDate={new Date(year + 1, 6, 1)}
                            defaultCalendarMonth={defaultHarvestMonth}
                            data-testid={`cashCrop.${year}.harvestedAt`}
                          />
                        </Stack>
                      </ConditionallyShow>
                    </Stack>
                  </Stack>
                </ConditionallyShow>
                <ConditionallyShow show={show('plantingRate') || show('plantingRateUnit')}>
                  <Stack direction="row" spacing={1}>
                    <ConditionallyShow show={show('plantingRate')}>
                      <Stack>
                        <InputLabel
                          required={!!benchmarkRequires('plantingRate')}
                          htmlFor={`${name}.plantingRate`}
                        >
                          {t('plantingRate')}
                        </InputLabel>
                        <NumberInputField
                          name={`${name}.plantingRate`}
                          sx={{ width: 100 }}
                          data-testid={`cashCrop.${year}.plantingRate`}
                        />
                        <PlantingRateWarning
                          plantingRate={plantingRate}
                          plantingRateUnit={plantingRateUnit}
                          crop={crop}
                        />
                      </Stack>
                    </ConditionallyShow>
                    <ConditionallyShow show={show('plantingRateUnit')}>
                      <Stack sx={{ flex: 1 }}>
                        <InputLabel
                          required={!!benchmarkRequires('plantingRateUnit')}
                          htmlFor={`${name}.plantingRateUnit`}
                        >
                          {t('unit')}
                        </InputLabel>

                        <SelectField
                          fieldName={`${name}.plantingRateUnit`}
                          options={CASH_CROP_PLANTING_RATE_UNIT}
                          renderOption={context => t('plantingRate', { context })}
                          data-testid={`cashCrop.${year}.plantingRateUnit`}
                        />
                      </Stack>
                    </ConditionallyShow>
                  </Stack>
                </ConditionallyShow>
                <ConditionallyShow show={show('harvestType')}>
                  <Stack>
                    <InputLabel
                      required={
                        // if yield per acre is required, harvest type is de facto required
                        !!benchmarkRequires('harvestType') || !!benchmarkRequires('yieldPerAcre')
                      }
                      htmlFor={`${name}.harvestType`}
                    >
                      {t('harvestType')}
                    </InputLabel>
                    <AutocompleteField
                      name={`${name}.harvestType`}
                      options={
                        (crop && SCOPED_HARVEST_TYPE_OPTIONS[crop as RDCashCropSpecies]) ||
                        CASH_CROP_HARVEST_TYPES
                      }
                      groupBy={
                        crop
                          ? c =>
                              SCOPED_PRIORITY_HARVEST_TYPES[crop as RDCashCropSpecies] &&
                              SCOPED_PRIORITY_HARVEST_TYPES[crop as RDCashCropSpecies]?.includes(c)
                                ? t('suggested')
                                : t('all')
                          : undefined
                      }
                      getOptionLabel={(option: string) => t(option)}
                      data-testid={`cashCrop.${year}.harvestType`}
                      selectOnFocus
                    />
                  </Stack>
                </ConditionallyShow>
                <ConditionallyShow show={show('yieldPerAcre')}>
                  <Collapse
                    in={
                      !!benchmarkRequires('yieldPerAcre') ||
                      (!!harvestType &&
                        !['none', 'grazed', 'not_available'].find(a => a === harvestType))
                    }
                  >
                    <Stack>
                      <InputLabel
                        required={!!benchmarkRequires('yieldPerAcre')}
                        htmlFor={`${name}.yieldPerAcre`}
                      >
                        {t('whatYield')}
                      </InputLabel>

                      <NumberInputField
                        disabled={!harvestType}
                        name={`${name}.yieldPerAcre`}
                        min={0}
                        unit={t(perAcreUnitKeyByHarvest(harvestType))}
                        data-testid={`cashCrop.${year}.yieldPerAcre`}
                      />
                      {!harvestType && (
                        <FormHelperText error={!!benchmarkRequires('yieldPerAcre')}>
                          {t('requiresType')}
                        </FormHelperText>
                      )}
                    </Stack>
                  </Collapse>
                </ConditionallyShow>
                <ConditionallyShow show={show('residuePercentageRemoved')}>
                  <>
                    <InputLabel
                      required={!!benchmarkRequires('residuePercentageRemoved')}
                      htmlFor={`${name}.residuePercentageRemoved`}
                    >
                      {t('removedPercentage')}
                    </InputLabel>
                    <NumberInputField
                      name={`${name}.residuePercentageRemoved`}
                      min={0}
                      max={100}
                      unit={'%'}
                      data-testid={`cashCrop.${year}.residuePercentageRemoved`}
                    />
                  </>
                </ConditionallyShow>
                <ConditionallyShow show={show('herbicideBurndown') || show('fireBurndown')}>
                  <Stack direction="row" justifyContent="space-between">
                    <ConditionallyShow show={show('herbicideBurndown')}>
                      <Stack direction="row" alignItems="center">
                        <CheckboxField
                          name={`${name}.herbicideBurndown`}
                          data-testid={`cashCrop.${year}.herbicideBurndown`}
                        />
                        <InputLabel
                          required={!!benchmarkRequires('herbicideBurndown')}
                          htmlFor={`${name}.herbicideBurndown`}
                        >
                          {t('herbicideBurndown')}
                        </InputLabel>
                      </Stack>
                    </ConditionallyShow>
                    <ConditionallyShow show={show('fireBurndown')}>
                      <Stack direction="row" alignItems="center">
                        <CheckboxField
                          name={`${name}.fireBurndown`}
                          data-testid={`cashCrop.${year}.fireBurndown`}
                        />
                        <InputLabel
                          required={!!benchmarkRequires('fireBurndown')}
                          htmlFor={`${name}.fireBurndown`}
                        >
                          {t('fireBurndown')}
                        </InputLabel>
                      </Stack>
                    </ConditionallyShow>
                  </Stack>
                </ConditionallyShow>
              </Stack>
            </Collapse>
          </Stack>
        )
      }}
    </FormikField>
  )
}
