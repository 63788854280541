import { ResourceDetailYearly } from '../ResourceDetail'

export const EQIP_PRACTICES_FLOODING_OPTIONS = [
  'occurs_no_effect',
  'occurs_negative_effect',
  'no_occurrence',
  'unknown',
] as const
export type EqipPracticesFloodingOption = typeof EQIP_PRACTICES_FLOODING_OPTIONS[number]

export type RDEqipPracticesFloodingInput = {
  ponding: EqipPracticesFloodingOption
}

export interface RDEqipPracticesFlooding extends ResourceDetailYearly {
  traitId: 'eqipPracticesFlooding'
  input: RDEqipPracticesFloodingInput
  result: RDEqipPracticesFloodingInput
}
