import { RDLolFarmId } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Stack, TextField, Typography } from '@mui/material'
import { ChangeEvent, FocusEventHandler, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAuth, useUpdateUserDetails, useUserDetails } from '../../../hooks'
import { useOrgUser } from '../../../queries'

export const RDLolFarmIdTaskEditor = ({
  detailRequirements,
  resourceIds: [resourceId],
  onError,
  onSuccess,
  onUpdating,
}: ResourceDetailFeatureTaskEditorProps<RDLolFarmId>) => {
  const { t } = useTranslation('@cibo/profile/RDLolFarmIdTaskEditor')
  const details = useUserDetails({ resourceId, detailRequirements })
  const updateUserDetails = useUpdateUserDetails({})
  const { userId } = useAuth()
  const user = useOrgUser(resourceId)
  const context = resourceId === userId?.toString() ? 'participant' : 'manager'
  const ownerName = user.data?.displayNameFull ?? ''
  const [farmId, setFarmId] = useState<string | undefined>(details.data?.[0]?.result)

  useEffect(() => {
    setFarmId(details.data?.[0]?.result)
  }, [details.dataUpdatedAt])

  const onChange = (event: ChangeEvent<HTMLTextAreaElement>) => setFarmId(event.target.value)

  const handleBlur: FocusEventHandler<HTMLTextAreaElement> = event => {
    onUpdating?.()
    updateUserDetails
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'lolFarmId',
            result: event.target.value,
          },
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }

  return (
    <Stack spacing={3} alignItems="flex-start">
      <Typography>{t('label', { context, name: ownerName })}</Typography>
      <TextField
        value={farmId}
        label={t('lolFarmId')}
        variant="outlined"
        onChange={onChange}
        onBlur={handleBlur}
        data-testid={`LolFarmId-editor`}
        InputLabelProps={{
          shrink: true,
        }}
        sx={{ flex: 1 }}
      />
    </Stack>
  )
}
