import { RadioBooleanField } from '@cibo/ui'
import { Collapse, FormControl, FormLabel, Stack } from '@mui/material'
import { FieldProps, Field as FormikField, useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../../types'
import { RDByrAppliedSlurryOnceInput } from './types'

export const ByrAppliedSlurryOnceEditor = ({ name = 'byrBareGroundEditor' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/ByrAppliedSlurryOnce')

  const { setFieldValue, values, errors } = useFormikContext<{
    input: { applied: boolean | 'true' | 'false'; isSlurry: boolean | 'true' | 'false' }
  }>()
  console.log('error', errors)

  useEffect(() => {
    if (values?.input.applied === 'true') {
      setFieldValue('input.applied', true)
    }
    if (values?.input.applied === 'false') {
      setFieldValue('input', { applied: false, isSlurry: undefined })
    }
  }, [values?.input.applied])

  return (
    <FormikField name={name} id={name}>
      {({ field }: FieldProps<RDByrAppliedSlurryOnceInput>) => (
        <Stack spacing={2}>
          <FormControl>
            <FormLabel required>{t('appliedAmendmentOnce')}</FormLabel>
            <RadioBooleanField name={`${name}.applied`} data-testid={`${name}.applied`} />
            <Collapse
              in={
                // @ts-ignore the form input passes true and false as strings
                field.value?.applied === 'true'
                  ? true
                  : // @ts-ignore the form input passes true and false as strings
                  field.value?.applied === 'false'
                  ? false
                  : field.value?.applied
              }
            >
              <FormLabel>{t('appliedAsSlurry')}</FormLabel>
              {/* @ts-ignore the form input passes true and false as strings */}
              {(field.value?.applied === 'true' || field.value?.applied) && (
                <RadioBooleanField name={`${name}.isSlurry`} data-testid={`${name}.isSlurry`} />
              )}
            </Collapse>
          </FormControl>
        </Stack>
      )}
    </FormikField>
  )
}
