import { RDDairySoilsEnrollment, RDEnrolledOtherCarbon } from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { CheckboxBooleanYearRollupEditor } from '../CheckboxBooleanYearRollupEditor'

export const EnrolledOtherCarbon: ResourceDetailFeature<RDEnrolledOtherCarbon> = {
  traitId: 'enrolledOtherCarbon',
  TaskEditor: (props: any) => (
    <CheckboxBooleanYearRollupEditor
      traitId="enrolledOtherCarbon"
      detailKey="result"
      ns="@cibo/landmanager/EnrolledOtherCarbon"
      {...props}
    />
  ),
}

// @deprecated -- do not remove until product has confirmed
export const DairySoilsEnrollment: ResourceDetailFeature<RDDairySoilsEnrollment> = {
  traitId: 'dairySoilsEnrollment',
  TaskEditor: (props: any) => (
    <CheckboxBooleanYearRollupEditor
      traitId="dairySoilsEnrollment"
      detailKey="result"
      ns="@cibo/landmanager/EnrolledOtherCarbon"
      {...props}
    />
  ),
}
