import { ResourceDetailSource } from '@cibo/core'
import FMSIcon from '@mui/icons-material/CompareArrows'
import NotAvailableIcon from '@mui/icons-material/DoDisturb'
import UserDefinedIcon from '@mui/icons-material/Person'
import UnknownIcon from '@mui/icons-material/QuestionMark'
import RemoteSensedIcon from '@mui/icons-material/SatelliteAlt'
import NotObservedIcon from '@mui/icons-material/VisibilityOff'
import { SvgIconProps } from '@mui/material'

interface SourceIconProps extends SvgIconProps {
  source?: ResourceDetailSource | 'notObserved' | 'none'
}

export const SourceIcon = ({ source, ...iconProps }: SourceIconProps) => {
  switch (source) {
    case 'cibo':
      return <RemoteSensedIcon {...iconProps} />
    case 'user':
      return <UserDefinedIcon {...iconProps} />
    case 'fms':
      return <FMSIcon {...iconProps} />
    case 'none':
      return <NotAvailableIcon {...iconProps} />
    case 'notObserved':
      return <NotObservedIcon {...iconProps} />
    default:
      return <UnknownIcon {...iconProps} />
  }
}
