export * from './adminRoutes'
export * from './engagementRoutes'
export * from './farmersRoutes'
export * from './scopeThreeRoutes'

// TS warns here but we like the enum keys for code completion
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export enum ROUTE {
  FORGOT_PASSWORD = '/forgot_password',
  LOGIN = '/login',
  REGISTRATION = '/register',
  RESULTS = '/results',
  VERIFY_DEVICE = '/login',

  ACCOUNT = '/account',
  ACCOUNT_LOGOUT = '/logout',
  ACCOUNT_SETTINGS = 'settings',
  ACCOUNT_APP_CONFIG = 'app-config',
  TERMS_OF_USE = 'terms-of-use',
  PRIVACY_POLICY = 'privacy-policy',
  POLICY = 'policy/:id',

  INSIGHTS = 'insights',
  INSIGHTS_PARCEL = 'insights/:resourceId',
  LAND = 'land',
  CREATE = 'create',
  EDIT = 'edit',
  RESOURCE_ID = ':resourceId',

  HOME = '/home',
  LEARN = '/learn',
  INVENTORIES = '/inventories',
  PROFILE_FIELDS = '/fields/profile',
  PROFILE_FIELD = '/fields/profile/:fieldId',
  ENROLLMENTS = '/enrollments',
  ENROLLMENTS_PERSONAL = '/enrollments/personal',
  ENROLLMENT = '/enrollments/:id',
  ALL_WORKFLOWS = 'workflows',
  PROGRAMS = '/programs',
  PROGRAM = '/programs/:programId',
  COMMIT = ':programId/commit',
  ENROLL = ':programId/enroll',
  PREQUALIFY = ':programId/qualify',
  ACTION_ITEMS = ':programId/action-items',

  WORKFLOW = 'workflow/:workflowId',

  OPPORTUNITIES = '/opportunities',

  CONTROL_GROUPS = '/control-groups',
  CONTROL_GROUPS_DETAIL = '/control-groups/:agreementId',
}

export const PROGRAM_PATHS = {
  ROOT: '/programs',
  DETAIL: ':programId',
  USER: ':userId',
  FIELDS: 'fields',
  ENROLLED: 'enrolled',
  ENROLLING: 'enrolling',
  ENGAGEMENTS: 'engagements',
  ENROLLMENTS: 'enrollments',
  ELIGIBLE: 'eligible',
  ABOUT: 'about',
  ENROLL: 'enroll',
  ENROLL_DETAIL: ':workflowId',
}

export const PROGRAM_ROUTES = {
  ROOT: PROGRAM_PATHS.ROOT,
  DETAIL: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL].join('/'),
  ABOUT: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ABOUT].join('/'),
  ENROLL: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ENROLL].join('/'),
  ENROLL_DETAIL: [
    PROGRAM_PATHS.ROOT,
    PROGRAM_PATHS.DETAIL,
    PROGRAM_PATHS.ENROLL,
    PROGRAM_PATHS.ENROLL_DETAIL,
  ].join('/'),
  ENGAGEMENTS: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ENGAGEMENTS].join('/'),
  ENROLLMENTS: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ENROLLMENTS].join('/'),
  FIELDS: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.FIELDS].join('/'),
  FIELDS_ENROLLED: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ENROLLED].join('/'),
  FIELDS_ENROLLING: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ENROLLING].join('/'),
  FIELDS_ELIGIBLE: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.ELIGIBLE].join('/'),
  USER: [PROGRAM_PATHS.ROOT, PROGRAM_PATHS.DETAIL, PROGRAM_PATHS.USER].join('/'),
}

export const ENROLLMENT_PATHS = {
  ROOT: '/enrollments',
  DETAIL: ':workflowId',
  CREATE: 'new',
  PERSONAL: 'personal',
  COMMIT: 'commit',
}

export const ENROLLMENT_ROUTES = {
  ROOT: ENROLLMENT_PATHS.ROOT,
  COMMIT: [ENROLLMENT_PATHS.ROOT, ENROLLMENT_PATHS.DETAIL, ENROLLMENT_PATHS.COMMIT].join('/'),
  DETAIL: [ENROLLMENT_PATHS.ROOT, ENROLLMENT_PATHS.DETAIL].join('/'),
  NEW: [ENROLLMENT_PATHS.ROOT, ENROLLMENT_PATHS.CREATE].join('/'),
  PERSONAL: [ENROLLMENT_PATHS.ROOT, ENROLLMENT_PATHS.PERSONAL].join('/'),
}

export const LAND_MANAGER_PATHS = {
  ADD: 'add',
  ARCHIVED: 'archived',
  CARBON_LAB: 'carbon-lab',
  DETAIL: ':resourceId',
  DETAILS: 'details',
  MAP: 'map',
  DRAW: 'draw',
  LIST: 'list',
  EDIT: 'edit',
  PERSONAL: 'my-fields',
  ROOT: '/fields',
  FARMS: '/farms',
  SEARCH: 'search',
  UPLOAD: 'upload',
}

export const LAND_MANAGER_ROUTES = {
  /**
   * START
   * These are needed for the landmanager package as defined by packages/landmanager/src/types/index.ts
   */
  ROOT: LAND_MANAGER_PATHS.ROOT,
  FARMS: LAND_MANAGER_PATHS.FARMS,
  DETAIL: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.DETAIL].join('/'),
  EDIT: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.DETAIL, LAND_MANAGER_PATHS.EDIT].join('/'),
  DRAW: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.ADD, LAND_MANAGER_PATHS.DRAW].join('/'),
  LIST: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.ADD, LAND_MANAGER_PATHS.LIST].join('/'),
  UPLOAD: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.ADD, LAND_MANAGER_PATHS.UPLOAD].join('/'),
  // END

  // sorted by path
  DETAIL_CARBON_LAB: [
    LAND_MANAGER_PATHS.ROOT,
    LAND_MANAGER_PATHS.DETAIL,
    LAND_MANAGER_PATHS.CARBON_LAB,
  ].join('/'),
  DETAIL_EDIT_BOUNDARY: `${[
    LAND_MANAGER_PATHS.ROOT,
    LAND_MANAGER_PATHS.ADD,
    LAND_MANAGER_PATHS.DRAW,
  ].join('/')}?mode=edit&fieldId=:resourceId`,
  FIELD_BOUNDARY_RESOLVE: `${[
    LAND_MANAGER_PATHS.ROOT,
    LAND_MANAGER_PATHS.ADD,
    LAND_MANAGER_PATHS.DRAW,
  ].join('/')}?mode=boundaryResolve&fieldId=:resourceId`,

  DETAIL_MAP: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.DETAIL, LAND_MANAGER_PATHS.MAP].join(
    '/'
  ),
  ADD: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.ADD].join('/'),
  UPLOAD_DETAILS: [
    LAND_MANAGER_PATHS.ROOT,
    LAND_MANAGER_PATHS.ADD,
    LAND_MANAGER_PATHS.UPLOAD,
    LAND_MANAGER_PATHS.DETAILS,
  ].join('/'),
  ARCHIVED: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.ARCHIVED].join('/'),
  MAP: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.MAP].join('/'),
  PERSONAL: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.PERSONAL].join('/'),
  SEARCH: [LAND_MANAGER_PATHS.ROOT, LAND_MANAGER_PATHS.SEARCH].join('/'),

  PROFILE_FIELDS: ROUTE.PROFILE_FIELDS,
  PROFILE_FIELD: ROUTE.PROFILE_FIELD,
}

export const ACCOUNT_PATHS = {
  ROOT: '/account',
  PROFILE: 'profile',
  SECURITY: 'security',
  SETTINGS: 'settings',
  DOCUMENTS: 'documents',
  AGREEMENT_ID: ':agreementId',
}

export const ACCOUNT_ROUTES = {
  ROOT: ACCOUNT_PATHS.ROOT,
  PROFILE: [ACCOUNT_PATHS.ROOT, ACCOUNT_PATHS.PROFILE].join('/'),
  SECURITY: [ACCOUNT_PATHS.ROOT, ACCOUNT_PATHS.SECURITY].join('/'),
  SETTINGS: [ACCOUNT_PATHS.ROOT, ACCOUNT_PATHS.SETTINGS].join('/'),
  DOCUMENTS: [ACCOUNT_PATHS.ROOT, ACCOUNT_PATHS.DOCUMENTS].join('/'),
  DOCUMENT_DETAIL: [ACCOUNT_PATHS.ROOT, ACCOUNT_PATHS.DOCUMENTS, ACCOUNT_PATHS.AGREEMENT_ID].join(
    '/'
  ),
}

export const REPORTING_PATHS = {
  ROOT: '/reports',
}

export const REPORTING_ROUTES = {
  ROOT: REPORTING_PATHS.ROOT,
}

export const SCALED_ANALYSIS_PATHS = {
  ROOT: '/scaled-analysis',
  CARBON_LAB: 'carbon-lab',
  SUPPLY_SHED: 'supply-shed',
  INSIGHTS: 'insights',
}
export const SCALED_ANALYSIS_ROUTES = {
  ROOT: SCALED_ANALYSIS_PATHS.ROOT,
  CARBON_LAB: [SCALED_ANALYSIS_PATHS.ROOT, SCALED_ANALYSIS_PATHS.CARBON_LAB].join('/'),
  SUPPLY_SHED: [SCALED_ANALYSIS_PATHS.ROOT, SCALED_ANALYSIS_PATHS.SUPPLY_SHED].join('/'),
  INSIGHTS: [SCALED_ANALYSIS_PATHS.ROOT, SCALED_ANALYSIS_PATHS.INSIGHTS].join('/'),
}

export const CARBON_LAB_PATHS = {
  ROOT: `${SCALED_ANALYSIS_PATHS.ROOT}/carbon-lab`, //nested under scaled-analysis today
  FIELD: ':resourceId',
  EDIT: 'edit',
  JOB_ID: ':jobId',
  JOB_STATUS: ':jobStatus',
  JOBS: 'jobs',
  SIMULATE: 'simulate',
}

export const CARBON_LAB_ROUTES = {
  ROOT: CARBON_LAB_PATHS.ROOT,
  FIELD: [CARBON_LAB_PATHS.ROOT, CARBON_LAB_PATHS.FIELD].join('/'),
  EDIT: [CARBON_LAB_PATHS.ROOT, CARBON_LAB_PATHS.EDIT].join('/'),
  JOBS: [CARBON_LAB_PATHS.ROOT, CARBON_LAB_PATHS.JOBS].join('/'),
  JOB_STATUS: [CARBON_LAB_PATHS.ROOT, CARBON_LAB_PATHS.JOBS, CARBON_LAB_PATHS.JOB_STATUS].join('/'),
  SIMULATE: [CARBON_LAB_PATHS.ROOT, CARBON_LAB_PATHS.SIMULATE].join('/'),
  JOB_ID: [CARBON_LAB_PATHS.ROOT, CARBON_LAB_PATHS.SIMULATE, CARBON_LAB_PATHS.JOB_ID].join('/'),
}

export const SUPPLY_SHED_PATHS = {
  ROOT: `${SCALED_ANALYSIS_PATHS.ROOT}/supply-shed`, //nested under scaled-analysis today
  BOUNDARIES: 'boundaries',
  CREATE: 'create',
  COMPARE: 'compare',
}

export const SUPPLY_SHED_ROUTES = {
  ROOT: SUPPLY_SHED_PATHS.ROOT,
  BOUNDARIES: [SUPPLY_SHED_PATHS.ROOT, SUPPLY_SHED_PATHS.BOUNDARIES].join('/'),
  CREATE: [SUPPLY_SHED_PATHS.ROOT, SUPPLY_SHED_PATHS.CREATE].join('/'),
  COMPARE: [SUPPLY_SHED_PATHS.ROOT, SUPPLY_SHED_PATHS.COMPARE].join('/'),
}

export const INSIGHTS_PATHS = {
  ROOT: `${SCALED_ANALYSIS_PATHS.ROOT}/insights`,
  PARCEL: ':resourceId',
}

export const INSIGHTS_ROUTES = {
  ROOT: INSIGHTS_PATHS.ROOT,
  PARCEL: [INSIGHTS_PATHS.ROOT, INSIGHTS_PATHS.PARCEL].join('/'),
}
