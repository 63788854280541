/* istanbul ignore file */
import { getMpAgent } from './utils'
import { generatePath, Params } from '../utils'
import { MpxResponseBase } from '../types/MpxTypes'
import { FileRequest, FilesRequest, FilesResponse, FileResponse } from '../types'

const mpAgent = getMpAgent()

const PATHS = {
  FILES: '/files',
  FILE: '/files/:fileId',
}

export interface FileDetails extends Omit<FileRequest, 'file'> {
  file: any
}

export class FilesApi {
  static createFile(fileDetails: FileDetails) {
    const formData = new FormData()
    formData.append('file', fileDetails.file)
    fileDetails.name && formData.append('name', fileDetails.name)
    return mpAgent
      .post<FileResponse>(PATHS.FILES, formData)
      .then(response => response && response.data.item)
  }

  static getFiles(requestParams?: FilesRequest) {
    const requestPath = generatePath(PATHS.FILES, requestParams ? (requestParams as Params) : {})
    return mpAgent.get<FilesResponse>(requestPath).then(response => response && response.data.items)
  }

  static deleteFile(fileId: string) {
    return mpAgent.delete<MpxResponseBase>(generatePath(PATHS.FILE, { fileId }))
  }
}
