import { ResourceDetail } from './ResourceDetail'

export type LiveStockInput = {
  daysGrazing: number

  species?: LivestockSpecies
  headcount?: number
}

export interface RDLivestock extends ResourceDetail {
  traitId: 'livestock'
  year: number
  result: boolean
  input: LiveStockInput
}

export const LIVESTOCK_SPECIES = ['cattle', 'goats', 'hog', 'horses', 'sheep', 'other'] as const

export type LivestockSpecies = typeof LIVESTOCK_SPECIES[number]
