import { Tooltip, Typography, TypographyProps } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useBuildInfo } from '../../hooks/useBuildInfo'

export const AppVersion = ({ sx, ...typographyProps }: TypographyProps) => {
  const { t } = useTranslation('@cibo/ui/AppVersion')
  const {
    buildInfo,
    buildInfo: { releaseName },
  } = useBuildInfo()
  const [showCopiedMessage, setShowCopiedMessage] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => setShowCopiedMessage(false), 5000)

    return () => clearTimeout(timeout)
  }, [showCopiedMessage])

  const handleClickVersionInfo = () => {
    navigator.clipboard.writeText(JSON.stringify(buildInfo)).then(() => setShowCopiedMessage(true))
  }

  return (
    <Typography
      tabIndex={1}
      sx={{
        cursor: 'pointer',
        overflow: 'hidden',
        ...(sx ? sx : {}),
      }}
      onClick={handleClickVersionInfo}
      {...typographyProps}
    >
      <Tooltip
        open={showCopiedMessage}
        title={t('copied')}
        disableHoverListener
        onClose={() => setShowCopiedMessage(false)}
      >
        <span>{!!releaseName ? releaseName : t('local')}</span>
      </Tooltip>
    </Typography>
  )
}
