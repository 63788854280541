import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'

dayjs.extend(relativeTime)

export const intuitiveFromNow = (day: Date | string, short = false) => {
  const theDate = dayjs(day)
  const monthFormat = short ? 'MMM' : 'MMMM'

  if (theDate.isAfter(dayjs(new Date()).subtract(5, 'day'))) {
    return theDate.fromNow()
  }
  if (theDate.isSame(dayjs(new Date()), 'year')) {
    return theDate.format(`${monthFormat} Do`)
  }
  return theDate.format(`${monthFormat} Do, YYYY`)
}
