import { RDOffer } from '@cibo/core'
import { AuthUserPermission, Can } from '@cibo/profile'
import { InputLabel, Stack, Typography } from '@mui/material'
import { FieldProps, Field as FormikField } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import * as Yup from 'yup'
import {
  DetailEditorProps,
  ITraitFeatureByIdYear,
  InitialValuesRequest,
  TraitCellDisplayProps,
} from '../types'
import { findInitialValuesDetail } from '../utils'

const TRAIT_ID = 'offer'

const OfferEditor = ({ fieldModels, name = 'offerEditor' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/OfferEditor')

  return (
    <Can
      useAny={AuthUserPermission.DEBUG_USER}
      fallback={<Typography>{t('noAccessError')}</Typography>}
    >
      <FormikField name={name} id={name}>
        {({ field }: FieldProps) => (
          <Stack>
            <InputLabel htmlFor={`${name}.agreementId`}>{t('agreementId')}</InputLabel>
            <FormikField component={TextField} name={`${name}.agreementId`} />

            <InputLabel htmlFor={`${name}.paymentAmount`}>{t('paymentAmount')}</InputLabel>
            <FormikField component={TextField} name={`${name}.paymentAmount`} />

            <InputLabel htmlFor={`${name}.status`}>{t('status')}</InputLabel>
            <FormikField component={TextField} name={`${name}.status`} />
          </Stack>
        )}
      </FormikField>
    </Can>
  )
}
export const OfferCell = ({ traitId, year, params }: TraitCellDisplayProps<any>) => {
  const detail = params.row.resolveStandingDetail(traitId, year)
  const { t } = useTranslation('@cibo/landmanager/OfferEditor')

  return (
    <Can
      useAny={AuthUserPermission.DEBUG_USER}
      fallback={<Typography>{t('noAccessError')}</Typography>}
    >
      <Typography variant="body2" sx={{ color: 'text.secondary' }}>
        {JSON.stringify(detail)}
      </Typography>
    </Can>
  )
}

export const Offer: ITraitFeatureByIdYear = {
  traitId: TRAIT_ID,
  cellDisplay: OfferCell,
  editor: OfferEditor,
  initialValues: (request: InitialValuesRequest) => {
    const detail = findInitialValuesDetail<RDOffer>(TRAIT_ID)(request)

    return detail
      ? {
          agreementId: detail.agreementId,
          paymentAmount: detail.input.paymentAmount,
          status: detail.input.status,
        }
      : {
          agreementId: '',
          paymentAmount: '',
          status: '',
        }
  },
  ns: '@cibo/landmanager/OfferEditor',
  validationSchema: () => Yup.object().nullable(),
}
