import { Stack, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRollupQuestion } from '../../Rollups/RollupQuestionContext'
import { ProgramAbreviation } from './ConservationProgramsTypes'

export const ConservationProgramsRollup = (program: ProgramAbreviation) => () =>
  <ConservationProgramsRollupWithoutProgram program={program} />

const ConservationProgramsRollupWithoutProgram = ({ program }: { program: ProgramAbreviation }) => {
  const { t } = useTranslation('@cibo/landmanager/ConservationPrograms')
  const { rollupRequested, setRollupRequested, detailsPresent } = useRollupQuestion()

  const handleChange = (value: string) => {
    setRollupRequested(value === 'no')
  }

  const answer =
    rollupRequested === undefined ? (detailsPresent ? 'yes' : '') : rollupRequested ? 'no' : 'yes'

  return (
    <Stack spacing={2}>
      <Stack spacing={1} id={`conservation-${program}`}>
        <Typography>{t(`question_${program}`)}</Typography>
        <Typography variant="body2" color="text.secondary">
          {t(`helperText_${program}`)}
        </Typography>
      </Stack>

      <ToggleButtonGroup
        value={answer}
        exclusive
        onChange={(event, value) => handleChange(value)}
        aria-labelledby={`conservation-${program}`}
      >
        <ToggleButton value="yes">
          {t('rollupQuestion_yes', { ns: '@cibo/landmanager/DetailEditing' })}
        </ToggleButton>
        <ToggleButton value="no">
          {t('rollupQuestion_no', { ns: '@cibo/landmanager/DetailEditing' })}
        </ToggleButton>
      </ToggleButtonGroup>
    </Stack>
  )
}
