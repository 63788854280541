import { ResourceDetail } from '@cibo/core'
import ClearRounded from '@mui/icons-material/ClearRounded'
import { Divider, ListItemIcon, ListItemText } from '@mui/material'
import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { TraitCellEditorProps } from '../types'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result === undefined ? '' : detail?.result ? 'yes' : 'no'
}

export const FertilizerReductionCellEditor = ({
  traitId,
  year,
  params,
  saveDetail,
  removeDetail,
}: TraitCellEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/DetailEditing/FertilizerReduction')
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleChangeCrop = (event: SelectChangeEvent) => {
    const { value } = event.target

    setAnswer(value)

    if (value === '') {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
        year,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, year, result: value === 'yes' }],
      })
    }
  }

  const source = detail?.source

  return (
    <Select value={answer} onChange={handleChangeCrop} fullWidth data-testid={`cover_crop_editor`}>
      {source === 'user' && (
        <MenuItem value={''}>
          <ListItemIcon>
            <ClearRounded fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('remove')}</ListItemText>
        </MenuItem>
      )}
      <Divider />
      <MenuItem value={'yes'}>{t('yes')}</MenuItem>
      <MenuItem value={'no'}>{t('no')}</MenuItem>
    </Select>
  )
}
