import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const ByrCoverCropsCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/ByrCoverCrops')
  const detail = params.row.resolveStandingDetail(traitId)

  return (
    <Stack>
      <Typography>{t(`coverCrops_${detail.result}`)}</Typography>
    </Stack>
  )
}
