import { FormControlLabel, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useRollupQuestion } from '../Rollups/RollupQuestionContext'

export const WetlandDesignationRollup = () => {
  const { t } = useTranslation('@cibo/landmanager/WetlandDesignationEditor')
  const { rollupRequested, setRollupRequested } = useRollupQuestion()

  const handleChange = (value: string) => {
    setRollupRequested(value === 'no')
  }

  const answer = rollupRequested === undefined ? '' : rollupRequested ? 'no' : 'yes'

  return (
    <Stack>
      <Typography variant="h6">{t('rollupQuestion')}</Typography>

      <RadioGroup value={answer} onChange={event => handleChange(event.target.value)}>
        <FormControlLabel value={'yes'} control={<Radio />} label={`${t('rollupQuestionYes')}`} />
        <FormControlLabel value={'no'} control={<Radio />} label={`${t('rollupQuestionNo')}`} />
      </RadioGroup>
    </Stack>
  )
}
