import { FieldModel } from '@cibo/core'
import { indexBy, pick } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { usePaginatedWorkflowFields } from '../../../queries'

export const useFieldOverlaps = (workflowId?: string) => {
  const workflowFields = usePaginatedWorkflowFields(workflowId)
  const worker: Worker = useMemo(
    () => new Worker(new URL('./findOverlapsWorker.ts', import.meta.url), { type: 'module' }),
    []
  )
  const [isReady, setIsReady] = useState(false)
  useEffect(() => setIsReady(false), [workflowId, workflowFields.isFetching])
  const [overlaps, setOverlaps] = useState<Array<[FieldModel, FieldModel]>>()

  const [fields, fieldIndex] = useMemo(() => {
    const fields = workflowFields.data?.items
    return [fields, fields && indexBy(({ resourceId }) => resourceId, fields)]
  }, [workflowFields.data?.items])

  useEffect(() => {
    if (fields && window.Worker) {
      setIsReady(false)
      worker.postMessage(fields.map(pick(['resourceId', 'bbox', 'geometry'])))
    }
  }, [fields])

  useEffect(() => {
    if (window.Worker) {
      worker.onmessage = ({ data }: MessageEvent<Array<[string, string]>>) => {
        if (fieldIndex) {
          setOverlaps(data.map(([a, b]: [string, string]) => [fieldIndex[a], fieldIndex[b]]))
          setIsReady(true)
        }
      }
    }
  }, [worker, fieldIndex, setIsReady, setOverlaps])

  return {
    ...workflowFields,
    isReady,
    overlaps,
  }
}
