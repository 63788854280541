import { LoadingButton } from '@mui/lab'
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Stack,
} from '@mui/material'
import { Field, Form, Formik } from 'formik'
import { TextField } from 'formik-mui'
import { useSnackbar } from 'notistack'
import { Dispatch, SetStateAction, useState } from 'react'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'

import { FriendlyError, ResponsiveDialog } from '@cibo/ui'

export const WorkflowNameDialog = ({
  open,
  setOpen,
  variant = 'create',
  onSubmit,
  benchmark = 'none',
  currentName = '',
}: {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  onSubmit(values: { name: string }): Promise<any> | undefined
  variant?: 'create' | 'edit'
  benchmark?: 'none' | 'prequalify' | 'enrollment'
  currentName?: string
}) => {
  const { t } = useTranslation('@cibo/programs/WorkflowNameDialog')
  const [error, setError] = useState<string>()
  const { enqueueSnackbar } = useSnackbar()

  const onCancel = () => {
    setError(undefined)
    setOpen(false)
  }

  return (
    <ResponsiveDialog maxWidth="xs" open={open} onClose={onCancel}>
      <DialogTitle>{t('nameTitle', { context: variant })}</DialogTitle>
      <Formik
        validationSchema={yup.object().shape({
          name: yup.string().required(t('validation')),
        })}
        initialValues={{ name: currentName }}
        onSubmit={(values, actions) => {
          // @ts-ignore
          onSubmit({ name: values.name })
            .then(response => {
              enqueueSnackbar(
                t('successMessage', { context: variant, workflowName: values.name }),
                {
                  variant: 'success',
                }
              )
            })
            .catch(({ response }) => setError(response.data.message))
            .finally(() => actions.setSubmitting(false))
        }}
      >
        {props => (
          <Form onSubmit={props.handleSubmit}>
            <DialogContent>
              <Stack direction="column" spacing={1}>
                <DialogContentText>
                  {t('nameInstructions', { context: benchmark })}
                </DialogContentText>

                <Field
                  data-testid="workflow_name"
                  id="name"
                  name="name"
                  component={TextField}
                  inputProps={{ fullWidth: true }}
                  maxLength={255}
                  placeholder={t('name')}
                />
                {!!error && <FriendlyError message={error} />}
              </Stack>
            </DialogContent>
            <DialogActions>
              <Button onClick={onCancel} disabled={props.isSubmitting}>
                {t('cancel')}
              </Button>
              <LoadingButton
                loading={props.isSubmitting}
                variant="contained"
                disabled={!!props.errors.name}
                type="submit"
                data-testid="workflow-name-save"
              >
                {t('save')}
              </LoadingButton>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </ResponsiveDialog>
  )
}
