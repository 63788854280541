import { DrawPolygonMode, ModeProps, FeatureCollection } from '@nebula.gl/edit-modes'

/**
 * DrawPolygonMode does not have a the ability to "cancel" a poly.  Super annoying
 */
export class CustomDrawPolygonMode extends DrawPolygonMode {
  handleKeyUp(event: KeyboardEvent, props: ModeProps<FeatureCollection>) {
    if (event.key === 'Escape') {
      this.resetClickSequence()
    }
    super.handleKeyUp(event, props)
  }
}
