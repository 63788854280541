import { Breakpoint, Dialog, DialogProps, useMediaQuery, useTheme } from '@mui/material'
import { PropsWithChildren, useContext, useEffect } from 'react'
import { CiboUiContext } from '../CiboUiProvider'

export interface ResponsiveDialogProps extends Omit<DialogProps, 'onClose'> {
  onClose(): void
  modal?: boolean
  breakpoint?: Breakpoint
  fullScreen?: boolean
}

export const ResponsiveDialog = ({
  breakpoint = 'md',
  onClose,
  modal,
  fullScreen,
  ...rest
}: PropsWithChildren<ResponsiveDialogProps>) => {
  const theme = useTheme()
  const fullScreenComputed = useMediaQuery(theme.breakpoints.down(breakpoint)) || fullScreen
  const { events } = useContext(CiboUiContext)

  useEffect(() => {
    events.addEventListener('close_modals', onClose)

    return () => events.removeEventListener('close_modals', onClose)
  }, [])

  return <Dialog fullScreen={fullScreenComputed} onClose={modal ? undefined : onClose} {...rest} />
}
