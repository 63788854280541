import { RDOwnership } from '@cibo/core'
import { boolean, number, object } from 'yup'

import { ITraitFeatureById, InitialValuesRequest } from '../types'
import { isDetailRequired } from '../useIsRequired'
import { findInitialValuesDetail } from '../utils'
import { OwnershipCell } from './OwnershipCell'
import { OwnershipEditor } from './OwnershipEditor'

export const OwnershipInput: ITraitFeatureById<RDOwnership> = {
  rollups: {},
  forbidBulkEdit: false,
  resultsOnly: true,
  traitId: 'ownership',
  editor: OwnershipEditor,
  cellDisplay: OwnershipCell,
  ns: '@cibo/landmanager/OwnershipEditor',
  validationSchema: ({ requirement, t }) =>
    object({
      operatorIsOwner: boolean().required(),
      isRented: boolean().required(),
      yearsInOperation: number()[
        isDetailRequired({ requirement, fieldName: 'yearsInOperation' }) ? 'required' : 'optional'
      ](t('required', { context: 'yearsInOperation' })),
    }).required(t('required')),

  initialValues: (request: InitialValuesRequest) => {
    const detail = findInitialValuesDetail<RDOwnership>('ownership')(request)

    if (detail?.input) {
      const { operatorIsOwner, yearsInOperation, isRented } = detail.input
      return { operatorIsOwner, yearsInOperation, isRented }
    }
    // setting an empty string to avoid "becoming controlled" error
    return {
      operatorIsOwner: false,
      isRented: false,
      yearsInOperation: '',
    } as unknown as RDOwnership['input']
    // It's not good that we now have to do things in this file because of
    // implementation details of the editor component.
  },
}
