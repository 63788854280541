import throttle from 'lodash/throttle'
import numeral from 'numeral'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button } from '@mui/material'

import { MpxSearch, MpxSearchQuery } from '@cibo/core'

interface Props {
  changed?: boolean
  onUpdate?: () => void
  query: MpxSearchQuery
  searchResultCount?: number
}

const lookupCount = throttle((query: MpxSearchQuery, setCount: Function) => {
  MpxSearch.query({ ...query, countOnly: true }).then(({ numSearchResults }) => {
    setCount(numSearchResults)
  })
}, 300)

export default ({ changed = true, onUpdate, query, searchResultCount }: Props) => {
  const { t } = useTranslation('advancedSearchUpdateButton')
  const [count, setCount] = useState(searchResultCount || 0)
  const label = changed ? t('update_1', { label: numeral(count).format('0,0') }) : t('update_0')

  useEffect(() => {
    setCount(searchResultCount || 0)
  }, [searchResultCount])

  const stringQuery = JSON.stringify(query)

  useEffect(() => {
    if (changed) {
      lookupCount(query, setCount)
    } else {
      setCount(searchResultCount || 0)
    }
  }, [changed, stringQuery])

  return (
    <Button onClick={onUpdate} variant="contained" disabled={!changed}>
      {label}
    </Button>
  )
}
