import { Paper, ThemeProvider, Typography, useTheme } from '@mui/material'

import { THEME_DARK } from '../../../theme'

//@todo update types
export const PolygonLabel = ({ value }: { value: any }) => {
  const [color, code] = value.split(':')
  const theme = useTheme()
  return (
    <ThemeProvider theme={THEME_DARK}>
      <Paper sx={{ backgroundColor: color, paddingX: 1 }} elevation={0}>
        <Typography
          sx={{
            borderColor: 'white',
            borderWidth: '2px',
            borderRadius: `${theme.shape.borderRadius}px`,
            paddingHorizontal: 1,
          }}
        >
          {code}
        </Typography>
      </Paper>
    </ThemeProvider>
  )
}
