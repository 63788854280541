import {
  EQIP_PRACTICES_SOIL_SALINITY_OPTIONS,
  EqipPracticesSoilSalinityOption,
  RDEqipPracticesSoilSalinity,
} from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { SelectRollupEditor } from '../../SelectRollupEditor'

export const RDEqipPracticesSoilSalinityResourceDetailFeature: ResourceDetailFeature<RDEqipPracticesSoilSalinity> =
  {
    traitId: 'eqipPracticesSoilSalinity',
    TaskEditor: (props: ResourceDetailFeatureTaskEditorProps<RDEqipPracticesSoilSalinity>) => (
      <SelectRollupEditor<RDEqipPracticesSoilSalinity, readonly EqipPracticesSoilSalinityOption[]>
        options={EQIP_PRACTICES_SOIL_SALINITY_OPTIONS}
        detailKey="soilSalinity"
        ns="@cibo/landmanager/RDEqipPracticesSoilSalinityDetailFeature"
        {...props}
      />
    ),
  }
