/* istanbul ignore file */
import { AxiosError, AxiosRequestConfig } from 'axios'
import { UserId } from '../types/AuthTypes'
import { MpxResponseBaseSingle } from '../types/MpxTypes'
import {
  OrgUserCreate,
  OrgUserSearchRequest,
  OrgUserSearchResult,
  OrgUserUpdate,
  OrgUsersInviteRequest,
  OrgUsersInviteResponse,
} from '../types/OrgUserTypes'
import { PaginationResponse } from '../types/PaginationTypes'
import { generatePath } from '../utils'
import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  USER: '/org/users',
  USER_BY_ID: '/org/users/:userId',
  SEARCH: '/org/users/search',
  INVITE: '/org/users/invite',
}

export class OrgUsersAPI {
  static user(userId: UserId, config?: AxiosRequestConfig) {
    return agent
      .get<MpxResponseBaseSingle<OrgUserSearchResult>>(
        generatePath(PATHS.USER_BY_ID, { userId: userId?.toString() }),
        config
      )
      .then(response => response.data)
  }

  static patchUser(payload: OrgUserUpdate, config?: AxiosRequestConfig) {
    return agent
      .patch<MpxResponseBaseSingle<OrgUserSearchResult>>(generatePath(PATHS.USER), payload)
      .then(response => response.data)
  }

  static paginatedUsers(
    { persona, fdrStats, fips, orgIds, ...request }: OrgUserSearchRequest,
    headers?: any
  ) {
    return agent
      .get<PaginationResponse<OrgUserSearchResult>>(PATHS.SEARCH, {
        params: {
          persona: !!persona ? (Array.isArray(persona) ? persona.join(',') : persona) : undefined,
          fips:
            !!fips && fips.length > 0 ? (Array.isArray(fips) ? fips.join(',') : fips) : undefined,
          orgIds: !!orgIds ? (Array.isArray(orgIds) ? orgIds.join(',') : orgIds) : undefined,
          fdrStats: !!fdrStats ? JSON.stringify(fdrStats) : undefined,
          ...request,
        },
        headers,
      })
      .then(response => response.data)
      .catch((error: AxiosError | unknown) => {
        if ((error as AxiosError).isAxiosError) {
          console.error((error as AxiosError).message)
        }
        console.error({ error })
        throw error
      })
  }

  static createUser(payload: OrgUserCreate, config?: AxiosRequestConfig) {
    return agent.post<MpxResponseBaseSingle<OrgUserSearchResult>>(PATHS.USER, payload, config)
  }

  static sendInvite(payload: OrgUsersInviteRequest, config?: AxiosRequestConfig) {
    return agent.post<OrgUsersInviteResponse>(PATHS.INVITE, payload, config)
  }
}
