import { ResourceDetail } from './ResourceDetail'

export const TILLAGE_TYPE = ['noTill', 'conservationTillage', 'conventionalTillage'] as const
export type RegenerativeTillage = typeof TILLAGE_TYPE[number]

export const TILLAGE_IMPLEMENT = [
  'moldboardPlow',
  'chiselPlow',
  'tandemDisk',
  'fieldCultivator',
  'offsetDisk',
  'highSpeedDisk',
  'verticalTillageTool',
  'inlineDeepRipper',
  'mulchTill',
  'pivotTill',
  'ridgeTill',
  'rotationalTill',
  'stripTill',
  'turboTill',
  'other',
] as const

export type TillageImplement = typeof TILLAGE_IMPLEMENT[number]

export type TillageEvent = {
  implement: TillageImplement
  date: Date
  depth?: number
  rowSpacing?: number
  stripWidth?: number
  residueRemaining?: number
}

export type TillageEvents = {
  events: TillageEvent[]
}

export interface RDTillage extends ResourceDetail {
  traitId: 'tillage'
  year: number
  result: RegenerativeTillage
  input: TillageEvents
}
