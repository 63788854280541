import { Card, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

const FlatCard = styled<OverridableComponent<any>>(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderColor: theme.palette.divider,
  borderStyle: 'solid',
  borderWidth: 1,
}))

FlatCard.defaultProps = {
  elevation: 0,
}

export { FlatCard }
