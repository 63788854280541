import { LoadingButton } from '@mui/lab'
import { ButtonProps } from '@mui/material'
import { useFormikContext } from 'formik'

interface SubmitButtonProperties extends ButtonProps {
  disabled?: boolean
  loading?: boolean
  testId?: string
  title?: string
}

export const SubmitButton = ({
  children,
  disabled,
  loading,
  testId,
  title,
  ...rest
}: SubmitButtonProperties) => {
  const { errors, submitForm } = useFormikContext()
  const otherProps = { ...rest }

  if (testId) {
    // @ts-ignore while we could type otherProps appropriately, using LoadingButtonProps from mui/lab is mysteriously catastrophic.
    otherProps['data-testid'] = testId
  }

  return (
    <LoadingButton
      loading={loading}
      variant="contained"
      disabled={Object.keys(errors).length > 0 || disabled || loading}
      {...otherProps}
      onClick={submitForm}
    >
      {title || children}
    </LoadingButton>
  )
}
