import { CircularProgress, Stack } from '@mui/material'
import { grey } from '@mui/material/colors'

import { useOrganizationContent } from '../../queries'
import { BrandLogo } from '../BrandLogo'

interface LoadingProps {
  large?: boolean
}
export const Loading = ({ large }: LoadingProps) => {
  const { isPending, isError, isFetching } = useOrganizationContent()

  return large ? (
    <Stack
      spacing={4}
      alignItems="center"
      justifyContent="center"
      sx={{ flex: 1, overflow: 'hidden' }}
      height="100vh"
    >
      {!(isPending || isError || isFetching) && (
        <BrandLogo logoStyle={{ height: 120, width: 'unset', opacity: '0.5' }} />
      )}
      <CircularProgress sx={{ color: grey[200] }} />
    </Stack>
  ) : (
    <Stack alignItems="center" justifyContent="center" sx={{ flex: 1 }}>
      <CircularProgress />
    </Stack>
  )
}
