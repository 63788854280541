import { FieldModel, TraitId } from '@cibo/core'
import { FriendlyError } from '@cibo/ui'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { countUniqueUnlockedValues } from '../utils'

type OverrideWarningProps = {
  fieldModels: FieldModel[]
  traitId: TraitId
  year?: number
}

export const OverrideWarning = ({ fieldModels, traitId, year }: OverrideWarningProps) => {
  const { t } = useTranslation('@cibo/landmanager/OverrideWarning')
  const [uniqueUnlockedValues, setUniqueUnlockedValues] = useState(0)

  useEffect(() => {
    setUniqueUnlockedValues(countUniqueUnlockedValues({ fieldModels, traitId, year }))
  }, [])

  return uniqueUnlockedValues > 1 ? (
    <FriendlyError
      type="info"
      textProps={{
        sx: { color: 'warning.main' },
        'data-testid': 'will-override',
      }}
      message={t('willOverride', { traitId })}
      small
    />
  ) : fieldModels.filter(field => field.resolveStandingDetail(traitId, year)?.immutable).length ? (
    <FriendlyError
      type="info"
      textProps={{
        sx: { color: 'info.main' },
        'data-testid': 'wont-override-locked',
      }}
      message={t('wontOverrideLocked', { traitId })}
      small
    />
  ) : null
}
