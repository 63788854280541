import { FeatureCollection } from '@turf/helpers'
import union from '@turf/union'
import { Feature, MultiPolygon, Polygon } from 'geojson'

/**
 * Given a FeatureCollection, union all Polygon or MultiPolygon Features into a single Polygon | MultiPolygon
 * @param featureCollection FeatureCollection
 * @returns Polygon | MultiPolygon | undefined
 */
export const mergeFeatureCollection = (
  featureCollection: FeatureCollection<Polygon | MultiPolygon>
): Feature<Polygon | MultiPolygon> | undefined => {
  if (!featureCollection.features.length) return

  return featureCollection.features.reduce(
    (acc: Feature<Polygon | MultiPolygon>, feature: Feature<Polygon | MultiPolygon>) => {
      try {
        const nextUnion = union(acc, feature)

        return nextUnion || acc || feature
      } catch (e) {
        console.error(e)

        return acc || feature
      }
    }
  )
}
