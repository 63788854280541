import { RDAmendmentSummaryApplicationTypes, RDAmendmentSummaryUnits } from '@cibo/core'
import { AutocompleteField } from '@cibo/ui'
import { FormControl, FormLabel, Stack, Typography } from '@mui/material'
import { Field, FieldProps } from 'formik'
import { CheckboxWithLabel, TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const AmendmentSummaryEditor = ({
  year,
  name = 'amendmentSummaryEditor',
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/AmendmentSummary')

  return (
    <Field name={name} id={name}>
      {({ field }: FieldProps) => {
        const applicationType = field.value?.applicationType
        const isNotApplicable = applicationType === 'not_available'
        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { year })}</Typography>
            <FormControl required>
              <FormLabel>{t('applicationType')}</FormLabel>
              <AutocompleteField
                name={`${name}.applicationType`}
                options={RDAmendmentSummaryApplicationTypes}
                getOptionLabel={(type: string) => t(`applicationType_${type}`)}
                selectOnFocus
                data-testid={`${name}.applicationType`}
              />
            </FormControl>
            <Stack direction="row" spacing={1} alignSelf="stretch">
              <FormControl required={!isNotApplicable}>
                <FormLabel>{t('rate')}</FormLabel>
                <Field
                  component={TextField}
                  InputProps={{ type: 'number', min: 0 }}
                  inputProps={{ 'data-testid': `${name}.rate` }}
                  name={`${name}.rate`}
                  disabled={isNotApplicable}
                />
              </FormControl>
              <FormControl sx={{ flex: 2 }} required={!isNotApplicable}>
                <FormLabel>{t('units')}</FormLabel>
                <AutocompleteField
                  name={`${name}.units`}
                  options={RDAmendmentSummaryUnits}
                  getOptionLabel={(units: string) => t(`units_${units}`)}
                  data-testid={`${name}.units`}
                  selectOnFocus
                  disabled={isNotApplicable}
                />
              </FormControl>
            </Stack>
            <Stack>
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={`${name}.compost`}
                Label={{ label: t('compost') }}
                data-testid={`${name}.compost`}
                disabled={isNotApplicable}
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={`${name}.manure`}
                Label={{ label: t('manure') }}
                data-testid={`${name}.manure`}
                disabled={isNotApplicable}
              />
              <Field
                component={CheckboxWithLabel}
                type="checkbox"
                name={`${name}.biochar`}
                Label={{ label: t('biochar') }}
                data-testid={`${name}.biochar`}
                disabled={isNotApplicable}
              />
            </Stack>
          </Stack>
        )
      }}
    </Field>
  )
}
