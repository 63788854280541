import { Alert, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TableCtaProps } from '../types'

export const TillageTableCta = ({ count }: TableCtaProps) => {
  const { t } = useTranslation('@cibo/landmanager/TillageTableCta')
  return (
    <>
      <Typography>{t('cta', { count })}</Typography>
      <Alert severity="info">{t('helperText')}</Alert>
    </>
  )
}
