import { BIOLOGICAL_TYPE } from '@cibo/core'
import { SelectField } from '@cibo/ui'
import { FormControl, FormLabel, Stack } from '@mui/material'
import { Field } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const BiologicalEditor = ({ name = 'biologicalEditor' }: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/BiologicalEditor')

  return (
    <Stack spacing={2}>
      <FormControl required>
        <FormLabel htmlFor={`${name}.type`}>{t('cta')}</FormLabel>
        <SelectField fieldName={`${name}.type`} options={BIOLOGICAL_TYPE} renderOption={t} />
      </FormControl>

      <FormControl>
        <FormLabel htmlFor={`${name}.product`}>{t('product')}</FormLabel>
        <Field
          component={TextField}
          id={`${name}.product`}
          name={`${name}.product`}
          inputProps={{ 'data-testid': `${name}.product` }}
        />
      </FormControl>
    </Stack>
  )
}
