import { IrrigationEvent, TraitId } from '@cibo/core'
import { Stack, Tooltip, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

type Props = {
  traitId: TraitId
  year?: number
  params: GridRenderCellParams
}

export const EventIrrigationCell = ({ traitId, year, params }: Props) => {
  const { t } = useTranslation('@cibo/landmanager/EventIrrigationEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.events?.length) {
    return (
      <Tooltip
        title={
          <Stack spacing={1} padding={1}>
            {detail.input?.events.map(
              (
                { startDate, endDate, amount, unit = 'inchesPerAcre' }: IrrigationEvent,
                index: number
              ) => (
                <Stack key={index}>
                  <Typography variant="body1">
                    {t('eventDate', { startDate: new Date(startDate), endDate: new Date(endDate) })}
                  </Typography>
                  <Typography variant="body2">
                    {t('irrigationValUnit', { context: unit, amount })}
                  </Typography>
                </Stack>
              )
            )}
          </Stack>
        }
      >
        <Typography variant="body1">
          {t('events', { count: detail.input?.events?.length })}
        </Typography>
      </Tooltip>
    )
  }

  return <Typography>{t('none')}</Typography>
}
