export interface LatLong {
  Latitude: number
  Longitude: number
}

export interface Bounds {
  lowerLeft: LatLong
  upperRight: LatLong
}

export interface NativeMapBounds {
  ne: [number, number]
  sw: [number, number]
}

export type Array2d = [[number, number], [number, number]]

export type BBoxArray = number[] & { __brand: 'BBoxArray' }
export function isBBoxArray(input: number[]): input is BBoxArray {
  return input.length === 4
}
export function assertBBoxArray(input?: number[]): asserts input is BBoxArray {
  if (!input || !isBBoxArray(input)) throw new Error(`input [${input}] is not BBoxArray`)
}
