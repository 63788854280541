import {
  TableRow as MuiTableRow,
  TableRowProps,
  TableRowTypeMap,
  alpha,
  styled,
} from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

interface StyledTableRowProps extends TableRowProps {
  hasError?: boolean
}

const StyledTableRow = styled<OverridableComponent<TableRowTypeMap<{ hasError?: boolean }, 'tr'>>>(
  MuiTableRow
)(({ theme, hasError }) => ({
  '&.MuiTableRow-root': {
    backgroundColor: hasError ? alpha(theme.palette.error.light, 0.8) : 'inherit',
    '& .MuiTableCell-root, .MuiIconButton-root': {
      color: hasError ? theme.palette.error.contrastText : undefined,
    },
  },
}))

export const TableRow = (props: StyledTableRowProps) => <StyledTableRow {...props} />
