import { RadioBooleanField } from '@cibo/ui'
import { FormControl, FormLabel, Stack } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

const AMOUNT_LBS = 20

export const CornNitrogenApplicationEditor = ({ name, year }: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/CornNitrogenApplication')

  return (
    <Stack spacing={2}>
      <FormControl>
        <FormLabel>{t('hasPreviousManagementData', { year })}</FormLabel>
        <RadioBooleanField
          name={`${name}.hasPreviousManagementData`}
          data-testid={`${name}.hasPreviousManagementData`}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('nitrogenApplicationReduced', { year, amount_lbs: AMOUNT_LBS })}</FormLabel>
        <RadioBooleanField
          name={`${name}.nitrogenApplicationReduced`}
          data-testid={`${name}.nitrogenApplicationReduced`}
        />
      </FormControl>
      <FormControl>
        <FormLabel>{t('addedNitrogenStabilizer', { year })}</FormLabel>
        <RadioBooleanField
          name={`${name}.addedNitrogenStabilizer`}
          data-testid={`${name}.addedNitrogenStabilizer`}
        />
      </FormControl>
    </Stack>
  )
}
