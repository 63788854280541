/* istanbul ignore file */
import { getMpAgent } from './utils'
import { generatePath } from '../utils'
import { MpxResponseBase, MpxListResponse } from '../types/MpxTypes'
import { LabelsMergeRequest, LabelsRequest, LabelsSearchRequest } from '../types/LabelsTypes'

const mpAgent = getMpAgent()

const PATHS = {
  LABELS: '/fields/labels',
  LABEL_SEARCH: '/fields/labels/search',
}

export class LabelsAPI {
  static searchLabels(query: LabelsSearchRequest) {
    const { fullText } = query
    return mpAgent
      .get<MpxListResponse<any>>(generatePath(PATHS.LABEL_SEARCH), {
        params: {
          ...query,
          fullText: !!fullText ? fullText : undefined,
        },
      })
      .then(response => response && response.data.items)
  }

  static setLabels(requestParams?: LabelsRequest) {
    return mpAgent.put<MpxResponseBase>(PATHS.LABELS, requestParams)
  }

  static mergeLabels(requestParams: LabelsMergeRequest) {
    return mpAgent.patch<MpxResponseBase>(PATHS.LABELS, requestParams)
  }
}
