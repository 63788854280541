/* istanbul ignore file */
import buildUrl from 'build-url'
import {
  SupplyShedReport,
  SupplyShedSearchQuery,
  SupplyShedSearchResult,
  SupplyShedFeature,
} from '../types/SupplyShedTypes'
import { Geometry } from 'geojson'
import { generatePath } from '../utils'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  REPORT: '/supplyShed/compare',
  SEARCH: '/supplyShed/search',
  CUSTOM: '/supplyShed/custom',
  FETCH_ONE: '/supplyShed/custom/:resourceId',
  PAGINATED: '/supplyShed/custom',
}

export type SupplyShedCashCropChoice = 'corn' | 'soybeans'
export type SupplyShedCoverCropChoice = 'notUnderCover' | 'underCover'
export type SupplyShedTillageChoice = 'noTill' | 'conservationTillage' | 'conventionalTillage'

export type SupplyShedFilters = {
  crop?: SupplyShedCashCropChoice[]
  coverCrop?: SupplyShedCoverCropChoice
  tillage?: SupplyShedTillageChoice[]
}

export interface SupplyShedReportRequest extends SupplyShedFilters {
  resourceIds: string[]
}

export type SupplyShedReportResponse = {
  items: SupplyShedReport[]
}

export type SupplyShedSearchResponse = {
  numItems: number
  items: SupplyShedSearchResult[]
}

export type CreateSupplyShedRequest = {
  name: string
  description: string
  geometry: Geometry
}
export interface DeleteSupplyShedRequest {
  resourceIds: string[]
}

export type CreateSupplyShedResponse = {
  item: any
}

export interface UserSupplyShedsRequest extends SupplyShedFilters {
  limit?: number
  offset?: number
  resourceIds?: string[]
}

export type UserSupplyShedsResponse = {
  numAvailable: number
  items: SupplyShedFeature[]
}

export class SupplyShedAPI {
  static async create(request: CreateSupplyShedRequest): Promise<any> {
    return agent
      .post<CreateSupplyShedResponse>(PATHS.CUSTOM, request)
      .then(response => response && response.data.item)
  }

  static async delete(request: DeleteSupplyShedRequest): Promise<any> {
    return (
      agent
        // @ts-ignore buildUrl TS doesn't like the request type
        .delete<CreateSupplyShedResponse>(buildUrl(PATHS.CUSTOM, { queryParams: request }))
        .then(response => response && response.data.item)
    )
  }

  static async fetch(resourceId: string): Promise<SupplyShedFeature> {
    return agent
      .get<CreateSupplyShedResponse>(generatePath(PATHS.FETCH_ONE, { resourceId }))
      .then(response => response && response.data.item)
  }

  static async list(request: UserSupplyShedsRequest): Promise<UserSupplyShedsResponse> {
    return agent
      .get<any>(
        buildUrl(PATHS.PAGINATED, {
          // @ts-ignore
          queryParams: request,
        })
      )
      .then(response => response && response.data)
  }

  static async report(request: SupplyShedReportRequest): Promise<SupplyShedReport[]> {
    //@ts-ignore why are we forced to return a possible `void` type on GET?  b/c of the error handler catch in agent
    return agent
      .post<SupplyShedReportResponse>(
        buildUrl(PATHS.REPORT, {
          // @ts-ignore
          queryParams: { ...request, includeGeometry: 'true' },
        })
      )
      .then(response => response && response.data.items)
  }

  static async search(request: SupplyShedSearchQuery): Promise<SupplyShedSearchResponse> {
    const queryParams: any = {
      ...request,
      crop: request.crop?.join(','),
      tillage: request.tillage?.join(','),
    }

    if (request.cover) {
      queryParams.cover = `${request.cover}`
    }
    //@ts-ignore why are we forced to return a possible `void` type on GET?  b/c of the error handler catch in agent
    return agent
      .get<SupplyShedSearchResponse>(
        buildUrl(PATHS.SEARCH, {
          queryParams,
        })
      )
      .then(response => response && response.data)
  }
}
