import { WorkflowItem } from '@cibo/core'
import { useAuth } from '@cibo/profile'
import { FriendlyError, Markdown, ResponsiveDialog, ResponsiveDialogProps } from '@cibo/ui'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material'
import { useSnackbar } from 'notistack'
import { PropsWithChildren, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDeleteWorkflow, useWorkflows } from '../../queries'

interface EnrollmentWindowClosedModalProps extends ResponsiveDialogProps {
  open: boolean
  onClose(): void
  onDeleteSuccess?: () => void
  workflowId?: string
  enrollments?: WorkflowItem[]
  programId?: string
  programName?: string
  count?: number
  modal?: boolean
  destructive?: boolean
}

export const EnrollmentWindowClosedModal = ({
  open,
  onClose,
  onDeleteSuccess,
  programName,
  programId,
  workflowId,
  enrollments,
  count,
  modal = true,
  destructive = false,
  children,
  ...rest
}: PropsWithChildren<EnrollmentWindowClosedModalProps>) => {
  const { persona } = useAuth()
  const { t } = useTranslation('@cibo/programs/EnrollmentWindowClosedModal')
  const enrollmentsQuery = useWorkflows(0, 1000, {
    benchmark: ['enrollment'],
    programId,
  })
  const deleteWorkflow = useDeleteWorkflow()

  const deleteAll = async () => {
    const enrollmentsToDelete = enrollments ? enrollments : enrollmentsQuery.data?.items
    return !enrollmentsToDelete
      ? await Promise.all([])
      : await Promise.all(
          enrollmentsToDelete.map(enrollment => deleteWorkflow.mutateAsync(enrollment.id))
        )
  }
  const { enqueueSnackbar } = useSnackbar()
  const [deleteFailed, setDeleteFailed] = useState(false)

  const handleClose = () => {
    onClose()
    onDeleteSuccess && onDeleteSuccess()
    setDeleteFailed(false)
  }

  const onDeleteWorkflow = async () => {
    if (!!workflowId) {
      deleteWorkflow
        .mutateAsync(workflowId)
        .then(() => {
          handleClose()
          enqueueSnackbar(t('deleteSuccess', { count: 1 }), { variant: 'success' })
        })
        .catch(() => {
          setDeleteFailed(true)
          enqueueSnackbar(t('deleteError', { count: 1 }), { variant: 'error' })
        })
    }
    if (programId || enrollments) {
      deleteAll()
        .then(() => {
          handleClose()
          enqueueSnackbar(t('deleteSuccess', { count: enrollmentsQuery.data?.numItems }), {
            variant: 'success',
          })
        })
        .catch(() => {
          setDeleteFailed(true)
          enqueueSnackbar(t('deleteError', { count: enrollmentsQuery.data?.numItems }), {
            variant: 'error',
          })
        })
    }
    if (!workflowId || !programId) {
      setDeleteFailed(true)
    }
  }

  return (
    <ResponsiveDialog open={open} disableEscapeKeyDown modal={modal} onClose={onClose} {...rest}>
      <DialogTitle>{t('deleteEnrollmentTitle')}</DialogTitle>
      <DialogContent>
        <Stack spacing={1}>
          {children ? (
            children
          ) : (
            <Markdown>
              {t('deleteEnrollmentDescriptionDetail', { programName, context: persona, count })}
            </Markdown>
          )}
          {deleteFailed && <FriendlyError message={t('deleteFailed')} />}
        </Stack>
      </DialogContent>

      <DialogActions>
        {deleteFailed && !!modal && (
          <Button variant="outlined" onClick={handleClose}>
            {t('goBack')}
          </Button>
        )}
        {!modal && (
          <Button variant="outlined" onClick={onClose}>
            {t('cancel')}
          </Button>
        )}
        <LoadingButton
          variant="contained"
          color={modal ? 'primary' : 'error'}
          onClick={onDeleteWorkflow}
          loading={enrollmentsQuery.isFetching}
        >
          {modal
            ? t('okay')
            : t(workflowId || (enrollments && enrollments.length === 1) ? 'delete' : 'deleteAll')}
        </LoadingButton>
      </DialogActions>
    </ResponsiveDialog>
  )
}
