import { GridRenderCellParams } from '@mui/x-data-grid-pro'

import { FieldModel } from '@cibo/core'
import { LineLimitTypography, useFigures } from '@cibo/ui'
import { Box, Link, Stack, Typography } from '@mui/material'
import { FieldLabel } from '../FieldLabel'
import { LandThumbnail, LandThumbnailSkin } from '../LandThumbnail'
import { FieldColumnProps } from './types'

export const fieldIdentifierColumn = ({ onClickField, t, ...columnProps }: FieldColumnProps) => ({
  // API sorts based on 'name' param
  field: 'name',
  headerName: t('identifier', { ns: '@cibo/landmanager/FieldColumns' }),
  resizable: true,
  filterable: false,
  minWidth: 250,
  flex: 1,
  renderCell: (params: GridRenderCellParams<FieldModel>) => {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const { acres } = useFigures()

    const nameDetail = params.row.resolveStandingDetail('name')

    return (
      <Stack
        justifySelf="center"
        spacing={0.5}
        flex={1}
        paddingY={0.5}
        overflow="hidden"
        data-testid={`col-identifier-${params.id}`}
      >
        <Stack direction="row" spacing={1}>
          <Box display="flex" justifySelf="center" borderRadius={2}>
            <LandThumbnail
              field={params.row}
              width={60}
              height={60}
              skin={LandThumbnailSkin.SMALL}
            />
          </Box>
          <Stack>
            {onClickField ? (
              <Link
                onClick={() => onClickField({ id: params.row.id })}
                underline="hover"
                sx={{ cursor: 'pointer', color: 'secondary.main' }}
              >
                <LineLimitTypography lineLimit={1} textOverflow="ellipsis" variant="body2">
                  {params.row.name}
                </LineLimitTypography>
              </Link>
            ) : (
              <LineLimitTypography lineLimit={1} textOverflow="ellipsis" variant="body2">
                {params.row.name}
              </LineLimitTypography>
            )}
            <LineLimitTypography lineLimit={1} textOverflow="ellipsis" variant="caption">
              {params.row.locationLabel}
            </LineLimitTypography>
            <Typography color="text.secondary" textOverflow="ellipsis" variant="body2">
              {acres(params.row.acres)}
            </Typography>
            {!!nameDetail.sourceData?.origin && (
              <Typography color="text.secondary" textOverflow="ellipsis" variant="caption">
                <>{t('createdWith', { origin: nameDetail.sourceData?.origin })}</>
              </Typography>
            )}
          </Stack>
        </Stack>
        {!!params.row.labels.length && (
          <Stack direction="row" spacing={1}>
            {params.row.labels.map(label => (
              <FieldLabel key={label} label={label} />
            ))}
          </Stack>
        )}
      </Stack>
    )
  },
  ...columnProps,
})
