import { Grid2 as Grid, Stack } from '@mui/material'
import { PropsWithChildren } from 'react'

import { CDLLegend, LayerSwitcher, MapStyles } from '@cibo/mapgl'

export const LayerControls = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Stack
      justifyContent="space-between"
      sx={{ position: 'relative', flex: 1, pointerEvents: 'none' }}
    >
      <Grid sx={{ padding: 2, paddingBottom: 5 }} container>
        <Grid size={{ xs: 6 }}></Grid>
      </Grid>
      <Grid sx={{ padding: 2, paddingBottom: 5 }} justifyContent="space-between" container>
        <Grid size={{ xs: 3 }} alignItems="flex-end" container>
          <Stack spacing={2} style={{ pointerEvents: 'all' }}>
            <CDLLegend />

            <LayerSwitcher mapLayers={{ [MapStyles.SATELLITE]: true, [MapStyles.FOCUSED]: true }} />
          </Stack>
        </Grid>
        <Grid size={{ xs: 3 }} alignItems="flex-end" justifyContent="flex-end" container>
          <div style={{ pointerEvents: 'all' }}>{!!children && children}</div>
        </Grid>
      </Grid>
    </Stack>
  )
}
