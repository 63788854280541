/* istanbul ignore file */
import { MpxResponseBase, getMpAgent } from '@cibo/core'
import { AxiosRequestConfig } from 'axios'
import {
  AgreementCreateAttachmentRequest,
  AgreementFileResponse,
  AgreementHistoryResponse,
  AgreementItemRequest,
  AgreementListRequest,
  AgreementNote,
  AgreementNoteCreateRequest,
  AgreementNotesResponse,
  AgreementResponse,
  AgreementStatusUpdate,
  AgreementsResponse,
  CreateAgreementRequest,
  CreateErrorReportRequest,
} from '../types'

const mpAgent = getMpAgent()

const PATHS = {
  AGREEMENTS: '/agreements',
  AGREEMENT: '/agreements/:agreementId',
  AGREEMENT_NOTES: '/agreements/:agreementId/notes',
  AGREEMENT_FILE: '/agreements/:agreementId/file',
  AGREEMENT_ZIP: '/agreements/:agreementId/documents/zip',
  AGREEMENT_HISTORY: '/agreements/:agreementId/history',
  CREATE_ERROR_REPORT: '/fields/reporting/error',
}

export class AgreementsAPI {
  static getAgreements(requestParams?: AgreementListRequest) {
    return mpAgent
      .get<AgreementsResponse>(PATHS.AGREEMENTS, { params: requestParams })
      .then(response => response && response.data)
  }

  static getAgreement({ agreementId, ...params }: AgreementItemRequest) {
    return mpAgent
      .get<AgreementResponse>({ template: PATHS.AGREEMENT, params: { agreementId } }, { params })
      .then(response => response && response.data)
  }

  static updateAgreement({ agreementId, ...request }: AgreementStatusUpdate) {
    return mpAgent
      .patch<AgreementResponse>({ template: PATHS.AGREEMENT, params: { agreementId } }, request)
      .then(response => response && response.data)
  }

  static getAgreementNotes(agreementId: string) {
    return mpAgent
      .get<AgreementNotesResponse>({ template: PATHS.AGREEMENT_NOTES, params: { agreementId } })
      .then(response => response && response.data.items)
  }

  static createAttachment(
    agreementId: string,
    request: AgreementCreateAttachmentRequest,
    config?: AxiosRequestConfig
  ) {
    const formData = new FormData()
    formData.append('file', request.file)

    request.fileId && formData.append('fileId', request.fileId)
    request.fileName && formData.append('fileName', request.fileName)
    request.description && formData.append('description', request.description)

    return mpAgent.post<AgreementFileResponse>(
      { template: PATHS.AGREEMENT_FILE, params: { agreementId } },
      formData,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
    )
  }
  static updateAttachment(
    agreementId: string,
    request: AgreementCreateAttachmentRequest,
    config?: AxiosRequestConfig
  ) {
    const formData = new FormData()
    formData.append('file', request.file)

    request.fileId && formData.append('fileId', request.fileId)
    request.fileName && formData.append('fileName', request.fileName)
    request.description && formData.append('description', request.description)

    return mpAgent.put<AgreementFileResponse>(
      { template: PATHS.AGREEMENT_FILE, params: { agreementId } },
      formData,
      config
    )
  }
  static getAttachment(agreementId: string, fileId: string) {
    return mpAgent.get<Blob>(
      { template: PATHS.AGREEMENT_FILE, params: { agreementId } },
      {
        params: { fileId },
        responseType: 'blob',
      }
    )
  }

  static getAgreementFile(agreementId: string) {
    return mpAgent.get<Blob>(
      { template: PATHS.AGREEMENT_FILE, params: { agreementId } },
      {
        responseType: 'blob',
      }
    )
  }
  static getAgreementZip(agreementId: string) {
    return mpAgent.get<Blob>(
      { template: PATHS.AGREEMENT_ZIP, params: { agreementId } },
      {
        responseType: 'blob',
      }
    )
  }

  static getAgreementHistory(agreementId: string) {
    return mpAgent
      .get<AgreementHistoryResponse>({ template: PATHS.AGREEMENT_HISTORY, params: { agreementId } })
      .then(response => response && response.data.items)
  }

  static createAgreement(request: CreateAgreementRequest) {
    return mpAgent
      .put<AgreementResponse>(PATHS.AGREEMENTS, request)
      .then(response => response && response.data)
  }

  static createAgreementNote({ agreementId, ...request }: AgreementNoteCreateRequest) {
    return mpAgent
      .post<AgreementNote>({ template: PATHS.AGREEMENT_NOTES, params: { agreementId } }, request)
      .then(response => response && response.data)
  }

  static createErrorReport(request: CreateErrorReportRequest) {
    return mpAgent.put<MpxResponseBase>(PATHS.CREATE_ERROR_REPORT, request)
  }
}
