import { RDEnrolledInUSCottonTrust } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Button, ButtonGroup, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useUpdateUserDetails, useUserDetails } from '../../../hooks'

const ANSWERS = ['yes', 'no'] as const
export type CottonTrustBulkAnswer = typeof ANSWERS[number]

export const EnrolledInUSCottonTrust: ResourceDetailFeature<RDEnrolledInUSCottonTrust> = {
  traitId: 'enrolledInUSCottonTrust',
  TaskEditor: ({
    detailRequirements,
    resourceIds: [resourceId],
    onError,
    onSuccess,
    onUpdating,
  }: ResourceDetailFeatureTaskEditorProps<RDEnrolledInUSCottonTrust>) => {
    const { t } = useTranslation('@cibo/landmanager/EnrolledInUSCottonTrust')
    const updateUserDetails = useUpdateUserDetails({})
    const details = useUserDetails({
      resourceId,
      // @ts-ignore detailRequirements is impossible to type as far as I can tell. it must be both `never` and `undefined`
      detailRequirements,
    })
    const year = detailRequirements[0].year

    const onChange = (value: 'yes' | 'no') => {
      onUpdating?.()
      updateUserDetails
        .mutateAsync({
          resourceId,
          details: [
            {
              traitId: 'enrolledInUSCottonTrust',
              result: value === 'yes',
              year,
            },
          ],
        })
        .then(onSuccess)
        .catch(onError)
    }

    return (
      <Stack spacing={2}>
        <Typography>{t('question')}</Typography>
        <ButtonGroup
          color="secondary"
          disabled={
            details.data?.[0]?.immutable || details?.isPending || updateUserDetails?.isPending
          }
        >
          <Button
            data-testid="cotton-trust-yes"
            onClick={() => onChange('yes')}
            variant={details.data?.[0]?.value === true ? 'contained' : 'outlined'}
            disableElevation
          >
            {t('yes')}
          </Button>
          <Button
            data-testid="cotton-trust-no"
            onClick={() => onChange('no')}
            variant={details.data?.[0]?.value === false ? 'contained' : 'outlined'}
            disableElevation
          >
            {t('no')}
          </Button>
        </ButtonGroup>
      </Stack>
    )
  },
}
