import { RDEqipEligibilityConservationACEP } from '@cibo/core'
import { ResourceDetailFeature, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { BooleanRollup } from '../../BooleanRollup'

export const RDEqipEligibilityConservationACEPResourceDetailFeature: ResourceDetailFeature<RDEqipEligibilityConservationACEP> =
  {
    traitId: 'eqipEligibilityConservationACEP',
    TaskEditor: (
      props: ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityConservationACEP>
    ) => (
      <BooleanRollup<RDEqipEligibilityConservationACEP>
        answerValues={{ yes: { value: 'yes' }, no: { value: 'no' } }}
        detailKey="acepEnrolled"
        ns="@cibo/landmanager/RDEqipEligibilityConservationACEPResourceDetailFeature"
        {...props}
      />
    ),
  }
