import {
  BenchmarkStatus,
  FDRStatus,
  FieldsAPI,
  GrowerProgramRequest,
  GrowerProgramsAPI,
  MpxResponseError,
  ProgramBenchmarkName,
  ProgramConfig,
  ProgramModel,
  ProgramOpportunitiesRequest,
  RewardToken,
} from '@cibo/core'
import { UseQueryOptions, UseQueryResult, useQueries, useQuery } from '@tanstack/react-query'
import { prop } from 'ramda'
import { PROGRAMS_QUERY_KEY } from './queryKey'
import { useAllProgramContent } from './useProgramContent'

type UseProgramsProps = Partial<Pick<GrowerProgramRequest, 'organization' | 'orgId' | 'sort'>>

/**
 * @TODO remove this once the backend implements filtering and sorting
 *
 * for now, hardcode the requested sorting logic here
 * https://cibotech.atlassian.net/browse/CPD-4512
 */
// eslint-disable-next-line complexity
const TEMP_sortPrograms = (a: ProgramModel, b: ProgramModel) => {
  if (a.isEnrolling && b.isEnrolling) {
    return 0
  } else if (a.isEnrolling) {
    return -1
  } else if (b.isEnrolling) {
    return 1
  } else if (a.isFuture && b.isFuture) {
    return 0
  } else if (a.isFuture) {
    return -1
  } else if (b.isFuture) {
    return 1
  } else if ((a.enrollmentCloseDate ?? 0) < (b.enrollmentCloseDate ?? 0)) {
    return -1
  } else if ((a.enrollmentCloseDate ?? 0) > (b.enrollmentCloseDate ?? 0)) {
    return 1
  } else if (a.closeDate < b.closeDate) {
    return -1
  } else if (a.closeDate > b.closeDate) {
    return 1
  }
  return 0
}

export const usePrograms = (params?: UseProgramsProps) =>
  useQuery<ProgramModel[]>({
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS, params],
    queryFn: async () => {
      const { sort, ...otherParams } = params ?? {}
      // @todo passthrough sort and filter to the backend once it is implemented
      const programs = await GrowerProgramsAPI.allPrograms()
      const programModels = await Promise.all(
        programs.map(async program => {
          const programData = await GrowerProgramsAPI.program({
            programId: program.programId,
            ...otherParams,
          })
          return new ProgramModel({
            ...programData,
            //@temp on 12/1/2023 there was an incident where the response dropped the programId
            programId: program.programId,
            content: program.content,
          })
        })
      )
      // @todo remove this once the backend implements filtering and sorting
      return sort ? programModels.sort(TEMP_sortPrograms) : programModels
    },
  })

export const useProgramStatusCount = ({
  benchmark,
  status,
  participating,
  programsProps,
}: {
  benchmark: ProgramBenchmarkName
  status: BenchmarkStatus
  participating?: boolean
  programsProps?: UseProgramsProps
}) => {
  const { data, ...programContentQuery } = useAllProgramContent()

  const programQueries = useQueries({
    queries: !!data
      ? data.map(({ programId }) => ({
          queryKey: [
            PROGRAMS_QUERY_KEY.PROGRAM_STATUS_FIELDCOUNTS,
            programId,
            benchmark,
            status,
            !!participating,
          ],
          queryFn: async () => {
            if (!programId) {
              return
            }
            const programData = await FieldsAPI.paginatedFields({
              limit: 1,
              programs: [
                {
                  id: programId,
                  benchmarks: [{ benchmark, status }],
                  participating: participating || undefined,
                },
              ],
            })
            return { count: programData.numAvailable, programId }
          },
        }))
      : [],
  })

  const isPending = programContentQuery.isPending || programQueries.some(prop('isPending'))
  const error = programContentQuery.error || programQueries.some(prop('error'))

  return {
    isError: programContentQuery.isError || programQueries.some(prop('isError')),
    isFetching: programContentQuery.isFetching || programQueries.some(prop('isFetching')),
    isFetched: programContentQuery.isFetched || programQueries.every(prop('isFetched')),
    isPending,
    error,
    data:
      !isPending && !error
        ? (programQueries
            .map(({ data }) => (data?.count !== 0 ? data : undefined))
            .filter(val => val !== undefined) as {
            count: number
            programId: string
          }[])
        : [],
  }
}

/**
 *
 * @todo fix the query on the fields request.  I am pretty sure that we do not want to fetch
 * fields where *ANY* program is hit - which is what the `programs` parameter does.  Rather
 * we probably want `allPrograms` which performs an `AND` on all referenced programs
 */
export const useProgramStatusCountAggregate = ({
  benchmark,
  status,
  participating,
  programsProps,
}: {
  benchmark: ProgramBenchmarkName
  status: BenchmarkStatus
  participating?: boolean
  programsProps?: UseProgramsProps
}) => {
  const { data, ...programContentQuery } = useAllProgramContent()

  const fieldsQuery = useQuery({
    queryKey: [
      PROGRAMS_QUERY_KEY.PROGRAM_STATUS_AGGREGATE_FIELDCOUNT,
      benchmark,
      status,
      participating,
    ],
    queryFn: async () => {
      if (!data) {
        return
      }
      const program = await FieldsAPI.paginatedFields({
        limit: 1,
        programs: data
          ? data.map(({ programId }) => ({
              id: programId,
              benchmarks: [{ benchmark, status }],
              participating: participating || undefined,
            }))
          : [],
      })
      return program
    },
  })

  const isPending = programContentQuery.isPending || fieldsQuery.isPending
  const error = programContentQuery.error || fieldsQuery.error

  return {
    isError: programContentQuery.isError || fieldsQuery.isError,
    isFetching: programContentQuery.isFetching || fieldsQuery.isFetching,
    isFetched: programContentQuery.isFetched || fieldsQuery.isFetched,
    isPending,
    error,
    data: !isPending && !error && fieldsQuery.data,
  }
}

export const useProgram = (
  { programId, ...params }: Partial<Omit<GrowerProgramRequest, 'filter' | 'sort'>>,
  options?: UseQueryOptions
) => {
  const programContentQuery = useAllProgramContent()
  const content = programContentQuery.data?.find(
    program => program.programId === programId
  )?.content

  return useQuery<ProgramConfig, unknown, ProgramConfig, any>({
    //@ts-ignore
    queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS, programId],
    //@ts-ignore content will be defined since it is dependent in the `enabled` clause
    queryFn: async () => {
      if (!programId) {
        return
      }
      const program = await GrowerProgramsAPI.program({
        programId,
        ...params,
      } as GrowerProgramRequest)
      /**
       * @todo
       * remove the stats call and overwritten opportunitiesByWfStatus.notInWorkflow once
       * CPD-2484 is fixed
       */
      const stats = await FieldsAPI.stats({
        fdrStats: [
          {
            status: FDRStatus.eligibleForEnrollment,
            programId,
          },
        ],
      })
      const fdrStats = stats.data.fdrStats[0]
      return {
        ...program,
        //@temp on 12/1/2023 there was an incident where the response dropped the programId
        programId,
        opportunitiesByWfStatus: {
          ...program.opportunitiesByWfStatus,
          notInWorkflow: [
            {
              acres: fdrStats?.acres,
              numResources: fdrStats?.fields,
              reward: !!fdrStats?.rewards?.opportunities
                ? Object.keys(fdrStats?.rewards?.opportunities).map(unit => ({
                    ...fdrStats?.rewards?.opportunities[unit as RewardToken],
                  }))
                : [],
            },
          ],
        },
        content,
      }
    },
    enabled: !!programId && !!content && !!programContentQuery.data,
    ...options,
  })
}

// @todo standardize on the useProgram hook returning a ProgramModel
export const useProgramModel = (params: Partial<Omit<GrowerProgramRequest, 'filter' | 'sort'>>) =>
  // @ts-ignore
  useProgram(params, {
    select: program => new ProgramModel(program as ProgramConfig),
  }) as UseQueryResult<ProgramModel, MpxResponseError>

export const useProgramOpportunity = (params: ProgramOpportunitiesRequest) =>
  useQuery({
    queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES, params],
    queryFn: async () => GrowerProgramsAPI.opportunities(params),
  })

export const useProgramOpportunities = (programs?: ProgramOpportunitiesRequest[]) =>
  useQueries({
    queries:
      programs?.map(params => ({
        queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES, params],
        queryFn: async () =>
          GrowerProgramsAPI.opportunities(params).then(r => ({ ...r, ...params })),
      })) || [],
  })
