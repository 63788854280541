import {
  SupplyShedReportBase,
  SupplyShedTillage,
  SupplyShedCoverCrop,
  SupplyShedCashCrop,
  SupplyShedCarbonIntensity,
  SupplyShedReportType,
  SupplyShedFieldsReport,
  SupplyShedParcelsReport,
  CDLLandUse,
  Array2d,
} from '@cibo/core'
import { descend, pluck, prop, propEq, sort, sum, uniq } from 'ramda'
import { Geometry } from 'geojson'

type LandUseRow = { crop: CDLLandUse; acres: number; portion: number }

export interface ISupplyShedReportModel {
  readonly totalAcres: number
  readonly tillableAcres: number
}

export class SupplyShedReportModelBase implements SupplyShedReportBase {
  resourceId: string
  tillage: SupplyShedTillage[]
  coverCrop: SupplyShedCoverCrop[]
  cashCrops: SupplyShedCashCrop[]
  carbonIntensity: SupplyShedCarbonIntensity[]
  type: SupplyShedReportType

  constructor(params: SupplyShedReportBase) {
    this.cashCrops = params.cashCrops
    this.resourceId = params.resourceId
    this.tillage = params.tillage
    this.coverCrop = params.coverCrop
    this.cashCrops = params.cashCrops
    this.carbonIntensity = params.carbonIntensity
    this.type = params.type
  }

  yearCrops(year: number) {
    const yearCrops = this.cashCrops.find(propEq('year', year))
    return yearCrops?.crops
  }

  // @todo use a backend-supplied tillable value
  yearTotalCropAcres(year: number) {
    const yearCrops = this.cashCrops.find(propEq('year', year))

    return yearCrops && sum(Object.values(yearCrops.crops))
  }

  topCrops(year: number, options?: { limit?: number; portion?: number }) {
    const totalCropAcres = this.yearTotalCropAcres(year)

    const yearLandUsage = this.cashCrops.find(propEq('year', year))
    if (!yearLandUsage || !totalCropAcres) {
      return
    }

    const crops = Object.keys(yearLandUsage.crops)

    const cropRows = crops.reduce((rows: Array<LandUseRow>, key: string) => {
      const crop = key as CDLLandUse

      return [
        ...rows,
        {
          crop,
          acres: yearLandUsage.crops[crop],
          portion: yearLandUsage.crops[crop] / totalCropAcres,
        },
      ]
    }, [])

    const sorted = sort(descend(prop('acres')), cropRows)

    if (options?.portion) {
      let portion = 0
      let cutoff = 0

      while (portion < options.portion) {
        portion += sorted[cutoff].acres / totalCropAcres
        cutoff++
      }

      sorted.splice(cutoff, sorted.length)
    }

    if (options?.limit) {
      return sorted.slice(0, options.limit)
    }

    return sorted
  }

  get hucLevel() {
    const [prefix, featureDigits] = this.resourceId.split(':')

    if (prefix === 'huc') {
      return featureDigits.length
    }
  }

  get associatedFeatureId() {
    const featureDigits = this.resourceId.split(':')[1]

    return parseInt(featureDigits)
  }

  get reportYears() {
    const dataYears = pluck('year', [...this.cashCrops, ...this.coverCrop, ...this.carbonIntensity])

    const uniqueYears = uniq(dataYears)

    return uniqueYears.sort()
  }

  get mostRecentYear() {
    const yearsWithCrops = this.cashCrops.filter(
      ({ crops }) => crops && Object.keys(crops).length > 0
    )
    const years = pluck('year')(yearsWithCrops)

    if (!years || years.length === 0) {
      return undefined
    }

    return Math.max.apply(null, years)
  }

  yearIntensities(year: number) {
    return this.carbonIntensity.filter(propEq('year', year))
  }
}

export class SupplyShedParcelsReportModel
  extends SupplyShedReportModelBase
  implements ISupplyShedReportModel
{
  boundingBox: Array2d
  label: string
  parcelAcres: number
  parcelCount: number
  parcelTillableAcres: number
  geometry: Geometry

  constructor(params: SupplyShedParcelsReport) {
    super(params)

    this.boundingBox = params.boundingBox
    this.label = params.label
    this.parcelAcres = params.parcelAcres
    this.parcelCount = params.parcelCount
    this.parcelTillableAcres = params.parcelTillableAcres
    this.geometry = params.geometry
  }

  get totalAcres() {
    return this.parcelAcres
  }
  get tillableAcres() {
    return this.parcelTillableAcres
  }
}

export class SupplyShedFieldsReportModel
  extends SupplyShedReportModelBase
  implements ISupplyShedReportModel
{
  fieldAcres: number
  fieldCount: number
  fieldTillableAcres: number
  geometry: Array<{ resourceId: string; location: [number, number] }>

  constructor(params: SupplyShedFieldsReport) {
    super(params)

    this.fieldAcres = params.fieldAcres
    this.fieldCount = params.fieldCount
    this.fieldTillableAcres = params.fieldTillableAcres
    this.geometry = params.geometry
  }

  get totalAcres() {
    return this.fieldAcres
  }
  get tillableAcres() {
    return this.fieldTillableAcres
  }
}

export type SupplyShedReportModel = SupplyShedParcelsReportModel | SupplyShedFieldsReportModel
