import {
  Array2d,
  SupplyShedAPI,
  SupplyShedFieldsReport,
  SupplyShedParcelsReport,
  SupplyShedReportRequest,
} from '@cibo/core'
import { useQuery } from '@tanstack/react-query'
import { partition, propEq } from 'ramda'
import {
  SupplyShedFieldsReportModel,
  SupplyShedParcelsReportModel,
  SupplyShedReportModel,
} from '../SupplyShedReportModel'
import { useSupplyShedFilters } from '../hooks'
import { QUERY_KEY } from './queryKey'

export type SupplyShedComparisonReport = {
  shedReports: SupplyShedParcelsReportModel[]
  fieldsReport: SupplyShedFieldsReportModel
  reports: SupplyShedReportModel[]
  comparisonBox: Array2d
}

export const useSupplyShedComparisonReport = (
  resourceIds?: string[],
  overrideFilters?: Omit<SupplyShedReportRequest, 'resourceIds'>
) => {
  const { cashCrops, coverCrop, tillageTypes } = useSupplyShedFilters()

  const contextFilters = { coverCrop, crop: cashCrops, tillage: tillageTypes }

  const query: SupplyShedReportRequest | undefined = !!resourceIds?.length
    ? { resourceIds, ...(overrideFilters || contextFilters) }
    : undefined

  return useQuery<SupplyShedComparisonReport | undefined, Error>({
    queryKey: [QUERY_KEY.COMPARISON, JSON.stringify(query)],
    queryFn: async () => {
      if (query) {
        const items = await SupplyShedAPI.report(query)
        const [fieldsReportData, shedReportData] = partition(propEq('type', 'Fields'), items)

        const shedReports = shedReportData.map(
          report => new SupplyShedParcelsReportModel(report as SupplyShedParcelsReport)
        )
        const fieldsReport =
          fieldsReportData[0] &&
          new SupplyShedFieldsReportModel(fieldsReportData[0] as SupplyShedFieldsReport)

        const reportBoxes = shedReports.map(({ boundingBox }) => boundingBox)

        const comparisonBox = reportBoxes.reduce((report, acc) =>
          !acc
            ? report
            : [
                [Math.min(acc[0][0], report[0][0]), Math.min(acc[0][1], report[0][1])],
                [Math.max(acc[1][0], report[1][0]), Math.max(acc[1][1], report[1][1])],
              ]
        )

        return {
          shedReports,
          fieldsReport,
          reports: [...shedReports, fieldsReport],
          comparisonBox,
        }
      }
    },

    enabled: !!query,
  })
}
