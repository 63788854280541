export const filterEmptyStrings = (item: object) => {
  const filtered: any = {}

  if (!item) return item

  for (const [key, value] of Object.entries(item)) {
    switch (typeof value) {
      case 'object':
        if (value instanceof Date) {
          filtered[key] = value
          break
        }
        const obj = filterEmptyStrings(value)
        if (JSON.stringify(obj) !== '{}') {
          filtered[key] = obj
        }
        break

      case 'string':
        if (value !== '') {
          filtered[key] = value
        }
        break

      default:
        filtered[key] = value
    }
  }
  return filtered
}
