import { MpxUser } from './MpxUser'
import { AxiosResponse } from 'axios'
export const MP_AUTH_CHECK_PENDING = 'MP_AUTH_CHECK_PENDING'
export const MP_AUTH_CHECK_SUCCESS = 'MP_AUTH_CHECK_SUCCESS'
export const MP_AUTH_CHECK_ERROR = 'MP_AUTH_CHECK_ERROR'

export interface AuthCheckResult extends Partial<MpxUser> {}

export interface AuthCheckError {
  activity: string
  duration: number
  httpCode: number
  message: string
  source: string
}

interface AuthCheckPendingAction {
  type: typeof MP_AUTH_CHECK_PENDING
}

interface AuthCheckSuccessAction {
  type: typeof MP_AUTH_CHECK_SUCCESS
  results: AxiosResponse<AuthCheckResult>
  message?: string
}

interface AuthCheckErrorAction {
  type: typeof MP_AUTH_CHECK_ERROR
  error: AuthCheckError
}

export type AuthCheckActionTypes =
  | AuthCheckPendingAction
  | AuthCheckSuccessAction
  | AuthCheckErrorAction
