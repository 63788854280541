import { FormControl, FormLabel } from '@mui/material'
import { Field as FormikField } from 'formik'
import { TextField } from 'formik-mui'
import { useTranslation } from 'react-i18next'
import { DetailEditorProps } from '../types'

export const XtremeAgCodeEditor = ({ name = 'xtremeAgCode' }: DetailEditorProps) => {
  const { t } = useTranslation('@cibo/landmanager/XtremeAgCode')

  return (
    <FormControl required>
      <FormLabel htmlFor={`${name}.xtremeAgCode`}>{t('enterXtremeAgCode')}</FormLabel>
      <FormikField
        component={TextField}
        name={`${name}.xtremeAgCode`}
        inputProps={{ 'data-testid': `${name}.xtremeAgCode` }}
      />
    </FormControl>
  )
}
