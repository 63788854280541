import { FieldsAPI } from '@cibo/core'
import { QUERY_KEY as LANDMANAGER_QUERY_KEY } from '@cibo/landmanager'
import { logRequestError } from '@cibo/ui'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { PROGRAMS_QUERY_KEY } from './queryKey'

export const useUnenrollField = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: ({ fieldId, programId }: { fieldId: string; programId: string }) =>
      FieldsAPI.unenrollField(fieldId, programId),

    onSuccess: (response, { fieldId, programId }) => {
      queryClient.invalidateQueries({ queryKey: [LANDMANAGER_QUERY_KEY.FIELDS, fieldId] })
      queryClient.invalidateQueries({ queryKey: [LANDMANAGER_QUERY_KEY.FIELDS_PAGINATED] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.PROGRAMS, programId] })
      queryClient.invalidateQueries({ queryKey: [PROGRAMS_QUERY_KEY.OPPORTUNITIES] })
    },

    onError: (error: any, variables, context) => {
      logRequestError(error, { query: 'useUnenrollField', variables, context })
      return error
    },
  })
}
