import { FieldModel, PlatformUser, Termset } from '@cibo/core'
import { GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro'
import { FieldColumnProps } from './types'

export const fieldFarmColumn = ({ t, ...columnProps }: FieldColumnProps) => ({
  field: 'farm',
  headerName: t('farm', { ns: '@cibo/landmanager' }),
  flex: 1,
  minWidth: 150,
  // API doesn't support sorting at present
  sortable: false,
  valueGetter: (params: GridValueGetterParams<any, FieldModel>) => params.row.farm,
  valueFormatter: ({ value }: GridValueFormatterParams<Termset<PlatformUser>>) => value?.item.name,
  ...columnProps,
})
