import * as Yup from 'yup'

Yup.addMethod(Yup.array, 'tuple', function (schema) {
  // @ts-ignore
  if (!this.isType(schema)) Yup.ValidationError()
  return Yup.object({
    tuple: Yup.array().min(schema.length).max(schema.length),
    ...Object.fromEntries(Object.entries(schema)),
  }).transform((value, originalValue) => {
    // @ts-ignore
    if (!this.isType(originalValue)) Yup.ValidationError()
    return {
      tuple: originalValue,
      ...Object.fromEntries(Object.entries(originalValue)),
    }
  })
})

export default Yup
