import { useTheme } from '@mui/material'
import { useContext } from 'react'
import { Layer, Source } from 'react-map-gl/dist/es5'

import { Config } from '../../../types'
import { MapTheme } from '../const'
import { LayerContext } from './LayerContext'

export const TownshipLayer = () => {
  const theme = useTheme<MapTheme>()
  const light = theme.palette.mode === 'light'
  const maptilesServiceUrl = Config.get('MAPTILES_SERVICE_URL')
  const { showTownships } = useContext(LayerContext)

  return (
    <Source
      id="townships"
      type="vector"
      tiles={[`${maptilesServiceUrl}geopolitical/{z}/{x}/{y}.pbf`]}
      minzoom={8}
      maxzoom={28}
    >
      <Layer
        id="township-shapes"
        type="line"
        source="geopolitical"
        source-layer="township-shapes"
        layout={{
          visibility: showTownships ? 'visible' : 'none',
        }}
        paint={{
          'line-color': theme.palette.townshipLine,
          'line-width': ['interpolate', ['exponential', 1.51], ['zoom'], 8, 0.9, 16, 4],
          'line-opacity': ['interpolate', ['linear'], ['zoom'], 8.75, 0, 9, 1, 12, 1, 13, 0],
        }}
      />
      <Layer
        id="township-label"
        type="symbol"
        source="townships"
        source-layer="township-centers"
        layout={{
          visibility: showTownships ? 'visible' : 'none',
          'text-field': ['to-string', ['get', 'label']],
          'text-font': ['Nunito Sans SemiBold', 'Arial Unicode MS Regular'],
          'text-size': [
            'interpolate',
            ['cubic-bezier', 0.2, 0, 0.9, 1],
            ['zoom'],
            3,
            12,
            5,
            14,
            13,
            12,
          ],
          'text-letter-spacing': 0.05,
        }}
        paint={{
          'text-color': theme.palette.townshipTextColor,
          'text-opacity': ['interpolate', ['linear'], ['zoom'], 8.75, 0, 9, 1, 12, 1, 13, 0],
          'text-halo-color': theme.palette.townshipTextHalo,
          'text-halo-blur': light ? 2 : 5,
          'text-halo-width': light ? 1 : 3,
        }}
      />
    </Source>
  )
}
