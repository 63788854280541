import { eventValidationSchema } from '../EventDetail'
import { ITraitFeatureByIdYear } from '../types'
import { findInitialValuesDetail } from '../utils'
import { FertilizerCell } from './FertilizerCell'
import { FertilizerEditor } from './FertilizerEditor'
import { FertilizerSimpleCell } from './FertilizerSimpleCell'
import { FertilizerEventValidationSchema } from './schema'

const TRAIT_ID = 'fertilizer'

export const Fertilizer: ITraitFeatureByIdYear = {
  traitId: TRAIT_ID,
  cellDisplay: FertilizerCell,
  SimpleCellView: FertilizerSimpleCell,
  editor: FertilizerEditor,
  initialValues: request => {
    const detail = findInitialValuesDetail(TRAIT_ID)(request)
    return detail?.input || {}
  },
  ns: '@cibo/landmanager/FertilizerEditor',
  validationSchema: eventValidationSchema(props => FertilizerEventValidationSchema(props)),
}
