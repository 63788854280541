import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useCallback } from 'react'

export const AddCellValueButton = (props: any) => {
  const { id, field } = props
  const apiRef = useGridApiContext()

  const handleClick = useCallback(() => {
    apiRef.current.startCellEditMode({ id, field })
  }, [apiRef.current, id, field])

  return (
    <IconButton onClick={handleClick} data-testid={props['data-testid'] || 'add-detail-button'}>
      <AddIcon />
    </IconButton>
  )
}
