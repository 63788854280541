import { Markdown } from '@cibo/ui'
import { Divider, Stack, Typography } from '@mui/material'
import { groupBy, prop } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

interface ParsingError {
  error: string
  traitId: string
  year: number
  sourceData?: any
  input?: any
  errorInfo: {
    traitId: string
    reason: string
    error: string
    year: number
  }
}

export const AgronomicDataCSVErrors = ({ errors }: { errors: ParsingError[] }) => {
  const { t } = useTranslation('traits')
  const groupedTraits = groupBy(prop('traitId'), errors || [])

  const groupedErrorsByTrait = useMemo(() => {
    const groups: Record<string, Record<string, ParsingError[]>> = {}
    Object.keys(groupedTraits).forEach((group: string) => {
      groups[group] = groupBy(
        a => (!!a.errorInfo.error ? a.errorInfo.error : a.error),
        groupedTraits[group] || []
      )
    })
    return groups
  }, [errors])

  return (
    <Stack spacing={2} divider={<Divider />}>
      {Object.keys(groupedTraits).map((trait: string) => {
        const errorGroups = groupedErrorsByTrait[trait]
        return (
          <>
            <Typography variant="h6" key={trait}>
              {t(trait)}
            </Typography>
            <Markdown>
              {Object.keys(errorGroups)
                .map((error: string) => {
                  const years = errorGroups[error].map(e => `**${e.year}**`)
                  return `- ${years.sort().join(', ')} — ${error}
`
                })
                .join('')}
            </Markdown>
          </>
        )
      })}
    </Stack>
  )
}
