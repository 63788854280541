import { useQuery } from '@tanstack/react-query'

import { DisplayApi, DisplayDataset, ONE_WEEK } from '@cibo/core'

const QUERY_KEY = 'display'

export const useDisplay = (dataset: DisplayDataset, resourceId?: string) => {
  return useQuery({
    queryKey: [QUERY_KEY, dataset, resourceId],
    queryFn: () => resourceId && DisplayApi.forResource(dataset, resourceId),

    enabled: !!resourceId,
    gcTime: ONE_WEEK,
  })
}
