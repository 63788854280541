import { Dispatch, PropsWithChildren, SetStateAction, createContext, useState } from 'react'
//@ts-ignore soon: https://deck.gl/docs/get-started/using-with-typescript
import { TranslateMode, ViewMode } from '@nebula.gl/edit-modes'
import {
  FeatureCollection,
  bbox,
  bboxPolygon,
  center,
  centerOfMass,
  coordAll,
  difference,
  distance,
  featureCollection,
  midpoint,
  transformTranslate,
} from '@turf/turf'
import { EditorMode } from '../GeometryEditorTypes'
import { clampViewState } from '../clampViewState'
import { fitBoundsOfFeature } from '../geometryEditorUtils'
import { useGeometryEditorStateContext } from './GeometryEditorStateContext'

export type GeometryEditorToolsContextValue = {
  copyBuffer?: FeatureCollection
  setCopyBuffer: Dispatch<SetStateAction<FeatureCollection | undefined>>
  nextMode?: EditorMode
  setNextMode: Dispatch<SetStateAction<EditorMode | undefined>>
  mode: EditorMode
  setMode: Dispatch<SetStateAction<EditorMode>>
  marqueeFeatures?: FeatureCollection
  setMarqueeFeatures: Dispatch<SetStateAction<FeatureCollection>>
  handleCopy: () => void
  handlePaste: () => void
  handleImmediateDifference: (marquee: FeatureCollection) => void
  handleDifference: () => void
  handleDifferenceSelected: () => void
  handleDeleteSelected: () => void
  handleRecenterMap: () => void
  handleUpdateMode: (newMode: typeof ViewMode, modeConfig: any) => void
  handleZoomIn: () => void
  handleZoomOut: () => void
  handleViewStateChange: (vs: any) => void
}

export const GeometryEditorToolsContext = createContext<GeometryEditorToolsContextValue>({
  mode: {
    editLayer: {
      mode: ViewMode,
      modeConfig: {},
    },
    marqueeLayer: {
      mode: ViewMode,
      modeConfig: {},
    },
    isMarquee: false,
    isTransform: false,
  },
  setMode: a => console.log('setMode unset'),
  setCopyBuffer: a => console.log('setCopyBuffer unset'),
  setNextMode: a => console.log('setNextMode unset'),
  setMarqueeFeatures: a => console.log('setMarqueeFeatures unset'),
  handleCopy: () => console.log('handleCopy unset'),
  handlePaste: () => console.log('handlePaste unset'),
  handleImmediateDifference: a => console.log('handleImmediateDifference unset'),
  handleDifference: () => console.log('handleDifference unset'),
  handleDifferenceSelected: () => console.log('handleDifferenceSelected unset'),
  handleDeleteSelected: () => console.log('handleDeleteSelected unset'),
  handleRecenterMap: () => console.log('handleRecenterMap unset'),
  handleUpdateMode: (newMode: typeof ViewMode, modeConfig: any) =>
    console.log('handleUpdateMode unset'),
  handleZoomIn: () => console.log('handleZoomIn unset'),
  handleZoomOut: () => console.log('handleZoomOut unset'),
  handleViewStateChange: (vs: any) => console.log('handleViewStateChange unset'),
})
GeometryEditorToolsContext.displayName = 'GeometryEditorToolsContext'

export const GeometryEditorToolsProvider = ({ children }: PropsWithChildren<{}>) => {
  const {
    container,
    editState,
    setEditState,
    baseFeatureCollection: initialFeatures,
    setPreviewFeatures,
    editMapViewState,
    setEditMapViewState,
  } = useGeometryEditorStateContext()
  const [copyBuffer, setCopyBuffer] = useState<FeatureCollection>()
  const [mode, setMode] = useState<EditorMode>({
    editLayer: {
      mode: ViewMode,
      modeConfig: {},
    },
    marqueeLayer: {
      mode: ViewMode,
      modeConfig: {},
    },
    isMarquee: false,
    isTransform: !!initialFeatures,
  })
  const [nextMode, setNextMode] = useState<EditorMode>()
  const [marqueeFeatures, setMarqueeFeatures] = useState<FeatureCollection>(featureCollection([]))

  const handleCopy = () => {
    if (mode.isTransform && editState.editLayer.selectedIndexes.length > 0) {
      setCopyBuffer(
        featureCollection(
          editState.editLayer.features.features.filter((f, index) =>
            editState.editLayer.selectedIndexes.includes(index)
          )
        )
      )
    }
  }
  const handlePaste = () => {
    if (copyBuffer) {
      // translate the copied items NE by half size of the bounding box
      const b = bboxPolygon(bbox(copyBuffer))
      const c = center(copyBuffer)
      const m = midpoint(c, coordAll(b)[1])

      const features = featureCollection([
        ...editState.editLayer.features.features,
        ...copyBuffer.features.map(feature => {
          return transformTranslate(feature, distance(c, m), 45)
        }),
      ])
      setEditState({
        ...editState,
        editLayer: {
          ...editState.editLayer,
          features,
          // select all the pasted items
          selectedIndexes: copyBuffer.features.map(
            (f, index) => index + editState.editLayer.features.features.length
          ),
        },
        undoStack: [editState.editLayer, ...editState.undoStack],
        redoStack: [],
      })
      setPreviewFeatures(features)
    }
  }

  const handleImmediateDifference = (marquee: FeatureCollection) => {
    if (marquee.features.length > 0) {
      const selectedIndexes = editState.editLayer.selectedIndexes
      const features = featureCollection(
        //@ts-ignore
        editState.editLayer.features.features
          .map(feature =>
            //@ts-ignore
            difference(feature, marquee.features[0])
          )
          .filter((feature, index) => {
            if (!feature?.geometry) {
              //remove selected indexes if we are wholesale removing the feature
              const deleteIndex = selectedIndexes.findIndex(e => e === index)
              if (deleteIndex >= 0) {
                selectedIndexes.splice(deleteIndex, 1)
              }
              return false
            }
            return true
          })
      )
      setEditState({
        ...editState,
        editLayer: {
          ...editState.editLayer,
          features,
          selectedIndexes,
        },
        undoStack: [editState.editLayer, ...editState.undoStack],
        redoStack: [],
      })
      setPreviewFeatures(features)
      setMarqueeFeatures(featureCollection([]))
    }
  }

  const handleDifference = () => {
    if (marqueeFeatures.features.length > 0) {
      const selectedIndexes = editState.editLayer.selectedIndexes
      const features = featureCollection(
        //@ts-ignore
        editState.editLayer.features.features
          .map(feature =>
            //@ts-ignore
            difference(feature, marqueeFeatures.features[0])
          )
          .filter((feature, index) => {
            if (!feature?.geometry) {
              //remove selected indexes if we are wholesale removing the feature
              const deleteIndex = selectedIndexes.findIndex(e => e === index)
              if (deleteIndex >= 0) {
                selectedIndexes.splice(deleteIndex, 1)
              }
              return false
            }
            return true
          })
      )
      setEditState({
        ...editState,
        editLayer: {
          ...editState.editLayer,
          features,
          selectedIndexes,
        },
        undoStack: [editState.editLayer, ...editState.undoStack],
        redoStack: [],
      })
      setPreviewFeatures(features)
    }
  }

  const handleDifferenceSelected = () => {
    if (editState.editLayer.selectedIndexes.length > 1) {
      const selectedIndexes = editState.editLayer.selectedIndexes
      const sourceIndex = selectedIndexes[0]
      const source = editState.editLayer.features.features[sourceIndex]
      const features = featureCollection(
        //@ts-ignore
        editState.editLayer.features.features
          .map((feature, index) => {
            if (selectedIndexes.includes(index)) {
              //@ts-ignore
              return difference(feature, source)
            }
            return feature
          })
          .filter((feature: any, index: number) => {
            if (index === sourceIndex) {
              selectedIndexes.splice(0, 1)
              return false
            }
            if (!feature?.geometry) {
              //remove selected indexes if we are wholesale removing the feature
              const deleteIndex = selectedIndexes.findIndex(e => e === index)
              if (deleteIndex >= 0) {
                selectedIndexes.splice(deleteIndex, 1)
              }
              return false
            }
            return true
          })
      )
      setEditState({
        ...editState,
        editLayer: {
          ...editState.editLayer,
          features,
          selectedIndexes,
        },
        undoStack: [editState.editLayer, ...editState.undoStack],
        redoStack: [],
      })
      setPreviewFeatures(features)
    }
  }

  const handleDeleteSelected = () => {
    const features = featureCollection(
      editState.editLayer.features.features?.filter(
        (v, i) => !editState.editLayer.selectedIndexes.includes(i)
      )
    )

    setEditState({
      ...editState,
      editLayer: {
        features,
        selectedIndexes: [],
      },
      undoStack: [editState.editLayer, ...editState.undoStack],
      redoStack: [],
    })
    setPreviewFeatures(features)
  }

  const handleRecenterMap = () => {
    if (!container.current) {
      return
    }

    if (editState.editLayer.features.features.length > 0) {
      const newCenter = centerOfMass(editState.editLayer.features).geometry?.coordinates
      if (newCenter) {
        // @ts-ignore This partial ViewState works
        setEditMapViewState({
          ...fitBoundsOfFeature({
            features: editState.editLayer.features,
            rect: container.current.getBoundingClientRect(),
            viewState: editMapViewState,
          }),
        })
      }
    }
  }

  const handleUpdateMode = (newMode: typeof ViewMode, modeConfig: any) => {
    if (modeConfig?.marquee) {
      let nextMarqueeMode = newMode
      if (!mode.isMarquee && marqueeFeatures.features.length > 0) {
        nextMarqueeMode = TranslateMode
        setNextMode({
          editLayer: {
            mode: ViewMode,
            modeConfig: {},
          },
          marqueeLayer: {
            mode: newMode,
            modeConfig,
          },
          isMarquee: true,
          isTransform: false,
        })
      }
      setMode({
        editLayer: {
          mode: ViewMode,
          modeConfig: {},
        },
        marqueeLayer: {
          mode: nextMarqueeMode,
          modeConfig,
        },
        isMarquee: true,
        isTransform: false,
      })
    } else {
      if (mode.isMarquee) {
        setPreviewFeatures(editState.editLayer.features)
      }
      setMode({
        editLayer: {
          mode: newMode,
          modeConfig,
        },
        marqueeLayer: {
          mode: ViewMode,
          modeConfig: {},
        },
        isMarquee: false,
        isTransform: !!modeConfig?.isTransform,
      })
      setEditState({
        ...editState,
      })
    }
  }

  const handleZoomIn = () => {
    if (!!editMapViewState) {
      setEditMapViewState(
        clampViewState({ viewState: { ...editMapViewState, zoom: editMapViewState.zoom * 1.1 } })
      )
    }
  }
  const handleZoomOut = () => {
    if (!!editMapViewState) {
      setEditMapViewState(
        clampViewState({ viewState: { ...editMapViewState, zoom: editMapViewState.zoom * 0.9 } })
      )
    }
  }

  const handleViewStateChange = (newViewState: any) => {
    setEditMapViewState(clampViewState({ viewState: newViewState.viewState }))
  }

  return (
    <GeometryEditorToolsContext.Provider
      value={{
        copyBuffer,
        setCopyBuffer,
        nextMode,
        setNextMode,
        marqueeFeatures,
        mode,
        setMode,
        setMarqueeFeatures,
        handleCopy,
        handlePaste,
        handleImmediateDifference,
        handleDifference,
        handleDifferenceSelected,
        handleDeleteSelected,
        handleRecenterMap,
        handleUpdateMode,
        handleZoomIn,
        handleZoomOut,
        handleViewStateChange,
      }}
    >
      {children}
    </GeometryEditorToolsContext.Provider>
  )
}
