import { DisplayDataset, FieldModel, ZONE_FILL } from '@cibo/core'
import { ResponsiveDialog, SoilLegend, Swatch, useDisplay } from '@cibo/ui'
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  Paper,
  Skeleton,
  Stack,
  Typography,
  styled,
  useTheme,
} from '@mui/material'
import { ReactNode, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { LandReportMapDataLayer } from '../LandReportViewProvider'

const StyledPaper = styled(Paper)(({ theme }) => ({
  '&.MuiPaper-root': {
    backgroundColor: theme.palette.mode === 'dark' ? 'rgba(0,0,0,0.7)' : 'rgba(255,255,255,0.7)',
    backdropFilter: 'blur(4px)',
    padding: theme.spacing(1),
  },
}))

const DatasetLegend = ({
  dataset,
  resourceId,
}: {
  dataset: DisplayDataset
  resourceId: string
}) => {
  const display = useDisplay(dataset, resourceId)
  let legend: ReactNode
  const [soilOpen, setSoilOpen] = useState(false)
  const { t } = useTranslation('@cibo/landmanager/LandReport/Map/Legend')

  switch (dataset) {
    case 'elevation':
      legend = (
        <Stack>
          <Typography variant="overline">{t('title', { context: dataset })}</Typography>
          {display.isPending ? (
            <Stack>
              <Skeleton variant="text" width={60} />
              <Skeleton variant="text" width={60} />
            </Stack>
          ) : (
            <>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Swatch color={display.data?.geojson?.features[0].properties['fill-color']} />
                <Typography variant="caption">
                  {t('low', {
                    value: display.data?.geojson?.features[0]?.properties.elevationMeters,
                    context: dataset,
                  })}
                </Typography>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={1}>
                <Swatch
                  color={
                    display.data?.geojson?.features[display.data?.geojson?.features.length - 1]
                      .properties['fill-color']
                  }
                />
                <Typography variant="caption">
                  {t('high', {
                    value:
                      display.data?.geojson?.features[display.data?.geojson?.features.length - 1]
                        ?.properties.elevationMeters,
                    context: dataset,
                  })}
                </Typography>
              </Stack>
            </>
          )}
        </Stack>
      )
      break
    case 'soil':
      legend = (
        <>
          <Stack>
            <Typography variant="overline">{t('title', { context: dataset })}</Typography>
            {display.isPending ? (
              <Stack spacing={1}>
                <Stack>
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={150} />
                  <Skeleton variant="text" width={120} />
                </Stack>
                <Box>
                  <Button variant="outlined" size="small" disabled>
                    {t('moreInfo')}
                  </Button>
                </Box>
              </Stack>
            ) : (
              <Stack spacing={1}>
                <Stack>
                  {/* @todo improve typing on useDisplay */}
                  {/* @ts-ignore useDisplay returns any on data */}
                  {display.data?.geojson?.features.map(({ id, properties }) => (
                    <Stack key={id} direction="row" alignItems="center" spacing={1}>
                      <Swatch color={properties['fill-color']} />
                      <Typography variant="caption">{properties.muname}</Typography>
                    </Stack>
                  ))}
                </Stack>
                <Box>
                  <Button variant="outlined" size="small" onClick={() => setSoilOpen(true)}>
                    {t('moreInfo')}
                  </Button>
                </Box>
              </Stack>
            )}
          </Stack>
          <ResponsiveDialog
            open={soilOpen}
            onClose={() => setSoilOpen(false)}
            maxWidth="md"
            fullWidth
          >
            <DialogContent>
              <SoilLegend featureCollection={display.data?.geojson} />
            </DialogContent>
            <DialogActions>
              <Button variant="contained" onClick={() => setSoilOpen(false)}>
                {t('close')}
              </Button>
            </DialogActions>
          </ResponsiveDialog>
        </>
      )
      break
    case 'stability':
      legend = (
        <Stack>
          <Typography variant="overline">{t('title', { context: dataset })}</Typography>
          {Object.values(ZONE_FILL)
            .reverse()
            .map(({ color, opacity, key }) => (
              <Stack direction="row" spacing={1}>
                <Swatch color={color} opacity={opacity} size={16} />
                <Typography variant="caption">{t(key)}</Typography>
              </Stack>
            ))}
        </Stack>
      )
      break
    default:
      return null
  }

  return <StyledPaper>{legend}</StyledPaper>
}

export const LandReportMapDataLayerLegend = ({
  dataLayer,
  field,
}: {
  dataLayer: LandReportMapDataLayer
  field: FieldModel
}) => {
  const { palette } = useTheme()
  const { t } = useTranslation('@cibo/landmanager/LandReport/Map/Legend')
  switch (dataLayer) {
    case 'acres':
      return (
        <StyledPaper>
          <Stack direction="row" spacing={1} alignItems="center">
            <Swatch color={palette.secondary.main} />
            <Typography variant="caption">{t('tillableAcres')}</Typography>
          </Stack>
        </StyledPaper>
      )
    case 'elevation':
    case 'stability':
    case 'soil':
      return <DatasetLegend dataset={dataLayer as DisplayDataset} resourceId={field.resourceId} />
    default:
      return null
  }
}
