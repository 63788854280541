// import { MAINLAND_US_BOUNDS } from '@cibo/ui'
import { LngLatBoundsLike } from 'mapbox-gl'
import { equals } from 'ramda'
import { PropsWithChildren, createContext, useCallback, useContext, useState } from 'react'
import { ViewState } from 'react-map-gl'

export const US_VIEWPORT = {
  zoom: 4,
  latitude: 40.12941536221467,
  longitude: -95.890216,
  padding: {
    top: 20,
    bottom: 20,
    left: 20,
    right: 20,
  },
  bearing: 0,
  pitch: 0,
}

// DO NOT EXPORT: this should only be BoundsLike on init otherwise will be ViewState.
type BoundsLike = { bounds?: LngLatBoundsLike }

export type MapGLViewport = ViewState

export type ViewportContextValue = {
  viewport: ViewState
  setViewport: (viewport: ViewState) => void
}

export const ViewportContext = createContext<ViewportContextValue>({
  viewport: US_VIEWPORT,
  setViewport: () => console.log('setViewport unset'),
})
ViewportContext.displayName = 'ViewportContext'

export const ViewportContextProvider = ({
  children,
  initialViewport,
}: PropsWithChildren<{
  // NOTE: this should only be BoundsLike on init otherwise will be ViewState
  initialViewport?: MapGLViewport
}>) => {
  const [viewport, setViewport] = useState<BoundsLike | ViewState>(initialViewport || US_VIEWPORT)

  const setViewportWithDupCheck = useCallback(
    (newViewport?: ViewState) => {
      if (!!newViewport && !equals(newViewport, viewport)) {
        setViewport(newViewport)
      }
      return
    },
    [viewport]
  )

  return (
    <ViewportContext.Provider
      value={{
        // @ts-ignore this *may* be BoundsLike on init
        viewport,
        setViewport: setViewportWithDupCheck,
      }}
    >
      {children}
    </ViewportContext.Provider>
  )
}

export const useViewportContext = () => useContext(ViewportContext)
