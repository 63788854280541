import { US_STATES } from '@cibo/core'
import { Autocomplete, FormControl, FormLabel, TextField } from '@mui/material'
import { useField } from 'formik'
import { equals } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

export const USStateFilter = () => {
  const { t } = useTranslation('@cibo/ui/USStateFilter')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, { value: fipsValue = [] }, { setValue: setFipsValue }] = useField('fips')

  // USStateFilter uses a local state to render the US states from FIPS values, which can include
  // state and county values.
  const [fips, setFips] = useState<typeof US_STATES>(
    US_STATES.filter(state => fipsValue.includes(state.fips))
  )

  // if the state values in the form value and local state aren't equal, set them as equal
  useEffect(() => {
    if (
      !equals(
        US_STATES.filter(state => fipsValue.includes(state.fips)),
        fips
      )
    ) {
      fipsValue.length > 0
        ? setFips(US_STATES.filter(state => fipsValue.includes(state.fips)))
        : setFips([])
    }
  }, [fipsValue])

  // Displays only state FIPS from the FIPS list which includes states and counties
  const handleChangeFips = (event: any, values: typeof US_STATES) => {
    setFips(values)
    const newFips = [
      ...values.map(({ fips }) => fips),
      ...fipsValue.filter((f: string) => f.length !== 2),
    ].filter(v => !!v)
    setFipsValue(newFips.length === 0 ? undefined : newFips)
  }

  return (
    <FormControl>
      <FormLabel>{t('state')}</FormLabel>
      <Autocomplete
        multiple
        limitTags={2}
        options={US_STATES}
        getOptionLabel={option => option.label}
        renderInput={params => (
          <TextField {...params} placeholder={fips.length === 0 ? t('any') : ''} />
        )}
        onChange={handleChangeFips}
        size="small"
        value={fips}
        fullWidth
        data-testid="stateDropDown"
      />
    </FormControl>
  )
}
