export const stripedLinearGradient = (
  colors: Array<string>,
  deg: number,
  startPercentage: number
) => {
  if (!colors.length) return ''
  if (colors.length === 1) return colors[0]

  return `linear-gradient(${deg}deg, ${colors
    .map((c, index) => {
      const stop = startPercentage + index * ((100 - startPercentage) / (colors.length - 1))
      return index < colors.length - 1 ? `${c} ${stop}%, ${colors[index + 1]} ${stop}%` : undefined
    })
    .filter(c => !!c)
    .join(', ')})`
}
