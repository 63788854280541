import { useTranslation } from 'react-i18next'

import { RDConversionDate } from '@cibo/core'
import { Typography } from '@mui/material'
import { TraitCellDisplayProps } from '../types'

export const NativeConversionDateCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDConversionDate>) => {
  const { t } = useTranslation('@cibo/landmanager/NativeConversionDateEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  const { conversionDate, moreThanTenYearsAgo } = detail.result

  return (
    <Typography>
      {conversionDate
        ? t('conversionDate', { date: new Date(conversionDate) })
        : moreThanTenYearsAgo
        ? t('greaterThanTenYearsAgo')
        : t('lessThanTenYearsAgo')}
    </Typography>
  )
}
