import { Button, Dialog, LinearProgress, Stack, Typography } from '@mui/material'
import dayjs from 'dayjs'
import duration from 'dayjs/plugin/duration'
import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router-dom'

import { useInterval } from '@cibo/ui'
import { useAuth } from '../../hooks'
import { AUTH_ROUTES } from '../../pages/consts'

dayjs.extend(duration)

const PROMPT_TIMEOUT = 1000 * 5 * 60
const TIMEOUT = 1000 * 10 * 60

export const IdleTimer = () => {
  const [open, setOpen] = useState(false)
  const [remaining, setRemaining] = useState<number>(0)
  const { logout, isLoggedIn } = useAuth()
  const navigate = useNavigate()
  const { t } = useTranslation('@cibo/profile/components/IdleTimer')

  const onLogout = () => {
    setOpen(false)
    logout()
    navigate(AUTH_ROUTES.LOGIN, { replace: true })
  }

  const onPrompt = () => {
    setRemaining(PROMPT_TIMEOUT)
    setOpen(true)
  }

  const onIdle = () => {
    onLogout()
  }

  const { getRemainingTime, isPrompted, activate, reset } = useIdleTimer({
    onPrompt,
    onIdle,
    timeout: TIMEOUT,
    promptTimeout: PROMPT_TIMEOUT,
    stopOnIdle: true,
  })

  useEffect(() => {
    if (isLoggedIn) {
      setOpen(false)
      reset()
    }
  }, [isLoggedIn])

  const tick = useCallback(() => {
    if (isPrompted()) {
      setRemaining(getRemainingTime())
    }
  }, [getRemainingTime])

  useInterval(tick, open ? 1000 : null)

  if (!isLoggedIn) {
    return <></>
  }

  return (
    <Dialog open={open}>
      <Stack direction="column" p={3} spacing={2} sx={{ width: 450 }}>
        <Typography>
          {t('title', {
            seconds: dayjs.duration(remaining).get('seconds'),
            minutes: dayjs.duration(remaining).get('minutes'),
          })}
        </Typography>

        <LinearProgress
          variant="determinate"
          value={((PROMPT_TIMEOUT - remaining) / PROMPT_TIMEOUT) * 100}
        />

        <Stack direction="row" spacing={2} justifyContent="flex-end">
          <Button variant="outlined" onClick={onLogout}>
            {t('logOut')}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              activate()
              setOpen(false)
            }}
          >
            {t('continue')}
          </Button>
        </Stack>
      </Stack>
    </Dialog>
  )
}
