import { RDEqipFieldsSelection } from '@cibo/core'
import { ResourceDetailFeatureTaskEditorProps, Section } from '@cibo/ui'
import { Stack } from '@mui/material'
import { FieldsSelectionList } from '../../../components/FieldsSelection'
import { PracticeSelectionSummaryTable } from '../../../components/PracticeSelectionConfirmation'

export const FieldsSelectionFeatureTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<RDEqipFieldsSelection>
) => (
  <Stack spacing={2} flex={1}>
    <FieldsSelectionList {...props} />
    <Section>
      <PracticeSelectionSummaryTable {...props} />
    </Section>
  </Stack>
)
