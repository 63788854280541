import {
  PRIORITIZED_DISPLAYABLE_CASH_CROPS,
  PRIORITY_CASH_CROPS,
  RDCashCropInput,
} from '@cibo/core'
import { AutocompleteField } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import InputLabel from '@mui/material/InputLabel'
import { FieldProps, Field as FormikField } from 'formik'
import { useTranslation } from 'react-i18next'
import { DetailEditorPropsByYear } from '../types'

export const CashCropResultsOnlyEditor = ({
  name = 'cashCropEditor',
  year,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/CashCropEditor')
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')

  return (
    <FormikField name={name} id={name}>
      {({
        field: { value: { crop } = {} as RDCashCropInput },
        ...rest
      }: FieldProps<RDCashCropInput>) => {
        return (
          <Stack spacing={2}>
            <Typography>{t('cta', { year })}</Typography>
            <Stack>
              <InputLabel required htmlFor={`${name}`}>
                {t('whatCrop')}
              </InputLabel>
              <AutocompleteField
                name={`${name}`}
                groupBy={c =>
                  PRIORITY_CASH_CROPS && PRIORITY_CASH_CROPS.includes(c) ? t('suggested') : t('all')
                }
                options={PRIORITIZED_DISPLAYABLE_CASH_CROPS}
                getOptionLabel={(option: string) => cropT(option)}
                data-testid={`cashCrop.${year}.crop`}
                selectOnFocus
              />
            </Stack>
          </Stack>
        )
      }}
    </FormikField>
  )
}
