import { RDEqipPracticesIrrigationMethod } from '@cibo/core'

import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../../types'

export const EqipIrrigationMethodCell = ({
  traitId,
  year,
  params,
}: TraitCellDisplayProps<RDEqipPracticesIrrigationMethod>) => {
  const { t } = useTranslation('@cibo/landmanager/EqipIrrigationMethod')
  const detail = params.row.resolveStandingDetail<RDEqipPracticesIrrigationMethod>(traitId, year)

  if (!detail) return <>--</>

  return <>{t(`furrowIrrigation_${detail.result?.furrowIrrigation}`)}</>
}
