/* istanbul ignore file */
import buildUrl from 'build-url'
import {
  CarbonPractice,
  CarbonPracticesResponse,
  CarbonFootprintRequest,
  CarbonFootprintResponse,
  CarbonComparisonRequest,
  CarbonComparisonResponse,
  PreviousCarbonComparisonRequest,
} from '../types/CarbonLabTypes'

import { getMpAgent } from './utils'

const agent = getMpAgent()

const PATHS = {
  COMPARISON: '/impact/carbonComparison',
  FOOTPRINT: '/impact/carbonFootprint',
  PRACTICES: '/impact/practices',
}

export class CarbonLabAPI {
  static async comparison(request: CarbonComparisonRequest) {
    return agent
      .post<CarbonComparisonResponse>(PATHS.COMPARISON, request)
      .then(response => response && (response.data as CarbonComparisonResponse))
  }

  static async previousComparison(
    request: PreviousCarbonComparisonRequest
  ): Promise<CarbonComparisonResponse> {
    return agent
      .get(
        buildUrl(PATHS.COMPARISON, {
          queryParams: request,
        })
      )
      .then(response => response && (response.data as CarbonComparisonResponse))
  }

  static async footprint(request: CarbonFootprintRequest): Promise<CarbonFootprintResponse | void> {
    return agent
      .post(PATHS.FOOTPRINT, request)
      .then(response => response && (response.data as CarbonFootprintResponse))
  }

  static async practices(): Promise<CarbonPractice[]> {
    return agent
      .get<CarbonPracticesResponse>(PATHS.PRACTICES)
      .then(response => response && (response.data?.practices as CarbonPractice[]))
  }
}
