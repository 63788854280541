import { useField } from 'formik'
import { Typography } from '@mui/material'
import { pick } from 'ramda'

import { LineLimitTypography } from '../../LineLimitTypography'

export type ErrorMessageProps = { name: string; inlineError?: boolean; showErrorMessage?: boolean }

export const pickErrorMessageProps = pick(['showErrorMessage', 'inlineError'])

export const ErrorMessage = ({
  name,
  inlineError = false,
  showErrorMessage = true,
}: ErrorMessageProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta] = useField(name)
  if (!showErrorMessage || !meta.touched) {
    return null
  }

  let errorComponent

  if (inlineError) {
    errorComponent = (
      <LineLimitTypography
        variant="body2"
        color="error"
        style={{
          height: 0,
          overflow: 'visible',
        }}
        lineLimit={1}
      >
        {typeof meta.error === 'string' && meta.error}
      </LineLimitTypography>
    )
  }
  errorComponent = (
    <Typography
      variant="body2"
      color="error"
      style={{
        marginTop: 0,
      }}
    >
      {typeof meta.error === 'string' && meta.error}
    </Typography>
  )

  if (inlineError) {
    return (
      <div
        style={{
          display: 'flex',
          height: 0,
        }}
      >
        {errorComponent}
      </div>
    )
  }
  return errorComponent
}
