import { MutationCacheNotifyEvent, useMutationSuccessCallback } from '@cibo/ui'
import { QUERY_KEY } from '../../../queries/queryKey'

// Notify a listener that details have been edited or removed.
export const useDetailsChanged = <T>(callback: (event: MutationCacheNotifyEvent<T>) => void) =>
  useMutationSuccessCallback(
    [
      QUERY_KEY.SAVE_DETAIL,
      QUERY_KEY.SAVE_BULK_DETAILS,
      QUERY_KEY.REMOVE_DETAIL,
      QUERY_KEY.SAVE_DETAIL_FILE,
      QUERY_KEY.UPDATE_MULTIPLE_FIELDS,
    ],
    callback
  )
