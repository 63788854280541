import { RDIrrigation, ResourceDetail } from '@cibo/core'
import { useEffect, useState } from 'react'

import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { TraitCellEditorProps } from '../types'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result === undefined ? '' : detail?.result ? 'yes' : 'no'
}

export const IrrigationCellEditor = ({
  traitId,
  year,
  params,
  t,
  saveDetail,
  removeDetail,
}: TraitCellEditorProps<RDIrrigation>) => {
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleChange = (event: SelectChangeEvent) => {
    const { value } = event.target

    setAnswer(value)

    if (value === '') {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
        year,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, year, result: value === 'yes' }],
      })
    }
  }

  const source = detail?.source

  return (
    <Select value={answer} onChange={handleChange} fullWidth data-testid={`irrigation_editor`}>
      <>
        {source === 'user' && (
          <MenuItem value={''}>
            <>{t('remove')}</>
          </MenuItem>
        )}
      </>
      <MenuItem value={'yes'}>
        <>{t('yes')}</>
      </MenuItem>
      <MenuItem value={'no'}>
        <>{t('no')}</>
      </MenuItem>
    </Select>
  )
}
