import { TermsPolicyDataUseEntry } from '@cibo/core'
import { Markdown, MarkdownLink } from '@cibo/ui'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  FormGroup,
  Stack,
  styled,
  Typography,
} from '@mui/material'
import dayjs from 'dayjs'
import { Form, Formik } from 'formik'
import { equals, reject, uniq } from 'ramda'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useAcceptPolicies, useAuth, useDeclinePolicies } from '../../hooks'
import { BrandLogo } from '../BrandLogo'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialog-container': {
    alignItems: 'flex-start',
  },
}))

export const PendingPolicies = ({ policies = [] }: { policies?: TermsPolicyDataUseEntry[] }) => {
  const { t } = useTranslation('@cibo/profile/PendingPolicies')
  const [showDeclineConfirmation, setShowDeclineConfirmation] = useState(false)
  const declinePolicies = useDeclinePolicies()
  const acceptPolicies = useAcceptPolicies()
  const { logout } = useAuth()

  const handleSubmit = () => acceptPolicies.mutateAsync({ policies })

  // record that the user declines polices WITHOUT a checkmark
  const declineTerms = (policyIds: string[]) =>
    declinePolicies
      .mutateAsync({ policies: policies.filter(({ sys: { id } }) => !policyIds.includes(id)) })
      .then(logout)

  if (!policies) return null

  return (
    <Formik
      initialValues={{ policyIds: [] as string[] }}
      onSubmit={handleSubmit}
      validate={values =>
        values.policyIds.length === policies?.length ? {} : { policyIds: 'Required' }
      }
      validateOnMount
    >
      {({ dirty, isValid, isSubmitting, setFieldValue, submitForm, values }) => (
        <Form>
          <StyledDialog
            open={true}
            maxWidth="md"
            slotProps={{}}
            fullWidth
            hideBackdrop
            disablePortal
          >
            <DialogTitle>
              <Stack direction="row" justifyContent="space-between" alignItems="flex-end">
                <Typography>{t('policyExplaination')}</Typography>
                <BrandLogo variant="square" size="large" />
              </Stack>
            </DialogTitle>
            <DialogContent>
              <Stack spacing={3}>
                <Stack>
                  {policies?.map(policy => (
                    <Accordion
                      expanded={policies.length === 1 ? true : undefined}
                      key={policy.sys.id}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls={`${policy.sys.id}-content`}
                        id={`${policy.sys.id}-header`}
                      >
                        <Stack>
                          <Typography variant="h6">{policy.fields.title}</Typography>
                          <Typography variant="caption">
                            {dayjs(policy.fields.revisionDate).format('MMM D, YYYY')}
                          </Typography>
                        </Stack>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Markdown
                          overrides={{
                            a: {
                              component: MarkdownLink,
                              props: { state: { perspective: 'program' } },
                            },
                          }}
                        >
                          {policy.fields.agreementMarkdownLong ?? policy.fields.agreementMarkdown}
                        </Markdown>
                      </AccordionDetails>
                    </Accordion>
                  ))}
                </Stack>
              </Stack>
            </DialogContent>
            <DialogActions>
              <Stack direction="row" justify-content="space-between" flex={1} px={2}>
                <Stack flex={1}>
                  <FormGroup sx={{ alignItems: 'flex-start' }}>
                    {policies?.map(policy => (
                      <FormControlLabel
                        key={policy.sys.id}
                        control={
                          <Checkbox
                            onChange={(e, checked) =>
                              setFieldValue(
                                'policyIds',
                                checked
                                  ? uniq([...values.policyIds, policy.sys.id])
                                  : reject(equals(policy.sys.id), values.policyIds)
                              )
                            }
                          />
                        }
                        label={
                          <div style={{ userSelect: 'none' }}>
                            <Markdown>
                              {t('agreeToPolicyMd', { policyName: policy.fields.title })}
                            </Markdown>
                          </div>
                        }
                      />
                    ))}
                  </FormGroup>
                </Stack>
                <Stack direction="row" spacing={2} alignItems="flex-end">
                  <Button onClick={() => setShowDeclineConfirmation(true)}>{t('decline')}</Button>
                  <LoadingButton
                    disabled={!isValid || !dirty}
                    loading={isSubmitting}
                    onClick={submitForm}
                    variant="contained"
                    data-testid="accept-terms"
                  >
                    {t('continue')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </DialogActions>
          </StyledDialog>
          <Dialog open={showDeclineConfirmation} maxWidth="sm" fullWidth>
            <DialogTitle>{t('declineConfirmationTitle')}</DialogTitle>
            <DialogContent>
              <Stack spacing={2}>
                <Typography>{t('declineConfirmationText')}</Typography>
                <Stack direction="row" spacing={2} justifyContent="flex-end">
                  <Button
                    disabled={declinePolicies.isPending}
                    variant="outlined"
                    onClick={() => setShowDeclineConfirmation(false)}
                  >
                    {t('cancel')}
                  </Button>
                  <LoadingButton
                    loading={declinePolicies.isPending}
                    variant="contained"
                    onClick={() => declineTerms(values.policyIds)}
                    color="error"
                  >
                    {t('confirmDecline')}
                  </LoadingButton>
                </Stack>
              </Stack>
            </DialogContent>
          </Dialog>
        </Form>
      )}
    </Formik>
  )
}
