import { forwardRef } from 'react'
import { TextField, TextFieldProps, InputAdornment } from '@mui/material'

export interface NumberInputProps {
  max?: number
  min?: number
  step?: number
  unit?: string
}

const BASE_WIDTH = 5
const DEFAULT_CHAR_WIDTH = 5

export const getNumberWidth = (value?: number) => {
  if (value === undefined) {
    return DEFAULT_CHAR_WIDTH
  }

  return `${value}`.length
}

export const getWidth = ({ max, min, unit }: NumberInputProps) =>
  Math.max(getNumberWidth(min), getNumberWidth(max)) + (unit?.length || 0)

export const NumberInput = forwardRef(
  ({ max, min, step, unit, ...textfieldProps }: NumberInputProps & TextFieldProps, ref: any) => (
    <TextField
      type="number"
      sx={{
        minWidth: `${BASE_WIDTH + getWidth({ min, max, unit })}ch`,
      }}
      InputProps={{
        inputProps: { ref, min, max, step },
        endAdornment: unit ? <InputAdornment position="end">{unit}</InputAdornment> : undefined,
      }}
      {...textfieldProps}
    />
  )
)
