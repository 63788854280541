import { useEffect, useState } from 'react'
import { Action } from 'redux'
import { Middleware } from 'redux'

import { v4 as uuid } from 'uuid'

import { getComponentDisplayName } from '../getComponentDisplayName'

type Handler = (action: Action) => void
type HandlerRecord = { listenerId: string; handler: Handler }

const combinedHandlers: Record<string, Array<HandlerRecord>> = {}

export const withActionsMiddleware: Middleware = () => next => (action: Action) => {
  const { type } = action

  if (combinedHandlers[type]) {
    combinedHandlers[type].forEach(({ handler }: HandlerRecord) => {
      handler(action)
    })
  }

  next(action)
}

export const withActions = (WrappedComponent: any) => {
  const WithActions = (props: any) => {
    const [listenerId] = useState(uuid())

    const subscribeAction = (type: string, handler: Handler) => {
      if (!combinedHandlers[type]) {
        combinedHandlers[type] = []
      }

      combinedHandlers[type].push({ listenerId, handler })
      return unsubscribe
    }

    const unsubscribe = () => {
      Object.keys(combinedHandlers).forEach((type: string) => {
        combinedHandlers[type] = combinedHandlers[type].filter(
          (handler: HandlerRecord) => handler.listenerId !== listenerId
        )
      })
    }

    useEffect(() => unsubscribe, [])

    return <WrappedComponent {...props} subscribeAction={subscribeAction} />
  }

  WithActions.displayName = `WithActions(${getComponentDisplayName(WrappedComponent)})`

  return WithActions
}
