let localTrackEvent: (event: any, params: any) => void
let localSetUserId: (id: string | null) => void
let localSetUserProperties: (properties: any) => void

type SetupAnalyticsRequest = {
  trackEvent(event: any, params: any): void
  setUserId(id?: any): void
  setUserProperties(properties: any): void
}

export const setupAnalytics = ({
  trackEvent,
  setUserId,
  setUserProperties,
}: SetupAnalyticsRequest) => {
  localTrackEvent = trackEvent
  localSetUserId = setUserId
  localSetUserProperties = setUserProperties
}

export const trackEvent = (event: any, parameters: any) => {
  if (localTrackEvent) {
    localTrackEvent(event, parameters)
  } else {
    console.warn('setupAnalytics() with trackEvent() implementation')
  }
}

export const setUserId = (id?: any) => {
  if (localSetUserId) {
    localSetUserId(id)
  } else {
    console.warn('setupAnalytics() with setUserId() implementation')
  }
}

export const setUserProperties = (userProperties: any) => {
  if (localSetUserProperties) {
    localSetUserProperties(userProperties)
  } else {
    console.warn('setupAnalytics() with setUserProperties() implementation')
  }
}
