import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const FutureLivestockChangeCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/FutureLivestockChangeEditor')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail?.input?.headcount) {
    return (
      <Stack>
        <Typography variant="body1">{t(detail.input?.livestockChange)}</Typography>
        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {detail?.input?.headcount}
          {t('perAc')}
        </Typography>
      </Stack>
    )
  }

  return <Typography>{t(detail?.input?.livestockChange)}</Typography>
}
