import { ResourceDetailYearly } from '../ResourceDetail'

export const EQIP_IRRIGATION_QUANTITY_OPTIONS = [
  'none',
  'amt_lt12',
  'amt_12to23_9',
  'amt_24to35_9',
  'amt_gte36',
  'unknown',
] as const
export type EqipPracticesIrrigationQuantity = typeof EQIP_IRRIGATION_QUANTITY_OPTIONS[number]

export interface RDEqipPracticesIrrigationQuantityInput {
  irrigationQuantity: EqipPracticesIrrigationQuantity
}

export interface RDEqipPracticesIrrigationQuantity extends ResourceDetailYearly {
  traitId: 'eqipPracticesIrrigationQuantity'
  result: RDEqipPracticesIrrigationQuantityInput
  input: RDEqipPracticesIrrigationQuantityInput
}
