import { ResourceDetailInputEvents, ResourceDetailYearlyEvents } from './ResourceDetail'

export type RDNaturalDisturbanceEvent = {
  month: MonthList
  lossPer: number
  disturbance: DisturbanceTypes
}

export interface RDNaturalDisturbance
  extends ResourceDetailYearlyEvents<
    ResourceDetailInputEvents<RDNaturalDisturbanceEvent>,
    boolean
  > {
  traitId: 'naturalDisturbance'
}

export const DISTURBANCE_TYPES = [
  'coldWetWeather',
  'drought',
  'excessMoisture',
  'wildFire',
  'hail',
  'heat',
  'insectsOrDisease',
  'tornado',
  'excessWind',
  'other',
] as const
export type DisturbanceTypes = typeof DISTURBANCE_TYPES[number]

export const MONTH_LIST = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
] as const
export type MonthList = typeof MONTH_LIST[number]
