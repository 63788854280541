import { AuthUserPermission, Can } from '@cibo/profile'
import { PropsWithChildren, useContext } from 'react'
import { CDLLayer, CDL_YEARS } from './CDLLayer'
import { CountiesLayer } from './CountiesLayer'
import { HUCWatershedLayer } from './HUCWatershedLayer'
import { LayerContext } from './LayerContext'
import { ParcelLayer } from './ParcelLayer'
import { SatelliteLayer } from './SatelliteLayer'
import { SectionLayer } from './SectionLayer'
import { StatesLayer } from './StatesLayer'
import { TownshipLayer } from './TownshipLayer'
import { MapStyles } from './const'

export interface LayerRendererProps {
  county?: boolean
  parcel?: boolean
  township?: boolean
  watershed?: boolean
  state?: boolean
}

export const LayerRenderer = ({
  children,
  county,
  parcel,
  township,
  watershed,
  state,
}: PropsWithChildren<LayerRendererProps>) => {
  const { mapStyle, showParcels, showWatersheds } = useContext(LayerContext)

  return (
    <>
      <SatelliteLayer show={mapStyle === MapStyles.SATELLITE} />
      {CDL_YEARS.map(year => (
        <CDLLayer key={year} year={year} />
      ))}
      <Can useAny={AuthUserPermission.DEBUG_USER}>
        {!!watershed && <HUCWatershedLayer disabled={!showWatersheds} />}
      </Can>
      {!!county && <CountiesLayer />}
      {!!township && <TownshipLayer />}
      {!!state && <StatesLayer />}
      <SectionLayer />
      {!!parcel && <ParcelLayer disable={!showParcels} />}
      {children}
    </>
  )
}
