import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { TraitCellDisplayProps } from '../types'

export const CoverCropCell = ({ traitId, year, params }: TraitCellDisplayProps) => {
  const { t } = useTranslation('@cibo/landmanager/CoverCropEditor')
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')
  const detail = params.row.resolveStandingDetail(traitId, year)

  if (detail.input?.crop) {
    return (
      <Stack flex={1} justifyContent="center">
        <Typography variant="body1">{cropT(detail.input?.crop)}</Typography>
        {!!detail.input?.plantedAt && !!detail.input?.terminatedAt && (
          <Typography variant="caption" sx={{ color: 'text.secondary' }}>
            {t('dates', {
              plant: new Date(detail.input?.plantedAt),
              harvest: new Date(detail.input?.terminatedAt),
            })}
          </Typography>
        )}
      </Stack>
    )
  }

  return (
    <Stack flex={1} overflow="hidden">
      <Typography>{detail.result ? t('yes') : t('no')}</Typography>
    </Stack>
  )
}
