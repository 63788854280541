import { RDEqipHasFarmInfo } from '@cibo/core'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import {
  Collapse,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Radio,
  RadioGroup,
  Stack,
  Typography,
} from '@mui/material'
import { ChangeEvent } from 'react'
import { useTranslation } from 'react-i18next'
import {
  useProgramEngagementDetails,
  useUpdateProgramEngagementDetails,
} from '../../../../hooks/useProgramEngagementDetails'

/**
 * Assumptions:
 * 1. you will only pass a single resourceId in the resourceIds array
 * 2. there will only ever be a single detail for a given resourceId
 */
export const RDEqipHasFarmInfoTaskEditor = ({
  detailRequirements,
  resourceIds: [resourceId],
  onUpdating,
  onError,
  onSuccess,
  ownerName,
  userRole,
}: ResourceDetailFeatureTaskEditorProps<RDEqipHasFarmInfo>) => {
  const { t } = useTranslation('@cibo/programs/RDEqipHasFarmInfoTaskEditor')
  const details = useProgramEngagementDetails({ resourceId, detailRequirements })
  const updateResourceDetail = useUpdateProgramEngagementDetails()

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    onUpdating?.()
    updateResourceDetail
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'eqipHasFarmInfo',
            result: event.target.value,
          } as RDEqipHasFarmInfo,
        ],
      })
      .then(onSuccess)
      .catch(onError)
  }

  return (
    <Stack>
      <Typography>{t('label', { context: userRole, name: ownerName })}</Typography>
      <FormControl component="fieldset" variant="standard" error={updateResourceDetail.isError}>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          value={details?.data?.[0]?.result?.toString() ?? ''}
          onChange={onChange}
        >
          <FormControlLabel
            value="registeredUpToDate"
            data-testid="registeredUpToDate"
            control={<Radio />}
            label={t('registeredUpToDate', { context: userRole, name: ownerName })}
            disabled={
              details.data?.[0]?.immutable || details?.isPending || updateResourceDetail?.isPending
            }
          />
          <FormControlLabel
            value="notRegistered"
            data-testid="notRegistered"
            control={<Radio />}
            label={t('notRegistered', { context: userRole, name: ownerName })}
            disabled={
              details.data?.[0]?.immutable || details?.isPending || updateResourceDetail?.isPending
            }
          />
          <FormControlLabel
            value="registeredOutOfDate"
            data-testid="registeredOutOfDate"
            control={<Radio />}
            label={t('registeredOutOfDate', { context: userRole, name: ownerName })}
            disabled={
              details.data?.[0]?.immutable || details?.isPending || updateResourceDetail?.isPending
            }
          />
        </RadioGroup>
        <FormHelperText>
          <Markdown overrides={{ small: { component: Typography, props: { variant: 'caption' } } }}>
            {t('helperMd', { context: userRole, name: ownerName })}
          </Markdown>
        </FormHelperText>
        <Collapse in={updateResourceDetail.isError}>
          <FormHelperText>{updateResourceDetail.error?.response?.data?.message}</FormHelperText>
        </Collapse>
      </FormControl>
    </Stack>
  )
}
