import { Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'

export const fieldNameLocationColumn = ({ t, overrides }: { t: TFunction; overrides?: any }) => ({
  field: 'name',
  headerName: t('name', { ns: '@cibo/landmanager/FieldColumns' }),
  hideable: false,
  flex: 1,
  minWidth: 160,
  renderCell: (
    params: GridRenderCellParams<any, { name: string; county: string; state: string }, any>
  ) => (
    <Stack>
      {params.row.name}
      <Typography variant="caption">
        {params.row.county ? `${params.row.county}, ` : ''}
        {params.row.state ? params.row.state.toUpperCase() : ''}
      </Typography>
    </Stack>
  ),
  ...overrides,
})
