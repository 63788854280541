import { Markdown } from '@cibo/ui'
import { useTranslation } from 'react-i18next'
import { RollupQuestionProps } from '../../Rollups/types'
import { displayYearsRange } from '../../utils'

export const WetlandComplianceCTA = ({ requirements }: RollupQuestionProps) => {
  const { t } = useTranslation('@cibo/landmanager/WetlandCompliance')

  return (
    <Markdown typographyProps={{ variant: 'body1' }}>
      {t('tableQuestion', { period: displayYearsRange(requirements) })}
    </Markdown>
  )
}
