import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { fieldFarmColumn } from './fieldFarmColumn'
import { fieldGrowerColumn } from './fieldGrowerColumn'
import { fieldIdentifierColumn } from './fieldIdentifierColumn'
import { fieldNameColumn } from './fieldNameColumn'
import { fieldUpdatedAtColumn } from './fieldUpdatedAtColumn'
import { FieldColumnProps } from './types'

export const useFieldColumns = (
  { onClickField }: Pick<FieldColumnProps, 'onClickField'> = { onClickField: () => null }
) => {
  const { t } = useTranslation('@cibo/landmanager/FieldColumns')

  return useMemo(
    () => ({
      fieldNameColumn: fieldNameColumn({ t }),
      fieldFarmColumn: fieldFarmColumn({ t }),
      fieldIdentifierColumn: fieldIdentifierColumn({ t, flex: 1, onClickField }),
      fieldUpdatedAtColumn: fieldUpdatedAtColumn({ t, width: 150, flex: 0 }),
      fieldGrowerColumn: fieldGrowerColumn({ t, width: 150 }),
    }),
    [onClickField]
  )
}
