import { ResourceDetailYearly } from '../ResourceDetail'

export const EQIP_PRACTICES_SOIL_COVER_OPTIONS = [
  'rapidly_depleting',
  'depleting',
  'maintaining',
  'building',
] as const

export type EqipPracticesSoilCoverOption = typeof EQIP_PRACTICES_SOIL_COVER_OPTIONS[number]

export type RDEqipPracticesSoilCoverInput = {
  soilCondition: EqipPracticesSoilCoverOption
}

export interface RDEqipPracticesSoilCover extends ResourceDetailYearly {
  traitId: 'eqipPracticesSoilCover'
  input: RDEqipPracticesSoilCoverInput
  result: RDEqipPracticesSoilCoverInput
}
