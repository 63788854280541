import { MultiPolygon } from 'geojson'
import { equals, path, uniqWith } from 'ramda'

import { MpxParcel } from '../types'
import { CarbonFootprintResult } from '../types/CarbonFootprintResult'
import { Collection } from '../types/CollectionTypes'
import {
  CollectionEntry,
  LandProfile,
  LandProfileCapability,
  LandProfileType,
  YieldValues,
} from '../types/LandProfile'
import { FieldUpdate, PortfolioUpdate, UserMessage } from '../types/Messages'
import { labelPositionForGeometry } from '../utils'
import { Array2d } from '../utils/geometry'

export interface OwnerInformation {
  owner: string
  ownerAddress?: string
}

export class LandProfileModel implements LandProfile {
  acreage!: number
  aggTransferVal?: number
  boundingBox: Array2d
  capabilities: LandProfileCapability[]
  cornHistoricalYields?: YieldValues[]
  countyPkId?: string
  description?: string
  entries?: CollectionEntry[]
  geometry?: MultiPolygon
  geometrySlug?: string
  ghgEmissions?: number
  ghgEmissionsIndex?: number
  ghgEmissionsPerYield?: number
  label?: {
    primary?: string
    secondary?: string
    tertiary?: string
  }
  labelPosition?: number[]
  leaseValuePerAcre!: number
  location: number[]
  ciboResults?: {
    carbonFootprint?: CarbonFootprintResult[]
  }
  messages?: UserMessage[]
  name?: string
  nccpi?: number
  nitrogenLeaching?: number
  nitrogenLeachingIndex?: number
  nitrogenLeachingPerYield?: number
  operator?: string
  owner?: string
  ownerAddress?: string
  parcelCount?: number
  productivity!: number
  productivityUpdatedAt?: string
  regenPotential?: number
  regenPotentialPerAcre?: number
  resourceId?: any
  soyHistoricalYields?: YieldValues[]
  space?: string
  stability!: number
  statePi?: number
  statePiName?: string
  sustainability!: number
  sustainabilityValuesUpdatedAt?: string
  tillableAcres!: number
  tillableGeometry?: MultiPolygon
  tillableGeometrySlug?: string
  transferDate?: string
  transferPerAcre?: number
  transferType?: string
  transferVal?: number
  type!: LandProfileType
  valuePerAcre!: number

  constructor(params: any = {}) {
    this.acreage = params.acreage
    this.aggTransferVal = params.aggTransferVal
    this.boundingBox = params.boundingBox
    this.capabilities = params.capabilities
    this.cornHistoricalYields = params.cornHistoricalYields
    this.countyPkId = params.countyPkId
    this.description = params.description
    this.entries = params.entries
    this.geometry = params.geometry
    this.geometrySlug = params.geometrySlug
    this.ghgEmissions = params.ghgEmissions
    this.ghgEmissionsIndex = params.ghgEmissionsIndex
    this.ghgEmissionsPerYield = params.ghgEmissionsPerYield
    this.label = params.label
    this.labelPosition = params.labelPosition
    this.leaseValuePerAcre = params.leaseValuePerAcre
    this.location = params.location
    this.ciboResults = params._meta?.ciboResults
    this.messages = params._meta?.messages
    this.name = params.name
    this.nccpi = params.nccpi
    this.nitrogenLeaching = params.nitrogenLeaching
    this.nitrogenLeachingIndex = params.nitrogenLeachingIndex
    this.nitrogenLeachingPerYield = params.nitrogenLeachingPerYield
    this.operator = params.operator
    this.owner = params.owner
    this.ownerAddress = params.ownerAddress
    this.parcelCount = params.parcelCount
    this.productivity = params.productivity
    this.productivityUpdatedAt = params.productivityUpdatedAt
    this.regenPotential = params.regenPotential
    this.regenPotentialPerAcre = params.regenPotentialPerAcre
    this.resourceId = params.resourceId
    this.soyHistoricalYields = params.soyHistoricalYields
    this.space = params.space
    this.stability = params.stability
    this.statePi = params.statePi
    this.statePiName = params.statePiName
    this.sustainability = params.sustainability
    this.sustainabilityValuesUpdatedAt = params.sustainabilityValuesUpdatedAt
    this.tillableAcres = params.tillableAcres
    this.tillableGeometry = params.tillableGeometry
    this.tillableGeometrySlug = params.tillableGeometrySlug
    this.transferDate = params.transferDate
    this.transferPerAcre = params.transferPerAcre
    this.transferType = params.transferType
    this.transferVal = params.transferVal
    this.type = params.type
    this.valuePerAcre = params.valuePerAcre
  }

  hasCapability(capability: LandProfileCapability) {
    return this.capabilities?.includes(capability)
  }

  get valuation() {
    return this.valuePerAcre * this.acreage
  }

  get leaseValue() {
    return this.leaseValuePerAcre * this.tillableAcres
  }

  get tillablePercent() {
    return Math.round((this.tillableAcres / this.acreage) * 100)
  }

  get displayName() {
    return this.type === 'portfolio' ? this.name : this.label?.primary
  }

  static fromParcel(parcel: MpxParcel): LandProfile {
    return new LandProfileModel({
      type: LandProfileType.PARCEL,
      resourceId: parcel.id,
      ...parcel.properties,
      labelPosition: labelPositionForGeometry(parcel.geometry),
      geometry: parcel.geometry,
    })
  }
  static fromCollection(collection: Collection): LandProfile {
    return new LandProfileModel({
      ...path(['_meta', 'landProfile'], collection),
      ...collection,
    })
  }

  static fromCollectionEntry(entry: CollectionEntry): LandProfile {
    return new LandProfileModel({
      ...entry.refItem,
      ...path(['_meta', 'landProfile'], entry),
      geometrySlug: entry.geometrySlug,
    })
  }

  get isPublic() {
    return this.space === 'public'
  }

  static findAverageYield = (arrayToSearch?: YieldValues[]) => {
    if (!arrayToSearch) {
      return
    }
    let totalForAverage = 0
    let countForAverage = 0
    let remainingLength = arrayToSearch.length - 1
    while (remainingLength > 0) {
      const yrYield = arrayToSearch[remainingLength].avgYield
      if (yrYield !== 0) {
        totalForAverage += yrYield
        countForAverage += 1
      }
      --remainingLength
    }
    return totalForAverage / countForAverage
  }

  get historicYield() {
    return {
      corn: LandProfileModel.findAverageYield(this.cornHistoricalYields),
      soy: LandProfileModel.findAverageYield(this.soyHistoricalYields),
    }
  }

  static findMostRecentYield = (cornArray?: YieldValues[], soyArray?: YieldValues[]) => {
    if (!cornArray && !soyArray) {
      return
    }
    const cornVal = cornArray && LandProfileModel.findMostRecentYieldAndYear(cornArray)
    const soyVal = soyArray && LandProfileModel.findMostRecentYieldAndYear(soyArray)
    if (cornVal && soyVal) {
      if (cornVal.year === soyVal.year) {
        return { corn: cornVal.avgYield, soy: soyVal.avgYield }
      }
      if (cornVal.year > soyVal.year) {
        return { corn: cornVal.avgYield }
      }
      return { soy: soyVal.avgYield }
    } else if (cornVal && !soyVal) {
      return { corn: cornVal.avgYield }
    }
    return soyVal ? { soy: soyVal.avgYield } : {}
  }

  static findMostRecentYieldAndYear = (arrayToSearch: YieldValues[]) => {
    let returnValue = { avgYield: 0, year: 0 }
    let remainingLength = arrayToSearch.length - 1
    while (returnValue.avgYield === 0 && remainingLength > 0) {
      returnValue = arrayToSearch[remainingLength]
      --remainingLength
    }
    return returnValue
  }

  get inSeasonYield() {
    return LandProfileModel.findMostRecentYield(this.cornHistoricalYields, this.soyHistoricalYields)
  }

  get willRenderThumbnail() {
    const noTillableGeometry = this.tillableGeometry === undefined
    const noGeometryCoordinates = this.geometry?.coordinates.length === 0
    return !noTillableGeometry || !noGeometryCoordinates
  }

  get hasUnviewedOwnerChange() {
    return (
      this.messages?.some(({ messageData, messageReadStatus, messageType }) => {
        if (messageType === 'pfUpdate' && messageReadStatus) {
          return (messageData as PortfolioUpdate).fieldData[this.resourceId]?.hasOwnerChange
        } else if (messageType === 'fieldUpdate' && messageReadStatus) {
          return (messageData as FieldUpdate).hasOwnerChange
        }
        return false
      }) || false
    )
  }
  get hasUnviewedPracticeChange() {
    return (
      this.messages?.some(({ messageData, messageReadStatus, messageType }) => {
        if (messageType === 'pfUpdate' && messageReadStatus) {
          return (messageData as PortfolioUpdate).fieldData[this.resourceId]?.hasPracticeChange
        } else if (messageType === 'fieldUpdate' && messageReadStatus) {
          return (messageData as FieldUpdate).hasPracticeChange
        }
        return false
      }) || false
    )
  }
  get hasUnviewedYieldChange() {
    return (
      this.messages?.some(({ messageData, messageReadStatus, messageType }) => {
        if (messageType === 'pfUpdate' && messageReadStatus) {
          return (messageData as PortfolioUpdate).fieldData[this.resourceId]?.hasYieldChange
        } else if (messageType === 'fieldUpdate' && messageReadStatus) {
          return (messageData as FieldUpdate).hasYieldChange
        }
        return false
      }) || false
    )
  }

  get estCarbonCredits() {
    return this.regenPotential
  }
  get creditsPerAcre() {
    return this.regenPotentialPerAcre
  }

  get ownerList() {
    return this.owner
      ? [
          {
            owner: this.owner,
            ownerAddress: this.ownerAddress || '',
          } as OwnerInformation,
        ]
      : this.entries
      ? this.entries.map(({ refItem: { ownerInfo } }) => ownerInfo as OwnerInformation)
      : []
  }

  get distinctOwnerList() {
    return uniqWith(equals, this.ownerList)
  }

  get baseFootprint() {
    return this.ciboResults?.carbonFootprint && this.ciboResults?.carbonFootprint[0]
  }
}
