import {
  EQIP_PRACTICE_CHOICE_ENGINEERING_LAND_EROSION_STRUCTURES,
  RDEqipPracticeChoiceEngineeringLandErosionStructures,
} from '@cibo/core'
import { ResourceDetailFeature } from '@cibo/ui'
import { WorkflowDetailChoiceSelection } from '../../../../components/WorkflowDetailChoiceSelection'

export const RDEqipPracticeChoiceEngineeringLandErosionStructuresResourceDetailFeature: ResourceDetailFeature<RDEqipPracticeChoiceEngineeringLandErosionStructures> =
  {
    traitId: 'eqipPracticeChoiceEngineeringLandErosionStructures',
    TaskEditor: props => (
      <WorkflowDetailChoiceSelection
        {...props}
        choices={EQIP_PRACTICE_CHOICE_ENGINEERING_LAND_EROSION_STRUCTURES}
      />
    ),
  }
