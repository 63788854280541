import chroma from 'chroma-js'

import {
  MpxSearchResultParcel,
  MpxSearchAggregationValue,
  MpxGeoPoliticalAggregationType,
  MapPreferenceDataLayer,
} from '@cibo/core'

import { Theme } from '../consts/Theme'

const DEFAULT_FILL = Theme.color.map.default.fill

const TEALS = [
  '#bbdfdf',
  '#a2d4d5',
  '#8ac9c9',
  '#75bcbb',
  '#61b0af',
  '#4da5a4',
  '#379998',
  '#2b8b8c',
  '#1e7f7f',
  '#127273',
  '#006667',
]
const WARM_GREYS = [
  '#dcd4d0',
  '#cec5c1',
  '#c0b8b4',
  '#b3aaa7',
  '#a59c99',
  '#98908c',
  '#8b827f',
  '#7e7673',
  '#726866',
  '#665c5a',
  '#59504e',
]
const YELLOW_GREEN = [
  '#e4f4ac',
  '#d1eca0',
  '#b9e294',
  '#9ed688',
  '#80c97c',
  '#62bb6e',
  '#47aa5e',
  '#329750',
  '#208344',
  '#0e723b',
  '#036034',
]
const YELLOW_GREEN_BLUE = [
  '#eff9bd',
  '#dbf1b4',
  '#bde5b5',
  '#94d5b9',
  '#69c5be',
  '#45b4c2',
  '#2c9ec0',
  '#2182b8',
  '#2163aa',
  '#23479c',
  '#1c3185',
]
const RED_YELLOW_GREEN = [
  '#a50026',
  '#d4322c',
  '#f16e43',
  '#fcac63',
  '#fedd8d',
  '#f9f7ae',
  '#d7ee8e',
  '#a4d86e',
  '#64bc61',
  '#22964f',
  '#006837',
]

export const teals = chroma.scale(TEALS)
const warmGreys = chroma.scale(WARM_GREYS)
export const yellowGreen = chroma.scale(YELLOW_GREEN)
export const yellowGreenBlue = chroma.scale(YELLOW_GREEN_BLUE)
export const redYellowGreen = chroma.scale(RED_YELLOW_GREEN)

const DEFAULT_WARM_GREY = 0.6

const parcelFillColor = (parcel: MpxSearchResultParcel, dataLayer?: MapPreferenceDataLayer) => {
  const {
    properties: { productivity, regenPotentialPerAcre, nccpi },
  } = parcel
  switch (dataLayer) {
    case MapPreferenceDataLayer.NCCPI:
      return productivity
        ? redYellowGreen(Math.min((nccpi || 0) / 100, 1)).hex()
        : warmGreys(DEFAULT_WARM_GREY).hex()
    case MapPreferenceDataLayer.REGENERATIVE_POTENTIAL:
      return regenPotentialPerAcre
        ? yellowGreenBlue(Math.min(regenPotentialPerAcre / 1.5 || 0, 1)).hex()
        : warmGreys(DEFAULT_WARM_GREY).hex()
    case MapPreferenceDataLayer.COUNT:
      return DEFAULT_FILL
    default:
      return warmGreys(DEFAULT_WARM_GREY).hex()
  }
}

const clusterFillColor = (
  feature: MpxSearchAggregationValue,
  dataLayer?: MapPreferenceDataLayer,
  geoPoliticalAggType?: MpxGeoPoliticalAggregationType
) => {
  let fillColor = DEFAULT_FILL
  let dividend

  switch (geoPoliticalAggType) {
    case MpxGeoPoliticalAggregationType.STATE: {
      dividend = 250000
      break
    }
    case MpxGeoPoliticalAggregationType.COUNTY: {
      dividend = 3500
      break
    }
    case MpxGeoPoliticalAggregationType.TOWNSHIP: {
      dividend = 350
      break
    }
    default: {
      dividend = 1
    }
  }

  const {
    properties: { aggMetricValue, count },
  } = feature

  switch (dataLayer) {
    case MapPreferenceDataLayer.NCCPI:
      fillColor = aggMetricValue
        ? redYellowGreen(Math.min((aggMetricValue || 0) / 100, 1)).hex()
        : warmGreys(Math.min(count / dividend, 1)).hex()
      break
    case MapPreferenceDataLayer.REGENERATIVE_POTENTIAL:
      fillColor = aggMetricValue
        ? yellowGreenBlue(Math.min((aggMetricValue || 0) / 1.5, 1)).hex()
        : warmGreys(Math.min(count / dividend, 1)).hex()
      break
    default:
      fillColor = count ? teals(Math.min(count / dividend, 1)).hex() : DEFAULT_FILL
      break
  }

  return {
    fillColor,
    defaultFillColor: warmGreys(Math.min(count / dividend, 1)).hex(),
  }
}

export { clusterFillColor, parcelFillColor }
