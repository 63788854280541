import ErrorIcon from '@mui/icons-material/ErrorOutlineRounded'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import WarningIcon from '@mui/icons-material/WarningAmberOutlined'
import { IconProps, Typography, TypographyProps, useTheme } from '@mui/material'

export interface FriendlyErrorProps {
  type?: 'info' | 'error' | 'warning'
  small?: boolean
  message: string
  textProps?: TypographyProps<any>
  iconProps?: IconProps<any>
}

export const FriendlyError = ({
  type = 'error',
  message,
  small = false,
  iconProps = {},
  textProps = {},
}: FriendlyErrorProps) => {
  const { palette } = useTheme()
  const { sx, ...restTextProps } = textProps
  const { sx: iconSx, ...restIconProps } = iconProps
  return (
    <Typography
      color={
        !textProps?.color
          ? type === 'error'
            ? 'error'
            : type === 'warning'
            ? palette.warning.main
            : undefined
          : undefined
      }
      variant={small ? 'caption' : 'body1'}
      sx={{ display: 'inline-flex', alignItems: 'center', ...sx }}
      {...restTextProps}
    >
      {type === 'error' ? (
        <WarningIcon
          color={(small ? palette.text.secondary : palette.error.main) as any}
          fontSize={small ? 'small' : undefined}
          sx={{ mr: 1, ...iconSx }}
          {...restIconProps}
        />
      ) : type === 'warning' ? (
        <ErrorIcon
          color={(small ? palette.text.secondary : palette.warning.main) as any}
          fontSize={small ? 'small' : undefined}
          sx={{ mr: 1, ...iconSx }}
          {...restIconProps}
        />
      ) : (
        <InfoIcon
          color={(small ? palette.text.secondary : palette.info.main) as any}
          fontSize={small ? 'small' : undefined}
          sx={{ mr: 1, ...iconSx }}
          {...restIconProps}
        />
      )}
      {message}
    </Typography>
  )
}

export default FriendlyError
