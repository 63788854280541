import { ResourceDetail } from '@cibo/core'

export const IRRIGATION_ENERGY_SOURCE = [
  'diesel',
  'electricity',
  'renewable',
  'other',
  'unknown',
] as const
export type IrrigationEnergySource = typeof IRRIGATION_ENERGY_SOURCE[number]

export const IRRIGATION_WATER_SOURCE = ['groundwater', 'onFarm', 'other'] as const
export type IrrigationWaterSource = typeof IRRIGATION_WATER_SOURCE[number]

export const IRRIGATION_SURFACE_WATER = ['tailwaterPits', 'waterBody'] as const
export type IrrigationSurfaceWater = typeof IRRIGATION_SURFACE_WATER[number]

export const IRRIGATION_WATER_UNIT = ['af', 'gal'] as const
export type IrrigationWaterUnit = typeof IRRIGATION_WATER_UNIT[number]

export const IRRIGATION_ENERGY_UNIT = ['gal', 'kWh'] as const
export type IrrigationEnergyUnit = typeof IRRIGATION_ENERGY_UNIT[number]

export interface WaterPumpIrrigation {
  pumpsUsed: boolean
  energySource?: IrrigationEnergySource
  energyAmount?: number
  energyUnit?: IrrigationEnergyUnit
  userEnergyUnit?: string
  totalWater?: number
  waterUnit?: IrrigationWaterUnit
  waterSource?: IrrigationWaterSource
  operatingPressurePSI?: number
  pumpingDepthFeet?: number
  surfaceWaterSource?: IrrigationSurfaceWater
  liftingOccurs?: boolean
}

export interface RDWaterPumpIrrigation extends ResourceDetail<WaterPumpIrrigation, boolean> {
  traitId: 'waterPumpIrrigation'
  season: number
}
