import { RDEqipEligibilityLandControl } from '@cibo/core'
import { Markdown, ResourceDetailFeatureTaskEditorProps } from '@cibo/ui'
import { Stack, Typography } from '@mui/material'
import { ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useFields } from '../../../../queries'
import { BooleanDetailSelectionTable } from '../../BooleanDetailSelectionTable'

export const EqipLandControlTaskEditor = (
  props: ResourceDetailFeatureTaskEditorProps<RDEqipEligibilityLandControl>
) => {
  const { t } = useTranslation('@cibo/landmanager/LandControlFeature')
  const { userRole, ownerName, resourceIds } = props
  const fieldModels = useFields(resourceIds)

  return (
    <Stack spacing={3} flex={1}>
      <Markdown
        overrides={{
          em: {
            component: ({ children }: { children: ReactNode }) => (
              <Typography variant="body2" color="text.secondary" component="span">
                {children}
              </Typography>
            ),
          },
        }}
      >
        {t('question', { context: userRole, name: ownerName, count: fieldModels.data?.length })}
      </Markdown>

      <Stack mt={3} spacing={3}>
        <Markdown>{t('tableCta', { context: userRole, name: ownerName })}</Markdown>

        <BooleanDetailSelectionTable<RDEqipEligibilityLandControl>
          answerValues={{ yes: { value: true }, no: { value: false } }}
          detailKey="landOwnershipEligible"
          {...props}
        />
      </Stack>
    </Stack>
  )
}
