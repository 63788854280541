import { COVER_CROP, CoverCrop } from '@cibo/core'
import {
  Autocomplete,
  Box,
  Collapse,
  FormControl,
  FormLabel,
  Slider,
  Stack,
  TextField,
} from '@mui/material'
import { useField } from 'formik'
import { uniq } from 'ramda'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { minMaxYears } from './utils'

const currentYear = new Date().getFullYear()
const COVER_CROP_YEARS = new Array(5).fill(0).map((v, i) => currentYear - i)
const COVER_CROP_OPTIONS = ['true', 'false', ...COVER_CROP]

export const CoverCropFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/CoverCropFilter')
  const { t: tCrops } = useTranslation('@cibo/ui/cropNames')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, { value: coverCrops = [] }, { setValue }] = useField('coverCrop')

  const [crops, setCrops] = useState<CoverCrop[]>(
    uniq(coverCrops.map((c: string) => c.split(':')[1]))
  )
  const [years, setYears] = useState<number[]>(
    coverCrops.length > 0
      ? minMaxYears(coverCrops.map((c: string) => parseInt(c.split(':')[0])).sort())
      : [COVER_CROP_YEARS[COVER_CROP_YEARS.length - 1], COVER_CROP_YEARS[0]]
  )

  const handleChangeYear = (event: any, newYears: number | number[], activeThumb: number) => {
    if (Array.isArray(newYears)) {
      setYears(newYears)
    }
  }

  const handleChangeCrop = (event: any, newCrops: CoverCrop[], reason: any) => {
    // `any` and `none` are exclusive options
    if (newCrops.includes('true')) {
      if (!crops.includes('true')) {
        setCrops(['true'])
      } else {
        setCrops(newCrops.filter(c => c !== 'true'))
      }
    } else if (newCrops.includes('false')) {
      if (!crops.includes('false')) {
        setCrops(['false'])
      } else {
        setCrops(newCrops.filter(c => c !== 'false'))
      }
    } else {
      setCrops(newCrops)
    }
  }

  /**
   * update the formik field when we have a valid input - which means
   * at least one crop and a range of years
   */
  useEffect(() => {
    if (crops.length > 0 && years.length > 0) {
      const newCoverCrops: string[] = []
      for (let year = years[0]; year <= years[1]; year++) {
        crops.forEach(crop => newCoverCrops.push(`${year}:${crop}`))
      }
      setValue(newCoverCrops)
    } else {
      setValue(undefined)
    }
  }, [crops.join(), years.join()])

  return (
    <FormControl>
      <FormLabel>{t('Cover Crop', { ns: 'clientLocalization' })}</FormLabel>
      <Stack>
        <Autocomplete
          multiple
          limitTags={2}
          options={COVER_CROP_OPTIONS}
          getOptionLabel={crop => tCrops(crop)}
          renderInput={params => (
            <TextField {...params} placeholder={crops.length === 0 ? t('any') : ''} />
          )}
          // @ts-ignore todo: fix for improved cc type
          onChange={handleChangeCrop}
          size="small"
          value={crops}
          fullWidth
        />
        <Collapse in={crops.length > 0}>
          <Box sx={{ px: 2 }}>
            <Slider
              min={COVER_CROP_YEARS[COVER_CROP_YEARS.length - 1]}
              max={COVER_CROP_YEARS[0]}
              value={years}
              onChange={handleChangeYear}
              valueLabelDisplay="auto"
              disabled={crops.length === 0}
              marks={[
                {
                  value: COVER_CROP_YEARS[COVER_CROP_YEARS.length - 1],
                  label: COVER_CROP_YEARS[COVER_CROP_YEARS.length - 1],
                },
                { value: COVER_CROP_YEARS[0], label: COVER_CROP_YEARS[0] },
              ]}
              size="small"
            />
          </Box>
        </Collapse>
      </Stack>
    </FormControl>
  )
}
