import { DISPLAYABLE_CASH_CROPS, ResourceDetail } from '@cibo/core'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import MenuItem from '@mui/material/MenuItem'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { TraitCellEditorProps } from '../types'

const answerFromDetail = (detail?: ResourceDetail) => {
  return detail?.result
}

export const FutureCashCropCellEditor = ({
  traitId,
  year,
  params,
  t,
  saveDetail,
  removeDetail,
}: TraitCellEditorProps) => {
  const { t: cropT } = useTranslation('@cibo/ui/cropNames')
  const detail = params.row.resolveStandingDetail(traitId, year)
  const [answer, setAnswer] = useState(answerFromDetail(detail))

  useEffect(() => {
    setAnswer(answerFromDetail(detail))
  }, [detail])

  const handleChangeCrop = (event: SelectChangeEvent) => {
    const { value } = event.target

    setAnswer(value)

    if (value === '') {
      removeDetail({
        resourceId: params.row.resourceId,
        traitId,
        year,
      })
    } else {
      saveDetail({
        resourceId: params.row.resourceId,
        details: [{ traitId, year, result: value }],
      })
    }
  }

  const source = detail?.source

  return (
    <Select
      value={answer}
      onChange={handleChangeCrop}
      fullWidth
      data-testid={`future_cash_crop_editor`}
    >
      <MenuItem key="undefined" value={'undefined'}>
        <>{t('')}</>
      </MenuItem>
      {source === 'user' && (
        <MenuItem key="remove" value={''}>
          <>{t('remove')}</>
        </MenuItem>
      )}
      {DISPLAYABLE_CASH_CROPS.map(crop => (
        <MenuItem key={crop} value={crop}>
          {cropT(crop)}
        </MenuItem>
      ))}
    </Select>
  )
}
