import { RDWetlandDesignation } from '@cibo/core'
import { pathEq } from 'ramda'
import { boolean, object } from 'yup'
import { ITraitFeatureById } from '../types'
import { findInitialValuesDetail } from '../utils'
import { WetlandDesignationCell } from './WetlandDesignationCell'
import { WetlandDesignationEditor } from './WetlandDesignationEditor'
import { WetlandDesignationRollup } from './WetlandDesignationRollup'

const TRAIT_ID = 'includesWetlandDesignation'

export const WetlandDesignation: ITraitFeatureById<RDWetlandDesignation> = {
  traitId: TRAIT_ID,
  cellDisplay: WetlandDesignationCell,
  editor: WetlandDesignationEditor,
  rollups: {
    default: {
      traitId: TRAIT_ID,
      Component: WetlandDesignationRollup,
      detailBaseValue: () => ({
        traitId: TRAIT_ID,
        input: {
          overlaysWetland: false,
        },
      }),
      isRollupValue: pathEq(['result'], false),
    },
  },
  initialValues: request =>
    findInitialValuesDetail(TRAIT_ID)(request)?.input as RDWetlandDesignation['input'],
  ns: '@cibo/landmanager/WetlandDesignationEditor',
  validationSchema: ({ t }) =>
    object()
      .shape({
        overlaysWetland: boolean().required(t('overlayResponseRequired')),
      })
      .required(),
}
