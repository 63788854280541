import { Breakpoint, Theme, useTheme } from '@mui/material'
import useMediaQuery from '@mui/material/useMediaQuery'

type BreakpointOrNull = Breakpoint | null

/**
 * lifted from https://mui.com/components/use-media-query/#migrating-from-withwidth
 *
 *
 * Be careful using this hook. It only works because the number of
 * breakpoints in theme is static. It will break once you change the number of
 * breakpoints. See https://reactjs.org/docs/hooks-rules.html#only-call-hooks-at-the-top-level
 */
export const useWidth = () => {
  const theme: Theme = useTheme()
  const keys: readonly Breakpoint[] = [...theme.breakpoints.keys].reverse()
  return (
    keys.reduce((output: BreakpointOrNull, key: Breakpoint) => {
      // eslint-disable-next-line react-hooks/rules-of-hooks
      const matches = useMediaQuery(theme.breakpoints.up(key))
      return !output && matches ? key : output
    }, null) || 'xs'
  )
}

const MOBILE = ['xs', 'sm']
const TABLET = ['md']
const DESKTOP = ['lg', 'xl']

export const useFormFactor = () => {
  const width = useWidth()
  return {
    isMobile: MOBILE.includes(width),
    isTablet: TABLET.includes(width),
    isDesktop: DESKTOP.includes(width),
  }
}
