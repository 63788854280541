import { Box, styled } from '@mui/material'
import { OverridableComponent } from '@mui/material/OverridableComponent'

export const Section = styled<OverridableComponent<any>>(Box)(({ theme: { palette, shape } }) => ({
  borderRadius: shape.borderRadius,
  backgroundColor: palette.background.paper,
  borderColor: palette.divider,
  borderStyle: 'solid',
  borderWidth: 1,
  overflow: 'hidden',
}))
