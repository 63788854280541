import { FieldModel, IntegerString } from '@cibo/core'
import { Chip } from '@mui/material'
import { GridColDef, GridRenderCellParams } from '@mui/x-data-grid-pro'
import { TFunction } from 'i18next'

export const fieldProgramStatusColumn = ({
  t,
  practiceId,
}: {
  t: TFunction
  practiceId?: IntegerString
}) =>
  ({
    field: 'availablePractices',
    headerName: t('status', { ns: '@cibo/programs/FieldSelection' }),
    flex: 1,
    sortable: false,
    filterable: false,
    renderCell: (
      params: GridRenderCellParams<FieldModel & { availablePractices: IntegerString[] }, string>
    ) => {
      if (!practiceId || !params.value) return null

      const available = params.value.includes(practiceId)

      return available ? (
        <Chip
          label={<>{t('recommended', { ns: '@cibo/programs/FieldsSelection' })}</>}
          color="success"
          variant="filled"
        />
      ) : (
        <Chip
          label={<>{t('ineligible', { ns: '@cibo/programs/FieldsSelection' })}</>}
          color="error"
          variant="filled"
        />
      )
    },
  } as GridColDef<FieldModel>)
