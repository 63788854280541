// @ts-nocheck
import { ResourceDetailSource } from '@cibo/core'
import { Chip, ChipProps, useTheme } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { SourceIcon } from '../SourceIcon'

interface CropChipProps extends Omit<ChipProps, 'icon'> {
  crop: string
  labelOverride?: string
  icon?: boolean
  source?: ResourceDetailSource | 'none' | 'notObserved'
}

export const CropChip = ({
  crop,
  icon,
  labelOverride,
  source,
  size = 'small',
  ...chipProps
}: CropChipProps) => {
  const { t } = useTranslation('@cibo/ui/cropNames')
  const { palette } = useTheme()

  if (palette[crop]) {
    chipProps.color = crop
  }

  return (
    <Chip
      size={size}
      {...chipProps}
      title={labelOverride && t(crop)}
      label={labelOverride ? labelOverride : t(crop)}
      sx={
        chipProps.color
          ? { border: `solid 1px ${palette[crop][palette.mode === 'dark' ? 'light' : 'dark']}` }
          : { border: `solid 1px ${palette.grey[palette.mode === 'dark' ? '400' : '600']}` }
      }
      icon={icon ? icon : source ? <SourceIcon source={source} sx={{ opacity: 0.8 }} /> : null}
    />
  )
}
