import {
  Benchmark,
  DetailRequirement,
  FieldModel,
  PrgDetail,
  ProgramTask,
  TraitId,
} from '@cibo/core'
import {
  TraitContent,
  TraitFeature,
  useDetailEditing,
  useFieldColumns,
  useRollupQuestion,
} from '@cibo/landmanager'
import { DataGridPro } from '@cibo/ui'
import { Box, Button, Stack, Typography } from '@mui/material'
import { styled } from '@mui/material/styles'
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GridColDef,
  GridRowId,
  GridToolbarContainer,
} from '@mui/x-data-grid-pro'
import { ascend, assoc, flatten, prop, propEq, propOr, sort, uniqBy } from 'ramda'
import { JSXElementConstructor, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ProgramLimitFeedback } from '../ProgramLimitFeedback'
import { CiboSensedLegend } from './CiboSensedLegend'
import { InfoNeededCorner } from './InfoNeededCorner'
import { useRequirementColumns } from './useRequirementColumns'

const StyledDataGridPro = styled(DataGridPro)(() => ({
  '.MuiDataGrid-cell': {
    padding: 0,
    position: 'relative',
  },
  '.MuiSelect': { boxShadow: 'none' },
  '.MuiOutlinedInput-notchedOutline': { border: 0 },
  '.MuiDataGrid-pinnedRows': {
    backgroundColor: 'unset',
    boxShadow: 'unset',
  },
}))

const PAGE_SIZE = 10

export type TraitTableProps = {
  disableRollup?: boolean
  programIds?: string[]
  benchmark?: Benchmark
  fieldModels: FieldModel[]
  filterInputs?: PrgDetail
  detailRequirements: DetailRequirement[]
  traitId: TraitId
  loading?: boolean
  growerName?: string
  statusColumn?: GridColDef
  ToolbarButtons?: JSXElementConstructor<{ selectedFields?: GridRowId[] }>
  task?: ProgramTask
}

// Because this isn't long for the world.
// eslint-disable-next-line complexity
export const TraitTable = ({
  disableRollup,
  fieldModels,
  filterInputs,
  detailRequirements,
  traitId,
  loading,
  ToolbarButtons,
  programIds,
  benchmark,
  growerName,
  statusColumn,
  task,
}: TraitTableProps) => {
  const { t } = useTranslation('@cibo/landmanager/components')
  const { t: traitT } = useTranslation('traits')
  const [selectedFields, setSelectedFields] = useState<GridRowId[]>()
  const detailEditing = useDetailEditing()
  const Trait = TraitFeature.forTraitId(traitId)

  const { detailsPresent, rollup: rollupQuestion, rollupRequested } = useRollupQuestion()

  useEffect(() => setSelectedFields(undefined), [traitId])

  const requirements = sort(ascend(propOr(0, 'year')), detailRequirements)
  const detailColumns = useRequirementColumns({
    requirements,
    programIds,
    benchmark,
    filterInputs,
    task,
  })

  const { fieldNameColumn } = useFieldColumns()

  const columns = [fieldNameColumn, ...detailColumns]

  if (statusColumn) {
    columns.unshift(statusColumn)
  }

  const handleBulkEdit = () => {
    if (!selectedFields || !requirements) {
      return
    }

    if (selectedFields.length === 1 && requirements.length === 1) {
      detailEditing.setDetailModal({
        traitId,
        resourceId: selectedFields[0] as string,
        requirement: requirements[0],
        filterInputs,
      })
    } else {
      detailEditing.setBulkEditModal({
        traitId,
        resourceIds: selectedFields as string[],
        requirements,
        filterInputs,
      })
    }
  }

  const RollupComponent = rollupQuestion?.Component
  const showTable =
    disableRollup ||
    !rollupQuestion ||
    rollupRequested === false ||
    (detailsPresent && rollupRequested === undefined)

  const traitDetails =
    fieldModels &&
    flatten(
      fieldModels.map(fieldModel =>
        fieldModel.details
          .filter(propEq('traitId', traitId))
          .map(assoc('resourceId', fieldModel.resourceId))
      )
    )

  const TableCta = Trait?.tableCta

  return (
    <Stack spacing={3} sx={{ flex: 1 }}>
      {Trait?.hasContent && <TraitContent traitId={traitId} />}

      {!disableRollup && !!RollupComponent && <RollupComponent requirements={requirements} />}

      {!!showTable && (
        <>
          {!!TableCta ? (
            <TableCta
              count={fieldModels?.length}
              requirements={requirements}
              fieldModels={fieldModels}
            />
          ) : (
            <Typography>
              {t('cta', { trait: traitT(traitId), count: fieldModels?.length })}
            </Typography>
          )}
          {benchmark !== 'profile' && (
            <ProgramLimitFeedback create={false} growerName={growerName} />
          )}
          <Stack direction="row" justifyContent="flex-end" spacing={2}>
            <Box sx={{ height: 16 }}>
              <CiboSensedLegend traitDetails={traitDetails} />
            </Box>
            <Box sx={{ height: 16 }}>
              <Stack direction="row" alignItems="center" paddingBottom={1}>
                <Box
                  sx={{
                    width: 16,
                    height: 16,
                    position: 'relative',
                  }}
                >
                  <InfoNeededCorner />
                </Box>
                <Typography variant="caption" paddingLeft={0.5}>
                  {t('infoNeeded')}
                </Typography>
              </Stack>
            </Box>
          </Stack>

          <StyledDataGridPro
            autoHeight
            key={traitId}
            getRowId={fieldModel => fieldModel.resourceId}
            rows={fieldModels}
            columns={uniqBy(prop('field'))(columns)}
            rowHeight={65}
            pagination={fieldModels.length > PAGE_SIZE}
            pageSizeOptions={[PAGE_SIZE]}
            pinnedColumns={{
              left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'field'],
            }}
            isRowSelectable={() => !Trait?.forbidBulkEdit}
            checkboxSelection
            disableRowSelectionOnClick
            onRowSelectionModelChange={setSelectedFields}
            initialState={{
              pinnedColumns: {
                left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'field'],
              },
            }}
            slots={{
              toolbar: () => (
                <GridToolbarContainer>
                  <Stack direction="row" justifyContent="space-between" spacing={1} flex={1}>
                    <Button
                      onClick={handleBulkEdit}
                      disabled={Trait?.forbidBulkEdit || !selectedFields?.length}
                      data-testid="table-edit-bulk"
                    >
                      {t('bulkEdit')}
                    </Button>

                    {ToolbarButtons && <ToolbarButtons selectedFields={selectedFields} />}
                  </Stack>
                </GridToolbarContainer>
              ),
            }}
            loading={loading}
          />
        </>
      )}
    </Stack>
  )
}
