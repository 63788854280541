import { ProgramEngagementModel } from '@cibo/core'
import { PROGRAMS_QUERY_KEY } from '@cibo/landmanager'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { RequiredDocumentsAPI } from '../RequiredDocumentsAPI'

export const useProcessSigningReturn = () => {
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: ({
      engagementId,
      taskId,
      eventParam,
    }: {
      engagementId: string
      taskId: string
      eventParam: string
    }) =>
      RequiredDocumentsAPI.processSigningReturn({ engagementId, taskId, eventParam }).then(
        response => {
          const updatedEngagement = response.data.item

          // invalidate lists of engagements (may include this)
          queryClient.invalidateQueries({
            predicate: query =>
              query.queryKey[0] === PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS &&
              query.queryKey[1] !== engagementId,
          })

          // freshen cache with updated response
          queryClient.setQueryData(
            [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, engagementId],
            new ProgramEngagementModel(updatedEngagement)
          )

          // refresh engagement details to get signed status
          queryClient.invalidateQueries({
            queryKey: [PROGRAMS_QUERY_KEY.PROGRAM_ENGAGEMENTS, engagementId, 'resourceDetails'],
          })
        }
      ),
  })
}
