import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent } from '@mui/material'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'

export const CoverCropScenarioDropDownMenu = () => {
  const { t } = useTranslation('@cibo/landmanager/FieldsSelection')

  const [scenario, setScenario] = useState('')

  const handleSelection = (event: SelectChangeEvent) => {
    setScenario(event.target.value as string)
  }

  return (
    <FormControl fullWidth size="small">
      <InputLabel id="select-label">{t('select')}</InputLabel>
      <Select
        labelId="select-label"
        id="select-label"
        value={scenario}
        label="scenario"
        onChange={handleSelection}
        size="small"
      >
        <MenuItem value="">{t('scenario_none')}</MenuItem>
        <MenuItem value={'singleSpecies'}>{t('scenario_singleSpecies')}</MenuItem>
        <MenuItem value={'multipleSpecies'}>{t('scenario_multipleSpecies')}</MenuItem>
        <MenuItem value={'winterKill'}>{t('scenario_winterKill')}</MenuItem>
      </Select>
    </FormControl>
  )
}
