import booleanDisjoint from '@turf/boolean-disjoint'
import { FeatureCollection, Properties } from '@turf/helpers'
import union from '@turf/union'
import { Feature, MultiPolygon, Point, Polygon } from 'geojson'
import { BBoxArray } from './bboxTypes'

export type ShapeFeature = Feature<Polygon | MultiPolygon>

export function isPointFeature(feature: Feature): feature is Feature<Point> {
  return feature.geometry.type === 'Point'
}
export function isPolygonFeature(feature: Feature): feature is Feature<Polygon> {
  return feature.geometry.type === 'Polygon'
}
export function isMultiPolygonFeature(feature: Feature): feature is Feature<MultiPolygon> {
  return feature.geometry.type === 'MultiPolygon'
}

export const centerCoordinateOfBBox = (bbox: BBoxArray) => {
  return [(bbox[0] + bbox[2]) / 2, (bbox[1] + bbox[3]) / 2]
}

/**
 * Union the features within a featureCollection which overlap.  This approaches n^2 complexity :(
 *
 * This utility was written for the GeometryEditor which needs to glue together overlapping shapes
 * as the user is editing.  There is a mergeFeatureCollection utility that is a more efficient
 * mode of merging what is on screen, but at the cost of dropping points as it simply unions every
 * single feature together.  @turf/union will drop a point between neighboring points if the line
 * drawn between the neighboring points intersects the candidate point. The net result using a more
 * efficient method that unions all features is that the user will not be able to add points on
 * an existing line.
 *
 * The strategy here is to check each feature for intersection against every other feature and only
 * union when an intersection is detected.  This is not perfect however, since if a user draws a
 * rectangle, adds a 5th vertex on any line of that rectangle then draws another rectangle intersecting
 * the first, that 5th vertext will disappear.
 * @param featureCollection
 * @returns featureCollection with equal or lesser feature count than the input
 */
export const unionOverlappingFeatures = (
  featureCollection: FeatureCollection<Polygon | MultiPolygon, Properties>
): FeatureCollection<Polygon | MultiPolygon, Properties> => {
  const { features } = featureCollection
  const mergedFeatures = [...features]

  // yep n^2
  mergedFeatures.forEach((target, index) => {
    if (target) {
      mergedFeatures.forEach((candidate, candidateIndex) => {
        if (index !== candidateIndex && !!candidate && !booleanDisjoint(target, candidate)) {
          //@ts-ignore the union is guaranteed to return since they intersect
          target = union(target, candidate)
          //@ts-ignore we filter out the undefineds upon return
          mergedFeatures[candidateIndex] = undefined
        }
      })
      mergedFeatures[index] = target
    }
  })

  return { type: 'FeatureCollection', features: mergedFeatures.filter(f => !!f) }
}
