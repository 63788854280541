import { Markdown, MarkdownLink } from '@cibo/ui'
import { Typography } from '@mui/material'
import { ComponentProps, ReactNode } from 'react'

export const TaskMarkdown = (props: ComponentProps<typeof Markdown>) => (
  <Markdown
    overrides={{
      a: {
        component: MarkdownLink,
        props: {
          sx: { color: 'secondary.main', textDecorationColor: 'unset' },
        },
      },
      em: {
        component: ({ children }: { children: ReactNode }) => (
          <Typography component="span" variant="body2">
            {children}
          </Typography>
        ),
      },
    }}
    {...props}
    //@ts-ignore sx is, in fact, a prop
    sx={{ ...props.sx, section: { mb: 3 } }}
  />
)
