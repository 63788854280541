import { getBaseAgent, AgentOptions } from './baseAgent'
import { Config } from '../../types'
import { GlobalMpAuth } from './GlobalMpAuth'

export const getMpAgent = (options?: AgentOptions) =>
  getBaseAgent({
    ...options,
    requestMiddlewares: [
      ...(options?.requestMiddlewares || []),
      config => ({ ...config, baseURL: Config.get('MP_BASE_URL') }),
      GlobalMpAuth.requestMiddleware,
    ],
  })
