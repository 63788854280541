import { Box, FormControl, FormLabel, Slider, Stack, Typography, styled } from '@mui/material'
import { useField } from 'formik'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useFigures } from '../hooks'

const maxAcreageScale = Math.sqrt(1200)
const sliderUnit = maxAcreageScale / 6
const MIN_ACREAGE = 0
const MAX_ACREAGE = 10000

const StyledLabelMark = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  fontWeight: 400,
  color: theme.palette.text.secondary,
  top: 30,
}))

const acreageScale = (value: number) => Math.round(Math.pow(value, 2))

export const AcreageFilter = () => {
  const { t } = useTranslation('@cibo/ui')
  const { acres } = useFigures()

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ltField, { value: ltValue = MAX_ACREAGE }, { setValue: setAcresLt }] = useField('acresLt')
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [gtField, { value: gtValue = MIN_ACREAGE }, { setValue: setAcresGt }] = useField('acresGt')
  const [acreage, setAcreage] = useState<number[]>([Math.sqrt(gtValue), Math.sqrt(ltValue)])

  useEffect(() => {
    setAcreage([Math.sqrt(gtValue), Math.sqrt(ltValue)])
  }, [ltValue, gtValue])

  const handleChangeAcreage = (event: any, newAcreage: number | number[], activeThumb: number) => {
    if (Array.isArray(newAcreage) && newAcreage.length === 2) {
      setAcreage(newAcreage)
      newAcreage[1] === maxAcreageScale
        ? setAcresLt(undefined)
        : setAcresLt(Math.pow(newAcreage[1], 2))
      newAcreage[0] === 0 ? setAcresGt(undefined) : setAcresGt(Math.pow(newAcreage[0], 2))
    } else {
      setAcresGt(undefined)
      setAcresLt(undefined)
    }
  }

  return (
    <FormControl>
      <FormLabel>{t('acres')}</FormLabel>
      <Stack>
        <Box sx={{ px: 2 }}>
          <Slider
            min={0}
            max={maxAcreageScale}
            value={acreage}
            onChange={handleChangeAcreage}
            valueLabelDisplay="auto"
            scale={acreageScale}
            marks={[
              {
                value: 0,
              },
              {
                value: sliderUnit * 1,
              },
              {
                value: sliderUnit * 2,
              },
              {
                value: sliderUnit * 3,
              },
              {
                value: sliderUnit * 4,
              },
              {
                value: sliderUnit * 5,
              },
              {
                value: maxAcreageScale,
              },
            ]}
            size="small"
          />
        </Box>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mt={-1}>
          <StyledLabelMark>{acres(0)}</StyledLabelMark>
          <StyledLabelMark>{`${acres(acreageScale(maxAcreageScale))}+`}</StyledLabelMark>
        </Stack>
      </Stack>
    </FormControl>
  )
}
