import { useTranslation } from 'react-i18next'

import { NumberInputField, RadioBooleanField } from '@cibo/ui'
import { Collapse, InputLabel, MenuItem, Stack } from '@mui/material'
import { Field, useField } from 'formik'
import { Select, TextField } from 'formik-mui'
import { DetailEditorPropsByYear } from '../types'
import {
  IRRIGATION_ENERGY_SOURCE,
  IRRIGATION_SURFACE_WATER,
  IRRIGATION_WATER_SOURCE,
  IRRIGATION_WATER_UNIT,
} from './types'

export const WaterPumpIrrigationEditor = ({
  fieldModels,
  year,
  name = 'waterPumpIrrigation',
  requirement,
  filterInputs,
  traitId,
}: DetailEditorPropsByYear) => {
  const { t } = useTranslation('@cibo/landmanager/WaterPumpIrrigation')

  const field = useField(name)
  // TODO: improve typing on `value` which is currently typed as `any`
  const { value } = field[1]

  return (
    <Stack spacing={2}>
      <Stack direction="row" spacing={2} alignItems={'center'}>
        <InputLabel htmlFor={`${name}.pumpsUsed`}>{t('pumpsUsed')}</InputLabel>
        <RadioBooleanField name={`${name}.pumpsUsed`} />
      </Stack>

      {/* @ts-ignore bool as string inside the form */}
      <Collapse in={value?.pumpsUsed === 'true'}>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={2}>
            <InputLabel htmlFor={`${name}.energySource`}>{t('energyTypeLabel')}</InputLabel>
            <Field component={Select} name={`${name}.energySource`} sx={{ width: 200 }}>
              {IRRIGATION_ENERGY_SOURCE.map(source => (
                <MenuItem key={source} value={source}>
                  {t(`energyType_${source}`)}
                </MenuItem>
              ))}
            </Field>
          </Stack>

          <Collapse in={value?.energySource === 'other'}>
            <Stack direction="row" alignItems="center" spacing={2}>
              <InputLabel htmlFor={`${name}.energyAmount`}>{t('energyAmount')}</InputLabel>
              <NumberInputField name={`${name}.energyAmount`} min={0} />
              <InputLabel htmlFor={`${name}.userEnergyUnit`}>{t('energyUnitLabel')}</InputLabel>
              <Field component={TextField} name={`${name}.userEnergyUnit`} />
            </Stack>
          </Collapse>

          <Collapse
            in={
              !!value &&
              (value.energySource === 'diesel' ||
                value.energySource === 'renewable' ||
                value.energySource === 'electricity')
            }
          >
            <Stack direction="row" alignItems="center" spacing={2}>
              <InputLabel htmlFor={`${name}.energyAmount`}>{t('energyAmount')}</InputLabel>
              <NumberInputField
                name={`${name}.energyAmount`}
                min={0}
                unit={value?.energySource === 'diesel' ? t('gal') : t('kWh')}
              />
            </Stack>
          </Collapse>

          <Collapse in={value?.energySource === 'unknown'}>
            <Stack spacing={2}>
              <InputLabel htmlFor={`${name}.totalWater`}>{t('totalWaterLabel')}</InputLabel>
              <Stack direction="row" alignItems="center" spacing={2}>
                <NumberInputField name={`${name}.totalWater`} min={0} />
                <Field
                  component={Select}
                  name={`${name}.waterUnit`}
                  sx={{ width: 200 }}
                  defaultValue="placeholder"
                >
                  <MenuItem value="placeholder">{t('totalWaterUnit')}</MenuItem>
                  {IRRIGATION_WATER_UNIT.map(unit => (
                    <MenuItem key={unit} value={unit}>
                      {t(`totalWater_${unit}`)}
                    </MenuItem>
                  ))}
                </Field>
              </Stack>

              <Stack direction="row" alignItems="center" spacing={2}>
                <InputLabel htmlFor={`${name}.waterSource`}>{t('waterSourceLabel')}</InputLabel>
                <Field component={Select} name={`${name}.waterSource`} sx={{ width: 200 }}>
                  {IRRIGATION_WATER_SOURCE.map(source => (
                    <MenuItem key={source} value={source}>
                      {t(`waterSource_${source}`)}
                    </MenuItem>
                  ))}
                </Field>
              </Stack>

              <Collapse in={value?.waterSource === 'groundwater'}>
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InputLabel htmlFor={`${name}.pumpingDepthFeet`}>
                      {t('pumpingDepthLabel')}
                    </InputLabel>
                    <NumberInputField name={`${name}.pumpingDepthFeet`} unit={t('feet')} />
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InputLabel htmlFor={`${name}.operatingPressurePSI`}>
                      {t('operatingPressureLabel')}
                    </InputLabel>
                    <NumberInputField
                      name={`${name}.operatingPressurePSI`}
                      unit={t('psi')}
                      min={0}
                    />
                  </Stack>
                </Stack>
              </Collapse>

              <Collapse in={value?.waterSource === 'onFarm'}>
                <Stack spacing={1}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InputLabel htmlFor={`${name}.surfaceWaterSource`}>
                      {t('surfaceWaterSourceLabel')}
                    </InputLabel>
                    <Field
                      component={Select}
                      name={`${name}.surfaceWaterSource`}
                      sx={{ width: 200 }}
                    >
                      {IRRIGATION_SURFACE_WATER.map(source => (
                        <MenuItem key={source} value={source}>
                          {t(`surfaceWaterSource_${source}`)}
                        </MenuItem>
                      ))}
                    </Field>
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InputLabel htmlFor={`${name}.operatingPressurePSI`}>
                      {t('operatingPressureLabel')}
                    </InputLabel>
                    <NumberInputField
                      name={`${name}.operatingPressurePSI`}
                      unit={t('psi')}
                      min={0}
                    />
                  </Stack>

                  <Stack direction="row" alignItems="center" spacing={2}>
                    <InputLabel htmlFor={`${name}.liftingOccurs`}>
                      {t('doesLiftingOccurLabel')}
                    </InputLabel>
                    <RadioBooleanField name={`${name}.liftingOccurs`} />
                  </Stack>
                </Stack>
              </Collapse>
            </Stack>
          </Collapse>
        </Stack>
      </Collapse>
    </Stack>
  )
}
