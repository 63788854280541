import { FDR_FILTER_STATUSES, FDRStatusQuery } from '@cibo/core'
import { Checkbox, FormControl, FormControlLabel, FormGroup, FormLabel } from '@mui/material'
import { useField } from 'formik'
import { prop, propEq, uniqBy } from 'ramda'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { PaginatedFieldsContext } from '../PaginatedFieldsContext'

export const ProgramStatusFilter = () => {
  const { t } = useTranslation('@cibo/landmanager/ProgramStatusFilter')
  const { baseFilters } = useContext(PaginatedFieldsContext)

  const fieldContext = useField('fdrStatusAnyOf')
  const { value = [] } = fieldContext[1]
  const { setValue } = fieldContext[2]

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const status = event.target.name

    if (event.target.checked) {
      setValue(uniqBy(prop('status'), [...value, { status }]))
    } else {
      setValue(value.filter(({ status: oldStatus }: FDRStatusQuery) => oldStatus !== status))
    }
  }

  return (
    <FormControl>
      <FormLabel>{t('status')}</FormLabel>
      <FormGroup>
        {FDR_FILTER_STATUSES.map(status => (
          <FormControlLabel
            key={status}
            control={
              <Checkbox
                name={status}
                onChange={handleChange}
                checked={value.some(propEq('status', status))}
                disabled={baseFilters.fdrStatusAnyOf?.some(propEq('status', status))}
              />
            }
            label={t(status)}
          />
        ))}
      </FormGroup>
    </FormControl>
  )
}
