import CancelIcon from '@mui/icons-material/Cancel'
import { Box, Fade, IconButton, Slide, Stack, Tooltip } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'

import { Blur } from '../Blur'
import { ExtendedDataGridProProps } from './types'

export const BulkActionToolbar = ({ slots }: ExtendedDataGridProProps) => {
  const { t } = useTranslation('@cibo/ui/BulkActionToolbar')
  const { current: grid } = useGridApiContext()
  const clearSelection = () => grid.setRowSelectionModel([])
  const container = useRef(null)

  if (slots?.bulkActions) {
    return (
      <Box px={2} py={1} sx={{ display: 'grid', alignItems: 'flex-end' }}>
        {slots?.toolbar && (
          <Box sx={{ gridArea: '1/1' }}>
            <Blur blur={grid.getSelectedRows().size !== 0}>
              <slots.toolbar />
            </Blur>
          </Box>
        )}

        <Box sx={{ gridArea: '1/1' }}>
          <Fade in={grid.getSelectedRows().size > 0}>
            <Stack direction="row" spacing={2} alignItems="center">
              <Box>
                <Tooltip
                  title={t('clearAll', { count: grid.getSelectedRows().size })}
                  placement="top"
                >
                  <IconButton size="small" onClick={clearSelection} sx={{ ml: -1 }}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>
              </Box>
              <Box ref={container} sx={{ overflow: 'hidden' }}>
                <Slide
                  in={grid.getSelectedRows().size > 0}
                  container={container?.current}
                  direction="right"
                >
                  <Box>
                    <slots.bulkActions />
                  </Box>
                </Slide>
              </Box>
            </Stack>
          </Fade>
        </Box>
      </Box>
    )
  }

  return <></>
}
