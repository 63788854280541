import { FieldModel, ResourceDetail, TraitIds } from '@cibo/core'
import { useDataGridPersistence } from '@cibo/profile'
import { DataGridPro } from '@cibo/ui'
import AddIcon from '@mui/icons-material/Add'
import EditIcon from '@mui/icons-material/Edit'
import LockIcon from '@mui/icons-material/Lock'
import { Box, IconButton, Stack, styled, Typography } from '@mui/material'
import { GridRenderCellParams, GridValueFormatterParams } from '@mui/x-data-grid-pro'
import { groupBy, indexBy, mapObjIndexed, pipe, prop } from 'ramda'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ITraitFeatureById, TraitFeature, useDetailEditing } from '../../../features'
import { LandReportSection } from '../LandReportSection'

const YEAR_COUNT = 10
const LATEST_YEAR = 2024
const YEARS = Array(YEAR_COUNT)
  .fill(0)
  .map((_, i) => LATEST_YEAR + 1 - YEAR_COUNT + i)

const practiceTableTraits = TraitIds.map(traitId => TraitFeature.forTraitId(traitId)).filter(
  trait => trait?.SimpleCellView
) as ITraitFeatureById[]

const HoverStyleBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  paddingLeft: theme.spacing(1),
  paddingRight: theme.spacing(1),
  '&:hover': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:hover .edit-icon': {
    visibility: 'visible',
  },
}))

type EditIconCellProps = {
  children: React.ReactNode
  detail?: ResourceDetail
  onClickEdit: () => void
  editButtonTestId: string
}

const EditIconCell = ({ children, detail, onClickEdit, editButtonTestId }: EditIconCellProps) => (
  <HoverStyleBox>
    {children}
    <IconButton
      className="edit-icon"
      sx={{ visibility: 'hidden' }}
      size="small"
      onClick={onClickEdit}
      data-testid={editButtonTestId}
    >
      {!detail ? (
        <AddIcon fontSize="small" />
      ) : detail.immutable ? (
        <LockIcon fontSize="small" />
      ) : (
        <EditIcon fontSize="small" />
      )}
    </IconButton>
  </HoverStyleBox>
)

type PracticesTableProps = {
  field: FieldModel
}

export const PracticesTable = ({ field }: PracticesTableProps) => {
  const { t } = useTranslation('@cibo/landmanager/PracticesTable')
  const { setDetailModal } = useDetailEditing()

  const details = useMemo(
    () =>
      pipe(groupBy(prop<string>('traitId')), mapObjIndexed(indexBy(prop('year'))))(field.details),
    [field]
  )

  const rows = useMemo(
    () =>
      practiceTableTraits.map(trait => ({
        id: trait.traitId,
        SimpleCellView: trait.SimpleCellView,
        ...details[trait.traitId],
      })),
    [details]
  )

  const columns = useMemo(
    () => [
      {
        field: 'id',
        headerName: t('data'),
        hideable: false,
        flex: 1,
        valueFormatter: ({ value }: GridValueFormatterParams) => t(value, { ns: 'trait' }),
      },
      ...YEARS.map((year, i) => ({
        field: `${year}`,
        renderCell: (params: GridRenderCellParams) => (
          <EditIconCell
            detail={params.value}
            editButtonTestId={`edit-${params.row.id}-${year}`}
            onClickEdit={() =>
              setDetailModal({
                resourceId: field.resourceId,
                traitId: params.row.id,
                requirement: { traitId: params.row.id, year, dataType: 'field' },
              })
            }
          >
            {!!params.value ? <params.row.SimpleCellView detail={params.value} /> : <Box />}
          </EditIconCell>
        ),
        sortable: false,
        flex: 1,
      })),
    ],
    []
  )

  const defaultColumnVisibility = useMemo(
    () =>
      Array(YEAR_COUNT - 4)
        .fill(0)
        .map((_, index) => `${LATEST_YEAR - YEAR_COUNT + 1 + index}`)
        .reduce((acc, item) => ({ ...acc, [item]: false }), {}),
    []
  )

  const persistenceProps = useDataGridPersistence({
    columns,
    key: 'practicesTable',
    options: ['columnVisibility'],
    defaults: { columnVisibilityModel: defaultColumnVisibility },
  })

  return (
    <LandReportSection>
      <Stack spacing={2}>
        <Typography variant="h6">{t('practices')}</Typography>

        <DataGridPro
          autoHeight
          {...persistenceProps}
          rows={rows}
          hideFooter
          hideCellFocus
          sx={{
            '& .MuiDataGrid-row:hover': {
              backgroundColor: 'inherit',
            },
          }}
          disableRowSelectionOnClick
        />
      </Stack>
    </LandReportSection>
  )
}
