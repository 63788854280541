import {
  RegisterVerifiedUserActionTypes,
  MP_REGISTER_VERIFIED_USER_CREATE_PENDING,
  MP_REGISTER_VERIFIED_USER_SEND_CODE_PENDING,
  MP_REGISTER_VERIFIED_USER_SET_LOGIN_PENDING,
  MP_REGISTER_VERIFIED_USER_SEND_CODE_ERROR,
  MP_REGISTER_VERIFIED_USER_CREATE_ERROR,
  MP_REGISTER_VERIFIED_USER_SET_LOGIN_ERROR,
  MP_REGISTER_VERIFIED_USER_SEND_CODE_SUCCESS,
  MP_REGISTER_VERIFIED_USER_CREATE_SUCCESS,
  MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS,
  RegisterVerifiedUserError,
  MP_REGISTER_VERIFIED_RESET,
  MP_AUTH_DIRECT_LOGOUT_SUCCESS,
  AuthDirectLogoutActionTypes,
  UserId,
} from '@cibo/core'

export interface RegisterVerifiedUserState {
  error?: RegisterVerifiedUserError
  pending: boolean
  contactType?: string
  contactAddress?: string
  verifyCode?: string
  verifyCodeSent: boolean
  userId?: UserId
  userJwt?: string
  registrationComplete: boolean
}

const initialState: RegisterVerifiedUserState = {
  pending: false,
  verifyCodeSent: false,
  registrationComplete: false,
}

export const registerVerifiedUserReducer = (
  state = initialState,
  action: RegisterVerifiedUserActionTypes | AuthDirectLogoutActionTypes
) => {
  switch (action.type) {
    case MP_REGISTER_VERIFIED_USER_SEND_CODE_PENDING:
      return {
        ...state,
        registrationComplete: false,
        pending: true,
      }
    case MP_REGISTER_VERIFIED_USER_CREATE_PENDING:
      const { verifyCode } = action.query
      return {
        ...state,
        pending: true,
        verifyCode,
      }
    case MP_REGISTER_VERIFIED_USER_SET_LOGIN_PENDING:
      return {
        ...state,
        pending: true,
      }

    case MP_REGISTER_VERIFIED_USER_SEND_CODE_ERROR:
    case MP_REGISTER_VERIFIED_USER_CREATE_ERROR:
    case MP_REGISTER_VERIFIED_USER_SET_LOGIN_ERROR:
      return {
        ...state,
        pending: false,
        error: action.error,
      }

    case MP_REGISTER_VERIFIED_USER_SEND_CODE_SUCCESS:
      const { contactAddress, contactType } = action.results.data
      return {
        ...state,
        pending: false,
        error: undefined,
        contactAddress,
        contactType,
        verifyCodeSent: true,
      }

    case MP_REGISTER_VERIFIED_USER_CREATE_SUCCESS:
      // @ todo remove default authUserLevel value when supplied by the backend.
      const { userId } = action.results.data
      return {
        ...state,
        pending: false,
        error: undefined,
        userId,
      }

    case MP_REGISTER_VERIFIED_USER_SET_LOGIN_SUCCESS: {
      const { userJwt } = action.results.data
      return {
        ...initialState,
        userJwt,
        registrationComplete: true,
      }
    }

    case MP_AUTH_DIRECT_LOGOUT_SUCCESS:
    case MP_REGISTER_VERIFIED_RESET:
      return { ...initialState }

    default:
      return state
  }
}

export interface StoreWithRegisterVerifiedUser {
  authReducer: { registerVerifiedUser: RegisterVerifiedUserState }
}

const selectBase = (state: StoreWithRegisterVerifiedUser) => state.authReducer.registerVerifiedUser
export const selectRegisterVerifiedUserLoading = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).pending
export const selectRegisterVerifiedUserError = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).error
export const selectRegisterVerifiedUserUserId = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).userId
export const selectRegisterVerifiedUserVerifyCode = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).verifyCode
export const selectRegisterVerifiedUserVerifyCodeSent = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).verifyCodeSent
export const selectRegisterVerifiedUserContactAddress = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).contactAddress
export const selectRegisterVerifiedUserContactType = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).contactType
export const selectRegisterVerifiedUserJwt = (state: StoreWithRegisterVerifiedUser) =>
  selectBase(state).userJwt
