import { ResourceDetailFeatureTaskEditorProps, SubmitButton } from '@cibo/ui'
import AddIcon from '@mui/icons-material/Add'
import ClearIcon from '@mui/icons-material/Clear'
import EditIcon from '@mui/icons-material/Edit'
import {
  Box,
  Button,
  ClickAwayListener,
  IconButton,
  Paper,
  Popover,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material'
import { Formik, useFormikContext } from 'formik'
import { omit } from 'ramda'
import { useCallback, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Loading } from '../../../../../components'
import { useAuth, useUpdateUserDetails, useUserDetails } from '../../../../../hooks'
import { UsdaGrowerDetails } from '../types'
import { UsdaMailingAddressField } from '../UsdaMailingAddressField'
import { useEscapeKeyHandler } from './useEscapeKeyHandler'

const mailingAddressFieldName = 'mailingAddress'

export const UsdaMailingAddressWidgetForm = ({
  detailRequirements,
  resourceIds: [resourceId],
  onClearAddress,
  popperState,
  setPopperState,
}: ResourceDetailFeatureTaskEditorProps<UsdaGrowerDetails> & {
  onClearAddress(): void
  popperState: { open: boolean }
  setPopperState(state: { open: boolean }): void
}) => {
  const { t } = useTranslation('@cibo/profile/UsdaGrowerDetailsTaskEditor')
  const details = useUserDetails({ resourceId, detailRequirements })

  const { userId } = useAuth()
  const context = resourceId === userId?.toString() ? 'participant' : 'manager'
  // This is to deal with inconsistency in our detail definitions.
  // By and large, details have used `input:` and `result:` but this
  // detail uses `value:`
  const detail = details?.data?.[0] as unknown as UsdaGrowerDetails

  const addressAnchorRef = useRef<HTMLDivElement | null>(null)

  const { resetForm } = useFormikContext()

  const disabled = details.data?.[0]?.immutable || details?.isPending

  const address = detail?.value?.mailingAddress

  useEscapeKeyHandler(() => setPopperState({ open: false }))

  return (
    <Stack spacing={2} ref={addressAnchorRef}>
      {!!address ? (
        <Stack direction="row">
          <Stack direction="row" spacing={2} component={Paper}>
            <Box p={2}>
              <Typography>{address.street1}</Typography>
              <Typography>{address.street2}</Typography>
              <Typography>
                {address.city}, {address.county}, {address.state} {address.zipCode}
              </Typography>
            </Box>

            <Stack>
              <Tooltip title={t('clearAddress')}>
                <IconButton
                  size="small"
                  onClick={() => {
                    resetForm()
                    onClearAddress()
                  }}
                  disabled={disabled}
                >
                  <ClearIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title={t('editAddress')}>
                <IconButton
                  size="small"
                  onClick={() => setPopperState({ open: true })}
                  disabled={disabled}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </Stack>
        </Stack>
      ) : (
        <Box>
          <Button
            startIcon={<AddIcon />}
            variant="contained"
            onClick={() => setPopperState({ open: true })}
          >
            {t('addAddress')}
          </Button>
        </Box>
      )}

      <Popover open={popperState.open} anchorEl={addressAnchorRef.current}>
        <ClickAwayListener onClickAway={() => popperState.open && setPopperState({ open: false })}>
          <Stack spacing={2} p={1.5}>
            <Stack spacing={1}>
              <Typography>{t('mailingAddressCta', { context })}</Typography>
              <UsdaMailingAddressField name={mailingAddressFieldName} disabled={disabled} />
            </Stack>

            <Stack direction="row" justifyContent="flex-end" spacing={1}>
              <Button variant="outlined" onClick={() => setPopperState({ open: false })}>
                {t('cancel')}
              </Button>

              <SubmitButton>{t('save')}</SubmitButton>
            </Stack>
          </Stack>
        </ClickAwayListener>
      </Popover>
    </Stack>
  )
}

export const UsdaMailingAddressWidget = (
  props: ResourceDetailFeatureTaskEditorProps<UsdaGrowerDetails>
) => {
  const {
    resourceIds: [resourceId],
    detailRequirements,
    onUpdating,
    onError,
    onSuccess,
  } = props
  const details = useUserDetails({ resourceId, detailRequirements })
  // This is to deal with inconsistency in our detail definitions.
  // By and large, details have used `input:` and `result:` but this
  // detail uses `value:`
  const detail = details?.data?.[0] as unknown as UsdaGrowerDetails

  const [popperState, setPopperState] = useState({ open: false })
  const updateUserDetails = useUpdateUserDetails<UsdaGrowerDetails>()

  const clearAddressDetail = useCallback(() => {
    onUpdating?.()

    updateUserDetails
      .mutateAsync({
        resourceId,
        details: [
          {
            traitId: 'usdaGrowerDetails',
            year: detailRequirements[0].year,
            value: omit(['mailingAddress'], detail?.value),
          } as UsdaGrowerDetails,
        ],
      })
      .catch(onError)
      .then(onSuccess)
  }, [updateUserDetails])

  const handleSubmit = useCallback(
    (values: any) => {
      onUpdating?.()

      updateUserDetails
        .mutateAsync({
          resourceId,
          details: [
            {
              traitId: 'usdaGrowerDetails',
              year: detailRequirements[0].year,
              value: { ...detail?.value, ...values },
            } as UsdaGrowerDetails,
          ],
        })
        .catch(onError)
        .then(() => {
          setPopperState({ open: false })
          onSuccess?.()
        })
    },
    [updateUserDetails]
  )

  if (details.isPending) return <Loading />

  return (
    <Formik
      // @ts-ignore How to allow empty strings for these initial values?
      initialValues={{
        mailingAddress: detail?.value?.mailingAddress ?? {
          street1: '',
          street2: '',
          city: '',
          county: '',
          state: '',
          zipCode: '',
        },
      }}
      validateOnChange
      onSubmit={handleSubmit}
    >
      <UsdaMailingAddressWidgetForm
        {...props}
        onClearAddress={clearAddressDetail}
        popperState={popperState}
        setPopperState={setPopperState}
      />
    </Formik>
  )
}
