import DeleteForever from '@mui/icons-material/DeleteForeverRounded'
import { IconButton, Link, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

import { RDAgronomicDataCSV } from '@cibo/core'
import { TraitCellDisplayProps } from '../types'

export const AgronomicDataCSVCell = ({
  year,
  params,
}: TraitCellDisplayProps<RDAgronomicDataCSV>) => {
  const detail = params.row.agronomicDataCsv?.find(detail => detail.year === year)
  const { t } = useTranslation('@cibo/landmanager/AgronomicDataCSVEditor')
  const file = detail?.file?.[0]
  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Link download underline="none" href={file?.uri} variant="body1" color="secondary">
          {file?.name}
        </Link>
        <IconButton aria-label={t('clearFile')} disabled>
          <DeleteForever />
        </IconButton>
      </Stack>
      <Typography variant="caption" sx={{ mt: -0.5 }}>
        {t('sizeBytes', { size: file?.bytes })}
      </Typography>
    </>
  )
}
