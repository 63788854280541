import { Paper, useTheme } from '@mui/material'

type LandReportSectionProps = {
  children: React.ReactNode
}

export const LandReportSection = ({ children }: LandReportSectionProps) => {
  const theme = useTheme()

  return (
    <Paper
      sx={{ padding: 2 }}
      style={{ borderRadius: 4, border: `1px solid ${theme.palette.divider}` }}
      elevation={0}
    >
      {children}
    </Paper>
  )
}
