import { RefObject, useLayoutEffect } from 'react'
import useResizeObserver from '@react-hook/resize-observer'
import { useDebounce } from '@react-hook/debounce'

export const useSize = (target: RefObject<HTMLElement>, debounceMs = 500) => {
  const [size, setSize] = useDebounce<DOMRect | undefined>(undefined, debounceMs, true)

  useLayoutEffect(() => {
    !!target?.current && setSize(target.current.getBoundingClientRect())
  }, [target])

  useResizeObserver(target, entry => setSize(entry.contentRect))
  return size
}
