import { useAuth } from '@cibo/profile'
import { Markdown, useFigures } from '@cibo/ui'
import { Alert, AlertTitle } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useProgram } from '../../queries'
import { ProgramLimitMeter } from '../ProgramLimitMeter'
import { useProgramLimit } from './ProgramLimitContext'
import { LimitType } from './utils/programLimitUtils'

interface ProgramLimitFeedbackProps {
  create?: boolean
  growerName?: string
  additionalMdContent?: Partial<Record<LimitType, string>>
}

export const ProgramLimitFeedback = ({
  create,
  growerName,
  additionalMdContent,
}: ProgramLimitFeedbackProps) => {
  const { t } = useTranslation('@cibo/programs/ProgramLimitFeedback')
  const { persona } = useAuth()
  const { display, limits, programId } = useProgramLimit()
  const program = useProgram({ programId: programId })
  const { acres, fields } = useFigures()
  const programName = program.data?.content.fields.title

  if (!limits || !display || limits[0].display === false) return null

  const { unit, committed, limitValue, workflowResourceCountForUnit, limitType, limitTypeName } =
    limits[0]

  const quantityFunct = unit === 'acres' ? acres : fields

  const quantityLimitRemain = quantityFunct(!!committed ? limitValue - committed : limitValue)
  const overEnrollQuantity = quantityFunct(
    !!committed
      ? workflowResourceCountForUnit - (limitValue - committed)
      : workflowResourceCountForUnit - limitValue
  )
  const translationArgs = {
    quantityLimitRemain,
    programName,
    quantityUnit: unit,
    growerName,
    overEnrollQuantity,
  }

  const translationVars = `${limitType}${limitType === 'limited' && create ? 'Create' : ''}${
    limitType === 'overCommit' && unit ? unit : ''
  }`
  console.log(additionalMdContent && additionalMdContent[limitType])

  return (
    <Alert severity="warning">
      <AlertTitle data-testid={`feedback-${limitTypeName}`}>
        {t(`feedbackTitle_${limitType}_${unit}`)}
      </AlertTitle>
      {limitType === 'limited' && (
        <ProgramLimitMeter programId={programId} displayUnit={limits[0].unit} />
      )}
      <Markdown typographyProps={{ variant: 'body1' }}>
        {persona === 'grower'
          ? t(`growerFeedbackMd_${translationVars}`, translationArgs)
          : t(`advisorFeedbackMd_${translationVars}`, translationArgs) +
            (additionalMdContent?.[limitType] ?? '')}
      </Markdown>
    </Alert>
  )
}
